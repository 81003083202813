/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

(function(root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['exports', 'echarts'], factory);
    } else if (typeof exports === 'object' && typeof exports.nodeName !== 'string') {
        // CommonJS
        factory(exports, require('echarts'));
    } else {
        // Browser globals
        factory({}, root.echarts);
    }
}(this, function(exports, echarts) {
    var log = function(msg) {
        if (typeof console !== 'undefined') {
            console && console.error && console.error(msg);
        }
    }
    if (!echarts) {
        log('ECharts is not Loaded');
        return;
    }
    if (!echarts.registerMap) {
        log('ECharts Map is not loaded')
        return;
    }
    echarts.registerMap('长沙市', {
        "type": "FeatureCollection",
        "features": [{
            "type": "Feature",
            "properties": {
                "adcode": 430102,
                "name": "芙蓉区",
                "center": [112.988094, 28.193106],
                "centroid": [113.049296, 28.197807],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430100
                },
                "subFeatureIndex": 0,
                "acroutes": [100000, 430000, 430100]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.986772, 28.185796],
                            [112.994175, 28.185927],
                            [113.002405, 28.185292],
                            [113.011298, 28.185039],
                            [113.03036, 28.184185],
                            [113.030289, 28.182084],
                            [113.03431, 28.182271],
                            [113.034404, 28.18044],
                            [113.036107, 28.180375],
                            [113.036958, 28.17943],
                            [113.038638, 28.176255],
                            [113.040861, 28.175539],
                            [113.042351, 28.175473],
                            [113.043888, 28.176108],
                            [113.044905, 28.177224],
                            [113.045165, 28.178535],
                            [113.043864, 28.181392],
                            [113.043675, 28.183183],
                            [113.044266, 28.184722],
                            [113.047104, 28.18727],
                            [113.047436, 28.188402],
                            [113.049588, 28.188174],
                            [113.050747, 28.189655],
                            [113.055169, 28.18679],
                            [113.065031, 28.180122],
                            [113.068484, 28.17711],
                            [113.073356, 28.174553],
                            [113.080286, 28.173161],
                            [113.083147, 28.17299],
                            [113.086955, 28.173772],
                            [113.091733, 28.175425],
                            [113.095375, 28.177297],
                            [113.101003, 28.180839],
                            [113.104788, 28.18228],
                            [113.107176, 28.182377],
                            [113.107838, 28.183737],
                            [113.108997, 28.18447],
                            [113.108784, 28.185707],
                            [113.109139, 28.187856],
                            [113.108761, 28.189444],
                            [113.107602, 28.189476],
                            [113.106798, 28.192863],
                            [113.107294, 28.194059],
                            [113.106703, 28.196656],
                            [113.108075, 28.197299],
                            [113.109778, 28.197348],
                            [113.110984, 28.201084],
                            [113.109707, 28.202753],
                            [113.107933, 28.202801],
                            [113.108146, 28.199847],
                            [113.10623, 28.199757],
                            [113.106372, 28.201068],
                            [113.105875, 28.204616],
                            [113.106703, 28.205439],
                            [113.105426, 28.205325],
                            [113.10545, 28.207929],
                            [113.10351, 28.207359],
                            [113.103416, 28.205243],
                            [113.101358, 28.207514],
                            [113.097621, 28.207172],
                            [113.097976, 28.207628],
                            [113.096936, 28.210078],
                            [113.094854, 28.21033],
                            [113.094807, 28.211193],
                            [113.092655, 28.21151],
                            [113.092702, 28.212975],
                            [113.091472, 28.213016],
                            [113.091165, 28.21453],
                            [113.089841, 28.214904],
                            [113.0888, 28.214196],
                            [113.088114, 28.215214],
                            [113.086766, 28.215214],
                            [113.087949, 28.212031],
                            [113.087665, 28.211234],
                            [113.085749, 28.210062],
                            [113.084708, 28.210037],
                            [113.084756, 28.212975],
                            [113.08381, 28.212642],
                            [113.081445, 28.213106],
                            [113.081232, 28.215287],
                            [113.072789, 28.21536],
                            [113.072623, 28.217582],
                            [113.069217, 28.21785],
                            [113.065008, 28.217207],
                            [113.06515, 28.218461],
                            [113.062856, 28.217338],
                            [113.060703, 28.217085],
                            [113.059497, 28.217663],
                            [113.058409, 28.219291],
                            [113.057085, 28.223091],
                            [113.045473, 28.217403],
                            [113.040766, 28.216565],
                            [113.032867, 28.217199],
                            [113.027924, 28.218607],
                            [113.024045, 28.219266],
                            [113.019457, 28.21868],
                            [113.013592, 28.216906],
                            [113.011936, 28.216548],
                            [113.010565, 28.216768],
                            [113.008223, 28.218884],
                            [113.005835, 28.218233],
                            [113.003233, 28.215067],
                            [113.001412, 28.213521],
                            [113.002074, 28.211982],
                            [113.004463, 28.210753],
                            [113.005503, 28.209191],
                            [113.00723, 28.209622],
                            [113.007869, 28.208824],
                            [113.006875, 28.208035],
                            [113.006852, 28.206008],
                            [113.004652, 28.205235],
                            [113.000655, 28.204926],
                            [112.997628, 28.205772],
                            [112.994766, 28.205512],
                            [112.992732, 28.203973],
                            [112.991834, 28.200832],
                            [112.991715, 28.197861],
                            [112.987316, 28.198056],
                            [112.984336, 28.198447],
                            [112.981782, 28.199432],
                            [112.979157, 28.200115],
                            [112.976012, 28.200579],
                            [112.976343, 28.195305],
                            [112.976343, 28.188345],
                            [112.978921, 28.188247],
                            [112.981215, 28.187701],
                            [112.986772, 28.185796]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430103,
                "name": "天心区",
                "center": [112.97307, 28.192375],
                "centroid": [112.981925, 28.064489],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430100
                },
                "subFeatureIndex": 1,
                "acroutes": [100000, 430000, 430100]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.976343, 28.195305],
                            [112.967379, 28.195622],
                            [112.967285, 28.194727],
                            [112.960544, 28.155679],
                            [112.957068, 28.147601],
                            [112.95572, 28.137665],
                            [112.95702, 28.130473],
                            [112.95676, 28.125741],
                            [112.953709, 28.114206],
                            [112.948861, 28.106744],
                            [112.938904, 28.096487],
                            [112.932495, 28.090441],
                            [112.929255, 28.084354],
                            [112.927954, 28.0735],
                            [112.92916, 28.061854],
                            [112.930887, 28.053051],
                            [112.933938, 28.047712],
                            [112.945006, 28.033291],
                            [112.960639, 28.009418],
                            [112.962696, 27.996558],
                            [112.963855, 27.995261],
                            [112.96492, 27.994087],
                            [112.97289, 27.987465],
                            [112.99207, 27.97585],
                            [112.996918, 27.972016],
                            [113.003186, 27.965156],
                            [113.006142, 27.966649],
                            [113.008412, 27.968411],
                            [113.011251, 27.971845],
                            [113.012362, 27.972604],
                            [113.019363, 27.972661],
                            [113.020758, 27.973705],
                            [113.023903, 27.976984],
                            [113.024447, 27.979382],
                            [113.02362, 27.983395],
                            [113.022863, 27.985271],
                            [113.026127, 27.985662],
                            [113.02835, 27.984577],
                            [113.030431, 27.98306],
                            [113.030265, 27.982196],
                            [113.028373, 27.980826],
                            [113.027782, 27.978477],
                            [113.029319, 27.975671],
                            [113.030715, 27.974936],
                            [113.034097, 27.975132],
                            [113.03535, 27.976127],
                            [113.035918, 27.977424],
                            [113.035918, 27.979537],
                            [113.03535, 27.981127],
                            [113.033387, 27.983892],
                            [113.03237, 27.9866],
                            [113.030053, 27.991942],
                            [113.03088, 27.994731],
                            [113.03334, 27.996754],
                            [113.035634, 27.999494],
                            [113.037526, 28.001174],
                            [113.039796, 28.004892],
                            [113.041286, 28.005904],
                            [113.037857, 28.004167],
                            [113.033908, 28.003408],
                            [113.033222, 28.004468],
                            [113.033718, 28.005985],
                            [113.033032, 28.008864],
                            [113.030478, 28.006744],
                            [113.0279, 28.007045],
                            [113.025157, 28.01038],
                            [113.022579, 28.014017],
                            [113.022579, 28.016594],
                            [113.020521, 28.01917],
                            [113.017423, 28.020083],
                            [113.016737, 28.021143],
                            [113.017068, 28.024021],
                            [113.015886, 28.024926],
                            [113.016217, 28.028261],
                            [113.01416, 28.028718],
                            [113.01416, 28.029476],
                            [113.021373, 28.028416],
                            [113.02239, 28.030234],
                            [113.023596, 28.030389],
                            [113.024471, 28.031294],
                            [113.025157, 28.033112],
                            [113.026694, 28.033715],
                            [113.027546, 28.035231],
                            [113.026339, 28.036903],
                            [113.027711, 28.036903],
                            [113.028586, 28.037962],
                            [113.032015, 28.038566],
                            [113.031495, 28.039625],
                            [113.032347, 28.041598],
                            [113.034924, 28.04296],
                            [113.035279, 28.045837],
                            [113.032867, 28.046905],
                            [113.035279, 28.049171],
                            [113.033222, 28.050385],
                            [113.035043, 28.051592],
                            [113.033245, 28.052961],
                            [113.032607, 28.054208],
                            [113.03334, 28.057159],
                            [113.032086, 28.059433],
                            [113.032559, 28.060077],
                            [113.031661, 28.061552],
                            [113.028279, 28.060688],
                            [113.025252, 28.064266],
                            [113.02317, 28.066361],
                            [113.021302, 28.067518],
                            [113.019173, 28.069694],
                            [113.015862, 28.072073],
                            [113.013947, 28.072954],
                            [113.011132, 28.072856],
                            [113.007537, 28.070183],
                            [113.005622, 28.075561],
                            [113.005267, 28.077648],
                            [113.005811, 28.078063],
                            [113.005669, 28.079734],
                            [113.004841, 28.081209],
                            [113.006047, 28.081999],
                            [113.005598, 28.082765],
                            [113.004297, 28.081852],
                            [113.002145, 28.081592],
                            [113.001057, 28.082545],
                            [113.000561, 28.082024],
                            [112.998787, 28.082977],
                            [112.998692, 28.08437],
                            [112.99803, 28.085218],
                            [112.999236, 28.087385],
                            [113.000182, 28.087874],
                            [113.00153, 28.087296],
                            [113.003044, 28.088551],
                            [112.999969, 28.095313],
                            [112.999591, 28.095216],
                            [112.999189, 28.098067],
                            [113.000537, 28.103176],
                            [113.000537, 28.104789],
                            [112.999094, 28.105318],
                            [112.996942, 28.107143],
                            [112.996493, 28.10975],
                            [112.995263, 28.109766],
                            [112.994932, 28.112284],
                            [112.996351, 28.112243],
                            [112.9968, 28.115045],
                            [112.999189, 28.115176],
                            [112.999189, 28.114736],
                            [113.001601, 28.114825],
                            [113.001365, 28.118369],
                            [113.000064, 28.120519],
                            [113.000324, 28.122906],
                            [113.001128, 28.123232],
                            [113.003824, 28.122686],
                            [113.006781, 28.123297],
                            [113.005149, 28.126205],
                            [113.004416, 28.129838],
                            [113.002571, 28.132143],
                            [113.000324, 28.137461],
                            [112.999354, 28.138659],
                            [112.995121, 28.142812],
                            [112.994151, 28.145141],
                            [112.993915, 28.14721],
                            [112.991053, 28.146607],
                            [112.986228, 28.144327],
                            [112.985259, 28.148521],
                            [112.985282, 28.150915],
                            [112.983982, 28.156282],
                            [112.983982, 28.156941],
                            [112.985495, 28.16006],
                            [112.986252, 28.163358],
                            [112.985164, 28.166965],
                            [112.985401, 28.173967],
                            [112.986583, 28.180326],
                            [112.986772, 28.185796],
                            [112.981215, 28.187701],
                            [112.978921, 28.188247],
                            [112.976343, 28.188345],
                            [112.976343, 28.195305]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430104,
                "name": "岳麓区",
                "center": [112.911591, 28.213044],
                "centroid": [112.836863, 28.131815],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430100
                },
                "subFeatureIndex": 2,
                "acroutes": [100000, 430000, 430100]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.669787, 28.205105],
                            [112.665152, 28.20368],
                            [112.66203, 28.202053],
                            [112.657087, 28.197185],
                            [112.654036, 28.196119],
                            [112.651789, 28.195638],
                            [112.647485, 28.194051],
                            [112.642802, 28.191153],
                            [112.640579, 28.188076],
                            [112.639751, 28.183883],
                            [112.638592, 28.179976],
                            [112.636961, 28.176279],
                            [112.636204, 28.175644],
                            [112.632065, 28.174684],
                            [112.630409, 28.173983],
                            [112.628352, 28.172038],
                            [112.630953, 28.169139],
                            [112.63365, 28.164099],
                            [112.633697, 28.161672],
                            [112.634123, 28.15914],
                            [112.632774, 28.152968],
                            [112.631545, 28.150011],
                            [112.631355, 28.146681],
                            [112.630835, 28.143114],
                            [112.630362, 28.137461],
                            [112.62795, 28.133764],
                            [112.623645, 28.130937],
                            [112.619909, 28.130204],
                            [112.617449, 28.12904],
                            [112.614706, 28.128209],
                            [112.613026, 28.126832],
                            [112.612861, 28.124625],
                            [112.613925, 28.121823],
                            [112.615297, 28.120373],
                            [112.616054, 28.117896],
                            [112.616243, 28.113669],
                            [112.617094, 28.113229],
                            [112.621564, 28.112561],
                            [112.623858, 28.112707],
                            [112.629913, 28.112626],
                            [112.638971, 28.113937],
                            [112.644221, 28.115208],
                            [112.648053, 28.115428],
                            [112.650559, 28.114622],
                            [112.651411, 28.114011],
                            [112.651387, 28.111534],
                            [112.651884, 28.108601],
                            [112.653445, 28.107102],
                            [112.657016, 28.106874],
                            [112.658695, 28.106337],
                            [112.661675, 28.104406],
                            [112.664064, 28.101791],
                            [112.664986, 28.099941],
                            [112.666807, 28.098556],
                            [112.668936, 28.09757],
                            [112.678869, 28.094955],
                            [112.683812, 28.094295],
                            [112.686744, 28.093496],
                            [112.688164, 28.092502],
                            [112.689866, 28.090213],
                            [112.691877, 28.085723],
                            [112.694123, 28.08446],
                            [112.698499, 28.082667],
                            [112.702921, 28.081543],
                            [112.707982, 28.080516],
                            [112.735228, 28.080361],
                            [112.739295, 28.080703],
                            [112.743671, 28.082056],
                            [112.747904, 28.084484],
                            [112.750719, 28.086717],
                            [112.756111, 28.089276],
                            [112.758452, 28.089585],
                            [112.760202, 28.088819],
                            [112.765027, 28.084867],
                            [112.771058, 28.081608],
                            [112.774416, 28.080125],
                            [112.776095, 28.078683],
                            [112.777656, 28.076662],
                            [112.779454, 28.072905],
                            [112.782717, 28.073565],
                            [112.788157, 28.072554],
                            [112.790971, 28.071234],
                            [112.794353, 28.070476],
                            [112.802134, 28.069376],
                            [112.807337, 28.070109],
                            [112.810459, 28.069294],
                            [112.81325, 28.069946],
                            [112.815639, 28.071723],
                            [112.816798, 28.071503],
                            [112.818382, 28.070395],
                            [112.818595, 28.069213],
                            [112.817933, 28.067176],
                            [112.816561, 28.064788],
                            [112.815923, 28.061511],
                            [112.816656, 28.059091],
                            [112.822119, 28.052089],
                            [112.825572, 28.044003],
                            [112.830562, 28.041117],
                            [112.831957, 28.040514],
                            [112.833542, 28.03771],
                            [112.833329, 28.034375],
                            [112.831792, 28.027364],
                            [112.832596, 28.025171],
                            [112.835741, 28.023002],
                            [112.838769, 28.021453],
                            [112.841323, 28.0212],
                            [112.842884, 28.02138],
                            [112.847377, 28.022497],
                            [112.849459, 28.022578],
                            [112.850499, 28.02129],
                            [112.852793, 28.016626],
                            [112.855655, 28.009492],
                            [112.859747, 28.004167],
                            [112.860882, 28.000685],
                            [112.860858, 27.998034],
                            [112.86126, 27.996526],
                            [112.859888, 27.993051],
                            [112.858706, 27.988859],
                            [112.858635, 27.987334],
                            [112.859345, 27.986723],
                            [112.862916, 27.986396],
                            [112.869893, 27.987987],
                            [112.871785, 27.989039],
                            [112.873014, 27.990303],
                            [112.874835, 27.993777],
                            [112.875332, 27.996305],
                            [112.874386, 27.998328],
                            [112.872305, 27.999315],
                            [112.870484, 27.999388],
                            [112.869017, 28.000644],
                            [112.86994, 28.00194],
                            [112.872825, 28.002568],
                            [112.873984, 28.004387],
                            [112.875001, 28.004672],
                            [112.877579, 28.004216],
                            [112.879471, 28.002462],
                            [112.881197, 28.001345],
                            [112.882498, 27.999233],
                            [112.882664, 27.996118],
                            [112.880866, 27.993997],
                            [112.882262, 27.992896],
                            [112.886069, 27.993312],
                            [112.887323, 27.993948],
                            [112.889262, 27.9958],
                            [112.891651, 27.995881],
                            [112.892762, 27.995351],
                            [112.893567, 27.993728],
                            [112.893472, 27.992146],
                            [112.892573, 27.989928],
                            [112.890563, 27.986502],
                            [112.886661, 27.982653],
                            [112.883752, 27.979154],
                            [112.883657, 27.978477],
                            [112.884532, 27.975834],
                            [112.885667, 27.974276],
                            [112.887843, 27.973697],
                            [112.889286, 27.974406],
                            [112.891485, 27.977155],
                            [112.894442, 27.978901],
                            [112.898438, 27.978754],
                            [112.90026, 27.978109],
                            [112.901702, 27.976723],
                            [112.902435, 27.975279],
                            [112.904351, 27.972816],
                            [112.904635, 27.97125],
                            [112.906551, 27.971062],
                            [112.914544, 27.97771],
                            [112.916507, 27.977914],
                            [112.920764, 27.976935],
                            [112.922302, 27.974969],
                            [112.924123, 27.974896],
                            [112.929208, 27.976943],
                            [112.930059, 27.977677],
                            [112.930508, 27.979863],
                            [112.93013, 27.980263],
                            [112.926913, 27.980614],
                            [112.926748, 27.982326],
                            [112.928356, 27.983574],
                            [112.932566, 27.984667],
                            [112.934647, 27.984724],
                            [112.935711, 27.985458],
                            [112.93758, 27.984904],
                            [112.937864, 27.981609],
                            [112.940371, 27.982587],
                            [112.941387, 27.984814],
                            [112.940134, 27.986274],
                            [112.940063, 27.989569],
                            [112.937627, 27.992399],
                            [112.934955, 27.99279],
                            [112.933181, 27.992195],
                            [112.929491, 27.991779],
                            [112.928049, 27.9931],
                            [112.929349, 27.994992],
                            [112.931549, 27.996501],
                            [112.933323, 27.998116],
                            [112.934505, 27.999943],
                            [112.935853, 28.003963],
                            [112.935593, 28.008644],
                            [112.933725, 28.013585],
                            [112.933441, 28.015273],
                            [112.934387, 28.016357],
                            [112.93862, 28.019227],
                            [112.939756, 28.019187],
                            [112.943043, 28.017026],
                            [112.945692, 28.014751],
                            [112.949027, 28.010886],
                            [112.952834, 28.003612],
                            [112.955176, 28.000399],
                            [112.95851, 27.997749],
                            [112.961727, 27.995645],
                            [112.963855, 27.995261],
                            [112.962696, 27.996558],
                            [112.960639, 28.009418],
                            [112.945006, 28.033291],
                            [112.933938, 28.047712],
                            [112.930887, 28.053051],
                            [112.92916, 28.061854],
                            [112.927954, 28.0735],
                            [112.929255, 28.084354],
                            [112.932495, 28.090441],
                            [112.938904, 28.096487],
                            [112.948861, 28.106744],
                            [112.953709, 28.114206],
                            [112.95676, 28.125741],
                            [112.95702, 28.130473],
                            [112.95572, 28.137665],
                            [112.957068, 28.147601],
                            [112.960544, 28.155679],
                            [112.967285, 28.194727],
                            [112.967379, 28.195622],
                            [112.971802, 28.236022],
                            [112.971423, 28.251205],
                            [112.964021, 28.271754],
                            [112.9622, 28.275308],
                            [112.961467, 28.273137],
                            [112.95501, 28.271079],
                            [112.953733, 28.271282],
                            [112.953189, 28.26946],
                            [112.951604, 28.27055],
                            [112.950966, 28.269134],
                            [112.951888, 28.268752],
                            [112.950919, 28.268183],
                            [112.950091, 28.266385],
                            [112.950327, 28.265523],
                            [112.951533, 28.265515],
                            [112.950966, 28.264368],
                            [112.948885, 28.264319],
                            [112.947016, 28.265035],
                            [112.945361, 28.264986],
                            [112.943965, 28.264148],
                            [112.941884, 28.264197],
                            [112.940678, 28.265067],
                            [112.939259, 28.265344],
                            [112.938928, 28.26667],
                            [112.936184, 28.2661],
                            [112.936208, 28.265165],
                            [112.933796, 28.264506],
                            [112.934009, 28.267898],
                            [112.932826, 28.267825],
                            [112.932353, 28.268793],
                            [112.930745, 28.269175],
                            [112.93013, 28.271176],
                            [112.927386, 28.272396],
                            [112.926062, 28.273454],
                            [112.923413, 28.272323],
                            [112.92041, 28.272689],
                            [112.918163, 28.270249],
                            [112.917453, 28.269964],
                            [112.917075, 28.268516],
                            [112.914402, 28.266108],
                            [112.910642, 28.265905],
                            [112.904871, 28.261748],
                            [112.904044, 28.258632],
                            [112.901702, 28.258958],
                            [112.898959, 28.258876],
                            [112.89657, 28.260064],
                            [112.894938, 28.258087],
                            [112.892857, 28.258681],
                            [112.890989, 28.257469],
                            [112.888624, 28.256867],
                            [112.887417, 28.25594],
                            [112.885242, 28.256721],
                            [112.88465, 28.256159],
                            [112.882498, 28.256517],
                            [112.881599, 28.25729],
                            [112.879163, 28.256371],
                            [112.878359, 28.256745],
                            [112.877603, 28.25572],
                            [112.876538, 28.255638],
                            [112.875261, 28.254321],
                            [112.874197, 28.255638],
                            [112.873085, 28.255817],
                            [112.871453, 28.255134],
                            [112.870082, 28.25598],
                            [112.868734, 28.255793],
                            [112.868095, 28.256655],
                            [112.863365, 28.254003],
                            [112.861189, 28.253377],
                            [112.861875, 28.252254],
                            [112.861047, 28.25105],
                            [112.859534, 28.251579],
                            [112.858446, 28.251123],
                            [112.858209, 28.252335],
                            [112.856577, 28.251351],
                            [112.856601, 28.253474],
                            [112.85556, 28.253694],
                            [112.854354, 28.253043],
                            [112.852912, 28.253108],
                            [112.850428, 28.254426],
                            [112.850381, 28.250798],
                            [112.849435, 28.249797],
                            [112.849601, 28.249008],
                            [112.846431, 28.247698],
                            [112.845533, 28.249284],
                            [112.84383, 28.24887],
                            [112.841559, 28.249219],
                            [112.840732, 28.248015],
                            [112.837799, 28.246453],
                            [112.834512, 28.247389],
                            [112.83288, 28.247307],
                            [112.829829, 28.24542],
                            [112.826069, 28.245916],
                            [112.826329, 28.247202],
                            [112.825359, 28.248333],
                            [112.82245, 28.248487],
                            [112.822355, 28.250253],
                            [112.820771, 28.250611],
                            [112.818997, 28.252693],
                            [112.81772, 28.253247],
                            [112.816798, 28.252189],
                            [112.814669, 28.252295],
                            [112.814172, 28.251408],
                            [112.812375, 28.250285],
                            [112.807645, 28.25118],
                            [112.806391, 28.250708],
                            [112.806581, 28.249773],
                            [112.805824, 28.247202],
                            [112.80457, 28.247104],
                            [112.805185, 28.246046],
                            [112.803506, 28.246591],
                            [112.802513, 28.247633],
                            [112.800503, 28.245778],
                            [112.79958, 28.245705],
                            [112.798776, 28.243792],
                            [112.799698, 28.243101],
                            [112.800715, 28.241091],
                            [112.803222, 28.240212],
                            [112.803885, 28.239073],
                            [112.802868, 28.238927],
                            [112.803624, 28.238105],
                            [112.805374, 28.238561],
                            [112.805942, 28.236974],
                            [112.805942, 28.234606],
                            [112.808544, 28.232311],
                            [112.809064, 28.230399],
                            [112.810625, 28.231563],
                            [112.812328, 28.230171],
                            [112.812777, 28.232092],
                            [112.813889, 28.230505],
                            [112.814527, 28.227217],
                            [112.815899, 28.225427],
                            [112.816869, 28.224808],
                            [112.817413, 28.225712],
                            [112.818027, 28.224955],
                            [112.819541, 28.225769],
                            [112.820369, 28.227527],
                            [112.821693, 28.228755],
                            [112.821764, 28.230155],
                            [112.823633, 28.230057],
                            [112.824981, 28.228983],
                            [112.825927, 28.229496],
                            [112.82718, 28.2283],
                            [112.827535, 28.225557],
                            [112.828457, 28.225972],
                            [112.828859, 28.224906],
                            [112.829356, 28.22598],
                            [112.831508, 28.227136],
                            [112.832194, 28.226119],
                            [112.831745, 28.224092],
                            [112.832998, 28.223344],
                            [112.83314, 28.222164],
                            [112.834535, 28.222058],
                            [112.835954, 28.220984],
                            [112.83709, 28.219079],
                            [112.838343, 28.219258],
                            [112.839313, 28.218567],
                            [112.840519, 28.21606],
                            [112.841536, 28.212577],
                            [112.841725, 28.209712],
                            [112.842458, 28.206342],
                            [112.845627, 28.207294],
                            [112.848418, 28.208597],
                            [112.850807, 28.209329],
                            [112.852817, 28.206659],
                            [112.854047, 28.206],
                            [112.854851, 28.2032],
                            [112.85478, 28.202142],
                            [112.85329, 28.201694],
                            [112.857594, 28.20062],
                            [112.85776, 28.199358],
                            [112.859912, 28.198178],
                            [112.859605, 28.196599],
                            [112.858328, 28.19642],
                            [112.855986, 28.194108],
                            [112.856199, 28.193384],
                            [112.858044, 28.192822],
                            [112.859061, 28.193025],
                            [112.859179, 28.192122],
                            [112.860361, 28.192179],
                            [112.861544, 28.190966],
                            [112.861804, 28.189207],
                            [112.861331, 28.188182],
                            [112.863223, 28.186106],
                            [112.861993, 28.186195],
                            [112.859865, 28.185438],
                            [112.851847, 28.17803],
                            [112.844752, 28.175856],
                            [112.844256, 28.17457],
                            [112.846763, 28.173438],
                            [112.84584, 28.172477],
                            [112.846668, 28.171232],
                            [112.845793, 28.170271],
                            [112.848607, 28.169554],
                            [112.849955, 28.169994],
                            [112.85102, 28.169343],
                            [112.851398, 28.168129],
                            [112.854023, 28.168756],
                            [112.855702, 28.168756],
                            [112.856838, 28.1676],
                            [112.856979, 28.165459],
                            [112.858044, 28.165011],
                            [112.855608, 28.16427],
                            [112.856696, 28.162625],
                            [112.852959, 28.159287],
                            [112.846219, 28.155744],
                            [112.84506, 28.151347],
                            [112.842789, 28.150223],
                            [112.841678, 28.151567],
                            [112.840661, 28.150052],
                            [112.839171, 28.151323],
                            [112.83884, 28.153041],
                            [112.836356, 28.153725],
                            [112.834275, 28.15335],
                            [112.834937, 28.154311],
                            [112.833116, 28.157357],
                            [112.832454, 28.157609],
                            [112.832005, 28.155899],
                            [112.830349, 28.154824],
                            [112.828859, 28.155679],
                            [112.827961, 28.157283],
                            [112.827866, 28.159254],
                            [112.826139, 28.158586],
                            [112.825903, 28.157568],
                            [112.823727, 28.155622],
                            [112.822781, 28.153497],
                            [112.820061, 28.153823],
                            [112.818879, 28.154938],
                            [112.816372, 28.155353],
                            [112.814835, 28.154735],
                            [112.814314, 28.153823],
                            [112.814764, 28.151152],
                            [112.814007, 28.149694],
                            [112.813061, 28.149393],
                            [112.810578, 28.146461],
                            [112.808662, 28.146917],
                            [112.807858, 28.14462],
                            [112.808591, 28.142405],
                            [112.806084, 28.142258],
                            [112.803577, 28.140858],
                            [112.80081, 28.140174],
                            [112.797688, 28.138146],
                            [112.797097, 28.139115],
                            [112.794141, 28.140076],
                            [112.793242, 28.14225],
                            [112.791799, 28.143838],
                            [112.79038, 28.144311],
                            [112.788796, 28.14598],
                            [112.789269, 28.148969],
                            [112.788819, 28.150101],
                            [112.788677, 28.152674],
                            [112.787045, 28.155443],
                            [112.784089, 28.157617],
                            [112.781464, 28.158318],
                            [112.781109, 28.160304],
                            [112.77943, 28.161852],
                            [112.777041, 28.162006],
                            [112.777136, 28.163741],
                            [112.775339, 28.164311],
                            [112.772193, 28.163822],
                            [112.773565, 28.162422],
                            [112.772453, 28.161664],
                            [112.770396, 28.162495],
                            [112.768598, 28.161436],
                            [112.766612, 28.161062],
                            [112.764767, 28.162536],
                            [112.764199, 28.161664],
                            [112.763419, 28.16348],
                            [112.761858, 28.164254],
                            [112.762473, 28.166452],
                            [112.76155, 28.16747],
                            [112.759091, 28.167413],
                            [112.758003, 28.166281],
                            [112.757317, 28.164425],
                            [112.755118, 28.163366],
                            [112.752019, 28.163179],
                            [112.746627, 28.165198],
                            [112.746249, 28.165882],
                            [112.744404, 28.166297],
                            [112.743836, 28.167169],
                            [112.741637, 28.168414],
                            [112.742299, 28.170157],
                            [112.740785, 28.171671],
                            [112.742583, 28.171826],
                            [112.743836, 28.170873],
                            [112.744901, 28.171744],
                            [112.742985, 28.172958],
                            [112.742039, 28.172844],
                            [112.739769, 28.17553],
                            [112.738515, 28.175758],
                            [112.737356, 28.174846],
                            [112.735937, 28.175001],
                            [112.73563, 28.17606],
                            [112.7344, 28.177159],
                            [112.730616, 28.177761],
                            [112.727234, 28.175229],
                            [112.724821, 28.174732],
                            [112.722764, 28.172542],
                            [112.719169, 28.171484],
                            [112.717277, 28.170206],
                            [112.715905, 28.168195],
                            [112.714652, 28.167405],
                            [112.712594, 28.167437],
                            [112.711696, 28.16922],
                            [112.709804, 28.17194],
                            [112.707486, 28.172282],
                            [112.70758, 28.172966],
                            [112.705925, 28.175636],
                            [112.706067, 28.178526],
                            [112.705003, 28.180122],
                            [112.701952, 28.182011],
                            [112.699799, 28.183069],
                            [112.695046, 28.184095],
                            [112.691404, 28.183484],
                            [112.688187, 28.183224],
                            [112.687738, 28.184233],
                            [112.686366, 28.185235],
                            [112.682204, 28.182247],
                            [112.682156, 28.183314],
                            [112.680879, 28.184673],
                            [112.678727, 28.185503],
                            [112.677852, 28.187034],
                            [112.676409, 28.187115],
                            [112.676622, 28.188418],
                            [112.671774, 28.190078],
                            [112.672247, 28.193131],
                            [112.671537, 28.194059],
                            [112.671466, 28.195817],
                            [112.670828, 28.196648],
                            [112.67071, 28.198162],
                            [112.667469, 28.198561],
                            [112.667446, 28.200237],
                            [112.666642, 28.200425],
                            [112.668392, 28.202468],
                            [112.669787, 28.205105]
                        ]
                    ],
                    [
                        [
                            [112.78468, 28.051127],
                            [112.783593, 28.048405],
                            [112.781464, 28.046514],
                            [112.780565, 28.046391],
                            [112.777491, 28.04856],
                            [112.77541, 28.050451],
                            [112.7747, 28.050557],
                            [112.773068, 28.049244],
                            [112.773021, 28.045006],
                            [112.772595, 28.043253],
                            [112.771389, 28.043008],
                            [112.76874, 28.043204],
                            [112.767274, 28.042796],
                            [112.766446, 28.041484],
                            [112.76673, 28.039625],
                            [112.768835, 28.037286],
                            [112.771791, 28.035346],
                            [112.772595, 28.036193],
                            [112.776994, 28.03696],
                            [112.779832, 28.035109],
                            [112.782978, 28.033943],
                            [112.784373, 28.033919],
                            [112.787448, 28.034538],
                            [112.788701, 28.033674],
                            [112.790475, 28.031767],
                            [112.790286, 28.027625],
                            [112.790475, 28.025269],
                            [112.79161, 28.02394],
                            [112.792461, 28.023736],
                            [112.795229, 28.024168],
                            [112.797783, 28.025163],
                            [112.802749, 28.025187],
                            [112.804239, 28.025636],
                            [112.807976, 28.033014],
                            [112.808662, 28.036862],
                            [112.807314, 28.038574],
                            [112.804381, 28.040212],
                            [112.8023, 28.042691],
                            [112.800503, 28.044362],
                            [112.794401, 28.048242],
                            [112.792603, 28.048959],
                            [112.791799, 28.049766],
                            [112.786029, 28.050296],
                            [112.78468, 28.051127]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430105,
                "name": "开福区",
                "center": [112.985525, 28.201336],
                "centroid": [113.001265, 28.302385],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430100
                },
                "subFeatureIndex": 3,
                "acroutes": [100000, 430000, 430100]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.978495, 28.390879],
                            [112.977904, 28.389449],
                            [112.97814, 28.38641],
                            [112.972346, 28.383395],
                            [112.968254, 28.382493],
                            [112.964092, 28.379276],
                            [112.962365, 28.37852],
                            [112.95948, 28.378821],
                            [112.958014, 28.37852],
                            [112.956926, 28.377658],
                            [112.956453, 28.375505],
                            [112.956831, 28.371109],
                            [112.956311, 28.368053],
                            [112.954277, 28.362665],
                            [112.951936, 28.357219],
                            [112.951865, 28.354521],
                            [112.95099, 28.353407],
                            [112.9438, 28.350205],
                            [112.920102, 28.343661],
                            [112.907568, 28.336825],
                            [112.915065, 28.329443],
                            [112.925613, 28.319963],
                            [112.938171, 28.308514],
                            [112.949168, 28.296674],
                            [112.960048, 28.279465],
                            [112.9622, 28.275308],
                            [112.964021, 28.271754],
                            [112.971423, 28.251205],
                            [112.971802, 28.236022],
                            [112.967379, 28.195622],
                            [112.976343, 28.195305],
                            [112.976012, 28.200579],
                            [112.979157, 28.200115],
                            [112.981782, 28.199432],
                            [112.984336, 28.198447],
                            [112.987316, 28.198056],
                            [112.991715, 28.197861],
                            [112.991834, 28.200832],
                            [112.992732, 28.203973],
                            [112.994766, 28.205512],
                            [112.997628, 28.205772],
                            [113.000655, 28.204926],
                            [113.004652, 28.205235],
                            [113.006852, 28.206008],
                            [113.006875, 28.208035],
                            [113.007869, 28.208824],
                            [113.00723, 28.209622],
                            [113.005503, 28.209191],
                            [113.004463, 28.210753],
                            [113.002074, 28.211982],
                            [113.001412, 28.213521],
                            [113.003233, 28.215067],
                            [113.005835, 28.218233],
                            [113.008223, 28.218884],
                            [113.010565, 28.216768],
                            [113.011936, 28.216548],
                            [113.013592, 28.216906],
                            [113.019457, 28.21868],
                            [113.024045, 28.219266],
                            [113.027924, 28.218607],
                            [113.032867, 28.217199],
                            [113.040766, 28.216565],
                            [113.045473, 28.217403],
                            [113.057085, 28.223091],
                            [113.055784, 28.227331],
                            [113.055713, 28.228397],
                            [113.057061, 28.2307],
                            [113.05848, 28.230725],
                            [113.059237, 28.237259],
                            [113.059426, 28.240953],
                            [113.058977, 28.245607],
                            [113.057818, 28.245656],
                            [113.054625, 28.247519],
                            [113.053395, 28.247844],
                            [113.052993, 28.24891],
                            [113.053703, 28.252417],
                            [113.053726, 28.255915],
                            [113.052118, 28.256851],
                            [113.05103, 28.256729],
                            [113.043084, 28.253409],
                            [113.041286, 28.253157],
                            [113.039749, 28.253645],
                            [113.038117, 28.25633],
                            [113.035705, 28.258787],
                            [113.034357, 28.25956],
                            [113.029934, 28.262993],
                            [113.030029, 28.265816],
                            [113.031069, 28.269061],
                            [113.035776, 28.276748],
                            [113.038259, 28.282092],
                            [113.041192, 28.286224],
                            [113.042989, 28.28772],
                            [113.046206, 28.289436],
                            [113.050534, 28.289924],
                            [113.052946, 28.29077],
                            [113.054294, 28.291608],
                            [113.055619, 28.293617],
                            [113.056801, 28.294064],
                            [113.059308, 28.293795],
                            [113.060703, 28.29434],
                            [113.063163, 28.296935],
                            [113.063329, 28.297943],
                            [113.062217, 28.299334],
                            [113.060088, 28.300708],
                            [113.057345, 28.303286],
                            [113.057274, 28.304514],
                            [113.058244, 28.305994],
                            [113.058977, 28.305888],
                            [113.059119, 28.304164],
                            [113.06068, 28.303595],
                            [113.061389, 28.304815],
                            [113.061389, 28.306108],
                            [113.060301, 28.310198],
                            [113.060893, 28.31119],
                            [113.064014, 28.312043],
                            [113.062856, 28.314076],
                            [113.059923, 28.316109],
                            [113.059261, 28.320109],
                            [113.060136, 28.321614],
                            [113.062643, 28.321979],
                            [113.066711, 28.323939],
                            [113.06969, 28.325939],
                            [113.070778, 28.327849],
                            [113.071133, 28.330898],
                            [113.07215, 28.33315],
                            [113.073333, 28.333906],
                            [113.07513, 28.333947],
                            [113.075887, 28.334849],
                            [113.075981, 28.336426],
                            [113.077022, 28.3378],
                            [113.077566, 28.339979],
                            [113.076313, 28.342092],
                            [113.076857, 28.344035],
                            [113.079884, 28.343702],
                            [113.081705, 28.345019],
                            [113.082178, 28.346498],
                            [113.081705, 28.348717],
                            [113.080002, 28.350001],
                            [113.079647, 28.351123],
                            [113.081752, 28.353732],
                            [113.078583, 28.356423],
                            [113.074586, 28.356845],
                            [113.068673, 28.354708],
                            [113.063447, 28.355146],
                            [113.055737, 28.358585],
                            [113.043888, 28.36503],
                            [113.039607, 28.371564],
                            [113.036296, 28.374384],
                            [113.031732, 28.37648],
                            [113.016808, 28.376651],
                            [113.007987, 28.376943],
                            [113.000442, 28.377585],
                            [112.991668, 28.380283],
                            [112.978495, 28.390879]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430111,
                "name": "雨花区",
                "center": [113.016337, 28.109937],
                "centroid": [113.083726, 28.06293],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430100
                },
                "subFeatureIndex": 4,
                "acroutes": [100000, 430000, 430100]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [113.041286, 28.005904],
                            [113.041688, 28.006181],
                            [113.047719, 28.007551],
                            [113.068224, 28.010731],
                            [113.071724, 28.010886],
                            [113.07435, 28.009891],
                            [113.07584, 28.008603],
                            [113.076738, 28.006271],
                            [113.080239, 28.002307],
                            [113.084945, 27.998352],
                            [113.086979, 27.996852],
                            [113.088067, 27.993728],
                            [113.088918, 27.992162],
                            [113.090952, 27.989887],
                            [113.090314, 27.987391],
                            [113.086837, 27.983264],
                            [113.084354, 27.980736],
                            [113.082272, 27.97917],
                            [113.077022, 27.975622],
                            [113.075272, 27.974015],
                            [113.073711, 27.972008],
                            [113.073569, 27.970426],
                            [113.074823, 27.968925],
                            [113.078701, 27.968884],
                            [113.080688, 27.96797],
                            [113.080806, 27.965996],
                            [113.08232, 27.964275],
                            [113.085371, 27.963826],
                            [113.089178, 27.964968],
                            [113.090952, 27.965115],
                            [113.091283, 27.964609],
                            [113.090905, 27.962407],
                            [113.088516, 27.955415],
                            [113.087996, 27.953433],
                            [113.086671, 27.950112],
                            [113.087759, 27.947102],
                            [113.093128, 27.940656],
                            [113.095398, 27.938559],
                            [113.098946, 27.936021],
                            [113.101808, 27.933704],
                            [113.103345, 27.933451],
                            [113.107744, 27.933533],
                            [113.108619, 27.933043],
                            [113.11141, 27.928008],
                            [113.114058, 27.926205],
                            [113.118197, 27.920451],
                            [113.118623, 27.918207],
                            [113.120208, 27.915808],
                            [113.1221, 27.914314],
                            [113.127515, 27.912886],
                            [113.130448, 27.912706],
                            [113.13279, 27.912861],
                            [113.135769, 27.913694],
                            [113.136621, 27.915008],
                            [113.136834, 27.916648],
                            [113.13681, 27.920933],
                            [113.139388, 27.927527],
                            [113.13707, 27.928898],
                            [113.134138, 27.929754],
                            [113.132955, 27.930579],
                            [113.132742, 27.931762],
                            [113.132908, 27.935605],
                            [113.130637, 27.937988],
                            [113.129904, 27.940011],
                            [113.131039, 27.942231],
                            [113.132435, 27.942704],
                            [113.136503, 27.943055],
                            [113.13856, 27.944442],
                            [113.138844, 27.94569],
                            [113.138158, 27.947657],
                            [113.136503, 27.950847],
                            [113.134965, 27.952511],
                            [113.135486, 27.953441],
                            [113.140594, 27.952992],
                            [113.14653, 27.953882],
                            [113.148044, 27.954869],
                            [113.149723, 27.956533],
                            [113.151308, 27.959152],
                            [113.152798, 27.963019],
                            [113.155186, 27.964797],
                            [113.15871, 27.96638],
                            [113.158923, 27.968753],
                            [113.156487, 27.971763],
                            [113.153649, 27.973126],
                            [113.153081, 27.973795],
                            [113.152301, 27.977171],
                            [113.151686, 27.978738],
                            [113.149534, 27.98085],
                            [113.149368, 27.982596],
                            [113.14977, 27.984349],
                            [113.151166, 27.987367],
                            [113.151, 27.988664],
                            [113.148635, 27.992293],
                            [113.149439, 27.993435],
                            [113.151331, 27.993973],
                            [113.157126, 27.99646],
                            [113.160271, 27.998205],
                            [113.160697, 28.000073],
                            [113.162021, 28.000309],
                            [113.163275, 27.999094],
                            [113.162092, 27.997284],
                            [113.163606, 27.99611],
                            [113.166893, 27.995457],
                            [113.168076, 27.996256],
                            [113.168525, 27.998597],
                            [113.170654, 27.999894],
                            [113.170394, 28.000856],
                            [113.169069, 28.001451],
                            [113.165214, 28.004371],
                            [113.164103, 28.006727],
                            [113.164836, 28.007567],
                            [113.166373, 28.011228],
                            [113.167177, 28.012313],
                            [113.169944, 28.014082],
                            [113.175833, 28.015028],
                            [113.177228, 28.01493],
                            [113.181036, 28.019015],
                            [113.184607, 28.025089],
                            [113.185388, 28.029084],
                            [113.185341, 28.031514],
                            [113.184536, 28.037555],
                            [113.184229, 28.040995],
                            [113.18527, 28.047304],
                            [113.186736, 28.051135],
                            [113.186381, 28.053393],
                            [113.184915, 28.05464],
                            [113.183543, 28.056719],
                            [113.174319, 28.056279],
                            [113.173634, 28.057648],
                            [113.17536, 28.062343],
                            [113.173634, 28.068854],
                            [113.168501, 28.074909],
                            [113.165734, 28.078544],
                            [113.161288, 28.082333],
                            [113.155447, 28.089602],
                            [113.152183, 28.089455],
                            [113.13707, 28.091875],
                            [113.126262, 28.088844],
                            [113.117346, 28.089145],
                            [113.103605, 28.098385],
                            [113.089533, 28.113685],
                            [113.086577, 28.12302],
                            [113.084992, 28.126075],
                            [113.079742, 28.131516],
                            [113.077235, 28.134383],
                            [113.075367, 28.137616],
                            [113.074799, 28.14067],
                            [113.074799, 28.144799],
                            [113.07539, 28.150125],
                            [113.076289, 28.153855],
                            [113.077495, 28.157422],
                            [113.080097, 28.161379],
                            [113.083408, 28.164653],
                            [113.089793, 28.169367],
                            [113.092844, 28.170881],
                            [113.095446, 28.171272],
                            [113.09878, 28.171223],
                            [113.102257, 28.169953],
                            [113.106254, 28.168032],
                            [113.108832, 28.16716],
                            [113.112261, 28.167494],
                            [113.113467, 28.168056],
                            [113.11491, 28.16944],
                            [113.11517, 28.171785],
                            [113.114863, 28.172721],
                            [113.111149, 28.179308],
                            [113.109683, 28.181042],
                            [113.107176, 28.182377],
                            [113.104788, 28.18228],
                            [113.101003, 28.180839],
                            [113.095375, 28.177297],
                            [113.091733, 28.175425],
                            [113.086955, 28.173772],
                            [113.083147, 28.17299],
                            [113.080286, 28.173161],
                            [113.073356, 28.174553],
                            [113.068484, 28.17711],
                            [113.065031, 28.180122],
                            [113.055169, 28.18679],
                            [113.050747, 28.189655],
                            [113.049588, 28.188174],
                            [113.047436, 28.188402],
                            [113.047104, 28.18727],
                            [113.044266, 28.184722],
                            [113.043675, 28.183183],
                            [113.043864, 28.181392],
                            [113.045165, 28.178535],
                            [113.044905, 28.177224],
                            [113.043888, 28.176108],
                            [113.042351, 28.175473],
                            [113.040861, 28.175539],
                            [113.038638, 28.176255],
                            [113.036958, 28.17943],
                            [113.036107, 28.180375],
                            [113.034404, 28.18044],
                            [113.03431, 28.182271],
                            [113.030289, 28.182084],
                            [113.03036, 28.184185],
                            [113.011298, 28.185039],
                            [113.002405, 28.185292],
                            [112.994175, 28.185927],
                            [112.986772, 28.185796],
                            [112.986583, 28.180326],
                            [112.985401, 28.173967],
                            [112.985164, 28.166965],
                            [112.986252, 28.163358],
                            [112.985495, 28.16006],
                            [112.983982, 28.156941],
                            [112.983982, 28.156282],
                            [112.985282, 28.150915],
                            [112.985259, 28.148521],
                            [112.986228, 28.144327],
                            [112.991053, 28.146607],
                            [112.993915, 28.14721],
                            [112.994151, 28.145141],
                            [112.995121, 28.142812],
                            [112.999354, 28.138659],
                            [113.000324, 28.137461],
                            [113.002571, 28.132143],
                            [113.004416, 28.129838],
                            [113.005149, 28.126205],
                            [113.006781, 28.123297],
                            [113.003824, 28.122686],
                            [113.001128, 28.123232],
                            [113.000324, 28.122906],
                            [113.000064, 28.120519],
                            [113.001365, 28.118369],
                            [113.001601, 28.114825],
                            [112.999189, 28.114736],
                            [112.999189, 28.115176],
                            [112.9968, 28.115045],
                            [112.996351, 28.112243],
                            [112.994932, 28.112284],
                            [112.995263, 28.109766],
                            [112.996493, 28.10975],
                            [112.996942, 28.107143],
                            [112.999094, 28.105318],
                            [113.000537, 28.104789],
                            [113.000537, 28.103176],
                            [112.999189, 28.098067],
                            [112.999591, 28.095216],
                            [112.999969, 28.095313],
                            [113.003044, 28.088551],
                            [113.00153, 28.087296],
                            [113.000182, 28.087874],
                            [112.999236, 28.087385],
                            [112.99803, 28.085218],
                            [112.998692, 28.08437],
                            [112.998787, 28.082977],
                            [113.000561, 28.082024],
                            [113.001057, 28.082545],
                            [113.002145, 28.081592],
                            [113.004297, 28.081852],
                            [113.005598, 28.082765],
                            [113.006047, 28.081999],
                            [113.004841, 28.081209],
                            [113.005669, 28.079734],
                            [113.005811, 28.078063],
                            [113.005267, 28.077648],
                            [113.005622, 28.075561],
                            [113.007537, 28.070183],
                            [113.011132, 28.072856],
                            [113.013947, 28.072954],
                            [113.015862, 28.072073],
                            [113.019173, 28.069694],
                            [113.021302, 28.067518],
                            [113.02317, 28.066361],
                            [113.025252, 28.064266],
                            [113.028279, 28.060688],
                            [113.031661, 28.061552],
                            [113.032559, 28.060077],
                            [113.032086, 28.059433],
                            [113.03334, 28.057159],
                            [113.032607, 28.054208],
                            [113.033245, 28.052961],
                            [113.035043, 28.051592],
                            [113.033222, 28.050385],
                            [113.035279, 28.049171],
                            [113.032867, 28.046905],
                            [113.035279, 28.045837],
                            [113.034924, 28.04296],
                            [113.032347, 28.041598],
                            [113.031495, 28.039625],
                            [113.032015, 28.038566],
                            [113.028586, 28.037962],
                            [113.027711, 28.036903],
                            [113.026339, 28.036903],
                            [113.027546, 28.035231],
                            [113.026694, 28.033715],
                            [113.025157, 28.033112],
                            [113.024471, 28.031294],
                            [113.023596, 28.030389],
                            [113.02239, 28.030234],
                            [113.021373, 28.028416],
                            [113.01416, 28.029476],
                            [113.01416, 28.028718],
                            [113.016217, 28.028261],
                            [113.015886, 28.024926],
                            [113.017068, 28.024021],
                            [113.016737, 28.021143],
                            [113.017423, 28.020083],
                            [113.020521, 28.01917],
                            [113.022579, 28.016594],
                            [113.022579, 28.014017],
                            [113.025157, 28.01038],
                            [113.0279, 28.007045],
                            [113.030478, 28.006744],
                            [113.033032, 28.008864],
                            [113.033718, 28.005985],
                            [113.033222, 28.004468],
                            [113.033908, 28.003408],
                            [113.037857, 28.004167],
                            [113.041286, 28.005904]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430112,
                "name": "望城区",
                "center": [112.819549, 28.347458],
                "centroid": [112.821335, 28.368695],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430100
                },
                "subFeatureIndex": 5,
                "acroutes": [100000, 430000, 430100]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.9622, 28.275308],
                            [112.960048, 28.279465],
                            [112.949168, 28.296674],
                            [112.938171, 28.308514],
                            [112.925613, 28.319963],
                            [112.915065, 28.329443],
                            [112.907568, 28.336825],
                            [112.920102, 28.343661],
                            [112.9438, 28.350205],
                            [112.95099, 28.353407],
                            [112.951865, 28.354521],
                            [112.951936, 28.357219],
                            [112.954277, 28.362665],
                            [112.956311, 28.368053],
                            [112.956831, 28.371109],
                            [112.956453, 28.375505],
                            [112.956926, 28.377658],
                            [112.958014, 28.37852],
                            [112.95948, 28.378821],
                            [112.962365, 28.37852],
                            [112.964092, 28.379276],
                            [112.968254, 28.382493],
                            [112.972346, 28.383395],
                            [112.97814, 28.38641],
                            [112.977904, 28.389449],
                            [112.978495, 28.390879],
                            [112.981144, 28.3917],
                            [112.98358, 28.39122],
                            [112.986134, 28.392276],
                            [112.99032, 28.39538],
                            [112.991242, 28.397428],
                            [112.990698, 28.398825],
                            [112.990225, 28.402034],
                            [112.988996, 28.403147],
                            [112.986158, 28.404017],
                            [112.984928, 28.405065],
                            [112.985282, 28.406657],
                            [112.987506, 28.407932],
                            [112.99084, 28.411247],
                            [112.992567, 28.411889],
                            [112.993418, 28.410605],
                            [112.994506, 28.41028],
                            [112.996753, 28.411604],
                            [113.000206, 28.41171],
                            [113.00328, 28.411247],
                            [113.005456, 28.411621],
                            [113.009027, 28.413806],
                            [113.012338, 28.416113],
                            [113.017565, 28.421092],
                            [113.021231, 28.424236],
                            [113.02362, 28.428159],
                            [113.025015, 28.432642],
                            [113.025086, 28.43598],
                            [113.024542, 28.437321],
                            [113.022697, 28.439676],
                            [113.022745, 28.441844],
                            [113.024045, 28.450672],
                            [113.024164, 28.45634],
                            [113.025157, 28.460408],
                            [113.027687, 28.463225],
                            [113.028208, 28.466449],
                            [113.02965, 28.469737],
                            [113.031235, 28.471288],
                            [113.032489, 28.471978],
                            [113.035634, 28.472473],
                            [113.044858, 28.473042],
                            [113.047412, 28.473805],
                            [113.048405, 28.475575],
                            [113.049682, 28.476995],
                            [113.049115, 28.479139],
                            [113.044929, 28.482248],
                            [113.042469, 28.483685],
                            [113.041428, 28.484911],
                            [113.036911, 28.486266],
                            [113.033884, 28.486112],
                            [113.032063, 28.485463],
                            [113.027782, 28.483206],
                            [113.024305, 28.480511],
                            [113.022272, 28.478505],
                            [113.021018, 28.476849],
                            [113.019031, 28.473025],
                            [113.016217, 28.470898],
                            [113.009973, 28.467975],
                            [113.003209, 28.465661],
                            [112.998172, 28.463786],
                            [112.995499, 28.462397],
                            [112.991857, 28.460936],
                            [112.988073, 28.460318],
                            [112.986087, 28.460854],
                            [112.984928, 28.461804],
                            [112.982563, 28.46476],
                            [112.979677, 28.465004],
                            [112.977123, 28.46463],
                            [112.974025, 28.465263],
                            [112.970548, 28.466968],
                            [112.969248, 28.468024],
                            [112.964754, 28.472985],
                            [112.962342, 28.475104],
                            [112.960781, 28.47706],
                            [112.960686, 28.478635],
                            [112.961892, 28.480064],
                            [112.963477, 28.480649],
                            [112.966102, 28.482264],
                            [112.967285, 28.483409],
                            [112.968349, 28.485568],
                            [112.969058, 28.489643],
                            [112.97069, 28.491811],
                            [112.974687, 28.493954],
                            [112.976721, 28.495845],
                            [112.977738, 28.497834],
                            [112.977502, 28.499636],
                            [112.975326, 28.503272],
                            [112.974805, 28.50479],
                            [112.9749, 28.506997],
                            [112.97639, 28.509781],
                            [112.978353, 28.512744],
                            [112.979133, 28.515243],
                            [112.978613, 28.521443],
                            [112.978637, 28.52344],
                            [112.974451, 28.520648],
                            [112.972842, 28.520461],
                            [112.965653, 28.521151],
                            [112.96123, 28.519463],
                            [112.957375, 28.517434],
                            [112.955341, 28.515941],
                            [112.95054, 28.515259],
                            [112.947537, 28.514034],
                            [112.945266, 28.511916],
                            [112.939803, 28.508515],
                            [112.937059, 28.509213],
                            [112.932424, 28.514359],
                            [112.928971, 28.517077],
                            [112.926724, 28.518465],
                            [112.923366, 28.519828],
                            [112.921261, 28.520315],
                            [112.917926, 28.519658],
                            [112.915845, 28.518449],
                            [112.912345, 28.517167],
                            [112.910098, 28.517305],
                            [112.90402, 28.516671],
                            [112.902033, 28.516712],
                            [112.898699, 28.517524],
                            [112.896003, 28.519463],
                            [112.89456, 28.521086],
                            [112.89333, 28.523221],
                            [112.89132, 28.524779],
                            [112.889168, 28.523797],
                            [112.887701, 28.522255],
                            [112.885123, 28.520648],
                            [112.882049, 28.520218],
                            [112.879755, 28.520364],
                            [112.87694, 28.519885],
                            [112.873937, 28.520023],
                            [112.871453, 28.519147],
                            [112.870484, 28.516996],
                            [112.871122, 28.512995],
                            [112.87292, 28.508442],
                            [112.873937, 28.503986],
                            [112.873535, 28.501673],
                            [112.872399, 28.500642],
                            [112.870082, 28.501072],
                            [112.867196, 28.502111],
                            [112.864997, 28.50367],
                            [112.86476, 28.506608],
                            [112.863649, 28.50772],
                            [112.860905, 28.508093],
                            [112.855442, 28.511794],
                            [112.852604, 28.512524],
                            [112.845367, 28.51065],
                            [112.841441, 28.510504],
                            [112.836285, 28.510779],
                            [112.832312, 28.512037],
                            [112.829569, 28.511729],
                            [112.826258, 28.510966],
                            [112.824153, 28.511169],
                            [112.822545, 28.512792],
                            [112.822308, 28.515608],
                            [112.822474, 28.517589],
                            [112.824366, 28.522855],
                            [112.823964, 28.52697],
                            [112.823136, 28.527984],
                            [112.821268, 28.528584],
                            [112.818902, 28.52856],
                            [112.815591, 28.527797],
                            [112.814409, 28.525744],
                            [112.812446, 28.521435],
                            [112.811311, 28.520356],
                            [112.808591, 28.519309],
                            [112.802915, 28.518627],
                            [112.798942, 28.518871],
                            [112.794897, 28.520461],
                            [112.792036, 28.523026],
                            [112.789411, 28.527968],
                            [112.788985, 28.530053],
                            [112.789127, 28.532325],
                            [112.788819, 28.535011],
                            [112.789694, 28.537453],
                            [112.790995, 28.539303],
                            [112.796931, 28.544821],
                            [112.799746, 28.548009],
                            [112.803009, 28.551319],
                            [112.805587, 28.554743],
                            [112.808023, 28.557582],
                            [112.80878, 28.559058],
                            [112.807976, 28.559894],
                            [112.806084, 28.559878],
                            [112.796435, 28.558531],
                            [112.787944, 28.554069],
                            [112.776829, 28.549072],
                            [112.77269, 28.546411],
                            [112.766706, 28.538549],
                            [112.757719, 28.529745],
                            [112.754786, 28.524202],
                            [112.753864, 28.517986],
                            [112.746178, 28.516671],
                            [112.745563, 28.515998],
                            [112.745516, 28.514294],
                            [112.746887, 28.510463],
                            [112.741661, 28.509018],
                            [112.731656, 28.507436],
                            [112.72766, 28.507793],
                            [112.724514, 28.508434],
                            [112.722102, 28.509927],
                            [112.71976, 28.51056],
                            [112.714557, 28.51065],
                            [112.71101, 28.512127],
                            [112.707628, 28.514229],
                            [112.700438, 28.519699],
                            [112.695921, 28.521792],
                            [112.688234, 28.524235],
                            [112.685396, 28.52542],
                            [112.682346, 28.525217],
                            [112.680785, 28.524681],
                            [112.678018, 28.521184],
                            [112.673737, 28.518911],
                            [112.672578, 28.517524],
                            [112.670284, 28.513377],
                            [112.663212, 28.50108],
                            [112.660942, 28.503434],
                            [112.657229, 28.498597],
                            [112.651766, 28.495658],
                            [112.652168, 28.49358],
                            [112.651316, 28.490544],
                            [112.651127, 28.488507],
                            [112.651837, 28.487833],
                            [112.659121, 28.484042],
                            [112.670639, 28.481485],
                            [112.676953, 28.477101],
                            [112.679318, 28.474803],
                            [112.682795, 28.472059],
                            [112.684403, 28.470484],
                            [112.684379, 28.468868],
                            [112.682393, 28.467748],
                            [112.673524, 28.464175],
                            [112.670142, 28.461999],
                            [112.667091, 28.458678],
                            [112.662527, 28.45729],
                            [112.65756, 28.451427],
                            [112.65659, 28.450858],
                            [112.656496, 28.449892],
                            [112.654769, 28.448122],
                            [112.654178, 28.449129],
                            [112.653185, 28.448698],
                            [112.653729, 28.447634],
                            [112.652664, 28.44817],
                            [112.653114, 28.446831],
                            [112.656023, 28.447894],
                            [112.657253, 28.447131],
                            [112.659216, 28.447042],
                            [112.658482, 28.445661],
                            [112.659003, 28.444881],
                            [112.66054, 28.444248],
                            [112.659665, 28.441308],
                            [112.659216, 28.437353],
                            [112.660114, 28.43369],
                            [112.660847, 28.432634],
                            [112.664868, 28.430604],
                            [112.672507, 28.428346],
                            [112.675936, 28.426648],
                            [112.677994, 28.425885],
                            [112.679957, 28.424163],
                            [112.682014, 28.420581],
                            [112.680832, 28.41803],
                            [112.678893, 28.415569],
                            [112.678183, 28.41262],
                            [112.678136, 28.410646],
                            [112.679507, 28.405585],
                            [112.681683, 28.402059],
                            [112.683694, 28.400393],
                            [112.686532, 28.398971],
                            [112.690268, 28.398183],
                            [112.700225, 28.397306],
                            [112.702212, 28.395868],
                            [112.702827, 28.393902],
                            [112.701408, 28.390725],
                            [112.700154, 28.38901],
                            [112.69663, 28.385914],
                            [112.696465, 28.383875],
                            [112.698026, 28.382713],
                            [112.704293, 28.383144],
                            [112.705641, 28.383103],
                            [112.70926, 28.381632],
                            [112.713209, 28.381803],
                            [112.718223, 28.382965],
                            [112.721345, 28.382851],
                            [112.722362, 28.382266],
                            [112.723828, 28.375497],
                            [112.72371, 28.374367],
                            [112.721771, 28.371856],
                            [112.716804, 28.369849],
                            [112.71373, 28.367525],
                            [112.710371, 28.36585],
                            [112.707391, 28.3649],
                            [112.70453, 28.364363],
                            [112.702661, 28.363485],
                            [112.701621, 28.361616],
                            [112.701124, 28.359056],
                            [112.702567, 28.353423],
                            [112.703631, 28.351237],
                            [112.704719, 28.350579],
                            [112.70758, 28.347855],
                            [112.708952, 28.346181],
                            [112.711483, 28.34384],
                            [112.715361, 28.338361],
                            [112.718247, 28.334678],
                            [112.719618, 28.333231],
                            [112.7217, 28.330215],
                            [112.721203, 28.329134],
                            [112.719737, 28.329223],
                            [112.716307, 28.328614],
                            [112.71626, 28.327427],
                            [112.71723, 28.323435],
                            [112.71652, 28.322126],
                            [112.714817, 28.321028],
                            [112.709118, 28.318353],
                            [112.708385, 28.316369],
                            [112.708999, 28.314735],
                            [112.711081, 28.312962],
                            [112.711979, 28.311507],
                            [112.711885, 28.309921],
                            [112.710631, 28.308661],
                            [112.707439, 28.308108],
                            [112.706966, 28.305506],
                            [112.709094, 28.30057],
                            [112.71153, 28.296317],
                            [112.713375, 28.294812],
                            [112.715622, 28.293665],
                            [112.71872, 28.291282],
                            [112.719193, 28.290494],
                            [112.719666, 28.285589],
                            [112.718791, 28.283662],
                            [112.714084, 28.281962],
                            [112.709945, 28.280994],
                            [112.709969, 28.279359],
                            [112.710962, 28.278806],
                            [112.712098, 28.279107],
                            [112.715125, 28.280782],
                            [112.716284, 28.280913],
                            [112.717324, 28.280197],
                            [112.715243, 28.278082],
                            [112.709898, 28.275918],
                            [112.702401, 28.274113],
                            [112.700391, 28.272169],
                            [112.699776, 28.269557],
                            [112.700012, 28.264888],
                            [112.701029, 28.262993],
                            [112.698924, 28.256582],
                            [112.70032, 28.25371],
                            [112.695944, 28.254231],
                            [112.691143, 28.253059],
                            [112.694431, 28.250847],
                            [112.694738, 28.249431],
                            [112.696749, 28.249887],
                            [112.695188, 28.246339],
                            [112.694975, 28.244216],
                            [112.696961, 28.243719],
                            [112.696772, 28.243166],
                            [112.695235, 28.243272],
                            [112.695897, 28.238691],
                            [112.696228, 28.238032],
                            [112.69559, 28.236136],
                            [112.696086, 28.235176],
                            [112.697576, 28.234639],
                            [112.696417, 28.234036],
                            [112.696441, 28.233198],
                            [112.698995, 28.233735],
                            [112.699019, 28.232254],
                            [112.699681, 28.23315],
                            [112.704151, 28.228926],
                            [112.70654, 28.225964],
                            [112.706209, 28.224499],
                            [112.704766, 28.222164],
                            [112.699539, 28.217688],
                            [112.692066, 28.213789],
                            [112.691569, 28.213106],
                            [112.689606, 28.208393],
                            [112.686957, 28.205528],
                            [112.685325, 28.204323],
                            [112.681849, 28.203086],
                            [112.68017, 28.203282],
                            [112.674967, 28.205634],
                            [112.671183, 28.205463],
                            [112.669787, 28.205105],
                            [112.668392, 28.202468],
                            [112.666642, 28.200425],
                            [112.667446, 28.200237],
                            [112.667469, 28.198561],
                            [112.67071, 28.198162],
                            [112.670828, 28.196648],
                            [112.671466, 28.195817],
                            [112.671537, 28.194059],
                            [112.672247, 28.193131],
                            [112.671774, 28.190078],
                            [112.676622, 28.188418],
                            [112.676409, 28.187115],
                            [112.677852, 28.187034],
                            [112.678727, 28.185503],
                            [112.680879, 28.184673],
                            [112.682156, 28.183314],
                            [112.682204, 28.182247],
                            [112.686366, 28.185235],
                            [112.687738, 28.184233],
                            [112.688187, 28.183224],
                            [112.691404, 28.183484],
                            [112.695046, 28.184095],
                            [112.699799, 28.183069],
                            [112.701952, 28.182011],
                            [112.705003, 28.180122],
                            [112.706067, 28.178526],
                            [112.705925, 28.175636],
                            [112.70758, 28.172966],
                            [112.707486, 28.172282],
                            [112.709804, 28.17194],
                            [112.711696, 28.16922],
                            [112.712594, 28.167437],
                            [112.714652, 28.167405],
                            [112.715905, 28.168195],
                            [112.717277, 28.170206],
                            [112.719169, 28.171484],
                            [112.722764, 28.172542],
                            [112.724821, 28.174732],
                            [112.727234, 28.175229],
                            [112.730616, 28.177761],
                            [112.7344, 28.177159],
                            [112.73563, 28.17606],
                            [112.735937, 28.175001],
                            [112.737356, 28.174846],
                            [112.738515, 28.175758],
                            [112.739769, 28.17553],
                            [112.742039, 28.172844],
                            [112.742985, 28.172958],
                            [112.744901, 28.171744],
                            [112.743836, 28.170873],
                            [112.742583, 28.171826],
                            [112.740785, 28.171671],
                            [112.742299, 28.170157],
                            [112.741637, 28.168414],
                            [112.743836, 28.167169],
                            [112.744404, 28.166297],
                            [112.746249, 28.165882],
                            [112.746627, 28.165198],
                            [112.752019, 28.163179],
                            [112.755118, 28.163366],
                            [112.757317, 28.164425],
                            [112.758003, 28.166281],
                            [112.759091, 28.167413],
                            [112.76155, 28.16747],
                            [112.762473, 28.166452],
                            [112.761858, 28.164254],
                            [112.763419, 28.16348],
                            [112.764199, 28.161664],
                            [112.764767, 28.162536],
                            [112.766612, 28.161062],
                            [112.768598, 28.161436],
                            [112.770396, 28.162495],
                            [112.772453, 28.161664],
                            [112.773565, 28.162422],
                            [112.772193, 28.163822],
                            [112.775339, 28.164311],
                            [112.777136, 28.163741],
                            [112.777041, 28.162006],
                            [112.77943, 28.161852],
                            [112.781109, 28.160304],
                            [112.781464, 28.158318],
                            [112.784089, 28.157617],
                            [112.787045, 28.155443],
                            [112.788677, 28.152674],
                            [112.788819, 28.150101],
                            [112.789269, 28.148969],
                            [112.788796, 28.14598],
                            [112.79038, 28.144311],
                            [112.791799, 28.143838],
                            [112.793242, 28.14225],
                            [112.794141, 28.140076],
                            [112.797097, 28.139115],
                            [112.797688, 28.138146],
                            [112.80081, 28.140174],
                            [112.803577, 28.140858],
                            [112.806084, 28.142258],
                            [112.808591, 28.142405],
                            [112.807858, 28.14462],
                            [112.808662, 28.146917],
                            [112.810578, 28.146461],
                            [112.813061, 28.149393],
                            [112.814007, 28.149694],
                            [112.814764, 28.151152],
                            [112.814314, 28.153823],
                            [112.814835, 28.154735],
                            [112.816372, 28.155353],
                            [112.818879, 28.154938],
                            [112.820061, 28.153823],
                            [112.822781, 28.153497],
                            [112.823727, 28.155622],
                            [112.825903, 28.157568],
                            [112.826139, 28.158586],
                            [112.827866, 28.159254],
                            [112.827961, 28.157283],
                            [112.828859, 28.155679],
                            [112.830349, 28.154824],
                            [112.832005, 28.155899],
                            [112.832454, 28.157609],
                            [112.833116, 28.157357],
                            [112.834937, 28.154311],
                            [112.834275, 28.15335],
                            [112.836356, 28.153725],
                            [112.83884, 28.153041],
                            [112.839171, 28.151323],
                            [112.840661, 28.150052],
                            [112.841678, 28.151567],
                            [112.842789, 28.150223],
                            [112.84506, 28.151347],
                            [112.846219, 28.155744],
                            [112.852959, 28.159287],
                            [112.856696, 28.162625],
                            [112.855608, 28.16427],
                            [112.858044, 28.165011],
                            [112.856979, 28.165459],
                            [112.856838, 28.1676],
                            [112.855702, 28.168756],
                            [112.854023, 28.168756],
                            [112.851398, 28.168129],
                            [112.85102, 28.169343],
                            [112.849955, 28.169994],
                            [112.848607, 28.169554],
                            [112.845793, 28.170271],
                            [112.846668, 28.171232],
                            [112.84584, 28.172477],
                            [112.846763, 28.173438],
                            [112.844256, 28.17457],
                            [112.844752, 28.175856],
                            [112.851847, 28.17803],
                            [112.859865, 28.185438],
                            [112.861993, 28.186195],
                            [112.863223, 28.186106],
                            [112.861331, 28.188182],
                            [112.861804, 28.189207],
                            [112.861544, 28.190966],
                            [112.860361, 28.192179],
                            [112.859179, 28.192122],
                            [112.859061, 28.193025],
                            [112.858044, 28.192822],
                            [112.856199, 28.193384],
                            [112.855986, 28.194108],
                            [112.858328, 28.19642],
                            [112.859605, 28.196599],
                            [112.859912, 28.198178],
                            [112.85776, 28.199358],
                            [112.857594, 28.20062],
                            [112.85329, 28.201694],
                            [112.85478, 28.202142],
                            [112.854851, 28.2032],
                            [112.854047, 28.206],
                            [112.852817, 28.206659],
                            [112.850807, 28.209329],
                            [112.848418, 28.208597],
                            [112.845627, 28.207294],
                            [112.842458, 28.206342],
                            [112.841725, 28.209712],
                            [112.841536, 28.212577],
                            [112.840519, 28.21606],
                            [112.839313, 28.218567],
                            [112.838343, 28.219258],
                            [112.83709, 28.219079],
                            [112.835954, 28.220984],
                            [112.834535, 28.222058],
                            [112.83314, 28.222164],
                            [112.832998, 28.223344],
                            [112.831745, 28.224092],
                            [112.832194, 28.226119],
                            [112.831508, 28.227136],
                            [112.829356, 28.22598],
                            [112.828859, 28.224906],
                            [112.828457, 28.225972],
                            [112.827535, 28.225557],
                            [112.82718, 28.2283],
                            [112.825927, 28.229496],
                            [112.824981, 28.228983],
                            [112.823633, 28.230057],
                            [112.821764, 28.230155],
                            [112.821693, 28.228755],
                            [112.820369, 28.227527],
                            [112.819541, 28.225769],
                            [112.818027, 28.224955],
                            [112.817413, 28.225712],
                            [112.816869, 28.224808],
                            [112.815899, 28.225427],
                            [112.814527, 28.227217],
                            [112.813889, 28.230505],
                            [112.812777, 28.232092],
                            [112.812328, 28.230171],
                            [112.810625, 28.231563],
                            [112.809064, 28.230399],
                            [112.808544, 28.232311],
                            [112.805942, 28.234606],
                            [112.805942, 28.236974],
                            [112.805374, 28.238561],
                            [112.803624, 28.238105],
                            [112.802868, 28.238927],
                            [112.803885, 28.239073],
                            [112.803222, 28.240212],
                            [112.800715, 28.241091],
                            [112.799698, 28.243101],
                            [112.798776, 28.243792],
                            [112.79958, 28.245705],
                            [112.800503, 28.245778],
                            [112.802513, 28.247633],
                            [112.803506, 28.246591],
                            [112.805185, 28.246046],
                            [112.80457, 28.247104],
                            [112.805824, 28.247202],
                            [112.806581, 28.249773],
                            [112.806391, 28.250708],
                            [112.807645, 28.25118],
                            [112.812375, 28.250285],
                            [112.814172, 28.251408],
                            [112.814669, 28.252295],
                            [112.816798, 28.252189],
                            [112.81772, 28.253247],
                            [112.818997, 28.252693],
                            [112.820771, 28.250611],
                            [112.822355, 28.250253],
                            [112.82245, 28.248487],
                            [112.825359, 28.248333],
                            [112.826329, 28.247202],
                            [112.826069, 28.245916],
                            [112.829829, 28.24542],
                            [112.83288, 28.247307],
                            [112.834512, 28.247389],
                            [112.837799, 28.246453],
                            [112.840732, 28.248015],
                            [112.841559, 28.249219],
                            [112.84383, 28.24887],
                            [112.845533, 28.249284],
                            [112.846431, 28.247698],
                            [112.849601, 28.249008],
                            [112.849435, 28.249797],
                            [112.850381, 28.250798],
                            [112.850428, 28.254426],
                            [112.852912, 28.253108],
                            [112.854354, 28.253043],
                            [112.85556, 28.253694],
                            [112.856601, 28.253474],
                            [112.856577, 28.251351],
                            [112.858209, 28.252335],
                            [112.858446, 28.251123],
                            [112.859534, 28.251579],
                            [112.861047, 28.25105],
                            [112.861875, 28.252254],
                            [112.861189, 28.253377],
                            [112.863365, 28.254003],
                            [112.868095, 28.256655],
                            [112.868734, 28.255793],
                            [112.870082, 28.25598],
                            [112.871453, 28.255134],
                            [112.873085, 28.255817],
                            [112.874197, 28.255638],
                            [112.875261, 28.254321],
                            [112.876538, 28.255638],
                            [112.877603, 28.25572],
                            [112.878359, 28.256745],
                            [112.879163, 28.256371],
                            [112.881599, 28.25729],
                            [112.882498, 28.256517],
                            [112.88465, 28.256159],
                            [112.885242, 28.256721],
                            [112.887417, 28.25594],
                            [112.888624, 28.256867],
                            [112.890989, 28.257469],
                            [112.892857, 28.258681],
                            [112.894938, 28.258087],
                            [112.89657, 28.260064],
                            [112.898959, 28.258876],
                            [112.901702, 28.258958],
                            [112.904044, 28.258632],
                            [112.904871, 28.261748],
                            [112.910642, 28.265905],
                            [112.914402, 28.266108],
                            [112.917075, 28.268516],
                            [112.917453, 28.269964],
                            [112.918163, 28.270249],
                            [112.92041, 28.272689],
                            [112.923413, 28.272323],
                            [112.926062, 28.273454],
                            [112.927386, 28.272396],
                            [112.93013, 28.271176],
                            [112.930745, 28.269175],
                            [112.932353, 28.268793],
                            [112.932826, 28.267825],
                            [112.934009, 28.267898],
                            [112.933796, 28.264506],
                            [112.936208, 28.265165],
                            [112.936184, 28.2661],
                            [112.938928, 28.26667],
                            [112.939259, 28.265344],
                            [112.940678, 28.265067],
                            [112.941884, 28.264197],
                            [112.943965, 28.264148],
                            [112.945361, 28.264986],
                            [112.947016, 28.265035],
                            [112.948885, 28.264319],
                            [112.950966, 28.264368],
                            [112.951533, 28.265515],
                            [112.950327, 28.265523],
                            [112.950091, 28.266385],
                            [112.950919, 28.268183],
                            [112.951888, 28.268752],
                            [112.950966, 28.269134],
                            [112.951604, 28.27055],
                            [112.953189, 28.26946],
                            [112.953733, 28.271282],
                            [112.95501, 28.271079],
                            [112.961467, 28.273137],
                            [112.9622, 28.275308]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430121,
                "name": "长沙县",
                "center": [113.080098, 28.237888],
                "centroid": [113.23516, 28.357041],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430100
                },
                "subFeatureIndex": 6,
                "acroutes": [100000, 430000, 430100]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [113.057085, 28.223091],
                            [113.058409, 28.219291],
                            [113.059497, 28.217663],
                            [113.060703, 28.217085],
                            [113.062856, 28.217338],
                            [113.06515, 28.218461],
                            [113.065008, 28.217207],
                            [113.069217, 28.21785],
                            [113.072623, 28.217582],
                            [113.072789, 28.21536],
                            [113.081232, 28.215287],
                            [113.081445, 28.213106],
                            [113.08381, 28.212642],
                            [113.084756, 28.212975],
                            [113.084708, 28.210037],
                            [113.085749, 28.210062],
                            [113.087665, 28.211234],
                            [113.087949, 28.212031],
                            [113.086766, 28.215214],
                            [113.088114, 28.215214],
                            [113.0888, 28.214196],
                            [113.089841, 28.214904],
                            [113.091165, 28.21453],
                            [113.091472, 28.213016],
                            [113.092702, 28.212975],
                            [113.092655, 28.21151],
                            [113.094807, 28.211193],
                            [113.094854, 28.21033],
                            [113.096936, 28.210078],
                            [113.097976, 28.207628],
                            [113.097621, 28.207172],
                            [113.101358, 28.207514],
                            [113.103416, 28.205243],
                            [113.10351, 28.207359],
                            [113.10545, 28.207929],
                            [113.105426, 28.205325],
                            [113.106703, 28.205439],
                            [113.105875, 28.204616],
                            [113.106372, 28.201068],
                            [113.10623, 28.199757],
                            [113.108146, 28.199847],
                            [113.107933, 28.202801],
                            [113.109707, 28.202753],
                            [113.110984, 28.201084],
                            [113.109778, 28.197348],
                            [113.108075, 28.197299],
                            [113.106703, 28.196656],
                            [113.107294, 28.194059],
                            [113.106798, 28.192863],
                            [113.107602, 28.189476],
                            [113.108761, 28.189444],
                            [113.109139, 28.187856],
                            [113.108784, 28.185707],
                            [113.108997, 28.18447],
                            [113.107838, 28.183737],
                            [113.107176, 28.182377],
                            [113.109683, 28.181042],
                            [113.111149, 28.179308],
                            [113.114863, 28.172721],
                            [113.11517, 28.171785],
                            [113.11491, 28.16944],
                            [113.113467, 28.168056],
                            [113.112261, 28.167494],
                            [113.108832, 28.16716],
                            [113.106254, 28.168032],
                            [113.102257, 28.169953],
                            [113.09878, 28.171223],
                            [113.095446, 28.171272],
                            [113.092844, 28.170881],
                            [113.089793, 28.169367],
                            [113.083408, 28.164653],
                            [113.080097, 28.161379],
                            [113.077495, 28.157422],
                            [113.076289, 28.153855],
                            [113.07539, 28.150125],
                            [113.074799, 28.144799],
                            [113.074799, 28.14067],
                            [113.075367, 28.137616],
                            [113.077235, 28.134383],
                            [113.079742, 28.131516],
                            [113.084992, 28.126075],
                            [113.086577, 28.12302],
                            [113.089533, 28.113685],
                            [113.103605, 28.098385],
                            [113.117346, 28.089145],
                            [113.126262, 28.088844],
                            [113.13707, 28.091875],
                            [113.152183, 28.089455],
                            [113.155447, 28.089602],
                            [113.161288, 28.082333],
                            [113.165734, 28.078544],
                            [113.168501, 28.074909],
                            [113.173634, 28.068854],
                            [113.17536, 28.062343],
                            [113.173634, 28.057648],
                            [113.174319, 28.056279],
                            [113.183543, 28.056719],
                            [113.187895, 28.057852],
                            [113.190922, 28.058161],
                            [113.194517, 28.058039],
                            [113.195818, 28.057729],
                            [113.197828, 28.056099],
                            [113.200642, 28.050059],
                            [113.201423, 28.049212],
                            [113.20367, 28.049415],
                            [113.204497, 28.05019],
                            [113.208021, 28.055521],
                            [113.211167, 28.058161],
                            [113.2176, 28.060786],
                            [113.220343, 28.060981],
                            [113.223701, 28.060843],
                            [113.225664, 28.05927],
                            [113.226468, 28.057966],
                            [113.227533, 28.053092],
                            [113.229685, 28.051355],
                            [113.233067, 28.050899],
                            [113.23581, 28.051902],
                            [113.237939, 28.053727],
                            [113.238861, 28.055455],
                            [113.238956, 28.058137],
                            [113.238317, 28.059131],
                            [113.234273, 28.061511],
                            [113.233303, 28.062815],
                            [113.232901, 28.065497],
                            [113.233564, 28.068137],
                            [113.234959, 28.070321],
                            [113.237229, 28.072473],
                            [113.239027, 28.07535],
                            [113.240115, 28.075716],
                            [113.24248, 28.074543],
                            [113.244845, 28.071927],
                            [113.24896, 28.066719],
                            [113.253099, 28.062114],
                            [113.256268, 28.06006],
                            [113.269228, 28.054062],
                            [113.273698, 28.051217],
                            [113.278121, 28.049065],
                            [113.281739, 28.047622],
                            [113.284956, 28.046799],
                            [113.297088, 28.04503],
                            [113.30021, 28.044981],
                            [113.301487, 28.044313],
                            [113.302102, 28.043261],
                            [113.302268, 28.040816],
                            [113.301582, 28.039014],
                            [113.299524, 28.036283],
                            [113.300589, 28.033601],
                            [113.30371, 28.031506],
                            [113.308038, 28.03087],
                            [113.316009, 28.032264],
                            [113.318634, 28.034057],
                            [113.320881, 28.036365],
                            [113.319603, 28.037946],
                            [113.315536, 28.038468],
                            [113.312981, 28.039267],
                            [113.311208, 28.041125],
                            [113.305271, 28.049489],
                            [113.30494, 28.051413],
                            [113.305839, 28.052937],
                            [113.308913, 28.056417],
                            [113.312508, 28.057314],
                            [113.320668, 28.055822],
                            [113.324144, 28.056156],
                            [113.325563, 28.057558],
                            [113.327195, 28.063312],
                            [113.328543, 28.064038],
                            [113.332304, 28.06222],
                            [113.334976, 28.062774],
                            [113.338429, 28.064405],
                            [113.341882, 28.066654],
                            [113.346044, 28.068838],
                            [113.350207, 28.071479],
                            [113.355079, 28.075969],
                            [113.356805, 28.078503],
                            [113.358153, 28.081209],
                            [113.361346, 28.083629],
                            [113.363144, 28.084126],
                            [113.365201, 28.08587],
                            [113.365532, 28.089137],
                            [113.366455, 28.093643],
                            [113.367992, 28.095501],
                            [113.372154, 28.097521],
                            [113.37407, 28.097961],
                            [113.376293, 28.099192],
                            [113.378091, 28.101277],
                            [113.38003, 28.104088],
                            [113.38379, 28.106948],
                            [113.385966, 28.108064],
                            [113.389939, 28.111208],
                            [113.399329, 28.113758],
                            [113.400984, 28.114996],
                            [113.40264, 28.11696],
                            [113.403089, 28.118312],
                            [113.404366, 28.119509],
                            [113.408481, 28.12157],
                            [113.413519, 28.123126],
                            [113.422246, 28.131988],
                            [113.42428, 28.132884],
                            [113.430854, 28.138683],
                            [113.432132, 28.140361],
                            [113.432581, 28.142446],
                            [113.428655, 28.143659],
                            [113.425533, 28.143676],
                            [113.418651, 28.142763],
                            [113.4156, 28.143342],
                            [113.413069, 28.145614],
                            [113.407748, 28.148798],
                            [113.406235, 28.150443],
                            [113.405809, 28.155402],
                            [113.404815, 28.156819],
                            [113.402829, 28.158244],
                            [113.397791, 28.157585],
                            [113.390956, 28.155419],
                            [113.382537, 28.153204],
                            [113.377499, 28.151526],
                            [113.37012, 28.148114],
                            [113.367519, 28.147055],
                            [113.362765, 28.14357],
                            [113.361559, 28.142283],
                            [113.359005, 28.142739],
                            [113.359478, 28.145899],
                            [113.360306, 28.14708],
                            [113.36286, 28.149042],
                            [113.365178, 28.151575],
                            [113.365012, 28.153212],
                            [113.363735, 28.154849],
                            [113.360873, 28.157511],
                            [113.356758, 28.162316],
                            [113.354464, 28.162096],
                            [113.352099, 28.162275],
                            [113.352477, 28.164083],
                            [113.35366, 28.167462],
                            [113.354677, 28.169652],
                            [113.356309, 28.17518],
                            [113.356569, 28.17698],
                            [113.35891, 28.181091],
                            [113.366195, 28.183159],
                            [113.369316, 28.185349],
                            [113.37005, 28.187148],
                            [113.370262, 28.191153],
                            [113.369742, 28.192447],
                            [113.36811, 28.193636],
                            [113.364279, 28.194898],
                            [113.362552, 28.196705],
                            [113.36085, 28.197047],
                            [113.359501, 28.196265],
                            [113.35813, 28.192773],
                            [113.356285, 28.191137],
                            [113.354227, 28.190697],
                            [113.348622, 28.190429],
                            [113.346494, 28.189256],
                            [113.345595, 28.186944],
                            [113.34323, 28.184811],
                            [113.341551, 28.184868],
                            [113.340156, 28.185886],
                            [113.339257, 28.187189],
                            [113.340699, 28.191357],
                            [113.340203, 28.192708],
                            [113.337838, 28.193677],
                            [113.336111, 28.193343],
                            [113.332848, 28.191096],
                            [113.324333, 28.18644],
                            [113.322347, 28.185837],
                            [113.321022, 28.185007],
                            [113.317853, 28.183997],
                            [113.315843, 28.183778],
                            [113.312106, 28.181791],
                            [113.307967, 28.181465],
                            [113.305413, 28.179951],
                            [113.301818, 28.178209],
                            [113.299335, 28.177655],
                            [113.297774, 28.175823],
                            [113.295456, 28.173902],
                            [113.292193, 28.173983],
                            [113.288929, 28.180676],
                            [113.2868, 28.184193],
                            [113.28654, 28.185927],
                            [113.285523, 28.187001],
                            [113.282851, 28.187172],
                            [113.282094, 28.188027],
                            [113.278854, 28.193685],
                            [113.278239, 28.19572],
                            [113.278759, 28.197519],
                            [113.280178, 28.198585],
                            [113.280817, 28.20084],
                            [113.280202, 28.205129],
                            [113.276583, 28.209883],
                            [113.272894, 28.213952],
                            [113.268117, 28.219877],
                            [113.267029, 28.222953],
                            [113.267076, 28.2248],
                            [113.2684, 28.227331],
                            [113.270032, 28.228178],
                            [113.273485, 28.229178],
                            [113.278523, 28.231197],
                            [113.282638, 28.233491],
                            [113.283915, 28.235631],
                            [113.284199, 28.240424],
                            [113.284908, 28.242116],
                            [113.289071, 28.245485],
                            [113.289142, 28.247291],
                            [113.287746, 28.250627],
                            [113.288976, 28.25214],
                            [113.293517, 28.253483],
                            [113.295811, 28.254662],
                            [113.298342, 28.260178],
                            [113.299051, 28.263391],
                            [113.299761, 28.270859],
                            [113.29943, 28.273649],
                            [113.300139, 28.275821],
                            [113.301156, 28.276951],
                            [113.302481, 28.281661],
                            [113.303639, 28.284589],
                            [113.305106, 28.285939],
                            [113.307092, 28.287118],
                            [113.30889, 28.288916],
                            [113.31012, 28.291901],
                            [113.310522, 28.294666],
                            [113.311373, 28.295853],
                            [113.312887, 28.296121],
                            [113.315583, 28.293633],
                            [113.317688, 28.293519],
                            [113.32159, 28.296219],
                            [113.324996, 28.298911],
                            [113.327313, 28.301277],
                            [113.327692, 28.303139],
                            [113.327455, 28.304603],
                            [113.325043, 28.307661],
                            [113.325303, 28.309572],
                            [113.329655, 28.312548],
                            [113.330695, 28.314182],
                            [113.330908, 28.315873],
                            [113.330979, 28.321687],
                            [113.332067, 28.328451],
                            [113.332564, 28.329858],
                            [113.336371, 28.336109],
                            [113.336159, 28.340231],
                            [113.335331, 28.341474],
                            [113.334574, 28.343791],
                            [113.333013, 28.350392],
                            [113.332446, 28.354968],
                            [113.332516, 28.356772],
                            [113.333439, 28.358739],
                            [113.33798, 28.361324],
                            [113.339091, 28.363745],
                            [113.339541, 28.365883],
                            [113.339375, 28.369272],
                            [113.337128, 28.378252],
                            [113.335307, 28.384801],
                            [113.330956, 28.396323],
                            [113.329442, 28.400556],
                            [113.328307, 28.402871],
                            [113.328188, 28.404455],
                            [113.32878, 28.407494],
                            [113.329868, 28.409809],
                            [113.333983, 28.413798],
                            [113.337909, 28.416836],
                            [113.341953, 28.418631],
                            [113.344838, 28.421555],
                            [113.347227, 28.422733],
                            [113.352028, 28.422262],
                            [113.354393, 28.422376],
                            [113.356001, 28.424284],
                            [113.356332, 28.42733],
                            [113.357302, 28.429751],
                            [113.360826, 28.430531],
                            [113.362883, 28.430693],
                            [113.365438, 28.43153],
                            [113.366668, 28.432821],
                            [113.367495, 28.434397],
                            [113.368157, 28.437215],
                            [113.368134, 28.440488],
                            [113.369009, 28.442405],
                            [113.371019, 28.444654],
                            [113.375513, 28.448357],
                            [113.37776, 28.448861],
                            [113.379533, 28.448739],
                            [113.384145, 28.449795],
                            [113.388781, 28.451914],
                            [113.394977, 28.45392],
                            [113.399258, 28.45435],
                            [113.405927, 28.45539],
                            [113.408789, 28.456275],
                            [113.411177, 28.457956],
                            [113.412194, 28.459531],
                            [113.412928, 28.462681],
                            [113.412928, 28.464265],
                            [113.412123, 28.467878],
                            [113.411887, 28.470979],
                            [113.407654, 28.476419],
                            [113.407724, 28.478173],
                            [113.408434, 28.479463],
                            [113.409971, 28.480413],
                            [113.416168, 28.481623],
                            [113.418864, 28.482394],
                            [113.42506, 28.483596],
                            [113.429033, 28.485487],
                            [113.430311, 28.487062],
                            [113.431091, 28.488921],
                            [113.434095, 28.498605],
                            [113.435679, 28.499717],
                            [113.436933, 28.495934],
                            [113.43821, 28.494676],
                            [113.440622, 28.493929],
                            [113.447457, 28.494619],
                            [113.452424, 28.495309],
                            [113.459826, 28.496835],
                            [113.461363, 28.49755],
                            [113.462404, 28.499067],
                            [113.465455, 28.500902],
                            [113.468151, 28.500772],
                            [113.470232, 28.500244],
                            [113.472455, 28.500447],
                            [113.474513, 28.501275],
                            [113.47553, 28.502963],
                            [113.475506, 28.505447],
                            [113.474513, 28.509684],
                            [113.476098, 28.510122],
                            [113.480567, 28.510763],
                            [113.483949, 28.511688],
                            [113.48797, 28.513287],
                            [113.490595, 28.514001],
                            [113.495349, 28.513166],
                            [113.497903, 28.514164],
                            [113.500032, 28.516566],
                            [113.500552, 28.517913],
                            [113.502089, 28.519479],
                            [113.504383, 28.520875],
                            [113.505731, 28.522839],
                            [113.506867, 28.529031],
                            [113.506819, 28.532926],
                            [113.507363, 28.53695],
                            [113.505424, 28.539328],
                            [113.503792, 28.543295],
                            [113.502515, 28.548114],
                            [113.501782, 28.551757],
                            [113.501545, 28.55501],
                            [113.50093, 28.559172],
                            [113.499653, 28.561955],
                            [113.497217, 28.56584],
                            [113.49516, 28.567876],
                            [113.491352, 28.570448],
                            [113.486007, 28.573157],
                            [113.478794, 28.576223],
                            [113.475861, 28.577877],
                            [113.474584, 28.579175],
                            [113.474347, 28.580716],
                            [113.474749, 28.582744],
                            [113.473685, 28.585363],
                            [113.472384, 28.587221],
                            [113.469783, 28.590108],
                            [113.467039, 28.592281],
                            [113.462735, 28.596514],
                            [113.460512, 28.597455],
                            [113.459046, 28.596806],
                            [113.456893, 28.593862],
                            [113.454363, 28.59104],
                            [113.45292, 28.590635],
                            [113.449467, 28.590975],
                            [113.446511, 28.593952],
                            [113.445187, 28.593814],
                            [113.442916, 28.591032],
                            [113.441994, 28.590367],
                            [113.433669, 28.590489],
                            [113.430405, 28.590294],
                            [113.429152, 28.589037],
                            [113.426787, 28.585769],
                            [113.426219, 28.580481],
                            [113.425486, 28.578656],
                            [113.424327, 28.578721],
                            [113.422175, 28.58362],
                            [113.420992, 28.587115],
                            [113.419857, 28.588615],
                            [113.418651, 28.588956],
                            [113.415671, 28.58765],
                            [113.414914, 28.588307],
                            [113.414583, 28.590902],
                            [113.415198, 28.595955],
                            [113.416924, 28.604664],
                            [113.418367, 28.606869],
                            [113.420708, 28.608661],
                            [113.420779, 28.609537],
                            [113.418816, 28.612602],
                            [113.417752, 28.615253],
                            [113.417705, 28.617029],
                            [113.418343, 28.619575],
                            [113.419313, 28.620037],
                            [113.423003, 28.619923],
                            [113.424374, 28.62281],
                            [113.42532, 28.628217],
                            [113.426432, 28.632027],
                            [113.426503, 28.634735],
                            [113.42532, 28.635959],
                            [113.424516, 28.636056],
                            [113.421229, 28.634597],
                            [113.41709, 28.633276],
                            [113.413897, 28.632498],
                            [113.411816, 28.632254],
                            [113.409711, 28.632457],
                            [113.406991, 28.63313],
                            [113.403326, 28.634508],
                            [113.4007, 28.63621],
                            [113.397413, 28.639307],
                            [113.38476, 28.644568],
                            [113.381142, 28.644892],
                            [113.37582, 28.643344],
                            [113.371634, 28.640952],
                            [113.369624, 28.640028],
                            [113.365438, 28.637224],
                            [113.363971, 28.635132],
                            [113.361346, 28.633268],
                            [113.360093, 28.63146],
                            [113.359809, 28.625469],
                            [113.358603, 28.622404],
                            [113.352974, 28.617759],
                            [113.349237, 28.616599],
                            [113.347274, 28.616867],
                            [113.343301, 28.618342],
                            [113.340487, 28.618861],
                            [113.334219, 28.619226],
                            [113.332209, 28.618983],
                            [113.325185, 28.615853],
                            [113.323482, 28.615407],
                            [113.320313, 28.61578],
                            [113.318042, 28.616891],
                            [113.316198, 28.619478],
                            [113.31563, 28.621018],
                            [113.315086, 28.624739],
                            [113.316529, 28.628622],
                            [113.315252, 28.630438],
                            [113.310001, 28.633162],
                            [113.304514, 28.634111],
                            [113.29723, 28.634711],
                            [113.292973, 28.635927],
                            [113.289118, 28.63758],
                            [113.287131, 28.638172],
                            [113.283608, 28.638707],
                            [113.281928, 28.638496],
                            [113.278594, 28.63758],
                            [113.277222, 28.635408],
                            [113.276489, 28.633519],
                            [113.275614, 28.632514],
                            [113.273745, 28.63159],
                            [113.268684, 28.631168],
                            [113.26464, 28.63133],
                            [113.263387, 28.630471],
                            [113.26237, 28.628922],
                            [113.260951, 28.628128],
                            [113.258869, 28.627617],
                            [113.256339, 28.627933],
                            [113.251822, 28.629693],
                            [113.251017, 28.634086],
                            [113.247517, 28.638131],
                            [113.245956, 28.640604],
                            [113.246098, 28.641974],
                            [113.247777, 28.644081],
                            [113.248108, 28.6455],
                            [113.246216, 28.648045],
                            [113.245081, 28.650095],
                            [113.243591, 28.656182],
                            [113.238246, 28.660875],
                            [113.233611, 28.661612],
                            [113.233469, 28.657187],
                            [113.234864, 28.651182],
                            [113.234699, 28.646448],
                            [113.233705, 28.645208],
                            [113.231979, 28.6443],
                            [113.225972, 28.642266],
                            [113.221478, 28.640199],
                            [113.21786, 28.638788],
                            [113.21462, 28.636834],
                            [113.21365, 28.635959],
                            [113.212846, 28.634273],
                            [113.212964, 28.63266],
                            [113.214289, 28.627236],
                            [113.215235, 28.624537],
                            [113.215447, 28.620823],
                            [113.215045, 28.619842],
                            [113.213579, 28.618351],
                            [113.211119, 28.616615],
                            [113.208352, 28.615099],
                            [113.204024, 28.610348],
                            [113.201517, 28.60914],
                            [113.199081, 28.609051],
                            [113.197355, 28.607607],
                            [113.195818, 28.60451],
                            [113.195037, 28.603675],
                            [113.186523, 28.601996],
                            [113.185199, 28.601307],
                            [113.183425, 28.599101],
                            [113.183283, 28.596871],
                            [113.185601, 28.591421],
                            [113.187067, 28.587423],
                            [113.188935, 28.584747],
                            [113.189692, 28.582152],
                            [113.189645, 28.580181],
                            [113.188108, 28.578153],
                            [113.185128, 28.573562],
                            [113.185104, 28.572378],
                            [113.185908, 28.569977],
                            [113.186121, 28.567957],
                            [113.18903, 28.567195],
                            [113.191561, 28.567503],
                            [113.193784, 28.567057],
                            [113.194659, 28.566465],
                            [113.199058, 28.558215],
                            [113.200761, 28.555586],
                            [113.199909, 28.553996],
                            [113.198537, 28.552998],
                            [113.195628, 28.551571],
                            [113.193926, 28.550086],
                            [113.193334, 28.548617],
                            [113.193855, 28.544188],
                            [113.194753, 28.541535],
                            [113.192365, 28.538094],
                            [113.188604, 28.533867],
                            [113.186665, 28.530824],
                            [113.185932, 28.529225],
                            [113.185341, 28.524998],
                            [113.184607, 28.522157],
                            [113.183969, 28.515235],
                            [113.18307, 28.510155],
                            [113.185695, 28.50578],
                            [113.185435, 28.503369],
                            [113.182384, 28.501413],
                            [113.179523, 28.500439],
                            [113.1786, 28.499254],
                            [113.177654, 28.49936],
                            [113.177063, 28.498386],
                            [113.176543, 28.495626],
                            [113.175502, 28.493588],
                            [113.171387, 28.492411],
                            [113.170606, 28.488937],
                            [113.171174, 28.484326],
                            [113.170985, 28.482459],
                            [113.169897, 28.480462],
                            [113.167532, 28.478765],
                            [113.161028, 28.47611],
                            [113.159987, 28.474763],
                            [113.156511, 28.47197],
                            [113.154288, 28.470882],
                            [113.151615, 28.471531],
                            [113.149439, 28.471069],
                            [113.147973, 28.471166],
                            [113.143621, 28.472489],
                            [113.140239, 28.472514],
                            [113.136597, 28.471978],
                            [113.133546, 28.471101],
                            [113.131181, 28.471312],
                            [113.129005, 28.472587],
                            [113.128674, 28.474],
                            [113.128816, 28.47637],
                            [113.129408, 28.480324],
                            [113.130708, 28.484229],
                            [113.13208, 28.490772],
                            [113.131702, 28.494968],
                            [113.131181, 28.496941],
                            [113.129928, 28.498962],
                            [113.126073, 28.49983],
                            [113.121225, 28.50035],
                            [113.117701, 28.500261],
                            [113.114602, 28.498881],
                            [113.111906, 28.499303],
                            [113.105355, 28.501892],
                            [113.103345, 28.503175],
                            [113.102896, 28.504465],
                            [113.101666, 28.505975],
                            [113.096557, 28.508694],
                            [113.096581, 28.508126],
                            [113.093837, 28.507363],
                            [113.091804, 28.506275],
                            [113.089533, 28.506015],
                            [113.08757, 28.507298],
                            [113.084992, 28.508231],
                            [113.085181, 28.51207],
                            [113.084306, 28.51517],
                            [113.081397, 28.518692],
                            [113.079032, 28.521882],
                            [113.076644, 28.523553],
                            [113.074775, 28.52404],
                            [113.07215, 28.52361],
                            [113.070376, 28.522352],
                            [113.06794, 28.519277],
                            [113.067254, 28.517402],
                            [113.066238, 28.511688],
                            [113.064748, 28.508401],
                            [113.063707, 28.505285],
                            [113.062312, 28.503402],
                            [113.060916, 28.502257],
                            [113.057038, 28.500464],
                            [113.055264, 28.499368],
                            [113.052851, 28.496852],
                            [113.051338, 28.494855],
                            [113.049942, 28.492298],
                            [113.047814, 28.489562],
                            [113.044668, 28.487094],
                            [113.041428, 28.484911],
                            [113.042469, 28.483685],
                            [113.044929, 28.482248],
                            [113.049115, 28.479139],
                            [113.049682, 28.476995],
                            [113.048405, 28.475575],
                            [113.047412, 28.473805],
                            [113.044858, 28.473042],
                            [113.035634, 28.472473],
                            [113.032489, 28.471978],
                            [113.031235, 28.471288],
                            [113.02965, 28.469737],
                            [113.028208, 28.466449],
                            [113.027687, 28.463225],
                            [113.025157, 28.460408],
                            [113.024164, 28.45634],
                            [113.024045, 28.450672],
                            [113.022745, 28.441844],
                            [113.022697, 28.439676],
                            [113.024542, 28.437321],
                            [113.025086, 28.43598],
                            [113.025015, 28.432642],
                            [113.02362, 28.428159],
                            [113.021231, 28.424236],
                            [113.017565, 28.421092],
                            [113.012338, 28.416113],
                            [113.009027, 28.413806],
                            [113.005456, 28.411621],
                            [113.00328, 28.411247],
                            [113.000206, 28.41171],
                            [112.996753, 28.411604],
                            [112.994506, 28.41028],
                            [112.993418, 28.410605],
                            [112.992567, 28.411889],
                            [112.99084, 28.411247],
                            [112.987506, 28.407932],
                            [112.985282, 28.406657],
                            [112.984928, 28.405065],
                            [112.986158, 28.404017],
                            [112.988996, 28.403147],
                            [112.990225, 28.402034],
                            [112.990698, 28.398825],
                            [112.991242, 28.397428],
                            [112.99032, 28.39538],
                            [112.986134, 28.392276],
                            [112.98358, 28.39122],
                            [112.981144, 28.3917],
                            [112.978495, 28.390879],
                            [112.991668, 28.380283],
                            [113.000442, 28.377585],
                            [113.007987, 28.376943],
                            [113.016808, 28.376651],
                            [113.031732, 28.37648],
                            [113.036296, 28.374384],
                            [113.039607, 28.371564],
                            [113.043888, 28.36503],
                            [113.055737, 28.358585],
                            [113.063447, 28.355146],
                            [113.068673, 28.354708],
                            [113.074586, 28.356845],
                            [113.078583, 28.356423],
                            [113.081752, 28.353732],
                            [113.079647, 28.351123],
                            [113.080002, 28.350001],
                            [113.081705, 28.348717],
                            [113.082178, 28.346498],
                            [113.081705, 28.345019],
                            [113.079884, 28.343702],
                            [113.076857, 28.344035],
                            [113.076313, 28.342092],
                            [113.077566, 28.339979],
                            [113.077022, 28.3378],
                            [113.075981, 28.336426],
                            [113.075887, 28.334849],
                            [113.07513, 28.333947],
                            [113.073333, 28.333906],
                            [113.07215, 28.33315],
                            [113.071133, 28.330898],
                            [113.070778, 28.327849],
                            [113.06969, 28.325939],
                            [113.066711, 28.323939],
                            [113.062643, 28.321979],
                            [113.060136, 28.321614],
                            [113.059261, 28.320109],
                            [113.059923, 28.316109],
                            [113.062856, 28.314076],
                            [113.064014, 28.312043],
                            [113.060893, 28.31119],
                            [113.060301, 28.310198],
                            [113.061389, 28.306108],
                            [113.061389, 28.304815],
                            [113.06068, 28.303595],
                            [113.059119, 28.304164],
                            [113.058977, 28.305888],
                            [113.058244, 28.305994],
                            [113.057274, 28.304514],
                            [113.057345, 28.303286],
                            [113.060088, 28.300708],
                            [113.062217, 28.299334],
                            [113.063329, 28.297943],
                            [113.063163, 28.296935],
                            [113.060703, 28.29434],
                            [113.059308, 28.293795],
                            [113.056801, 28.294064],
                            [113.055619, 28.293617],
                            [113.054294, 28.291608],
                            [113.052946, 28.29077],
                            [113.050534, 28.289924],
                            [113.046206, 28.289436],
                            [113.042989, 28.28772],
                            [113.041192, 28.286224],
                            [113.038259, 28.282092],
                            [113.035776, 28.276748],
                            [113.031069, 28.269061],
                            [113.030029, 28.265816],
                            [113.029934, 28.262993],
                            [113.034357, 28.25956],
                            [113.035705, 28.258787],
                            [113.038117, 28.25633],
                            [113.039749, 28.253645],
                            [113.041286, 28.253157],
                            [113.043084, 28.253409],
                            [113.05103, 28.256729],
                            [113.052118, 28.256851],
                            [113.053726, 28.255915],
                            [113.053703, 28.252417],
                            [113.052993, 28.24891],
                            [113.053395, 28.247844],
                            [113.054625, 28.247519],
                            [113.057818, 28.245656],
                            [113.058977, 28.245607],
                            [113.059426, 28.240953],
                            [113.059237, 28.237259],
                            [113.05848, 28.230725],
                            [113.057061, 28.2307],
                            [113.055713, 28.228397],
                            [113.055784, 28.227331],
                            [113.057085, 28.223091]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430181,
                "name": "浏阳市",
                "center": [113.633301, 28.141112],
                "centroid": [113.715506, 28.228483],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430100
                },
                "subFeatureIndex": 7,
                "acroutes": [100000, 430000, 430100]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [113.177228, 28.01493],
                            [113.178009, 28.014596],
                            [113.178529, 28.012737],
                            [113.183874, 28.012346],
                            [113.186736, 28.012346],
                            [113.190827, 28.010674],
                            [113.194115, 28.01056],
                            [113.196314, 28.011432],
                            [113.198774, 28.011905],
                            [113.202061, 28.011734],
                            [113.205798, 28.011147],
                            [113.208447, 28.011391],
                            [113.20918, 28.012297],
                            [113.212278, 28.019235],
                            [113.212491, 28.02319],
                            [113.213934, 28.024543],
                            [113.214572, 28.02407],
                            [113.217883, 28.018966],
                            [113.219492, 28.018542],
                            [113.223441, 28.019154],
                            [113.225664, 28.020442],
                            [113.226563, 28.020426],
                            [113.227556, 28.01524],
                            [113.228195, 28.014433],
                            [113.232121, 28.013332],
                            [113.238104, 28.012941],
                            [113.239452, 28.012174],
                            [113.240257, 28.010266],
                            [113.241392, 28.009679],
                            [113.243851, 28.009924],
                            [113.244656, 28.011],
                            [113.246098, 28.015216],
                            [113.247801, 28.016512],
                            [113.250261, 28.019676],
                            [113.251372, 28.019774],
                            [113.255416, 28.018779],
                            [113.256694, 28.015044],
                            [113.256244, 28.012982],
                            [113.25719, 28.012403],
                            [113.258917, 28.013724],
                            [113.259839, 28.013234],
                            [113.260336, 28.01069],
                            [113.259295, 28.006629],
                            [113.25816, 28.004477],
                            [113.257829, 28.002234],
                            [113.258373, 28.001027],
                            [113.258231, 27.996501],
                            [113.257569, 27.994479],
                            [113.256835, 27.993802],
                            [113.25868, 27.994275],
                            [113.260076, 27.994144],
                            [113.260312, 27.995155],
                            [113.259366, 27.996811],
                            [113.259697, 27.998303],
                            [113.259863, 28.002658],
                            [113.260643, 28.004574],
                            [113.26192, 28.009573],
                            [113.264025, 28.011742],
                            [113.265279, 28.010193],
                            [113.268826, 28.004044],
                            [113.268708, 28.001769],
                            [113.266981, 28.001345],
                            [113.267005, 28.000448],
                            [113.268164, 27.998605],
                            [113.269985, 27.99668],
                            [113.269417, 27.99558],
                            [113.267525, 27.994364],
                            [113.267573, 27.993125],
                            [113.269488, 27.992252],
                            [113.271428, 27.990654],
                            [113.271782, 27.988851],
                            [113.271073, 27.984659],
                            [113.269086, 27.978648],
                            [113.269559, 27.977016],
                            [113.270671, 27.975964],
                            [113.27559, 27.97244],
                            [113.277671, 27.970613],
                            [113.277979, 27.968696],
                            [113.275945, 27.965906],
                            [113.273367, 27.963769],
                            [113.27183, 27.961762],
                            [113.271026, 27.95814],
                            [113.271522, 27.949688],
                            [113.271191, 27.947763],
                            [113.270103, 27.946898],
                            [113.269228, 27.947102],
                            [113.26535, 27.949354],
                            [113.263197, 27.949484],
                            [113.261873, 27.948562],
                            [113.259413, 27.944115],
                            [113.259082, 27.941798],
                            [113.262464, 27.938804],
                            [113.266698, 27.934202],
                            [113.268471, 27.931688],
                            [113.270671, 27.927437],
                            [113.271168, 27.925136],
                            [113.271428, 27.921692],
                            [113.271972, 27.920125],
                            [113.274029, 27.918803],
                            [113.279351, 27.919913],
                            [113.284223, 27.918648],
                            [113.287557, 27.916844],
                            [113.28848, 27.915563],
                            [113.290561, 27.910911],
                            [113.290206, 27.90989],
                            [113.287983, 27.909629],
                            [113.283702, 27.910005],
                            [113.281597, 27.910772],
                            [113.274881, 27.909564],
                            [113.272918, 27.908854],
                            [113.271664, 27.907874],
                            [113.271759, 27.90652],
                            [113.274147, 27.905263],
                            [113.276749, 27.90452],
                            [113.27779, 27.903695],
                            [113.279398, 27.90079],
                            [113.281621, 27.894447],
                            [113.281668, 27.892815],
                            [113.281172, 27.888407],
                            [113.281597, 27.883957],
                            [113.28103, 27.880896],
                            [113.280155, 27.879639],
                            [113.278405, 27.878308],
                            [113.276938, 27.876144],
                            [113.276016, 27.874152],
                            [113.274526, 27.870005],
                            [113.273745, 27.866943],
                            [113.27358, 27.863889],
                            [113.274999, 27.862681],
                            [113.27805, 27.863073],
                            [113.280935, 27.864085],
                            [113.285121, 27.864722],
                            [113.288385, 27.86455],
                            [113.289828, 27.864918],
                            [113.290206, 27.865824],
                            [113.289686, 27.869425],
                            [113.289497, 27.872544],
                            [113.287912, 27.876618],
                            [113.288503, 27.877198],
                            [113.294037, 27.877067],
                            [113.297301, 27.878537],
                            [113.29898, 27.878112],
                            [113.300068, 27.87723],
                            [113.300589, 27.874307],
                            [113.30021, 27.870911],
                            [113.300754, 27.868837],
                            [113.305768, 27.866837],
                            [113.311018, 27.865751],
                            [113.312154, 27.865824],
                            [113.315748, 27.865106],
                            [113.317995, 27.864069],
                            [113.324972, 27.859235],
                            [113.327219, 27.85792],
                            [113.331334, 27.856924],
                            [113.339139, 27.858026],
                            [113.341787, 27.858696],
                            [113.343065, 27.858549],
                            [113.346943, 27.856026],
                            [113.350112, 27.854213],
                            [113.355907, 27.850481],
                            [113.357988, 27.84866],
                            [113.359383, 27.848072],
                            [113.366904, 27.851583],
                            [113.371516, 27.85262],
                            [113.375016, 27.852849],
                            [113.384405, 27.854368],
                            [113.387125, 27.854523],
                            [113.391288, 27.854303],
                            [113.397247, 27.853372],
                            [113.400014, 27.853388],
                            [113.405596, 27.855078],
                            [113.409049, 27.855944],
                            [113.413235, 27.857798],
                            [113.414678, 27.859488],
                            [113.41508, 27.861015],
                            [113.414796, 27.866257],
                            [113.415742, 27.869662],
                            [113.416191, 27.872764],
                            [113.415647, 27.880088],
                            [113.414867, 27.884766],
                            [113.415009, 27.887133],
                            [113.415529, 27.88897],
                            [113.418911, 27.891321],
                            [113.421607, 27.892733],
                            [113.424185, 27.897655],
                            [113.426077, 27.898194],
                            [113.42875, 27.898341],
                            [113.431659, 27.898047],
                            [113.435277, 27.89661],
                            [113.439061, 27.896578],
                            [113.441805, 27.896055],
                            [113.44618, 27.895664],
                            [113.452565, 27.896317],
                            [113.454718, 27.896961],
                            [113.457981, 27.898643],
                            [113.459542, 27.900186],
                            [113.458052, 27.904797],
                            [113.458171, 27.90807],
                            [113.460701, 27.914983],
                            [113.461553, 27.916289],
                            [113.46361, 27.91824],
                            [113.46555, 27.919447],
                            [113.469073, 27.921023],
                            [113.471391, 27.922345],
                            [113.477682, 27.924401],
                            [113.479432, 27.925209],
                            [113.481561, 27.927159],
                            [113.482814, 27.930391],
                            [113.483926, 27.935932],
                            [113.48499, 27.937017],
                            [113.48849, 27.938918],
                            [113.490193, 27.939448],
                            [113.495396, 27.939008],
                            [113.499204, 27.938371],
                            [113.501876, 27.93767],
                            [113.504218, 27.937645],
                            [113.509657, 27.93873],
                            [113.515948, 27.940778],
                            [113.519614, 27.941276],
                            [113.521553, 27.942312],
                            [113.523162, 27.944474],
                            [113.524439, 27.946914],
                            [113.524486, 27.951883],
                            [113.523777, 27.955766],
                            [113.524581, 27.959046],
                            [113.525905, 27.959911],
                            [113.53125, 27.958393],
                            [113.533284, 27.958532],
                            [113.538251, 27.960734],
                            [113.542342, 27.961917],
                            [113.547096, 27.962032],
                            [113.554215, 27.961216],
                            [113.558637, 27.962007],
                            [113.565638, 27.964357],
                            [113.567719, 27.964781],
                            [113.569256, 27.964626],
                            [113.571054, 27.963802],
                            [113.573348, 27.962195],
                            [113.575689, 27.959747],
                            [113.576895, 27.956435],
                            [113.577463, 27.952552],
                            [113.57725, 27.947469],
                            [113.578101, 27.942402],
                            [113.57822, 27.939758],
                            [113.576777, 27.938779],
                            [113.575311, 27.938755],
                            [113.573253, 27.937939],
                            [113.572, 27.936625],
                            [113.571858, 27.934365],
                            [113.569895, 27.930734],
                            [113.569445, 27.927788],
                            [113.570391, 27.924703],
                            [113.572165, 27.921398],
                            [113.573111, 27.920451],
                            [113.573939, 27.917693],
                            [113.573111, 27.915204],
                            [113.571361, 27.912584],
                            [113.571858, 27.910445],
                            [113.573726, 27.905622],
                            [113.575973, 27.901141],
                            [113.577392, 27.899067],
                            [113.584061, 27.895998],
                            [113.58619, 27.89439],
                            [113.588035, 27.893402],
                            [113.589335, 27.892235],
                            [113.591393, 27.891247],
                            [113.592836, 27.891949],
                            [113.59442, 27.895525],
                            [113.597282, 27.90292],
                            [113.59688, 27.90705],
                            [113.59494, 27.909311],
                            [113.594799, 27.910331],
                            [113.596076, 27.912151],
                            [113.597305, 27.912951],
                            [113.600758, 27.913588],
                            [113.602177, 27.913555],
                            [113.606198, 27.911906],
                            [113.608563, 27.909548],
                            [113.610833, 27.907777],
                            [113.614996, 27.90603],
                            [113.618472, 27.90332],
                            [113.62325, 27.900226],
                            [113.628358, 27.898618],
                            [113.63245, 27.896929],
                            [113.63394, 27.89577],
                            [113.635714, 27.893084],
                            [113.636778, 27.890284],
                            [113.637937, 27.889288],
                            [113.641224, 27.887304],
                            [113.643684, 27.884464],
                            [113.64418, 27.881255],
                            [113.644914, 27.879745],
                            [113.64787, 27.878047],
                            [113.651701, 27.877483],
                            [113.654066, 27.877459],
                            [113.654941, 27.877818],
                            [113.655012, 27.88092],
                            [113.655485, 27.882227],
                            [113.658252, 27.884529],
                            [113.660665, 27.884904],
                            [113.662604, 27.883802],
                            [113.6675, 27.880267],
                            [113.669699, 27.87839],
                            [113.672206, 27.876847],
                            [113.678355, 27.875026],
                            [113.680767, 27.874838],
                            [113.686207, 27.87363],
                            [113.689991, 27.871768],
                            [113.696968, 27.869899],
                            [113.699428, 27.870225],
                            [113.701461, 27.871099],
                            [113.704134, 27.874128],
                            [113.707161, 27.875981],
                            [113.709621, 27.876634],
                            [113.711584, 27.877573],
                            [113.714966, 27.88061],
                            [113.718111, 27.882406],
                            [113.71979, 27.884578],
                            [113.721044, 27.885386],
                            [113.722889, 27.885745],
                            [113.730386, 27.884708],
                            [113.730244, 27.886872],
                            [113.730599, 27.88857],
                            [113.731521, 27.890276],
                            [113.73443, 27.897933],
                            [113.734572, 27.9007],
                            [113.735305, 27.902847],
                            [113.7367, 27.905744],
                            [113.738994, 27.909335],
                            [113.739822, 27.910241],
                            [113.743393, 27.913114],
                            [113.745002, 27.915954],
                            [113.745191, 27.918501],
                            [113.745829, 27.921439],
                            [113.74862, 27.926221],
                            [113.750205, 27.929738],
                            [113.752428, 27.933435],
                            [113.753445, 27.934683],
                            [113.75607, 27.936919],
                            [113.759712, 27.939],
                            [113.762881, 27.942141],
                            [113.766121, 27.946808],
                            [113.76948, 27.95114],
                            [113.771041, 27.952682],
                            [113.772223, 27.953262],
                            [113.77648, 27.95433],
                            [113.780454, 27.954322],
                            [113.784025, 27.954086],
                            [113.788022, 27.954355],
                            [113.792704, 27.955089],
                            [113.794975, 27.956019],
                            [113.795188, 27.957602],
                            [113.793698, 27.959837],
                            [113.793083, 27.962203],
                            [113.793887, 27.96452],
                            [113.796086, 27.968329],
                            [113.797269, 27.96992],
                            [113.800083, 27.972212],
                            [113.802969, 27.974047],
                            [113.807888, 27.975516],
                            [113.810418, 27.976503],
                            [113.813753, 27.978175],
                            [113.818838, 27.981503],
                            [113.821936, 27.982269],
                            [113.823261, 27.982057],
                            [113.825129, 27.980777],
                            [113.825578, 27.976437],
                            [113.826761, 27.97324],
                            [113.828842, 27.971625],
                            [113.8322, 27.971095],
                            [113.833832, 27.971225],
                            [113.838964, 27.972416],
                            [113.845255, 27.97169],
                            [113.849725, 27.973313],
                            [113.850837, 27.974121],
                            [113.852043, 27.976103],
                            [113.852327, 27.981021],
                            [113.852871, 27.982424],
                            [113.852421, 27.983158],
                            [113.85209, 27.987497],
                            [113.852209, 27.988631],
                            [113.853486, 27.991127],
                            [113.856111, 27.994258],
                            [113.858358, 27.996558],
                            [113.8585, 27.998907],
                            [113.859824, 28.001728],
                            [113.862473, 28.003955],
                            [113.864861, 28.005088],
                            [113.867203, 28.004884],
                            [113.869497, 28.004232],
                            [113.87146, 28.002723],
                            [113.874464, 27.998915],
                            [113.877349, 27.993875],
                            [113.878697, 27.991967],
                            [113.880021, 27.991298],
                            [113.881913, 27.991722],
                            [113.885177, 27.991526],
                            [113.887211, 27.990026],
                            [113.888938, 27.989765],
                            [113.894424, 27.987799],
                            [113.898516, 27.987399],
                            [113.900171, 27.987587],
                            [113.914291, 27.991355],
                            [113.915781, 27.993027],
                            [113.916301, 27.996501],
                            [113.91895, 28.00141],
                            [113.921031, 28.002666],
                            [113.925288, 28.004167],
                            [113.927369, 28.006564],
                            [113.929805, 28.010919],
                            [113.930657, 28.015346],
                            [113.934346, 28.018371],
                            [113.935529, 28.018917],
                            [113.937492, 28.018281],
                            [113.944066, 28.014188],
                            [113.951162, 28.013772],
                            [113.957334, 28.014245],
                            [113.961922, 28.015175],
                            [113.965565, 28.016863],
                            [113.966298, 28.0178],
                            [113.966321, 28.019276],
                            [113.964926, 28.021249],
                            [113.961639, 28.024437],
                            [113.959463, 28.025962],
                            [113.956979, 28.028416],
                            [113.956246, 28.030609],
                            [113.957145, 28.03343],
                            [113.957949, 28.034816],
                            [113.960291, 28.037514],
                            [113.961804, 28.038851],
                            [113.963341, 28.039642],
                            [113.968828, 28.041142],
                            [113.976113, 28.040987],
                            [113.978407, 28.040783],
                            [113.989262, 28.038721],
                            [113.997398, 28.040245],
                            [114.000567, 28.040432],
                            [114.002175, 28.039935],
                            [114.004848, 28.038207],
                            [114.010193, 28.038068],
                            [114.014994, 28.036356],
                            [114.017855, 28.03484],
                            [114.019606, 28.033462],
                            [114.022254, 28.032492],
                            [114.024548, 28.03228],
                            [114.025447, 28.031408],
                            [114.026819, 28.031807],
                            [114.027812, 28.036218],
                            [114.027316, 28.037848],
                            [114.027954, 28.040253],
                            [114.027552, 28.041794],
                            [114.029728, 28.042316],
                            [114.032992, 28.046163],
                            [114.034245, 28.049587],
                            [114.035924, 28.05085],
                            [114.037249, 28.051307],
                            [114.040583, 28.051714],
                            [114.04283, 28.05244],
                            [114.045715, 28.052888],
                            [114.045053, 28.055504],
                            [114.045763, 28.056988],
                            [114.047016, 28.057322],
                            [114.046874, 28.058292],
                            [114.044628, 28.062856],
                            [114.042948, 28.067738],
                            [114.041506, 28.068365],
                            [114.037438, 28.072619],
                            [114.038124, 28.074054],
                            [114.037367, 28.075333],
                            [114.038053, 28.075814],
                            [114.036303, 28.078153],
                            [114.033536, 28.079098],
                            [114.030532, 28.079465],
                            [114.029255, 28.080068],
                            [114.028238, 28.081567],
                            [114.025778, 28.080573],
                            [114.02417, 28.080915],
                            [114.021214, 28.083018],
                            [114.019085, 28.085446],
                            [114.019606, 28.085829],
                            [114.022325, 28.084704],
                            [114.023461, 28.086049],
                            [114.024099, 28.08534],
                            [114.027316, 28.08402],
                            [114.028356, 28.084216],
                            [114.028403, 28.086945],
                            [114.027907, 28.088942],
                            [114.026913, 28.091223],
                            [114.027032, 28.092388],
                            [114.024359, 28.0937],
                            [114.023058, 28.095835],
                            [114.022491, 28.099078],
                            [114.021663, 28.099811],
                            [114.018352, 28.100495],
                            [114.017359, 28.102768],
                            [114.016649, 28.107453],
                            [114.016531, 28.110768],
                            [114.01568, 28.117514],
                            [114.017075, 28.120169],
                            [114.016436, 28.121456],
                            [114.014805, 28.12333],
                            [114.013433, 28.123981],
                            [114.009199, 28.123745],
                            [114.007899, 28.124226],
                            [114.007213, 28.125431],
                            [114.00726, 28.131947],
                            [114.008135, 28.138048],
                            [114.006716, 28.140475],
                            [114.005983, 28.144848],
                            [113.999905, 28.147234],
                            [113.997634, 28.14993],
                            [113.995837, 28.152625],
                            [113.994678, 28.154873],
                            [113.99404, 28.157511],
                            [113.992999, 28.158855],
                            [113.992644, 28.161428],
                            [113.99411, 28.16396],
                            [113.995624, 28.164783],
                            [114.000212, 28.164425],
                            [114.005983, 28.165923],
                            [114.007355, 28.166566],
                            [114.010027, 28.171248],
                            [114.011257, 28.174415],
                            [114.012345, 28.175099],
                            [114.0162, 28.173959],
                            [114.025021, 28.172176],
                            [114.027292, 28.172095],
                            [114.031809, 28.173983],
                            [114.033819, 28.174057],
                            [114.036468, 28.173633],
                            [114.041009, 28.173397],
                            [114.043398, 28.174],
                            [114.045905, 28.176035],
                            [114.048033, 28.176686],
                            [114.049854, 28.17505],
                            [114.052172, 28.171875],
                            [114.053473, 28.171085],
                            [114.060331, 28.170995],
                            [114.06868, 28.171655],
                            [114.072464, 28.17273],
                            [114.07542, 28.173845],
                            [114.07762, 28.174163],
                            [114.080387, 28.175278],
                            [114.081924, 28.177688],
                            [114.083769, 28.179202],
                            [114.092023, 28.180138],
                            [114.093324, 28.180953],
                            [114.095523, 28.18149],
                            [114.100632, 28.180969],
                            [114.102807, 28.180985],
                            [114.105314, 28.181514],
                            [114.107254, 28.182972],
                            [114.107821, 28.184404],
                            [114.107798, 28.185902],
                            [114.106662, 28.189028],
                            [114.10671, 28.190632],
                            [114.107585, 28.193042],
                            [114.107939, 28.195052],
                            [114.106615, 28.198194],
                            [114.106284, 28.199668],
                            [114.106639, 28.201629],
                            [114.109453, 28.20491],
                            [114.111629, 28.205959],
                            [114.117187, 28.210705],
                            [114.120025, 28.212153],
                            [114.121231, 28.213138],
                            [114.12291, 28.21733],
                            [114.126245, 28.220219],
                            [114.128657, 28.223384],
                            [114.12965, 28.228153],
                            [114.135468, 28.231221],
                            [114.137337, 28.232385],
                            [114.14377, 28.238162],
                            [114.144668, 28.239309],
                            [114.144905, 28.242002],
                            [114.143959, 28.245208],
                            [114.144077, 28.246689],
                            [114.145023, 28.247787],
                            [114.14876, 28.249236],
                            [114.152095, 28.249545],
                            [114.153656, 28.24904],
                            [114.155997, 28.248918],
                            [114.158811, 28.25],
                            [114.165859, 28.253019],
                            [114.16794, 28.253556],
                            [114.170707, 28.253515],
                            [114.173664, 28.252734],
                            [114.177235, 28.250985],
                            [114.180546, 28.249805],
                            [114.182154, 28.250049],
                            [114.182462, 28.251424],
                            [114.182131, 28.252791],
                            [114.183691, 28.25681],
                            [114.183313, 28.26139],
                            [114.183904, 28.265572],
                            [114.184661, 28.267638],
                            [114.186482, 28.267589],
                            [114.188682, 28.266865],
                            [114.193057, 28.267914],
                            [114.196273, 28.268288],
                            [114.198378, 28.269785],
                            [114.199135, 28.272478],
                            [114.197858, 28.276829],
                            [114.198804, 28.278554],
                            [114.198402, 28.280148],
                            [114.196439, 28.28197],
                            [114.196155, 28.284426],
                            [114.198142, 28.290738],
                            [114.199939, 28.291892],
                            [114.20202, 28.291112],
                            [114.204646, 28.291925],
                            [114.208122, 28.294129],
                            [114.21044, 28.294714],
                            [114.215667, 28.294007],
                            [114.219001, 28.295861],
                            [114.221958, 28.298341],
                            [114.224772, 28.300309],
                            [114.227161, 28.301017],
                            [114.228556, 28.302562],
                            [114.229053, 28.305384],
                            [114.230921, 28.306709],
                            [114.232955, 28.307181],
                            [114.235131, 28.310921],
                            [114.238016, 28.311336],
                            [114.239956, 28.31193],
                            [114.241682, 28.313035],
                            [114.242581, 28.314759],
                            [114.244, 28.315621],
                            [114.245537, 28.315922],
                            [114.250622, 28.318199],
                            [114.254098, 28.320906],
                            [114.255683, 28.323614],
                            [114.255517, 28.326597],
                            [114.254595, 28.328126],
                            [114.253034, 28.329443],
                            [114.250409, 28.333077],
                            [114.247122, 28.338085],
                            [114.247855, 28.341133],
                            [114.248493, 28.342401],
                            [114.252206, 28.346717],
                            [114.25372, 28.349766],
                            [114.255659, 28.350644],
                            [114.257859, 28.349969],
                            [114.259727, 28.350099],
                            [114.261265, 28.352237],
                            [114.260933, 28.354],
                            [114.260129, 28.355707],
                            [114.258687, 28.357536],
                            [114.256203, 28.35969],
                            [114.251592, 28.362453],
                            [114.250598, 28.363876],
                            [114.249865, 28.365989],
                            [114.250622, 28.368118],
                            [114.255565, 28.37119],
                            [114.258072, 28.372929],
                            [114.259278, 28.375229],
                            [114.259112, 28.378268],
                            [114.256345, 28.381852],
                            [114.256085, 28.383217],
                            [114.25599, 28.392098],
                            [114.255021, 28.393918],
                            [114.252467, 28.395738],
                            [114.249368, 28.396225],
                            [114.247287, 28.396038],
                            [114.242699, 28.396461],
                            [114.240547, 28.397062],
                            [114.236148, 28.396924],
                            [114.233333, 28.398793],
                            [114.229218, 28.396648],
                            [114.228154, 28.395437],
                            [114.226451, 28.396794],
                            [114.22586, 28.398167],
                            [114.223944, 28.400556],
                            [114.222525, 28.401628],
                            [114.220775, 28.402311],
                            [114.217346, 28.402107],
                            [114.214626, 28.403066],
                            [114.213491, 28.406486],
                            [114.210795, 28.41037],
                            [114.21018, 28.412027],
                            [114.208028, 28.415504],
                            [114.206703, 28.418525],
                            [114.204527, 28.421775],
                            [114.202233, 28.424447],
                            [114.193388, 28.430864],
                            [114.189107, 28.431798],
                            [114.186388, 28.431197],
                            [114.182769, 28.431765],
                            [114.181279, 28.432553],
                            [114.178678, 28.433284],
                            [114.172363, 28.43278],
                            [114.171843, 28.434616],
                            [114.173238, 28.436167],
                            [114.175485, 28.437036],
                            [114.180853, 28.436784],
                            [114.182982, 28.436866],
                            [114.187546, 28.438953],
                            [114.19003, 28.441316],
                            [114.191401, 28.446303],
                            [114.192158, 28.447626],
                            [114.193766, 28.449072],
                            [114.196959, 28.450639],
                            [114.199655, 28.453457],
                            [114.202304, 28.459263],
                            [114.206443, 28.459864],
                            [114.208051, 28.460392],
                            [114.211197, 28.462527],
                            [114.21491, 28.464736],
                            [114.216897, 28.466408],
                            [114.218552, 28.468649],
                            [114.219309, 28.470549],
                            [114.219711, 28.476102],
                            [114.219427, 28.479082],
                            [114.218789, 28.482629],
                            [114.218197, 28.48457],
                            [114.216897, 28.485698],
                            [114.213065, 28.487167],
                            [114.210842, 28.487776],
                            [114.205899, 28.491234],
                            [114.204456, 28.49272],
                            [114.200696, 28.494416],
                            [114.19729, 28.497477],
                            [114.195588, 28.498556],
                            [114.192418, 28.499449],
                            [114.185205, 28.497403],
                            [114.180049, 28.496908],
                            [114.174657, 28.497379],
                            [114.17189, 28.498053],
                            [114.168886, 28.499343],
                            [114.164606, 28.501949],
                            [114.15997, 28.506786],
                            [114.154649, 28.507549],
                            [114.151622, 28.511258],
                            [114.148925, 28.51547],
                            [114.147294, 28.517686],
                            [114.143841, 28.519212],
                            [114.142682, 28.520234],
                            [114.140624, 28.523423],
                            [114.139678, 28.526385],
                            [114.139063, 28.529891],
                            [114.138117, 28.533713],
                            [114.136911, 28.53536],
                            [114.133671, 28.536545],
                            [114.13114, 28.53652],
                            [114.128042, 28.536098],
                            [114.126387, 28.535514],
                            [114.125417, 28.534411],
                            [114.123218, 28.534735],
                            [114.121302, 28.537583],
                            [114.118511, 28.538995],
                            [114.11468, 28.539133],
                            [114.109429, 28.538573],
                            [114.104628, 28.53893],
                            [114.100655, 28.540317],
                            [114.098976, 28.541283],
                            [114.09732, 28.542808],
                            [114.094624, 28.546216],
                            [114.091881, 28.547904],
                            [114.09032, 28.549729],
                            [114.086772, 28.555887],
                            [114.081569, 28.554783],
                            [114.075893, 28.555408],
                            [114.073883, 28.555205],
                            [114.071329, 28.552998],
                            [114.068443, 28.550962],
                            [114.06596, 28.547717],
                            [114.062271, 28.546127],
                            [114.05728, 28.545315],
                            [114.053284, 28.544171],
                            [114.051723, 28.544374],
                            [114.044959, 28.548918],
                            [114.043067, 28.550037],
                            [114.040063, 28.551027],
                            [114.037272, 28.550183],
                            [114.032258, 28.547652],
                            [114.030319, 28.546427],
                            [114.027292, 28.543693],
                            [114.025447, 28.542808],
                            [114.019582, 28.542898],
                            [114.017619, 28.543603],
                            [114.015065, 28.545689],
                            [114.014568, 28.552033],
                            [114.013551, 28.554597],
                            [114.010926, 28.55673],
                            [114.006007, 28.558937],
                            [114.00201, 28.562182],
                            [113.995766, 28.564899],
                            [113.993354, 28.564794],
                            [113.990279, 28.563179],
                            [113.987417, 28.562839],
                            [113.983515, 28.562879],
                            [113.979045, 28.56421],
                            [113.975994, 28.564737],
                            [113.974292, 28.563974],
                            [113.970626, 28.558775],
                            [113.966014, 28.550118],
                            [113.963578, 28.54379],
                            [113.962443, 28.542338],
                            [113.959486, 28.541437],
                            [113.953219, 28.538102],
                            [113.950996, 28.536415],
                            [113.948016, 28.532098],
                            [113.944847, 28.528901],
                            [113.941252, 28.526369],
                            [113.939833, 28.52654],
                            [113.938414, 28.528325],
                            [113.935434, 28.531392],
                            [113.932525, 28.5308],
                            [113.927771, 28.528893],
                            [113.917862, 28.525444],
                            [113.91377, 28.525525],
                            [113.911122, 28.526085],
                            [113.906959, 28.527578],
                            [113.903199, 28.528609],
                            [113.901661, 28.528292],
                            [113.900053, 28.527221],
                            [113.897594, 28.524876],
                            [113.89537, 28.522214],
                            [113.889529, 28.515625],
                            [113.888559, 28.515097],
                            [113.88565, 28.515162],
                            [113.880234, 28.516972],
                            [113.876497, 28.518903],
                            [113.873447, 28.521054],
                            [113.868693, 28.524024],
                            [113.862118, 28.529396],
                            [113.860581, 28.531059],
                            [113.858452, 28.532228],
                            [113.85578, 28.531838],
                            [113.851783, 28.529574],
                            [113.846154, 28.525436],
                            [113.844428, 28.523902],
                            [113.843292, 28.521963],
                            [113.84062, 28.514837],
                            [113.838468, 28.509944],
                            [113.836718, 28.506884],
                            [113.835109, 28.503093],
                            [113.832461, 28.498865],
                            [113.832129, 28.497006],
                            [113.832271, 28.495131],
                            [113.83317, 28.492306],
                            [113.832981, 28.489043],
                            [113.827849, 28.485576],
                            [113.824892, 28.484107],
                            [113.823237, 28.4806],
                            [113.817537, 28.466879],
                            [113.815149, 28.464849],
                            [113.806398, 28.458646],
                            [113.803513, 28.457152],
                            [113.801289, 28.45677],
                            [113.797836, 28.456786],
                            [113.789677, 28.457972],
                            [113.78743, 28.458021],
                            [113.784971, 28.457558],
                            [113.779744, 28.453286],
                            [113.776764, 28.45193],
                            [113.773902, 28.451037],
                            [113.770355, 28.45042],
                            [113.768415, 28.45046],
                            [113.763733, 28.451508],
                            [113.761675, 28.451378],
                            [113.756259, 28.449502],
                            [113.750347, 28.448325],
                            [113.746397, 28.446928],
                            [113.740366, 28.444451],
                            [113.73197, 28.442364],
                            [113.729724, 28.4413],
                            [113.727217, 28.438888],
                            [113.721659, 28.431741],
                            [113.719625, 28.425105],
                            [113.717757, 28.424016],
                            [113.713807, 28.42357],
                            [113.704678, 28.422181],
                            [113.703401, 28.422554],
                            [113.699664, 28.42556],
                            [113.696093, 28.427793],
                            [113.691245, 28.432675],
                            [113.684622, 28.437589],
                            [113.682423, 28.438693],
                            [113.67696, 28.442031],
                            [113.673128, 28.443972],
                            [113.669983, 28.445052],
                            [113.665773, 28.445767],
                            [113.663668, 28.4464],
                            [113.659766, 28.446725],
                            [113.657992, 28.44731],
                            [113.656455, 28.448666],
                            [113.656668, 28.449941],
                            [113.661421, 28.454464],
                            [113.66109, 28.456997],
                            [113.65908, 28.459336],
                            [113.656313, 28.46083],
                            [113.654232, 28.46234],
                            [113.652907, 28.464143],
                            [113.649738, 28.470021],
                            [113.646096, 28.473797],
                            [113.643755, 28.475696],
                            [113.641934, 28.47646],
                            [113.640609, 28.477604],
                            [113.638362, 28.480567],
                            [113.635808, 28.482605],
                            [113.63193, 28.482305],
                            [113.629635, 28.482767],
                            [113.629021, 28.484545],
                            [113.625828, 28.489635],
                            [113.62429, 28.491007],
                            [113.619418, 28.492955],
                            [113.615327, 28.495748],
                            [113.612111, 28.4988],
                            [113.607262, 28.503085],
                            [113.598133, 28.511989],
                            [113.594704, 28.51629],
                            [113.588295, 28.523464],
                            [113.585528, 28.526182],
                            [113.582004, 28.532033],
                            [113.578432, 28.535336],
                            [113.575926, 28.536374],
                            [113.573277, 28.535214],
                            [113.569658, 28.53424],
                            [113.566962, 28.533055],
                            [113.564029, 28.53295],
                            [113.562587, 28.532561],
                            [113.558022, 28.533169],
                            [113.556816, 28.532739],
                            [113.55412, 28.530629],
                            [113.552015, 28.526499],
                            [113.550501, 28.525558],
                            [113.548491, 28.524957],
                            [113.543548, 28.525874],
                            [113.54116, 28.526864],
                            [113.537778, 28.527611],
                            [113.535152, 28.527789],
                            [113.530446, 28.528503],
                            [113.528199, 28.528284],
                            [113.524794, 28.530337],
                            [113.521624, 28.533721],
                            [113.517746, 28.536325],
                            [113.515404, 28.537015],
                            [113.511124, 28.536342],
                            [113.507363, 28.53695],
                            [113.506819, 28.532926],
                            [113.506867, 28.529031],
                            [113.505731, 28.522839],
                            [113.504383, 28.520875],
                            [113.502089, 28.519479],
                            [113.500552, 28.517913],
                            [113.500032, 28.516566],
                            [113.497903, 28.514164],
                            [113.495349, 28.513166],
                            [113.490595, 28.514001],
                            [113.48797, 28.513287],
                            [113.483949, 28.511688],
                            [113.480567, 28.510763],
                            [113.476098, 28.510122],
                            [113.474513, 28.509684],
                            [113.475506, 28.505447],
                            [113.47553, 28.502963],
                            [113.474513, 28.501275],
                            [113.472455, 28.500447],
                            [113.470232, 28.500244],
                            [113.468151, 28.500772],
                            [113.465455, 28.500902],
                            [113.462404, 28.499067],
                            [113.461363, 28.49755],
                            [113.459826, 28.496835],
                            [113.452424, 28.495309],
                            [113.447457, 28.494619],
                            [113.440622, 28.493929],
                            [113.43821, 28.494676],
                            [113.436933, 28.495934],
                            [113.435679, 28.499717],
                            [113.434095, 28.498605],
                            [113.431091, 28.488921],
                            [113.430311, 28.487062],
                            [113.429033, 28.485487],
                            [113.42506, 28.483596],
                            [113.418864, 28.482394],
                            [113.416168, 28.481623],
                            [113.409971, 28.480413],
                            [113.408434, 28.479463],
                            [113.407724, 28.478173],
                            [113.407654, 28.476419],
                            [113.411887, 28.470979],
                            [113.412123, 28.467878],
                            [113.412928, 28.464265],
                            [113.412928, 28.462681],
                            [113.412194, 28.459531],
                            [113.411177, 28.457956],
                            [113.408789, 28.456275],
                            [113.405927, 28.45539],
                            [113.399258, 28.45435],
                            [113.394977, 28.45392],
                            [113.388781, 28.451914],
                            [113.384145, 28.449795],
                            [113.379533, 28.448739],
                            [113.37776, 28.448861],
                            [113.375513, 28.448357],
                            [113.371019, 28.444654],
                            [113.369009, 28.442405],
                            [113.368134, 28.440488],
                            [113.368157, 28.437215],
                            [113.367495, 28.434397],
                            [113.366668, 28.432821],
                            [113.365438, 28.43153],
                            [113.362883, 28.430693],
                            [113.360826, 28.430531],
                            [113.357302, 28.429751],
                            [113.356332, 28.42733],
                            [113.356001, 28.424284],
                            [113.354393, 28.422376],
                            [113.352028, 28.422262],
                            [113.347227, 28.422733],
                            [113.344838, 28.421555],
                            [113.341953, 28.418631],
                            [113.337909, 28.416836],
                            [113.333983, 28.413798],
                            [113.329868, 28.409809],
                            [113.32878, 28.407494],
                            [113.328188, 28.404455],
                            [113.328307, 28.402871],
                            [113.329442, 28.400556],
                            [113.330956, 28.396323],
                            [113.335307, 28.384801],
                            [113.337128, 28.378252],
                            [113.339375, 28.369272],
                            [113.339541, 28.365883],
                            [113.339091, 28.363745],
                            [113.33798, 28.361324],
                            [113.333439, 28.358739],
                            [113.332516, 28.356772],
                            [113.332446, 28.354968],
                            [113.333013, 28.350392],
                            [113.334574, 28.343791],
                            [113.335331, 28.341474],
                            [113.336159, 28.340231],
                            [113.336371, 28.336109],
                            [113.332564, 28.329858],
                            [113.332067, 28.328451],
                            [113.330979, 28.321687],
                            [113.330908, 28.315873],
                            [113.330695, 28.314182],
                            [113.329655, 28.312548],
                            [113.325303, 28.309572],
                            [113.325043, 28.307661],
                            [113.327455, 28.304603],
                            [113.327692, 28.303139],
                            [113.327313, 28.301277],
                            [113.324996, 28.298911],
                            [113.32159, 28.296219],
                            [113.317688, 28.293519],
                            [113.315583, 28.293633],
                            [113.312887, 28.296121],
                            [113.311373, 28.295853],
                            [113.310522, 28.294666],
                            [113.31012, 28.291901],
                            [113.30889, 28.288916],
                            [113.307092, 28.287118],
                            [113.305106, 28.285939],
                            [113.303639, 28.284589],
                            [113.302481, 28.281661],
                            [113.301156, 28.276951],
                            [113.300139, 28.275821],
                            [113.29943, 28.273649],
                            [113.299761, 28.270859],
                            [113.299051, 28.263391],
                            [113.298342, 28.260178],
                            [113.295811, 28.254662],
                            [113.293517, 28.253483],
                            [113.288976, 28.25214],
                            [113.287746, 28.250627],
                            [113.289142, 28.247291],
                            [113.289071, 28.245485],
                            [113.284908, 28.242116],
                            [113.284199, 28.240424],
                            [113.283915, 28.235631],
                            [113.282638, 28.233491],
                            [113.278523, 28.231197],
                            [113.273485, 28.229178],
                            [113.270032, 28.228178],
                            [113.2684, 28.227331],
                            [113.267076, 28.2248],
                            [113.267029, 28.222953],
                            [113.268117, 28.219877],
                            [113.272894, 28.213952],
                            [113.276583, 28.209883],
                            [113.280202, 28.205129],
                            [113.280817, 28.20084],
                            [113.280178, 28.198585],
                            [113.278759, 28.197519],
                            [113.278239, 28.19572],
                            [113.278854, 28.193685],
                            [113.282094, 28.188027],
                            [113.282851, 28.187172],
                            [113.285523, 28.187001],
                            [113.28654, 28.185927],
                            [113.2868, 28.184193],
                            [113.288929, 28.180676],
                            [113.292193, 28.173983],
                            [113.295456, 28.173902],
                            [113.297774, 28.175823],
                            [113.299335, 28.177655],
                            [113.301818, 28.178209],
                            [113.305413, 28.179951],
                            [113.307967, 28.181465],
                            [113.312106, 28.181791],
                            [113.315843, 28.183778],
                            [113.317853, 28.183997],
                            [113.321022, 28.185007],
                            [113.322347, 28.185837],
                            [113.324333, 28.18644],
                            [113.332848, 28.191096],
                            [113.336111, 28.193343],
                            [113.337838, 28.193677],
                            [113.340203, 28.192708],
                            [113.340699, 28.191357],
                            [113.339257, 28.187189],
                            [113.340156, 28.185886],
                            [113.341551, 28.184868],
                            [113.34323, 28.184811],
                            [113.345595, 28.186944],
                            [113.346494, 28.189256],
                            [113.348622, 28.190429],
                            [113.354227, 28.190697],
                            [113.356285, 28.191137],
                            [113.35813, 28.192773],
                            [113.359501, 28.196265],
                            [113.36085, 28.197047],
                            [113.362552, 28.196705],
                            [113.364279, 28.194898],
                            [113.36811, 28.193636],
                            [113.369742, 28.192447],
                            [113.370262, 28.191153],
                            [113.37005, 28.187148],
                            [113.369316, 28.185349],
                            [113.366195, 28.183159],
                            [113.35891, 28.181091],
                            [113.356569, 28.17698],
                            [113.356309, 28.17518],
                            [113.354677, 28.169652],
                            [113.35366, 28.167462],
                            [113.352477, 28.164083],
                            [113.352099, 28.162275],
                            [113.354464, 28.162096],
                            [113.356758, 28.162316],
                            [113.360873, 28.157511],
                            [113.363735, 28.154849],
                            [113.365012, 28.153212],
                            [113.365178, 28.151575],
                            [113.36286, 28.149042],
                            [113.360306, 28.14708],
                            [113.359478, 28.145899],
                            [113.359005, 28.142739],
                            [113.361559, 28.142283],
                            [113.362765, 28.14357],
                            [113.367519, 28.147055],
                            [113.37012, 28.148114],
                            [113.377499, 28.151526],
                            [113.382537, 28.153204],
                            [113.390956, 28.155419],
                            [113.397791, 28.157585],
                            [113.402829, 28.158244],
                            [113.404815, 28.156819],
                            [113.405809, 28.155402],
                            [113.406235, 28.150443],
                            [113.407748, 28.148798],
                            [113.413069, 28.145614],
                            [113.4156, 28.143342],
                            [113.418651, 28.142763],
                            [113.425533, 28.143676],
                            [113.428655, 28.143659],
                            [113.432581, 28.142446],
                            [113.432132, 28.140361],
                            [113.430854, 28.138683],
                            [113.42428, 28.132884],
                            [113.422246, 28.131988],
                            [113.413519, 28.123126],
                            [113.408481, 28.12157],
                            [113.404366, 28.119509],
                            [113.403089, 28.118312],
                            [113.40264, 28.11696],
                            [113.400984, 28.114996],
                            [113.399329, 28.113758],
                            [113.389939, 28.111208],
                            [113.385966, 28.108064],
                            [113.38379, 28.106948],
                            [113.38003, 28.104088],
                            [113.378091, 28.101277],
                            [113.376293, 28.099192],
                            [113.37407, 28.097961],
                            [113.372154, 28.097521],
                            [113.367992, 28.095501],
                            [113.366455, 28.093643],
                            [113.365532, 28.089137],
                            [113.365201, 28.08587],
                            [113.363144, 28.084126],
                            [113.361346, 28.083629],
                            [113.358153, 28.081209],
                            [113.356805, 28.078503],
                            [113.355079, 28.075969],
                            [113.350207, 28.071479],
                            [113.346044, 28.068838],
                            [113.341882, 28.066654],
                            [113.338429, 28.064405],
                            [113.334976, 28.062774],
                            [113.332304, 28.06222],
                            [113.328543, 28.064038],
                            [113.327195, 28.063312],
                            [113.325563, 28.057558],
                            [113.324144, 28.056156],
                            [113.320668, 28.055822],
                            [113.312508, 28.057314],
                            [113.308913, 28.056417],
                            [113.305839, 28.052937],
                            [113.30494, 28.051413],
                            [113.305271, 28.049489],
                            [113.311208, 28.041125],
                            [113.312981, 28.039267],
                            [113.315536, 28.038468],
                            [113.319603, 28.037946],
                            [113.320881, 28.036365],
                            [113.318634, 28.034057],
                            [113.316009, 28.032264],
                            [113.308038, 28.03087],
                            [113.30371, 28.031506],
                            [113.300589, 28.033601],
                            [113.299524, 28.036283],
                            [113.301582, 28.039014],
                            [113.302268, 28.040816],
                            [113.302102, 28.043261],
                            [113.301487, 28.044313],
                            [113.30021, 28.044981],
                            [113.297088, 28.04503],
                            [113.284956, 28.046799],
                            [113.281739, 28.047622],
                            [113.278121, 28.049065],
                            [113.273698, 28.051217],
                            [113.269228, 28.054062],
                            [113.256268, 28.06006],
                            [113.253099, 28.062114],
                            [113.24896, 28.066719],
                            [113.244845, 28.071927],
                            [113.24248, 28.074543],
                            [113.240115, 28.075716],
                            [113.239027, 28.07535],
                            [113.237229, 28.072473],
                            [113.234959, 28.070321],
                            [113.233564, 28.068137],
                            [113.232901, 28.065497],
                            [113.233303, 28.062815],
                            [113.234273, 28.061511],
                            [113.238317, 28.059131],
                            [113.238956, 28.058137],
                            [113.238861, 28.055455],
                            [113.237939, 28.053727],
                            [113.23581, 28.051902],
                            [113.233067, 28.050899],
                            [113.229685, 28.051355],
                            [113.227533, 28.053092],
                            [113.226468, 28.057966],
                            [113.225664, 28.05927],
                            [113.223701, 28.060843],
                            [113.220343, 28.060981],
                            [113.2176, 28.060786],
                            [113.211167, 28.058161],
                            [113.208021, 28.055521],
                            [113.204497, 28.05019],
                            [113.20367, 28.049415],
                            [113.201423, 28.049212],
                            [113.200642, 28.050059],
                            [113.197828, 28.056099],
                            [113.195818, 28.057729],
                            [113.194517, 28.058039],
                            [113.190922, 28.058161],
                            [113.187895, 28.057852],
                            [113.183543, 28.056719],
                            [113.184915, 28.05464],
                            [113.186381, 28.053393],
                            [113.186736, 28.051135],
                            [113.18527, 28.047304],
                            [113.184229, 28.040995],
                            [113.184536, 28.037555],
                            [113.185341, 28.031514],
                            [113.185388, 28.029084],
                            [113.184607, 28.025089],
                            [113.181036, 28.019015],
                            [113.177228, 28.01493]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430182,
                "name": "宁乡市",
                "center": [112.553182, 28.253928],
                "centroid": [112.353146, 28.125286],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430100
                },
                "subFeatureIndex": 8,
                "acroutes": [100000, 430000, 430100]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.651837, 28.487833],
                            [112.654414, 28.484505],
                            [112.650962, 28.481404],
                            [112.643914, 28.473293],
                            [112.639846, 28.469323],
                            [112.637741, 28.468316],
                            [112.635754, 28.467862],
                            [112.631072, 28.46791],
                            [112.627311, 28.468259],
                            [112.627359, 28.467602],
                            [112.625679, 28.455],
                            [112.624284, 28.446067],
                            [112.623504, 28.44454],
                            [112.618111, 28.435339],
                            [112.612861, 28.426713],
                            [112.612128, 28.424544],
                            [112.610401, 28.423545],
                            [112.603732, 28.420353],
                            [112.599144, 28.418712],
                            [112.59737, 28.417632],
                            [112.595383, 28.415804],
                            [112.594768, 28.41461],
                            [112.594366, 28.407591],
                            [112.592995, 28.405487],
                            [112.590559, 28.403927],
                            [112.586349, 28.40305],
                            [112.584268, 28.402416],
                            [112.582399, 28.398858],
                            [112.582423, 28.393016],
                            [112.581997, 28.391212],
                            [112.580271, 28.386735],
                            [112.579609, 28.384411],
                            [112.57845, 28.382355],
                            [112.579845, 28.379617],
                            [112.580484, 28.374993],
                            [112.580342, 28.372905],
                            [112.579845, 28.372157],
                            [112.576179, 28.369971],
                            [112.575446, 28.369052],
                            [112.575351, 28.363632],
                            [112.575588, 28.36121],
                            [112.575186, 28.354602],
                            [112.573389, 28.351188],
                            [112.570834, 28.348961],
                            [112.564047, 28.345937],
                            [112.562486, 28.344734],
                            [112.562533, 28.342759],
                            [112.564236, 28.337247],
                            [112.564141, 28.334874],
                            [112.564425, 28.333126],
                            [112.565395, 28.332183],
                            [112.56925, 28.330646],
                            [112.571402, 28.327402],
                            [112.572513, 28.326451],
                            [112.571402, 28.324971],
                            [112.570598, 28.324996],
                            [112.568871, 28.326191],
                            [112.566081, 28.327101],
                            [112.562888, 28.326841],
                            [112.560499, 28.325402],
                            [112.556431, 28.320329],
                            [112.553924, 28.318947],
                            [112.552647, 28.31893],
                            [112.549904, 28.31963],
                            [112.547208, 28.32067],
                            [112.543991, 28.322662],
                            [112.541815, 28.323207],
                            [112.537464, 28.323719],
                            [112.535997, 28.324378],
                            [112.535784, 28.325451],
                            [112.539971, 28.329394],
                            [112.539687, 28.331085],
                            [112.538717, 28.331914],
                            [112.536991, 28.332524],
                            [112.534578, 28.331988],
                            [112.532308, 28.330776],
                            [112.530416, 28.329394],
                            [112.528808, 28.327744],
                            [112.52597, 28.325963],
                            [112.522422, 28.324963],
                            [112.519182, 28.324817],
                            [112.516155, 28.324272],
                            [112.510266, 28.322061],
                            [112.508066, 28.32102],
                            [112.505299, 28.320727],
                            [112.504046, 28.323475],
                            [112.502319, 28.326451],
                            [112.500782, 28.327419],
                            [112.4996, 28.327191],
                            [112.499292, 28.325353],
                            [112.501326, 28.322321],
                            [112.503171, 28.318028],
                            [112.503336, 28.31545],
                            [112.5031, 28.313499],
                            [112.50187, 28.312174],
                            [112.500971, 28.311889],
                            [112.497282, 28.313019],
                            [112.493025, 28.312539],
                            [112.49066, 28.311556],
                            [112.486923, 28.309246],
                            [112.485244, 28.309409],
                            [112.481602, 28.311523],
                            [112.480608, 28.312361],
                            [112.477463, 28.31306],
                            [112.475878, 28.31432],
                            [112.473797, 28.317264],
                            [112.471598, 28.317605],
                            [112.468452, 28.314101],
                            [112.467435, 28.31406],
                            [112.466016, 28.312889],
                            [112.463675, 28.313621],
                            [112.461333, 28.314922],
                            [112.459654, 28.314686],
                            [112.459063, 28.31406],
                            [112.456556, 28.314702],
                            [112.453127, 28.317223],
                            [112.451258, 28.317865],
                            [112.449248, 28.317873],
                            [112.445937, 28.317199],
                            [112.443761, 28.31715],
                            [112.442981, 28.316296],
                            [112.441869, 28.313149],
                            [112.440947, 28.312239],
                            [112.438345, 28.310629],
                            [112.436098, 28.310889],
                            [112.435365, 28.31197],
                            [112.435294, 28.313922],
                            [112.435649, 28.315873],
                            [112.435318, 28.317703],
                            [112.434396, 28.318792],
                            [112.432977, 28.317922],
                            [112.432149, 28.316133],
                            [112.429311, 28.311133],
                            [112.428128, 28.309986],
                            [112.425692, 28.309734],
                            [112.42451, 28.310588],
                            [112.422854, 28.312808],
                            [112.421175, 28.31354],
                            [112.420087, 28.312556],
                            [112.420442, 28.309637],
                            [112.422689, 28.305587],
                            [112.42231, 28.304611],
                            [112.419472, 28.302521],
                            [112.416705, 28.301188],
                            [112.41285, 28.29835],
                            [112.411573, 28.29617],
                            [112.409847, 28.294763],
                            [112.408475, 28.294357],
                            [112.406914, 28.295723],
                            [112.406299, 28.297951],
                            [112.404809, 28.298691],
                            [112.403012, 28.297073],
                            [112.401522, 28.294454],
                            [112.397241, 28.294292],
                            [112.396177, 28.293755],
                            [112.395065, 28.291486],
                            [112.393764, 28.290274],
                            [112.389626, 28.289371],
                            [112.387663, 28.289379],
                            [112.383098, 28.290485],
                            [112.381892, 28.29443],
                            [112.380922, 28.295398],
                            [112.379953, 28.295389],
                            [112.378841, 28.294064],
                            [112.378746, 28.292218],
                            [112.37799, 28.290038],
                            [112.375364, 28.287094],
                            [112.373094, 28.285524],
                            [112.370327, 28.28441],
                            [112.368506, 28.284678],
                            [112.366023, 28.286777],
                            [112.362901, 28.287492],
                            [112.360678, 28.288444],
                            [112.352802, 28.291234],
                            [112.348758, 28.291657],
                            [112.347079, 28.291291],
                            [112.345281, 28.289501],
                            [112.345612, 28.28881],
                            [112.348498, 28.286037],
                            [112.349018, 28.285004],
                            [112.348237, 28.283824],
                            [112.349136, 28.281848],
                            [112.352849, 28.282011],
                            [112.358644, 28.283491],
                            [112.359306, 28.282694],
                            [112.358691, 28.280457],
                            [112.356799, 28.277293],
                            [112.35363, 28.275447],
                            [112.350248, 28.274462],
                            [112.346771, 28.274804],
                            [112.34372, 28.27687],
                            [112.341119, 28.278334],
                            [112.340007, 28.279758],
                            [112.3375, 28.281279],
                            [112.335277, 28.283101],
                            [112.332936, 28.284386],
                            [112.329861, 28.28476],
                            [112.326834, 28.284101],
                            [112.324043, 28.283955],
                            [112.32208, 28.284735],
                            [112.320188, 28.285004],
                            [112.317918, 28.284865],
                            [112.316783, 28.283995],
                            [112.316641, 28.282962],
                            [112.317658, 28.280913],
                            [112.319148, 28.274674],
                            [112.321725, 28.270802],
                            [112.322861, 28.26789],
                            [112.322884, 28.265083],
                            [112.322459, 28.263245],
                            [112.320259, 28.261846],
                            [112.316428, 28.262318],
                            [112.308647, 28.261789],
                            [112.306424, 28.261349],
                            [112.305501, 28.259332],
                            [112.304011, 28.257998],
                            [112.30167, 28.256672],
                            [112.301623, 28.253662],
                            [112.299802, 28.252539],
                            [112.295734, 28.248015],
                            [112.294126, 28.247088],
                            [112.292257, 28.248577],
                            [112.290294, 28.252246],
                            [112.288308, 28.254076],
                            [112.286274, 28.254857],
                            [112.284263, 28.255012],
                            [112.280692, 28.252636],
                            [112.279699, 28.251261],
                            [112.279557, 28.249781],
                            [112.280645, 28.248446],
                            [112.278564, 28.247429],
                            [112.276151, 28.24734],
                            [112.273786, 28.244378],
                            [112.272083, 28.242897],
                            [112.269293, 28.241604],
                            [112.26856, 28.239838],
                            [112.267448, 28.239041],
                            [112.266005, 28.236762],
                            [112.264421, 28.235688],
                            [112.261441, 28.235485],
                            [112.26066, 28.234744],
                            [112.25742, 28.234085],
                            [112.255741, 28.233467],
                            [112.254086, 28.231823],
                            [112.252477, 28.228218],
                            [112.252454, 28.226574],
                            [112.251697, 28.224393],
                            [112.24971, 28.222538],
                            [112.245855, 28.219869],
                            [112.242544, 28.216288],
                            [112.24245, 28.214562],
                            [112.241693, 28.212951],
                            [112.239824, 28.211274],
                            [112.237956, 28.21046],
                            [112.237105, 28.211022],
                            [112.235993, 28.213944],
                            [112.234077, 28.215669],
                            [112.231381, 28.216385],
                            [112.229442, 28.217745],
                            [112.22852, 28.218851],
                            [112.227266, 28.22126],
                            [112.226698, 28.227193],
                            [112.226202, 28.227779],
                            [112.224617, 28.232035],
                            [112.223269, 28.233939],
                            [112.218019, 28.236331],
                            [112.217262, 28.236323],
                            [112.21336, 28.238357],
                            [112.210096, 28.239244],
                            [112.205602, 28.239244],
                            [112.204302, 28.23878],
                            [112.201369, 28.235208],
                            [112.197467, 28.234053],
                            [112.195527, 28.233239],
                            [112.193895, 28.23328],
                            [112.190466, 28.23446],
                            [112.18725, 28.234036],
                            [112.183371, 28.234411],
                            [112.177979, 28.235745],
                            [112.175945, 28.237218],
                            [112.173201, 28.240465],
                            [112.171026, 28.244639],
                            [112.169512, 28.245599],
                            [112.166012, 28.246494],
                            [112.162062, 28.247259],
                            [112.159532, 28.248503],
                            [112.15596, 28.249854],
                            [112.149835, 28.253133],
                            [112.146193, 28.25454],
                            [112.14501, 28.255899],
                            [112.144348, 28.257502],
                            [112.14352, 28.261162],
                            [112.142669, 28.262993],
                            [112.141037, 28.264587],
                            [112.139074, 28.265946],
                            [112.137324, 28.266621],
                            [112.134864, 28.266719],
                            [112.131293, 28.266515],
                            [112.125877, 28.265832],
                            [112.123157, 28.266157],
                            [112.120674, 28.267792],
                            [112.117907, 28.270753],
                            [112.116275, 28.271713],
                            [112.112917, 28.271681],
                            [112.108991, 28.270151],
                            [112.103693, 28.268785],
                            [112.100642, 28.269956],
                            [112.099081, 28.270054],
                            [112.096811, 28.268834],
                            [112.094801, 28.265938],
                            [112.093453, 28.26501],
                            [112.090331, 28.264807],
                            [112.082242, 28.267874],
                            [112.081154, 28.266946],
                            [112.081746, 28.26248],
                            [112.080658, 28.260804],
                            [112.078789, 28.259519],
                            [112.075053, 28.25742],
                            [112.071008, 28.255761],
                            [112.065356, 28.255126],
                            [112.06292, 28.253605],
                            [112.061737, 28.253417],
                            [112.060106, 28.254142],
                            [112.056676, 28.254792],
                            [112.053058, 28.251652],
                            [112.05171, 28.251286],
                            [112.047855, 28.252734],
                            [112.046247, 28.253059],
                            [112.043077, 28.251644],
                            [112.041375, 28.251449],
                            [112.038513, 28.252156],
                            [112.033121, 28.252466],
                            [112.03033, 28.252059],
                            [112.026593, 28.250399],
                            [112.023117, 28.247421],
                            [112.017606, 28.247071],
                            [112.015785, 28.246526],
                            [112.014177, 28.245534],
                            [112.009659, 28.244037],
                            [112.00869, 28.243223],
                            [112.005544, 28.239448],
                            [112.004291, 28.237088],
                            [112.003983, 28.235013],
                            [112.005734, 28.23131],
                            [112.007791, 28.229105],
                            [112.007957, 28.226924],
                            [112.007058, 28.22594],
                            [112.003487, 28.224516],
                            [112.000247, 28.224133],
                            [111.996226, 28.223954],
                            [111.991141, 28.224288],
                            [111.9866, 28.224174],
                            [111.98511, 28.223694],
                            [111.981776, 28.221529],
                            [111.976005, 28.219673],
                            [111.974988, 28.218794],
                            [111.97461, 28.216149],
                            [111.9739, 28.214937],
                            [111.972316, 28.213936],
                            [111.972387, 28.213024],
                            [111.969241, 28.211608],
                            [111.96619, 28.210981],
                            [111.964038, 28.210078],
                            [111.962146, 28.208865],
                            [111.961862, 28.206049],
                            [111.960916, 28.203762],
                            [111.961129, 28.198145],
                            [111.958882, 28.19594],
                            [111.957179, 28.193563],
                            [111.95524, 28.192162],
                            [111.951858, 28.193042],
                            [111.949871, 28.191926],
                            [111.947506, 28.188914],
                            [111.944763, 28.186741],
                            [111.939111, 28.186798],
                            [111.938496, 28.186505],
                            [111.939252, 28.184535],
                            [111.941665, 28.182418],
                            [111.944124, 28.180749],
                            [111.944905, 28.178982],
                            [111.942942, 28.173829],
                            [111.941783, 28.172274],
                            [111.938638, 28.170507],
                            [111.935397, 28.170474],
                            [111.933222, 28.168154],
                            [111.933127, 28.166143],
                            [111.9336, 28.163627],
                            [111.934073, 28.158757],
                            [111.933009, 28.155533],
                            [111.932678, 28.153114],
                            [111.931117, 28.150402],
                            [111.930928, 28.148472],
                            [111.931259, 28.147104],
                            [111.93334, 28.143488],
                            [111.933222, 28.137535],
                            [111.932938, 28.135637],
                            [111.931874, 28.133332],
                            [111.92816, 28.129732],
                            [111.926363, 28.128217],
                            [111.92194, 28.125073],
                            [111.916052, 28.12122],
                            [111.913166, 28.119754],
                            [111.908838, 28.116064],
                            [111.907064, 28.112715],
                            [111.904841, 28.107705],
                            [111.902902, 28.103713],
                            [111.899496, 28.100422],
                            [111.899165, 28.099811],
                            [111.90075, 28.095916],
                            [111.901814, 28.094083],
                            [111.905622, 28.091402],
                            [111.909997, 28.087899],
                            [111.915271, 28.082798],
                            [111.915531, 28.081714],
                            [111.913876, 28.080084],
                            [111.914089, 28.079343],
                            [111.917021, 28.077713],
                            [111.917068, 28.074445],
                            [111.921231, 28.068936],
                            [111.922863, 28.068268],
                            [111.926174, 28.067502],
                            [111.926883, 28.066996],
                            [111.926268, 28.065497],
                            [111.924731, 28.064616],
                            [111.920663, 28.063027],
                            [111.919268, 28.061919],
                            [111.918062, 28.060126],
                            [111.917447, 28.058284],
                            [111.916501, 28.057183],
                            [111.912338, 28.053866],
                            [111.911061, 28.052309],
                            [111.909831, 28.048144],
                            [111.90801, 28.044133],
                            [111.907537, 28.041835],
                            [111.908105, 28.037441],
                            [111.909217, 28.035362],
                            [111.912055, 28.033079],
                            [111.913379, 28.030055],
                            [111.913095, 28.027967],
                            [111.910848, 28.026312],
                            [111.907821, 28.025089],
                            [111.90496, 28.023638],
                            [111.900939, 28.021062],
                            [111.899709, 28.020018],
                            [111.899165, 28.017719],
                            [111.899189, 28.016284],
                            [111.896611, 28.011326],
                            [111.896398, 28.008684],
                            [111.899354, 28.005349],
                            [111.907537, 28.001443],
                            [111.908294, 28.000179],
                            [111.907561, 27.999037],
                            [111.907112, 27.996477],
                            [111.905243, 27.994226],
                            [111.904297, 27.992162],
                            [111.900608, 27.986094],
                            [111.899638, 27.98364],
                            [111.900064, 27.981364],
                            [111.902145, 27.978607],
                            [111.904889, 27.976405],
                            [111.908318, 27.974512],
                            [111.910967, 27.973664],
                            [111.912622, 27.973795],
                            [111.91494, 27.9747],
                            [111.918156, 27.975589],
                            [111.921444, 27.977204],
                            [111.926836, 27.980866],
                            [111.929674, 27.983794],
                            [111.935468, 27.990548],
                            [111.93632, 27.991208],
                            [111.938756, 27.991657],
                            [111.94034, 27.991567],
                            [111.947767, 27.988696],
                            [111.95122, 27.987098],
                            [111.95524, 27.982441],
                            [111.956943, 27.980149],
                            [111.958835, 27.97678],
                            [111.960751, 27.97262],
                            [111.961957, 27.969039],
                            [111.96321, 27.966135],
                            [111.964156, 27.965156],
                            [111.969194, 27.962962],
                            [111.970258, 27.962709],
                            [111.971819, 27.961322],
                            [111.975319, 27.957014],
                            [111.977188, 27.955848],
                            [111.982249, 27.95433],
                            [111.983431, 27.953466],
                            [111.986009, 27.950023],
                            [111.987357, 27.946327],
                            [111.990266, 27.940966],
                            [111.990739, 27.937563],
                            [111.992726, 27.928946],
                            [111.994665, 27.923601],
                            [111.997503, 27.918582],
                            [111.99826, 27.916248],
                            [111.998449, 27.914322],
                            [111.998284, 27.911],
                            [111.999703, 27.907842],
                            [112.002304, 27.908438],
                            [112.006301, 27.908299],
                            [112.007578, 27.907703],
                            [112.009234, 27.907687],
                            [112.012734, 27.909148],
                            [112.013514, 27.908699],
                            [112.014082, 27.906577],
                            [112.016187, 27.905801],
                            [112.017559, 27.906307],
                            [112.018883, 27.909246],
                            [112.021863, 27.911351],
                            [112.023684, 27.911857],
                            [112.026286, 27.911972],
                            [112.028485, 27.913947],
                            [112.030519, 27.914387],
                            [112.031512, 27.913041],
                            [112.03111, 27.90887],
                            [112.032411, 27.908413],
                            [112.034019, 27.909882],
                            [112.035344, 27.913147],
                            [112.036952, 27.913588],
                            [112.039979, 27.913261],
                            [112.043006, 27.912021],
                            [112.044094, 27.912143],
                            [112.045915, 27.914053],
                            [112.048612, 27.914494],
                            [112.049629, 27.915514],
                            [112.051213, 27.919186],
                            [112.052183, 27.920019],
                            [112.05398, 27.91988],
                            [112.05592, 27.918199],
                            [112.057362, 27.918256],
                            [112.0602, 27.920035],
                            [112.06214, 27.919766],
                            [112.064221, 27.917513],
                            [112.067224, 27.916648],
                            [112.068194, 27.915987],
                            [112.071292, 27.915171],
                            [112.084797, 27.909719],
                            [112.087753, 27.908389],
                            [112.088983, 27.907066],
                            [112.089527, 27.904332],
                            [112.090425, 27.901492],
                            [112.093098, 27.895917],
                            [112.095108, 27.894423],
                            [112.097142, 27.893917],
                            [112.101139, 27.893598],
                            [112.105207, 27.894121],
                            [112.11119, 27.896341],
                            [112.118285, 27.897623],
                            [112.119752, 27.899843],
                            [112.12136, 27.903149],
                            [112.127675, 27.911245],
                            [112.129046, 27.912649],
                            [112.130749, 27.913139],
                            [112.134178, 27.913008],
                            [112.135739, 27.911882],
                            [112.135952, 27.910764],
                            [112.13581, 27.907434],
                            [112.136496, 27.906218],
                            [112.137608, 27.905695],
                            [112.139547, 27.906389],
                            [112.141274, 27.907581],
                            [112.143331, 27.908266],
                            [112.14695, 27.90763],
                            [112.149575, 27.908005],
                            [112.152436, 27.909899],
                            [112.153382, 27.913539],
                            [112.157237, 27.915375],
                            [112.159886, 27.916191],
                            [112.16069, 27.916893],
                            [112.160359, 27.918917],
                            [112.157214, 27.922083],
                            [112.156788, 27.924009],
                            [112.157498, 27.926229],
                            [112.158869, 27.928334],
                            [112.161045, 27.930138],
                            [112.166319, 27.933818],
                            [112.168826, 27.934805],
                            [112.169843, 27.934593],
                            [112.171546, 27.933263],
                            [112.172799, 27.934716],
                            [112.171806, 27.937164],
                            [112.172516, 27.939726],
                            [112.173438, 27.940729],
                            [112.177955, 27.938755],
                            [112.180911, 27.938118],
                            [112.182047, 27.938412],
                            [112.182614, 27.939514],
                            [112.182638, 27.941635],
                            [112.183064, 27.944556],
                            [112.18304, 27.947094],
                            [112.18874, 27.952952],
                            [112.192902, 27.959984],
                            [112.192737, 27.96164],
                            [112.193068, 27.965148],
                            [112.195149, 27.96691],
                            [112.196828, 27.96961],
                            [112.1982, 27.970426],
                            [112.199406, 27.968672],
                            [112.198838, 27.963883],
                            [112.199406, 27.961836],
                            [112.20047, 27.961412],
                            [112.200754, 27.959902],
                            [112.202788, 27.959013],
                            [112.205129, 27.959209],
                            [112.207163, 27.957773],
                            [112.207896, 27.956517],
                            [112.210214, 27.954004],
                            [112.211113, 27.953735],
                            [112.213407, 27.954159],
                            [112.214519, 27.95513],
                            [112.217167, 27.958801],
                            [112.219745, 27.962701],
                            [112.221401, 27.963524],
                            [112.221708, 27.965164],
                            [112.220407, 27.967905],
                            [112.220786, 27.970385],
                            [112.222418, 27.971356],
                            [112.223813, 27.971372],
                            [112.226604, 27.969218],
                            [112.227928, 27.967424],
                            [112.228709, 27.963516],
                            [112.230364, 27.961836],
                            [112.231429, 27.961746],
                            [112.234267, 27.962407],
                            [112.245288, 27.963696],
                            [112.246305, 27.9635],
                            [112.248741, 27.961917],
                            [112.250987, 27.961297],
                            [112.253802, 27.961787],
                            [112.255315, 27.961403],
                            [112.256853, 27.959503],
                            [112.25768, 27.959144],
                            [112.260518, 27.959299],
                            [112.263475, 27.959959],
                            [112.266218, 27.961232],
                            [112.274969, 27.964202],
                            [112.289088, 27.968191],
                            [112.294669, 27.970393],
                            [112.298477, 27.971258],
                            [112.305052, 27.971804],
                            [112.30666, 27.971641],
                            [112.307393, 27.971037],
                            [112.309214, 27.967514],
                            [112.310846, 27.961664],
                            [112.313022, 27.959184],
                            [112.315576, 27.958401],
                            [112.318107, 27.959242],
                            [112.323736, 27.962717],
                            [112.325935, 27.963557],
                            [112.328206, 27.963973],
                            [112.332344, 27.96372],
                            [112.333551, 27.963214],
                            [112.337382, 27.958736],
                            [112.339085, 27.957855],
                            [112.342632, 27.957186],
                            [112.344406, 27.957316],
                            [112.347741, 27.958148],
                            [112.352613, 27.961159],
                            [112.353015, 27.961803],
                            [112.353133, 27.964822],
                            [112.354032, 27.96567],
                            [112.356728, 27.966298],
                            [112.365029, 27.968957],
                            [112.369334, 27.969928],
                            [112.371107, 27.970059],
                            [112.374962, 27.967652],
                            [112.377493, 27.967628],
                            [112.380236, 27.968256],
                            [112.382743, 27.967946],
                            [112.38473, 27.964267],
                            [112.386007, 27.964112],
                            [112.387733, 27.964667],
                            [112.389011, 27.964365],
                            [112.389791, 27.962129],
                            [112.390524, 27.961836],
                            [112.392322, 27.962815],
                            [112.393859, 27.962529],
                            [112.397004, 27.963002],
                            [112.39892, 27.96381],
                            [112.400812, 27.965727],
                            [112.402349, 27.966355],
                            [112.404123, 27.965694],
                            [112.40611, 27.963092],
                            [112.407411, 27.963508],
                            [112.40611, 27.968403],
                            [112.406441, 27.969765],
                            [112.408877, 27.969308],
                            [112.409752, 27.968223],
                            [112.409823, 27.962839],
                            [112.410225, 27.962048],
                            [112.411289, 27.963255],
                            [112.411597, 27.968133],
                            [112.412685, 27.971111],
                            [112.412543, 27.972465],
                            [112.411549, 27.974586],
                            [112.411833, 27.978958],
                            [112.414789, 27.986413],
                            [112.415877, 27.991086],
                            [112.415381, 27.992138],
                            [112.411739, 27.99142],
                            [112.410722, 27.992733],
                            [112.409208, 27.995669],
                            [112.4076, 27.996273],
                            [112.401805, 27.995743],
                            [112.399086, 27.995979],
                            [112.39814, 27.99686],
                            [112.39795, 28.000831],
                            [112.398565, 28.002381],
                            [112.399984, 28.002683],
                            [112.402184, 28.002413],
                            [112.407127, 28.001133],
                            [112.408522, 28.00172],
                            [112.409563, 28.002984],
                            [112.410059, 28.004819],
                            [112.409965, 28.006719],
                            [112.410651, 28.008554],
                            [112.412826, 28.012125],
                            [112.41486, 28.014205],
                            [112.41505, 28.015232],
                            [112.41188, 28.021518],
                            [112.412495, 28.025016],
                            [112.412259, 28.0284],
                            [112.410107, 28.031595],
                            [112.410343, 28.03303],
                            [112.411289, 28.034644],
                            [112.413299, 28.040677],
                            [112.416681, 28.044655],
                            [112.41829, 28.045756],
                            [112.419449, 28.045878],
                            [112.421932, 28.043946],
                            [112.422689, 28.044639],
                            [112.422902, 28.049342],
                            [112.423658, 28.050377],
                            [112.425669, 28.051478],
                            [112.430753, 28.053295],
                            [112.432527, 28.054102],
                            [112.436808, 28.054135],
                            [112.438747, 28.054371],
                            [112.440285, 28.055072],
                            [112.443998, 28.05733],
                            [112.445298, 28.057395],
                            [112.449295, 28.056727],
                            [112.452086, 28.055765],
                            [112.455586, 28.052578],
                            [112.457336, 28.051722],
                            [112.459157, 28.05191],
                            [112.465685, 28.056124],
                            [112.468689, 28.058887],
                            [112.470817, 28.059702],
                            [112.473135, 28.060166],
                            [112.475453, 28.060183],
                            [112.477179, 28.059734],
                            [112.478953, 28.058422],
                            [112.480254, 28.056246],
                            [112.483163, 28.048983],
                            [112.484038, 28.045715],
                            [112.485409, 28.041883],
                            [112.486284, 28.040221],
                            [112.488484, 28.038745],
                            [112.491795, 28.036927],
                            [112.496596, 28.033332],
                            [112.498985, 28.030935],
                            [112.501468, 28.029337],
                            [112.503336, 28.027283],
                            [112.506056, 28.025522],
                            [112.507097, 28.025171],
                            [112.51107, 28.022847],
                            [112.516415, 28.018828],
                            [112.51554, 28.017776],
                            [112.511235, 28.016969],
                            [112.509036, 28.013324],
                            [112.508303, 28.010935],
                            [112.50809, 28.008896],
                            [112.508397, 28.006116],
                            [112.509438, 28.001264],
                            [112.511401, 27.997953],
                            [112.511803, 27.994682],
                            [112.512489, 27.993957],
                            [112.514641, 27.993435],
                            [112.517337, 27.994617],
                            [112.519679, 27.994943],
                            [112.522044, 27.994536],
                            [112.527199, 27.994128],
                            [112.531125, 27.994144],
                            [112.533396, 27.993117],
                            [112.534153, 27.99076],
                            [112.534389, 27.986535],
                            [112.535122, 27.983215],
                            [112.536328, 27.978892],
                            [112.537913, 27.976715],
                            [112.539474, 27.975393],
                            [112.541248, 27.975034],
                            [112.545316, 27.975116],
                            [112.550471, 27.972726],
                            [112.553901, 27.972628],
                            [112.556597, 27.974904],
                            [112.55908, 27.975214],
                            [112.56135, 27.980459],
                            [112.563313, 27.980948],
                            [112.564756, 27.978827],
                            [112.566625, 27.974023],
                            [112.566743, 27.97253],
                            [112.567736, 27.970646],
                            [112.570054, 27.969341],
                            [112.574287, 27.967848],
                            [112.575493, 27.966013],
                            [112.575541, 27.963924],
                            [112.575139, 27.962391],
                            [112.575564, 27.960645],
                            [112.577338, 27.958989],
                            [112.581288, 27.958173],
                            [112.585805, 27.958719],
                            [112.58817, 27.958034],
                            [112.596944, 27.953474],
                            [112.597796, 27.952593],
                            [112.599073, 27.949965],
                            [112.598907, 27.947085],
                            [112.598434, 27.945551],
                            [112.594626, 27.938583],
                            [112.594177, 27.936707],
                            [112.594343, 27.932814],
                            [112.594745, 27.931525],
                            [112.598363, 27.927429],
                            [112.598765, 27.926702],
                            [112.5986, 27.92325],
                            [112.598978, 27.921178],
                            [112.599924, 27.919896],
                            [112.602053, 27.918019],
                            [112.60333, 27.917995],
                            [112.60534, 27.919276],
                            [112.606546, 27.918852],
                            [112.606948, 27.918011],
                            [112.607279, 27.913669],
                            [112.607729, 27.911033],
                            [112.608249, 27.910307],
                            [112.611395, 27.909131],
                            [112.61253, 27.907352],
                            [112.609952, 27.904471],
                            [112.60955, 27.903059],
                            [112.611655, 27.900275],
                            [112.61331, 27.900088],
                            [112.61752, 27.900235],
                            [112.619696, 27.899778],
                            [112.621801, 27.898806],
                            [112.625774, 27.896472],
                            [112.62918, 27.89248],
                            [112.630504, 27.890537],
                            [112.631687, 27.887574],
                            [112.632018, 27.884586],
                            [112.633129, 27.880994],
                            [112.633862, 27.880112],
                            [112.637812, 27.879696],
                            [112.640981, 27.879818],
                            [112.643796, 27.879663],
                            [112.648313, 27.880047],
                            [112.649945, 27.880757],
                            [112.651931, 27.883402],
                            [112.652381, 27.885329],
                            [112.652735, 27.893019],
                            [112.653279, 27.894439],
                            [112.656307, 27.897443],
                            [112.657063, 27.900341],
                            [112.654958, 27.902945],
                            [112.653468, 27.905956],
                            [112.652641, 27.908421],
                            [112.651955, 27.912135],
                            [112.6516, 27.915906],
                            [112.651837, 27.916722],
                            [112.651222, 27.918525],
                            [112.65011, 27.91895],
                            [112.648076, 27.916909],
                            [112.645853, 27.916811],
                            [112.642755, 27.920125],
                            [112.641927, 27.923308],
                            [112.644505, 27.928449],
                            [112.645782, 27.930342],
                            [112.648218, 27.932594],
                            [112.650678, 27.935932],
                            [112.651718, 27.939342],
                            [112.652593, 27.940427],
                            [112.654604, 27.941496],
                            [112.659428, 27.941888],
                            [112.661675, 27.941088],
                            [112.664087, 27.937531],
                            [112.666807, 27.935564],
                            [112.671419, 27.934765],
                            [112.67298, 27.934234],
                            [112.679271, 27.929811],
                            [112.683008, 27.928261],
                            [112.687123, 27.927053],
                            [112.687738, 27.925544],
                            [112.686248, 27.924213],
                            [112.682724, 27.922043],
                            [112.682251, 27.921186],
                            [112.683433, 27.920313],
                            [112.685325, 27.920239],
                            [112.693579, 27.921447],
                            [112.699185, 27.922075],
                            [112.702117, 27.922173],
                            [112.70453, 27.92321],
                            [112.706043, 27.923095],
                            [112.712429, 27.916477],
                            [112.715787, 27.915693],
                            [112.721487, 27.915497],
                            [112.723592, 27.915701],
                            [112.730994, 27.916966],
                            [112.732437, 27.918248],
                            [112.732413, 27.920435],
                            [112.729079, 27.92201],
                            [112.728582, 27.923683],
                            [112.728866, 27.924752],
                            [112.730403, 27.926286],
                            [112.730994, 27.928604],
                            [112.731846, 27.929159],
                            [112.734092, 27.928057],
                            [112.738444, 27.928237],
                            [112.740856, 27.929093],
                            [112.741755, 27.929779],
                            [112.74308, 27.931958],
                            [112.743411, 27.935972],
                            [112.744262, 27.93944],
                            [112.744215, 27.941472],
                            [112.743694, 27.944613],
                            [112.744097, 27.946433],
                            [112.745445, 27.948326],
                            [112.750553, 27.954526],
                            [112.753722, 27.959331],
                            [112.755401, 27.961175],
                            [112.758405, 27.965417],
                            [112.759587, 27.969333],
                            [112.759682, 27.970466],
                            [112.75831, 27.972351],
                            [112.758523, 27.973542],
                            [112.760202, 27.977082],
                            [112.760415, 27.980695],
                            [112.761338, 27.982457],
                            [112.764483, 27.986812],
                            [112.765878, 27.991698],
                            [112.765618, 27.996664],
                            [112.763679, 28.00234],
                            [112.764436, 28.003082],
                            [112.767014, 28.00402],
                            [112.768693, 28.006409],
                            [112.768977, 28.012117],
                            [112.770088, 28.017393],
                            [112.773234, 28.019121],
                            [112.77541, 28.019366],
                            [112.779454, 28.021502],
                            [112.7804, 28.022945],
                            [112.780234, 28.02469],
                            [112.779099, 28.025073],
                            [112.772406, 28.031033],
                            [112.771625, 28.033707],
                            [112.771791, 28.035346],
                            [112.768835, 28.037286],
                            [112.76673, 28.039625],
                            [112.766446, 28.041484],
                            [112.767274, 28.042796],
                            [112.76874, 28.043204],
                            [112.771389, 28.043008],
                            [112.772595, 28.043253],
                            [112.773021, 28.045006],
                            [112.773068, 28.049244],
                            [112.7747, 28.050557],
                            [112.77541, 28.050451],
                            [112.777491, 28.04856],
                            [112.780565, 28.046391],
                            [112.781464, 28.046514],
                            [112.783593, 28.048405],
                            [112.78468, 28.051127],
                            [112.782552, 28.05279],
                            [112.777136, 28.061136],
                            [112.777041, 28.063223],
                            [112.777443, 28.065374],
                            [112.778815, 28.067591],
                            [112.779052, 28.068952],
                            [112.778555, 28.071821],
                            [112.779454, 28.072905],
                            [112.777656, 28.076662],
                            [112.776095, 28.078683],
                            [112.774416, 28.080125],
                            [112.771058, 28.081608],
                            [112.765027, 28.084867],
                            [112.760202, 28.088819],
                            [112.758452, 28.089585],
                            [112.756111, 28.089276],
                            [112.750719, 28.086717],
                            [112.747904, 28.084484],
                            [112.743671, 28.082056],
                            [112.739295, 28.080703],
                            [112.735228, 28.080361],
                            [112.707982, 28.080516],
                            [112.702921, 28.081543],
                            [112.698499, 28.082667],
                            [112.694123, 28.08446],
                            [112.691877, 28.085723],
                            [112.689866, 28.090213],
                            [112.688164, 28.092502],
                            [112.686744, 28.093496],
                            [112.683812, 28.094295],
                            [112.678869, 28.094955],
                            [112.668936, 28.09757],
                            [112.666807, 28.098556],
                            [112.664986, 28.099941],
                            [112.664064, 28.101791],
                            [112.661675, 28.104406],
                            [112.658695, 28.106337],
                            [112.657016, 28.106874],
                            [112.653445, 28.107102],
                            [112.651884, 28.108601],
                            [112.651387, 28.111534],
                            [112.651411, 28.114011],
                            [112.650559, 28.114622],
                            [112.648053, 28.115428],
                            [112.644221, 28.115208],
                            [112.638971, 28.113937],
                            [112.629913, 28.112626],
                            [112.623858, 28.112707],
                            [112.621564, 28.112561],
                            [112.617094, 28.113229],
                            [112.616243, 28.113669],
                            [112.616054, 28.117896],
                            [112.615297, 28.120373],
                            [112.613925, 28.121823],
                            [112.612861, 28.124625],
                            [112.613026, 28.126832],
                            [112.614706, 28.128209],
                            [112.617449, 28.12904],
                            [112.619909, 28.130204],
                            [112.623645, 28.130937],
                            [112.62795, 28.133764],
                            [112.630362, 28.137461],
                            [112.630835, 28.143114],
                            [112.631355, 28.146681],
                            [112.631545, 28.150011],
                            [112.632774, 28.152968],
                            [112.634123, 28.15914],
                            [112.633697, 28.161672],
                            [112.63365, 28.164099],
                            [112.630953, 28.169139],
                            [112.628352, 28.172038],
                            [112.630409, 28.173983],
                            [112.632065, 28.174684],
                            [112.636204, 28.175644],
                            [112.636961, 28.176279],
                            [112.638592, 28.179976],
                            [112.639751, 28.183883],
                            [112.640579, 28.188076],
                            [112.642802, 28.191153],
                            [112.647485, 28.194051],
                            [112.651789, 28.195638],
                            [112.654036, 28.196119],
                            [112.657087, 28.197185],
                            [112.66203, 28.202053],
                            [112.665152, 28.20368],
                            [112.669787, 28.205105],
                            [112.671183, 28.205463],
                            [112.674967, 28.205634],
                            [112.68017, 28.203282],
                            [112.681849, 28.203086],
                            [112.685325, 28.204323],
                            [112.686957, 28.205528],
                            [112.689606, 28.208393],
                            [112.691569, 28.213106],
                            [112.692066, 28.213789],
                            [112.699539, 28.217688],
                            [112.704766, 28.222164],
                            [112.706209, 28.224499],
                            [112.70654, 28.225964],
                            [112.704151, 28.228926],
                            [112.699681, 28.23315],
                            [112.699019, 28.232254],
                            [112.698995, 28.233735],
                            [112.696441, 28.233198],
                            [112.696417, 28.234036],
                            [112.697576, 28.234639],
                            [112.696086, 28.235176],
                            [112.69559, 28.236136],
                            [112.696228, 28.238032],
                            [112.695897, 28.238691],
                            [112.695235, 28.243272],
                            [112.696772, 28.243166],
                            [112.696961, 28.243719],
                            [112.694975, 28.244216],
                            [112.695188, 28.246339],
                            [112.696749, 28.249887],
                            [112.694738, 28.249431],
                            [112.694431, 28.250847],
                            [112.691143, 28.253059],
                            [112.695944, 28.254231],
                            [112.70032, 28.25371],
                            [112.698924, 28.256582],
                            [112.701029, 28.262993],
                            [112.700012, 28.264888],
                            [112.699776, 28.269557],
                            [112.700391, 28.272169],
                            [112.702401, 28.274113],
                            [112.709898, 28.275918],
                            [112.715243, 28.278082],
                            [112.717324, 28.280197],
                            [112.716284, 28.280913],
                            [112.715125, 28.280782],
                            [112.712098, 28.279107],
                            [112.710962, 28.278806],
                            [112.709969, 28.279359],
                            [112.709945, 28.280994],
                            [112.714084, 28.281962],
                            [112.718791, 28.283662],
                            [112.719666, 28.285589],
                            [112.719193, 28.290494],
                            [112.71872, 28.291282],
                            [112.715622, 28.293665],
                            [112.713375, 28.294812],
                            [112.71153, 28.296317],
                            [112.709094, 28.30057],
                            [112.706966, 28.305506],
                            [112.707439, 28.308108],
                            [112.710631, 28.308661],
                            [112.711885, 28.309921],
                            [112.711979, 28.311507],
                            [112.711081, 28.312962],
                            [112.708999, 28.314735],
                            [112.708385, 28.316369],
                            [112.709118, 28.318353],
                            [112.714817, 28.321028],
                            [112.71652, 28.322126],
                            [112.71723, 28.323435],
                            [112.71626, 28.327427],
                            [112.716307, 28.328614],
                            [112.719737, 28.329223],
                            [112.721203, 28.329134],
                            [112.7217, 28.330215],
                            [112.719618, 28.333231],
                            [112.718247, 28.334678],
                            [112.715361, 28.338361],
                            [112.711483, 28.34384],
                            [112.708952, 28.346181],
                            [112.70758, 28.347855],
                            [112.704719, 28.350579],
                            [112.703631, 28.351237],
                            [112.702567, 28.353423],
                            [112.701124, 28.359056],
                            [112.701621, 28.361616],
                            [112.702661, 28.363485],
                            [112.70453, 28.364363],
                            [112.707391, 28.3649],
                            [112.710371, 28.36585],
                            [112.71373, 28.367525],
                            [112.716804, 28.369849],
                            [112.721771, 28.371856],
                            [112.72371, 28.374367],
                            [112.723828, 28.375497],
                            [112.722362, 28.382266],
                            [112.721345, 28.382851],
                            [112.718223, 28.382965],
                            [112.713209, 28.381803],
                            [112.70926, 28.381632],
                            [112.705641, 28.383103],
                            [112.704293, 28.383144],
                            [112.698026, 28.382713],
                            [112.696465, 28.383875],
                            [112.69663, 28.385914],
                            [112.700154, 28.38901],
                            [112.701408, 28.390725],
                            [112.702827, 28.393902],
                            [112.702212, 28.395868],
                            [112.700225, 28.397306],
                            [112.690268, 28.398183],
                            [112.686532, 28.398971],
                            [112.683694, 28.400393],
                            [112.681683, 28.402059],
                            [112.679507, 28.405585],
                            [112.678136, 28.410646],
                            [112.678183, 28.41262],
                            [112.678893, 28.415569],
                            [112.680832, 28.41803],
                            [112.682014, 28.420581],
                            [112.679957, 28.424163],
                            [112.677994, 28.425885],
                            [112.675936, 28.426648],
                            [112.672507, 28.428346],
                            [112.664868, 28.430604],
                            [112.660847, 28.432634],
                            [112.660114, 28.43369],
                            [112.659216, 28.437353],
                            [112.659665, 28.441308],
                            [112.66054, 28.444248],
                            [112.659003, 28.444881],
                            [112.658482, 28.445661],
                            [112.659216, 28.447042],
                            [112.657253, 28.447131],
                            [112.656023, 28.447894],
                            [112.653114, 28.446831],
                            [112.652664, 28.44817],
                            [112.653729, 28.447634],
                            [112.653185, 28.448698],
                            [112.654178, 28.449129],
                            [112.654769, 28.448122],
                            [112.656496, 28.449892],
                            [112.65659, 28.450858],
                            [112.65756, 28.451427],
                            [112.662527, 28.45729],
                            [112.667091, 28.458678],
                            [112.670142, 28.461999],
                            [112.673524, 28.464175],
                            [112.682393, 28.467748],
                            [112.684379, 28.468868],
                            [112.684403, 28.470484],
                            [112.682795, 28.472059],
                            [112.679318, 28.474803],
                            [112.676953, 28.477101],
                            [112.670639, 28.481485],
                            [112.659121, 28.484042],
                            [112.651837, 28.487833]
                        ]
                    ]
                ]
            }
        }]
    });
}));