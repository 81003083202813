import request from "@/utils/request";

//左侧四条数据
export function getLeftData(data) {
  return request({
    url: "/system/homepage/getLeftTotal",
    method: "get",
    params: data
  });
}

//中间地图数据
export function getMapData(data) {
  return request({
    url: "/system/homepage/getProvinceTotal",
    method: "get",
    params: data
  });
}

//右侧文字滚动数据
export function textData(data) {
  return request({
    url: "/system/homepage/getActivity",
    method: "get",
    params: data
  });
}
