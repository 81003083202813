/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

(function(root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['exports', 'echarts'], factory);
    } else if (typeof exports === 'object' && typeof exports.nodeName !== 'string') {
        // CommonJS
        factory(exports, require('echarts'));
    } else {
        // Browser globals
        factory({}, root.echarts);
    }
}(this, function(exports, echarts) {
    var log = function(msg) {
        if (typeof console !== 'undefined') {
            console && console.error && console.error(msg);
        }
    }
    if (!echarts) {
        log('ECharts is not Loaded');
        return;
    }
    if (!echarts.registerMap) {
        log('ECharts Map is not loaded')
        return;
    }
    echarts.registerMap('怀化市', {
        "type": "FeatureCollection",
        "features": [{
            "type": "Feature",
            "properties": {
                "adcode": 431202,
                "name": "鹤城区",
                "center": [109.982242, 27.548474],
                "centroid": [109.947186, 27.611957],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431200
                },
                "subFeatureIndex": 0,
                "acroutes": [100000, 430000, 431200]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [110.058298, 27.736326],
                            [110.046816, 27.73601],
                            [110.043515, 27.737844],
                            [110.041958, 27.743347],
                            [110.034218, 27.741133],
                            [110.033521, 27.734935],
                            [110.029988, 27.739236],
                            [110.024061, 27.741987],
                            [110.014903, 27.742967],
                            [110.007767, 27.735441],
                            [110.000353, 27.734777],
                            [109.997703, 27.73952],
                            [109.98564, 27.743536],
                            [109.977946, 27.739837],
                            [109.973855, 27.734967],
                            [109.963907, 27.736421],
                            [109.958956, 27.72978],
                            [109.943035, 27.731899],
                            [109.938688, 27.740659],
                            [109.937665, 27.746319],
                            [109.932947, 27.75043],
                            [109.933017, 27.753339],
                            [109.928391, 27.757924],
                            [109.926462, 27.765701],
                            [109.921325, 27.767788],
                            [109.919559, 27.77174],
                            [109.923069, 27.77879],
                            [109.919257, 27.789063],
                            [109.906008, 27.794689],
                            [109.901452, 27.790549],
                            [109.896525, 27.790738],
                            [109.887716, 27.78606],
                            [109.883067, 27.787293],
                            [109.878744, 27.779833],
                            [109.871236, 27.774016],
                            [109.877372, 27.768231],
                            [109.877884, 27.763615],
                            [109.870864, 27.754667],
                            [109.874467, 27.743062],
                            [109.871329, 27.740153],
                            [109.866425, 27.742999],
                            [109.864193, 27.737085],
                            [109.856546, 27.737844],
                            [109.851596, 27.734935],
                            [109.853432, 27.732278],
                            [109.847946, 27.729938],
                            [109.831141, 27.731836],
                            [109.828794, 27.73503],
                            [109.82131, 27.736801],
                            [109.816521, 27.72434],
                            [109.817707, 27.720228],
                            [109.809967, 27.716401],
                            [109.801158, 27.715674],
                            [109.796648, 27.712226],
                            [109.795463, 27.69527],
                            [109.790977, 27.689386],
                            [109.790442, 27.685557],
                            [109.795905, 27.681697],
                            [109.804203, 27.670623],
                            [109.803157, 27.660053],
                            [109.805667, 27.656699],
                            [109.804574, 27.652236],
                            [109.80741, 27.649388],
                            [109.804272, 27.638785],
                            [109.796741, 27.628561],
                            [109.78935, 27.627231],
                            [109.790024, 27.622039],
                            [109.793999, 27.614695],
                            [109.807294, 27.602885],
                            [109.8211, 27.600447],
                            [109.830444, 27.60165],
                            [109.835279, 27.60488],
                            [109.845506, 27.622577],
                            [109.848156, 27.633245],
                            [109.855733, 27.635208],
                            [109.862195, 27.630808],
                            [109.86933, 27.623812],
                            [109.886275, 27.603138],
                            [109.887948, 27.595697],
                            [109.880789, 27.582935],
                            [109.883276, 27.57638],
                            [109.887716, 27.572516],
                            [109.909262, 27.560131],
                            [109.912144, 27.556109],
                            [109.913399, 27.545718],
                            [109.912447, 27.537735],
                            [109.909007, 27.532285],
                            [109.903265, 27.53238],
                            [109.895967, 27.525156],
                            [109.894363, 27.510929],
                            [109.890528, 27.507855],
                            [109.886182, 27.510168],
                            [109.88925, 27.521354],
                            [109.886158, 27.524681],
                            [109.878511, 27.522907],
                            [109.870934, 27.512165],
                            [109.861776, 27.512545],
                            [109.855919, 27.507823],
                            [109.856244, 27.502943],
                            [109.862915, 27.500217],
                            [109.865193, 27.495938],
                            [109.856918, 27.49784],
                            [109.858197, 27.484021],
                            [109.855384, 27.48158],
                            [109.851456, 27.46741],
                            [109.866913, 27.467569],
                            [109.869284, 27.460943],
                            [109.873979, 27.454538],
                            [109.878581, 27.442712],
                            [109.896362, 27.440904],
                            [109.90459, 27.44417],
                            [109.910564, 27.44918],
                            [109.916351, 27.451336],
                            [109.917676, 27.456821],
                            [109.92279, 27.455426],
                            [109.924068, 27.449814],
                            [109.933993, 27.448356],
                            [109.939339, 27.452446],
                            [109.940571, 27.458343],
                            [109.950194, 27.465857],
                            [109.953355, 27.474702],
                            [109.957399, 27.474638],
                            [109.963744, 27.478284],
                            [109.96658, 27.476286],
                            [109.980387, 27.476667],
                            [109.989498, 27.473782],
                            [109.994821, 27.469978],
                            [109.99747, 27.47356],
                            [110.024084, 27.470897],
                            [110.027268, 27.462306],
                            [110.031475, 27.458438],
                            [110.038751, 27.45755],
                            [110.046049, 27.463606],
                            [110.046002, 27.467664],
                            [110.052697, 27.467252],
                            [110.054881, 27.472673],
                            [110.060739, 27.470866],
                            [110.063923, 27.476699],
                            [110.065225, 27.484053],
                            [110.069804, 27.486937],
                            [110.070129, 27.491945],
                            [110.081565, 27.500534],
                            [110.085539, 27.50627],
                            [110.089444, 27.503671],
                            [110.09307, 27.507538],
                            [110.087724, 27.511657],
                            [110.09479, 27.517995],
                            [110.09486, 27.52541],
                            [110.085841, 27.539509],
                            [110.08619, 27.548854],
                            [110.090955, 27.563172],
                            [110.092187, 27.571756],
                            [110.089119, 27.576728],
                            [110.078473, 27.576411],
                            [110.07236, 27.586102],
                            [110.066131, 27.589491],
                            [110.055509, 27.602663],
                            [110.052348, 27.616721],
                            [110.055044, 27.621375],
                            [110.046165, 27.631979],
                            [110.037751, 27.638057],
                            [110.033265, 27.639038],
                            [110.025479, 27.63755],
                            [110.012695, 27.632296],
                            [110.002305, 27.632043],
                            [109.995123, 27.642868],
                            [109.998261, 27.647774],
                            [110.019993, 27.65537],
                            [110.023991, 27.659231],
                            [110.031661, 27.673977],
                            [110.041563, 27.681508],
                            [110.055555, 27.698086],
                            [110.058786, 27.706437],
                            [110.053254, 27.714345],
                            [110.053766, 27.721778],
                            [110.059646, 27.724245],
                            [110.061645, 27.727851],
                            [110.058298, 27.736326]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 431221,
                "name": "中方县",
                "center": [109.948061, 27.43736],
                "centroid": [110.160324, 27.508841],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431200
                },
                "subFeatureIndex": 1,
                "acroutes": [100000, 430000, 431200]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [109.878581, 27.442712],
                            [109.87205, 27.415629],
                            [109.882742, 27.39482],
                            [109.880162, 27.386413],
                            [109.87291, 27.382321],
                            [109.870841, 27.371089],
                            [109.862799, 27.368455],
                            [109.857825, 27.371914],
                            [109.854385, 27.378894],
                            [109.846714, 27.379941],
                            [109.845622, 27.384891],
                            [109.841973, 27.38543],
                            [109.830189, 27.383431],
                            [109.818056, 27.379973],
                            [109.820054, 27.374516],
                            [109.818195, 27.370391],
                            [109.808688, 27.36652],
                            [109.796509, 27.359095],
                            [109.786561, 27.34748],
                            [109.775637, 27.339069],
                            [109.773335, 27.3338],
                            [109.771453, 27.322214],
                            [109.761946, 27.314627],
                            [109.759692, 27.307389],
                            [109.761435, 27.305611],
                            [109.771267, 27.306119],
                            [109.773893, 27.300468],
                            [109.783028, 27.298912],
                            [109.792, 27.301198],
                            [109.789234, 27.295801],
                            [109.790489, 27.290784],
                            [109.794231, 27.29561],
                            [109.798485, 27.296182],
                            [109.805039, 27.300563],
                            [109.812686, 27.298722],
                            [109.810269, 27.302786],
                            [109.823913, 27.313929],
                            [109.827353, 27.31358],
                            [109.835349, 27.307326],
                            [109.840369, 27.306246],
                            [109.843484, 27.296023],
                            [109.849434, 27.284909],
                            [109.85894, 27.28202],
                            [109.864565, 27.276907],
                            [109.866936, 27.278749],
                            [109.874955, 27.279098],
                            [109.880394, 27.275287],
                            [109.885531, 27.278717],
                            [109.886042, 27.275541],
                            [109.893015, 27.276177],
                            [109.901336, 27.27262],
                            [109.904892, 27.276907],
                            [109.899895, 27.277669],
                            [109.902289, 27.284084],
                            [109.909913, 27.2851],
                            [109.914887, 27.28818],
                            [109.924184, 27.285163],
                            [109.927508, 27.28167],
                            [109.933807, 27.283639],
                            [109.940245, 27.280495],
                            [109.942686, 27.275319],
                            [109.94945, 27.273414],
                            [109.954168, 27.269412],
                            [109.960467, 27.271858],
                            [109.963791, 27.269031],
                            [109.967231, 27.270809],
                            [109.980131, 27.27227],
                            [109.984338, 27.270365],
                            [109.994426, 27.276335],
                            [109.995611, 27.280305],
                            [110.003816, 27.283353],
                            [110.003909, 27.293006],
                            [110.001538, 27.299642],
                            [109.994612, 27.299801],
                            [109.985175, 27.304659],
                            [109.987685, 27.309802],
                            [109.998005, 27.315993],
                            [110.004188, 27.317072],
                            [110.006326, 27.325294],
                            [110.014531, 27.329261],
                            [110.019412, 27.3358],
                            [110.030127, 27.344496],
                            [110.040075, 27.345798],
                            [110.045119, 27.34218],
                            [110.048768, 27.349447],
                            [110.047606, 27.359983],
                            [110.043655, 27.361316],
                            [110.041633, 27.365631],
                            [110.046677, 27.374833],
                            [110.045375, 27.379275],
                            [110.039146, 27.38524],
                            [110.038053, 27.390125],
                            [110.044747, 27.405479],
                            [110.053138, 27.405479],
                            [110.060251, 27.401958],
                            [110.060878, 27.406557],
                            [110.064783, 27.41103],
                            [110.072174, 27.413599],
                            [110.082773, 27.424255],
                            [110.081007, 27.42822],
                            [110.088956, 27.433103],
                            [110.099532, 27.435418],
                            [110.105668, 27.440809],
                            [110.118754, 27.447595],
                            [110.114663, 27.453429],
                            [110.116778, 27.457043],
                            [110.111456, 27.45606],
                            [110.106086, 27.457931],
                            [110.113059, 27.460055],
                            [110.111944, 27.466364],
                            [110.115872, 27.469027],
                            [110.119916, 27.476065],
                            [110.124263, 27.475843],
                            [110.129074, 27.471563],
                            [110.128167, 27.469566],
                            [110.120125, 27.469883],
                            [110.124007, 27.466618],
                            [110.134188, 27.467188],
                            [110.136535, 27.471817],
                            [110.132142, 27.475811],
                            [110.137883, 27.477713],
                            [110.141928, 27.475462],
                            [110.146553, 27.469217],
                            [110.145298, 27.462021],
                            [110.149807, 27.462338],
                            [110.151085, 27.45828],
                            [110.144856, 27.455838],
                            [110.144879, 27.452382],
                            [110.139719, 27.450829],
                            [110.138929, 27.447024],
                            [110.145995, 27.445407],
                            [110.143322, 27.442014],
                            [110.152178, 27.442648],
                            [110.159104, 27.437797],
                            [110.164032, 27.436338],
                            [110.167867, 27.429266],
                            [110.168797, 27.414963],
                            [110.164381, 27.412901],
                            [110.162126, 27.407667],
                            [110.167309, 27.404115],
                            [110.166403, 27.394884],
                            [110.172469, 27.394916],
                            [110.179954, 27.402656],
                            [110.19648, 27.404147],
                            [110.199617, 27.403512],
                            [110.199571, 27.408111],
                            [110.210565, 27.407794],
                            [110.213912, 27.420767],
                            [110.218398, 27.421274],
                            [110.219211, 27.42527],
                            [110.226277, 27.424731],
                            [110.232879, 27.430915],
                            [110.241664, 27.420989],
                            [110.247545, 27.423621],
                            [110.252263, 27.419403],
                            [110.262607, 27.421274],
                            [110.264722, 27.423145],
                            [110.265512, 27.430883],
                            [110.269928, 27.433008],
                            [110.27546, 27.42879],
                            [110.281922, 27.435069],
                            [110.283014, 27.431486],
                            [110.289267, 27.430979],
                            [110.290871, 27.426951],
                            [110.295961, 27.426031],
                            [110.298262, 27.429203],
                            [110.296891, 27.435196],
                            [110.302376, 27.44176],
                            [110.312394, 27.432723],
                            [110.321482, 27.43323],
                            [110.325875, 27.428917],
                            [110.330826, 27.416073],
                            [110.336125, 27.413535],
                            [110.347514, 27.413757],
                            [110.355882, 27.415216],
                            [110.364714, 27.411632],
                            [110.372385, 27.410141],
                            [110.393048, 27.414265],
                            [110.400742, 27.417849],
                            [110.403019, 27.434499],
                            [110.409574, 27.436496],
                            [110.414292, 27.43285],
                            [110.423381, 27.420703],
                            [110.430958, 27.413472],
                            [110.433073, 27.41417],
                            [110.440418, 27.411981],
                            [110.456851, 27.41972],
                            [110.461174, 27.423177],
                            [110.465869, 27.432818],
                            [110.467241, 27.44287],
                            [110.459036, 27.46554],
                            [110.457664, 27.472958],
                            [110.456595, 27.489536],
                            [110.457525, 27.496858],
                            [110.460965, 27.507031],
                            [110.468403, 27.514921],
                            [110.473493, 27.518344],
                            [110.484324, 27.531366],
                            [110.488299, 27.53783],
                            [110.492181, 27.549805],
                            [110.492529, 27.560385],
                            [110.495295, 27.566181],
                            [110.494528, 27.57052],
                            [110.488834, 27.580148],
                            [110.494156, 27.581637],
                            [110.494947, 27.588604],
                            [110.48537, 27.588636],
                            [110.487439, 27.586546],
                            [110.484929, 27.57866],
                            [110.481326, 27.577868],
                            [110.471657, 27.585152],
                            [110.47777, 27.594905],
                            [110.47254, 27.60051],
                            [110.465218, 27.60279],
                            [110.449948, 27.582239],
                            [110.443904, 27.585691],
                            [110.437559, 27.584075],
                            [110.435606, 27.595475],
                            [110.429517, 27.595095],
                            [110.424589, 27.590251],
                            [110.420173, 27.580624],
                            [110.406901, 27.581194],
                            [110.39772, 27.579863],
                            [110.389538, 27.583759],
                            [110.386145, 27.587844],
                            [110.382472, 27.585152],
                            [110.378196, 27.590219],
                            [110.365853, 27.589871],
                            [110.362994, 27.582904],
                            [110.359694, 27.582049],
                            [110.349118, 27.586292],
                            [110.343703, 27.585152],
                            [110.348956, 27.601713],
                            [110.342796, 27.607096],
                            [110.34261, 27.609661],
                            [110.350768, 27.61365],
                            [110.352303, 27.616531],
                            [110.34877, 27.620868],
                            [110.347956, 27.627421],
                            [110.340355, 27.631315],
                            [110.33566, 27.638025],
                            [110.335265, 27.664199],
                            [110.337404, 27.672996],
                            [110.341053, 27.674895],
                            [110.34361, 27.68233],
                            [110.340123, 27.686],
                            [110.32706, 27.693467],
                            [110.312952, 27.689354],
                            [110.306234, 27.692518],
                            [110.299912, 27.691632],
                            [110.290917, 27.692708],
                            [110.272671, 27.690746],
                            [110.260166, 27.685114],
                            [110.254169, 27.68445],
                            [110.247057, 27.691189],
                            [110.23841, 27.693657],
                            [110.231972, 27.698529],
                            [110.22042, 27.704571],
                            [110.200919, 27.716243],
                            [110.188019, 27.720956],
                            [110.164427, 27.728262],
                            [110.156431, 27.72861],
                            [110.139836, 27.727534],
                            [110.123542, 27.727566],
                            [110.107388, 27.729875],
                            [110.089793, 27.734271],
                            [110.081495, 27.74477],
                            [110.073778, 27.742683],
                            [110.070222, 27.737908],
                            [110.058298, 27.736326],
                            [110.061645, 27.727851],
                            [110.059646, 27.724245],
                            [110.053766, 27.721778],
                            [110.053254, 27.714345],
                            [110.058786, 27.706437],
                            [110.055555, 27.698086],
                            [110.041563, 27.681508],
                            [110.031661, 27.673977],
                            [110.023991, 27.659231],
                            [110.019993, 27.65537],
                            [109.998261, 27.647774],
                            [109.995123, 27.642868],
                            [110.002305, 27.632043],
                            [110.012695, 27.632296],
                            [110.025479, 27.63755],
                            [110.033265, 27.639038],
                            [110.037751, 27.638057],
                            [110.046165, 27.631979],
                            [110.055044, 27.621375],
                            [110.052348, 27.616721],
                            [110.055509, 27.602663],
                            [110.066131, 27.589491],
                            [110.07236, 27.586102],
                            [110.078473, 27.576411],
                            [110.089119, 27.576728],
                            [110.092187, 27.571756],
                            [110.090955, 27.563172],
                            [110.08619, 27.548854],
                            [110.085841, 27.539509],
                            [110.09486, 27.52541],
                            [110.09479, 27.517995],
                            [110.087724, 27.511657],
                            [110.09307, 27.507538],
                            [110.089444, 27.503671],
                            [110.085539, 27.50627],
                            [110.081565, 27.500534],
                            [110.070129, 27.491945],
                            [110.069804, 27.486937],
                            [110.065225, 27.484053],
                            [110.063923, 27.476699],
                            [110.060739, 27.470866],
                            [110.054881, 27.472673],
                            [110.052697, 27.467252],
                            [110.046002, 27.467664],
                            [110.046049, 27.463606],
                            [110.038751, 27.45755],
                            [110.031475, 27.458438],
                            [110.027268, 27.462306],
                            [110.024084, 27.470897],
                            [109.99747, 27.47356],
                            [109.994821, 27.469978],
                            [109.989498, 27.473782],
                            [109.980387, 27.476667],
                            [109.96658, 27.476286],
                            [109.963744, 27.478284],
                            [109.957399, 27.474638],
                            [109.953355, 27.474702],
                            [109.950194, 27.465857],
                            [109.940571, 27.458343],
                            [109.939339, 27.452446],
                            [109.933993, 27.448356],
                            [109.924068, 27.449814],
                            [109.92279, 27.455426],
                            [109.917676, 27.456821],
                            [109.916351, 27.451336],
                            [109.910564, 27.44918],
                            [109.90459, 27.44417],
                            [109.896362, 27.440904],
                            [109.878581, 27.442712]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 431222,
                "name": "沅陵县",
                "center": [110.399161, 28.455554],
                "centroid": [110.595225, 28.570921],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431200
                },
                "subFeatureIndex": 2,
                "acroutes": [100000, 430000, 431200]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [110.721081, 28.3008],
                            [110.727124, 28.309325],
                            [110.733167, 28.311338],
                            [110.737025, 28.315585],
                            [110.736863, 28.319579],
                            [110.74107, 28.325492],
                            [110.750483, 28.331657],
                            [110.753249, 28.33867],
                            [110.758874, 28.34238],
                            [110.769891, 28.345399],
                            [110.793972, 28.354361],
                            [110.812055, 28.363008],
                            [110.825885, 28.371716],
                            [110.832765, 28.380519],
                            [110.832602, 28.387528],
                            [110.835856, 28.393878],
                            [110.846339, 28.398844],
                            [110.853916, 28.398435],
                            [110.859843, 28.40447],
                            [110.862144, 28.4188],
                            [110.870256, 28.423671],
                            [110.872906, 28.427347],
                            [110.888641, 28.438941],
                            [110.889223, 28.447298],
                            [110.905377, 28.45512],
                            [110.917486, 28.469695],
                            [110.92346, 28.473307],
                            [110.925622, 28.482477],
                            [110.929736, 28.484801],
                            [110.938963, 28.479305],
                            [110.943821, 28.478457],
                            [110.952607, 28.487565],
                            [110.959348, 28.492244],
                            [110.96295, 28.498304],
                            [110.966576, 28.509545],
                            [110.9699, 28.512559],
                            [110.981661, 28.511963],
                            [110.982312, 28.515228],
                            [110.993167, 28.521758],
                            [111.002022, 28.53714],
                            [111.013179, 28.545771],
                            [111.022151, 28.541848],
                            [111.028171, 28.543857],
                            [111.039095, 28.539682],
                            [111.041861, 28.535036],
                            [111.049276, 28.536826],
                            [111.059619, 28.53422],
                            [111.067545, 28.53014],
                            [111.075936, 28.530171],
                            [111.079283, 28.537705],
                            [111.068963, 28.551954],
                            [111.064849, 28.554873],
                            [111.05927, 28.563848],
                            [111.054808, 28.565103],
                            [111.054738, 28.570626],
                            [111.062083, 28.574894],
                            [111.0645, 28.5828],
                            [111.055366, 28.590173],
                            [111.057179, 28.59695],
                            [111.055087, 28.602314],
                            [111.057434, 28.607929],
                            [111.051042, 28.608964],
                            [111.049183, 28.6068],
                            [111.050508, 28.600338],
                            [111.043535, 28.603036],
                            [111.040722, 28.599208],
                            [111.041791, 28.59513],
                            [111.03626, 28.596448],
                            [111.033796, 28.591585],
                            [111.03103, 28.594628],
                            [111.026311, 28.590079],
                            [111.020175, 28.589734],
                            [111.015898, 28.593938],
                            [111.015782, 28.599334],
                            [111.008042, 28.598173],
                            [111.010111, 28.587381],
                            [111.005718, 28.583585],
                            [111.000186, 28.586911],
                            [110.986287, 28.587632],
                            [110.978384, 28.58553],
                            [110.972759, 28.579977],
                            [110.95614, 28.568838],
                            [110.950678, 28.56821],
                            [110.950027, 28.577467],
                            [110.943868, 28.591617],
                            [110.945936, 28.601404],
                            [110.943589, 28.606361],
                            [110.93729, 28.610783],
                            [110.93471, 28.617558],
                            [110.946122, 28.622044],
                            [110.955396, 28.619189],
                            [110.960022, 28.622639],
                            [110.970853, 28.620287],
                            [110.978361, 28.620413],
                            [110.987147, 28.618688],
                            [110.991725, 28.620005],
                            [110.995026, 28.630574],
                            [110.995351, 28.63785],
                            [111.005927, 28.642302],
                            [111.015271, 28.649859],
                            [111.027055, 28.652117],
                            [111.038305, 28.650549],
                            [111.051693, 28.652305],
                            [111.06464, 28.659767],
                            [111.061897, 28.668577],
                            [111.055412, 28.673937],
                            [111.046673, 28.684689],
                            [111.051577, 28.692118],
                            [111.065732, 28.6966],
                            [111.07582, 28.692306],
                            [111.081189, 28.691867],
                            [111.085071, 28.695315],
                            [111.09632, 28.700517],
                            [111.095832, 28.709229],
                            [111.101108, 28.713648],
                            [111.101968, 28.722798],
                            [111.105478, 28.72709],
                            [111.102573, 28.734046],
                            [111.115147, 28.74335],
                            [111.108407, 28.751025],
                            [111.106152, 28.760735],
                            [111.098714, 28.767657],
                            [111.101015, 28.778995],
                            [111.092439, 28.781312],
                            [111.086302, 28.785383],
                            [111.078748, 28.793681],
                            [111.079353, 28.805704],
                            [111.072752, 28.809837],
                            [111.071218, 28.816881],
                            [111.064407, 28.823423],
                            [111.059456, 28.825082],
                            [111.048462, 28.825395],
                            [111.040281, 28.822703],
                            [111.033726, 28.828807],
                            [111.030472, 28.839668],
                            [111.022593, 28.846898],
                            [111.013133, 28.851311],
                            [111.012435, 28.854534],
                            [111.003882, 28.867207],
                            [111.000186, 28.867708],
                            [111.003091, 28.872182],
                            [111.002092, 28.875718],
                            [110.992446, 28.888263],
                            [110.989727, 28.897273],
                            [110.972666, 28.904248],
                            [110.964229, 28.904217],
                            [110.958278, 28.906375],
                            [110.957442, 28.910597],
                            [110.962857, 28.917916],
                            [110.955931, 28.926172],
                            [110.955141, 28.930425],
                            [110.95012, 28.939243],
                            [110.956791, 28.951031],
                            [110.954931, 28.960754],
                            [110.95865, 28.963817],
                            [110.965344, 28.965161],
                            [110.974572, 28.97679],
                            [110.982591, 28.976821],
                            [110.986379, 28.983228],
                            [110.98703, 28.988385],
                            [110.983497, 28.987635],
                            [110.979964, 28.991136],
                            [110.978337, 28.987666],
                            [110.973479, 28.990167],
                            [110.967622, 28.988198],
                            [110.970272, 28.993292],
                            [110.965437, 28.993698],
                            [110.962021, 28.998386],
                            [110.956721, 28.997292],
                            [110.950352, 29.001042],
                            [110.954559, 29.001917],
                            [110.943519, 29.011791],
                            [110.936778, 29.01326],
                            [110.928318, 29.018572],
                            [110.919067, 29.02254],
                            [110.913884, 29.029944],
                            [110.914674, 29.035723],
                            [110.903819, 29.034786],
                            [110.903122, 29.021321],
                            [110.905051, 29.017478],
                            [110.915139, 29.008542],
                            [110.909142, 29.003073],
                            [110.905121, 28.995698],
                            [110.900426, 28.995792],
                            [110.897265, 29.001417],
                            [110.894104, 28.994605],
                            [110.889153, 28.992448],
                            [110.878647, 28.994573],
                            [110.868792, 28.998386],
                            [110.860703, 28.998948],
                            [110.847315, 28.995917],
                            [110.840458, 28.992542],
                            [110.839366, 28.98776],
                            [110.842132, 28.981291],
                            [110.850267, 28.968569],
                            [110.851847, 28.952],
                            [110.849151, 28.946528],
                            [110.836809, 28.94187],
                            [110.822816, 28.948248],
                            [110.811427, 28.955283],
                            [110.801549, 28.958409],
                            [110.79862, 28.95522],
                            [110.798062, 28.935741],
                            [110.794994, 28.923358],
                            [110.788858, 28.913819],
                            [110.782513, 28.909284],
                            [110.769915, 28.905093],
                            [110.763639, 28.905843],
                            [110.746834, 28.917728],
                            [110.73935, 28.924452],
                            [110.730471, 28.926141],
                            [110.723544, 28.917728],
                            [110.716943, 28.913412],
                            [110.709877, 28.914632],
                            [110.699511, 28.922075],
                            [110.690888, 28.923983],
                            [110.692119, 28.91801],
                            [110.690655, 28.908158],
                            [110.684333, 28.905906],
                            [110.678592, 28.906187],
                            [110.670526, 28.910848],
                            [110.67034, 28.916133],
                            [110.666505, 28.923483],
                            [110.656976, 28.935053],
                            [110.644285, 28.945028],
                            [110.635964, 28.949686],
                            [110.620739, 28.965193],
                            [110.614743, 28.966631],
                            [110.606607, 28.965568],
                            [110.593312, 28.96935],
                            [110.585898, 28.980447],
                            [110.578994, 28.992917],
                            [110.572579, 29.000573],
                            [110.558401, 28.995448],
                            [110.549452, 28.990042],
                            [110.541782, 28.982322],
                            [110.545849, 28.973789],
                            [110.553543, 28.963348],
                            [110.553427, 28.955564],
                            [110.551056, 28.953282],
                            [110.533228, 28.946153],
                            [110.521932, 28.938493],
                            [110.521072, 28.932739],
                            [110.528742, 28.920731],
                            [110.539016, 28.908439],
                            [110.543037, 28.897617],
                            [110.543967, 28.889327],
                            [110.541015, 28.882695],
                            [110.530765, 28.874122],
                            [110.524373, 28.886105],
                            [110.518469, 28.891923],
                            [110.507591, 28.898493],
                            [110.493064, 28.909847],
                            [110.488276, 28.911786],
                            [110.483906, 28.910785],
                            [110.476561, 28.898774],
                            [110.482651, 28.894801],
                            [110.481768, 28.889421],
                            [110.472145, 28.887481],
                            [110.468031, 28.880223],
                            [110.450924, 28.88282],
                            [110.449157, 28.889827],
                            [110.432817, 28.890766],
                            [110.423102, 28.90062],
                            [110.418662, 28.901777],
                            [110.411689, 28.898086],
                            [110.400207, 28.89574],
                            [110.390886, 28.887262],
                            [110.389771, 28.874435],
                            [110.396325, 28.864203],
                            [110.395024, 28.861074],
                            [110.383565, 28.857413],
                            [110.377591, 28.850247],
                            [110.37178, 28.847055],
                            [110.3659, 28.846116],
                            [110.344772, 28.820762],
                            [110.343168, 28.809085],
                            [110.340495, 28.801759],
                            [110.34261, 28.782001],
                            [110.340379, 28.76985],
                            [110.330872, 28.760046],
                            [110.310883, 28.757133],
                            [110.303422, 28.759138],
                            [110.292916, 28.76653],
                            [110.285362, 28.765496],
                            [110.282898, 28.754095],
                            [110.285525, 28.743507],
                            [110.282666, 28.731414],
                            [110.279226, 28.726902],
                            [110.278203, 28.717471],
                            [110.274461, 28.715528],
                            [110.271532, 28.700737],
                            [110.273717, 28.689422],
                            [110.270277, 28.671398],
                            [110.259864, 28.67331],
                            [110.257679, 28.672401],
                            [110.251055, 28.659924],
                            [110.244012, 28.658168],
                            [110.242571, 28.653058],
                            [110.243338, 28.644748],
                            [110.23597, 28.646003],
                            [110.228671, 28.653152],
                            [110.22314, 28.651458],
                            [110.218607, 28.644184],
                            [110.217445, 28.637975],
                            [110.212076, 28.625682],
                            [110.213168, 28.623329],
                            [110.222047, 28.620789],
                            [110.230833, 28.615927],
                            [110.224557, 28.612069],
                            [110.22723, 28.597954],
                            [110.230833, 28.593185],
                            [110.229927, 28.587538],
                            [110.236621, 28.573356],
                            [110.233855, 28.568241],
                            [110.224232, 28.563315],
                            [110.211076, 28.566547],
                            [110.207543, 28.575113],
                            [110.188623, 28.574988],
                            [110.185857, 28.582706],
                            [110.177583, 28.58575],
                            [110.172469, 28.584244],
                            [110.167542, 28.574768],
                            [110.16545, 28.557007],
                            [110.167193, 28.542507],
                            [110.167123, 28.535036],
                            [110.155804, 28.532839],
                            [110.150876, 28.527817],
                            [110.150876, 28.517991],
                            [110.148598, 28.483671],
                            [110.15506, 28.473338],
                            [110.16359, 28.467527],
                            [110.157733, 28.461434],
                            [110.141649, 28.456502],
                            [110.138139, 28.447738],
                            [110.135629, 28.446732],
                            [110.126355, 28.448617],
                            [110.128377, 28.455497],
                            [110.127377, 28.459957],
                            [110.121706, 28.462093],
                            [110.109224, 28.463633],
                            [110.10604, 28.462062],
                            [110.103018, 28.452701],
                            [110.106667, 28.451036],
                            [110.114222, 28.442554],
                            [110.114617, 28.437527],
                            [110.110363, 28.425273],
                            [110.111734, 28.415909],
                            [110.119986, 28.405695],
                            [110.12582, 28.40249],
                            [110.141439, 28.383316],
                            [110.145786, 28.383159],
                            [110.148133, 28.389603],
                            [110.152085, 28.391709],
                            [110.161963, 28.386397],
                            [110.170075, 28.378035],
                            [110.179791, 28.376495],
                            [110.190413, 28.363228],
                            [110.194341, 28.361718],
                            [110.205823, 28.36263],
                            [110.212773, 28.348638],
                            [110.203057, 28.33977],
                            [110.191505, 28.337852],
                            [110.185044, 28.333166],
                            [110.183208, 28.322001],
                            [110.178094, 28.316402],
                            [110.178443, 28.302687],
                            [110.185555, 28.289852],
                            [110.185764, 28.285667],
                            [110.176932, 28.28466],
                            [110.175049, 28.280538],
                            [110.177257, 28.275472],
                            [110.18337, 28.269525],
                            [110.182162, 28.265717],
                            [110.186508, 28.259392],
                            [110.19548, 28.254293],
                            [110.205568, 28.252814],
                            [110.208822, 28.243781],
                            [110.216329, 28.242962],
                            [110.220257, 28.240004],
                            [110.226533, 28.240854],
                            [110.235342, 28.236478],
                            [110.240293, 28.230843],
                            [110.23769, 28.227821],
                            [110.243477, 28.217842],
                            [110.244361, 28.212993],
                            [110.249265, 28.2123],
                            [110.257284, 28.21781],
                            [110.262467, 28.217149],
                            [110.270579, 28.213371],
                            [110.262165, 28.205468],
                            [110.261491, 28.201689],
                            [110.270998, 28.206444],
                            [110.285734, 28.209781],
                            [110.304468, 28.199328],
                            [110.30907, 28.192715],
                            [110.315648, 28.189629],
                            [110.317972, 28.181031],
                            [110.314881, 28.163834],
                            [110.315927, 28.158101],
                            [110.321389, 28.153281],
                            [110.323434, 28.156274],
                            [110.324201, 28.169125],
                            [110.32706, 28.17533],
                            [110.330965, 28.178574],
                            [110.341587, 28.171362],
                            [110.353883, 28.165472],
                            [110.356021, 28.159707],
                            [110.354046, 28.154257],
                            [110.355766, 28.150792],
                            [110.36597, 28.143515],
                            [110.366295, 28.137276],
                            [110.358718, 28.133527],
                            [110.347305, 28.13192],
                            [110.346259, 28.121616],
                            [110.348421, 28.111879],
                            [110.354604, 28.105197],
                            [110.364343, 28.103369],
                            [110.36339, 28.099272],
                            [110.369619, 28.096309],
                            [110.382054, 28.097255],
                            [110.39084, 28.100942],
                            [110.405855, 28.089501],
                            [110.416965, 28.089028],
                            [110.432724, 28.081242],
                            [110.437141, 28.08121],
                            [110.449274, 28.093378],
                            [110.453667, 28.09404],
                            [110.461128, 28.090604],
                            [110.478932, 28.09467],
                            [110.500595, 28.096467],
                            [110.512728, 28.110649],
                            [110.518934, 28.116574],
                            [110.534158, 28.128233],
                            [110.538551, 28.130061],
                            [110.54399, 28.138316],
                            [110.560795, 28.161188],
                            [110.56728, 28.167614],
                            [110.588478, 28.170795],
                            [110.601447, 28.17996],
                            [110.614417, 28.190731],
                            [110.625318, 28.19621],
                            [110.652048, 28.212489],
                            [110.658649, 28.221903],
                            [110.670922, 28.231221],
                            [110.673873, 28.237958],
                            [110.67055, 28.243592],
                            [110.662322, 28.24504],
                            [110.657905, 28.250328],
                            [110.659091, 28.262696],
                            [110.662089, 28.27135],
                            [110.664809, 28.274654],
                            [110.665878, 28.288247],
                            [110.672549, 28.297528],
                            [110.681428, 28.298566],
                            [110.686099, 28.296647],
                            [110.699511, 28.299856],
                            [110.709273, 28.29693],
                            [110.721081, 28.3008]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 431223,
                "name": "辰溪县",
                "center": [110.196953, 28.005474],
                "centroid": [110.265855, 27.890091],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431200
                },
                "subFeatureIndex": 3,
                "acroutes": [100000, 430000, 431200]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [110.069618, 27.968328],
                            [110.073313, 27.965077],
                            [110.072988, 27.957945],
                            [110.077009, 27.952327],
                            [110.08633, 27.944784],
                            [110.084981, 27.937713],
                            [110.0801, 27.931589],
                            [110.080193, 27.920351],
                            [110.083633, 27.911479],
                            [110.090676, 27.907122],
                            [110.096557, 27.895975],
                            [110.095883, 27.892218],
                            [110.088747, 27.886912],
                            [110.090258, 27.880185],
                            [110.088538, 27.87529],
                            [110.077683, 27.876395],
                            [110.066085, 27.882586],
                            [110.056997, 27.881607],
                            [110.052162, 27.877817],
                            [110.055346, 27.866857],
                            [110.054091, 27.86215],
                            [110.046769, 27.851726],
                            [110.035845, 27.83991],
                            [110.030499, 27.837067],
                            [110.012183, 27.833812],
                            [110.001608, 27.830558],
                            [109.989173, 27.825186],
                            [109.979945, 27.825534],
                            [109.969811, 27.81972],
                            [109.964674, 27.818582],
                            [109.95626, 27.819593],
                            [109.950147, 27.815201],
                            [109.938642, 27.814221],
                            [109.931855, 27.811661],
                            [109.928136, 27.81381],
                            [109.926532, 27.818961],
                            [109.928508, 27.822342],
                            [109.928415, 27.832422],
                            [109.925463, 27.836561],
                            [109.920372, 27.831221],
                            [109.923115, 27.825881],
                            [109.922767, 27.818898],
                            [109.916003, 27.815169],
                            [109.918583, 27.809923],
                            [109.917514, 27.797882],
                            [109.919257, 27.789063],
                            [109.923069, 27.77879],
                            [109.919559, 27.77174],
                            [109.921325, 27.767788],
                            [109.926462, 27.765701],
                            [109.928391, 27.757924],
                            [109.933017, 27.753339],
                            [109.932947, 27.75043],
                            [109.937665, 27.746319],
                            [109.938688, 27.740659],
                            [109.943035, 27.731899],
                            [109.958956, 27.72978],
                            [109.963907, 27.736421],
                            [109.973855, 27.734967],
                            [109.977946, 27.739837],
                            [109.98564, 27.743536],
                            [109.997703, 27.73952],
                            [110.000353, 27.734777],
                            [110.007767, 27.735441],
                            [110.014903, 27.742967],
                            [110.024061, 27.741987],
                            [110.029988, 27.739236],
                            [110.033521, 27.734935],
                            [110.034218, 27.741133],
                            [110.041958, 27.743347],
                            [110.043515, 27.737844],
                            [110.046816, 27.73601],
                            [110.058298, 27.736326],
                            [110.070222, 27.737908],
                            [110.073778, 27.742683],
                            [110.081495, 27.74477],
                            [110.089793, 27.734271],
                            [110.107388, 27.729875],
                            [110.123542, 27.727566],
                            [110.139836, 27.727534],
                            [110.156431, 27.72861],
                            [110.164427, 27.728262],
                            [110.188019, 27.720956],
                            [110.200919, 27.716243],
                            [110.22042, 27.704571],
                            [110.231972, 27.698529],
                            [110.23841, 27.693657],
                            [110.247057, 27.691189],
                            [110.254169, 27.68445],
                            [110.260166, 27.685114],
                            [110.272671, 27.690746],
                            [110.290917, 27.692708],
                            [110.299912, 27.691632],
                            [110.306234, 27.692518],
                            [110.312952, 27.689354],
                            [110.32706, 27.693467],
                            [110.340123, 27.686],
                            [110.34361, 27.68233],
                            [110.341053, 27.674895],
                            [110.337404, 27.672996],
                            [110.335265, 27.664199],
                            [110.33566, 27.638025],
                            [110.340355, 27.631315],
                            [110.347956, 27.627421],
                            [110.34877, 27.620868],
                            [110.352303, 27.616531],
                            [110.350768, 27.61365],
                            [110.34261, 27.609661],
                            [110.342796, 27.607096],
                            [110.348956, 27.601713],
                            [110.343703, 27.585152],
                            [110.349118, 27.586292],
                            [110.359694, 27.582049],
                            [110.362994, 27.582904],
                            [110.365853, 27.589871],
                            [110.378196, 27.590219],
                            [110.382472, 27.585152],
                            [110.386145, 27.587844],
                            [110.389538, 27.583759],
                            [110.39772, 27.579863],
                            [110.406901, 27.581194],
                            [110.420173, 27.580624],
                            [110.424589, 27.590251],
                            [110.429517, 27.595095],
                            [110.435606, 27.595475],
                            [110.437559, 27.584075],
                            [110.443904, 27.585691],
                            [110.449948, 27.582239],
                            [110.465218, 27.60279],
                            [110.468077, 27.615106],
                            [110.483534, 27.636379],
                            [110.490437, 27.643881],
                            [110.497596, 27.656129],
                            [110.499897, 27.664294],
                            [110.506987, 27.679799],
                            [110.514332, 27.690461],
                            [110.531392, 27.703843],
                            [110.536459, 27.710929],
                            [110.533345, 27.719374],
                            [110.518376, 27.727914],
                            [110.510496, 27.734271],
                            [110.505081, 27.743252],
                            [110.502175, 27.750967],
                            [110.507893, 27.764468],
                            [110.504755, 27.776798],
                            [110.49655, 27.786598],
                            [110.490623, 27.791244],
                            [110.481001, 27.803191],
                            [110.469495, 27.813873],
                            [110.45613, 27.819783],
                            [110.447182, 27.817223],
                            [110.431888, 27.807837],
                            [110.425031, 27.801042],
                            [110.413363, 27.791939],
                            [110.402857, 27.78508],
                            [110.388655, 27.781793],
                            [110.370153, 27.784701],
                            [110.367992, 27.787767],
                            [110.370944, 27.798198],
                            [110.378846, 27.80474],
                            [110.382077, 27.811029],
                            [110.3778, 27.817634],
                            [110.366923, 27.822469],
                            [110.356138, 27.820194],
                            [110.332151, 27.809291],
                            [110.32197, 27.808343],
                            [110.310534, 27.818361],
                            [110.306211, 27.82566],
                            [110.302864, 27.825629],
                            [110.296519, 27.820352],
                            [110.291591, 27.819214],
                            [110.288895, 27.822058],
                            [110.291103, 27.829231],
                            [110.281666, 27.83397],
                            [110.275135, 27.830811],
                            [110.270509, 27.837256],
                            [110.270416, 27.843543],
                            [110.272648, 27.847366],
                            [110.273694, 27.84288],
                            [110.281945, 27.85558],
                            [110.293846, 27.846387],
                            [110.296798, 27.846482],
                            [110.297239, 27.853242],
                            [110.289104, 27.856717],
                            [110.289755, 27.86155],
                            [110.297797, 27.861297],
                            [110.306908, 27.856401],
                            [110.313231, 27.857128],
                            [110.317833, 27.862908],
                            [110.318391, 27.8715],
                            [110.315811, 27.881259],
                            [110.322644, 27.884007],
                            [110.32297, 27.88906],
                            [110.31516, 27.892249],
                            [110.314346, 27.904691],
                            [110.310395, 27.910879],
                            [110.312092, 27.915678],
                            [110.324759, 27.923224],
                            [110.336962, 27.927296],
                            [110.34268, 27.927296],
                            [110.359671, 27.924929],
                            [110.370316, 27.920351],
                            [110.379799, 27.909364],
                            [110.384843, 27.906869],
                            [110.396046, 27.907722],
                            [110.401137, 27.911163],
                            [110.403786, 27.917509],
                            [110.39858, 27.921393],
                            [110.410852, 27.928496],
                            [110.41743, 27.92878],
                            [110.424705, 27.933641],
                            [110.426495, 27.931337],
                            [110.422358, 27.923981],
                            [110.427936, 27.917604],
                            [110.442161, 27.913847],
                            [110.449901, 27.915236],
                            [110.451598, 27.920824],
                            [110.455596, 27.924834],
                            [110.463684, 27.929474],
                            [110.462034, 27.932063],
                            [110.466334, 27.93522],
                            [110.466125, 27.946362],
                            [110.47519, 27.950434],
                            [110.47777, 27.954694],
                            [110.474493, 27.959681],
                            [110.480373, 27.971263],
                            [110.480954, 27.981108],
                            [110.473749, 27.986503],
                            [110.465985, 27.996757],
                            [110.469333, 28.01029],
                            [110.471169, 28.033],
                            [110.475306, 28.044447],
                            [110.486649, 28.062547],
                            [110.493389, 28.083385],
                            [110.500595, 28.096467],
                            [110.478932, 28.09467],
                            [110.461128, 28.090604],
                            [110.453667, 28.09404],
                            [110.449274, 28.093378],
                            [110.437141, 28.08121],
                            [110.432724, 28.081242],
                            [110.416965, 28.089028],
                            [110.405855, 28.089501],
                            [110.39084, 28.100942],
                            [110.382054, 28.097255],
                            [110.369619, 28.096309],
                            [110.36339, 28.099272],
                            [110.364343, 28.103369],
                            [110.354604, 28.105197],
                            [110.348421, 28.111879],
                            [110.346259, 28.121616],
                            [110.347305, 28.13192],
                            [110.358718, 28.133527],
                            [110.366295, 28.137276],
                            [110.36597, 28.143515],
                            [110.355766, 28.150792],
                            [110.354046, 28.154257],
                            [110.356021, 28.159707],
                            [110.353883, 28.165472],
                            [110.341587, 28.171362],
                            [110.330965, 28.178574],
                            [110.32706, 28.17533],
                            [110.324201, 28.169125],
                            [110.323434, 28.156274],
                            [110.321389, 28.153281],
                            [110.315927, 28.158101],
                            [110.314881, 28.163834],
                            [110.317972, 28.181031],
                            [110.315648, 28.189629],
                            [110.30907, 28.192715],
                            [110.304468, 28.199328],
                            [110.285734, 28.209781],
                            [110.270998, 28.206444],
                            [110.261491, 28.201689],
                            [110.262165, 28.205468],
                            [110.270579, 28.213371],
                            [110.262467, 28.217149],
                            [110.257284, 28.21781],
                            [110.249265, 28.2123],
                            [110.244361, 28.212993],
                            [110.242966, 28.207231],
                            [110.235923, 28.19769],
                            [110.231228, 28.196588],
                            [110.223023, 28.198068],
                            [110.216655, 28.204366],
                            [110.209287, 28.202886],
                            [110.196898, 28.202728],
                            [110.191459, 28.201217],
                            [110.191273, 28.197029],
                            [110.197804, 28.193502],
                            [110.201988, 28.187865],
                            [110.199989, 28.178763],
                            [110.194295, 28.173504],
                            [110.174538, 28.165724],
                            [110.168588, 28.160621],
                            [110.169169, 28.155581],
                            [110.182231, 28.14572],
                            [110.184068, 28.139167],
                            [110.177885, 28.133968],
                            [110.154897, 28.130408],
                            [110.150039, 28.127288],
                            [110.151806, 28.120891],
                            [110.166333, 28.110618],
                            [110.166751, 28.103117],
                            [110.1585, 28.095931],
                            [110.14732, 28.09218],
                            [110.123193, 28.093661],
                            [110.114593, 28.093126],
                            [110.111758, 28.090951],
                            [110.112339, 28.085245],
                            [110.11629, 28.075567],
                            [110.124495, 28.065731],
                            [110.137442, 28.057786],
                            [110.135768, 28.05129],
                            [110.137511, 28.047285],
                            [110.1317, 28.0447],
                            [110.130817, 28.039623],
                            [110.122194, 28.040033],
                            [110.128586, 28.024831],
                            [110.128214, 28.020605],
                            [110.124402, 28.016725],
                            [110.117708, 28.016441],
                            [110.111153, 28.005527],
                            [110.109433, 27.993065],
                            [110.110317, 27.988522],
                            [110.10353, 27.983222],
                            [110.099253, 27.977069],
                            [110.090025, 27.973819],
                            [110.084703, 27.974481],
                            [110.081797, 27.969527],
                            [110.069618, 27.968328]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 431224,
                "name": "溆浦县",
                "center": [110.593373, 27.903802],
                "centroid": [110.650583, 27.831449],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431200
                },
                "subFeatureIndex": 4,
                "acroutes": [100000, 430000, 431200]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [110.52428, 27.293387],
                            [110.535762, 27.302405],
                            [110.540852, 27.305167],
                            [110.553938, 27.308246],
                            [110.558656, 27.299325],
                            [110.562282, 27.296372],
                            [110.573997, 27.281035],
                            [110.578901, 27.278558],
                            [110.585084, 27.27859],
                            [110.598705, 27.281448],
                            [110.609071, 27.288974],
                            [110.609629, 27.296848],
                            [110.606468, 27.300881],
                            [110.610001, 27.309516],
                            [110.605747, 27.31685],
                            [110.606561, 27.32104],
                            [110.614859, 27.324119],
                            [110.621948, 27.330341],
                            [110.622436, 27.333324],
                            [110.632221, 27.343259],
                            [110.636498, 27.353636],
                            [110.649212, 27.365187],
                            [110.649282, 27.373723],
                            [110.654907, 27.380195],
                            [110.659672, 27.396755],
                            [110.660323, 27.412171],
                            [110.663089, 27.420672],
                            [110.674106, 27.434562],
                            [110.688238, 27.443853],
                            [110.691585, 27.447563],
                            [110.693328, 27.455838],
                            [110.684658, 27.46871],
                            [110.684008, 27.487888],
                            [110.680312, 27.497048],
                            [110.671968, 27.506143],
                            [110.659718, 27.51752],
                            [110.648213, 27.529592],
                            [110.63264, 27.536087],
                            [110.631315, 27.540459],
                            [110.633081, 27.54689],
                            [110.638125, 27.555919],
                            [110.652769, 27.565833],
                            [110.653047, 27.574194],
                            [110.661415, 27.572896],
                            [110.657231, 27.578248],
                            [110.65758, 27.583062],
                            [110.662205, 27.585627],
                            [110.666622, 27.592689],
                            [110.669876, 27.593702],
                            [110.674036, 27.600067],
                            [110.680219, 27.602632],
                            [110.693421, 27.602758],
                            [110.705322, 27.60469],
                            [110.710528, 27.606938],
                            [110.714456, 27.616024],
                            [110.734143, 27.616372],
                            [110.732749, 27.621501],
                            [110.734934, 27.625933],
                            [110.741047, 27.625648],
                            [110.743673, 27.617354],
                            [110.738002, 27.609882],
                            [110.737932, 27.600668],
                            [110.746625, 27.603866],
                            [110.755829, 27.596837],
                            [110.767033, 27.581289],
                            [110.776911, 27.574004],
                            [110.786813, 27.560512],
                            [110.799457, 27.559435],
                            [110.808708, 27.563394],
                            [110.824327, 27.578818],
                            [110.842689, 27.588414],
                            [110.853474, 27.592024],
                            [110.85459, 27.601808],
                            [110.847664, 27.608996],
                            [110.844526, 27.620963],
                            [110.845641, 27.624255],
                            [110.857286, 27.621406],
                            [110.861586, 27.62492],
                            [110.852312, 27.627579],
                            [110.854265, 27.630681],
                            [110.853846, 27.641697],
                            [110.85559, 27.647362],
                            [110.868954, 27.653154],
                            [110.871767, 27.658218],
                            [110.868304, 27.672901],
                            [110.860006, 27.677236],
                            [110.849174, 27.675812],
                            [110.837529, 27.681508],
                            [110.833299, 27.696631],
                            [110.830347, 27.714978],
                            [110.818261, 27.7436],
                            [110.816122, 27.753908],
                            [110.81145, 27.757797],
                            [110.800619, 27.756817],
                            [110.795692, 27.758177],
                            [110.790206, 27.767567],
                            [110.789114, 27.775344],
                            [110.785697, 27.782678],
                            [110.780746, 27.787925],
                            [110.774029, 27.789948],
                            [110.767776, 27.795416],
                            [110.768497, 27.800695],
                            [110.776446, 27.811756],
                            [110.78586, 27.832928],
                            [110.791647, 27.855675],
                            [110.795227, 27.860981],
                            [110.806569, 27.864235],
                            [110.821096, 27.858107],
                            [110.828093, 27.852642],
                            [110.837181, 27.84923],
                            [110.868373, 27.84784],
                            [110.875346, 27.845818],
                            [110.882064, 27.850494],
                            [110.894545, 27.849893],
                            [110.898497, 27.85599],
                            [110.903773, 27.855548],
                            [110.909421, 27.858581],
                            [110.912791, 27.864267],
                            [110.917556, 27.865814],
                            [110.922344, 27.863287],
                            [110.923506, 27.870205],
                            [110.929898, 27.875069],
                            [110.930107, 27.880722],
                            [110.941566, 27.889975],
                            [110.944797, 27.896228],
                            [110.954513, 27.897523],
                            [110.958929, 27.904249],
                            [110.963485, 27.905101],
                            [110.973944, 27.910879],
                            [110.980429, 27.920004],
                            [110.978291, 27.924108],
                            [110.970783, 27.925244],
                            [110.969761, 27.93885],
                            [110.966692, 27.949234],
                            [110.96818, 27.953211],
                            [110.975176, 27.961448],
                            [110.987425, 27.972903],
                            [110.995932, 27.988333],
                            [111.000535, 27.992245],
                            [110.990982, 27.992971],
                            [110.982382, 27.998492],
                            [110.971132, 28.002751],
                            [110.968994, 28.006662],
                            [110.976222, 28.014485],
                            [110.977663, 28.02543],
                            [110.980406, 28.029089],
                            [110.995258, 28.030098],
                            [111.013202, 28.028931],
                            [111.014085, 28.033599],
                            [111.022011, 28.041515],
                            [111.010483, 28.04451],
                            [110.992144, 28.054033],
                            [110.983846, 28.053088],
                            [110.972271, 28.054002],
                            [110.97083, 28.056051],
                            [110.970923, 28.065826],
                            [110.964438, 28.063587],
                            [110.962671, 28.058101],
                            [110.958883, 28.055862],
                            [110.953467, 28.057124],
                            [110.954629, 28.049177],
                            [110.942984, 28.038361],
                            [110.938638, 28.028111],
                            [110.938057, 28.018491],
                            [110.925761, 28.02133],
                            [110.927806, 28.017514],
                            [110.922182, 28.012814],
                            [110.924947, 28.004454],
                            [110.9173, 28.007388],
                            [110.922065, 28.000637],
                            [110.907678, 27.998208],
                            [110.904168, 27.999596],
                            [110.896149, 27.998618],
                            [110.889571, 28.000984],
                            [110.878159, 28.013066],
                            [110.869047, 28.011678],
                            [110.866188, 28.01376],
                            [110.866444, 28.025462],
                            [110.872813, 28.036658],
                            [110.882412, 28.045993],
                            [110.884086, 28.051006],
                            [110.896126, 28.058763],
                            [110.889501, 28.067875],
                            [110.876369, 28.081809],
                            [110.871465, 28.082377],
                            [110.868048, 28.078562],
                            [110.859913, 28.07645],
                            [110.854288, 28.0779],
                            [110.843317, 28.083827],
                            [110.833183, 28.087483],
                            [110.823467, 28.087452],
                            [110.834996, 28.104724],
                            [110.844317, 28.116354],
                            [110.840528, 28.120072],
                            [110.83302, 28.118875],
                            [110.834113, 28.125334],
                            [110.829301, 28.127099],
                            [110.818656, 28.139671],
                            [110.829627, 28.152304],
                            [110.836716, 28.157408],
                            [110.848012, 28.170511],
                            [110.849105, 28.177283],
                            [110.853242, 28.1884],
                            [110.860494, 28.198603],
                            [110.861424, 28.203547],
                            [110.858541, 28.205153],
                            [110.848895, 28.204712],
                            [110.846548, 28.206601],
                            [110.848059, 28.213024],
                            [110.840063, 28.21463],
                            [110.835066, 28.209498],
                            [110.826791, 28.209057],
                            [110.824606, 28.203767],
                            [110.815727, 28.206601],
                            [110.810033, 28.211734],
                            [110.802479, 28.215354],
                            [110.799852, 28.223194],
                            [110.787859, 28.231253],
                            [110.788579, 28.233519],
                            [110.80629, 28.253003],
                            [110.802711, 28.264364],
                            [110.793019, 28.275913],
                            [110.783326, 28.278241],
                            [110.77461, 28.273112],
                            [110.765475, 28.274277],
                            [110.749693, 28.280916],
                            [110.739536, 28.281608],
                            [110.733469, 28.285164],
                            [110.732051, 28.288782],
                            [110.721081, 28.3008],
                            [110.709273, 28.29693],
                            [110.699511, 28.299856],
                            [110.686099, 28.296647],
                            [110.681428, 28.298566],
                            [110.672549, 28.297528],
                            [110.665878, 28.288247],
                            [110.664809, 28.274654],
                            [110.662089, 28.27135],
                            [110.659091, 28.262696],
                            [110.657905, 28.250328],
                            [110.662322, 28.24504],
                            [110.67055, 28.243592],
                            [110.673873, 28.237958],
                            [110.670922, 28.231221],
                            [110.658649, 28.221903],
                            [110.652048, 28.212489],
                            [110.625318, 28.19621],
                            [110.614417, 28.190731],
                            [110.601447, 28.17996],
                            [110.588478, 28.170795],
                            [110.56728, 28.167614],
                            [110.560795, 28.161188],
                            [110.54399, 28.138316],
                            [110.538551, 28.130061],
                            [110.534158, 28.128233],
                            [110.518934, 28.116574],
                            [110.512728, 28.110649],
                            [110.500595, 28.096467],
                            [110.493389, 28.083385],
                            [110.486649, 28.062547],
                            [110.475306, 28.044447],
                            [110.471169, 28.033],
                            [110.469333, 28.01029],
                            [110.465985, 27.996757],
                            [110.473749, 27.986503],
                            [110.480954, 27.981108],
                            [110.480373, 27.971263],
                            [110.474493, 27.959681],
                            [110.47777, 27.954694],
                            [110.47519, 27.950434],
                            [110.466125, 27.946362],
                            [110.466334, 27.93522],
                            [110.462034, 27.932063],
                            [110.463684, 27.929474],
                            [110.455596, 27.924834],
                            [110.451598, 27.920824],
                            [110.449901, 27.915236],
                            [110.442161, 27.913847],
                            [110.427936, 27.917604],
                            [110.422358, 27.923981],
                            [110.426495, 27.931337],
                            [110.424705, 27.933641],
                            [110.41743, 27.92878],
                            [110.410852, 27.928496],
                            [110.39858, 27.921393],
                            [110.403786, 27.917509],
                            [110.401137, 27.911163],
                            [110.396046, 27.907722],
                            [110.384843, 27.906869],
                            [110.379799, 27.909364],
                            [110.370316, 27.920351],
                            [110.359671, 27.924929],
                            [110.34268, 27.927296],
                            [110.336962, 27.927296],
                            [110.324759, 27.923224],
                            [110.312092, 27.915678],
                            [110.310395, 27.910879],
                            [110.314346, 27.904691],
                            [110.31516, 27.892249],
                            [110.32297, 27.88906],
                            [110.322644, 27.884007],
                            [110.315811, 27.881259],
                            [110.318391, 27.8715],
                            [110.317833, 27.862908],
                            [110.313231, 27.857128],
                            [110.306908, 27.856401],
                            [110.297797, 27.861297],
                            [110.289755, 27.86155],
                            [110.289104, 27.856717],
                            [110.297239, 27.853242],
                            [110.296798, 27.846482],
                            [110.293846, 27.846387],
                            [110.281945, 27.85558],
                            [110.273694, 27.84288],
                            [110.272648, 27.847366],
                            [110.270416, 27.843543],
                            [110.270509, 27.837256],
                            [110.275135, 27.830811],
                            [110.281666, 27.83397],
                            [110.291103, 27.829231],
                            [110.288895, 27.822058],
                            [110.291591, 27.819214],
                            [110.296519, 27.820352],
                            [110.302864, 27.825629],
                            [110.306211, 27.82566],
                            [110.310534, 27.818361],
                            [110.32197, 27.808343],
                            [110.332151, 27.809291],
                            [110.356138, 27.820194],
                            [110.366923, 27.822469],
                            [110.3778, 27.817634],
                            [110.382077, 27.811029],
                            [110.378846, 27.80474],
                            [110.370944, 27.798198],
                            [110.367992, 27.787767],
                            [110.370153, 27.784701],
                            [110.388655, 27.781793],
                            [110.402857, 27.78508],
                            [110.413363, 27.791939],
                            [110.425031, 27.801042],
                            [110.431888, 27.807837],
                            [110.447182, 27.817223],
                            [110.45613, 27.819783],
                            [110.469495, 27.813873],
                            [110.481001, 27.803191],
                            [110.490623, 27.791244],
                            [110.49655, 27.786598],
                            [110.504755, 27.776798],
                            [110.507893, 27.764468],
                            [110.502175, 27.750967],
                            [110.505081, 27.743252],
                            [110.510496, 27.734271],
                            [110.518376, 27.727914],
                            [110.533345, 27.719374],
                            [110.536459, 27.710929],
                            [110.531392, 27.703843],
                            [110.514332, 27.690461],
                            [110.506987, 27.679799],
                            [110.499897, 27.664294],
                            [110.497596, 27.656129],
                            [110.490437, 27.643881],
                            [110.483534, 27.636379],
                            [110.468077, 27.615106],
                            [110.465218, 27.60279],
                            [110.47254, 27.60051],
                            [110.47777, 27.594905],
                            [110.471657, 27.585152],
                            [110.481326, 27.577868],
                            [110.484929, 27.57866],
                            [110.487439, 27.586546],
                            [110.48537, 27.588636],
                            [110.494947, 27.588604],
                            [110.494156, 27.581637],
                            [110.488834, 27.580148],
                            [110.494528, 27.57052],
                            [110.495295, 27.566181],
                            [110.492529, 27.560385],
                            [110.492181, 27.549805],
                            [110.488299, 27.53783],
                            [110.484324, 27.531366],
                            [110.473493, 27.518344],
                            [110.468403, 27.514921],
                            [110.460965, 27.507031],
                            [110.457525, 27.496858],
                            [110.456595, 27.489536],
                            [110.457664, 27.472958],
                            [110.459036, 27.46554],
                            [110.467241, 27.44287],
                            [110.465869, 27.432818],
                            [110.461174, 27.423177],
                            [110.456851, 27.41972],
                            [110.440418, 27.411981],
                            [110.433073, 27.41417],
                            [110.422079, 27.388951],
                            [110.41578, 27.369249],
                            [110.415943, 27.354303],
                            [110.417361, 27.339069],
                            [110.423636, 27.331896],
                            [110.431818, 27.335197],
                            [110.442324, 27.356048],
                            [110.44681, 27.357698],
                            [110.457362, 27.353287],
                            [110.474493, 27.351288],
                            [110.483418, 27.354525],
                            [110.497852, 27.354525],
                            [110.518492, 27.350971],
                            [110.523396, 27.347289],
                            [110.527394, 27.340656],
                            [110.527743, 27.333515],
                            [110.525674, 27.324659],
                            [110.521305, 27.316373],
                            [110.522513, 27.302055],
                            [110.524861, 27.298277],
                            [110.52428, 27.293387]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 431225,
                "name": "会同县",
                "center": [109.720785, 26.870789],
                "centroid": [109.803953, 26.908949],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431200
                },
                "subFeatureIndex": 5,
                "acroutes": [100000, 430000, 431200]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [109.469685, 27.069438],
                            [109.464293, 27.070679],
                            [109.454577, 27.06947],
                            [109.455762, 27.065779],
                            [109.466989, 27.065079],
                            [109.478215, 27.060338],
                            [109.487094, 27.053943],
                            [109.495322, 27.060593],
                            [109.494834, 27.063743],
                            [109.488024, 27.066479],
                            [109.486002, 27.072142],
                            [109.489791, 27.078632],
                            [109.498484, 27.079364],
                            [109.505061, 27.072715],
                            [109.518519, 27.072556],
                            [109.522029, 27.069056],
                            [109.510477, 27.067943],
                            [109.519751, 27.058684],
                            [109.516706, 27.04901],
                            [109.517915, 27.043219],
                            [109.525004, 27.037268],
                            [109.525794, 27.026542],
                            [109.528095, 27.023582],
                            [109.541344, 27.012887],
                            [109.53995, 27.005597],
                            [109.542344, 26.996461],
                            [109.538764, 26.992736],
                            [109.53207, 26.993182],
                            [109.528584, 26.988374],
                            [109.53049, 26.983312],
                            [109.527607, 26.977198],
                            [109.536812, 26.974682],
                            [109.546713, 26.984585],
                            [109.550548, 26.980478],
                            [109.548619, 26.974173],
                            [109.550944, 26.959047],
                            [109.553314, 26.955384],
                            [109.554988, 26.946912],
                            [109.549177, 26.942931],
                            [109.53895, 26.94188],
                            [109.533627, 26.938599],
                            [109.532256, 26.929871],
                            [109.526701, 26.925889],
                            [109.514103, 26.930763],
                            [109.501877, 26.926048],
                            [109.493254, 26.92111],
                            [109.484235, 26.91356],
                            [109.471777, 26.91117],
                            [109.469708, 26.907315],
                            [109.458226, 26.901867],
                            [109.454089, 26.903141],
                            [109.446604, 26.899668],
                            [109.443467, 26.89492],
                            [109.436424, 26.892626],
                            [109.435982, 26.887049],
                            [109.438539, 26.877393],
                            [109.444861, 26.871752],
                            [109.436633, 26.862445],
                            [109.437563, 26.860309],
                            [109.447883, 26.859608],
                            [109.457482, 26.864103],
                            [109.456739, 26.873218],
                            [109.472939, 26.884787],
                            [109.474194, 26.894283],
                            [109.481911, 26.897087],
                            [109.486815, 26.895303],
                            [109.49086, 26.88364],
                            [109.499158, 26.877999],
                            [109.509385, 26.878126],
                            [109.513801, 26.87427],
                            [109.510361, 26.865505],
                            [109.511081, 26.854954],
                            [109.51408, 26.844658],
                            [109.513127, 26.840258],
                            [109.501064, 26.828653],
                            [109.497321, 26.823073],
                            [109.497461, 26.815261],
                            [109.507688, 26.801166],
                            [109.517543, 26.779924],
                            [109.518287, 26.766144],
                            [109.517497, 26.756222],
                            [109.522285, 26.755648],
                            [109.522215, 26.749362],
                            [109.528653, 26.744034],
                            [109.534185, 26.744544],
                            [109.543878, 26.740907],
                            [109.553896, 26.735291],
                            [109.559265, 26.735163],
                            [109.567516, 26.741928],
                            [109.561519, 26.742215],
                            [109.556731, 26.745629],
                            [109.559172, 26.747799],
                            [109.558056, 26.754659],
                            [109.561705, 26.751341],
                            [109.570422, 26.747895],
                            [109.577348, 26.751596],
                            [109.580346, 26.755648],
                            [109.577766, 26.763177],
                            [109.577116, 26.770578],
                            [109.579835, 26.770737],
                            [109.596431, 26.760688],
                            [109.597128, 26.756094],
                            [109.588458, 26.7515],
                            [109.586715, 26.74531],
                            [109.579068, 26.740811],
                            [109.578162, 26.733281],
                            [109.56826, 26.726196],
                            [109.553361, 26.723005],
                            [109.547294, 26.717899],
                            [109.544831, 26.722654],
                            [109.547899, 26.727664],
                            [109.546016, 26.731174],
                            [109.538555, 26.734046],
                            [109.529141, 26.740811],
                            [109.528188, 26.729196],
                            [109.526236, 26.726803],
                            [109.512918, 26.721345],
                            [109.514475, 26.716271],
                            [109.523912, 26.713526],
                            [109.534743, 26.714196],
                            [109.555081, 26.708324],
                            [109.5707, 26.698269],
                            [109.580556, 26.690512],
                            [109.590969, 26.686426],
                            [109.59413, 26.690225],
                            [109.598802, 26.688852],
                            [109.607285, 26.689395],
                            [109.6104, 26.686011],
                            [109.614468, 26.687448],
                            [109.611655, 26.694407],
                            [109.618605, 26.700408],
                            [109.626577, 26.700663],
                            [109.635642, 26.697024],
                            [109.640918, 26.701876],
                            [109.651355, 26.704908],
                            [109.660327, 26.709632],
                            [109.661512, 26.717611],
                            [109.651889, 26.725335],
                            [109.643034, 26.738067],
                            [109.643219, 26.741737],
                            [109.650425, 26.756637],
                            [109.652261, 26.762316],
                            [109.662116, 26.77364],
                            [109.669694, 26.77632],
                            [109.696772, 26.777341],
                            [109.71446, 26.771822],
                            [109.730242, 26.763847],
                            [109.742608, 26.759668],
                            [109.751556, 26.754244],
                            [109.757251, 26.755169],
                            [109.767548, 26.767547],
                            [109.76785, 26.770418],
                            [109.781587, 26.782859],
                            [109.786119, 26.775969],
                            [109.795161, 26.776671],
                            [109.796532, 26.784262],
                            [109.805341, 26.785092],
                            [109.814337, 26.778648],
                            [109.821193, 26.780977],
                            [109.820426, 26.773385],
                            [109.82382, 26.770131],
                            [109.826353, 26.755743],
                            [109.830863, 26.750671],
                            [109.8436, 26.742598],
                            [109.84446, 26.739726],
                            [109.83923, 26.736121],
                            [109.838347, 26.727951],
                            [109.844832, 26.725143],
                            [109.84346, 26.720069],
                            [109.851131, 26.719877],
                            [109.860126, 26.714547],
                            [109.868424, 26.714835],
                            [109.867936, 26.719654],
                            [109.885461, 26.726451],
                            [109.891992, 26.722941],
                            [109.899639, 26.723164],
                            [109.906101, 26.714069],
                            [109.912981, 26.710941],
                            [109.918978, 26.710973],
                            [109.926276, 26.718122],
                            [109.931111, 26.717931],
                            [109.932994, 26.709952],
                            [109.930344, 26.700695],
                            [109.932784, 26.696865],
                            [109.944197, 26.689299],
                            [109.946196, 26.686075],
                            [109.954098, 26.690321],
                            [109.963582, 26.691821],
                            [109.976668, 26.68898],
                            [109.977528, 26.685883],
                            [109.987592, 26.685341],
                            [109.997494, 26.677711],
                            [110.000515, 26.672698],
                            [110.007721, 26.67257],
                            [110.002026, 26.6666],
                            [110.005257, 26.664333],
                            [110.011207, 26.664939],
                            [110.020342, 26.662864],
                            [110.021899, 26.66826],
                            [110.025269, 26.669761],
                            [110.028733, 26.675508],
                            [110.036891, 26.681957],
                            [110.041842, 26.695524],
                            [110.044166, 26.69811],
                            [110.050512, 26.696418],
                            [110.056206, 26.697886],
                            [110.065178, 26.695396],
                            [110.072081, 26.700153],
                            [110.071547, 26.709856],
                            [110.067619, 26.712728],
                            [110.067293, 26.718409],
                            [110.062621, 26.717196],
                            [110.054324, 26.720356],
                            [110.047467, 26.724664],
                            [110.050302, 26.735642],
                            [110.055904, 26.738354],
                            [110.050163, 26.749075],
                            [110.059112, 26.747257],
                            [110.064109, 26.750958],
                            [110.061692, 26.760338],
                            [110.06827, 26.759636],
                            [110.068223, 26.765282],
                            [110.063481, 26.769908],
                            [110.068595, 26.784613],
                            [110.061297, 26.794596],
                            [110.060111, 26.802761],
                            [110.056532, 26.80882],
                            [110.057438, 26.822691],
                            [110.048722, 26.825018],
                            [110.043004, 26.832734],
                            [110.042307, 26.836592],
                            [110.045909, 26.842107],
                            [110.048699, 26.860724],
                            [110.055927, 26.876214],
                            [110.063574, 26.882078],
                            [110.068479, 26.880963],
                            [110.078218, 26.882014],
                            [110.093256, 26.89116],
                            [110.097045, 26.896036],
                            [110.097417, 26.906359],
                            [110.090118, 26.915472],
                            [110.084912, 26.919995],
                            [110.077427, 26.923563],
                            [110.072174, 26.932005],
                            [110.075893, 26.941721],
                            [110.089677, 26.94876],
                            [110.099694, 26.947963],
                            [110.104785, 26.951913],
                            [110.112246, 26.971052],
                            [110.116267, 26.977994],
                            [110.125541, 26.984808],
                            [110.138627, 26.987801],
                            [110.146274, 26.986878],
                            [110.148529, 26.989711],
                            [110.146878, 26.990603],
                            [110.133188, 26.989393],
                            [110.130399, 26.990698],
                            [110.133816, 26.996238],
                            [110.122473, 27.003878],
                            [110.117034, 27.009672],
                            [110.112106, 27.008049],
                            [110.094325, 27.005916],
                            [110.090839, 27.012728],
                            [110.100415, 27.022882],
                            [110.10095, 27.032526],
                            [110.097858, 27.037013],
                            [110.088979, 27.044078],
                            [110.08017, 27.047642],
                            [110.077055, 27.051906],
                            [110.072337, 27.064284],
                            [110.066666, 27.073733],
                            [110.056811, 27.082164],
                            [110.050465, 27.083818],
                            [110.042167, 27.092184],
                            [110.039936, 27.092057],
                            [110.036426, 27.084613],
                            [110.021946, 27.060625],
                            [110.018482, 27.058938],
                            [110.010789, 27.059702],
                            [110.000771, 27.069979],
                            [109.993124, 27.076469],
                            [109.985523, 27.079078],
                            [109.966324, 27.077074],
                            [109.953424, 27.077137],
                            [109.949659, 27.079141],
                            [109.949078, 27.092566],
                            [109.941315, 27.115911],
                            [109.934016, 27.12313],
                            [109.925788, 27.124148],
                            [109.906031, 27.124911],
                            [109.892271, 27.121127],
                            [109.879209, 27.114607],
                            [109.871957, 27.113876],
                            [109.864728, 27.116802],
                            [109.863264, 27.12189],
                            [109.871236, 27.145102],
                            [109.866169, 27.149458],
                            [109.842461, 27.138584],
                            [109.835, 27.133401],
                            [109.83056, 27.127169],
                            [109.826656, 27.116547],
                            [109.81873, 27.107451],
                            [109.808642, 27.10567],
                            [109.800251, 27.1002],
                            [109.788118, 27.094506],
                            [109.763434, 27.094411],
                            [109.751905, 27.09053],
                            [109.745699, 27.085249],
                            [109.738842, 27.0821],
                            [109.727686, 27.085313],
                            [109.719016, 27.092089],
                            [109.714669, 27.092947],
                            [109.709509, 27.087635],
                            [109.701769, 27.082863],
                            [109.68536, 27.079619],
                            [109.678503, 27.083182],
                            [109.673389, 27.076692],
                            [109.665138, 27.070965],
                            [109.654283, 27.057793],
                            [109.652238, 27.047674],
                            [109.649983, 27.045605],
                            [109.632063, 27.041023],
                            [109.621673, 27.044173],
                            [109.617257, 27.043887],
                            [109.606728, 27.03905],
                            [109.595594, 27.03765],
                            [109.591573, 27.0394],
                            [109.583717, 27.046624],
                            [109.57593, 27.045351],
                            [109.571956, 27.042964],
                            [109.5664, 27.044587],
                            [109.560194, 27.049201],
                            [109.560404, 27.053115],
                            [109.57077, 27.059607],
                            [109.58725, 27.078664],
                            [109.603613, 27.08649],
                            [109.597732, 27.094506],
                            [109.59062, 27.101472],
                            [109.576325, 27.101249],
                            [109.573327, 27.103221],
                            [109.563077, 27.103635],
                            [109.540856, 27.107483],
                            [109.537741, 27.113621],
                            [109.534301, 27.111077],
                            [109.534441, 27.10284],
                            [109.537532, 27.091166],
                            [109.535975, 27.077233],
                            [109.531071, 27.069629],
                            [109.52096, 27.071761],
                            [109.507897, 27.097242],
                            [109.496926, 27.100899],
                            [109.491185, 27.093266],
                            [109.487908, 27.079205],
                            [109.482097, 27.069661],
                            [109.469685, 27.069438]
                        ]
                    ],
                    [
                        [
                            [109.466942, 26.831937],
                            [109.462735, 26.828558],
                            [109.468662, 26.826549],
                            [109.480702, 26.832575],
                            [109.482097, 26.837038],
                            [109.475775, 26.83656],
                            [109.466942, 26.831937]
                        ]
                    ],
                    [
                        [
                            [109.522122, 26.749203],
                            [109.528514, 26.743938],
                            [109.528653, 26.744034],
                            [109.522215, 26.749362],
                            [109.522122, 26.749203]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 431226,
                "name": "麻阳苗族自治县",
                "center": [109.802807, 27.865991],
                "centroid": [109.722413, 27.784229],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431200
                },
                "subFeatureIndex": 6,
                "acroutes": [100000, 430000, 431200]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [110.069618, 27.968328],
                            [110.062482, 27.959271],
                            [110.056671, 27.955578],
                            [110.051534, 27.955294],
                            [110.047839, 27.950023],
                            [110.050767, 27.945668],
                            [110.053975, 27.933136],
                            [110.048327, 27.927517],
                            [110.048257, 27.923476],
                            [110.044306, 27.918678],
                            [110.022503, 27.908006],
                            [110.016042, 27.908574],
                            [110.00779, 27.915773],
                            [110.000376, 27.919783],
                            [109.999283, 27.923792],
                            [110.003002, 27.931526],
                            [110.001724, 27.936167],
                            [109.994821, 27.938913],
                            [109.988777, 27.945541],
                            [109.984129, 27.94592],
                            [109.974134, 27.938092],
                            [109.970927, 27.943711],
                            [109.97253, 27.951254],
                            [109.970253, 27.956872],
                            [109.962931, 27.961606],
                            [109.958399, 27.961164],
                            [109.957887, 27.951696],
                            [109.951495, 27.948319],
                            [109.9478, 27.956872],
                            [109.943778, 27.956462],
                            [109.933621, 27.959271],
                            [109.928136, 27.955578],
                            [109.920349, 27.956115],
                            [109.914376, 27.951254],
                            [109.916816, 27.946993],
                            [109.913446, 27.94532],
                            [109.909355, 27.937429],
                            [109.904404, 27.937587],
                            [109.897431, 27.940681],
                            [109.891644, 27.945731],
                            [109.882602, 27.946899],
                            [109.876815, 27.956304],
                            [109.863496, 27.96432],
                            [109.857151, 27.970884],
                            [109.847505, 27.973408],
                            [109.838882, 27.973187],
                            [109.837464, 27.975018],
                            [109.842833, 27.982906],
                            [109.83321, 27.986187],
                            [109.822913, 27.984673],
                            [109.816126, 27.980887],
                            [109.79788, 27.979341],
                            [109.79293, 27.973882],
                            [109.782633, 27.991109],
                            [109.775334, 27.993823],
                            [109.775915, 27.999123],
                            [109.784469, 28.006726],
                            [109.784725, 28.021425],
                            [109.791163, 28.027891],
                            [109.790117, 28.029751],
                            [109.781749, 28.026187],
                            [109.770221, 28.025588],
                            [109.75244, 28.021456],
                            [109.732172, 28.023633],
                            [109.730614, 28.016536],
                            [109.73603, 28.012435],
                            [109.730823, 28.00518],
                            [109.733101, 27.987418],
                            [109.728522, 27.983726],
                            [109.719783, 27.98177],
                            [109.714623, 27.973913],
                            [109.713391, 27.967507],
                            [109.719527, 27.958671],
                            [109.719109, 27.952674],
                            [109.711253, 27.949802],
                            [109.706325, 27.944342],
                            [109.700073, 27.941722],
                            [109.696098, 27.936987],
                            [109.691031, 27.938345],
                            [109.682013, 27.935946],
                            [109.680246, 27.930958],
                            [109.67074, 27.921677],
                            [109.661535, 27.91953],
                            [109.654934, 27.91129],
                            [109.655771, 27.905164],
                            [109.64989, 27.903964],
                            [109.630436, 27.909964],
                            [109.628135, 27.901028],
                            [109.624857, 27.899575],
                            [109.622742, 27.89326],
                            [109.621952, 27.880722],
                            [109.624462, 27.877532],
                            [109.63699, 27.876016],
                            [109.636735, 27.871563],
                            [109.643127, 27.861929],
                            [109.6405, 27.856054],
                            [109.642267, 27.850336],
                            [109.647659, 27.843322],
                            [109.647403, 27.834981],
                            [109.636804, 27.827335],
                            [109.623184, 27.826608],
                            [109.621185, 27.82092],
                            [109.626647, 27.817065],
                            [109.619186, 27.812451],
                            [109.613375, 27.815643],
                            [109.610772, 27.808691],
                            [109.603404, 27.798166],
                            [109.597779, 27.800094],
                            [109.596268, 27.804614],
                            [109.585948, 27.802781],
                            [109.585204, 27.805625],
                            [109.573792, 27.808912],
                            [109.567516, 27.805056],
                            [109.56575, 27.799936],
                            [109.559637, 27.799209],
                            [109.557893, 27.795005],
                            [109.549758, 27.786187],
                            [109.543483, 27.785017],
                            [109.53737, 27.793488],
                            [109.533023, 27.795859],
                            [109.533953, 27.804076],
                            [109.532791, 27.811535],
                            [109.525097, 27.809986],
                            [109.521239, 27.812514],
                            [109.515056, 27.807142],
                            [109.511965, 27.800126],
                            [109.507269, 27.802812],
                            [109.492626, 27.795764],
                            [109.485862, 27.787894],
                            [109.478494, 27.787261],
                            [109.476495, 27.790106],
                            [109.479168, 27.79943],
                            [109.475147, 27.804708],
                            [109.461341, 27.803413],
                            [109.454484, 27.794879],
                            [109.45381, 27.790675],
                            [109.457761, 27.787862],
                            [109.461759, 27.77762],
                            [109.461527, 27.773321],
                            [109.451137, 27.765069],
                            [109.446767, 27.758809],
                            [109.442676, 27.749197],
                            [109.433472, 27.744485],
                            [109.426429, 27.738951],
                            [109.42378, 27.728262],
                            [109.41841, 27.721367],
                            [109.418248, 27.714282],
                            [109.426871, 27.710897],
                            [109.429986, 27.703559],
                            [109.456855, 27.693562],
                            [109.470475, 27.680242],
                            [109.469383, 27.677869],
                            [109.458203, 27.674768],
                            [109.456157, 27.66942],
                            [109.464479, 27.651414],
                            [109.470057, 27.62875],
                            [109.46915, 27.620931],
                            [109.462968, 27.607539],
                            [109.46148, 27.601618],
                            [109.457947, 27.598674],
                            [109.451253, 27.585944],
                            [109.453763, 27.582682],
                            [109.461782, 27.566213],
                            [109.465664, 27.567131],
                            [109.470452, 27.564186],
                            [109.476821, 27.566118],
                            [109.479378, 27.561969],
                            [109.494439, 27.559308],
                            [109.498623, 27.552973],
                            [109.505085, 27.551167],
                            [109.523168, 27.53821],
                            [109.535952, 27.534408],
                            [109.552083, 27.532349],
                            [109.56124, 27.5352],
                            [109.565052, 27.538939],
                            [109.565401, 27.545813],
                            [109.555941, 27.557471],
                            [109.55701, 27.563141],
                            [109.565703, 27.571756],
                            [109.579789, 27.579895],
                            [109.587645, 27.581859],
                            [109.602428, 27.588161],
                            [109.612724, 27.596932],
                            [109.627089, 27.599592],
                            [109.639663, 27.59747],
                            [109.649263, 27.59804],
                            [109.668624, 27.601872],
                            [109.681292, 27.605988],
                            [109.68873, 27.609597],
                            [109.703489, 27.619348],
                            [109.717761, 27.624983],
                            [109.746513, 27.63027],
                            [109.758158, 27.635303],
                            [109.763504, 27.633942],
                            [109.775637, 27.62378],
                            [109.781657, 27.620773],
                            [109.790024, 27.622039],
                            [109.78935, 27.627231],
                            [109.796741, 27.628561],
                            [109.804272, 27.638785],
                            [109.80741, 27.649388],
                            [109.804574, 27.652236],
                            [109.805667, 27.656699],
                            [109.803157, 27.660053],
                            [109.804203, 27.670623],
                            [109.795905, 27.681697],
                            [109.790442, 27.685557],
                            [109.790977, 27.689386],
                            [109.795463, 27.69527],
                            [109.796648, 27.712226],
                            [109.801158, 27.715674],
                            [109.809967, 27.716401],
                            [109.817707, 27.720228],
                            [109.816521, 27.72434],
                            [109.82131, 27.736801],
                            [109.828794, 27.73503],
                            [109.831141, 27.731836],
                            [109.847946, 27.729938],
                            [109.853432, 27.732278],
                            [109.851596, 27.734935],
                            [109.856546, 27.737844],
                            [109.864193, 27.737085],
                            [109.866425, 27.742999],
                            [109.871329, 27.740153],
                            [109.874467, 27.743062],
                            [109.870864, 27.754667],
                            [109.877884, 27.763615],
                            [109.877372, 27.768231],
                            [109.871236, 27.774016],
                            [109.878744, 27.779833],
                            [109.883067, 27.787293],
                            [109.887716, 27.78606],
                            [109.896525, 27.790738],
                            [109.901452, 27.790549],
                            [109.906008, 27.794689],
                            [109.919257, 27.789063],
                            [109.917514, 27.797882],
                            [109.918583, 27.809923],
                            [109.916003, 27.815169],
                            [109.922767, 27.818898],
                            [109.923115, 27.825881],
                            [109.920372, 27.831221],
                            [109.925463, 27.836561],
                            [109.928415, 27.832422],
                            [109.928508, 27.822342],
                            [109.926532, 27.818961],
                            [109.928136, 27.81381],
                            [109.931855, 27.811661],
                            [109.938642, 27.814221],
                            [109.950147, 27.815201],
                            [109.95626, 27.819593],
                            [109.964674, 27.818582],
                            [109.969811, 27.81972],
                            [109.979945, 27.825534],
                            [109.989173, 27.825186],
                            [110.001608, 27.830558],
                            [110.012183, 27.833812],
                            [110.030499, 27.837067],
                            [110.035845, 27.83991],
                            [110.046769, 27.851726],
                            [110.054091, 27.86215],
                            [110.055346, 27.866857],
                            [110.052162, 27.877817],
                            [110.056997, 27.881607],
                            [110.066085, 27.882586],
                            [110.077683, 27.876395],
                            [110.088538, 27.87529],
                            [110.090258, 27.880185],
                            [110.088747, 27.886912],
                            [110.095883, 27.892218],
                            [110.096557, 27.895975],
                            [110.090676, 27.907122],
                            [110.083633, 27.911479],
                            [110.080193, 27.920351],
                            [110.0801, 27.931589],
                            [110.084981, 27.937713],
                            [110.08633, 27.944784],
                            [110.077009, 27.952327],
                            [110.072988, 27.957945],
                            [110.073313, 27.965077],
                            [110.069618, 27.968328]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 431227,
                "name": "新晃侗族自治县",
                "center": [109.174443, 27.359897],
                "centroid": [109.161744, 27.22862],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431200
                },
                "subFeatureIndex": 7,
                "acroutes": [100000, 430000, 431200]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [109.410275, 27.153527],
                            [109.407347, 27.153655],
                            [109.406091, 27.164972],
                            [109.409717, 27.169549],
                            [109.423431, 27.171584],
                            [109.434262, 27.177401],
                            [109.43819, 27.18363],
                            [109.437493, 27.195167],
                            [109.427731, 27.221509],
                            [109.424407, 27.232185],
                            [109.430079, 27.242986],
                            [109.44163, 27.247592],
                            [109.446163, 27.25566],
                            [109.443443, 27.262901],
                            [109.431264, 27.271889],
                            [109.417922, 27.288244],
                            [109.417806, 27.296055],
                            [109.438028, 27.309262],
                            [109.439632, 27.315643],
                            [109.431752, 27.322627],
                            [109.42206, 27.324595],
                            [109.41246, 27.323389],
                            [109.409531, 27.327166],
                            [109.413576, 27.335736],
                            [109.410949, 27.339958],
                            [109.397445, 27.337958],
                            [109.387706, 27.339482],
                            [109.380872, 27.342021],
                            [109.353306, 27.359348],
                            [109.348076, 27.363442],
                            [109.3428, 27.376959],
                            [109.343009, 27.39187],
                            [109.341754, 27.397897],
                            [109.33771, 27.404908],
                            [109.324391, 27.412742],
                            [109.308167, 27.41953],
                            [109.294221, 27.423399],
                            [109.286458, 27.424636],
                            [109.275627, 27.42397],
                            [109.260426, 27.421623],
                            [109.252802, 27.417151],
                            [109.24555, 27.418166],
                            [109.243667, 27.421369],
                            [109.243249, 27.429615],
                            [109.239321, 27.43323],
                            [109.231558, 27.434023],
                            [109.221935, 27.438906],
                            [109.213683, 27.45048],
                            [109.202108, 27.450068],
                            [109.18951, 27.43656],
                            [109.166988, 27.417849],
                            [109.156226, 27.416517],
                            [109.155087, 27.421306],
                            [109.159248, 27.431486],
                            [109.159434, 27.442331],
                            [109.155296, 27.443568],
                            [109.154064, 27.448768],
                            [109.145023, 27.448895],
                            [109.139049, 27.445661],
                            [109.136539, 27.440809],
                            [109.130054, 27.434911],
                            [109.129124, 27.431327],
                            [109.123314, 27.4286],
                            [109.12329, 27.41842],
                            [109.118711, 27.419561],
                            [109.115341, 27.424192],
                            [109.111506, 27.423336],
                            [109.110111, 27.415121],
                            [109.118642, 27.41233],
                            [109.12501, 27.415565],
                            [109.129241, 27.425556],
                            [109.134796, 27.429837],
                            [109.138654, 27.430344],
                            [109.142791, 27.423526],
                            [109.141931, 27.41769],
                            [109.131472, 27.411252],
                            [109.12766, 27.405066],
                            [109.117131, 27.401133],
                            [109.116224, 27.39942],
                            [109.124731, 27.387905],
                            [109.11734, 27.377752],
                            [109.113854, 27.368265],
                            [109.118456, 27.362236],
                            [109.112784, 27.351827],
                            [109.106392, 27.355604],
                            [109.101093, 27.349828],
                            [109.103673, 27.336847],
                            [109.096839, 27.336181],
                            [109.086357, 27.331039],
                            [109.080406, 27.33488],
                            [109.072666, 27.329547],
                            [109.072178, 27.323548],
                            [109.06925, 27.322818],
                            [109.059534, 27.326881],
                            [109.05363, 27.335959],
                            [109.045913, 27.334689],
                            [109.044403, 27.331071],
                            [109.04768, 27.318786],
                            [109.054118, 27.306056],
                            [109.053142, 27.292816],
                            [109.045611, 27.288212],
                            [109.041404, 27.276621],
                            [109.023902, 27.282242],
                            [109.022856, 27.278622],
                            [109.013419, 27.274208],
                            [109.01056, 27.270524],
                            [108.999566, 27.267538],
                            [108.997823, 27.262743],
                            [108.994383, 27.269412],
                            [108.987178, 27.270555],
                            [108.983319, 27.267062],
                            [108.984644, 27.256231],
                            [108.982413, 27.253595],
                            [108.972162, 27.24864],
                            [108.96856, 27.245527],
                            [108.968676, 27.24054],
                            [108.963446, 27.23552],
                            [108.951894, 27.236314],
                            [108.947106, 27.228404],
                            [108.939878, 27.225131],
                            [108.927768, 27.221795],
                            [108.91424, 27.214646],
                            [108.91503, 27.210357],
                            [108.907639, 27.20756],
                            [108.908662, 27.204351],
                            [108.916402, 27.202413],
                            [108.918424, 27.19987],
                            [108.925769, 27.199616],
                            [108.919168, 27.193483],
                            [108.924839, 27.183408],
                            [108.929767, 27.18007],
                            [108.926001, 27.175176],
                            [108.926768, 27.160903],
                            [108.919656, 27.155371],
                            [108.913706, 27.157692],
                            [108.913496, 27.149299],
                            [108.909987, 27.14755],
                            [108.90492, 27.134928],
                            [108.898086, 27.128632],
                            [108.890346, 27.124625],
                            [108.887139, 27.12119],
                            [108.887464, 27.112508],
                            [108.885233, 27.109042],
                            [108.878771, 27.106306],
                            [108.873541, 27.113939],
                            [108.86966, 27.116325],
                            [108.860595, 27.115434],
                            [108.855063, 27.106084],
                            [108.856527, 27.098069],
                            [108.854342, 27.096001],
                            [108.845882, 27.098482],
                            [108.840977, 27.096828],
                            [108.837793, 27.092884],
                            [108.824405, 27.089067],
                            [108.818362, 27.09123],
                            [108.816502, 27.096446],
                            [108.807484, 27.098577],
                            [108.798326, 27.089162],
                            [108.790842, 27.084422],
                            [108.804183, 27.078632],
                            [108.805717, 27.073447],
                            [108.808762, 27.07421],
                            [108.824451, 27.063234],
                            [108.830076, 27.063647],
                            [108.828496, 27.060529],
                            [108.831448, 27.057825],
                            [108.83863, 27.058938],
                            [108.840954, 27.063043],
                            [108.845626, 27.058047],
                            [108.847114, 27.050092],
                            [108.843976, 27.04796],
                            [108.854714, 27.031985],
                            [108.859549, 27.032557],
                            [108.866568, 27.029757],
                            [108.865429, 27.024314],
                            [108.86952, 27.023137],
                            [108.868544, 27.03103],
                            [108.872286, 27.023837],
                            [108.87561, 27.027561],
                            [108.874285, 27.021609],
                            [108.877353, 27.016261],
                            [108.878446, 27.006361],
                            [108.874401, 27.004802],
                            [108.872867, 26.999867],
                            [108.885, 27.002923],
                            [108.891764, 27.007889],
                            [108.900643, 27.016962],
                            [108.906709, 27.017312],
                            [108.912311, 27.019954],
                            [108.914659, 27.017248],
                            [108.91847, 27.019062],
                            [108.914937, 27.025937],
                            [108.921004, 27.029789],
                            [108.923235, 27.02024],
                            [108.919586, 27.012091],
                            [108.921376, 27.009736],
                            [108.931975, 27.012569],
                            [108.936786, 27.011805],
                            [108.938553, 27.015752],
                            [108.941481, 27.01432],
                            [108.952359, 27.019317],
                            [108.95301, 27.023996],
                            [108.949802, 27.029948],
                            [108.950965, 27.036949],
                            [108.944085, 27.036758],
                            [108.940412, 27.044714],
                            [108.943922, 27.04866],
                            [108.946223, 27.047388],
                            [108.96426, 27.054484],
                            [108.969559, 27.059861],
                            [108.989618, 27.074083],
                            [109.007562, 27.080223],
                            [109.016116, 27.086744],
                            [109.018835, 27.095842],
                            [109.02518, 27.101885],
                            [109.032549, 27.103794],
                            [109.035849, 27.0981],
                            [109.044751, 27.096319],
                            [109.053723, 27.098323],
                            [109.061951, 27.110409],
                            [109.064764, 27.103476],
                            [109.080383, 27.116515],
                            [109.086496, 27.117883],
                            [109.097211, 27.116102],
                            [109.101674, 27.120077],
                            [109.108275, 27.119696],
                            [109.11139, 27.123162],
                            [109.122942, 27.123385],
                            [109.131309, 27.121254],
                            [109.133959, 27.117756],
                            [109.131077, 27.110091],
                            [109.118967, 27.105225],
                            [109.109182, 27.092534],
                            [109.106974, 27.082323],
                            [109.101697, 27.074878],
                            [109.101139, 27.068929],
                            [109.110762, 27.069438],
                            [109.125034, 27.06562],
                            [109.132076, 27.065525],
                            [109.131635, 27.070424],
                            [109.141071, 27.073574],
                            [109.149253, 27.071252],
                            [109.154274, 27.071951],
                            [109.156296, 27.066575],
                            [109.163524, 27.065811],
                            [109.167267, 27.070488],
                            [109.168475, 27.077424],
                            [109.175239, 27.087222],
                            [109.17933, 27.083277],
                            [109.185489, 27.082291],
                            [109.195926, 27.091262],
                            [109.218262, 27.11642],
                            [109.226769, 27.128282],
                            [109.234928, 27.144021],
                            [109.238926, 27.14914],
                            [109.248107, 27.153432],
                            [109.257427, 27.150602],
                            [109.260495, 27.138711],
                            [109.264238, 27.131875],
                            [109.272582, 27.127455],
                            [109.280554, 27.127105],
                            [109.291293, 27.130985],
                            [109.298963, 27.135246],
                            [109.318232, 27.13744],
                            [109.324949, 27.136899],
                            [109.33506, 27.139093],
                            [109.333689, 27.148727],
                            [109.358745, 27.15286],
                            [109.369971, 27.147614],
                            [109.373992, 27.147805],
                            [109.378153, 27.155435],
                            [109.383964, 27.149235],
                            [109.384568, 27.143926],
                            [109.393168, 27.143862],
                            [109.392634, 27.155085],
                            [109.394284, 27.157915],
                            [109.400862, 27.159599],
                            [109.400234, 27.152415],
                            [109.410275, 27.153527]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 431228,
                "name": "芷江侗族自治县",
                "center": [109.687777, 27.437996],
                "centroid": [109.603294, 27.395167],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431200
                },
                "subFeatureIndex": 8,
                "acroutes": [100000, 430000, 431200]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [109.469685, 27.069438],
                            [109.482097, 27.069661],
                            [109.487908, 27.079205],
                            [109.491185, 27.093266],
                            [109.496926, 27.100899],
                            [109.507897, 27.097242],
                            [109.52096, 27.071761],
                            [109.531071, 27.069629],
                            [109.535975, 27.077233],
                            [109.537532, 27.091166],
                            [109.534441, 27.10284],
                            [109.534301, 27.111077],
                            [109.537741, 27.113621],
                            [109.540856, 27.107483],
                            [109.543343, 27.11378],
                            [109.547016, 27.115243],
                            [109.546202, 27.12472],
                            [109.537765, 27.128409],
                            [109.550944, 27.131907],
                            [109.562798, 27.12825],
                            [109.568167, 27.132924],
                            [109.572885, 27.132257],
                            [109.579626, 27.13798],
                            [109.589365, 27.130253],
                            [109.597988, 27.132447],
                            [109.600708, 27.139284],
                            [109.596849, 27.144943],
                            [109.605449, 27.142463],
                            [109.609284, 27.146883],
                            [109.617675, 27.13887],
                            [109.617582, 27.147709],
                            [109.623277, 27.147423],
                            [109.62495, 27.151334],
                            [109.629111, 27.151365],
                            [109.63699, 27.157247],
                            [109.643731, 27.154545],
                            [109.645707, 27.15588],
                            [109.646125, 27.166816],
                            [109.641709, 27.173109],
                            [109.642267, 27.177083],
                            [109.653772, 27.183694],
                            [109.644451, 27.200125],
                            [109.645218, 27.202381],
                            [109.652796, 27.204669],
                            [109.653632, 27.192116],
                            [109.657049, 27.190177],
                            [109.656073, 27.19469],
                            [109.662767, 27.204859],
                            [109.662, 27.213248],
                            [109.669508, 27.218968],
                            [109.670554, 27.21274],
                            [109.678177, 27.208959],
                            [109.683593, 27.208768],
                            [109.697237, 27.212835],
                            [109.701839, 27.217029],
                            [109.708022, 27.226657],
                            [109.710276, 27.235298],
                            [109.706418, 27.250133],
                            [109.703861, 27.255533],
                            [109.697376, 27.259916],
                            [109.698585, 27.263314],
                            [109.713182, 27.266173],
                            [109.71611, 27.267888],
                            [109.725385, 27.282242],
                            [109.735914, 27.285703],
                            [109.743375, 27.28564],
                            [109.753834, 27.28167],
                            [109.754811, 27.279765],
                            [109.762969, 27.278304],
                            [109.7738, 27.283639],
                            [109.780611, 27.288434],
                            [109.784911, 27.283957],
                            [109.789908, 27.28383],
                            [109.790489, 27.290784],
                            [109.789234, 27.295801],
                            [109.792, 27.301198],
                            [109.783028, 27.298912],
                            [109.773893, 27.300468],
                            [109.771267, 27.306119],
                            [109.761435, 27.305611],
                            [109.759692, 27.307389],
                            [109.761946, 27.314627],
                            [109.771453, 27.322214],
                            [109.773335, 27.3338],
                            [109.775637, 27.339069],
                            [109.786561, 27.34748],
                            [109.796509, 27.359095],
                            [109.808688, 27.36652],
                            [109.818195, 27.370391],
                            [109.820054, 27.374516],
                            [109.818056, 27.379973],
                            [109.830189, 27.383431],
                            [109.841973, 27.38543],
                            [109.845622, 27.384891],
                            [109.846714, 27.379941],
                            [109.854385, 27.378894],
                            [109.857825, 27.371914],
                            [109.862799, 27.368455],
                            [109.870841, 27.371089],
                            [109.87291, 27.382321],
                            [109.880162, 27.386413],
                            [109.882742, 27.39482],
                            [109.87205, 27.415629],
                            [109.878581, 27.442712],
                            [109.873979, 27.454538],
                            [109.869284, 27.460943],
                            [109.866913, 27.467569],
                            [109.851456, 27.46741],
                            [109.855384, 27.48158],
                            [109.858197, 27.484021],
                            [109.856918, 27.49784],
                            [109.865193, 27.495938],
                            [109.862915, 27.500217],
                            [109.856244, 27.502943],
                            [109.855919, 27.507823],
                            [109.861776, 27.512545],
                            [109.870934, 27.512165],
                            [109.878511, 27.522907],
                            [109.886158, 27.524681],
                            [109.88925, 27.521354],
                            [109.886182, 27.510168],
                            [109.890528, 27.507855],
                            [109.894363, 27.510929],
                            [109.895967, 27.525156],
                            [109.903265, 27.53238],
                            [109.909007, 27.532285],
                            [109.912447, 27.537735],
                            [109.913399, 27.545718],
                            [109.912144, 27.556109],
                            [109.909262, 27.560131],
                            [109.887716, 27.572516],
                            [109.883276, 27.57638],
                            [109.880789, 27.582935],
                            [109.887948, 27.595697],
                            [109.886275, 27.603138],
                            [109.86933, 27.623812],
                            [109.862195, 27.630808],
                            [109.855733, 27.635208],
                            [109.848156, 27.633245],
                            [109.845506, 27.622577],
                            [109.835279, 27.60488],
                            [109.830444, 27.60165],
                            [109.8211, 27.600447],
                            [109.807294, 27.602885],
                            [109.793999, 27.614695],
                            [109.790024, 27.622039],
                            [109.781657, 27.620773],
                            [109.775637, 27.62378],
                            [109.763504, 27.633942],
                            [109.758158, 27.635303],
                            [109.746513, 27.63027],
                            [109.717761, 27.624983],
                            [109.703489, 27.619348],
                            [109.68873, 27.609597],
                            [109.681292, 27.605988],
                            [109.668624, 27.601872],
                            [109.649263, 27.59804],
                            [109.639663, 27.59747],
                            [109.627089, 27.599592],
                            [109.612724, 27.596932],
                            [109.602428, 27.588161],
                            [109.587645, 27.581859],
                            [109.579789, 27.579895],
                            [109.565703, 27.571756],
                            [109.55701, 27.563141],
                            [109.555941, 27.557471],
                            [109.565401, 27.545813],
                            [109.565052, 27.538939],
                            [109.56124, 27.5352],
                            [109.552083, 27.532349],
                            [109.535952, 27.534408],
                            [109.523168, 27.53821],
                            [109.505085, 27.551167],
                            [109.498623, 27.552973],
                            [109.494439, 27.559308],
                            [109.479378, 27.561969],
                            [109.476821, 27.566118],
                            [109.470452, 27.564186],
                            [109.465664, 27.567131],
                            [109.461782, 27.566213],
                            [109.459133, 27.560131],
                            [109.445977, 27.56238],
                            [109.433635, 27.548379],
                            [109.428242, 27.547144],
                            [109.418108, 27.551135],
                            [109.404976, 27.55047],
                            [109.379152, 27.535802],
                            [109.36946, 27.528737],
                            [109.35828, 27.518692],
                            [109.337617, 27.504305],
                            [109.325902, 27.494227],
                            [109.312351, 27.486176],
                            [109.303007, 27.475652],
                            [109.302426, 27.46554],
                            [109.306076, 27.451368],
                            [109.306936, 27.434403],
                            [109.303217, 27.426317],
                            [109.294221, 27.423399],
                            [109.308167, 27.41953],
                            [109.324391, 27.412742],
                            [109.33771, 27.404908],
                            [109.341754, 27.397897],
                            [109.343009, 27.39187],
                            [109.3428, 27.376959],
                            [109.348076, 27.363442],
                            [109.353306, 27.359348],
                            [109.380872, 27.342021],
                            [109.387706, 27.339482],
                            [109.397445, 27.337958],
                            [109.410949, 27.339958],
                            [109.413576, 27.335736],
                            [109.409531, 27.327166],
                            [109.41246, 27.323389],
                            [109.42206, 27.324595],
                            [109.431752, 27.322627],
                            [109.439632, 27.315643],
                            [109.438028, 27.309262],
                            [109.417806, 27.296055],
                            [109.417922, 27.288244],
                            [109.431264, 27.271889],
                            [109.443443, 27.262901],
                            [109.446163, 27.25566],
                            [109.44163, 27.247592],
                            [109.430079, 27.242986],
                            [109.424407, 27.232185],
                            [109.427731, 27.221509],
                            [109.437493, 27.195167],
                            [109.43819, 27.18363],
                            [109.434262, 27.177401],
                            [109.423431, 27.171584],
                            [109.409717, 27.169549],
                            [109.406091, 27.164972],
                            [109.407347, 27.153655],
                            [109.410275, 27.153527],
                            [109.415273, 27.153877],
                            [109.423919, 27.134228],
                            [109.425035, 27.129427],
                            [109.433658, 27.121954],
                            [109.44091, 27.118169],
                            [109.448324, 27.120586],
                            [109.457575, 27.133338],
                            [109.467012, 27.136454],
                            [109.472707, 27.134896],
                            [109.473102, 27.126883],
                            [109.46936, 27.115434],
                            [109.459597, 27.10831],
                            [109.463107, 27.100518],
                            [109.459388, 27.099723],
                            [109.459714, 27.093647],
                            [109.467407, 27.094856],
                            [109.467547, 27.090085],
                            [109.462154, 27.089926],
                            [109.459621, 27.085663],
                            [109.462085, 27.081305],
                            [109.469894, 27.080509],
                            [109.475542, 27.082005],
                            [109.482422, 27.081114],
                            [109.481934, 27.072683],
                            [109.479796, 27.070138],
                            [109.469685, 27.069438]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 431229,
                "name": "靖州苗族侗族自治县",
                "center": [109.691159, 26.573511],
                "centroid": [109.584585, 26.542872],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431200
                },
                "subFeatureIndex": 9,
                "acroutes": [100000, 430000, 431200]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [109.946196, 26.686075],
                            [109.944197, 26.689299],
                            [109.932784, 26.696865],
                            [109.930344, 26.700695],
                            [109.932994, 26.709952],
                            [109.931111, 26.717931],
                            [109.926276, 26.718122],
                            [109.918978, 26.710973],
                            [109.912981, 26.710941],
                            [109.906101, 26.714069],
                            [109.899639, 26.723164],
                            [109.891992, 26.722941],
                            [109.885461, 26.726451],
                            [109.867936, 26.719654],
                            [109.868424, 26.714835],
                            [109.860126, 26.714547],
                            [109.851131, 26.719877],
                            [109.84346, 26.720069],
                            [109.844832, 26.725143],
                            [109.838347, 26.727951],
                            [109.83923, 26.736121],
                            [109.84446, 26.739726],
                            [109.8436, 26.742598],
                            [109.830863, 26.750671],
                            [109.826353, 26.755743],
                            [109.82382, 26.770131],
                            [109.820426, 26.773385],
                            [109.821193, 26.780977],
                            [109.814337, 26.778648],
                            [109.805341, 26.785092],
                            [109.796532, 26.784262],
                            [109.795161, 26.776671],
                            [109.786119, 26.775969],
                            [109.781587, 26.782859],
                            [109.76785, 26.770418],
                            [109.767548, 26.767547],
                            [109.757251, 26.755169],
                            [109.751556, 26.754244],
                            [109.742608, 26.759668],
                            [109.730242, 26.763847],
                            [109.71446, 26.771822],
                            [109.696772, 26.777341],
                            [109.669694, 26.77632],
                            [109.662116, 26.77364],
                            [109.652261, 26.762316],
                            [109.650425, 26.756637],
                            [109.643219, 26.741737],
                            [109.643034, 26.738067],
                            [109.651889, 26.725335],
                            [109.661512, 26.717611],
                            [109.660327, 26.709632],
                            [109.651355, 26.704908],
                            [109.640918, 26.701876],
                            [109.635642, 26.697024],
                            [109.626577, 26.700663],
                            [109.618605, 26.700408],
                            [109.611655, 26.694407],
                            [109.614468, 26.687448],
                            [109.6104, 26.686011],
                            [109.607285, 26.689395],
                            [109.598802, 26.688852],
                            [109.59413, 26.690225],
                            [109.590969, 26.686426],
                            [109.580556, 26.690512],
                            [109.5707, 26.698269],
                            [109.555081, 26.708324],
                            [109.534743, 26.714196],
                            [109.523912, 26.713526],
                            [109.514475, 26.716271],
                            [109.512918, 26.721345],
                            [109.526236, 26.726803],
                            [109.528188, 26.729196],
                            [109.529141, 26.740811],
                            [109.528514, 26.743938],
                            [109.522122, 26.749203],
                            [109.512685, 26.746714],
                            [109.507967, 26.7433],
                            [109.50462, 26.744608],
                            [109.502342, 26.75769],
                            [109.497716, 26.760146],
                            [109.471638, 26.760625],
                            [109.467059, 26.761677],
                            [109.453949, 26.761199],
                            [109.437679, 26.755233],
                            [109.429126, 26.747288],
                            [109.417736, 26.739758],
                            [109.414529, 26.733759],
                            [109.413971, 26.722239],
                            [109.407091, 26.719845],
                            [109.390565, 26.725303],
                            [109.387706, 26.727568],
                            [109.381291, 26.727441],
                            [109.380594, 26.723547],
                            [109.38594, 26.712154],
                            [109.383452, 26.708675],
                            [109.372993, 26.705036],
                            [109.364951, 26.696162],
                            [109.355003, 26.693226],
                            [109.335595, 26.699993],
                            [109.319115, 26.701749],
                            [109.308377, 26.699706],
                            [109.298429, 26.696546],
                            [109.292943, 26.70127],
                            [109.283739, 26.698588],
                            [109.287016, 26.689682],
                            [109.304216, 26.663119],
                            [109.311003, 26.657755],
                            [109.327087, 26.648846],
                            [109.334084, 26.646227],
                            [109.342242, 26.648686],
                            [109.35133, 26.65734],
                            [109.355142, 26.659],
                            [109.358303, 26.655168],
                            [109.360116, 26.640734],
                            [109.36437, 26.633452],
                            [109.37369, 26.629013],
                            [109.378688, 26.622465],
                            [109.379408, 26.615725],
                            [109.384731, 26.604736],
                            [109.391285, 26.600519],
                            [109.389728, 26.591637],
                            [109.387171, 26.588059],
                            [109.390216, 26.582243],
                            [109.385498, 26.570995],
                            [109.395237, 26.572465],
                            [109.399025, 26.570963],
                            [109.39519, 26.566458],
                            [109.403976, 26.560194],
                            [109.407811, 26.546706],
                            [109.408741, 26.537085],
                            [109.407044, 26.533153],
                            [109.401001, 26.527303],
                            [109.390077, 26.522764],
                            [109.383685, 26.521517],
                            [109.381709, 26.51848],
                            [109.382895, 26.509559],
                            [109.386962, 26.497665],
                            [109.385521, 26.493508],
                            [109.364068, 26.475919],
                            [109.362464, 26.46626],
                            [109.367647, 26.462677],
                            [109.374829, 26.463253],
                            [109.380989, 26.46175],
                            [109.380872, 26.454648],
                            [109.373551, 26.449466],
                            [109.360999, 26.448315],
                            [109.34882, 26.442588],
                            [109.326809, 26.427935],
                            [109.319557, 26.418624],
                            [109.31356, 26.397407],
                            [109.306726, 26.380956],
                            [109.299033, 26.368183],
                            [109.295802, 26.350447],
                            [109.292571, 26.345356],
                            [109.277324, 26.340041],
                            [109.27235, 26.333733],
                            [109.271443, 26.327745],
                            [109.274488, 26.315415],
                            [109.27823, 26.307664],
                            [109.285877, 26.295813],
                            [109.289689, 26.29482],
                            [109.296871, 26.29735],
                            [109.305309, 26.291232],
                            [109.310445, 26.290143],
                            [109.324903, 26.290335],
                            [109.325902, 26.286939],
                            [109.322997, 26.283063],
                            [109.332201, 26.27829],
                            [109.336733, 26.274125],
                            [109.339802, 26.264064],
                            [109.354352, 26.265282],
                            [109.357908, 26.269671],
                            [109.373249, 26.278194],
                            [109.391169, 26.277104],
                            [109.401513, 26.283704],
                            [109.421037, 26.288733],
                            [109.4298, 26.286715],
                            [109.436099, 26.289502],
                            [109.44263, 26.289887],
                            [109.439678, 26.292834],
                            [109.447325, 26.297382],
                            [109.443699, 26.299528],
                            [109.444466, 26.303084],
                            [109.460155, 26.313814],
                            [109.466129, 26.314134],
                            [109.476891, 26.29924],
                            [109.471684, 26.297478],
                            [109.462387, 26.286171],
                            [109.45883, 26.284152],
                            [109.455088, 26.27028],
                            [109.456367, 26.267044],
                            [109.468058, 26.271049],
                            [109.473427, 26.271049],
                            [109.485398, 26.265955],
                            [109.492138, 26.268197],
                            [109.4986, 26.275022],
                            [109.507316, 26.276271],
                            [109.514312, 26.273676],
                            [109.519031, 26.275983],
                            [109.529397, 26.289022],
                            [109.537253, 26.296902],
                            [109.538392, 26.308881],
                            [109.540903, 26.315927],
                            [109.55629, 26.327456],
                            [109.566052, 26.33876],
                            [109.579161, 26.345324],
                            [109.586157, 26.352368],
                            [109.595408, 26.363702],
                            [109.598685, 26.372313],
                            [109.599057, 26.379867],
                            [109.591178, 26.390974],
                            [109.591387, 26.400159],
                            [109.596129, 26.40272],
                            [109.611283, 26.399135],
                            [109.614979, 26.401023],
                            [109.619163, 26.408],
                            [109.62667, 26.409536],
                            [109.631086, 26.402912],
                            [109.636618, 26.401983],
                            [109.645149, 26.397151],
                            [109.652052, 26.395935],
                            [109.661094, 26.396415],
                            [109.664743, 26.39299],
                            [109.663836, 26.389278],
                            [109.667997, 26.382428],
                            [109.661489, 26.377851],
                            [109.669833, 26.372025],
                            [109.674668, 26.373305],
                            [109.680804, 26.379643],
                            [109.683175, 26.387037],
                            [109.677271, 26.388093],
                            [109.676527, 26.391102],
                            [109.681455, 26.395071],
                            [109.678503, 26.399263],
                            [109.673041, 26.402368],
                            [109.678363, 26.411296],
                            [109.685081, 26.411936],
                            [109.693262, 26.410624],
                            [109.698841, 26.417056],
                            [109.716947, 26.430335],
                            [109.725803, 26.442844],
                            [109.730614, 26.45644],
                            [109.738959, 26.464149],
                            [109.749302, 26.466228],
                            [109.758553, 26.457144],
                            [109.76434, 26.445403],
                            [109.765107, 26.440317],
                            [109.763039, 26.425247],
                            [109.767827, 26.419072],
                            [109.776868, 26.420992],
                            [109.781657, 26.427103],
                            [109.788815, 26.429919],
                            [109.792511, 26.442172],
                            [109.802064, 26.447579],
                            [109.803714, 26.443228],
                            [109.81529, 26.44454],
                            [109.819659, 26.45145],
                            [109.836278, 26.449786],
                            [109.839858, 26.463029],
                            [109.850364, 26.463061],
                            [109.856291, 26.465204],
                            [109.857662, 26.475759],
                            [109.862357, 26.486856],
                            [109.86689, 26.49079],
                            [109.875792, 26.494115],
                            [109.879371, 26.497057],
                            [109.878349, 26.503804],
                            [109.883183, 26.509943],
                            [109.883439, 26.513524],
                            [109.879697, 26.517393],
                            [109.883788, 26.522796],
                            [109.89255, 26.525481],
                            [109.890296, 26.531715],
                            [109.884926, 26.530468],
                            [109.872305, 26.538907],
                            [109.869098, 26.537117],
                            [109.862683, 26.539866],
                            [109.861962, 26.550957],
                            [109.86517, 26.562686],
                            [109.872026, 26.568375],
                            [109.87205, 26.571027],
                            [109.861683, 26.583649],
                            [109.856849, 26.580933],
                            [109.849109, 26.586333],
                            [109.840299, 26.594513],
                            [109.832559, 26.599241],
                            [109.826841, 26.605854],
                            [109.827446, 26.616428],
                            [109.8307, 26.621507],
                            [109.840392, 26.622082],
                            [109.843995, 26.61959],
                            [109.851456, 26.621826],
                            [109.855012, 26.635337],
                            [109.85822, 26.643417],
                            [109.876512, 26.651177],
                            [109.885415, 26.653827],
                            [109.898965, 26.655584],
                            [109.901081, 26.668005],
                            [109.914027, 26.67621],
                            [109.928647, 26.675731],
                            [109.934063, 26.67372],
                            [109.941965, 26.677743],
                            [109.946196, 26.686075]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 431230,
                "name": "通道侗族自治县",
                "center": [109.783359, 26.158349],
                "centroid": [109.736712, 26.208635],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431200
                },
                "subFeatureIndex": 10,
                "acroutes": [100000, 430000, 431200]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [109.456367, 26.267044],
                            [109.453415, 26.264513],
                            [109.446023, 26.250414],
                            [109.439794, 26.243684],
                            [109.439608, 26.238845],
                            [109.44765, 26.217627],
                            [109.452392, 26.209646],
                            [109.45804, 26.206504],
                            [109.46829, 26.203202],
                            [109.470452, 26.196342],
                            [109.461248, 26.196021],
                            [109.459319, 26.194097],
                            [109.464316, 26.182619],
                            [109.469987, 26.17704],
                            [109.471754, 26.169953],
                            [109.4675, 26.162353],
                            [109.475519, 26.152924],
                            [109.47617, 26.148081],
                            [109.482469, 26.1459],
                            [109.486676, 26.148723],
                            [109.493184, 26.144008],
                            [109.495183, 26.152636],
                            [109.49939, 26.145868],
                            [109.501133, 26.139069],
                            [109.504992, 26.134161],
                            [109.514103, 26.128291],
                            [109.512732, 26.115491],
                            [109.506967, 26.109074],
                            [109.504085, 26.096849],
                            [109.499622, 26.098614],
                            [109.475984, 26.099481],
                            [109.468895, 26.095181],
                            [109.46499, 26.096657],
                            [109.466524, 26.091587],
                            [109.461527, 26.092229],
                            [109.449673, 26.101823],
                            [109.45109, 26.076665],
                            [109.447557, 26.071787],
                            [109.451788, 26.065336],
                            [109.45281, 26.056252],
                            [109.454809, 26.053074],
                            [109.463247, 26.049319],
                            [109.479261, 26.047489],
                            [109.481423, 26.04017],
                            [109.482632, 26.02964],
                            [109.496415, 26.023764],
                            [109.501203, 26.01426],
                            [109.508897, 26.00954],
                            [109.51057, 26.001062],
                            [109.513173, 25.998108],
                            [109.5287, 25.995763],
                            [109.540298, 26.000067],
                            [109.545412, 26.013907],
                            [109.551153, 26.019012],
                            [109.559753, 26.02142],
                            [109.575814, 26.01959],
                            [109.588272, 26.019751],
                            [109.598174, 26.02264],
                            [109.604473, 26.026429],
                            [109.612701, 26.037088],
                            [109.626066, 26.045338],
                            [109.634968, 26.047328],
                            [109.646032, 26.041968],
                            [109.648263, 26.031213],
                            [109.647589, 26.025466],
                            [109.649263, 26.016797],
                            [109.656724, 26.013457],
                            [109.666928, 26.013168],
                            [109.675458, 26.015127],
                            [109.682152, 26.00954],
                            [109.690124, 26.007581],
                            [109.689171, 25.9983],
                            [109.69805, 25.998911],
                            [109.702304, 26.003053],
                            [109.701769, 26.008191],
                            [109.708231, 26.013554],
                            [109.711857, 26.011788],
                            [109.715506, 26.005847],
                            [109.723223, 26.003471],
                            [109.725013, 25.996759],
                            [109.729685, 25.991524],
                            [109.724455, 25.981921],
                            [109.723525, 25.97463],
                            [109.718597, 25.970711],
                            [109.715599, 25.964961],
                            [109.711485, 25.961749],
                            [109.709997, 25.954071],
                            [109.700328, 25.955902],
                            [109.693471, 25.959179],
                            [109.694285, 25.949123],
                            [109.689288, 25.943951],
                            [109.69031, 25.937653],
                            [109.682082, 25.933187],
                            [109.67927, 25.923065],
                            [109.683826, 25.914774],
                            [109.692565, 25.909921],
                            [109.692519, 25.898029],
                            [109.685197, 25.901115],
                            [109.680548, 25.895137],
                            [109.688404, 25.887422],
                            [109.685546, 25.886394],
                            [109.68522, 25.88035],
                            [109.695773, 25.882536],
                            [109.702118, 25.882279],
                            [109.710672, 25.886908],
                            [109.719434, 25.883822],
                            [109.729127, 25.885011],
                            [109.747233, 25.889254],
                            [109.768361, 25.890347],
                            [109.764759, 25.876171],
                            [109.770151, 25.874339],
                            [109.779541, 25.865948],
                            [109.788281, 25.867587],
                            [109.799391, 25.871863],
                            [109.813244, 25.879836],
                            [109.815871, 25.890862],
                            [109.825958, 25.911399],
                            [109.826911, 25.916702],
                            [109.825029, 25.922872],
                            [109.818753, 25.934761],
                            [109.810455, 25.945653],
                            [109.807271, 25.95481],
                            [109.80648, 25.973634],
                            [109.801065, 25.977456],
                            [109.790838, 25.981247],
                            [109.784655, 25.985743],
                            [109.782447, 25.997209],
                            [109.788653, 26.007613],
                            [109.791814, 26.017278],
                            [109.801971, 26.034391],
                            [109.806248, 26.03789],
                            [109.814592, 26.040972],
                            [109.840183, 26.029094],
                            [109.856244, 26.026301],
                            [109.864124, 26.027521],
                            [109.870888, 26.033364],
                            [109.877, 26.05041],
                            [109.880464, 26.057343],
                            [109.88237, 26.066074],
                            [109.882486, 26.075253],
                            [109.888157, 26.087961],
                            [109.899012, 26.095213],
                            [109.899058, 26.108978],
                            [109.904544, 26.135669],
                            [109.907194, 26.144874],
                            [109.914469, 26.154368],
                            [109.931204, 26.161263],
                            [109.939037, 26.167324],
                            [109.95038, 26.178515],
                            [109.9607, 26.190827],
                            [109.966255, 26.195508],
                            [109.971694, 26.208908],
                            [109.974367, 26.218333],
                            [109.978643, 26.224615],
                            [109.992775, 26.238621],
                            [109.988824, 26.24519],
                            [109.995332, 26.258681],
                            [109.991102, 26.265282],
                            [109.990381, 26.272843],
                            [109.984292, 26.279091],
                            [109.99596, 26.295012],
                            [110.009301, 26.304013],
                            [110.009906, 26.316408],
                            [110.012974, 26.335046],
                            [110.016762, 26.340617],
                            [110.017669, 26.347534],
                            [110.013462, 26.356658],
                            [110.011556, 26.364662],
                            [110.01223, 26.372473],
                            [110.005466, 26.377659],
                            [109.993659, 26.380571],
                            [109.981526, 26.385565],
                            [109.978667, 26.388029],
                            [109.977993, 26.400191],
                            [109.979434, 26.407328],
                            [109.988336, 26.429951],
                            [109.987267, 26.432478],
                            [109.972205, 26.428127],
                            [109.966836, 26.428031],
                            [109.95289, 26.434494],
                            [109.950566, 26.436989],
                            [109.955028, 26.446907],
                            [109.950868, 26.451226],
                            [109.939804, 26.454041],
                            [109.9349, 26.456952],
                            [109.93476, 26.466835],
                            [109.932575, 26.475983],
                            [109.928461, 26.47707],
                            [109.919024, 26.474671],
                            [109.910448, 26.466739],
                            [109.902847, 26.461814],
                            [109.891388, 26.459383],
                            [109.881579, 26.465716],
                            [109.87542, 26.468147],
                            [109.860172, 26.463605],
                            [109.856291, 26.465204],
                            [109.850364, 26.463061],
                            [109.839858, 26.463029],
                            [109.836278, 26.449786],
                            [109.819659, 26.45145],
                            [109.81529, 26.44454],
                            [109.803714, 26.443228],
                            [109.802064, 26.447579],
                            [109.792511, 26.442172],
                            [109.788815, 26.429919],
                            [109.781657, 26.427103],
                            [109.776868, 26.420992],
                            [109.767827, 26.419072],
                            [109.763039, 26.425247],
                            [109.765107, 26.440317],
                            [109.76434, 26.445403],
                            [109.758553, 26.457144],
                            [109.749302, 26.466228],
                            [109.738959, 26.464149],
                            [109.730614, 26.45644],
                            [109.725803, 26.442844],
                            [109.716947, 26.430335],
                            [109.698841, 26.417056],
                            [109.693262, 26.410624],
                            [109.685081, 26.411936],
                            [109.678363, 26.411296],
                            [109.673041, 26.402368],
                            [109.678503, 26.399263],
                            [109.681455, 26.395071],
                            [109.676527, 26.391102],
                            [109.677271, 26.388093],
                            [109.683175, 26.387037],
                            [109.680804, 26.379643],
                            [109.674668, 26.373305],
                            [109.669833, 26.372025],
                            [109.661489, 26.377851],
                            [109.667997, 26.382428],
                            [109.663836, 26.389278],
                            [109.664743, 26.39299],
                            [109.661094, 26.396415],
                            [109.652052, 26.395935],
                            [109.645149, 26.397151],
                            [109.636618, 26.401983],
                            [109.631086, 26.402912],
                            [109.62667, 26.409536],
                            [109.619163, 26.408],
                            [109.614979, 26.401023],
                            [109.611283, 26.399135],
                            [109.596129, 26.40272],
                            [109.591387, 26.400159],
                            [109.591178, 26.390974],
                            [109.599057, 26.379867],
                            [109.598685, 26.372313],
                            [109.595408, 26.363702],
                            [109.586157, 26.352368],
                            [109.579161, 26.345324],
                            [109.566052, 26.33876],
                            [109.55629, 26.327456],
                            [109.540903, 26.315927],
                            [109.538392, 26.308881],
                            [109.537253, 26.296902],
                            [109.529397, 26.289022],
                            [109.519031, 26.275983],
                            [109.514312, 26.273676],
                            [109.507316, 26.276271],
                            [109.4986, 26.275022],
                            [109.492138, 26.268197],
                            [109.485398, 26.265955],
                            [109.473427, 26.271049],
                            [109.468058, 26.271049],
                            [109.456367, 26.267044]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 431281,
                "name": "洪江市",
                "center": [109.831765, 27.201876],
                "centroid": [110.07934, 27.232457],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431200
                },
                "subFeatureIndex": 11,
                "acroutes": [100000, 430000, 431200]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [110.52428, 27.293387],
                            [110.524861, 27.298277],
                            [110.522513, 27.302055],
                            [110.521305, 27.316373],
                            [110.525674, 27.324659],
                            [110.527743, 27.333515],
                            [110.527394, 27.340656],
                            [110.523396, 27.347289],
                            [110.518492, 27.350971],
                            [110.497852, 27.354525],
                            [110.483418, 27.354525],
                            [110.474493, 27.351288],
                            [110.457362, 27.353287],
                            [110.44681, 27.357698],
                            [110.442324, 27.356048],
                            [110.431818, 27.335197],
                            [110.423636, 27.331896],
                            [110.417361, 27.339069],
                            [110.415943, 27.354303],
                            [110.41578, 27.369249],
                            [110.422079, 27.388951],
                            [110.433073, 27.41417],
                            [110.430958, 27.413472],
                            [110.423381, 27.420703],
                            [110.414292, 27.43285],
                            [110.409574, 27.436496],
                            [110.403019, 27.434499],
                            [110.400742, 27.417849],
                            [110.393048, 27.414265],
                            [110.372385, 27.410141],
                            [110.364714, 27.411632],
                            [110.355882, 27.415216],
                            [110.347514, 27.413757],
                            [110.336125, 27.413535],
                            [110.330826, 27.416073],
                            [110.325875, 27.428917],
                            [110.321482, 27.43323],
                            [110.312394, 27.432723],
                            [110.302376, 27.44176],
                            [110.296891, 27.435196],
                            [110.298262, 27.429203],
                            [110.295961, 27.426031],
                            [110.290871, 27.426951],
                            [110.289267, 27.430979],
                            [110.283014, 27.431486],
                            [110.281922, 27.435069],
                            [110.27546, 27.42879],
                            [110.269928, 27.433008],
                            [110.265512, 27.430883],
                            [110.264722, 27.423145],
                            [110.262607, 27.421274],
                            [110.252263, 27.419403],
                            [110.247545, 27.423621],
                            [110.241664, 27.420989],
                            [110.232879, 27.430915],
                            [110.226277, 27.424731],
                            [110.219211, 27.42527],
                            [110.218398, 27.421274],
                            [110.213912, 27.420767],
                            [110.210565, 27.407794],
                            [110.199571, 27.408111],
                            [110.199617, 27.403512],
                            [110.19648, 27.404147],
                            [110.179954, 27.402656],
                            [110.172469, 27.394916],
                            [110.166403, 27.394884],
                            [110.167309, 27.404115],
                            [110.162126, 27.407667],
                            [110.164381, 27.412901],
                            [110.168797, 27.414963],
                            [110.167867, 27.429266],
                            [110.164032, 27.436338],
                            [110.159104, 27.437797],
                            [110.152178, 27.442648],
                            [110.143322, 27.442014],
                            [110.145995, 27.445407],
                            [110.138929, 27.447024],
                            [110.139719, 27.450829],
                            [110.144879, 27.452382],
                            [110.144856, 27.455838],
                            [110.151085, 27.45828],
                            [110.149807, 27.462338],
                            [110.145298, 27.462021],
                            [110.146553, 27.469217],
                            [110.141928, 27.475462],
                            [110.137883, 27.477713],
                            [110.132142, 27.475811],
                            [110.136535, 27.471817],
                            [110.134188, 27.467188],
                            [110.124007, 27.466618],
                            [110.120125, 27.469883],
                            [110.128167, 27.469566],
                            [110.129074, 27.471563],
                            [110.124263, 27.475843],
                            [110.119916, 27.476065],
                            [110.115872, 27.469027],
                            [110.111944, 27.466364],
                            [110.113059, 27.460055],
                            [110.106086, 27.457931],
                            [110.111456, 27.45606],
                            [110.116778, 27.457043],
                            [110.114663, 27.453429],
                            [110.118754, 27.447595],
                            [110.105668, 27.440809],
                            [110.099532, 27.435418],
                            [110.088956, 27.433103],
                            [110.081007, 27.42822],
                            [110.082773, 27.424255],
                            [110.072174, 27.413599],
                            [110.064783, 27.41103],
                            [110.060878, 27.406557],
                            [110.060251, 27.401958],
                            [110.053138, 27.405479],
                            [110.044747, 27.405479],
                            [110.038053, 27.390125],
                            [110.039146, 27.38524],
                            [110.045375, 27.379275],
                            [110.046677, 27.374833],
                            [110.041633, 27.365631],
                            [110.043655, 27.361316],
                            [110.047606, 27.359983],
                            [110.048768, 27.349447],
                            [110.045119, 27.34218],
                            [110.040075, 27.345798],
                            [110.030127, 27.344496],
                            [110.019412, 27.3358],
                            [110.014531, 27.329261],
                            [110.006326, 27.325294],
                            [110.004188, 27.317072],
                            [109.998005, 27.315993],
                            [109.987685, 27.309802],
                            [109.985175, 27.304659],
                            [109.994612, 27.299801],
                            [110.001538, 27.299642],
                            [110.003909, 27.293006],
                            [110.003816, 27.283353],
                            [109.995611, 27.280305],
                            [109.994426, 27.276335],
                            [109.984338, 27.270365],
                            [109.980131, 27.27227],
                            [109.967231, 27.270809],
                            [109.963791, 27.269031],
                            [109.960467, 27.271858],
                            [109.954168, 27.269412],
                            [109.94945, 27.273414],
                            [109.942686, 27.275319],
                            [109.940245, 27.280495],
                            [109.933807, 27.283639],
                            [109.927508, 27.28167],
                            [109.924184, 27.285163],
                            [109.914887, 27.28818],
                            [109.909913, 27.2851],
                            [109.902289, 27.284084],
                            [109.899895, 27.277669],
                            [109.904892, 27.276907],
                            [109.901336, 27.27262],
                            [109.893015, 27.276177],
                            [109.886042, 27.275541],
                            [109.885531, 27.278717],
                            [109.880394, 27.275287],
                            [109.874955, 27.279098],
                            [109.866936, 27.278749],
                            [109.864565, 27.276907],
                            [109.85894, 27.28202],
                            [109.849434, 27.284909],
                            [109.843484, 27.296023],
                            [109.840369, 27.306246],
                            [109.835349, 27.307326],
                            [109.827353, 27.31358],
                            [109.823913, 27.313929],
                            [109.810269, 27.302786],
                            [109.812686, 27.298722],
                            [109.805039, 27.300563],
                            [109.798485, 27.296182],
                            [109.794231, 27.29561],
                            [109.790489, 27.290784],
                            [109.789908, 27.28383],
                            [109.784911, 27.283957],
                            [109.780611, 27.288434],
                            [109.7738, 27.283639],
                            [109.762969, 27.278304],
                            [109.754811, 27.279765],
                            [109.753834, 27.28167],
                            [109.743375, 27.28564],
                            [109.735914, 27.285703],
                            [109.725385, 27.282242],
                            [109.71611, 27.267888],
                            [109.713182, 27.266173],
                            [109.698585, 27.263314],
                            [109.697376, 27.259916],
                            [109.703861, 27.255533],
                            [109.706418, 27.250133],
                            [109.710276, 27.235298],
                            [109.708022, 27.226657],
                            [109.701839, 27.217029],
                            [109.697237, 27.212835],
                            [109.683593, 27.208768],
                            [109.678177, 27.208959],
                            [109.670554, 27.21274],
                            [109.669508, 27.218968],
                            [109.662, 27.213248],
                            [109.662767, 27.204859],
                            [109.656073, 27.19469],
                            [109.657049, 27.190177],
                            [109.653632, 27.192116],
                            [109.652796, 27.204669],
                            [109.645218, 27.202381],
                            [109.644451, 27.200125],
                            [109.653772, 27.183694],
                            [109.642267, 27.177083],
                            [109.641709, 27.173109],
                            [109.646125, 27.166816],
                            [109.645707, 27.15588],
                            [109.643731, 27.154545],
                            [109.63699, 27.157247],
                            [109.629111, 27.151365],
                            [109.62495, 27.151334],
                            [109.623277, 27.147423],
                            [109.617582, 27.147709],
                            [109.617675, 27.13887],
                            [109.609284, 27.146883],
                            [109.605449, 27.142463],
                            [109.596849, 27.144943],
                            [109.600708, 27.139284],
                            [109.597988, 27.132447],
                            [109.589365, 27.130253],
                            [109.579626, 27.13798],
                            [109.572885, 27.132257],
                            [109.568167, 27.132924],
                            [109.562798, 27.12825],
                            [109.550944, 27.131907],
                            [109.537765, 27.128409],
                            [109.546202, 27.12472],
                            [109.547016, 27.115243],
                            [109.543343, 27.11378],
                            [109.540856, 27.107483],
                            [109.563077, 27.103635],
                            [109.573327, 27.103221],
                            [109.576325, 27.101249],
                            [109.59062, 27.101472],
                            [109.597732, 27.094506],
                            [109.603613, 27.08649],
                            [109.58725, 27.078664],
                            [109.57077, 27.059607],
                            [109.560404, 27.053115],
                            [109.560194, 27.049201],
                            [109.5664, 27.044587],
                            [109.571956, 27.042964],
                            [109.57593, 27.045351],
                            [109.583717, 27.046624],
                            [109.591573, 27.0394],
                            [109.595594, 27.03765],
                            [109.606728, 27.03905],
                            [109.617257, 27.043887],
                            [109.621673, 27.044173],
                            [109.632063, 27.041023],
                            [109.649983, 27.045605],
                            [109.652238, 27.047674],
                            [109.654283, 27.057793],
                            [109.665138, 27.070965],
                            [109.673389, 27.076692],
                            [109.678503, 27.083182],
                            [109.68536, 27.079619],
                            [109.701769, 27.082863],
                            [109.709509, 27.087635],
                            [109.714669, 27.092947],
                            [109.719016, 27.092089],
                            [109.727686, 27.085313],
                            [109.738842, 27.0821],
                            [109.745699, 27.085249],
                            [109.751905, 27.09053],
                            [109.763434, 27.094411],
                            [109.788118, 27.094506],
                            [109.800251, 27.1002],
                            [109.808642, 27.10567],
                            [109.81873, 27.107451],
                            [109.826656, 27.116547],
                            [109.83056, 27.127169],
                            [109.835, 27.133401],
                            [109.842461, 27.138584],
                            [109.866169, 27.149458],
                            [109.871236, 27.145102],
                            [109.863264, 27.12189],
                            [109.864728, 27.116802],
                            [109.871957, 27.113876],
                            [109.879209, 27.114607],
                            [109.892271, 27.121127],
                            [109.906031, 27.124911],
                            [109.925788, 27.124148],
                            [109.934016, 27.12313],
                            [109.941315, 27.115911],
                            [109.949078, 27.092566],
                            [109.949659, 27.079141],
                            [109.953424, 27.077137],
                            [109.966324, 27.077074],
                            [109.985523, 27.079078],
                            [109.993124, 27.076469],
                            [110.000771, 27.069979],
                            [110.010789, 27.059702],
                            [110.018482, 27.058938],
                            [110.021946, 27.060625],
                            [110.036426, 27.084613],
                            [110.039936, 27.092057],
                            [110.042167, 27.092184],
                            [110.050465, 27.083818],
                            [110.056811, 27.082164],
                            [110.066666, 27.073733],
                            [110.072337, 27.064284],
                            [110.077055, 27.051906],
                            [110.08017, 27.047642],
                            [110.088979, 27.044078],
                            [110.097858, 27.037013],
                            [110.10095, 27.032526],
                            [110.100415, 27.022882],
                            [110.090839, 27.012728],
                            [110.094325, 27.005916],
                            [110.112106, 27.008049],
                            [110.117034, 27.009672],
                            [110.122473, 27.003878],
                            [110.133816, 26.996238],
                            [110.130399, 26.990698],
                            [110.133188, 26.989393],
                            [110.146878, 26.990603],
                            [110.148529, 26.989711],
                            [110.153619, 26.998403],
                            [110.153991, 27.009481],
                            [110.150342, 27.02285],
                            [110.15506, 27.037713],
                            [110.162614, 27.046401],
                            [110.183277, 27.055311],
                            [110.198107, 27.059352],
                            [110.201407, 27.061929],
                            [110.208357, 27.081273],
                            [110.204405, 27.092025],
                            [110.196108, 27.098609],
                            [110.194178, 27.102076],
                            [110.200477, 27.118169],
                            [110.206846, 27.122367],
                            [110.22651, 27.130349],
                            [110.239596, 27.140079],
                            [110.249474, 27.151079],
                            [110.267046, 27.153877],
                            [110.287663, 27.160776],
                            [110.292335, 27.163414],
                            [110.297588, 27.161221],
                            [110.304631, 27.166975],
                            [110.314369, 27.157946],
                            [110.325805, 27.158805],
                            [110.330942, 27.156548],
                            [110.331779, 27.151461],
                            [110.342726, 27.150507],
                            [110.345818, 27.15499],
                            [110.346468, 27.160299],
                            [110.355905, 27.175271],
                            [110.356417, 27.184393],
                            [110.353232, 27.191639],
                            [110.352117, 27.199044],
                            [110.357765, 27.208959],
                            [110.362785, 27.209753],
                            [110.366458, 27.215218],
                            [110.375592, 27.223035],
                            [110.378614, 27.223098],
                            [110.387144, 27.218809],
                            [110.391723, 27.214074],
                            [110.397139, 27.212803],
                            [110.408296, 27.20648],
                            [110.424055, 27.199489],
                            [110.434839, 27.199362],
                            [110.443044, 27.201237],
                            [110.450459, 27.20683],
                            [110.456293, 27.21328],
                            [110.459477, 27.228912],
                            [110.459872, 27.239714],
                            [110.462104, 27.247083],
                            [110.46745, 27.252388],
                            [110.483534, 27.262139],
                            [110.5045, 27.270301],
                            [110.511659, 27.273763],
                            [110.521072, 27.282559],
                            [110.526395, 27.289926],
                            [110.52428, 27.293387]
                        ]
                    ]
                ]
            }
        }]
    });
}));