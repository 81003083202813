/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

(function(root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['exports', 'echarts'], factory);
    } else if (typeof exports === 'object' && typeof exports.nodeName !== 'string') {
        // CommonJS
        factory(exports, require('echarts'));
    } else {
        // Browser globals
        factory({}, root.echarts);
    }
}(this, function(exports, echarts) {
    var log = function(msg) {
        if (typeof console !== 'undefined') {
            console && console.error && console.error(msg);
        }
    }
    if (!echarts) {
        log('ECharts is not Loaded');
        return;
    }
    if (!echarts.registerMap) {
        log('ECharts Map is not loaded')
        return;
    }
    echarts.registerMap('湘潭市', {
        "type": "FeatureCollection",
        "features": [{
            "type": "Feature",
            "properties": {
                "adcode": 430302,
                "name": "雨湖区",
                "center": [112.907427, 27.86077],
                "centroid": [112.850937, 27.926096],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430300
                },
                "subFeatureIndex": 0,
                "acroutes": [100000, 430000, 430300]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.895874, 27.79849],
                            [112.894291, 27.800158],
                            [112.892261, 27.803142],
                            [112.890383, 27.807198],
                            [112.888091, 27.813128],
                            [112.887283, 27.818139],
                            [112.887109, 27.823591],
                            [112.887949, 27.829043],
                            [112.889314, 27.833576],
                            [112.890831, 27.837294],
                            [112.891781, 27.839027],
                            [112.89392, 27.841753],
                            [112.896758, 27.843589],
                            [112.90107, 27.84558],
                            [112.906146, 27.848195],
                            [112.929571, 27.858339],
                            [112.93706, 27.862247],
                            [112.947736, 27.86838],
                            [112.952539, 27.872625],
                            [112.955715, 27.876135],
                            [112.957964, 27.879491],
                            [112.961097, 27.885314],
                            [112.962516, 27.889559],
                            [112.963989, 27.895639],
                            [112.967242, 27.906035],
                            [112.969262, 27.909655],
                            [112.971663, 27.911908],
                            [112.974141, 27.915711],
                            [112.975757, 27.920402],
                            [112.977252, 27.923647],
                            [112.979413, 27.926627],
                            [112.984915, 27.929798],
                            [112.988768, 27.931552],
                            [112.992633, 27.932529],
                            [112.998844, 27.93465],
                            [113.0038, 27.938613],
                            [113.00869, 27.943435],
                            [113.010273, 27.948052],
                            [113.010469, 27.952066],
                            [113.009749, 27.956667],
                            [113.008297, 27.960153],
                            [113.004869, 27.963235],
                            [113.003188, 27.965158],
                            [112.996933, 27.972018],
                            [112.992076, 27.975848],
                            [112.972897, 27.98747],
                            [112.964917, 27.994087],
                            [112.963858, 27.995261],
                            [112.961719, 27.995649],
                            [112.95852, 27.997747],
                            [112.955169, 28.000403],
                            [112.952844, 28.003608],
                            [112.949024, 28.010885],
                            [112.945694, 28.01475],
                            [112.943042, 28.017031],
                            [112.939756, 28.019187],
                            [112.93861, 28.019231],
                            [112.934385, 28.016356],
                            [112.933447, 28.015271],
                            [112.93373, 28.013584],
                            [112.935586, 28.00864],
                            [112.935848, 28.00396],
                            [112.934505, 27.999941],
                            [112.933327, 27.998114],
                            [112.931547, 27.9965],
                            [112.929342, 27.994996],
                            [112.928054, 27.993104],
                            [112.929484, 27.991776],
                            [112.933174, 27.992194],
                            [112.934942, 27.992796],
                            [112.937627, 27.992392],
                            [112.940062, 27.989568],
                            [112.940138, 27.986274],
                            [112.941393, 27.984814],
                            [112.940367, 27.982591],
                            [112.937868, 27.981615],
                            [112.937584, 27.984909],
                            [112.935706, 27.985459],
                            [112.934658, 27.984726],
                            [112.932573, 27.984667],
                            [112.928349, 27.983574],
                            [112.926733, 27.982327],
                            [112.926908, 27.98061],
                            [112.930117, 27.980265],
                            [112.93051, 27.979862],
                            [112.930063, 27.977675],
                            [112.929211, 27.976941],
                            [112.924135, 27.974902],
                            [112.922302, 27.974968],
                            [112.920773, 27.976934],
                            [112.916505, 27.977917],
                            [112.91454, 27.977712],
                            [112.90655, 27.971064],
                            [112.904629, 27.971248],
                            [112.904345, 27.972811],
                            [112.902424, 27.975276],
                            [112.901692, 27.976729],
                            [112.900251, 27.978108],
                            [112.89845, 27.978754],
                            [112.894444, 27.9789],
                            [112.89237, 27.977903],
                            [112.889281, 27.974403],
                            [112.88784, 27.973698],
                            [112.885657, 27.974278],
                            [112.884543, 27.975841],
                            [112.883648, 27.978475],
                            [112.883757, 27.979157],
                            [112.886661, 27.98265],
                            [112.890558, 27.986501],
                            [112.892578, 27.989935],
                            [112.893473, 27.99215],
                            [112.89356, 27.993727],
                            [112.892763, 27.995349],
                            [112.89165, 27.995877],
                            [112.889259, 27.995803],
                            [112.887327, 27.993947],
                            [112.886072, 27.993309],
                            [112.882273, 27.992898],
                            [112.880854, 27.993999],
                            [112.882677, 27.996119],
                            [112.882502, 27.999229],
                            [112.881203, 28.001349],
                            [112.879478, 28.002457],
                            [112.877568, 28.004217],
                            [112.875003, 28.004672],
                            [112.873977, 28.004393],
                            [112.87282, 28.002567],
                            [112.869927, 28.001943],
                            [112.869032, 28.000645],
                            [112.870484, 27.999383],
                            [112.872296, 27.999317],
                            [112.874381, 27.998327],
                            [112.87533, 27.99631],
                            [112.87485, 27.993779],
                            [112.873027, 27.990301],
                            [112.871783, 27.98904],
                            [112.869894, 27.987983],
                            [112.862919, 27.986391],
                            [112.859339, 27.986721],
                            [112.858629, 27.98733],
                            [112.858706, 27.988856],
                            [112.859884, 27.993052],
                            [112.86126, 27.99653],
                            [112.860856, 27.998033],
                            [112.860878, 28.000682],
                            [112.859732, 28.004173],
                            [112.855649, 28.009491],
                            [112.8528, 28.016628],
                            [112.850508, 28.021285],
                            [112.849471, 28.022576],
                            [112.84839, 28.022678],
                            [112.842877, 28.02138],
                            [112.841338, 28.021197],
                            [112.838762, 28.021453],
                            [112.835738, 28.023001],
                            [112.832595, 28.025172],
                            [112.831787, 28.027364],
                            [112.833337, 28.034375],
                            [112.833544, 28.037704],
                            [112.831962, 28.040513],
                            [112.830564, 28.041121],
                            [112.825565, 28.044003],
                            [112.822115, 28.052091],
                            [112.816668, 28.059092],
                            [112.815926, 28.061512],
                            [112.81657, 28.064789],
                            [112.817935, 28.067171],
                            [112.818601, 28.069209],
                            [112.818393, 28.070397],
                            [112.816788, 28.071504],
                            [112.815631, 28.071724],
                            [112.813252, 28.06995],
                            [112.810457, 28.069297],
                            [112.807335, 28.070111],
                            [112.802128, 28.069378],
                            [112.794356, 28.070477],
                            [112.790972, 28.071232],
                            [112.788145, 28.072552],
                            [112.782731, 28.073564],
                            [112.77974, 28.07319],
                            [112.77855, 28.071819],
                            [112.779052, 28.068945],
                            [112.778812, 28.067589],
                            [112.777448, 28.065375],
                            [112.777044, 28.063227],
                            [112.777142, 28.061138],
                            [112.779947, 28.056658],
                            [112.782556, 28.052794],
                            [112.786038, 28.050294],
                            [112.791791, 28.049766],
                            [112.79261, 28.04896],
                            [112.7944, 28.048241],
                            [112.800513, 28.044362],
                            [112.802314, 28.042683],
                            [112.804388, 28.040212],
                            [112.807324, 28.038577],
                            [112.808667, 28.036861],
                            [112.807968, 28.033019],
                            [112.804246, 28.025634],
                            [112.802751, 28.025186],
                            [112.797773, 28.025164],
                            [112.795219, 28.024167],
                            [112.792457, 28.023734],
                            [112.791616, 28.02394],
                            [112.79047, 28.025274],
                            [112.790296, 28.027628],
                            [112.79047, 28.031765],
                            [112.788702, 28.033671],
                            [112.787447, 28.034537],
                            [112.784357, 28.033921],
                            [112.782982, 28.033943],
                            [112.779827, 28.035108],
                            [112.777, 28.036956],
                            [112.77259, 28.036201],
                            [112.771782, 28.035343],
                            [112.771618, 28.033708],
                            [112.772404, 28.031039],
                            [112.779107, 28.025076],
                            [112.78022, 28.024688],
                            [112.780406, 28.02295],
                            [112.779456, 28.021497],
                            [112.775406, 28.019363],
                            [112.773245, 28.019121],
                            [112.770123, 28.017427],
                            [112.768988, 28.012117],
                            [112.768704, 28.00641],
                            [112.767023, 28.004019],
                            [112.764425, 28.00308],
                            [112.763683, 28.002339],
                            [112.765626, 27.996669],
                            [112.765866, 27.991695],
                            [112.764469, 27.986817],
                            [112.761336, 27.982459],
                            [112.76043, 27.980691],
                            [112.760211, 27.977081],
                            [112.758519, 27.973544],
                            [112.758301, 27.972348],
                            [112.759677, 27.970463],
                            [112.759589, 27.969333],
                            [112.75841, 27.965414],
                            [112.755408, 27.961173],
                            [112.753727, 27.959331],
                            [112.750551, 27.954525],
                            [112.745431, 27.948323],
                            [112.744089, 27.94643],
                            [112.743685, 27.944617],
                            [112.744209, 27.941468],
                            [112.744274, 27.939435],
                            [112.743401, 27.935978],
                            [112.743073, 27.931963],
                            [112.741764, 27.929783],
                            [112.740868, 27.929093],
                            [112.738445, 27.928242],
                            [112.73409, 27.928058],
                            [112.731852, 27.929152],
                            [112.731, 27.928609],
                            [112.730389, 27.926289],
                            [112.728872, 27.924748],
                            [112.728588, 27.923683],
                            [112.72909, 27.92201],
                            [112.73242, 27.920439],
                            [112.73243, 27.918251],
                            [112.731, 27.916967],
                            [112.723599, 27.915704],
                            [112.721493, 27.915491],
                            [112.724833, 27.910477],
                            [112.727835, 27.904016],
                            [112.729745, 27.892312],
                            [112.724757, 27.890396],
                            [112.721307, 27.881768],
                            [112.721111, 27.875915],
                            [112.723992, 27.869753],
                            [112.728217, 27.864223],
                            [112.733675, 27.85914],
                            [112.735072, 27.851324],
                            [112.751086, 27.845183],
                            [112.751555, 27.843846],
                            [112.749361, 27.841349],
                            [112.745322, 27.839446],
                            [112.743859, 27.836603],
                            [112.743892, 27.833392],
                            [112.745519, 27.830417],
                            [112.750987, 27.824664],
                            [112.752516, 27.821123],
                            [112.755681, 27.824951],
                            [112.761161, 27.828014],
                            [112.765844, 27.831093],
                            [112.77045, 27.834737],
                            [112.773234, 27.840012],
                            [112.777622, 27.84367],
                            [112.781858, 27.844133],
                            [112.788003, 27.844419],
                            [112.801659, 27.837183],
                            [112.814223, 27.834781],
                            [112.825729, 27.83085],
                            [112.842506, 27.821071],
                            [112.855453, 27.8203],
                            [112.862974, 27.821578],
                            [112.864163, 27.820366],
                            [112.869872, 27.817331],
                            [112.872187, 27.814752],
                            [112.874031, 27.811901],
                            [112.875243, 27.808425],
                            [112.876084, 27.804744],
                            [112.876859, 27.802649],
                            [112.878562, 27.800841],
                            [112.881236, 27.799732],
                            [112.884664, 27.799313],
                            [112.887174, 27.799629],
                            [112.895874, 27.79849]
                        ]
                    ],
                    [
                        [
                            [112.702248, 27.847637],
                            [112.703176, 27.846623],
                            [112.705206, 27.846755],
                            [112.705752, 27.847226],
                            [112.705796, 27.84998],
                            [112.707488, 27.85128],
                            [112.710053, 27.851413],
                            [112.711657, 27.851133],
                            [112.713033, 27.849158],
                            [112.71503, 27.849187],
                            [112.716428, 27.850656],
                            [112.716198, 27.851714],
                            [112.714485, 27.852691],
                            [112.709835, 27.852683],
                            [112.70871, 27.853176],
                            [112.708841, 27.85402],
                            [112.710599, 27.855218],
                            [112.712389, 27.855886],
                            [112.71574, 27.856062],
                            [112.71729, 27.856518],
                            [112.717912, 27.858258],
                            [112.717399, 27.859691],
                            [112.71538, 27.860242],
                            [112.712345, 27.860381],
                            [112.708274, 27.859794],
                            [112.707488, 27.858905],
                            [112.706669, 27.856635],
                            [112.705206, 27.853895],
                            [112.703514, 27.852757],
                            [112.702837, 27.851332],
                            [112.703099, 27.848952],
                            [112.702248, 27.847637]
                        ]
                    ],
                    [
                        [
                            [112.668965, 27.851736],
                            [112.667317, 27.854233],
                            [112.664806, 27.854373],
                            [112.661695, 27.853234],
                            [112.655593, 27.852559],
                            [112.653792, 27.851839],
                            [112.652908, 27.850509],
                            [112.65233, 27.849628],
                            [112.652646, 27.846873],
                            [112.654567, 27.840188],
                            [112.655572, 27.835178],
                            [112.657635, 27.834046],
                            [112.659305, 27.834134],
                            [112.661357, 27.835119],
                            [112.666848, 27.835699],
                            [112.66866, 27.836632],
                            [112.669937, 27.838844],
                            [112.670472, 27.842539],
                            [112.669992, 27.849657],
                            [112.668965, 27.851736]
                        ]
                    ],
                    [
                        [
                            [112.674358, 27.824209],
                            [112.673321, 27.825715],
                            [112.670046, 27.826112],
                            [112.6683, 27.825289],
                            [112.668179, 27.822857],
                            [112.668365, 27.821475],
                            [112.669828, 27.819976],
                            [112.672513, 27.819528],
                            [112.674063, 27.81994],
                            [112.674795, 27.82099],
                            [112.674936, 27.822416],
                            [112.674358, 27.824209]
                        ]
                    ],
                    [
                        [
                            [112.683265, 27.83614],
                            [112.682588, 27.838234],
                            [112.680875, 27.839468],
                            [112.678659, 27.839013],
                            [112.675777, 27.837911],
                            [112.67403, 27.836838],
                            [112.673113, 27.835207],
                            [112.673332, 27.834311],
                            [112.678571, 27.829205],
                            [112.67999, 27.828793],
                            [112.683756, 27.829543],
                            [112.685023, 27.830329],
                            [112.68559, 27.832386],
                            [112.683265, 27.83614]
                        ]
                    ],
                    [
                        [
                            [112.662056, 27.819896],
                            [112.659982, 27.819866],
                            [112.657307, 27.820263],
                            [112.655921, 27.819381],
                            [112.655419, 27.817743],
                            [112.656128, 27.8152],
                            [112.658301, 27.815178],
                            [112.661128, 27.816472],
                            [112.665865, 27.81811],
                            [112.666302, 27.819168],
                            [112.664403, 27.819925],
                            [112.662056, 27.819896]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430304,
                "name": "岳塘区",
                "center": [112.927707, 27.828854],
                "centroid": [112.997621, 27.886637],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430300
                },
                "subFeatureIndex": 1,
                "acroutes": [100000, 430000, 430300]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [113.003188, 27.965158],
                            [113.004869, 27.963235],
                            [113.008297, 27.960153],
                            [113.009749, 27.956667],
                            [113.010469, 27.952066],
                            [113.010273, 27.948052],
                            [113.00869, 27.943435],
                            [113.0038, 27.938613],
                            [112.998844, 27.93465],
                            [112.992633, 27.932529],
                            [112.988768, 27.931552],
                            [112.984915, 27.929798],
                            [112.979413, 27.926627],
                            [112.977252, 27.923647],
                            [112.975757, 27.920402],
                            [112.974141, 27.915711],
                            [112.971663, 27.911908],
                            [112.969262, 27.909655],
                            [112.967242, 27.906035],
                            [112.963989, 27.895639],
                            [112.962516, 27.889559],
                            [112.961097, 27.885314],
                            [112.957964, 27.879491],
                            [112.955715, 27.876135],
                            [112.952539, 27.872625],
                            [112.947736, 27.86838],
                            [112.93706, 27.862247],
                            [112.929571, 27.858339],
                            [112.906146, 27.848195],
                            [112.90107, 27.84558],
                            [112.896758, 27.843589],
                            [112.89392, 27.841753],
                            [112.891781, 27.839027],
                            [112.890831, 27.837294],
                            [112.889314, 27.833576],
                            [112.887949, 27.829043],
                            [112.887109, 27.823591],
                            [112.887283, 27.818139],
                            [112.888091, 27.813128],
                            [112.890383, 27.807198],
                            [112.892261, 27.803142],
                            [112.894291, 27.800158],
                            [112.895874, 27.79849],
                            [112.905087, 27.793764],
                            [112.908744, 27.791787],
                            [112.913219, 27.790824],
                            [112.91966, 27.790361],
                            [112.924987, 27.790824],
                            [112.927716, 27.791353],
                            [112.932006, 27.79139],
                            [112.941797, 27.793154],
                            [112.97139, 27.801444],
                            [112.999368, 27.810777],
                            [113.004203, 27.812797],
                            [113.008057, 27.817155],
                            [113.012456, 27.822893],
                            [113.019267, 27.828918],
                            [113.02693, 27.834105],
                            [113.033633, 27.839115],
                            [113.038141, 27.843281],
                            [113.040957, 27.84475],
                            [113.046382, 27.84641],
                            [113.045073, 27.849811],
                            [113.042322, 27.855284],
                            [113.042038, 27.856349],
                            [113.042758, 27.860881],
                            [113.04254, 27.862225],
                            [113.039789, 27.862452],
                            [113.038599, 27.86412],
                            [113.038599, 27.866661],
                            [113.03932, 27.86871],
                            [113.040248, 27.870142],
                            [113.042551, 27.872669],
                            [113.045924, 27.875621],
                            [113.046382, 27.876561],
                            [113.047769, 27.88367],
                            [113.04838, 27.891005],
                            [113.053423, 27.900382],
                            [113.057113, 27.904941],
                            [113.061337, 27.910881],
                            [113.061654, 27.915697],
                            [113.063313, 27.923713],
                            [113.065212, 27.930356],
                            [113.069208, 27.938584],
                            [113.076543, 27.944037],
                            [113.079883, 27.946349],
                            [113.08115, 27.945909],
                            [113.082743, 27.946217],
                            [113.085101, 27.949028],
                            [113.086684, 27.950107],
                            [113.087983, 27.953431],
                            [113.089337, 27.957974],
                            [113.090898, 27.962406],
                            [113.09128, 27.964607],
                            [113.090963, 27.965114],
                            [113.089173, 27.964967],
                            [113.085385, 27.963822],
                            [113.082318, 27.96427],
                            [113.080811, 27.965994],
                            [113.080691, 27.967968],
                            [113.078704, 27.968885],
                            [113.077056, 27.968687],
                            [113.074818, 27.968929],
                            [113.073563, 27.970426],
                            [113.073727, 27.972011],
                            [113.075266, 27.974014],
                            [113.077023, 27.975628],
                            [113.082274, 27.979172],
                            [113.084348, 27.980735],
                            [113.086837, 27.983266],
                            [113.090308, 27.987389],
                            [113.090952, 27.989883],
                            [113.088922, 27.992165],
                            [113.088059, 27.993727],
                            [113.086979, 27.996852],
                            [113.084948, 27.998349],
                            [113.080233, 28.002303],
                            [113.07674, 28.006271],
                            [113.075834, 28.008603],
                            [113.07436, 28.009887],
                            [113.071729, 28.010892],
                            [113.068225, 28.010731],
                            [113.047703, 28.007555],
                            [113.041689, 28.006176],
                            [113.0398, 28.004892],
                            [113.037541, 28.001173],
                            [113.03563, 27.999493],
                            [113.033349, 27.996757],
                            [113.030871, 27.994732],
                            [113.030052, 27.991937],
                            [113.032377, 27.986604],
                            [113.033382, 27.983889],
                            [113.035336, 27.981131],
                            [113.035903, 27.979539],
                            [113.035925, 27.977418],
                            [113.035357, 27.976127],
                            [113.034091, 27.975137],
                            [113.030718, 27.974931],
                            [113.02931, 27.975672],
                            [113.027793, 27.978482],
                            [113.028371, 27.980823],
                            [113.03026, 27.982202],
                            [113.030434, 27.98306],
                            [113.028339, 27.984579],
                            [113.026134, 27.985658],
                            [113.022859, 27.985269],
                            [113.023634, 27.983398],
                            [113.024442, 27.979385],
                            [113.023907, 27.976986],
                            [113.020763, 27.973706],
                            [113.019366, 27.972664],
                            [113.012347, 27.972605],
                            [113.011255, 27.971842],
                            [113.008417, 27.968416],
                            [113.006136, 27.966647],
                            [113.003188, 27.965158]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430321,
                "name": "湘潭县",
                "center": [112.952829, 27.778601],
                "centroid": [112.773463, 27.608879],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430300
                },
                "subFeatureIndex": 2,
                "acroutes": [100000, 430000, 430300]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.721493, 27.915491],
                            [112.715784, 27.915697],
                            [112.712433, 27.916475],
                            [112.706047, 27.923096],
                            [112.704518, 27.923206],
                            [112.702128, 27.922171],
                            [112.699181, 27.922076],
                            [112.693592, 27.921445],
                            [112.685328, 27.920241],
                            [112.683429, 27.920307],
                            [112.68225, 27.921188],
                            [112.68273, 27.922046],
                            [112.686245, 27.924212],
                            [112.687741, 27.925541],
                            [112.687129, 27.927053],
                            [112.683014, 27.928264],
                            [112.67927, 27.929813],
                            [112.672972, 27.934239],
                            [112.671432, 27.934767],
                            [112.666804, 27.935567],
                            [112.664075, 27.937534],
                            [112.661685, 27.941094],
                            [112.659436, 27.941887],
                            [112.654589, 27.94149],
                            [112.652581, 27.940434],
                            [112.651729, 27.93934],
                            [112.65067, 27.935927],
                            [112.648214, 27.932595],
                            [112.64578, 27.930341],
                            [112.644503, 27.928447],
                            [112.641927, 27.923309],
                            [112.642745, 27.920131],
                            [112.645846, 27.916812],
                            [112.648072, 27.916908],
                            [112.650103, 27.918949],
                            [112.651227, 27.918523],
                            [112.651849, 27.916724],
                            [112.651598, 27.915909],
                            [112.651969, 27.912136],
                            [112.652635, 27.908421],
                            [112.653476, 27.905962],
                            [112.654949, 27.902944],
                            [112.657067, 27.900345],
                            [112.656314, 27.897445],
                            [112.653279, 27.894442],
                            [112.652733, 27.893017],
                            [112.652384, 27.885329],
                            [112.651926, 27.883405],
                            [112.649459, 27.880299],
                            [112.643804, 27.87966],
                            [112.640988, 27.879822],
                            [112.64066, 27.878008],
                            [112.639471, 27.874762],
                            [112.639416, 27.872279],
                            [112.640311, 27.870076],
                            [112.639449, 27.864303],
                            [112.635213, 27.862871],
                            [112.628937, 27.862335],
                            [112.627551, 27.861791],
                            [112.626568, 27.859669],
                            [112.652908, 27.850509],
                            [112.653792, 27.851839],
                            [112.655593, 27.852559],
                            [112.661695, 27.853234],
                            [112.664806, 27.854373],
                            [112.667317, 27.854233],
                            [112.668965, 27.851736],
                            [112.706669, 27.856635],
                            [112.707488, 27.858905],
                            [112.708274, 27.859794],
                            [112.712345, 27.860381],
                            [112.71538, 27.860242],
                            [112.717399, 27.859691],
                            [112.717912, 27.858258],
                            [112.71729, 27.856518],
                            [112.71574, 27.856062],
                            [112.712389, 27.855886],
                            [112.710599, 27.855218],
                            [112.708841, 27.85402],
                            [112.70871, 27.853176],
                            [112.709835, 27.852683],
                            [112.714485, 27.852691],
                            [112.716198, 27.851714],
                            [112.716428, 27.850656],
                            [112.71503, 27.849187],
                            [112.713033, 27.849158],
                            [112.711657, 27.851133],
                            [112.710053, 27.851413],
                            [112.707488, 27.85128],
                            [112.705796, 27.84998],
                            [112.705752, 27.847226],
                            [112.705206, 27.846755],
                            [112.703176, 27.846623],
                            [112.702248, 27.847637],
                            [112.683265, 27.83614],
                            [112.68559, 27.832386],
                            [112.685023, 27.830329],
                            [112.683756, 27.829543],
                            [112.67999, 27.828793],
                            [112.678571, 27.829205],
                            [112.674358, 27.824209],
                            [112.674936, 27.822416],
                            [112.674795, 27.82099],
                            [112.674063, 27.81994],
                            [112.672513, 27.819528],
                            [112.669828, 27.819976],
                            [112.668365, 27.821475],
                            [112.668179, 27.822857],
                            [112.662056, 27.819896],
                            [112.664403, 27.819925],
                            [112.666302, 27.819168],
                            [112.665865, 27.81811],
                            [112.661128, 27.816472],
                            [112.658301, 27.815178],
                            [112.656128, 27.8152],
                            [112.647396, 27.811879],
                            [112.648334, 27.810049],
                            [112.64673, 27.808205],
                            [112.643717, 27.806213],
                            [112.642614, 27.804891],
                            [112.642516, 27.803818],
                            [112.64435, 27.79979],
                            [112.645147, 27.79874],
                            [112.647854, 27.797328],
                            [112.651042, 27.794697],
                            [112.651904, 27.790986],
                            [112.653399, 27.787634],
                            [112.654022, 27.781556],
                            [112.654425, 27.78038],
                            [112.655954, 27.778101],
                            [112.655321, 27.776609],
                            [112.64983, 27.777793],
                            [112.643564, 27.777917],
                            [112.639209, 27.777498],
                            [112.635246, 27.776741],
                            [112.631786, 27.775727],
                            [112.628435, 27.774338],
                            [112.625171, 27.772713],
                            [112.623544, 27.771184],
                            [112.619669, 27.757775],
                            [112.618741, 27.75254],
                            [112.619364, 27.747217],
                            [112.6217, 27.740416],
                            [112.624101, 27.734761],
                            [112.627245, 27.731813],
                            [112.634187, 27.727724],
                            [112.636545, 27.724598],
                            [112.637277, 27.720642],
                            [112.636109, 27.715655],
                            [112.633172, 27.711235],
                            [112.630607, 27.708403],
                            [112.627845, 27.706263],
                            [112.622889, 27.705873],
                            [112.613447, 27.707815],
                            [112.604376, 27.711117],
                            [112.598241, 27.712177],
                            [112.594006, 27.711059],
                            [112.590491, 27.70897],
                            [112.58893, 27.705616],
                            [112.589105, 27.701239],
                            [112.591877, 27.696936],
                            [112.597379, 27.691088],
                            [112.601854, 27.685298],
                            [112.607782, 27.67828],
                            [112.609703, 27.672872],
                            [112.611133, 27.664741],
                            [112.610423, 27.662262],
                            [112.606843, 27.659488],
                            [112.596484, 27.656206],
                            [112.589137, 27.654815],
                            [112.585906, 27.649958],
                            [112.58476, 27.641752],
                            [112.584979, 27.634613],
                            [112.586408, 27.629468],
                            [112.589574, 27.623756],
                            [112.595938, 27.619008],
                            [112.601887, 27.612434],
                            [112.60943, 27.606007],
                            [112.61491, 27.599934],
                            [112.615761, 27.594139],
                            [112.615663, 27.590465],
                            [112.616143, 27.587667],
                            [112.617126, 27.584243],
                            [112.618359, 27.581224],
                            [112.618392, 27.579758],
                            [112.61741, 27.578831],
                            [112.61396, 27.577453],
                            [112.60955, 27.576113],
                            [112.607902, 27.576025],
                            [112.601658, 27.576953],
                            [112.599497, 27.577417],
                            [112.594181, 27.57939],
                            [112.592521, 27.579641],
                            [112.591091, 27.578595],
                            [112.589574, 27.573816],
                            [112.589137, 27.571606],
                            [112.586845, 27.569412],
                            [112.584837, 27.568918],
                            [112.581988, 27.56908],
                            [112.58083, 27.569456],
                            [112.579717, 27.570951],
                            [112.577163, 27.571525],
                            [112.573888, 27.568572],
                            [112.571519, 27.567335],
                            [112.5695, 27.567239],
                            [112.567841, 27.567887],
                            [112.564271, 27.570693],
                            [112.563529, 27.570281],
                            [112.562437, 27.568454],
                            [112.562164, 27.564382],
                            [112.560429, 27.556781],
                            [112.55913, 27.555565],
                            [112.557678, 27.555646],
                            [112.555113, 27.55684],
                            [112.553355, 27.558497],
                            [112.55246, 27.561244],
                            [112.551849, 27.565037],
                            [112.55103, 27.566945],
                            [112.548945, 27.569279],
                            [112.549469, 27.576135],
                            [112.549273, 27.580281],
                            [112.548487, 27.583551],
                            [112.5471, 27.585701],
                            [112.544819, 27.586305],
                            [112.541402, 27.58537],
                            [112.536774, 27.582446],
                            [112.530093, 27.58263],
                            [112.52517, 27.582483],
                            [112.519832, 27.583507],
                            [112.516143, 27.585811],
                            [112.513436, 27.590811],
                            [112.510772, 27.593042],
                            [112.507956, 27.593447],
                            [112.504561, 27.592976],
                            [112.499976, 27.590274],
                            [112.494759, 27.586238],
                            [112.492084, 27.581577],
                            [112.486757, 27.575936],
                            [112.4831, 27.575473],
                            [112.476627, 27.575193],
                            [112.470394, 27.574434],
                            [112.465679, 27.573573],
                            [112.460646, 27.57182],
                            [112.457055, 27.568823],
                            [112.453966, 27.56556],
                            [112.449971, 27.563969],
                            [112.445626, 27.56447],
                            [112.440594, 27.566156],
                            [112.435835, 27.568278],
                            [112.430519, 27.566304],
                            [112.428455, 27.562982],
                            [112.428717, 27.556295],
                            [112.429634, 27.55119],
                            [112.430093, 27.547021],
                            [112.428412, 27.542815],
                            [112.428499, 27.541202],
                            [112.429132, 27.539647],
                            [112.429307, 27.537032],
                            [112.426862, 27.532023],
                            [112.425978, 27.530969],
                            [112.42434, 27.530115],
                            [112.422332, 27.529827],
                            [112.421284, 27.527433],
                            [112.427102, 27.526092],
                            [112.430213, 27.524427],
                            [112.432625, 27.521701],
                            [112.436009, 27.514569],
                            [112.437527, 27.512351],
                            [112.437243, 27.51129],
                            [112.436424, 27.51171],
                            [112.434634, 27.513854],
                            [112.433433, 27.513618],
                            [112.432789, 27.512727],
                            [112.4322, 27.509963],
                            [112.432352, 27.50695],
                            [112.433913, 27.504805],
                            [112.434175, 27.503177],
                            [112.433379, 27.501998],
                            [112.4303, 27.50045],
                            [112.429656, 27.499765],
                            [112.430093, 27.49849],
                            [112.431403, 27.497908],
                            [112.435125, 27.497679],
                            [112.435987, 27.497223],
                            [112.437014, 27.495461],
                            [112.437789, 27.495078],
                            [112.440801, 27.495535],
                            [112.441991, 27.495358],
                            [112.44365, 27.492808],
                            [112.445004, 27.491651],
                            [112.446368, 27.491474],
                            [112.449087, 27.492359],
                            [112.450386, 27.493936],
                            [112.455276, 27.494606],
                            [112.457011, 27.49258],
                            [112.460144, 27.49045],
                            [112.463692, 27.483316],
                            [112.468495, 27.471345],
                            [112.470231, 27.461917],
                            [112.473025, 27.458224],
                            [112.476867, 27.455209],
                            [112.480961, 27.453108],
                            [112.489683, 27.450749],
                            [112.495326, 27.448633],
                            [112.500959, 27.445831],
                            [112.504026, 27.44328],
                            [112.507989, 27.440714],
                            [112.509451, 27.43851],
                            [112.513534, 27.435029],
                            [112.518468, 27.432021],
                            [112.521994, 27.429189],
                            [112.526491, 27.429742],
                            [112.5285, 27.430229],
                            [112.531032, 27.430273],
                            [112.531785, 27.427803],
                            [112.531491, 27.424912],
                            [112.531764, 27.4243],
                            [112.534132, 27.423106],
                            [112.536097, 27.420827],
                            [112.536676, 27.41784],
                            [112.537396, 27.417007],
                            [112.538859, 27.416697],
                            [112.540736, 27.417191],
                            [112.542472, 27.41846],
                            [112.546249, 27.423452],
                            [112.54817, 27.424846],
                            [112.55079, 27.425628],
                            [112.554545, 27.424912],
                            [112.554021, 27.427729],
                            [112.552231, 27.430627],
                            [112.552274, 27.431475],
                            [112.553322, 27.432404],
                            [112.558835, 27.43601],
                            [112.56176, 27.437765],
                            [112.565592, 27.44095],
                            [112.566902, 27.441768],
                            [112.568605, 27.441746],
                            [112.571989, 27.440522],
                            [112.573812, 27.438753],
                            [112.575536, 27.435737],
                            [112.576584, 27.434461],
                            [112.578058, 27.433702],
                            [112.582053, 27.433503],
                            [112.585492, 27.434764],
                            [112.587271, 27.437057],
                            [112.587205, 27.441577],
                            [112.587838, 27.443848],
                            [112.588744, 27.445507],
                            [112.591594, 27.447711],
                            [112.5931, 27.448308],
                            [112.593766, 27.449171],
                            [112.593711, 27.451147],
                            [112.592598, 27.455017],
                            [112.594268, 27.456071],
                            [112.598612, 27.454641],
                            [112.600785, 27.454236],
                            [112.603688, 27.454582],
                            [112.608328, 27.455998],
                            [112.611777, 27.458947],
                            [112.613338, 27.461298],
                            [112.615161, 27.465987],
                            [112.617846, 27.468161],
                            [112.619025, 27.468309],
                            [112.62135, 27.46662],
                            [112.623282, 27.466171],
                            [112.626121, 27.465987],
                            [112.630476, 27.466252],
                            [112.631382, 27.465714],
                            [112.631491, 27.46463],
                            [112.632495, 27.463547],
                            [112.635126, 27.463996],
                            [112.637156, 27.463274],
                            [112.638073, 27.461195],
                            [112.63958, 27.459839],
                            [112.641108, 27.460023],
                            [112.643946, 27.463723],
                            [112.646282, 27.464711],
                            [112.648422, 27.464173],
                            [112.650135, 27.463266],
                            [112.650736, 27.461881],
                            [112.650092, 27.461519],
                            [112.651489, 27.458357],
                            [112.653345, 27.457059],
                            [112.656259, 27.45442],
                            [112.659752, 27.452297],
                            [112.66282, 27.451692],
                            [112.664446, 27.452289],
                            [112.667415, 27.455187],
                            [112.66878, 27.456175],
                            [112.670614, 27.456728],
                            [112.673092, 27.456787],
                            [112.674129, 27.456189],
                            [112.675384, 27.453845],
                            [112.67927, 27.453712],
                            [112.684193, 27.454671],
                            [112.687239, 27.454685],
                            [112.690677, 27.454147],
                            [112.691791, 27.452982],
                            [112.691605, 27.450667],
                            [112.689924, 27.4469],
                            [112.687271, 27.437123],
                            [112.685743, 27.43483],
                            [112.680504, 27.431151],
                            [112.678484, 27.428902],
                            [112.679314, 27.42621],
                            [112.678299, 27.421505],
                            [112.677414, 27.419278],
                            [112.678506, 27.416314],
                            [112.682185, 27.41419],
                            [112.6838, 27.412766],
                            [112.684542, 27.411535],
                            [112.684968, 27.408732],
                            [112.684728, 27.40708],
                            [112.685645, 27.405686],
                            [112.686988, 27.405612],
                            [112.69, 27.406696],
                            [112.694923, 27.407714],
                            [112.698438, 27.406999],
                            [112.704115, 27.40764],
                            [112.706931, 27.40725],
                            [112.710064, 27.406364],
                            [112.715314, 27.404277],
                            [112.718065, 27.401887],
                            [112.720216, 27.401283],
                            [112.723512, 27.39893],
                            [112.724767, 27.396864],
                            [112.726088, 27.395544],
                            [112.727486, 27.394895],
                            [112.729254, 27.395212],
                            [112.730946, 27.396215],
                            [112.732332, 27.398502],
                            [112.734745, 27.400929],
                            [112.735803, 27.401511],
                            [112.738205, 27.401681],
                            [112.741076, 27.401231],
                            [112.745748, 27.399468],
                            [112.748411, 27.399018],
                            [112.751064, 27.39924],
                            [112.754655, 27.40028],
                            [112.75805, 27.403621],
                            [112.759546, 27.404329],
                            [112.760321, 27.404012],
                            [112.761849, 27.40008],
                            [112.762678, 27.398856],
                            [112.764534, 27.397676],
                            [112.769195, 27.395448],
                            [112.771815, 27.392778],
                            [112.772546, 27.391325],
                            [112.774391, 27.386287],
                            [112.77509, 27.381329],
                            [112.774053, 27.377294],
                            [112.77414, 27.376335],
                            [112.77557, 27.375405],
                            [112.777731, 27.374771],
                            [112.781334, 27.372248],
                            [112.785471, 27.366227],
                            [112.790437, 27.362022],
                            [112.791562, 27.362125],
                            [112.796441, 27.364338],
                            [112.801419, 27.362996],
                            [112.803831, 27.362044],
                            [112.804541, 27.359653],
                            [112.804726, 27.357336],
                            [112.806233, 27.350592],
                            [112.806287, 27.348725],
                            [112.805752, 27.347308],
                            [112.80489, 27.346614],
                            [112.799825, 27.344312],
                            [112.801157, 27.341913],
                            [112.805338, 27.33877],
                            [112.80656, 27.338231],
                            [112.807881, 27.338371],
                            [112.809246, 27.339301],
                            [112.812509, 27.342578],
                            [112.814125, 27.343397],
                            [112.816395, 27.343662],
                            [112.817116, 27.342947],
                            [112.818764, 27.339928],
                            [112.819932, 27.338762],
                            [112.821515, 27.338283],
                            [112.826525, 27.338039],
                            [112.831121, 27.338349],
                            [112.832311, 27.338829],
                            [112.83266, 27.339736],
                            [112.831154, 27.341854],
                            [112.830051, 27.34533],
                            [112.830684, 27.348112],
                            [112.832726, 27.351817],
                            [112.834931, 27.354399],
                            [112.836874, 27.354945],
                            [112.843849, 27.354281],
                            [112.848052, 27.355373],
                            [112.850453, 27.355425],
                            [112.852986, 27.355071],
                            [112.858007, 27.35347],
                            [112.861915, 27.351736],
                            [112.863891, 27.351212],
                            [112.86508, 27.351514],
                            [112.867165, 27.354038],
                            [112.869949, 27.356119],
                            [112.878802, 27.356119],
                            [112.882655, 27.356362],
                            [112.885864, 27.35693],
                            [112.888932, 27.358008],
                            [112.897533, 27.360317],
                            [112.899553, 27.360642],
                            [112.9019, 27.360516],
                            [112.912706, 27.358524],
                            [112.920195, 27.356613],
                            [112.923426, 27.362361],
                            [112.924932, 27.367083],
                            [112.923961, 27.376962],
                            [112.924004, 27.388101],
                            [112.922389, 27.402146],
                            [112.921799, 27.409838],
                            [112.922607, 27.415082],
                            [112.925118, 27.418887],
                            [112.931373, 27.423039],
                            [112.939625, 27.426262],
                            [112.955944, 27.430288],
                            [112.978136, 27.439188],
                            [112.987939, 27.440235],
                            [112.994969, 27.438613],
                            [112.995416, 27.438303],
                            [112.995569, 27.440574],
                            [112.994466, 27.443826],
                            [112.993102, 27.445374],
                            [112.990613, 27.445772],
                            [112.989107, 27.447099],
                            [112.987382, 27.450557],
                            [112.986411, 27.456352],
                            [112.986542, 27.461099],
                            [112.987011, 27.462743],
                            [112.98736, 27.46693],
                            [112.986531, 27.469341],
                            [112.985636, 27.469775],
                            [112.983365, 27.468817],
                            [112.97901, 27.467535],
                            [112.975757, 27.468257],
                            [112.974196, 27.469746],
                            [112.974698, 27.472245],
                            [112.972198, 27.473653],
                            [112.97139, 27.475223],
                            [112.97282, 27.476608],
                            [112.973857, 27.480802],
                            [112.976968, 27.483522],
                            [112.98092, 27.485814],
                            [112.983987, 27.487347],
                            [112.985112, 27.488445],
                            [112.986727, 27.49283],
                            [112.987677, 27.49776],
                            [112.987557, 27.498696],
                            [112.98199, 27.50171],
                            [112.975778, 27.501283],
                            [112.972187, 27.504982],
                            [112.969425, 27.511651],
                            [112.972351, 27.518518],
                            [112.975287, 27.526306],
                            [112.976171, 27.535485],
                            [112.976073, 27.539581],
                            [112.97651, 27.542137],
                            [112.975549, 27.549032],
                            [112.972766, 27.554792],
                            [112.970779, 27.561679],
                            [112.972154, 27.568793],
                            [112.981204, 27.577129],
                            [112.983583, 27.576481],
                            [112.986563, 27.576997],
                            [112.988823, 27.578345],
                            [112.991574, 27.582697],
                            [112.993757, 27.584832],
                            [112.997425, 27.585701],
                            [112.998866, 27.586636],
                            [113.000088, 27.588411],
                            [113.001529, 27.592284],
                            [113.002501, 27.594058],
                            [113.003505, 27.594758],
                            [113.005339, 27.595192],
                            [113.006845, 27.596068],
                            [113.009006, 27.598741],
                            [113.008177, 27.601237],
                            [113.007205, 27.602459],
                            [113.004302, 27.604027],
                            [113.000972, 27.604579],
                            [112.999018, 27.606118],
                            [112.998647, 27.608481],
                            [112.998811, 27.612206],
                            [112.998527, 27.613553],
                            [112.99713, 27.616123],
                            [112.997523, 27.618331],
                            [112.998822, 27.619656],
                            [113.002533, 27.621717],
                            [113.005437, 27.622284],
                            [113.007751, 27.621644],
                            [113.010054, 27.618596],
                            [113.012248, 27.616542],
                            [113.017161, 27.614135],
                            [113.018973, 27.612707],
                            [113.021723, 27.612648],
                            [113.022728, 27.613237],
                            [113.02561, 27.617587],
                            [113.026974, 27.61892],
                            [113.030194, 27.619892],
                            [113.03312, 27.620179],
                            [113.038807, 27.621599],
                            [113.040466, 27.624117],
                            [113.040117, 27.628062],
                            [113.039036, 27.630749],
                            [113.037792, 27.632302],
                            [113.032901, 27.637108],
                            [113.029201, 27.640074],
                            [113.024551, 27.642569],
                            [113.023863, 27.644755],
                            [113.02609, 27.650054],
                            [113.027913, 27.651224],
                            [113.03146, 27.651923],
                            [113.031493, 27.653446],
                            [113.030947, 27.654903],
                            [113.029758, 27.656515],
                            [113.02728, 27.659002],
                            [113.023939, 27.660282],
                            [113.01763, 27.660827],
                            [113.016102, 27.660393],
                            [113.010524, 27.656831],
                            [113.00678, 27.653983],
                            [113.004007, 27.652401],
                            [113.002741, 27.652431],
                            [113.001114, 27.653409],
                            [113.001038, 27.656566],
                            [113.000416, 27.658582],
                            [112.994576, 27.665794],
                            [112.993801, 27.668656],
                            [112.993986, 27.672048],
                            [112.994477, 27.675219],
                            [112.995078, 27.676587],
                            [112.997829, 27.678684],
                            [113.001376, 27.681973],
                            [113.003701, 27.683327],
                            [113.005797, 27.68365],
                            [113.00845, 27.684783],
                            [113.010491, 27.687027],
                            [113.011408, 27.6888],
                            [113.013995, 27.692522],
                            [113.016069, 27.695958],
                            [113.017477, 27.697296],
                            [113.020654, 27.702092],
                            [113.022509, 27.706211],
                            [113.026199, 27.711014],
                            [113.034506, 27.710801],
                            [113.039713, 27.71125],
                            [113.044079, 27.712302],
                            [113.04695, 27.712191],
                            [113.048107, 27.711478],
                            [113.050421, 27.711022],
                            [113.0516, 27.711838],
                            [113.053554, 27.715207],
                            [113.055912, 27.718362],
                            [113.056894, 27.721517],
                            [113.058936, 27.727113],
                            [113.058859, 27.72818],
                            [113.056316, 27.728893],
                            [113.05624, 27.729643],
                            [113.053696, 27.733335],
                            [113.044691, 27.738438],
                            [113.037977, 27.741004],
                            [113.032028, 27.742416],
                            [113.028666, 27.745438],
                            [113.028218, 27.748173],
                            [113.02955, 27.75207],
                            [113.032454, 27.756187],
                            [113.034866, 27.762826],
                            [113.033665, 27.770633],
                            [113.030893, 27.777307],
                            [113.027334, 27.783981],
                            [113.023022, 27.791809],
                            [113.018034, 27.796079],
                            [113.01548, 27.796638],
                            [113.01441, 27.79627],
                            [113.012598, 27.794764],
                            [113.010557, 27.794889],
                            [113.008613, 27.796145],
                            [113.006168, 27.797167],
                            [113.003767, 27.798754],
                            [113.001867, 27.801077],
                            [113.001431, 27.803443],
                            [113.001889, 27.807962],
                            [113.002337, 27.810402],
                            [113.004203, 27.812797],
                            [112.999368, 27.810777],
                            [112.97139, 27.801444],
                            [112.941797, 27.793154],
                            [112.932006, 27.79139],
                            [112.927716, 27.791353],
                            [112.924987, 27.790824],
                            [112.91966, 27.790361],
                            [112.913219, 27.790824],
                            [112.908744, 27.791787],
                            [112.905087, 27.793764],
                            [112.895874, 27.79849],
                            [112.887174, 27.799629],
                            [112.884664, 27.799313],
                            [112.881236, 27.799732],
                            [112.878562, 27.800841],
                            [112.876859, 27.802649],
                            [112.876084, 27.804744],
                            [112.875243, 27.808425],
                            [112.874031, 27.811901],
                            [112.872187, 27.814752],
                            [112.869872, 27.817331],
                            [112.864163, 27.820366],
                            [112.862974, 27.821578],
                            [112.855453, 27.8203],
                            [112.842506, 27.821071],
                            [112.825729, 27.83085],
                            [112.814223, 27.834781],
                            [112.801659, 27.837183],
                            [112.788003, 27.844419],
                            [112.781858, 27.844133],
                            [112.777622, 27.84367],
                            [112.773234, 27.840012],
                            [112.77045, 27.834737],
                            [112.765844, 27.831093],
                            [112.761161, 27.828014],
                            [112.755681, 27.824951],
                            [112.752516, 27.821123],
                            [112.750987, 27.824664],
                            [112.745519, 27.830417],
                            [112.743892, 27.833392],
                            [112.743859, 27.836603],
                            [112.745322, 27.839446],
                            [112.749361, 27.841349],
                            [112.751555, 27.843846],
                            [112.751086, 27.845183],
                            [112.735072, 27.851324],
                            [112.733675, 27.85914],
                            [112.728217, 27.864223],
                            [112.723992, 27.869753],
                            [112.721111, 27.875915],
                            [112.721307, 27.881768],
                            [112.724757, 27.890396],
                            [112.729745, 27.892312],
                            [112.727835, 27.904016],
                            [112.724833, 27.910477],
                            [112.721493, 27.915491]
                        ]
                    ],
                    [
                        [
                            [112.647396, 27.811879],
                            [112.656128, 27.8152],
                            [112.655419, 27.817743],
                            [112.655921, 27.819381],
                            [112.657307, 27.820263],
                            [112.659982, 27.819866],
                            [112.662056, 27.819896],
                            [112.668179, 27.822857],
                            [112.6683, 27.825289],
                            [112.670046, 27.826112],
                            [112.673321, 27.825715],
                            [112.674358, 27.824209],
                            [112.678571, 27.829205],
                            [112.673332, 27.834311],
                            [112.673113, 27.835207],
                            [112.67403, 27.836838],
                            [112.675777, 27.837911],
                            [112.678659, 27.839013],
                            [112.680875, 27.839468],
                            [112.682588, 27.838234],
                            [112.683265, 27.83614],
                            [112.702248, 27.847637],
                            [112.703099, 27.848952],
                            [112.702837, 27.851332],
                            [112.703514, 27.852757],
                            [112.705206, 27.853895],
                            [112.706669, 27.856635],
                            [112.668965, 27.851736],
                            [112.669992, 27.849657],
                            [112.670472, 27.842539],
                            [112.669937, 27.838844],
                            [112.66866, 27.836632],
                            [112.666848, 27.835699],
                            [112.661357, 27.835119],
                            [112.659305, 27.834134],
                            [112.657635, 27.834046],
                            [112.655572, 27.835178],
                            [112.654567, 27.840188],
                            [112.652646, 27.846873],
                            [112.65233, 27.849628],
                            [112.652908, 27.850509],
                            [112.626568, 27.859669],
                            [112.625509, 27.855144],
                            [112.623031, 27.852375],
                            [112.619975, 27.850502],
                            [112.617202, 27.846939],
                            [112.614517, 27.84558],
                            [112.612377, 27.846946],
                            [112.611701, 27.848173],
                            [112.61002, 27.849378],
                            [112.607836, 27.849951],
                            [112.605424, 27.852044],
                            [112.602106, 27.852764],
                            [112.599278, 27.853719],
                            [112.594694, 27.854072],
                            [112.593362, 27.853932],
                            [112.592947, 27.852904],
                            [112.59358, 27.850663],
                            [112.592685, 27.843927],
                            [112.591823, 27.843288],
                            [112.590338, 27.843876],
                            [112.586911, 27.846175],
                            [112.5838, 27.847409],
                            [112.583363, 27.845308],
                            [112.583625, 27.842994],
                            [112.584509, 27.839005],
                            [112.585492, 27.833216],
                            [112.588057, 27.827735],
                            [112.591288, 27.823342],
                            [112.592718, 27.822298],
                            [112.594748, 27.820087],
                            [112.595851, 27.819433],
                            [112.600414, 27.817669],
                            [112.600959, 27.816156],
                            [112.600064, 27.813995],
                            [112.597979, 27.81146],
                            [112.596451, 27.809175],
                            [112.596233, 27.808036],
                            [112.596637, 27.804494],
                            [112.598034, 27.798085],
                            [112.598514, 27.796851],
                            [112.599802, 27.796086],
                            [112.601178, 27.79691],
                            [112.604092, 27.799401],
                            [112.609659, 27.804883],
                            [112.613982, 27.809726],
                            [112.61575, 27.812246],
                            [112.618621, 27.816824],
                            [112.622835, 27.820535],
                            [112.624745, 27.820572],
                            [112.62814, 27.817324],
                            [112.63041, 27.816016],
                            [112.634438, 27.815259],
                            [112.637659, 27.814032],
                            [112.639438, 27.813797],
                            [112.643313, 27.813995],
                            [112.644918, 27.813804],
                            [112.647396, 27.811879]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430381,
                "name": "湘乡市",
                "center": [112.525217, 27.734918],
                "centroid": [112.345921, 27.768158],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430300
                },
                "subFeatureIndex": 3,
                "acroutes": [100000, 430000, 430300]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.501668, 28.02911],
                            [112.498994, 28.030936],
                            [112.496582, 28.033334],
                            [112.49179, 28.036927],
                            [112.488482, 28.038746],
                            [112.486277, 28.040227],
                            [112.485415, 28.041884],
                            [112.484028, 28.045719],
                            [112.483155, 28.048982],
                            [112.480251, 28.056248],
                            [112.478942, 28.058418],
                            [112.477184, 28.05973],
                            [112.475448, 28.060185],
                            [112.473134, 28.06017],
                            [112.470809, 28.059701],
                            [112.468691, 28.058887],
                            [112.46569, 28.056123],
                            [112.459151, 28.051907],
                            [112.45735, 28.051724],
                            [112.455592, 28.052574],
                            [112.452088, 28.055764],
                            [112.449305, 28.056732],
                            [112.445299, 28.057391],
                            [112.444, 28.057325],
                            [112.440277, 28.055067],
                            [112.438738, 28.054371],
                            [112.436806, 28.054129],
                            [112.432538, 28.054107],
                            [112.430748, 28.053293],
                            [112.425661, 28.051482],
                            [112.423663, 28.050375],
                            [112.422899, 28.049341],
                            [112.422692, 28.044641],
                            [112.421928, 28.043944],
                            [112.41945, 28.045873],
                            [112.418293, 28.045756],
                            [112.416677, 28.044656],
                            [112.413304, 28.040674],
                            [112.411296, 28.034639],
                            [112.410335, 28.033026],
                            [112.410095, 28.031596],
                            [112.412256, 28.028399],
                            [112.412496, 28.025018],
                            [112.411874, 28.021519],
                            [112.415051, 28.015234],
                            [112.414865, 28.0142],
                            [112.412824, 28.012124],
                            [112.410663, 28.008552],
                            [112.409975, 28.006718],
                            [112.410051, 28.004826],
                            [112.409549, 28.002985],
                            [112.408534, 28.001716],
                            [112.407115, 28.001136],
                            [112.402192, 28.002413],
                            [112.399987, 28.002684],
                            [112.398579, 28.002383],
                            [112.397935, 28.000828],
                            [112.398142, 27.99686],
                            [112.399081, 27.995979],
                            [112.403414, 27.995774],
                            [112.407606, 27.996273],
                            [112.409211, 27.995671],
                            [112.410717, 27.99273],
                            [112.411743, 27.991424],
                            [112.415378, 27.992135],
                            [112.41588, 27.991086],
                            [112.414778, 27.986413],
                            [112.411831, 27.978959],
                            [112.411536, 27.974586],
                            [112.41254, 27.972466],
                            [112.412682, 27.971108],
                            [112.411601, 27.968129],
                            [112.411296, 27.96325],
                            [112.410226, 27.962046],
                            [112.409833, 27.962839],
                            [112.409746, 27.968225],
                            [112.408861, 27.969311],
                            [112.406449, 27.969766],
                            [112.406111, 27.968401],
                            [112.407421, 27.963507],
                            [112.406111, 27.963088],
                            [112.404124, 27.965693],
                            [112.402356, 27.966361],
                            [112.400806, 27.96573],
                            [112.398928, 27.963807],
                            [112.397018, 27.963],
                            [112.393852, 27.962531],
                            [112.392324, 27.962817],
                            [112.390523, 27.961841],
                            [112.389802, 27.962134],
                            [112.389016, 27.964365],
                            [112.387728, 27.964666],
                            [112.386014, 27.964108],
                            [112.384726, 27.964262],
                            [112.382751, 27.967946],
                            [112.38024, 27.968254],
                            [112.377478, 27.96763],
                            [112.374968, 27.967652],
                            [112.371114, 27.970059],
                            [112.369324, 27.969927],
                            [112.365023, 27.968958],
                            [112.356727, 27.966295],
                            [112.354042, 27.965671],
                            [112.353136, 27.96482],
                            [112.353027, 27.961804],
                            [112.352612, 27.961158],
                            [112.347743, 27.95815],
                            [112.344414, 27.957313],
                            [112.342635, 27.957181],
                            [112.339076, 27.957856],
                            [112.337384, 27.958729],
                            [112.333553, 27.963213],
                            [112.332341, 27.963727],
                            [112.328215, 27.963976],
                            [112.325944, 27.963558],
                            [112.323739, 27.962714],
                            [112.318107, 27.959243],
                            [112.315585, 27.958399],
                            [112.31302, 27.959184],
                            [112.310837, 27.961665],
                            [112.309221, 27.967513],
                            [112.307398, 27.971035],
                            [112.306656, 27.971637],
                            [112.305051, 27.971798],
                            [112.298469, 27.971255],
                            [112.29467, 27.970389],
                            [112.289081, 27.968195],
                            [112.274967, 27.964204],
                            [112.266223, 27.961232],
                            [112.263484, 27.959962],
                            [112.260525, 27.959294],
                            [112.257676, 27.959148],
                            [112.256858, 27.959507],
                            [112.255318, 27.961408],
                            [112.253812, 27.961782],
                            [112.250996, 27.961298],
                            [112.248736, 27.961914],
                            [112.246302, 27.963499],
                            [112.245287, 27.963697],
                            [112.234262, 27.962406],
                            [112.231435, 27.961745],
                            [112.230354, 27.961833],
                            [112.228695, 27.963514],
                            [112.227931, 27.967425],
                            [112.226588, 27.969215],
                            [112.223815, 27.971373],
                            [112.222418, 27.971351],
                            [112.220792, 27.970382],
                            [112.220399, 27.967909],
                            [112.221708, 27.965165],
                            [112.221414, 27.963529],
                            [112.219754, 27.962699],
                            [112.217178, 27.958803],
                            [112.215574, 27.95691],
                            [112.214515, 27.955134],
                            [112.213401, 27.954158],
                            [112.211109, 27.953732],
                            [112.209166, 27.954957],
                            [112.207158, 27.957775],
                            [112.205116, 27.959214],
                            [112.20278, 27.959008],
                            [112.20075, 27.959896],
                            [112.200466, 27.961415],
                            [112.199407, 27.961841],
                            [112.19884, 27.963881],
                            [112.199396, 27.968672],
                            [112.198185, 27.970426],
                            [112.19682, 27.969612],
                            [112.195139, 27.966904],
                            [112.193065, 27.96515],
                            [112.192738, 27.961635],
                            [112.192901, 27.959984],
                            [112.188742, 27.952947],
                            [112.183044, 27.94709],
                            [112.183066, 27.944551],
                            [112.182619, 27.939516],
                            [112.18204, 27.938415],
                            [112.180905, 27.938114],
                            [112.177958, 27.93876],
                            [112.173438, 27.940727],
                            [112.172511, 27.939722],
                            [112.171812, 27.93716],
                            [112.172794, 27.934716],
                            [112.171539, 27.933263],
                            [112.169847, 27.934591],
                            [112.168821, 27.934804],
                            [112.16631, 27.93382],
                            [112.161049, 27.930136],
                            [112.158877, 27.928337],
                            [112.15749, 27.926231],
                            [112.156781, 27.924014],
                            [112.157217, 27.922083],
                            [112.160361, 27.918919],
                            [112.160678, 27.916893],
                            [112.159881, 27.916196],
                            [112.157228, 27.915381],
                            [112.153375, 27.913538],
                            [112.152425, 27.909897],
                            [112.149565, 27.90801],
                            [112.146956, 27.907628],
                            [112.143321, 27.908267],
                            [112.141269, 27.907584],
                            [112.139555, 27.906388],
                            [112.137612, 27.905697],
                            [112.136488, 27.906211],
                            [112.135822, 27.90743],
                            [112.135964, 27.910763],
                            [112.135746, 27.911879],
                            [112.134174, 27.91301],
                            [112.130757, 27.913142],
                            [112.129043, 27.91265],
                            [112.127668, 27.911248],
                            [112.121359, 27.90315],
                            [112.119077, 27.89862],
                            [112.11828, 27.897628],
                            [112.111196, 27.896343],
                            [112.105214, 27.894119],
                            [112.101131, 27.893597],
                            [112.097136, 27.89392],
                            [112.094888, 27.894456],
                            [112.093097, 27.89591],
                            [112.090423, 27.90149],
                            [112.089539, 27.904332],
                            [112.088993, 27.907063],
                            [112.087749, 27.908384],
                            [112.084812, 27.909721],
                            [112.071298, 27.915168],
                            [112.068187, 27.91599],
                            [112.067227, 27.916651],
                            [112.064214, 27.91751],
                            [112.062151, 27.919763],
                            [112.060197, 27.920035],
                            [112.057359, 27.918259],
                            [112.055918, 27.9182],
                            [112.053975, 27.919881],
                            [112.052174, 27.920013],
                            [112.051224, 27.919184],
                            [112.04963, 27.915513],
                            [112.048604, 27.914493],
                            [112.045919, 27.914059],
                            [112.044096, 27.912143],
                            [112.043004, 27.912019],
                            [112.03997, 27.913267],
                            [112.036957, 27.91359],
                            [112.035352, 27.913149],
                            [112.034021, 27.909882],
                            [112.032416, 27.908414],
                            [112.031117, 27.908869],
                            [112.031521, 27.913039],
                            [112.030517, 27.91439],
                            [112.028486, 27.913949],
                            [112.026292, 27.911974],
                            [112.023694, 27.911857],
                            [112.021871, 27.91135],
                            [112.01888, 27.909251],
                            [112.017548, 27.906307],
                            [112.016173, 27.9058],
                            [112.014088, 27.906578],
                            [112.01352, 27.908693],
                            [112.012724, 27.909148],
                            [112.009241, 27.907687],
                            [112.007571, 27.907709],
                            [112.006305, 27.908296],
                            [112.002299, 27.908436],
                            [111.999701, 27.907841],
                            [112.000421, 27.905484],
                            [112.000476, 27.903062],
                            [112.001371, 27.901006],
                            [112.002474, 27.899625],
                            [112.003391, 27.896057],
                            [112.006381, 27.889449],
                            [112.008728, 27.885057],
                            [112.010311, 27.88248],
                            [112.012298, 27.878661],
                            [112.014263, 27.871677],
                            [112.014055, 27.869422],
                            [112.012451, 27.867807],
                            [112.012789, 27.865912],
                            [112.013259, 27.853521],
                            [112.014809, 27.847101],
                            [112.018422, 27.842054],
                            [112.021009, 27.839534],
                            [112.022821, 27.837249],
                            [112.024884, 27.835868],
                            [112.026958, 27.835185],
                            [112.029818, 27.836559],
                            [112.031117, 27.839321],
                            [112.031226, 27.840225],
                            [112.040526, 27.839505],
                            [112.040712, 27.835192],
                            [112.043277, 27.828081],
                            [112.045329, 27.821431],
                            [112.046869, 27.817067],
                            [112.051257, 27.813628],
                            [112.056442, 27.811563],
                            [112.063155, 27.806515],
                            [112.067041, 27.80445],
                            [112.069879, 27.803987],
                            [112.076887, 27.806044],
                            [112.080784, 27.809483],
                            [112.087006, 27.813834],
                            [112.091929, 27.814282],
                            [112.096569, 27.811526],
                            [112.101219, 27.807382],
                            [112.104035, 27.800261],
                            [112.106502, 27.790648],
                            [112.104756, 27.787752],
                            [112.102911, 27.783393],
                            [112.098697, 27.778248],
                            [112.097769, 27.776528],
                            [112.097748, 27.769824],
                            [112.097093, 27.768303],
                            [112.097999, 27.765414],
                            [112.098686, 27.765009],
                            [112.106065, 27.764186],
                            [112.108456, 27.76362],
                            [112.112659, 27.761995],
                            [112.114875, 27.757819],
                            [112.117342, 27.755724],
                            [112.125703, 27.752989],
                            [112.130779, 27.747306],
                            [112.130168, 27.746136],
                            [112.128301, 27.745173],
                            [112.124208, 27.743975],
                            [112.118739, 27.743387],
                            [112.111982, 27.739953],
                            [112.110923, 27.738717],
                            [112.110235, 27.733033],
                            [112.109318, 27.723392],
                            [112.108358, 27.72012],
                            [112.106393, 27.717134],
                            [112.105159, 27.716773],
                            [112.103424, 27.717428],
                            [112.101906, 27.718899],
                            [112.100717, 27.722274],
                            [112.102387, 27.7259],
                            [112.101983, 27.726996],
                            [112.101721, 27.731651],
                            [112.100793, 27.733364],
                            [112.099134, 27.73468],
                            [112.094091, 27.735203],
                            [112.089703, 27.734497],
                            [112.087159, 27.733732],
                            [112.085806, 27.732805],
                            [112.085194, 27.731673],
                            [112.085576, 27.728849],
                            [112.084638, 27.727216],
                            [112.082673, 27.725591],
                            [112.081745, 27.723959],
                            [112.081821, 27.721774],
                            [112.0855, 27.721112],
                            [112.091318, 27.719524],
                            [112.092333, 27.717972],
                            [112.092104, 27.716332],
                            [112.08872, 27.715354],
                            [112.085751, 27.715008],
                            [112.083295, 27.715207],
                            [112.081068, 27.718413],
                            [112.078721, 27.719517],
                            [112.075556, 27.720178],
                            [112.071768, 27.720656],
                            [112.068176, 27.719862],
                            [112.066725, 27.718685],
                            [112.060972, 27.716442],
                            [112.053462, 27.714118],
                            [112.051289, 27.712669],
                            [112.04534, 27.706329],
                            [112.044707, 27.704873],
                            [112.044784, 27.702512],
                            [112.045602, 27.701776],
                            [112.049401, 27.70185],
                            [112.050307, 27.701114],
                            [112.050296, 27.69929],
                            [112.049248, 27.697024],
                            [112.049128, 27.69584],
                            [112.049739, 27.694744],
                            [112.052905, 27.693089],
                            [112.053702, 27.691537],
                            [112.052981, 27.690536],
                            [112.04879, 27.688322],
                            [112.047524, 27.686122],
                            [112.047109, 27.681627],
                            [112.048244, 27.677595],
                            [112.047272, 27.676367],
                            [112.047262, 27.674903],
                            [112.048157, 27.674042],
                            [112.049554, 27.674792],
                            [112.049215, 27.678015],
                            [112.050449, 27.679574],
                            [112.051246, 27.67928],
                            [112.051813, 27.677198],
                            [112.052687, 27.676109],
                            [112.055034, 27.676374],
                            [112.056529, 27.677169],
                            [112.057042, 27.679633],
                            [112.058057, 27.680384],
                            [112.06012, 27.678478],
                            [112.062566, 27.678412],
                            [112.062882, 27.678037],
                            [112.06262, 27.672467],
                            [112.064007, 27.670613],
                            [112.065098, 27.670319],
                            [112.067827, 27.671467],
                            [112.070174, 27.670981],
                            [112.07096, 27.669605],
                            [112.0706, 27.666147],
                            [112.068209, 27.66143],
                            [112.066987, 27.65845],
                            [112.067401, 27.656934],
                            [112.070643, 27.656772],
                            [112.071975, 27.656242],
                            [112.073754, 27.653343],
                            [112.075545, 27.652004],
                            [112.07692, 27.650385],
                            [112.077782, 27.64839],
                            [112.078044, 27.645756],
                            [112.084135, 27.644343],
                            [112.089441, 27.64251],
                            [112.091263, 27.642348],
                            [112.092672, 27.643268],
                            [112.095139, 27.646374],
                            [112.101786, 27.651643],
                            [112.104264, 27.653358],
                            [112.105825, 27.653748],
                            [112.109318, 27.651989],
                            [112.110879, 27.652438],
                            [112.112582, 27.653983],
                            [112.113685, 27.653453],
                            [112.114503, 27.647897],
                            [112.115464, 27.64644],
                            [112.117211, 27.645439],
                            [112.119667, 27.644843],
                            [112.121413, 27.644828],
                            [112.124874, 27.646124],
                            [112.134589, 27.654712],
                            [112.135517, 27.65586],
                            [112.1358, 27.658001],
                            [112.134327, 27.660268],
                            [112.1346, 27.661599],
                            [112.136695, 27.663777],
                            [112.137951, 27.66625],
                            [112.140101, 27.666986],
                            [112.142175, 27.666735],
                            [112.145788, 27.665139],
                            [112.147011, 27.663851],
                            [112.150133, 27.658906],
                            [112.150362, 27.65572],
                            [112.150821, 27.653343],
                            [112.151781, 27.651945],
                            [112.15487, 27.649487],
                            [112.156617, 27.649009],
                            [112.158243, 27.649399],
                            [112.159411, 27.650252],
                            [112.160285, 27.652901],
                            [112.160579, 27.656544],
                            [112.162555, 27.659068],
                            [112.165481, 27.660429],
                            [112.166649, 27.660069],
                            [112.169694, 27.656566],
                            [112.172347, 27.656191],
                            [112.175763, 27.654307],
                            [112.176451, 27.652622],
                            [112.175589, 27.650951],
                            [112.176102, 27.650193],
                            [112.179289, 27.65051],
                            [112.186647, 27.650723],
                            [112.189376, 27.651386],
                            [112.195226, 27.653586],
                            [112.199385, 27.654292],
                            [112.201001, 27.653873],
                            [112.204898, 27.651628],
                            [112.206852, 27.651614],
                            [112.205673, 27.656654],
                            [112.205946, 27.65856],
                            [112.207867, 27.665256],
                            [112.207878, 27.666353],
                            [112.20623, 27.66909],
                            [112.207081, 27.670525],
                            [112.211131, 27.672681],
                            [112.2141, 27.673865],
                            [112.215868, 27.676161],
                            [112.216578, 27.675866],
                            [112.218084, 27.670642],
                            [112.218794, 27.67023],
                            [112.22113, 27.670613],
                            [112.222636, 27.671525],
                            [112.227024, 27.675705],
                            [112.229917, 27.678743],
                            [112.230583, 27.680119],
                            [112.231183, 27.684401],
                            [112.230616, 27.68585],
                            [112.232122, 27.687571],
                            [112.233498, 27.688542],
                            [112.235845, 27.689035],
                            [112.237711, 27.687807],
                            [112.23877, 27.687674],
                            [112.239774, 27.688815],
                            [112.240866, 27.691095],
                            [112.241182, 27.696024],
                            [112.242765, 27.699437],
                            [112.243136, 27.701202],
                            [112.243355, 27.707749],
                            [112.244086, 27.710007],
                            [112.245079, 27.710566],
                            [112.24687, 27.709823],
                            [112.248114, 27.710161],
                            [112.252666, 27.70602],
                            [112.256607, 27.704159],
                            [112.259576, 27.703696],
                            [112.263582, 27.704387],
                            [112.268505, 27.700916],
                            [112.27393, 27.700901],
                            [112.280141, 27.702034],
                            [112.284289, 27.70363],
                            [112.290511, 27.705675],
                            [112.295423, 27.704284],
                            [112.304189, 27.697841],
                            [112.309068, 27.691176],
                            [112.31576, 27.685887],
                            [112.319089, 27.679906],
                            [112.320617, 27.675315],
                            [112.319548, 27.670267],
                            [112.317452, 27.666831],
                            [112.315116, 27.664315],
                            [112.31457, 27.659723],
                            [112.314537, 27.653755],
                            [112.313478, 27.651003],
                            [112.309854, 27.649406],
                            [112.30682, 27.64973],
                            [112.296395, 27.647831],
                            [112.288885, 27.646933],
                            [112.283449, 27.64488],
                            [112.281877, 27.643276],
                            [112.282117, 27.638455],
                            [112.283896, 27.633634],
                            [112.290315, 27.626958],
                            [112.298043, 27.620974],
                            [112.307846, 27.615674],
                            [112.314297, 27.611985],
                            [112.319438, 27.608761],
                            [112.324864, 27.606913],
                            [112.32744, 27.605301],
                            [112.33078, 27.602547],
                            [112.332319, 27.597953],
                            [112.331762, 27.591754],
                            [112.333531, 27.58601],
                            [112.334284, 27.581423],
                            [112.333727, 27.575914],
                            [112.332407, 27.569949],
                            [112.330289, 27.563984],
                            [112.325333, 27.556191],
                            [112.317255, 27.545879],
                            [112.315683, 27.543994],
                            [112.315531, 27.541555],
                            [112.314363, 27.538299],
                            [112.312987, 27.536649],
                            [112.30921, 27.535168],
                            [112.308588, 27.534572],
                            [112.305521, 27.529216],
                            [112.305466, 27.527669],
                            [112.306187, 27.52526],
                            [112.305728, 27.523793],
                            [112.3042, 27.521546],
                            [112.304331, 27.519351],
                            [112.304014, 27.518091],
                            [112.302792, 27.515902],
                            [112.303283, 27.513574],
                            [112.303534, 27.508983],
                            [112.302464, 27.50706],
                            [112.301318, 27.506102],
                            [112.300871, 27.503899],
                            [112.301427, 27.503206],
                            [112.303971, 27.503427],
                            [112.305248, 27.501931],
                            [112.306241, 27.497915],
                            [112.306263, 27.496021],
                            [112.305717, 27.494326],
                            [112.306394, 27.492816],
                            [112.310236, 27.491283],
                            [112.311448, 27.491224],
                            [112.314319, 27.492049],
                            [112.315869, 27.492889],
                            [112.319548, 27.495734],
                            [112.321622, 27.496471],
                            [112.324001, 27.496073],
                            [112.32601, 27.495225],
                            [112.327156, 27.493788],
                            [112.326774, 27.493228],
                            [112.32411, 27.49188],
                            [112.3241, 27.490376],
                            [112.324984, 27.489234],
                            [112.326414, 27.489131],
                            [112.328717, 27.490509],
                            [112.332134, 27.494363],
                            [112.333869, 27.495734],
                            [112.335212, 27.495432],
                            [112.337286, 27.493995],
                            [112.33888, 27.493582],
                            [112.340244, 27.49412],
                            [112.342154, 27.496331],
                            [112.344469, 27.497657],
                            [112.346084, 27.496264],
                            [112.347514, 27.492381],
                            [112.348104, 27.48818],
                            [112.349086, 27.487775],
                            [112.352088, 27.488954],
                            [112.353551, 27.487627],
                            [112.355024, 27.487797],
                            [112.356531, 27.490199],
                            [112.357218, 27.489536],
                            [112.357851, 27.48734],
                            [112.356476, 27.485563],
                            [112.356585, 27.485055],
                            [112.360384, 27.482357],
                            [112.363255, 27.482188],
                            [112.36582, 27.480928],
                            [112.36832, 27.481311],
                            [112.369608, 27.482173],
                            [112.370699, 27.484266],
                            [112.371704, 27.485306],
                            [112.373756, 27.486573],
                            [112.375, 27.486883],
                            [112.378755, 27.486684],
                            [112.381572, 27.487752],
                            [112.382554, 27.48975],
                            [112.384192, 27.490929],
                            [112.388896, 27.490936],
                            [112.393459, 27.492543],
                            [112.394147, 27.493287],
                            [112.394354, 27.496235],
                            [112.394038, 27.497635],
                            [112.391931, 27.499706],
                            [112.392946, 27.502189],
                            [112.395424, 27.503516],
                            [112.399681, 27.503427],
                            [112.401199, 27.503759],
                            [112.402727, 27.505144],
                            [112.402814, 27.507193],
                            [112.402214, 27.508247],
                            [112.399605, 27.510273],
                            [112.399725, 27.511172],
                            [112.401078, 27.511555],
                            [112.401843, 27.512594],
                            [112.40146, 27.513604],
                            [112.402323, 27.514738],
                            [112.404462, 27.515615],
                            [112.40622, 27.517597],
                            [112.407202, 27.51969],
                            [112.407279, 27.520883],
                            [112.406001, 27.524095],
                            [112.406787, 27.524736],
                            [112.410608, 27.525097],
                            [112.41302, 27.525871],
                            [112.417583, 27.527706],
                            [112.419941, 27.527816],
                            [112.421284, 27.527433],
                            [112.422332, 27.529827],
                            [112.42434, 27.530115],
                            [112.425978, 27.530969],
                            [112.426862, 27.532023],
                            [112.429307, 27.537032],
                            [112.429132, 27.539647],
                            [112.428499, 27.541202],
                            [112.428412, 27.542815],
                            [112.430093, 27.547021],
                            [112.429634, 27.55119],
                            [112.428717, 27.556295],
                            [112.428455, 27.562982],
                            [112.430519, 27.566304],
                            [112.435835, 27.568278],
                            [112.440594, 27.566156],
                            [112.445626, 27.56447],
                            [112.449971, 27.563969],
                            [112.453966, 27.56556],
                            [112.457055, 27.568823],
                            [112.460646, 27.57182],
                            [112.465679, 27.573573],
                            [112.470394, 27.574434],
                            [112.476627, 27.575193],
                            [112.4831, 27.575473],
                            [112.486757, 27.575936],
                            [112.492084, 27.581577],
                            [112.494759, 27.586238],
                            [112.499976, 27.590274],
                            [112.504561, 27.592976],
                            [112.507956, 27.593447],
                            [112.510772, 27.593042],
                            [112.513436, 27.590811],
                            [112.516143, 27.585811],
                            [112.519832, 27.583507],
                            [112.52517, 27.582483],
                            [112.530093, 27.58263],
                            [112.536774, 27.582446],
                            [112.541402, 27.58537],
                            [112.544819, 27.586305],
                            [112.5471, 27.585701],
                            [112.548487, 27.583551],
                            [112.549273, 27.580281],
                            [112.549469, 27.576135],
                            [112.548945, 27.569279],
                            [112.55103, 27.566945],
                            [112.551849, 27.565037],
                            [112.55246, 27.561244],
                            [112.553355, 27.558497],
                            [112.555113, 27.55684],
                            [112.557678, 27.555646],
                            [112.55913, 27.555565],
                            [112.560429, 27.556781],
                            [112.562164, 27.564382],
                            [112.562437, 27.568454],
                            [112.563529, 27.570281],
                            [112.564271, 27.570693],
                            [112.567841, 27.567887],
                            [112.5695, 27.567239],
                            [112.571519, 27.567335],
                            [112.573888, 27.568572],
                            [112.577163, 27.571525],
                            [112.579717, 27.570951],
                            [112.58083, 27.569456],
                            [112.581988, 27.56908],
                            [112.584837, 27.568918],
                            [112.586845, 27.569412],
                            [112.589137, 27.571606],
                            [112.589574, 27.573816],
                            [112.591091, 27.578595],
                            [112.592521, 27.579641],
                            [112.594181, 27.57939],
                            [112.599497, 27.577417],
                            [112.601658, 27.576953],
                            [112.607902, 27.576025],
                            [112.60955, 27.576113],
                            [112.61396, 27.577453],
                            [112.61741, 27.578831],
                            [112.618392, 27.579758],
                            [112.618359, 27.581224],
                            [112.617126, 27.584243],
                            [112.616143, 27.587667],
                            [112.615663, 27.590465],
                            [112.615761, 27.594139],
                            [112.61491, 27.599934],
                            [112.60943, 27.606007],
                            [112.601887, 27.612434],
                            [112.595938, 27.619008],
                            [112.589574, 27.623756],
                            [112.586408, 27.629468],
                            [112.584979, 27.634613],
                            [112.58476, 27.641752],
                            [112.585906, 27.649958],
                            [112.589137, 27.654815],
                            [112.596484, 27.656206],
                            [112.606843, 27.659488],
                            [112.610423, 27.662262],
                            [112.611133, 27.664741],
                            [112.609703, 27.672872],
                            [112.607782, 27.67828],
                            [112.601854, 27.685298],
                            [112.597379, 27.691088],
                            [112.591877, 27.696936],
                            [112.589105, 27.701239],
                            [112.58893, 27.705616],
                            [112.590491, 27.70897],
                            [112.594006, 27.711059],
                            [112.598241, 27.712177],
                            [112.604376, 27.711117],
                            [112.613447, 27.707815],
                            [112.622889, 27.705873],
                            [112.627845, 27.706263],
                            [112.630607, 27.708403],
                            [112.633172, 27.711235],
                            [112.636109, 27.715655],
                            [112.637277, 27.720642],
                            [112.636545, 27.724598],
                            [112.634187, 27.727724],
                            [112.627245, 27.731813],
                            [112.624101, 27.734761],
                            [112.6217, 27.740416],
                            [112.619364, 27.747217],
                            [112.618741, 27.75254],
                            [112.619669, 27.757775],
                            [112.623544, 27.771184],
                            [112.625171, 27.772713],
                            [112.628435, 27.774338],
                            [112.631786, 27.775727],
                            [112.635246, 27.776741],
                            [112.639209, 27.777498],
                            [112.643564, 27.777917],
                            [112.64983, 27.777793],
                            [112.655321, 27.776609],
                            [112.655954, 27.778101],
                            [112.654425, 27.78038],
                            [112.654022, 27.781556],
                            [112.653399, 27.787634],
                            [112.651904, 27.790986],
                            [112.651042, 27.794697],
                            [112.647854, 27.797328],
                            [112.645147, 27.79874],
                            [112.64435, 27.79979],
                            [112.642516, 27.803818],
                            [112.642614, 27.804891],
                            [112.643717, 27.806213],
                            [112.64673, 27.808205],
                            [112.648334, 27.810049],
                            [112.647396, 27.811879],
                            [112.644918, 27.813804],
                            [112.643313, 27.813995],
                            [112.639438, 27.813797],
                            [112.637659, 27.814032],
                            [112.634438, 27.815259],
                            [112.63041, 27.816016],
                            [112.62814, 27.817324],
                            [112.624745, 27.820572],
                            [112.622835, 27.820535],
                            [112.618621, 27.816824],
                            [112.61575, 27.812246],
                            [112.613982, 27.809726],
                            [112.609659, 27.804883],
                            [112.604092, 27.799401],
                            [112.601178, 27.79691],
                            [112.599802, 27.796086],
                            [112.598514, 27.796851],
                            [112.598034, 27.798085],
                            [112.596637, 27.804494],
                            [112.596233, 27.808036],
                            [112.596451, 27.809175],
                            [112.597979, 27.81146],
                            [112.600064, 27.813995],
                            [112.600959, 27.816156],
                            [112.600414, 27.817669],
                            [112.595851, 27.819433],
                            [112.594748, 27.820087],
                            [112.592718, 27.822298],
                            [112.591288, 27.823342],
                            [112.588057, 27.827735],
                            [112.585492, 27.833216],
                            [112.584509, 27.839005],
                            [112.583625, 27.842994],
                            [112.583363, 27.845308],
                            [112.5838, 27.847409],
                            [112.580438, 27.848834],
                            [112.579149, 27.850303],
                            [112.57665, 27.851376],
                            [112.572556, 27.852081],
                            [112.569795, 27.853168],
                            [112.566061, 27.85524],
                            [112.564173, 27.857656],
                            [112.562634, 27.861196],
                            [112.560047, 27.864575],
                            [112.556564, 27.866404],
                            [112.549338, 27.866617],
                            [112.548083, 27.867359],
                            [112.547821, 27.866095],
                            [112.545889, 27.865412],
                            [112.545943, 27.864553],
                            [112.539645, 27.864142],
                            [112.533805, 27.860873],
                            [112.52254, 27.864957],
                            [112.509102, 27.872279],
                            [112.501767, 27.879028],
                            [112.500228, 27.88165],
                            [112.489923, 27.874365],
                            [112.481343, 27.872544],
                            [112.482347, 27.857612],
                            [112.491233, 27.855034],
                            [112.477763, 27.850362],
                            [112.475765, 27.839916],
                            [112.465635, 27.837485],
                            [112.449327, 27.849782],
                            [112.4415, 27.85853],
                            [112.440332, 27.860264],
                            [112.440081, 27.862555],
                            [112.438553, 27.865354],
                            [112.435453, 27.866705],
                            [112.432243, 27.870825],
                            [112.429514, 27.873719],
                            [112.427451, 27.877097],
                            [112.427604, 27.885726],
                            [112.426873, 27.887077],
                            [112.425803, 27.887532],
                            [112.423783, 27.887165],
                            [112.421808, 27.885953],
                            [112.419581, 27.884022],
                            [112.415335, 27.882957],
                            [112.413381, 27.881672],
                            [112.411066, 27.880879],
                            [112.408763, 27.880813],
                            [112.406908, 27.886401],
                            [112.404921, 27.889691],
                            [112.404582, 27.891901],
                            [112.408872, 27.895947],
                            [112.410739, 27.898465],
                            [112.410859, 27.900264],
                            [112.410433, 27.902562],
                            [112.408076, 27.90569],
                            [112.407901, 27.906755],
                            [112.408654, 27.907731],
                            [112.410881, 27.908685],
                            [112.413828, 27.909398],
                            [112.41611, 27.909368],
                            [112.419122, 27.910389],
                            [112.422648, 27.911248],
                            [112.425355, 27.912415],
                            [112.426731, 27.913788],
                            [112.428281, 27.916174],
                            [112.430246, 27.920035],
                            [112.433149, 27.923683],
                            [112.434274, 27.923874],
                            [112.435551, 27.92292],
                            [112.437232, 27.925673],
                            [112.436839, 27.926987],
                            [112.434732, 27.927376],
                            [112.432964, 27.926928],
                            [112.431261, 27.927523],
                            [112.432232, 27.93007],
                            [112.432844, 27.933512],
                            [112.432418, 27.936815],
                            [112.431708, 27.937292],
                            [112.429995, 27.936162],
                            [112.429067, 27.937476],
                            [112.429558, 27.943435],
                            [112.430289, 27.945307],
                            [112.430169, 27.947164],
                            [112.429034, 27.949035],
                            [112.429067, 27.949791],
                            [112.431621, 27.949505],
                            [112.432669, 27.950459],
                            [112.433215, 27.949769],
                            [112.432276, 27.947692],
                            [112.433597, 27.945087],
                            [112.434972, 27.945021],
                            [112.435878, 27.946422],
                            [112.4378, 27.948463],
                            [112.440026, 27.950085],
                            [112.441576, 27.951633],
                            [112.442548, 27.951567],
                            [112.442875, 27.949864],
                            [112.443738, 27.948521],
                            [112.445517, 27.949153],
                            [112.445059, 27.952308],
                            [112.4496, 27.956777],
                            [112.444884, 27.962068],
                            [112.452438, 27.964116],
                            [112.460679, 27.965099],
                            [112.46248, 27.963961],
                            [112.463168, 27.965855],
                            [112.465908, 27.965025],
                            [112.466814, 27.966126],
                            [112.466388, 27.966875],
                            [112.465275, 27.978482],
                            [112.465919, 27.983501],
                            [112.468604, 27.988159],
                            [112.472894, 27.993412],
                            [112.479629, 27.996955],
                            [112.492139, 28.006616],
                            [112.493099, 28.009696],
                            [112.491877, 28.013481],
                            [112.493078, 28.016811],
                            [112.494027, 28.017845],
                            [112.49621, 28.019114],
                            [112.497215, 28.021585],
                            [112.497127, 28.023588],
                            [112.497695, 28.025428],
                            [112.50038, 28.027614],
                            [112.501668, 28.02911]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430382,
                "name": "韶山市",
                "center": [112.52848, 27.922682],
                "centroid": [112.519403, 27.916867],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430300
                },
                "subFeatureIndex": 4,
                "acroutes": [100000, 430000, 430300]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.501668, 28.02911],
                            [112.50038, 28.027614],
                            [112.497695, 28.025428],
                            [112.497127, 28.023588],
                            [112.497215, 28.021585],
                            [112.49621, 28.019114],
                            [112.494027, 28.017845],
                            [112.493078, 28.016811],
                            [112.491877, 28.013481],
                            [112.493099, 28.009696],
                            [112.492139, 28.006616],
                            [112.479629, 27.996955],
                            [112.472894, 27.993412],
                            [112.468604, 27.988159],
                            [112.465919, 27.983501],
                            [112.465275, 27.978482],
                            [112.466388, 27.966875],
                            [112.466814, 27.966126],
                            [112.465908, 27.965025],
                            [112.463168, 27.965855],
                            [112.46248, 27.963961],
                            [112.460679, 27.965099],
                            [112.452438, 27.964116],
                            [112.444884, 27.962068],
                            [112.4496, 27.956777],
                            [112.445059, 27.952308],
                            [112.445517, 27.949153],
                            [112.443738, 27.948521],
                            [112.442875, 27.949864],
                            [112.442548, 27.951567],
                            [112.441576, 27.951633],
                            [112.440026, 27.950085],
                            [112.4378, 27.948463],
                            [112.435878, 27.946422],
                            [112.434972, 27.945021],
                            [112.433597, 27.945087],
                            [112.432276, 27.947692],
                            [112.433215, 27.949769],
                            [112.432669, 27.950459],
                            [112.431621, 27.949505],
                            [112.429067, 27.949791],
                            [112.429034, 27.949035],
                            [112.430169, 27.947164],
                            [112.430289, 27.945307],
                            [112.429558, 27.943435],
                            [112.429067, 27.937476],
                            [112.429995, 27.936162],
                            [112.431708, 27.937292],
                            [112.432418, 27.936815],
                            [112.432844, 27.933512],
                            [112.432232, 27.93007],
                            [112.431261, 27.927523],
                            [112.432964, 27.926928],
                            [112.434732, 27.927376],
                            [112.436839, 27.926987],
                            [112.437232, 27.925673],
                            [112.435551, 27.92292],
                            [112.434274, 27.923874],
                            [112.433149, 27.923683],
                            [112.430246, 27.920035],
                            [112.428281, 27.916174],
                            [112.426731, 27.913788],
                            [112.425355, 27.912415],
                            [112.422648, 27.911248],
                            [112.419122, 27.910389],
                            [112.41611, 27.909368],
                            [112.413828, 27.909398],
                            [112.410881, 27.908685],
                            [112.408654, 27.907731],
                            [112.407901, 27.906755],
                            [112.408076, 27.90569],
                            [112.410433, 27.902562],
                            [112.410859, 27.900264],
                            [112.410739, 27.898465],
                            [112.408872, 27.895947],
                            [112.404582, 27.891901],
                            [112.404921, 27.889691],
                            [112.406908, 27.886401],
                            [112.408763, 27.880813],
                            [112.411066, 27.880879],
                            [112.413381, 27.881672],
                            [112.415335, 27.882957],
                            [112.419581, 27.884022],
                            [112.421808, 27.885953],
                            [112.423783, 27.887165],
                            [112.425803, 27.887532],
                            [112.426873, 27.887077],
                            [112.427604, 27.885726],
                            [112.427451, 27.877097],
                            [112.429514, 27.873719],
                            [112.432243, 27.870825],
                            [112.435453, 27.866705],
                            [112.438553, 27.865354],
                            [112.440081, 27.862555],
                            [112.440332, 27.860264],
                            [112.4415, 27.85853],
                            [112.449327, 27.849782],
                            [112.465635, 27.837485],
                            [112.475765, 27.839916],
                            [112.477763, 27.850362],
                            [112.491233, 27.855034],
                            [112.482347, 27.857612],
                            [112.481343, 27.872544],
                            [112.489923, 27.874365],
                            [112.500228, 27.88165],
                            [112.501767, 27.879028],
                            [112.509102, 27.872279],
                            [112.52254, 27.864957],
                            [112.533805, 27.860873],
                            [112.539645, 27.864142],
                            [112.545943, 27.864553],
                            [112.545889, 27.865412],
                            [112.547821, 27.866095],
                            [112.548083, 27.867359],
                            [112.549338, 27.866617],
                            [112.556564, 27.866404],
                            [112.560047, 27.864575],
                            [112.562634, 27.861196],
                            [112.564173, 27.857656],
                            [112.566061, 27.85524],
                            [112.569795, 27.853168],
                            [112.572556, 27.852081],
                            [112.57665, 27.851376],
                            [112.579149, 27.850303],
                            [112.580438, 27.848834],
                            [112.5838, 27.847409],
                            [112.586911, 27.846175],
                            [112.590338, 27.843876],
                            [112.591823, 27.843288],
                            [112.592685, 27.843927],
                            [112.59358, 27.850663],
                            [112.592947, 27.852904],
                            [112.593362, 27.853932],
                            [112.594694, 27.854072],
                            [112.599278, 27.853719],
                            [112.602106, 27.852764],
                            [112.605424, 27.852044],
                            [112.607836, 27.849951],
                            [112.61002, 27.849378],
                            [112.611701, 27.848173],
                            [112.612377, 27.846946],
                            [112.614517, 27.84558],
                            [112.617202, 27.846939],
                            [112.619975, 27.850502],
                            [112.623031, 27.852375],
                            [112.625509, 27.855144],
                            [112.626568, 27.859669],
                            [112.627551, 27.861791],
                            [112.628937, 27.862335],
                            [112.635213, 27.862871],
                            [112.639449, 27.864303],
                            [112.640311, 27.870076],
                            [112.639416, 27.872279],
                            [112.639471, 27.874762],
                            [112.64066, 27.878008],
                            [112.640988, 27.879822],
                            [112.637822, 27.879697],
                            [112.63386, 27.880108],
                            [112.633139, 27.880996],
                            [112.632015, 27.884587],
                            [112.631677, 27.887576],
                            [112.630509, 27.890535],
                            [112.629177, 27.892481],
                            [112.625771, 27.896468],
                            [112.621809, 27.89881],
                            [112.61968, 27.89978],
                            [112.617508, 27.900235],
                            [112.613316, 27.900088],
                            [112.611646, 27.900279],
                            [112.60955, 27.903054],
                            [112.609943, 27.904471],
                            [112.61253, 27.907357],
                            [112.611406, 27.909133],
                            [112.608251, 27.910308],
                            [112.607727, 27.911027],
                            [112.607269, 27.91367],
                            [112.606963, 27.918016],
                            [112.606548, 27.918853],
                            [112.605337, 27.919279],
                            [112.602575, 27.917862],
                            [112.601156, 27.918677],
                            [112.598984, 27.921173],
                            [112.598612, 27.92325],
                            [112.598776, 27.9267],
                            [112.598361, 27.927427],
                            [112.594759, 27.931523],
                            [112.594333, 27.932815],
                            [112.59417, 27.936705],
                            [112.594628, 27.938584],
                            [112.598438, 27.945549],
                            [112.598907, 27.947083],
                            [112.599082, 27.949967],
                            [112.597783, 27.952594],
                            [112.596942, 27.953475],
                            [112.588166, 27.958032],
                            [112.585797, 27.958715],
                            [112.581289, 27.958172],
                            [112.577326, 27.958986],
                            [112.575558, 27.960645],
                            [112.575132, 27.962391],
                            [112.575547, 27.963925],
                            [112.575482, 27.966009],
                            [112.574292, 27.967851],
                            [112.570046, 27.96934],
                            [112.567731, 27.970653],
                            [112.566738, 27.972532],
                            [112.566629, 27.974021],
                            [112.564762, 27.978827],
                            [112.56331, 27.980947],
                            [112.561346, 27.980456],
                            [112.559075, 27.975217],
                            [112.556597, 27.974909],
                            [112.55389, 27.972627],
                            [112.550462, 27.97273],
                            [112.54531, 27.975115],
                            [112.54126, 27.975034],
                            [112.53947, 27.975401],
                            [112.537909, 27.976714],
                            [112.536326, 27.978886],
                            [112.535562, 27.981468],
                            [112.534394, 27.986531],
                            [112.534143, 27.990764],
                            [112.533379, 27.993118],
                            [112.53113, 27.994145],
                            [112.527201, 27.994123],
                            [112.522048, 27.994534],
                            [112.51968, 27.994938],
                            [112.517344, 27.994615],
                            [112.514647, 27.993434],
                            [112.512486, 27.993955],
                            [112.511798, 27.994681],
                            [112.511405, 27.997953],
                            [112.509441, 28.001268],
                            [112.508404, 28.006117],
                            [112.508098, 28.00889],
                            [112.508305, 28.010936],
                            [112.509026, 28.01332],
                            [112.511242, 28.016965],
                            [112.515543, 28.017772],
                            [112.516405, 28.018828],
                            [112.511067, 28.022847],
                            [112.507094, 28.025172],
                            [112.506046, 28.025524],
                            [112.503339, 28.027284],
                            [112.501668, 28.02911]
                        ]
                    ]
                ]
            }
        }]
    });
}));