/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

(function(root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['exports', 'echarts'], factory);
    } else if (typeof exports === 'object' && typeof exports.nodeName !== 'string') {
        // CommonJS
        factory(exports, require('echarts'));
    } else {
        // Browser globals
        factory({}, root.echarts);
    }
}(this, function(exports, echarts) {
    var log = function(msg) {
        if (typeof console !== 'undefined') {
            console && console.error && console.error(msg);
        }
    }
    if (!echarts) {
        log('ECharts is not Loaded');
        return;
    }
    if (!echarts.registerMap) {
        log('ECharts Map is not loaded')
        return;
    }
    echarts.registerMap('邵阳市', {
        "type": "FeatureCollection",
        "features": [{
            "type": "Feature",
            "properties": {
                "adcode": 430502,
                "name": "双清区",
                "center": [111.479756, 27.240001],
                "centroid": [111.546436, 27.241775],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430500
                },
                "subFeatureIndex": 0,
                "acroutes": [100000, 430000, 430500]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [111.600585, 27.286758],
                            [111.59138, 27.286775],
                            [111.588455, 27.284017],
                            [111.584828, 27.277775],
                            [111.580225, 27.275795],
                            [111.575192, 27.276573],
                            [111.562268, 27.276252],
                            [111.555421, 27.280295],
                            [111.55109, 27.284068],
                            [111.549435, 27.294218],
                            [111.5471, 27.295266],
                            [111.545082, 27.294556],
                            [111.544153, 27.285743],
                            [111.542747, 27.280583],
                            [111.540434, 27.278773],
                            [111.535673, 27.27725],
                            [111.526377, 27.276962],
                            [111.523634, 27.277876],
                            [111.524337, 27.283053],
                            [111.534154, 27.291799],
                            [111.537351, 27.296248],
                            [111.536784, 27.298683],
                            [111.532862, 27.300696],
                            [111.525266, 27.299901],
                            [111.51665, 27.298024],
                            [111.510688, 27.299918],
                            [111.50579, 27.299123],
                            [111.503772, 27.300865],
                            [111.500689, 27.300967],
                            [111.499102, 27.305923],
                            [111.491937, 27.302709],
                            [111.489919, 27.299766],
                            [111.492164, 27.297601],
                            [111.4867, 27.291748],
                            [111.485475, 27.289803],
                            [111.489534, 27.287637],
                            [111.491348, 27.285777],
                            [111.493502, 27.279314],
                            [111.491144, 27.273156],
                            [111.491937, 27.270906],
                            [111.495542, 27.267268],
                            [111.495724, 27.263698],
                            [111.493615, 27.259654],
                            [111.488332, 27.253664],
                            [111.485339, 27.252818],
                            [111.481644, 27.253427],
                            [111.478333, 27.25627],
                            [111.473754, 27.265322],
                            [111.469945, 27.267251],
                            [111.466068, 27.265745],
                            [111.464707, 27.262987],
                            [111.464684, 27.257471],
                            [111.4694, 27.249332],
                            [111.473867, 27.249146],
                            [111.474955, 27.246083],
                            [111.468539, 27.242139],
                            [111.473572, 27.234811],
                            [111.474638, 27.230377],
                            [111.476293, 27.229192],
                            [111.480805, 27.229108],
                            [111.481462, 27.227483],
                            [111.473708, 27.220746],
                            [111.473867, 27.218224],
                            [111.47686, 27.217699],
                            [111.474729, 27.214415],
                            [111.475227, 27.213214],
                            [111.479694, 27.209777],
                            [111.485022, 27.204309],
                            [111.486428, 27.20155],
                            [111.487901, 27.196217],
                            [111.492753, 27.195878],
                            [111.499306, 27.200686],
                            [111.502729, 27.206341],
                            [111.505382, 27.206815],
                            [111.508375, 27.203107],
                            [111.521593, 27.197249],
                            [111.519734, 27.192001],
                            [111.521979, 27.191899],
                            [111.523135, 27.193491],
                            [111.527783, 27.196471],
                            [111.529801, 27.200161],
                            [111.534267, 27.202785],
                            [111.539346, 27.199112],
                            [111.544311, 27.201668],
                            [111.54676, 27.20116],
                            [111.5488, 27.197216],
                            [111.549367, 27.192509],
                            [111.550342, 27.190596],
                            [111.554718, 27.190376],
                            [111.561543, 27.195167],
                            [111.565329, 27.197131],
                            [111.572811, 27.198028],
                            [111.577391, 27.197232],
                            [111.580724, 27.197436],
                            [111.584397, 27.199366],
                            [111.588478, 27.200229],
                            [111.598227, 27.199755],
                            [111.600812, 27.197571],
                            [111.602059, 27.193051],
                            [111.604235, 27.191713],
                            [111.608906, 27.192814],
                            [111.610743, 27.196606],
                            [111.614007, 27.197791],
                            [111.618293, 27.200923],
                            [111.618655, 27.20717],
                            [111.620016, 27.208981],
                            [111.61997, 27.219477],
                            [111.621059, 27.225469],
                            [111.62319, 27.228532],
                            [111.623757, 27.231562],
                            [111.622351, 27.235708],
                            [111.622532, 27.239347],
                            [111.624958, 27.240379],
                            [111.626251, 27.242512],
                            [111.627339, 27.247707],
                            [111.629538, 27.251447],
                            [111.626183, 27.254476],
                            [111.618383, 27.251802],
                            [111.614778, 27.248723],
                            [111.611831, 27.249281],
                            [111.611196, 27.25099],
                            [111.612942, 27.253596],
                            [111.612511, 27.256168],
                            [111.61403, 27.259451],
                            [111.609518, 27.260906],
                            [111.608838, 27.264747],
                            [111.603918, 27.267369],
                            [111.606571, 27.274188],
                            [111.604984, 27.283239],
                            [111.600585, 27.286758]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430503,
                "name": "大祥区",
                "center": [111.462968, 27.233593],
                "centroid": [111.485515, 27.159475],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430500
                },
                "subFeatureIndex": 1,
                "acroutes": [100000, 430000, 430500]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [111.468539, 27.242139],
                            [111.465909, 27.241056],
                            [111.458427, 27.239702],
                            [111.452668, 27.239296],
                            [111.446047, 27.242732],
                            [111.43988, 27.240566],
                            [111.433668, 27.239567],
                            [111.42741, 27.236741],
                            [111.42113, 27.229548],
                            [111.420609, 27.226789],
                            [111.423873, 27.22012],
                            [111.430653, 27.209523],
                            [111.430335, 27.202853],
                            [111.428091, 27.199433],
                            [111.422513, 27.194811],
                            [111.41757, 27.19432],
                            [111.409658, 27.198384],
                            [111.407821, 27.201414],
                            [111.407458, 27.206561],
                            [111.406302, 27.208829],
                            [111.40213, 27.209523],
                            [111.398208, 27.208744],
                            [111.395238, 27.207018],
                            [111.390499, 27.201685],
                            [111.386509, 27.194168],
                            [111.383471, 27.194083],
                            [111.38143, 27.188276],
                            [111.379571, 27.185736],
                            [111.379798, 27.183704],
                            [111.381952, 27.179251],
                            [111.382473, 27.173561],
                            [111.383652, 27.170327],
                            [111.387121, 27.166737],
                            [111.385942, 27.16252],
                            [111.389093, 27.160234],
                            [111.391905, 27.162554],
                            [111.394172, 27.162317],
                            [111.4034, 27.153442],
                            [111.408297, 27.151833],
                            [111.410361, 27.149377],
                            [111.410361, 27.145261],
                            [111.407889, 27.139485],
                            [111.408615, 27.138469],
                            [111.411902, 27.138808],
                            [111.414895, 27.136419],
                            [111.416233, 27.133353],
                            [111.41909, 27.131066],
                            [111.416006, 27.130541],
                            [111.41714, 27.12922],
                            [111.422853, 27.130355],
                            [111.428431, 27.12551],
                            [111.425325, 27.119936],
                            [111.421652, 27.118326],
                            [111.417684, 27.111702],
                            [111.41952, 27.107991],
                            [111.417117, 27.104535],
                            [111.420382, 27.103535],
                            [111.421584, 27.100892],
                            [111.420246, 27.098892],
                            [111.419226, 27.094808],
                            [111.414532, 27.095198],
                            [111.408207, 27.093927],
                            [111.40696, 27.092487],
                            [111.409091, 27.091199],
                            [111.410406, 27.087911],
                            [111.409544, 27.085657],
                            [111.411562, 27.085843],
                            [111.41426, 27.083776],
                            [111.418251, 27.083962],
                            [111.422354, 27.080386],
                            [111.42444, 27.077386],
                            [111.428295, 27.076573],
                            [111.429882, 27.077607],
                            [111.42834, 27.07998],
                            [111.431854, 27.083217],
                            [111.436207, 27.081115],
                            [111.439767, 27.086725],
                            [111.44505, 27.088131],
                            [111.449199, 27.087758],
                            [111.456386, 27.091809],
                            [111.457225, 27.094368],
                            [111.462395, 27.093639],
                            [111.465591, 27.099112],
                            [111.468131, 27.097926],
                            [111.468312, 27.100485],
                            [111.466589, 27.101247],
                            [111.467405, 27.105094],
                            [111.472915, 27.105178],
                            [111.471872, 27.100739],
                            [111.475771, 27.09696],
                            [111.479399, 27.095469],
                            [111.483367, 27.096554],
                            [111.484024, 27.098706],
                            [111.487403, 27.100146],
                            [111.490441, 27.104619],
                            [111.491983, 27.11038],
                            [111.490622, 27.117225],
                            [111.485861, 27.120004],
                            [111.486496, 27.126831],
                            [111.487675, 27.128508],
                            [111.484841, 27.132252],
                            [111.485022, 27.13647],
                            [111.482437, 27.136843],
                            [111.486224, 27.137893],
                            [111.487357, 27.140891],
                            [111.484795, 27.140214],
                            [111.486632, 27.142246],
                            [111.492957, 27.139875],
                            [111.493796, 27.142568],
                            [111.498081, 27.144702],
                            [111.503342, 27.144533],
                            [111.507581, 27.146633],
                            [111.512887, 27.146684],
                            [111.516197, 27.144245],
                            [111.516129, 27.140332],
                            [111.518034, 27.135132],
                            [111.521162, 27.130795],
                            [111.523203, 27.130643],
                            [111.522477, 27.128779],
                            [111.526763, 27.126797],
                            [111.529823, 27.127441],
                            [111.535945, 27.12512],
                            [111.533406, 27.122731],
                            [111.53531, 27.120004],
                            [111.540071, 27.118886],
                            [111.538371, 27.115124],
                            [111.537215, 27.110279],
                            [111.539527, 27.109516],
                            [111.542203, 27.110448],
                            [111.542928, 27.105314],
                            [111.544651, 27.102128],
                            [111.541659, 27.10101],
                            [111.539029, 27.095215],
                            [111.53989, 27.089995],
                            [111.548438, 27.085945],
                            [111.550614, 27.085488],
                            [111.559366, 27.089267],
                            [111.559253, 27.092148],
                            [111.561112, 27.095147],
                            [111.564603, 27.097011],
                            [111.567982, 27.095808],
                            [111.569841, 27.096876],
                            [111.568934, 27.100485],
                            [111.571405, 27.101298],
                            [111.573763, 27.099688],
                            [111.578139, 27.101061],
                            [111.580021, 27.100146],
                            [111.582764, 27.101129],
                            [111.58417, 27.103518],
                            [111.588773, 27.101891],
                            [111.588523, 27.104382],
                            [111.585961, 27.104348],
                            [111.586188, 27.105704],
                            [111.591335, 27.108584],
                            [111.595098, 27.108703],
                            [111.593625, 27.112905],
                            [111.594962, 27.11814],
                            [111.594554, 27.122393],
                            [111.592582, 27.122596],
                            [111.589475, 27.119563],
                            [111.586392, 27.120088],
                            [111.586052, 27.122223],
                            [111.590586, 27.126001],
                            [111.589952, 27.12778],
                            [111.583649, 27.132015],
                            [111.585961, 27.132777],
                            [111.586664, 27.135386],
                            [111.582923, 27.139045],
                            [111.577073, 27.141382],
                            [111.57535, 27.143618],
                            [111.574988, 27.148937],
                            [111.577346, 27.154865],
                            [111.583286, 27.16523],
                            [111.584828, 27.170733],
                            [111.586097, 27.178675],
                            [111.590927, 27.187311],
                            [111.593987, 27.190697],
                            [111.592967, 27.192577],
                            [111.595983, 27.195895],
                            [111.598227, 27.199755],
                            [111.588478, 27.200229],
                            [111.584397, 27.199366],
                            [111.580724, 27.197436],
                            [111.577391, 27.197232],
                            [111.572811, 27.198028],
                            [111.565329, 27.197131],
                            [111.561543, 27.195167],
                            [111.554718, 27.190376],
                            [111.550342, 27.190596],
                            [111.549367, 27.192509],
                            [111.5488, 27.197216],
                            [111.54676, 27.20116],
                            [111.544311, 27.201668],
                            [111.539346, 27.199112],
                            [111.534267, 27.202785],
                            [111.529801, 27.200161],
                            [111.527783, 27.196471],
                            [111.523135, 27.193491],
                            [111.521979, 27.191899],
                            [111.519734, 27.192001],
                            [111.521593, 27.197249],
                            [111.508375, 27.203107],
                            [111.505382, 27.206815],
                            [111.502729, 27.206341],
                            [111.499306, 27.200686],
                            [111.492753, 27.195878],
                            [111.487901, 27.196217],
                            [111.486428, 27.20155],
                            [111.485022, 27.204309],
                            [111.479694, 27.209777],
                            [111.475227, 27.213214],
                            [111.474729, 27.214415],
                            [111.47686, 27.217699],
                            [111.473867, 27.218224],
                            [111.473708, 27.220746],
                            [111.481462, 27.227483],
                            [111.480805, 27.229108],
                            [111.476293, 27.229192],
                            [111.474638, 27.230377],
                            [111.473572, 27.234811],
                            [111.468539, 27.242139]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430511,
                "name": "北塔区",
                "center": [111.452315, 27.245688],
                "centroid": [111.420079, 27.248192],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430500
                },
                "subFeatureIndex": 2,
                "acroutes": [100000, 430000, 430500]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [111.485475, 27.289803],
                            [111.478991, 27.293355],
                            [111.475522, 27.299055],
                            [111.472711, 27.29958],
                            [111.468924, 27.298362],
                            [111.465886, 27.299174],
                            [111.463528, 27.297838],
                            [111.463165, 27.292949],
                            [111.460354, 27.291917],
                            [111.460626, 27.289684],
                            [111.455525, 27.28779],
                            [111.454822, 27.291105],
                            [111.452736, 27.292222],
                            [111.448451, 27.290395],
                            [111.445435, 27.290767],
                            [111.442805, 27.293795],
                            [111.442465, 27.296061],
                            [111.437454, 27.297651],
                            [111.431968, 27.29596],
                            [111.429564, 27.296535],
                            [111.42503, 27.291951],
                            [111.426821, 27.29146],
                            [111.430585, 27.286792],
                            [111.427297, 27.282647],
                            [111.424554, 27.28241],
                            [111.424259, 27.28082],
                            [111.420495, 27.281192],
                            [111.419044, 27.280295],
                            [111.41714, 27.276032],
                            [111.412378, 27.277318],
                            [111.409907, 27.275711],
                            [111.409839, 27.273105],
                            [111.404057, 27.272428],
                            [111.402924, 27.274949],
                            [111.400112, 27.27209],
                            [111.395669, 27.27297],
                            [111.392721, 27.271532],
                            [111.391406, 27.268588],
                            [111.387166, 27.268266],
                            [111.38567, 27.270347],
                            [111.381997, 27.269603],
                            [111.377553, 27.26676],
                            [111.373154, 27.2616],
                            [111.373699, 27.260314],
                            [111.372044, 27.257962],
                            [111.368189, 27.256473],
                            [111.366103, 27.257623],
                            [111.363496, 27.256033],
                            [111.365536, 27.255322],
                            [111.361591, 27.252919],
                            [111.36039, 27.249586],
                            [111.362249, 27.246996],
                            [111.366829, 27.245152],
                            [111.367305, 27.243578],
                            [111.364811, 27.237773],
                            [111.366783, 27.232882],
                            [111.3654, 27.230343],
                            [111.365242, 27.226924],
                            [111.361161, 27.225824],
                            [111.355946, 27.219189],
                            [111.356127, 27.215329],
                            [111.358009, 27.21621],
                            [111.359732, 27.211402],
                            [111.357556, 27.209828],
                            [111.358644, 27.207458],
                            [111.357011, 27.207424],
                            [111.357147, 27.204665],
                            [111.354699, 27.205105],
                            [111.354767, 27.202616],
                            [111.353044, 27.199298],
                            [111.354449, 27.197927],
                            [111.3581, 27.198367],
                            [111.362906, 27.197656],
                            [111.366103, 27.199196],
                            [111.371635, 27.199874],
                            [111.373653, 27.198198],
                            [111.376691, 27.199687],
                            [111.376283, 27.196403],
                            [111.377938, 27.194117],
                            [111.383471, 27.194083],
                            [111.386509, 27.194168],
                            [111.390499, 27.201685],
                            [111.395238, 27.207018],
                            [111.398208, 27.208744],
                            [111.40213, 27.209523],
                            [111.406302, 27.208829],
                            [111.407458, 27.206561],
                            [111.407821, 27.201414],
                            [111.409658, 27.198384],
                            [111.41757, 27.19432],
                            [111.422513, 27.194811],
                            [111.428091, 27.199433],
                            [111.430335, 27.202853],
                            [111.430653, 27.209523],
                            [111.423873, 27.22012],
                            [111.420609, 27.226789],
                            [111.42113, 27.229548],
                            [111.42741, 27.236741],
                            [111.433668, 27.239567],
                            [111.43988, 27.240566],
                            [111.446047, 27.242732],
                            [111.452668, 27.239296],
                            [111.458427, 27.239702],
                            [111.465909, 27.241056],
                            [111.468539, 27.242139],
                            [111.474955, 27.246083],
                            [111.473867, 27.249146],
                            [111.4694, 27.249332],
                            [111.464684, 27.257471],
                            [111.464707, 27.262987],
                            [111.466068, 27.265745],
                            [111.469945, 27.267251],
                            [111.473754, 27.265322],
                            [111.478333, 27.25627],
                            [111.481644, 27.253427],
                            [111.485339, 27.252818],
                            [111.488332, 27.253664],
                            [111.493615, 27.259654],
                            [111.495724, 27.263698],
                            [111.495542, 27.267268],
                            [111.491937, 27.270906],
                            [111.491144, 27.273156],
                            [111.493502, 27.279314],
                            [111.491348, 27.285777],
                            [111.489534, 27.287637],
                            [111.485475, 27.289803]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430522,
                "name": "新邵县",
                "center": [111.459762, 27.311429],
                "centroid": [111.464608, 27.425567],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430500
                },
                "subFeatureIndex": 3,
                "acroutes": [100000, 430000, 430500]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [111.823753, 27.447383],
                            [111.819491, 27.449292],
                            [111.813573, 27.454343],
                            [111.812054, 27.459866],
                            [111.810762, 27.471351],
                            [111.808472, 27.474103],
                            [111.803575, 27.474796],
                            [111.801262, 27.473192],
                            [111.799448, 27.470456],
                            [111.796365, 27.468159],
                            [111.792238, 27.468395],
                            [111.790175, 27.470456],
                            [111.788905, 27.479423],
                            [111.787363, 27.484928],
                            [111.787817, 27.491547],
                            [111.787477, 27.494621],
                            [111.785754, 27.495617],
                            [111.779292, 27.494975],
                            [111.7702, 27.495634],
                            [111.754375, 27.498723],
                            [111.748457, 27.498268],
                            [111.744058, 27.499416],
                            [111.740453, 27.502624],
                            [111.737098, 27.507908],
                            [111.733765, 27.515708],
                            [111.730931, 27.518223],
                            [111.728346, 27.517987],
                            [111.723177, 27.515691],
                            [111.717735, 27.516602],
                            [111.70819, 27.523929],
                            [111.700685, 27.529195],
                            [111.692999, 27.533854],
                            [111.689825, 27.531272],
                            [111.690755, 27.523692],
                            [111.689644, 27.52143],
                            [111.687807, 27.522511],
                            [111.686742, 27.526309],
                            [111.682502, 27.529668],
                            [111.675383, 27.537162],
                            [111.67112, 27.538361],
                            [111.668944, 27.537179],
                            [111.666064, 27.531558],
                            [111.658514, 27.52138],
                            [111.654025, 27.514762],
                            [111.651667, 27.514256],
                            [111.649309, 27.517649],
                            [111.64389, 27.520148],
                            [111.644253, 27.52219],
                            [111.642552, 27.52403],
                            [111.642961, 27.527288],
                            [111.638653, 27.537939],
                            [111.641373, 27.538496],
                            [111.643301, 27.5362],
                            [111.647178, 27.536251],
                            [111.650102, 27.534023],
                            [111.651962, 27.536048],
                            [111.652823, 27.540133],
                            [111.649241, 27.542107],
                            [111.645409, 27.546074],
                            [111.641691, 27.547812],
                            [111.644003, 27.550546],
                            [111.643437, 27.553719],
                            [111.640489, 27.555035],
                            [111.639832, 27.559186],
                            [111.637678, 27.561026],
                            [111.635116, 27.560368],
                            [111.636045, 27.555491],
                            [111.630649, 27.553077],
                            [111.626681, 27.554512],
                            [111.626228, 27.557296],
                            [111.628405, 27.558798],
                            [111.630604, 27.562325],
                            [111.635615, 27.562392],
                            [111.64033, 27.568754],
                            [111.64185, 27.572702],
                            [111.641396, 27.574643],
                            [111.6377, 27.572719],
                            [111.635138, 27.572888],
                            [111.635637, 27.575503],
                            [111.639696, 27.575992],
                            [111.640081, 27.577325],
                            [111.637383, 27.584732],
                            [111.635206, 27.584074],
                            [111.636181, 27.5807],
                            [111.635138, 27.578776],
                            [111.631352, 27.577444],
                            [111.632214, 27.580211],
                            [111.628065, 27.579974],
                            [111.62786, 27.577039],
                            [111.62548, 27.575199],
                            [111.623303, 27.57579],
                            [111.616161, 27.579687],
                            [111.612398, 27.5807],
                            [111.611241, 27.578523],
                            [111.620651, 27.571774],
                            [111.617522, 27.569513],
                            [111.615572, 27.566172],
                            [111.611491, 27.5671],
                            [111.611944, 27.569699],
                            [111.609223, 27.572061],
                            [111.607682, 27.575858],
                            [111.604009, 27.576954],
                            [111.602399, 27.575115],
                            [111.599588, 27.576802],
                            [111.598363, 27.579839],
                            [111.595552, 27.580615],
                            [111.594713, 27.58431],
                            [111.596549, 27.589776],
                            [111.595506, 27.590873],
                            [111.593148, 27.589608],
                            [111.590201, 27.593943],
                            [111.590858, 27.597621],
                            [111.590586, 27.601973],
                            [111.589045, 27.606561],
                            [111.58052, 27.607489],
                            [111.574081, 27.607708],
                            [111.570476, 27.611621],
                            [111.56814, 27.616884],
                            [111.568911, 27.622399],
                            [111.568004, 27.62707],
                            [111.565556, 27.627779],
                            [111.563515, 27.629954],
                            [111.56093, 27.630578],
                            [111.556078, 27.628774],
                            [111.553607, 27.626058],
                            [111.551612, 27.620172],
                            [111.548959, 27.615787],
                            [111.542656, 27.609648],
                            [111.533746, 27.607219],
                            [111.531184, 27.60592],
                            [111.52742, 27.608838],
                            [111.525856, 27.607522],
                            [111.526717, 27.605397],
                            [111.522273, 27.605549],
                            [111.51792, 27.602951],
                            [111.508919, 27.601349],
                            [111.489352, 27.594264],
                            [111.485249, 27.591733],
                            [111.482415, 27.587836],
                            [111.477812, 27.578641],
                            [111.472869, 27.578],
                            [111.468267, 27.579586],
                            [111.463619, 27.584867],
                            [111.460558, 27.591598],
                            [111.457951, 27.595428],
                            [111.454074, 27.597503],
                            [111.449947, 27.59865],
                            [111.441944, 27.597486],
                            [111.430358, 27.590603],
                            [111.425982, 27.585778],
                            [111.414396, 27.575199],
                            [111.403967, 27.557381],
                            [111.402924, 27.556436],
                            [111.396575, 27.553921],
                            [111.392381, 27.550664],
                            [111.389706, 27.547744],
                            [111.388391, 27.544909],
                            [111.382677, 27.545348],
                            [111.380274, 27.544082],
                            [111.376533, 27.545213],
                            [111.374628, 27.542951],
                            [111.37787, 27.539981],
                            [111.378029, 27.538276],
                            [111.376533, 27.532824],
                            [111.375263, 27.531204],
                            [111.371046, 27.529077],
                            [111.367758, 27.524908],
                            [111.36277, 27.524486],
                            [111.360299, 27.526562],
                            [111.357964, 27.525634],
                            [111.357102, 27.522916],
                            [111.353338, 27.524925],
                            [111.350006, 27.5296],
                            [111.351139, 27.531153],
                            [111.355742, 27.526832],
                            [111.355674, 27.528672],
                            [111.350912, 27.535677],
                            [111.348033, 27.537989],
                            [111.339236, 27.538901],
                            [111.338284, 27.540453],
                            [111.335087, 27.540487],
                            [111.322141, 27.54685],
                            [111.317198, 27.553702],
                            [111.303708, 27.564198],
                            [111.300874, 27.567083],
                            [111.295772, 27.566071],
                            [111.292847, 27.563861],
                            [111.278382, 27.555777],
                            [111.275775, 27.549888],
                            [111.270696, 27.54177],
                            [111.268701, 27.541584],
                            [111.266026, 27.543559],
                            [111.261015, 27.542192],
                            [111.257659, 27.54388],
                            [111.256095, 27.542783],
                            [111.255641, 27.53782],
                            [111.253193, 27.53912],
                            [111.252082, 27.538327],
                            [111.252286, 27.532386],
                            [111.251674, 27.530883],
                            [111.249112, 27.53085],
                            [111.247094, 27.525988],
                            [111.244645, 27.525094],
                            [111.223809, 27.536183],
                            [111.225011, 27.546344],
                            [111.223968, 27.549196],
                            [111.220793, 27.551744],
                            [111.212246, 27.551997],
                            [111.207961, 27.553128],
                            [111.204152, 27.552774],
                            [111.199866, 27.549972],
                            [111.197395, 27.549179],
                            [111.197894, 27.54415],
                            [111.199413, 27.538445],
                            [111.202292, 27.532554],
                            [111.202587, 27.527288],
                            [111.197486, 27.519641],
                            [111.196874, 27.516602],
                            [111.201408, 27.508921],
                            [111.201499, 27.504565],
                            [111.200297, 27.500817],
                            [111.198778, 27.499399],
                            [111.192997, 27.498251],
                            [111.190004, 27.496393],
                            [111.186943, 27.491683],
                            [111.187147, 27.48812],
                            [111.191727, 27.485519],
                            [111.191523, 27.484438],
                            [111.187827, 27.48454],
                            [111.185923, 27.483037],
                            [111.185333, 27.479997],
                            [111.183519, 27.47743],
                            [111.183519, 27.474931],
                            [111.185265, 27.473327],
                            [111.192452, 27.46902],
                            [111.193541, 27.46723],
                            [111.190049, 27.465017],
                            [111.187736, 27.465288],
                            [111.185651, 27.463058],
                            [111.188643, 27.459579],
                            [111.187238, 27.45816],
                            [111.182454, 27.456657],
                            [111.179393, 27.452214],
                            [111.177488, 27.451944],
                            [111.174609, 27.453295],
                            [111.170505, 27.454022],
                            [111.168011, 27.453228],
                            [111.166107, 27.449123],
                            [111.160733, 27.447806],
                            [111.156833, 27.445762],
                            [111.156629, 27.443008],
                            [111.161209, 27.444427],
                            [111.163318, 27.44414],
                            [111.164701, 27.440035],
                            [111.154271, 27.433734],
                            [111.152775, 27.43125],
                            [111.154657, 27.428209],
                            [111.15994, 27.425083],
                            [111.167218, 27.424256],
                            [111.169326, 27.422904],
                            [111.169099, 27.41944],
                            [111.163273, 27.415081],
                            [111.161368, 27.409741],
                            [111.157468, 27.407342],
                            [111.153591, 27.408879],
                            [111.147696, 27.40648],
                            [111.145883, 27.402205],
                            [111.147424, 27.394617],
                            [111.146019, 27.392301],
                            [111.146812, 27.389192],
                            [111.148603, 27.387755],
                            [111.156403, 27.388363],
                            [111.16028, 27.388076],
                            [111.161028, 27.386961],
                            [111.15969, 27.383715],
                            [111.161481, 27.38282],
                            [111.161164, 27.380758],
                            [111.16266, 27.378171],
                            [111.166265, 27.379777],
                            [111.168941, 27.378966],
                            [111.172138, 27.374588],
                            [111.172228, 27.36994],
                            [111.175221, 27.367979],
                            [111.179007, 27.366711],
                            [111.179144, 27.364834],
                            [111.183338, 27.359661],
                            [111.182635, 27.357514],
                            [111.17674, 27.354421],
                            [111.172364, 27.354911],
                            [111.168487, 27.353305],
                            [111.161549, 27.353744],
                            [111.151233, 27.355553],
                            [111.1479, 27.355553],
                            [111.146109, 27.352781],
                            [111.145361, 27.348655],
                            [111.145905, 27.340387],
                            [111.147719, 27.336262],
                            [111.153138, 27.333979],
                            [111.159554, 27.333996],
                            [111.175516, 27.336786],
                            [111.17962, 27.336786],
                            [111.182726, 27.33496],
                            [111.185311, 27.331747],
                            [111.187895, 27.326471],
                            [111.191319, 27.320789],
                            [111.190026, 27.317457],
                            [111.193722, 27.315495],
                            [111.202587, 27.31492],
                            [111.204786, 27.313043],
                            [111.203177, 27.307343],
                            [111.203177, 27.304586],
                            [111.204673, 27.303961],
                            [111.211044, 27.304028],
                            [111.215239, 27.303487],
                            [111.216077, 27.300172],
                            [111.213379, 27.297415],
                            [111.215261, 27.295723],
                            [111.216667, 27.292154],
                            [111.220839, 27.291613],
                            [111.222834, 27.289718],
                            [111.223129, 27.285709],
                            [111.225623, 27.283933],
                            [111.232152, 27.282122],
                            [111.231744, 27.278384],
                            [111.227935, 27.273579],
                            [111.227437, 27.271176],
                            [111.230815, 27.265001],
                            [111.233944, 27.261532],
                            [111.237843, 27.26072],
                            [111.23934, 27.262395],
                            [111.241652, 27.267386],
                            [111.243035, 27.267826],
                            [111.247026, 27.264612],
                            [111.252173, 27.262327],
                            [111.254485, 27.263986],
                            [111.25868, 27.263004],
                            [111.262285, 27.261329],
                            [111.266796, 27.263241],
                            [111.265708, 27.261464],
                            [111.268429, 27.261295],
                            [111.269064, 27.263563],
                            [111.268043, 27.268114],
                            [111.271014, 27.2672],
                            [111.271875, 27.263173],
                            [111.273553, 27.260297],
                            [111.277317, 27.259654],
                            [111.277203, 27.254222],
                            [111.27879, 27.252699],
                            [111.27446, 27.250381],
                            [111.27641, 27.250161],
                            [111.27548, 27.245846],
                            [111.277657, 27.246235],
                            [111.27904, 27.244255],
                            [111.28396, 27.246929],
                            [111.283484, 27.248993],
                            [111.286771, 27.247775],
                            [111.289356, 27.249298],
                            [111.29185, 27.252682],
                            [111.293528, 27.249146],
                            [111.296248, 27.250973],
                            [111.302393, 27.248333],
                            [111.297813, 27.24505],
                            [111.299536, 27.244018],
                            [111.299151, 27.241412],
                            [111.302597, 27.240396],
                            [111.302778, 27.244238],
                            [111.30373, 27.243578],
                            [111.306383, 27.246489],
                            [111.314092, 27.247233],
                            [111.314024, 27.251125],
                            [111.315611, 27.253071],
                            [111.31806, 27.253731],
                            [111.32069, 27.252801],
                            [111.324068, 27.253782],
                            [111.327945, 27.251413],
                            [111.330031, 27.254222],
                            [111.333046, 27.253325],
                            [111.341821, 27.252665],
                            [111.344292, 27.254865],
                            [111.349802, 27.254628],
                            [111.352137, 27.25808],
                            [111.355923, 27.259688],
                            [111.359415, 27.258503],
                            [111.361138, 27.255254],
                            [111.363496, 27.256033],
                            [111.366103, 27.257623],
                            [111.368189, 27.256473],
                            [111.372044, 27.257962],
                            [111.373699, 27.260314],
                            [111.373154, 27.2616],
                            [111.377553, 27.26676],
                            [111.381997, 27.269603],
                            [111.38567, 27.270347],
                            [111.387166, 27.268266],
                            [111.391406, 27.268588],
                            [111.392721, 27.271532],
                            [111.395669, 27.27297],
                            [111.400112, 27.27209],
                            [111.402924, 27.274949],
                            [111.404057, 27.272428],
                            [111.409839, 27.273105],
                            [111.409907, 27.275711],
                            [111.412378, 27.277318],
                            [111.41714, 27.276032],
                            [111.419044, 27.280295],
                            [111.420495, 27.281192],
                            [111.424259, 27.28082],
                            [111.424554, 27.28241],
                            [111.427297, 27.282647],
                            [111.430585, 27.286792],
                            [111.426821, 27.29146],
                            [111.42503, 27.291951],
                            [111.429564, 27.296535],
                            [111.431968, 27.29596],
                            [111.437454, 27.297651],
                            [111.442465, 27.296061],
                            [111.442805, 27.293795],
                            [111.445435, 27.290767],
                            [111.448451, 27.290395],
                            [111.452736, 27.292222],
                            [111.454822, 27.291105],
                            [111.455525, 27.28779],
                            [111.460626, 27.289684],
                            [111.460354, 27.291917],
                            [111.463165, 27.292949],
                            [111.463528, 27.297838],
                            [111.465886, 27.299174],
                            [111.468924, 27.298362],
                            [111.472711, 27.29958],
                            [111.475522, 27.299055],
                            [111.478991, 27.293355],
                            [111.485475, 27.289803],
                            [111.4867, 27.291748],
                            [111.492164, 27.297601],
                            [111.489919, 27.299766],
                            [111.491937, 27.302709],
                            [111.499102, 27.305923],
                            [111.500689, 27.300967],
                            [111.503772, 27.300865],
                            [111.50579, 27.299123],
                            [111.510688, 27.299918],
                            [111.51665, 27.298024],
                            [111.525266, 27.299901],
                            [111.532862, 27.300696],
                            [111.536784, 27.298683],
                            [111.537351, 27.296248],
                            [111.534154, 27.291799],
                            [111.524337, 27.283053],
                            [111.523634, 27.277876],
                            [111.526377, 27.276962],
                            [111.535673, 27.27725],
                            [111.540434, 27.278773],
                            [111.542747, 27.280583],
                            [111.544153, 27.285743],
                            [111.545082, 27.294556],
                            [111.5471, 27.295266],
                            [111.549435, 27.294218],
                            [111.55109, 27.284068],
                            [111.555421, 27.280295],
                            [111.562268, 27.276252],
                            [111.575192, 27.276573],
                            [111.580225, 27.275795],
                            [111.584828, 27.277775],
                            [111.588455, 27.284017],
                            [111.59138, 27.286775],
                            [111.600585, 27.286758],
                            [111.600041, 27.28982],
                            [111.601832, 27.299817],
                            [111.601605, 27.303978],
                            [111.595393, 27.307529],
                            [111.594622, 27.31147],
                            [111.597479, 27.315462],
                            [111.603351, 27.318134],
                            [111.614733, 27.322345],
                            [111.618066, 27.321939],
                            [111.62022, 27.319301],
                            [111.622056, 27.312299],
                            [111.622714, 27.306751],
                            [111.623757, 27.30325],
                            [111.629742, 27.300392],
                            [111.633959, 27.301322],
                            [111.63727, 27.304367],
                            [111.643165, 27.311419],
                            [111.649082, 27.314565],
                            [111.652211, 27.315089],
                            [111.654229, 27.314278],
                            [111.655567, 27.311233],
                            [111.654909, 27.30161],
                            [111.659716, 27.301745],
                            [111.666382, 27.306142],
                            [111.670123, 27.30555],
                            [111.669669, 27.301525],
                            [111.672412, 27.300696],
                            [111.677196, 27.294928],
                            [111.681436, 27.292864],
                            [111.686606, 27.292831],
                            [111.690165, 27.295639],
                            [111.691707, 27.299225],
                            [111.692365, 27.304942],
                            [111.691594, 27.312333],
                            [111.686061, 27.336752],
                            [111.68656, 27.340185],
                            [111.693521, 27.343718],
                            [111.7154, 27.346018],
                            [111.721726, 27.347742],
                            [111.725875, 27.351665],
                            [111.728664, 27.358174],
                            [111.727575, 27.364665],
                            [111.728777, 27.374723],
                            [111.73279, 27.38025],
                            [111.739683, 27.382634],
                            [111.74755, 27.38429],
                            [111.760133, 27.389039],
                            [111.763217, 27.390848],
                            [111.767683, 27.388549],
                            [111.768273, 27.385946],
                            [111.770926, 27.384746],
                            [111.773352, 27.385592],
                            [111.777093, 27.384915],
                            [111.778408, 27.383293],
                            [111.782534, 27.382938],
                            [111.785346, 27.386268],
                            [111.791649, 27.38958],
                            [111.793258, 27.393248],
                            [111.793666, 27.399095],
                            [111.795979, 27.402543],
                            [111.799199, 27.402627],
                            [111.803212, 27.405077],
                            [111.80659, 27.40408],
                            [111.80786, 27.39607],
                            [111.810376, 27.39411],
                            [111.816203, 27.394701],
                            [111.819332, 27.391879],
                            [111.823436, 27.393248],
                            [111.825159, 27.396695],
                            [111.827358, 27.398081],
                            [111.832528, 27.393501],
                            [111.835906, 27.394769],
                            [111.837992, 27.397456],
                            [111.836563, 27.400937],
                            [111.838536, 27.406649],
                            [111.835906, 27.410029],
                            [111.830306, 27.412022],
                            [111.825612, 27.412766],
                            [111.818357, 27.41792],
                            [111.814276, 27.423935],
                            [111.814979, 27.428496],
                            [111.819559, 27.440018],
                            [111.823753, 27.447383]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430523,
                "name": "邵阳县",
                "center": [111.2757, 26.989713],
                "centroid": [111.325512, 26.979422],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430500
                },
                "subFeatureIndex": 4,
                "acroutes": [100000, 430000, 430500]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [111.66976, 27.032549],
                            [111.670327, 27.035516],
                            [111.668581, 27.038144],
                            [111.669148, 27.041026],
                            [111.671687, 27.042705],
                            [111.670735, 27.043857],
                            [111.667833, 27.04362],
                            [111.668195, 27.046044],
                            [111.67205, 27.055233],
                            [111.672186, 27.056691],
                            [111.674793, 27.057758],
                            [111.676607, 27.060742],
                            [111.675723, 27.061861],
                            [111.67239, 27.058826],
                            [111.670916, 27.058742],
                            [111.670939, 27.061878],
                            [111.672095, 27.065285],
                            [111.668218, 27.063047],
                            [111.664069, 27.067658],
                            [111.660441, 27.078149],
                            [111.657675, 27.07859],
                            [111.653821, 27.080912],
                            [111.651463, 27.080878],
                            [111.650034, 27.083589],
                            [111.64745, 27.084657],
                            [111.646361, 27.082352],
                            [111.643935, 27.080454],
                            [111.64423, 27.077963],
                            [111.640897, 27.0782],
                            [111.638743, 27.076336],
                            [111.637066, 27.077386],
                            [111.636612, 27.083793],
                            [111.632871, 27.08425],
                            [111.627498, 27.087606],
                            [111.626341, 27.08564],
                            [111.623167, 27.084844],
                            [111.621331, 27.082674],
                            [111.624006, 27.080539],
                            [111.624369, 27.077861],
                            [111.622396, 27.076692],
                            [111.623643, 27.074048],
                            [111.620378, 27.07359],
                            [111.618179, 27.075014],
                            [111.616864, 27.073031],
                            [111.616773, 27.068624],
                            [111.614846, 27.067454],
                            [111.610493, 27.066878],
                            [111.607636, 27.064386],
                            [111.605641, 27.064522],
                            [111.604508, 27.068454],
                            [111.602036, 27.071031],
                            [111.601311, 27.073709],
                            [111.603215, 27.079997],
                            [111.602331, 27.082912],
                            [111.603963, 27.083318],
                            [111.605755, 27.08808],
                            [111.60809, 27.087555],
                            [111.610017, 27.088572],
                            [111.605664, 27.092232],
                            [111.603261, 27.096486],
                            [111.60215, 27.094334],
                            [111.600018, 27.097926],
                            [111.594917, 27.099824],
                            [111.594214, 27.098621],
                            [111.591856, 27.101891],
                            [111.588773, 27.101891],
                            [111.58417, 27.103518],
                            [111.582764, 27.101129],
                            [111.580021, 27.100146],
                            [111.578139, 27.101061],
                            [111.573763, 27.099688],
                            [111.571405, 27.101298],
                            [111.568934, 27.100485],
                            [111.569841, 27.096876],
                            [111.567982, 27.095808],
                            [111.564603, 27.097011],
                            [111.561112, 27.095147],
                            [111.559253, 27.092148],
                            [111.559366, 27.089267],
                            [111.550614, 27.085488],
                            [111.548438, 27.085945],
                            [111.53989, 27.089995],
                            [111.539029, 27.095215],
                            [111.541659, 27.10101],
                            [111.544651, 27.102128],
                            [111.542928, 27.105314],
                            [111.542203, 27.110448],
                            [111.539527, 27.109516],
                            [111.537215, 27.110279],
                            [111.538371, 27.115124],
                            [111.540071, 27.118886],
                            [111.53531, 27.120004],
                            [111.533406, 27.122731],
                            [111.535945, 27.12512],
                            [111.529823, 27.127441],
                            [111.526763, 27.126797],
                            [111.522477, 27.128779],
                            [111.523203, 27.130643],
                            [111.521162, 27.130795],
                            [111.518034, 27.135132],
                            [111.516129, 27.140332],
                            [111.516197, 27.144245],
                            [111.512887, 27.146684],
                            [111.507581, 27.146633],
                            [111.503342, 27.144533],
                            [111.498081, 27.144702],
                            [111.493796, 27.142568],
                            [111.492957, 27.139875],
                            [111.486632, 27.142246],
                            [111.484795, 27.140214],
                            [111.487357, 27.140891],
                            [111.486224, 27.137893],
                            [111.482437, 27.136843],
                            [111.485022, 27.13647],
                            [111.484841, 27.132252],
                            [111.487675, 27.128508],
                            [111.486496, 27.126831],
                            [111.485861, 27.120004],
                            [111.490622, 27.117225],
                            [111.491983, 27.11038],
                            [111.490441, 27.104619],
                            [111.487403, 27.100146],
                            [111.484024, 27.098706],
                            [111.483367, 27.096554],
                            [111.479399, 27.095469],
                            [111.475771, 27.09696],
                            [111.471872, 27.100739],
                            [111.472915, 27.105178],
                            [111.467405, 27.105094],
                            [111.466589, 27.101247],
                            [111.468312, 27.100485],
                            [111.468131, 27.097926],
                            [111.465591, 27.099112],
                            [111.462395, 27.093639],
                            [111.457225, 27.094368],
                            [111.456386, 27.091809],
                            [111.449199, 27.087758],
                            [111.44505, 27.088131],
                            [111.439767, 27.086725],
                            [111.436207, 27.081115],
                            [111.431854, 27.083217],
                            [111.42834, 27.07998],
                            [111.429882, 27.077607],
                            [111.428295, 27.076573],
                            [111.42444, 27.077386],
                            [111.422354, 27.080386],
                            [111.418251, 27.083962],
                            [111.41426, 27.083776],
                            [111.411562, 27.085843],
                            [111.409544, 27.085657],
                            [111.410406, 27.087911],
                            [111.409091, 27.091199],
                            [111.40696, 27.092487],
                            [111.408207, 27.093927],
                            [111.414532, 27.095198],
                            [111.419226, 27.094808],
                            [111.420246, 27.098892],
                            [111.421584, 27.100892],
                            [111.420382, 27.103535],
                            [111.417117, 27.104535],
                            [111.41952, 27.107991],
                            [111.417684, 27.111702],
                            [111.421652, 27.118326],
                            [111.425325, 27.119936],
                            [111.428431, 27.12551],
                            [111.422853, 27.130355],
                            [111.41714, 27.12922],
                            [111.416006, 27.130541],
                            [111.41909, 27.131066],
                            [111.416233, 27.133353],
                            [111.414895, 27.136419],
                            [111.411902, 27.138808],
                            [111.408615, 27.138469],
                            [111.407889, 27.139485],
                            [111.410361, 27.145261],
                            [111.410361, 27.149377],
                            [111.408297, 27.151833],
                            [111.4034, 27.153442],
                            [111.394172, 27.162317],
                            [111.391905, 27.162554],
                            [111.389093, 27.160234],
                            [111.385942, 27.16252],
                            [111.387121, 27.166737],
                            [111.383652, 27.170327],
                            [111.382473, 27.173561],
                            [111.381952, 27.179251],
                            [111.379798, 27.183704],
                            [111.379571, 27.185736],
                            [111.38143, 27.188276],
                            [111.383471, 27.194083],
                            [111.377938, 27.194117],
                            [111.376283, 27.196403],
                            [111.376691, 27.199687],
                            [111.373653, 27.198198],
                            [111.371635, 27.199874],
                            [111.366103, 27.199196],
                            [111.362906, 27.197656],
                            [111.3581, 27.198367],
                            [111.354449, 27.197927],
                            [111.353044, 27.199298],
                            [111.354767, 27.202616],
                            [111.354699, 27.205105],
                            [111.357147, 27.204665],
                            [111.357011, 27.207424],
                            [111.358644, 27.207458],
                            [111.357556, 27.209828],
                            [111.359732, 27.211402],
                            [111.358009, 27.21621],
                            [111.356127, 27.215329],
                            [111.355946, 27.219189],
                            [111.361161, 27.225824],
                            [111.365242, 27.226924],
                            [111.3654, 27.230343],
                            [111.366783, 27.232882],
                            [111.364811, 27.237773],
                            [111.367305, 27.243578],
                            [111.366829, 27.245152],
                            [111.362249, 27.246996],
                            [111.36039, 27.249586],
                            [111.361591, 27.252919],
                            [111.365536, 27.255322],
                            [111.363496, 27.256033],
                            [111.361138, 27.255254],
                            [111.359415, 27.258503],
                            [111.355923, 27.259688],
                            [111.352137, 27.25808],
                            [111.349802, 27.254628],
                            [111.344292, 27.254865],
                            [111.341821, 27.252665],
                            [111.333046, 27.253325],
                            [111.330031, 27.254222],
                            [111.327945, 27.251413],
                            [111.324068, 27.253782],
                            [111.32069, 27.252801],
                            [111.31806, 27.253731],
                            [111.315611, 27.253071],
                            [111.314024, 27.251125],
                            [111.314092, 27.247233],
                            [111.306383, 27.246489],
                            [111.30373, 27.243578],
                            [111.302778, 27.244238],
                            [111.302597, 27.240396],
                            [111.299151, 27.241412],
                            [111.299536, 27.244018],
                            [111.297813, 27.24505],
                            [111.302393, 27.248333],
                            [111.296248, 27.250973],
                            [111.293528, 27.249146],
                            [111.29185, 27.252682],
                            [111.289356, 27.249298],
                            [111.286771, 27.247775],
                            [111.283484, 27.248993],
                            [111.28396, 27.246929],
                            [111.27904, 27.244255],
                            [111.277657, 27.246235],
                            [111.27548, 27.245846],
                            [111.27641, 27.250161],
                            [111.27446, 27.250381],
                            [111.27879, 27.252699],
                            [111.277203, 27.254222],
                            [111.277317, 27.259654],
                            [111.273553, 27.260297],
                            [111.271875, 27.263173],
                            [111.271014, 27.2672],
                            [111.268043, 27.268114],
                            [111.269064, 27.263563],
                            [111.268429, 27.261295],
                            [111.265708, 27.261464],
                            [111.266796, 27.263241],
                            [111.262285, 27.261329],
                            [111.25868, 27.263004],
                            [111.254485, 27.263986],
                            [111.252173, 27.262327],
                            [111.247026, 27.264612],
                            [111.243035, 27.267826],
                            [111.241652, 27.267386],
                            [111.23934, 27.262395],
                            [111.237843, 27.26072],
                            [111.233944, 27.261532],
                            [111.231903, 27.256727],
                            [111.227255, 27.249806],
                            [111.227867, 27.248621],
                            [111.225532, 27.246269],
                            [111.219546, 27.244712],
                            [111.218141, 27.242123],
                            [111.218889, 27.238467],
                            [111.220499, 27.239516],
                            [111.22365, 27.233982],
                            [111.225328, 27.235725],
                            [111.224965, 27.231596],
                            [111.227595, 27.228228],
                            [111.2273, 27.225012],
                            [111.224829, 27.225232],
                            [111.225555, 27.222134],
                            [111.230951, 27.220492],
                            [111.23213, 27.21643],
                            [111.23111, 27.211927],
                            [111.232515, 27.211148],
                            [111.23018, 27.208626],
                            [111.230089, 27.206256],
                            [111.226915, 27.203141],
                            [111.230225, 27.200195],
                            [111.229976, 27.198367],
                            [111.235463, 27.193914],
                            [111.231427, 27.192441],
                            [111.227142, 27.193626],
                            [111.227527, 27.196589],
                            [111.224308, 27.198198],
                            [111.22195, 27.195167],
                            [111.22433, 27.19366],
                            [111.221292, 27.189207],
                            [111.221065, 27.186227],
                            [111.222834, 27.185431],
                            [111.221156, 27.183772],
                            [111.224784, 27.184619],
                            [111.224172, 27.181926],
                            [111.222267, 27.181046],
                            [111.223083, 27.179488],
                            [111.226099, 27.178828],
                            [111.225872, 27.175983],
                            [111.22891, 27.177016],
                            [111.230135, 27.175983],
                            [111.228321, 27.172766],
                            [111.22882, 27.170954],
                            [111.226439, 27.170259],
                            [111.225645, 27.16738],
                            [111.221474, 27.167296],
                            [111.22136, 27.162401],
                            [111.223378, 27.162859],
                            [111.225328, 27.160894],
                            [111.228661, 27.16186],
                            [111.230497, 27.159048],
                            [111.232991, 27.158608],
                            [111.233536, 27.155017],
                            [111.236188, 27.15141],
                            [111.23612, 27.147446],
                            [111.23408, 27.145939],
                            [111.235576, 27.141552],
                            [111.23383, 27.137588],
                            [111.229681, 27.136047],
                            [111.227867, 27.132506],
                            [111.222018, 27.129288],
                            [111.221088, 27.126848],
                            [111.222358, 27.124493],
                            [111.221179, 27.123917],
                            [111.216191, 27.126391],
                            [111.212903, 27.124256],
                            [111.211566, 27.120512],
                            [111.212563, 27.114209],
                            [111.211974, 27.111973],
                            [111.215624, 27.108466],
                            [111.219773, 27.111482],
                            [111.218299, 27.108093],
                            [111.217891, 27.103925],
                            [111.218503, 27.099146],
                            [111.213878, 27.093147],
                            [111.214876, 27.088724],
                            [111.214332, 27.085132],
                            [111.215216, 27.083928],
                            [111.215805, 27.076776],
                            [111.213221, 27.071861],
                            [111.211316, 27.069963],
                            [111.205716, 27.066386],
                            [111.203449, 27.066353],
                            [111.202474, 27.068387],
                            [111.201249, 27.066403],
                            [111.197191, 27.067675],
                            [111.193631, 27.063607],
                            [111.190911, 27.062895],
                            [111.189868, 27.066488],
                            [111.183905, 27.066403],
                            [111.181864, 27.06459],
                            [111.181411, 27.066624],
                            [111.179007, 27.065895],
                            [111.173158, 27.066336],
                            [111.16978, 27.063691],
                            [111.166515, 27.063285],
                            [111.164452, 27.059691],
                            [111.159872, 27.059606],
                            [111.159985, 27.062725],
                            [111.156947, 27.062217],
                            [111.155201, 27.059301],
                            [111.151437, 27.059148],
                            [111.149488, 27.055572],
                            [111.147152, 27.055673],
                            [111.146223, 27.058182],
                            [111.142731, 27.059691],
                            [111.14094, 27.059335],
                            [111.141915, 27.055894],
                            [111.140645, 27.056267],
                            [111.139489, 27.059284],
                            [111.139375, 27.054928],
                            [111.137063, 27.052826],
                            [111.135907, 27.055267],
                            [111.13475, 27.053826],
                            [111.134932, 27.045163],
                            [111.137652, 27.043688],
                            [111.136655, 27.038076],
                            [111.134274, 27.037415],
                            [111.134365, 27.035719],
                            [111.12813, 27.035923],
                            [111.126747, 27.032769],
                            [111.12745, 27.031379],
                            [111.133118, 27.025461],
                            [111.139058, 27.021697],
                            [111.140169, 27.017593],
                            [111.138151, 27.008589],
                            [111.140124, 27.005672],
                            [111.139103, 27.002721],
                            [111.134954, 27.001873],
                            [111.131485, 27.004909],
                            [111.128742, 27.011539],
                            [111.126792, 27.013998],
                            [111.117678, 27.020781],
                            [111.106092, 27.030548],
                            [111.10387, 27.029853],
                            [111.098995, 27.026682],
                            [111.093486, 27.02597],
                            [111.091445, 27.027598],
                            [111.087908, 27.037737],
                            [111.083668, 27.046468],
                            [111.081855, 27.047875],
                            [111.078703, 27.048164],
                            [111.077116, 27.046892],
                            [111.074735, 27.04262],
                            [111.074259, 27.036601],
                            [111.072604, 27.028463],
                            [111.071516, 27.027123],
                            [111.06587, 27.02363],
                            [111.058978, 27.020239],
                            [111.053174, 27.011556],
                            [111.046961, 27.006486],
                            [111.040205, 27.0025],
                            [111.034741, 27.00038],
                            [111.027055, 27.001567],
                            [111.024152, 26.999244],
                            [111.023314, 26.996276],
                            [111.023722, 26.992901],
                            [111.022951, 26.991171],
                            [111.024152, 26.987439],
                            [111.028052, 26.980959],
                            [111.036305, 26.97967],
                            [111.035874, 26.977923],
                            [111.032043, 26.974615],
                            [111.031408, 26.972393],
                            [111.035307, 26.968796],
                            [111.034831, 26.966726],
                            [111.032043, 26.965047],
                            [111.031748, 26.962875],
                            [111.035511, 26.952611],
                            [111.033403, 26.94825],
                            [111.029843, 26.943923],
                            [111.030342, 26.940275],
                            [111.032655, 26.9333],
                            [111.030093, 26.929007],
                            [111.02388, 26.927377],
                            [111.015061, 26.928413],
                            [111.00912, 26.93106],
                            [111.004427, 26.928124],
                            [111.002205, 26.925477],
                            [111.000935, 26.921624],
                            [110.998373, 26.918077],
                            [111.005357, 26.914071],
                            [111.010662, 26.90808],
                            [111.018439, 26.900815],
                            [111.031929, 26.893515],
                            [111.039457, 26.890408],
                            [111.050725, 26.888948],
                            [111.055214, 26.886894],
                            [111.060542, 26.885264],
                            [111.066142, 26.884297],
                            [111.070768, 26.884314],
                            [111.076867, 26.882854],
                            [111.079111, 26.877692],
                            [111.0763, 26.873923],
                            [111.073874, 26.872429],
                            [111.068908, 26.87107],
                            [111.066188, 26.868897],
                            [111.064737, 26.866214],
                            [111.065303, 26.862971],
                            [111.067934, 26.860084],
                            [111.069997, 26.851933],
                            [111.070496, 26.847772],
                            [111.072151, 26.845174],
                            [111.078952, 26.843],
                            [111.081288, 26.839212],
                            [111.082059, 26.835255],
                            [111.080744, 26.830958],
                            [111.077275, 26.828597],
                            [111.070586, 26.825029],
                            [111.065167, 26.820919],
                            [111.064782, 26.818642],
                            [111.07036, 26.813308],
                            [111.073443, 26.813155],
                            [111.081038, 26.815092],
                            [111.09335, 26.814616],
                            [111.094302, 26.817317],
                            [111.09802, 26.817912],
                            [111.103983, 26.811541],
                            [111.108064, 26.808925],
                            [111.113279, 26.80811],
                            [111.112712, 26.804372],
                            [111.113302, 26.800991],
                            [111.114617, 26.7997],
                            [111.125658, 26.797542],
                            [111.127472, 26.79637],
                            [111.128084, 26.792088],
                            [111.13017, 26.790049],
                            [111.133435, 26.790338],
                            [111.13509, 26.796285],
                            [111.138831, 26.801773],
                            [111.1423, 26.803132],
                            [111.148694, 26.803743],
                            [111.153818, 26.80235],
                            [111.155813, 26.794993],
                            [111.157128, 26.793685],
                            [111.163023, 26.792224],
                            [111.167535, 26.792207],
                            [111.176559, 26.788843],
                            [111.18454, 26.786464],
                            [111.187578, 26.784883],
                            [111.199368, 26.784781],
                            [111.203358, 26.783303],
                            [111.206011, 26.778817],
                            [111.205421, 26.775707],
                            [111.201386, 26.771748],
                            [111.200705, 26.769487],
                            [111.201431, 26.764678],
                            [111.203086, 26.759817],
                            [111.208074, 26.755704],
                            [111.210115, 26.749415],
                            [111.210727, 26.741748],
                            [111.214853, 26.728913],
                            [111.212903, 26.717879],
                            [111.218118, 26.713748],
                            [111.223877, 26.711435],
                            [111.227618, 26.712047],
                            [111.23705, 26.712541],
                            [111.242423, 26.71453],
                            [111.253374, 26.716944],
                            [111.257999, 26.717165],
                            [111.262375, 26.716587],
                            [111.270356, 26.713952],
                            [111.271716, 26.712728],
                            [111.271898, 26.708902],
                            [111.269245, 26.702083],
                            [111.26988, 26.696046],
                            [111.272601, 26.69205],
                            [111.276251, 26.688853],
                            [111.29448, 26.69625],
                            [111.30296, 26.700008],
                            [111.304433, 26.701607],
                            [111.305907, 26.707235],
                            [111.307902, 26.709888],
                            [111.31026, 26.71118],
                            [111.313729, 26.710483],
                            [111.319511, 26.7122],
                            [111.321438, 26.714224],
                            [111.321982, 26.719869],
                            [111.321143, 26.72162],
                            [111.316745, 26.724663],
                            [111.317198, 26.733181],
                            [111.319783, 26.73653],
                            [111.323977, 26.73772],
                            [111.327514, 26.752083],
                            [111.329396, 26.757607],
                            [111.326675, 26.764831],
                            [111.329215, 26.772138],
                            [111.328036, 26.774195],
                            [111.324023, 26.775894],
                            [111.321687, 26.780754],
                            [111.321914, 26.784408],
                            [111.324091, 26.788333],
                            [111.32298, 26.791748],
                            [111.320508, 26.794212],
                            [111.318264, 26.798867],
                            [111.315158, 26.802571],
                            [111.316268, 26.805273],
                            [111.321211, 26.810913],
                            [111.322503, 26.813087],
                            [111.321619, 26.818557],
                            [111.323932, 26.823161],
                            [111.329872, 26.836682],
                            [111.331867, 26.838652],
                            [111.340936, 26.844783],
                            [111.349711, 26.851542],
                            [111.350368, 26.854786],
                            [111.349665, 26.859829],
                            [111.352998, 26.86236],
                            [111.359256, 26.863429],
                            [111.364675, 26.865467],
                            [111.366375, 26.864941],
                            [111.372905, 26.858199],
                            [111.375558, 26.858131],
                            [111.385262, 26.861324],
                            [111.392426, 26.863005],
                            [111.396711, 26.862648],
                            [111.406415, 26.858216],
                            [111.410406, 26.858046],
                            [111.414646, 26.860271],
                            [111.416822, 26.864261],
                            [111.419634, 26.867793],
                            [111.430018, 26.867895],
                            [111.433895, 26.862427],
                            [111.43818, 26.860848],
                            [111.440289, 26.865365],
                            [111.442805, 26.867233],
                            [111.445503, 26.867589],
                            [111.460105, 26.867114],
                            [111.461397, 26.865942],
                            [111.462508, 26.861052],
                            [111.464911, 26.860577],
                            [111.471464, 26.861986],
                            [111.476814, 26.858063],
                            [111.482528, 26.856756],
                            [111.486178, 26.857622],
                            [111.490962, 26.860135],
                            [111.495247, 26.860712],
                            [111.507944, 26.859558],
                            [111.517195, 26.865127],
                            [111.522999, 26.865586],
                            [111.528259, 26.864992],
                            [111.530436, 26.862988],
                            [111.532181, 26.859269],
                            [111.533292, 26.853988],
                            [111.533655, 26.84711],
                            [111.542135, 26.842779],
                            [111.54354, 26.841182],
                            [111.547009, 26.842779],
                            [111.55075, 26.846957],
                            [111.552065, 26.85178],
                            [111.549979, 26.854395],
                            [111.551, 26.860016],
                            [111.554423, 26.864482],
                            [111.558822, 26.865348],
                            [111.562971, 26.86781],
                            [111.565374, 26.872649],
                            [111.565873, 26.879373],
                            [111.566599, 26.881852],
                            [111.569093, 26.884297],
                            [111.572539, 26.884449],
                            [111.576824, 26.882497],
                            [111.57857, 26.883108],
                            [111.580996, 26.888626],
                            [111.583989, 26.889916],
                            [111.58519, 26.892989],
                            [111.58909, 26.89674],
                            [111.589249, 26.899066],
                            [111.583059, 26.90903],
                            [111.581903, 26.915938],
                            [111.583603, 26.923236],
                            [111.583127, 26.925035],
                            [111.574602, 26.927802],
                            [111.570929, 26.932367],
                            [111.56712, 26.934811],
                            [111.568254, 26.937305],
                            [111.571587, 26.939664],
                            [111.576484, 26.945026],
                            [111.579885, 26.953696],
                            [111.581449, 26.962146],
                            [111.584623, 26.964029],
                            [111.587571, 26.96693],
                            [111.591675, 26.965386],
                            [111.593466, 26.962315],
                            [111.599451, 26.960738],
                            [111.602172, 26.959177],
                            [111.606548, 26.958379],
                            [111.610153, 26.96028],
                            [111.61369, 26.959703],
                            [111.619857, 26.954918],
                            [111.622056, 26.954205],
                            [111.626319, 26.955308],
                            [111.630695, 26.957667],
                            [111.635274, 26.963741],
                            [111.642394, 26.962943],
                            [111.644525, 26.964623],
                            [111.650261, 26.963181],
                            [111.649218, 26.967422],
                            [111.652778, 26.974123],
                            [111.657675, 26.986014],
                            [111.657494, 26.98827],
                            [111.652166, 26.993274],
                            [111.653957, 26.996445],
                            [111.649785, 27.001143],
                            [111.649286, 27.004451],
                            [111.651531, 27.008707],
                            [111.6567, 27.009776],
                            [111.659602, 27.012167],
                            [111.661121, 27.01739],
                            [111.662482, 27.019611],
                            [111.660645, 27.023444],
                            [111.66552, 27.026869],
                            [111.664069, 27.029463],
                            [111.665815, 27.031752],
                            [111.66976, 27.032549]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430524,
                "name": "隆回县",
                "center": [111.038785, 27.116002],
                "centroid": [110.965394, 27.346434],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430500
                },
                "subFeatureIndex": 5,
                "acroutes": [100000, 430000, 430500]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [110.64826, 27.367286],
                            [110.656332, 27.369635],
                            [110.660209, 27.369466],
                            [110.663655, 27.370903],
                            [110.666943, 27.369618],
                            [110.673949, 27.361707],
                            [110.674311, 27.355029],
                            [110.673336, 27.351631],
                            [110.674697, 27.348131],
                            [110.677803, 27.34431],
                            [110.68007, 27.343059],
                            [110.689366, 27.342873],
                            [110.692767, 27.343769],
                            [110.697846, 27.34267],
                            [110.70204, 27.343093],
                            [110.705645, 27.34174],
                            [110.705804, 27.3366],
                            [110.706756, 27.333015],
                            [110.7112, 27.333455],
                            [110.714646, 27.337124],
                            [110.716256, 27.337395],
                            [110.722514, 27.33594],
                            [110.734576, 27.335315],
                            [110.736526, 27.336076],
                            [110.738634, 27.343989],
                            [110.74208, 27.346627],
                            [110.746819, 27.352375],
                            [110.757407, 27.356466],
                            [110.764594, 27.360033],
                            [110.772349, 27.359881],
                            [110.778085, 27.357075],
                            [110.781803, 27.35437],
                            [110.783118, 27.350634],
                            [110.783413, 27.346931],
                            [110.781826, 27.333928],
                            [110.780556, 27.32571],
                            [110.780647, 27.31881],
                            [110.777858, 27.302861],
                            [110.778357, 27.294353],
                            [110.777246, 27.288652],
                            [110.777291, 27.281074],
                            [110.779445, 27.278434],
                            [110.786202, 27.274882],
                            [110.79187, 27.270939],
                            [110.793502, 27.268317],
                            [110.794228, 27.260331],
                            [110.797833, 27.255864],
                            [110.797198, 27.250753],
                            [110.795452, 27.248012],
                            [110.795656, 27.245761],
                            [110.797991, 27.243984],
                            [110.79849, 27.241784],
                            [110.796314, 27.23559],
                            [110.799828, 27.234828],
                            [110.801732, 27.232696],
                            [110.800758, 27.22904],
                            [110.801755, 27.226366],
                            [110.805405, 27.222083],
                            [110.808444, 27.216701],
                            [110.813091, 27.215448],
                            [110.816175, 27.213112],
                            [110.817535, 27.209845],
                            [110.820687, 27.206442],
                            [110.820914, 27.199112],
                            [110.823498, 27.200026],
                            [110.825766, 27.197165],
                            [110.829371, 27.195048],
                            [110.826627, 27.199264],
                            [110.830708, 27.198164],
                            [110.831456, 27.196251],
                            [110.833882, 27.196352],
                            [110.833452, 27.194659],
                            [110.830209, 27.195793],
                            [110.83055, 27.193711],
                            [110.828962, 27.193051],
                            [110.827988, 27.189529],
                            [110.833905, 27.185262],
                            [110.833746, 27.18384],
                            [110.829121, 27.182502],
                            [110.828078, 27.180047],
                            [110.822886, 27.180826],
                            [110.817377, 27.178032],
                            [110.81418, 27.17876],
                            [110.808988, 27.177981],
                            [110.805088, 27.179996],
                            [110.80366, 27.17876],
                            [110.800826, 27.178879],
                            [110.798309, 27.18069],
                            [110.796608, 27.184483],
                            [110.793298, 27.184094],
                            [110.791734, 27.182519],
                            [110.790169, 27.17854],
                            [110.786156, 27.174374],
                            [110.78466, 27.178371],
                            [110.781214, 27.175949],
                            [110.776679, 27.175831],
                            [110.775817, 27.173697],
                            [110.778584, 27.172461],
                            [110.778584, 27.171174],
                            [110.775704, 27.168278],
                            [110.776838, 27.165958],
                            [110.779468, 27.16462],
                            [110.776407, 27.160826],
                            [110.775092, 27.157371],
                            [110.777994, 27.155627],
                            [110.778992, 27.153222],
                            [110.773051, 27.148446],
                            [110.778561, 27.149225],
                            [110.785408, 27.15307],
                            [110.792459, 27.151207],
                            [110.798059, 27.150817],
                            [110.803569, 27.149462],
                            [110.807083, 27.147548],
                            [110.807854, 27.145092],
                            [110.812117, 27.142839],
                            [110.810484, 27.141264],
                            [110.810734, 27.137554],
                            [110.813273, 27.1342],
                            [110.813749, 27.131998],
                            [110.813885, 27.122613],
                            [110.814701, 27.121427],
                            [110.822387, 27.121952],
                            [110.834086, 27.121867],
                            [110.839619, 27.122664],
                            [110.844131, 27.125696],
                            [110.845899, 27.124544],
                            [110.848484, 27.119529],
                            [110.851771, 27.119258],
                            [110.856986, 27.10428],
                            [110.861067, 27.09535],
                            [110.863652, 27.091317],
                            [110.866305, 27.08864],
                            [110.877505, 27.082573],
                            [110.882833, 27.077014],
                            [110.883944, 27.074217],
                            [110.883717, 27.062505],
                            [110.884511, 27.058776],
                            [110.884012, 27.054436],
                            [110.886551, 27.050181],
                            [110.890496, 27.047452],
                            [110.897661, 27.050554],
                            [110.905256, 27.052707],
                            [110.907977, 27.050723],
                            [110.908499, 27.04679],
                            [110.910584, 27.043247],
                            [110.916411, 27.041806],
                            [110.918905, 27.040314],
                            [110.920923, 27.035618],
                            [110.919608, 27.027055],
                            [110.925979, 27.025817],
                            [110.930695, 27.027208],
                            [110.93523, 27.029734],
                            [110.938857, 27.030023],
                            [110.948629, 27.029327],
                            [110.970191, 27.031311],
                            [110.977424, 27.03143],
                            [110.984203, 27.032498],
                            [110.988579, 27.032057],
                            [110.992841, 27.030023],
                            [110.99724, 27.02614],
                            [110.999893, 27.017916],
                            [110.999348, 27.013371],
                            [111.000958, 27.01176],
                            [111.001548, 27.007673],
                            [111.003679, 27.005756],
                            [111.007873, 27.006011],
                            [111.009891, 27.008521],
                            [111.013882, 27.001958],
                            [111.017351, 26.997836],
                            [111.019641, 26.996666],
                            [111.020162, 26.99441],
                            [111.023722, 26.992901],
                            [111.023314, 26.996276],
                            [111.024152, 26.999244],
                            [111.027055, 27.001567],
                            [111.034741, 27.00038],
                            [111.040205, 27.0025],
                            [111.046961, 27.006486],
                            [111.053174, 27.011556],
                            [111.058978, 27.020239],
                            [111.06587, 27.02363],
                            [111.071516, 27.027123],
                            [111.072604, 27.028463],
                            [111.074259, 27.036601],
                            [111.074735, 27.04262],
                            [111.077116, 27.046892],
                            [111.078703, 27.048164],
                            [111.081855, 27.047875],
                            [111.083668, 27.046468],
                            [111.087908, 27.037737],
                            [111.091445, 27.027598],
                            [111.093486, 27.02597],
                            [111.098995, 27.026682],
                            [111.10387, 27.029853],
                            [111.106092, 27.030548],
                            [111.117678, 27.020781],
                            [111.126792, 27.013998],
                            [111.128742, 27.011539],
                            [111.131485, 27.004909],
                            [111.134954, 27.001873],
                            [111.139103, 27.002721],
                            [111.140124, 27.005672],
                            [111.138151, 27.008589],
                            [111.140169, 27.017593],
                            [111.139058, 27.021697],
                            [111.133118, 27.025461],
                            [111.12745, 27.031379],
                            [111.126747, 27.032769],
                            [111.12813, 27.035923],
                            [111.134365, 27.035719],
                            [111.134274, 27.037415],
                            [111.136655, 27.038076],
                            [111.137652, 27.043688],
                            [111.134932, 27.045163],
                            [111.13475, 27.053826],
                            [111.135907, 27.055267],
                            [111.137063, 27.052826],
                            [111.139375, 27.054928],
                            [111.139489, 27.059284],
                            [111.140645, 27.056267],
                            [111.141915, 27.055894],
                            [111.14094, 27.059335],
                            [111.142731, 27.059691],
                            [111.146223, 27.058182],
                            [111.147152, 27.055673],
                            [111.149488, 27.055572],
                            [111.151437, 27.059148],
                            [111.155201, 27.059301],
                            [111.156947, 27.062217],
                            [111.159985, 27.062725],
                            [111.159872, 27.059606],
                            [111.164452, 27.059691],
                            [111.166515, 27.063285],
                            [111.16978, 27.063691],
                            [111.173158, 27.066336],
                            [111.179007, 27.065895],
                            [111.181411, 27.066624],
                            [111.181864, 27.06459],
                            [111.183905, 27.066403],
                            [111.189868, 27.066488],
                            [111.190911, 27.062895],
                            [111.193631, 27.063607],
                            [111.197191, 27.067675],
                            [111.201249, 27.066403],
                            [111.202474, 27.068387],
                            [111.203449, 27.066353],
                            [111.205716, 27.066386],
                            [111.211316, 27.069963],
                            [111.213221, 27.071861],
                            [111.215805, 27.076776],
                            [111.215216, 27.083928],
                            [111.214332, 27.085132],
                            [111.214876, 27.088724],
                            [111.213878, 27.093147],
                            [111.218503, 27.099146],
                            [111.217891, 27.103925],
                            [111.218299, 27.108093],
                            [111.219773, 27.111482],
                            [111.215624, 27.108466],
                            [111.211974, 27.111973],
                            [111.212563, 27.114209],
                            [111.211566, 27.120512],
                            [111.212903, 27.124256],
                            [111.216191, 27.126391],
                            [111.221179, 27.123917],
                            [111.222358, 27.124493],
                            [111.221088, 27.126848],
                            [111.222018, 27.129288],
                            [111.227867, 27.132506],
                            [111.229681, 27.136047],
                            [111.23383, 27.137588],
                            [111.235576, 27.141552],
                            [111.23408, 27.145939],
                            [111.23612, 27.147446],
                            [111.236188, 27.15141],
                            [111.233536, 27.155017],
                            [111.232991, 27.158608],
                            [111.230497, 27.159048],
                            [111.228661, 27.16186],
                            [111.225328, 27.160894],
                            [111.223378, 27.162859],
                            [111.22136, 27.162401],
                            [111.221474, 27.167296],
                            [111.225645, 27.16738],
                            [111.226439, 27.170259],
                            [111.22882, 27.170954],
                            [111.228321, 27.172766],
                            [111.230135, 27.175983],
                            [111.22891, 27.177016],
                            [111.225872, 27.175983],
                            [111.226099, 27.178828],
                            [111.223083, 27.179488],
                            [111.222267, 27.181046],
                            [111.224172, 27.181926],
                            [111.224784, 27.184619],
                            [111.221156, 27.183772],
                            [111.222834, 27.185431],
                            [111.221065, 27.186227],
                            [111.221292, 27.189207],
                            [111.22433, 27.19366],
                            [111.22195, 27.195167],
                            [111.224308, 27.198198],
                            [111.227527, 27.196589],
                            [111.227142, 27.193626],
                            [111.231427, 27.192441],
                            [111.235463, 27.193914],
                            [111.229976, 27.198367],
                            [111.230225, 27.200195],
                            [111.226915, 27.203141],
                            [111.230089, 27.206256],
                            [111.23018, 27.208626],
                            [111.232515, 27.211148],
                            [111.23111, 27.211927],
                            [111.23213, 27.21643],
                            [111.230951, 27.220492],
                            [111.225555, 27.222134],
                            [111.224829, 27.225232],
                            [111.2273, 27.225012],
                            [111.227595, 27.228228],
                            [111.224965, 27.231596],
                            [111.225328, 27.235725],
                            [111.22365, 27.233982],
                            [111.220499, 27.239516],
                            [111.218889, 27.238467],
                            [111.218141, 27.242123],
                            [111.219546, 27.244712],
                            [111.225532, 27.246269],
                            [111.227867, 27.248621],
                            [111.227255, 27.249806],
                            [111.231903, 27.256727],
                            [111.233944, 27.261532],
                            [111.230815, 27.265001],
                            [111.227437, 27.271176],
                            [111.227935, 27.273579],
                            [111.231744, 27.278384],
                            [111.232152, 27.282122],
                            [111.225623, 27.283933],
                            [111.223129, 27.285709],
                            [111.222834, 27.289718],
                            [111.220839, 27.291613],
                            [111.216667, 27.292154],
                            [111.215261, 27.295723],
                            [111.213379, 27.297415],
                            [111.216077, 27.300172],
                            [111.215239, 27.303487],
                            [111.211044, 27.304028],
                            [111.204673, 27.303961],
                            [111.203177, 27.304586],
                            [111.203177, 27.307343],
                            [111.204786, 27.313043],
                            [111.202587, 27.31492],
                            [111.193722, 27.315495],
                            [111.190026, 27.317457],
                            [111.191319, 27.320789],
                            [111.187895, 27.326471],
                            [111.185311, 27.331747],
                            [111.182726, 27.33496],
                            [111.17962, 27.336786],
                            [111.175516, 27.336786],
                            [111.159554, 27.333996],
                            [111.153138, 27.333979],
                            [111.147719, 27.336262],
                            [111.145905, 27.340387],
                            [111.145361, 27.348655],
                            [111.146109, 27.352781],
                            [111.1479, 27.355553],
                            [111.151233, 27.355553],
                            [111.161549, 27.353744],
                            [111.168487, 27.353305],
                            [111.172364, 27.354911],
                            [111.17674, 27.354421],
                            [111.182635, 27.357514],
                            [111.183338, 27.359661],
                            [111.179144, 27.364834],
                            [111.179007, 27.366711],
                            [111.175221, 27.367979],
                            [111.172228, 27.36994],
                            [111.172138, 27.374588],
                            [111.168941, 27.378966],
                            [111.166265, 27.379777],
                            [111.16266, 27.378171],
                            [111.161164, 27.380758],
                            [111.161481, 27.38282],
                            [111.15969, 27.383715],
                            [111.161028, 27.386961],
                            [111.16028, 27.388076],
                            [111.156403, 27.388363],
                            [111.148603, 27.387755],
                            [111.146812, 27.389192],
                            [111.146019, 27.392301],
                            [111.147424, 27.394617],
                            [111.145883, 27.402205],
                            [111.147696, 27.40648],
                            [111.153591, 27.408879],
                            [111.157468, 27.407342],
                            [111.161368, 27.409741],
                            [111.163273, 27.415081],
                            [111.169099, 27.41944],
                            [111.169326, 27.422904],
                            [111.167218, 27.424256],
                            [111.15994, 27.425083],
                            [111.154657, 27.428209],
                            [111.152775, 27.43125],
                            [111.154271, 27.433734],
                            [111.164701, 27.440035],
                            [111.163318, 27.44414],
                            [111.161209, 27.444427],
                            [111.156629, 27.443008],
                            [111.156833, 27.445762],
                            [111.160733, 27.447806],
                            [111.166107, 27.449123],
                            [111.168011, 27.453228],
                            [111.170505, 27.454022],
                            [111.174609, 27.453295],
                            [111.177488, 27.451944],
                            [111.179393, 27.452214],
                            [111.182454, 27.456657],
                            [111.187238, 27.45816],
                            [111.188643, 27.459579],
                            [111.185651, 27.463058],
                            [111.187736, 27.465288],
                            [111.190049, 27.465017],
                            [111.193541, 27.46723],
                            [111.192452, 27.46902],
                            [111.185265, 27.473327],
                            [111.183519, 27.474931],
                            [111.183519, 27.47743],
                            [111.185333, 27.479997],
                            [111.185923, 27.483037],
                            [111.187827, 27.48454],
                            [111.191523, 27.484438],
                            [111.191727, 27.485519],
                            [111.187147, 27.48812],
                            [111.186943, 27.491683],
                            [111.190004, 27.496393],
                            [111.192997, 27.498251],
                            [111.198778, 27.499399],
                            [111.200297, 27.500817],
                            [111.201499, 27.504565],
                            [111.201408, 27.508921],
                            [111.196874, 27.516602],
                            [111.197486, 27.519641],
                            [111.202587, 27.527288],
                            [111.202292, 27.532554],
                            [111.199413, 27.538445],
                            [111.197894, 27.54415],
                            [111.197395, 27.549179],
                            [111.195831, 27.551356],
                            [111.192566, 27.552976],
                            [111.187283, 27.553803],
                            [111.184562, 27.555339],
                            [111.182408, 27.558646],
                            [111.182136, 27.563742],
                            [111.176763, 27.56084],
                            [111.173883, 27.559979],
                            [111.169485, 27.562629],
                            [111.168623, 27.566307],
                            [111.166197, 27.569901],
                            [111.165948, 27.57223],
                            [111.170619, 27.577224],
                            [111.169213, 27.578203],
                            [111.16715, 27.575976],
                            [111.163454, 27.576448],
                            [111.162048, 27.572804],
                            [111.1574, 27.571167],
                            [111.154339, 27.573343],
                            [111.155246, 27.576583],
                            [111.157536, 27.578574],
                            [111.1501, 27.582168],
                            [111.147696, 27.581577],
                            [111.146404, 27.578962],
                            [111.143139, 27.578017],
                            [111.134864, 27.581392],
                            [111.130216, 27.581375],
                            [111.123777, 27.578608],
                            [111.116295, 27.577207],
                            [111.113188, 27.578793],
                            [111.111873, 27.58453],
                            [111.106931, 27.590704],
                            [111.098678, 27.590215],
                            [111.093236, 27.589034],
                            [111.084961, 27.58922],
                            [111.080313, 27.588747],
                            [111.074101, 27.591463],
                            [111.072015, 27.593977],
                            [111.07147, 27.59924],
                            [111.07376, 27.605465],
                            [111.073216, 27.612566],
                            [111.070858, 27.620594],
                            [111.066165, 27.625603],
                            [111.05993, 27.631303],
                            [111.050589, 27.632855],
                            [111.044626, 27.632113],
                            [111.037371, 27.632062],
                            [111.034514, 27.633883],
                            [111.0288, 27.635435],
                            [111.025739, 27.635654],
                            [111.017668, 27.632838],
                            [111.011728, 27.627964],
                            [111.0081, 27.625873],
                            [110.996945, 27.626935],
                            [110.994088, 27.630123],
                            [110.998441, 27.63933],
                            [110.996854, 27.64621],
                            [110.994746, 27.652381],
                            [110.990053, 27.656478],
                            [110.983795, 27.660103],
                            [110.975497, 27.659799],
                            [110.966972, 27.65722],
                            [110.957358, 27.656225],
                            [110.945727, 27.653848],
                            [110.938472, 27.653561],
                            [110.928881, 27.655568],
                            [110.9175, 27.656411],
                            [110.907932, 27.654977],
                            [110.889363, 27.651201],
                            [110.882901, 27.650712],
                            [110.877482, 27.651605],
                            [110.876485, 27.65292],
                            [110.87127, 27.653645],
                            [110.869184, 27.655466],
                            [110.868957, 27.653157],
                            [110.862269, 27.65083],
                            [110.85558, 27.647356],
                            [110.853857, 27.641691],
                            [110.854492, 27.638167],
                            [110.854265, 27.630679],
                            [110.852315, 27.627576],
                            [110.861589, 27.624912],
                            [110.861838, 27.623512],
                            [110.857303, 27.621387],
                            [110.850184, 27.624186],
                            [110.84565, 27.624271],
                            [110.84735, 27.622011],
                            [110.844516, 27.620965],
                            [110.846035, 27.617575],
                            [110.846284, 27.613055],
                            [110.847667, 27.609007],
                            [110.851862, 27.605869],
                            [110.854605, 27.601821],
                            [110.854333, 27.594146],
                            [110.853472, 27.592037],
                            [110.84998, 27.590401],
                            [110.842679, 27.58841],
                            [110.839006, 27.58593],
                            [110.833316, 27.583686],
                            [110.824315, 27.578827],
                            [110.818964, 27.573529],
                            [110.813046, 27.56845],
                            [110.808693, 27.563388],
                            [110.80409, 27.561059],
                            [110.799442, 27.559439],
                            [110.793003, 27.55895],
                            [110.786791, 27.560519],
                            [110.783685, 27.564164],
                            [110.776906, 27.574002],
                            [110.767043, 27.58129],
                            [110.75582, 27.596828],
                            [110.748996, 27.60258],
                            [110.746638, 27.603845],
                            [110.742398, 27.603592],
                            [110.737931, 27.600674],
                            [110.736866, 27.602192],
                            [110.736866, 27.606392],
                            [110.737999, 27.609867],
                            [110.74165, 27.614084],
                            [110.743667, 27.617339],
                            [110.741718, 27.620813],
                            [110.74106, 27.62567],
                            [110.737296, 27.627104],
                            [110.734938, 27.62594],
                            [110.732989, 27.623326],
                            [110.732853, 27.619919],
                            [110.734145, 27.616378],
                            [110.731538, 27.616664],
                            [110.723489, 27.616057],
                            [110.714442, 27.616023],
                            [110.712991, 27.610373],
                            [110.71052, 27.606949],
                            [110.705328, 27.604706],
                            [110.693425, 27.602749],
                            [110.685648, 27.603373],
                            [110.680229, 27.602631],
                            [110.674039, 27.60005],
                            [110.66989, 27.593707],
                            [110.666603, 27.592678],
                            [110.662567, 27.587583],
                            [110.662204, 27.585643],
                            [110.657579, 27.583062],
                            [110.657216, 27.578253],
                            [110.661411, 27.57417],
                            [110.661433, 27.572888],
                            [110.657171, 27.573698],
                            [110.655108, 27.57525],
                            [110.653044, 27.574187],
                            [110.652115, 27.569547],
                            [110.652772, 27.565835],
                            [110.638126, 27.555912],
                            [110.636675, 27.551339],
                            [110.63307, 27.546884],
                            [110.631324, 27.540453],
                            [110.632639, 27.536099],
                            [110.639667, 27.532943],
                            [110.648215, 27.529584],
                            [110.653702, 27.524587],
                            [110.65971, 27.517514],
                            [110.671953, 27.506152],
                            [110.68032, 27.497052],
                            [110.682678, 27.492257],
                            [110.683993, 27.4879],
                            [110.684628, 27.475049],
                            [110.684673, 27.468716],
                            [110.686215, 27.466841],
                            [110.690432, 27.459494],
                            [110.693311, 27.455846],
                            [110.693357, 27.4524],
                            [110.691588, 27.447569],
                            [110.688233, 27.44387],
                            [110.674107, 27.434545],
                            [110.668122, 27.427516],
                            [110.663088, 27.420674],
                            [110.660322, 27.412158],
                            [110.659574, 27.40675],
                            [110.660186, 27.405686],
                            [110.659665, 27.396763],
                            [110.655652, 27.383648],
                            [110.654904, 27.3802],
                            [110.651072, 27.374875],
                            [110.649281, 27.373709],
                            [110.649303, 27.368891],
                            [110.64826, 27.367286]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430525,
                "name": "洞口县",
                "center": [110.579212, 27.062286],
                "centroid": [110.592108, 27.09772],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430500
                },
                "subFeatureIndex": 6,
                "acroutes": [100000, 430000, 430500]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [110.919608, 27.027055],
                            [110.920923, 27.035618],
                            [110.918905, 27.040314],
                            [110.916411, 27.041806],
                            [110.910584, 27.043247],
                            [110.908499, 27.04679],
                            [110.907977, 27.050723],
                            [110.905256, 27.052707],
                            [110.897661, 27.050554],
                            [110.890496, 27.047452],
                            [110.886551, 27.050181],
                            [110.884012, 27.054436],
                            [110.884511, 27.058776],
                            [110.883717, 27.062505],
                            [110.883944, 27.074217],
                            [110.882833, 27.077014],
                            [110.877505, 27.082573],
                            [110.866305, 27.08864],
                            [110.863652, 27.091317],
                            [110.861067, 27.09535],
                            [110.856986, 27.10428],
                            [110.851771, 27.119258],
                            [110.848484, 27.119529],
                            [110.845899, 27.124544],
                            [110.844131, 27.125696],
                            [110.839619, 27.122664],
                            [110.834086, 27.121867],
                            [110.822387, 27.121952],
                            [110.814701, 27.121427],
                            [110.813885, 27.122613],
                            [110.813749, 27.131998],
                            [110.813273, 27.1342],
                            [110.810734, 27.137554],
                            [110.810484, 27.141264],
                            [110.812117, 27.142839],
                            [110.807854, 27.145092],
                            [110.807083, 27.147548],
                            [110.803569, 27.149462],
                            [110.798059, 27.150817],
                            [110.792459, 27.151207],
                            [110.785408, 27.15307],
                            [110.778561, 27.149225],
                            [110.773051, 27.148446],
                            [110.778992, 27.153222],
                            [110.777994, 27.155627],
                            [110.775092, 27.157371],
                            [110.776407, 27.160826],
                            [110.779468, 27.16462],
                            [110.776838, 27.165958],
                            [110.775704, 27.168278],
                            [110.778584, 27.171174],
                            [110.778584, 27.172461],
                            [110.775817, 27.173697],
                            [110.776679, 27.175831],
                            [110.781214, 27.175949],
                            [110.78466, 27.178371],
                            [110.786156, 27.174374],
                            [110.790169, 27.17854],
                            [110.791734, 27.182519],
                            [110.793298, 27.184094],
                            [110.796608, 27.184483],
                            [110.798309, 27.18069],
                            [110.800826, 27.178879],
                            [110.80366, 27.17876],
                            [110.805088, 27.179996],
                            [110.808988, 27.177981],
                            [110.81418, 27.17876],
                            [110.817377, 27.178032],
                            [110.822886, 27.180826],
                            [110.828078, 27.180047],
                            [110.829121, 27.182502],
                            [110.833746, 27.18384],
                            [110.833905, 27.185262],
                            [110.827988, 27.189529],
                            [110.828962, 27.193051],
                            [110.83055, 27.193711],
                            [110.830209, 27.195793],
                            [110.833452, 27.194659],
                            [110.833882, 27.196352],
                            [110.831456, 27.196251],
                            [110.830708, 27.198164],
                            [110.826627, 27.199264],
                            [110.829371, 27.195048],
                            [110.825766, 27.197165],
                            [110.823498, 27.200026],
                            [110.820914, 27.199112],
                            [110.820687, 27.206442],
                            [110.817535, 27.209845],
                            [110.816175, 27.213112],
                            [110.813091, 27.215448],
                            [110.808444, 27.216701],
                            [110.805405, 27.222083],
                            [110.801755, 27.226366],
                            [110.800758, 27.22904],
                            [110.801732, 27.232696],
                            [110.799828, 27.234828],
                            [110.796314, 27.23559],
                            [110.79849, 27.241784],
                            [110.797991, 27.243984],
                            [110.795656, 27.245761],
                            [110.795452, 27.248012],
                            [110.797198, 27.250753],
                            [110.797833, 27.255864],
                            [110.794228, 27.260331],
                            [110.793502, 27.268317],
                            [110.79187, 27.270939],
                            [110.786202, 27.274882],
                            [110.779445, 27.278434],
                            [110.777291, 27.281074],
                            [110.777246, 27.288652],
                            [110.778357, 27.294353],
                            [110.777858, 27.302861],
                            [110.780647, 27.31881],
                            [110.780556, 27.32571],
                            [110.781826, 27.333928],
                            [110.783413, 27.346931],
                            [110.783118, 27.350634],
                            [110.781803, 27.35437],
                            [110.778085, 27.357075],
                            [110.772349, 27.359881],
                            [110.764594, 27.360033],
                            [110.757407, 27.356466],
                            [110.746819, 27.352375],
                            [110.74208, 27.346627],
                            [110.738634, 27.343989],
                            [110.736526, 27.336076],
                            [110.734576, 27.335315],
                            [110.722514, 27.33594],
                            [110.716256, 27.337395],
                            [110.714646, 27.337124],
                            [110.7112, 27.333455],
                            [110.706756, 27.333015],
                            [110.705804, 27.3366],
                            [110.705645, 27.34174],
                            [110.70204, 27.343093],
                            [110.697846, 27.34267],
                            [110.692767, 27.343769],
                            [110.689366, 27.342873],
                            [110.68007, 27.343059],
                            [110.677803, 27.34431],
                            [110.674697, 27.348131],
                            [110.673336, 27.351631],
                            [110.674311, 27.355029],
                            [110.673949, 27.361707],
                            [110.666943, 27.369618],
                            [110.663655, 27.370903],
                            [110.660209, 27.369466],
                            [110.656332, 27.369635],
                            [110.64826, 27.367286],
                            [110.649213, 27.365206],
                            [110.640846, 27.358039],
                            [110.636516, 27.353643],
                            [110.632208, 27.343262],
                            [110.628127, 27.337953],
                            [110.622436, 27.333336],
                            [110.62196, 27.330326],
                            [110.614863, 27.324103],
                            [110.606542, 27.321059],
                            [110.605409, 27.319943],
                            [110.605749, 27.316831],
                            [110.609989, 27.309525],
                            [110.609989, 27.307834],
                            [110.607517, 27.304722],
                            [110.606384, 27.301829],
                            [110.609966, 27.295994],
                            [110.609671, 27.290936],
                            [110.608674, 27.288602],
                            [110.605613, 27.287028],
                            [110.598698, 27.281463],
                            [110.585071, 27.278604],
                            [110.578904, 27.278553],
                            [110.574007, 27.281057],
                            [110.57115, 27.284237],
                            [110.566457, 27.291325],
                            [110.562285, 27.296366],
                            [110.558658, 27.299326],
                            [110.556572, 27.302523],
                            [110.553942, 27.30824],
                            [110.550087, 27.307986],
                            [110.540859, 27.305178],
                            [110.535758, 27.302404],
                            [110.524286, 27.293372],
                            [110.526394, 27.289921],
                            [110.521066, 27.282545],
                            [110.516758, 27.278164],
                            [110.511657, 27.273765],
                            [110.504515, 27.270297],
                            [110.493768, 27.266557],
                            [110.483543, 27.262141],
                            [110.475879, 27.257962],
                            [110.467468, 27.252395],
                            [110.462117, 27.247081],
                            [110.459872, 27.239719],
                            [110.459487, 27.228921],
                            [110.45629, 27.213281],
                            [110.45044, 27.206815],
                            [110.443049, 27.201245],
                            [110.434842, 27.199349],
                            [110.424049, 27.199501],
                            [110.408292, 27.206476],
                            [110.397137, 27.212807],
                            [110.391718, 27.214094],
                            [110.387138, 27.218817],
                            [110.380087, 27.222693],
                            [110.376731, 27.223201],
                            [110.37129, 27.220154],
                            [110.36646, 27.215211],
                            [110.365055, 27.211792],
                            [110.362787, 27.209743],
                            [110.357777, 27.208948],
                            [110.354671, 27.205122],
                            [110.352131, 27.199044],
                            [110.352109, 27.195946],
                            [110.353242, 27.191645],
                            [110.356416, 27.184416],
                            [110.356779, 27.181317],
                            [110.355895, 27.175272],
                            [110.35297, 27.170242],
                            [110.349751, 27.166195],
                            [110.346486, 27.160301],
                            [110.345828, 27.154983],
                            [110.342722, 27.150512],
                            [110.338913, 27.149818],
                            [110.331771, 27.151461],
                            [110.331884, 27.155153],
                            [110.330932, 27.156542],
                            [110.325785, 27.158794],
                            [110.320163, 27.158693],
                            [110.314358, 27.157964],
                            [110.31191, 27.159285],
                            [110.30708, 27.164214],
                            [110.306378, 27.166449],
                            [110.304632, 27.166991],
                            [110.297603, 27.161233],
                            [110.293908, 27.163435],
                            [110.292343, 27.163418],
                            [110.287673, 27.160776],
                            [110.278127, 27.158066],
                            [110.274205, 27.156203],
                            [110.267063, 27.153866],
                            [110.260443, 27.15268],
                            [110.256271, 27.152443],
                            [110.249492, 27.151071],
                            [110.245569, 27.147751],
                            [110.239584, 27.140078],
                            [110.236931, 27.13769],
                            [110.231353, 27.134539],
                            [110.226524, 27.130355],
                            [110.21784, 27.126137],
                            [110.206844, 27.122359],
                            [110.200473, 27.118174],
                            [110.19748, 27.112413],
                            [110.194193, 27.102078],
                            [110.196097, 27.098621],
                            [110.204395, 27.092029],
                            [110.208363, 27.081268],
                            [110.205869, 27.07459],
                            [110.203919, 27.067454],
                            [110.201403, 27.061912],
                            [110.198115, 27.059369],
                            [110.183287, 27.055317],
                            [110.175102, 27.052266],
                            [110.162609, 27.046417],
                            [110.155059, 27.03772],
                            [110.151976, 27.030718],
                            [110.150344, 27.022867],
                            [110.151273, 27.016084],
                            [110.153994, 27.009487],
                            [110.153631, 26.998413],
                            [110.150638, 26.994834],
                            [110.14853, 26.989695],
                            [110.151863, 26.984403],
                            [110.156533, 26.982248],
                            [110.165806, 26.980993],
                            [110.175873, 26.975836],
                            [110.182494, 26.974852],
                            [110.18755, 26.973461],
                            [110.193671, 26.972732],
                            [110.200904, 26.973767],
                            [110.207683, 26.975294],
                            [110.219495, 26.975209],
                            [110.224665, 26.97073],
                            [110.224778, 26.961128],
                            [110.229403, 26.951915],
                            [110.23845, 26.951338],
                            [110.2443, 26.950388],
                            [110.256021, 26.952628],
                            [110.265136, 26.959329],
                            [110.269444, 26.967185],
                            [110.273253, 26.975294],
                            [110.281438, 26.988033],
                            [110.284068, 26.993426],
                            [110.283659, 27.004247],
                            [110.289509, 27.016169],
                            [110.294746, 27.022596],
                            [110.29885, 27.026784],
                            [110.302909, 27.034431],
                            [110.305199, 27.042823],
                            [110.305085, 27.045349],
                            [110.308645, 27.058064],
                            [110.308826, 27.061284],
                            [110.310504, 27.067878],
                            [110.309847, 27.074556],
                            [110.306922, 27.08181],
                            [110.305516, 27.088538],
                            [110.304269, 27.098231],
                            [110.308123, 27.107263],
                            [110.315832, 27.116395],
                            [110.318893, 27.116497],
                            [110.327123, 27.114464],
                            [110.330683, 27.111905],
                            [110.332633, 27.112346],
                            [110.337847, 27.116039],
                            [110.341294, 27.117649],
                            [110.347529, 27.122443],
                            [110.352199, 27.122731],
                            [110.356099, 27.124171],
                            [110.358797, 27.126899],
                            [110.355872, 27.13171],
                            [110.360429, 27.136081],
                            [110.367413, 27.136877],
                            [110.368728, 27.137791],
                            [110.369907, 27.142467],
                            [110.372605, 27.143229],
                            [110.375892, 27.140671],
                            [110.379747, 27.135776],
                            [110.385687, 27.133319],
                            [110.393849, 27.128],
                            [110.40149, 27.121054],
                            [110.403712, 27.117903],
                            [110.40319, 27.112769],
                            [110.401898, 27.107144],
                            [110.393441, 27.09713],
                            [110.382762, 27.090504],
                            [110.377593, 27.088013],
                            [110.372401, 27.08808],
                            [110.367435, 27.089097],
                            [110.364601, 27.091385],
                            [110.359228, 27.08964],
                            [110.356371, 27.087267],
                            [110.355714, 27.084742],
                            [110.362039, 27.078149],
                            [110.36408, 27.079505],
                            [110.362629, 27.082166],
                            [110.363694, 27.084318],
                            [110.368116, 27.086742],
                            [110.371403, 27.087064],
                            [110.373988, 27.086216],
                            [110.373353, 27.081234],
                            [110.370882, 27.081285],
                            [110.370791, 27.079183],
                            [110.368501, 27.079132],
                            [110.36612, 27.07659],
                            [110.366506, 27.073336],
                            [110.369567, 27.070166],
                            [110.371879, 27.066437],
                            [110.379905, 27.06542],
                            [110.381107, 27.063539],
                            [110.378681, 27.05864],
                            [110.37893, 27.056385],
                            [110.381538, 27.051554],
                            [110.375167, 27.045807],
                            [110.37884, 27.04045],
                            [110.385891, 27.041331],
                            [110.389587, 27.040535],
                            [110.395527, 27.03672],
                            [110.400198, 27.039093],
                            [110.403576, 27.039263],
                            [110.407271, 27.040738],
                            [110.411285, 27.044146],
                            [110.417111, 27.043959],
                            [110.423528, 27.04162],
                            [110.429128, 27.041297],
                            [110.432007, 27.039619],
                            [110.434025, 27.027717],
                            [110.430488, 27.02653],
                            [110.433753, 27.024122],
                            [110.433141, 27.022579],
                            [110.430148, 27.021188],
                            [110.425183, 27.022494],
                            [110.424593, 27.018255],
                            [110.423052, 27.016101],
                            [110.42058, 27.01505],
                            [110.419016, 27.016712],
                            [110.414617, 27.01427],
                            [110.414255, 27.012472],
                            [110.417656, 27.013235],
                            [110.421601, 27.01271],
                            [110.424004, 27.010217],
                            [110.428675, 27.006927],
                            [110.434161, 27.00094],
                            [110.435408, 26.996734],
                            [110.432075, 26.993155],
                            [110.431713, 26.990102],
                            [110.432166, 26.984572],
                            [110.43398, 26.982808],
                            [110.438174, 26.983724],
                            [110.44153, 26.983283],
                            [110.447221, 26.977516],
                            [110.453683, 26.976294],
                            [110.453388, 26.972834],
                            [110.449942, 26.967676],
                            [110.449488, 26.965471],
                            [110.452413, 26.961603],
                            [110.467626, 26.954613],
                            [110.477172, 26.948063],
                            [110.480164, 26.945145],
                            [110.482636, 26.940987],
                            [110.481049, 26.933419],
                            [110.476446, 26.930738],
                            [110.47563, 26.929024],
                            [110.477534, 26.926088],
                            [110.487102, 26.914869],
                            [110.490639, 26.913647],
                            [110.493632, 26.913647],
                            [110.495242, 26.917313],
                            [110.496013, 26.925629],
                            [110.500071, 26.927327],
                            [110.506079, 26.920504],
                            [110.509095, 26.916346],
                            [110.510478, 26.910252],
                            [110.51567, 26.901307],
                            [110.520159, 26.898217],
                            [110.525555, 26.890901],
                            [110.52721, 26.884942],
                            [110.52687, 26.883295],
                            [110.52195, 26.879373],
                            [110.51669, 26.871036],
                            [110.514038, 26.864041],
                            [110.5144, 26.862461],
                            [110.520431, 26.864126],
                            [110.526893, 26.863378],
                            [110.530453, 26.861544],
                            [110.532675, 26.858131],
                            [110.535509, 26.855584],
                            [110.540021, 26.853631],
                            [110.540814, 26.857027],
                            [110.540383, 26.861833],
                            [110.538161, 26.865518],
                            [110.538456, 26.870255],
                            [110.541109, 26.877421],
                            [110.5485, 26.880748],
                            [110.553307, 26.883872],
                            [110.556617, 26.888643],
                            [110.557751, 26.88866],
                            [110.564439, 26.885791],
                            [110.569949, 26.889084],
                            [110.584323, 26.893447],
                            [110.594299, 26.901018],
                            [110.598947, 26.905635],
                            [110.604048, 26.909302],
                            [110.620917, 26.911729],
                            [110.62858, 26.910677],
                            [110.632571, 26.908657],
                            [110.636198, 26.904821],
                            [110.639441, 26.902835],
                            [110.645064, 26.902105],
                            [110.653316, 26.90219],
                            [110.658577, 26.899881],
                            [110.666149, 26.89287],
                            [110.672316, 26.888456],
                            [110.677644, 26.882938],
                            [110.683358, 26.87922],
                            [110.688505, 26.87922],
                            [110.693969, 26.88012],
                            [110.69873, 26.882191],
                            [110.698458, 26.886113],
                            [110.694694, 26.900543],
                            [110.696213, 26.904142],
                            [110.699796, 26.908114],
                            [110.70458, 26.91066],
                            [110.718591, 26.912068],
                            [110.734077, 26.912493],
                            [110.74174, 26.91604],
                            [110.748225, 26.921013],
                            [110.750968, 26.932146],
                            [110.759584, 26.942532],
                            [110.766726, 26.948793],
                            [110.772757, 26.947961],
                            [110.776226, 26.948623],
                            [110.78042, 26.951033],
                            [110.781486, 26.952695],
                            [110.780987, 26.957412],
                            [110.782597, 26.961891],
                            [110.782007, 26.966591],
                            [110.793752, 26.970425],
                            [110.798354, 26.971069],
                            [110.802662, 26.970391],
                            [110.803569, 26.969356],
                            [110.801392, 26.968067],
                            [110.80332, 26.96525],
                            [110.806675, 26.965573],
                            [110.809555, 26.961077],
                            [110.818034, 26.96089],
                            [110.825267, 26.962129],
                            [110.827806, 26.95558],
                            [110.827806, 26.959499],
                            [110.832046, 26.959177],
                            [110.835424, 26.952628],
                            [110.83946, 26.952526],
                            [110.843541, 26.95636],
                            [110.840594, 26.958617],
                            [110.843972, 26.958973],
                            [110.8532, 26.950982],
                            [110.852021, 26.944687],
                            [110.857553, 26.944245],
                            [110.857507, 26.940716],
                            [110.867347, 26.935472],
                            [110.869252, 26.93929],
                            [110.876281, 26.937118],
                            [110.875714, 26.934301],
                            [110.868799, 26.926155],
                            [110.869819, 26.922184],
                            [110.872902, 26.918128],
                            [110.878344, 26.914631],
                            [110.884647, 26.912493],
                            [110.904576, 26.910863],
                            [110.905211, 26.913902],
                            [110.907252, 26.911644],
                            [110.907297, 26.913698],
                            [110.909723, 26.914462],
                            [110.908317, 26.924781],
                            [110.910244, 26.926851],
                            [110.913419, 26.932435],
                            [110.921377, 26.937729],
                            [110.925526, 26.936406],
                            [110.928723, 26.936796],
                            [110.933484, 26.938578],
                            [110.936363, 26.937611],
                            [110.941691, 26.93924],
                            [110.950511, 26.943363],
                            [110.95101, 26.944279],
                            [110.950058, 26.949404],
                            [110.95135, 26.951966],
                            [110.956678, 26.953764],
                            [110.957358, 26.957565],
                            [110.959467, 26.962298],
                            [110.956837, 26.964063],
                            [110.945002, 26.964165],
                            [110.941102, 26.965963],
                            [110.934844, 26.97414],
                            [110.922646, 26.985302],
                            [110.919359, 26.990289],
                            [110.9175, 26.997734],
                            [110.917976, 27.009199],
                            [110.91453, 27.016033],
                            [110.912784, 27.025088],
                            [110.919608, 27.027055]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430527,
                "name": "绥宁县",
                "center": [110.155075, 26.580622],
                "centroid": [110.199275, 26.707518],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430500
                },
                "subFeatureIndex": 7,
                "acroutes": [100000, 430000, 430500]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [110.14853, 26.989695],
                            [110.146262, 26.986879],
                            [110.142771, 26.98788],
                            [110.138622, 26.987795],
                            [110.135584, 26.985743],
                            [110.132432, 26.986353],
                            [110.125539, 26.984793],
                            [110.116266, 26.978008],
                            [110.112231, 26.971069],
                            [110.104794, 26.951915],
                            [110.102776, 26.948912],
                            [110.099715, 26.947961],
                            [110.09611, 26.948606],
                            [110.089694, 26.948759],
                            [110.083799, 26.946163],
                            [110.075886, 26.9417],
                            [110.072168, 26.93201],
                            [110.073505, 26.928345],
                            [110.077428, 26.923576],
                            [110.08491, 26.919995],
                            [110.090125, 26.915463],
                            [110.097425, 26.906365],
                            [110.097765, 26.899253],
                            [110.09704, 26.896028],
                            [110.093253, 26.891155],
                            [110.089172, 26.88905],
                            [110.078221, 26.882022],
                            [110.068472, 26.880969],
                            [110.063575, 26.882056],
                            [110.059471, 26.880392],
                            [110.055934, 26.876215],
                            [110.048701, 26.860729],
                            [110.04682, 26.845768],
                            [110.045913, 26.842117],
                            [110.042308, 26.836597],
                            [110.042988, 26.832724],
                            [110.048724, 26.825012],
                            [110.050447, 26.824163],
                            [110.05743, 26.822702],
                            [110.057771, 26.818184],
                            [110.056206, 26.81358],
                            [110.056524, 26.80884],
                            [110.060106, 26.802775],
                            [110.061648, 26.793481],
                            [110.064935, 26.788945],
                            [110.067316, 26.787466],
                            [110.068608, 26.784629],
                            [110.064958, 26.775945],
                            [110.063484, 26.769912],
                            [110.068223, 26.765289],
                            [110.069402, 26.761703],
                            [110.068268, 26.759647],
                            [110.063529, 26.761499],
                            [110.061693, 26.760327],
                            [110.063484, 26.756689],
                            [110.064096, 26.750944],
                            [110.063076, 26.749398],
                            [110.059108, 26.747273],
                            [110.050152, 26.749075],
                            [110.049268, 26.747579],
                            [110.054438, 26.743125],
                            [110.056546, 26.740201],
                            [110.055911, 26.738366],
                            [110.052647, 26.73755],
                            [110.050311, 26.735629],
                            [110.048361, 26.730716],
                            [110.047477, 26.724663],
                            [110.05115, 26.72349],
                            [110.054324, 26.720362],
                            [110.060809, 26.717539],
                            [110.063348, 26.717828],
                            [110.063983, 26.720294],
                            [110.067293, 26.718407],
                            [110.067611, 26.712745],
                            [110.071556, 26.709854],
                            [110.072735, 26.705671],
                            [110.0721, 26.700144],
                            [110.068427, 26.698852],
                            [110.065185, 26.695383],
                            [110.061988, 26.695026],
                            [110.056206, 26.697866],
                            [110.050515, 26.69642],
                            [110.044167, 26.698121],
                            [110.041832, 26.695519],
                            [110.03868, 26.684992],
                            [110.036889, 26.681948],
                            [110.028749, 26.675518],
                            [110.025326, 26.671691],
                            [110.025258, 26.669752],
                            [110.02188, 26.668272],
                            [110.022084, 26.665704],
                            [110.02036, 26.662863],
                            [110.017776, 26.662234],
                            [110.011201, 26.664938],
                            [110.00526, 26.664343],
                            [110.002018, 26.666605],
                            [110.002132, 26.668],
                            [110.005464, 26.669225],
                            [110.007709, 26.672576],
                            [110.006621, 26.673307],
                            [110.000522, 26.672712],
                            [109.997756, 26.674294],
                            [109.997484, 26.67773],
                            [109.995556, 26.68006],
                            [109.991589, 26.681948],
                            [109.987598, 26.685349],
                            [109.983154, 26.686267],
                            [109.977532, 26.685893],
                            [109.97667, 26.688989],
                            [109.973405, 26.690349],
                            [109.969505, 26.690536],
                            [109.963588, 26.691829],
                            [109.954111, 26.690315],
                            [109.950007, 26.68887],
                            [109.946198, 26.686063],
                            [109.943931, 26.683274],
                            [109.941958, 26.677747],
                            [109.937877, 26.675161],
                            [109.934068, 26.673732],
                            [109.928649, 26.675722],
                            [109.919421, 26.676284],
                            [109.914025, 26.676216],
                            [109.909445, 26.67409],
                            [109.904616, 26.671045],
                            [109.901079, 26.668017],
                            [109.899855, 26.664326],
                            [109.89897, 26.655582],
                            [109.896431, 26.654409],
                            [109.885435, 26.653813],
                            [109.876502, 26.651193],
                            [109.861764, 26.645511],
                            [109.858205, 26.643401],
                            [109.855008, 26.63532],
                            [109.85358, 26.62865],
                            [109.851471, 26.621809],
                            [109.847594, 26.62009],
                            [109.844012, 26.61958],
                            [109.840407, 26.622081],
                            [109.834512, 26.622473],
                            [109.83068, 26.621503],
                            [109.82746, 26.616415],
                            [109.826758, 26.611581],
                            [109.826848, 26.605846],
                            [109.832562, 26.599242],
                            [109.840293, 26.594511],
                            [109.849113, 26.58634],
                            [109.856844, 26.580927],
                            [109.859815, 26.583345],
                            [109.861696, 26.583668],
                            [109.863102, 26.580927],
                            [109.866752, 26.576263],
                            [109.872058, 26.571037],
                            [109.872012, 26.568381],
                            [109.865165, 26.562677],
                            [109.863873, 26.556259],
                            [109.861968, 26.550963],
                            [109.862898, 26.543301],
                            [109.862671, 26.539861],
                            [109.866458, 26.537409],
                            [109.86911, 26.537119],
                            [109.872307, 26.53889],
                            [109.875527, 26.535927],
                            [109.879154, 26.534616],
                            [109.884936, 26.530477],
                            [109.890287, 26.531721],
                            [109.892554, 26.525487],
                            [109.887679, 26.524466],
                            [109.88378, 26.522779],
                            [109.883099, 26.518828],
                            [109.879699, 26.51738],
                            [109.879789, 26.515984],
                            [109.88344, 26.513514],
                            [109.88319, 26.509954],
                            [109.880673, 26.507978],
                            [109.878338, 26.503805],
                            [109.879381, 26.497042],
                            [109.877341, 26.497621],
                            [109.875776, 26.494112],
                            [109.871967, 26.492238],
                            [109.866888, 26.490807],
                            [109.8641, 26.489154],
                            [109.862354, 26.486854],
                            [109.857661, 26.475763],
                            [109.856482, 26.469561],
                            [109.8563, 26.465199],
                            [109.860177, 26.463614],
                            [109.868045, 26.465999],
                            [109.870833, 26.467874],
                            [109.875413, 26.468163],
                            [109.88158, 26.46571],
                            [109.891398, 26.459388],
                            [109.896748, 26.459916],
                            [109.902847, 26.461825],
                            [109.910443, 26.466749],
                            [109.915476, 26.472321],
                            [109.919036, 26.474672],
                            [109.928468, 26.477075],
                            [109.932571, 26.476001],
                            [109.934159, 26.472338],
                            [109.934771, 26.466834],
                            [109.933796, 26.462012],
                            [109.934907, 26.456968],
                            [109.939804, 26.454054],
                            [109.950868, 26.451225],
                            [109.95504, 26.446913],
                            [109.954541, 26.444851],
                            [109.951775, 26.440897],
                            [109.950551, 26.436977],
                            [109.952909, 26.434488],
                            [109.96064, 26.431165],
                            [109.96683, 26.428045],
                            [109.972203, 26.428113],
                            [109.978348, 26.429579],
                            [109.987281, 26.432477],
                            [109.988324, 26.429954],
                            [109.986601, 26.425113],
                            [109.982882, 26.416795],
                            [109.979436, 26.407333],
                            [109.977985, 26.400189],
                            [109.977826, 26.393762],
                            [109.978665, 26.388032],
                            [109.981522, 26.385543],
                            [109.993675, 26.380564],
                            [110.005464, 26.377665],
                            [110.012221, 26.372463],
                            [110.011541, 26.364652],
                            [110.013445, 26.356636],
                            [110.016347, 26.351861],
                            [110.017685, 26.347528],
                            [110.016755, 26.34062],
                            [110.012992, 26.335058],
                            [110.009908, 26.316411],
                            [110.010271, 26.309074],
                            [110.009296, 26.304006],
                            [110.002608, 26.299297],
                            [109.998504, 26.297505],
                            [109.995942, 26.294996],
                            [109.990636, 26.28718],
                            [109.984288, 26.27909],
                            [109.985195, 26.27624],
                            [109.990387, 26.272827],
                            [109.991747, 26.268457],
                            [109.996531, 26.268867],
                            [110.003401, 26.270352],
                            [110.012742, 26.26733],
                            [110.018615, 26.262807],
                            [110.021789, 26.263558],
                            [110.02519, 26.267945],
                            [110.029656, 26.27653],
                            [110.029316, 26.281275],
                            [110.023353, 26.285405],
                            [110.022061, 26.29474],
                            [110.022424, 26.303563],
                            [110.024646, 26.307573],
                            [110.033987, 26.314705],
                            [110.035619, 26.316735],
                            [110.036209, 26.320421],
                            [110.040494, 26.326392],
                            [110.048656, 26.335246],
                            [110.054052, 26.333711],
                            [110.059244, 26.336014],
                            [110.066998, 26.337754],
                            [110.087767, 26.347443],
                            [110.097607, 26.354129],
                            [110.103842, 26.35592],
                            [110.110734, 26.355391],
                            [110.117105, 26.350291],
                            [110.120597, 26.348756],
                            [110.128759, 26.34862],
                            [110.140504, 26.343946],
                            [110.148575, 26.341984],
                            [110.15642, 26.340705],
                            [110.163108, 26.342001],
                            [110.166804, 26.343895],
                            [110.167983, 26.346829],
                            [110.166373, 26.35447],
                            [110.16626, 26.361821],
                            [110.166849, 26.372139],
                            [110.169253, 26.388101],
                            [110.170953, 26.395603],
                            [110.170091, 26.402764],
                            [110.167507, 26.407009],
                            [110.164877, 26.414715],
                            [110.163063, 26.428113],
                            [110.163471, 26.435221],
                            [110.164922, 26.442959],
                            [110.16796, 26.45177],
                            [110.172858, 26.461416],
                            [110.177007, 26.471571],
                            [110.18034, 26.480823],
                            [110.180952, 26.487451],
                            [110.179773, 26.493941],
                            [110.1834, 26.508472],
                            [110.189023, 26.522217],
                            [110.195893, 26.526049],
                            [110.204962, 26.528144],
                            [110.216299, 26.520276],
                            [110.219813, 26.514843],
                            [110.222284, 26.504163],
                            [110.225685, 26.50079],
                            [110.234641, 26.500602],
                            [110.238337, 26.502732],
                            [110.245592, 26.508608],
                            [110.255976, 26.51595],
                            [110.262823, 26.514723],
                            [110.264229, 26.512134],
                            [110.26534, 26.504963],
                            [110.263413, 26.493345],
                            [110.26407, 26.48251],
                            [110.263617, 26.474502],
                            [110.265, 26.46709],
                            [110.271552, 26.455997],
                            [110.27552, 26.453764],
                            [110.280621, 26.453543],
                            [110.288126, 26.454804],
                            [110.293726, 26.458468],
                            [110.296719, 26.46133],
                            [110.302274, 26.468658],
                            [110.304065, 26.477773],
                            [110.303906, 26.483975],
                            [110.305131, 26.491965],
                            [110.308259, 26.501931],
                            [110.309461, 26.50946],
                            [110.312114, 26.519918],
                            [110.317192, 26.532334],
                            [110.323564, 26.54468],
                            [110.32633, 26.553057],
                            [110.333358, 26.563324],
                            [110.339298, 26.572943],
                            [110.346735, 26.581813],
                            [110.354671, 26.590204],
                            [110.360384, 26.595685],
                            [110.367118, 26.601132],
                            [110.37401, 26.609097],
                            [110.380472, 26.618695],
                            [110.384281, 26.62266],
                            [110.39072, 26.627186],
                            [110.399064, 26.634163],
                            [110.404687, 26.642414],
                            [110.406818, 26.648284],
                            [110.411919, 26.665279],
                            [110.41727, 26.673766],
                            [110.421578, 26.681607],
                            [110.425614, 26.693359],
                            [110.429649, 26.700995],
                            [110.436565, 26.705484],
                            [110.4333, 26.709769],
                            [110.438174, 26.714139],
                            [110.447742, 26.720345],
                            [110.453002, 26.722861],
                            [110.461097, 26.730257],
                            [110.471526, 26.739097],
                            [110.480731, 26.745709],
                            [110.485901, 26.752015],
                            [110.498756, 26.774721],
                            [110.501772, 26.779361],
                            [110.508732, 26.792139],
                            [110.509843, 26.795146],
                            [110.51338, 26.799224],
                            [110.51762, 26.802792],
                            [110.518799, 26.805476],
                            [110.527278, 26.815279],
                            [110.53043, 26.822906],
                            [110.530475, 26.825403],
                            [110.533922, 26.828393],
                            [110.534466, 26.831858],
                            [110.536574, 26.836019],
                            [110.538547, 26.8448],
                            [110.538638, 26.848078],
                            [110.540021, 26.853631],
                            [110.535509, 26.855584],
                            [110.532675, 26.858131],
                            [110.530453, 26.861544],
                            [110.526893, 26.863378],
                            [110.520431, 26.864126],
                            [110.5144, 26.862461],
                            [110.514038, 26.864041],
                            [110.51669, 26.871036],
                            [110.52195, 26.879373],
                            [110.52687, 26.883295],
                            [110.52721, 26.884942],
                            [110.525555, 26.890901],
                            [110.520159, 26.898217],
                            [110.51567, 26.901307],
                            [110.510478, 26.910252],
                            [110.509095, 26.916346],
                            [110.506079, 26.920504],
                            [110.500071, 26.927327],
                            [110.496013, 26.925629],
                            [110.495242, 26.917313],
                            [110.493632, 26.913647],
                            [110.490639, 26.913647],
                            [110.487102, 26.914869],
                            [110.477534, 26.926088],
                            [110.47563, 26.929024],
                            [110.476446, 26.930738],
                            [110.481049, 26.933419],
                            [110.482636, 26.940987],
                            [110.480164, 26.945145],
                            [110.477172, 26.948063],
                            [110.467626, 26.954613],
                            [110.452413, 26.961603],
                            [110.449488, 26.965471],
                            [110.449942, 26.967676],
                            [110.453388, 26.972834],
                            [110.453683, 26.976294],
                            [110.447221, 26.977516],
                            [110.44153, 26.983283],
                            [110.438174, 26.983724],
                            [110.43398, 26.982808],
                            [110.432166, 26.984572],
                            [110.431713, 26.990102],
                            [110.432075, 26.993155],
                            [110.435408, 26.996734],
                            [110.434161, 27.00094],
                            [110.428675, 27.006927],
                            [110.424004, 27.010217],
                            [110.421601, 27.01271],
                            [110.417656, 27.013235],
                            [110.414255, 27.012472],
                            [110.414617, 27.01427],
                            [110.419016, 27.016712],
                            [110.42058, 27.01505],
                            [110.423052, 27.016101],
                            [110.424593, 27.018255],
                            [110.425183, 27.022494],
                            [110.430148, 27.021188],
                            [110.433141, 27.022579],
                            [110.433753, 27.024122],
                            [110.430488, 27.02653],
                            [110.434025, 27.027717],
                            [110.432007, 27.039619],
                            [110.429128, 27.041297],
                            [110.423528, 27.04162],
                            [110.417111, 27.043959],
                            [110.411285, 27.044146],
                            [110.407271, 27.040738],
                            [110.403576, 27.039263],
                            [110.400198, 27.039093],
                            [110.395527, 27.03672],
                            [110.389587, 27.040535],
                            [110.385891, 27.041331],
                            [110.37884, 27.04045],
                            [110.375167, 27.045807],
                            [110.381538, 27.051554],
                            [110.37893, 27.056385],
                            [110.378681, 27.05864],
                            [110.381107, 27.063539],
                            [110.379905, 27.06542],
                            [110.371879, 27.066437],
                            [110.369567, 27.070166],
                            [110.366506, 27.073336],
                            [110.36612, 27.07659],
                            [110.368501, 27.079132],
                            [110.370791, 27.079183],
                            [110.370882, 27.081285],
                            [110.373353, 27.081234],
                            [110.373988, 27.086216],
                            [110.371403, 27.087064],
                            [110.368116, 27.086742],
                            [110.363694, 27.084318],
                            [110.362629, 27.082166],
                            [110.36408, 27.079505],
                            [110.362039, 27.078149],
                            [110.355714, 27.084742],
                            [110.356371, 27.087267],
                            [110.359228, 27.08964],
                            [110.364601, 27.091385],
                            [110.367435, 27.089097],
                            [110.372401, 27.08808],
                            [110.377593, 27.088013],
                            [110.382762, 27.090504],
                            [110.393441, 27.09713],
                            [110.401898, 27.107144],
                            [110.40319, 27.112769],
                            [110.403712, 27.117903],
                            [110.40149, 27.121054],
                            [110.393849, 27.128],
                            [110.385687, 27.133319],
                            [110.379747, 27.135776],
                            [110.375892, 27.140671],
                            [110.372605, 27.143229],
                            [110.369907, 27.142467],
                            [110.368728, 27.137791],
                            [110.367413, 27.136877],
                            [110.360429, 27.136081],
                            [110.355872, 27.13171],
                            [110.358797, 27.126899],
                            [110.356099, 27.124171],
                            [110.352199, 27.122731],
                            [110.347529, 27.122443],
                            [110.341294, 27.117649],
                            [110.337847, 27.116039],
                            [110.332633, 27.112346],
                            [110.330683, 27.111905],
                            [110.327123, 27.114464],
                            [110.318893, 27.116497],
                            [110.315832, 27.116395],
                            [110.308123, 27.107263],
                            [110.304269, 27.098231],
                            [110.305516, 27.088538],
                            [110.306922, 27.08181],
                            [110.309847, 27.074556],
                            [110.310504, 27.067878],
                            [110.308826, 27.061284],
                            [110.308645, 27.058064],
                            [110.305085, 27.045349],
                            [110.305199, 27.042823],
                            [110.302909, 27.034431],
                            [110.29885, 27.026784],
                            [110.294746, 27.022596],
                            [110.289509, 27.016169],
                            [110.283659, 27.004247],
                            [110.284068, 26.993426],
                            [110.281438, 26.988033],
                            [110.273253, 26.975294],
                            [110.269444, 26.967185],
                            [110.265136, 26.959329],
                            [110.256021, 26.952628],
                            [110.2443, 26.950388],
                            [110.23845, 26.951338],
                            [110.229403, 26.951915],
                            [110.224778, 26.961128],
                            [110.224665, 26.97073],
                            [110.219495, 26.975209],
                            [110.207683, 26.975294],
                            [110.200904, 26.973767],
                            [110.193671, 26.972732],
                            [110.18755, 26.973461],
                            [110.182494, 26.974852],
                            [110.175873, 26.975836],
                            [110.165806, 26.980993],
                            [110.156533, 26.982248],
                            [110.151863, 26.984403],
                            [110.14853, 26.989695]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430528,
                "name": "新宁县",
                "center": [110.859115, 26.438912],
                "centroid": [110.917976, 26.541665],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430500
                },
                "subFeatureIndex": 8,
                "acroutes": [100000, 430000, 430500]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [110.585525, 26.301327],
                            [110.587021, 26.305696],
                            [110.591057, 26.310763],
                            [110.595093, 26.318373],
                            [110.605976, 26.32878],
                            [110.612347, 26.33366],
                            [110.617199, 26.333933],
                            [110.62103, 26.332585],
                            [110.622844, 26.330077],
                            [110.624703, 26.322519],
                            [110.625792, 26.315643],
                            [110.635042, 26.310439],
                            [110.643771, 26.30846],
                            [110.650392, 26.309893],
                            [110.659846, 26.312965],
                            [110.667238, 26.317623],
                            [110.673382, 26.316991],
                            [110.68134, 26.315234],
                            [110.690341, 26.311873],
                            [110.697778, 26.308716],
                            [110.702675, 26.303716],
                            [110.704761, 26.298904],
                            [110.709477, 26.293153],
                            [110.720927, 26.294228],
                            [110.732558, 26.31177],
                            [110.736389, 26.313169],
                            [110.741763, 26.313903],
                            [110.745617, 26.310252],
                            [110.749517, 26.301105],
                            [110.750333, 26.293768],
                            [110.750038, 26.290508],
                            [110.742806, 26.276104],
                            [110.735211, 26.271],
                            [110.744892, 26.262892],
                            [110.758201, 26.255194],
                            [110.759085, 26.248689],
                            [110.767791, 26.250277],
                            [110.77618, 26.25376],
                            [110.782279, 26.257481],
                            [110.78865, 26.25842],
                            [110.797062, 26.258693],
                            [110.804703, 26.258505],
                            [110.807151, 26.259256],
                            [110.814656, 26.258795],
                            [110.82411, 26.256525],
                            [110.834313, 26.255893],
                            [110.841183, 26.257293],
                            [110.84599, 26.259854],
                            [110.85057, 26.263302],
                            [110.855626, 26.26815],
                            [110.865058, 26.269345],
                            [110.87653, 26.270317],
                            [110.889295, 26.272434],
                            [110.899974, 26.275045],
                            [110.911015, 26.276104],
                            [110.916389, 26.275984],
                            [110.917613, 26.270608],
                            [110.9192, 26.268269],
                            [110.924324, 26.263046],
                            [110.924664, 26.260349],
                            [110.922193, 26.257652],
                            [110.921603, 26.254886],
                            [110.927204, 26.254016],
                            [110.92997, 26.255654],
                            [110.92938, 26.259581],
                            [110.930378, 26.264343],
                            [110.935252, 26.275438],
                            [110.936908, 26.280644],
                            [110.939742, 26.286685],
                            [110.938971, 26.29073],
                            [110.935547, 26.294314],
                            [110.936862, 26.29986],
                            [110.936023, 26.305371],
                            [110.932441, 26.308426],
                            [110.929448, 26.315046],
                            [110.925435, 26.318749],
                            [110.928383, 26.321069],
                            [110.938495, 26.32315],
                            [110.942394, 26.324669],
                            [110.943913, 26.326716],
                            [110.947201, 26.339579],
                            [110.947858, 26.350445],
                            [110.947405, 26.353123],
                            [110.950511, 26.35696],
                            [110.948743, 26.365522],
                            [110.944911, 26.37156],
                            [110.944843, 26.373606],
                            [110.949037, 26.377972],
                            [110.958945, 26.382746],
                            [110.967947, 26.383582],
                            [110.974567, 26.38556],
                            [110.977197, 26.384452],
                            [110.978966, 26.381348],
                            [110.983636, 26.378023],
                            [110.983818, 26.371918],
                            [110.988647, 26.366256],
                            [110.991889, 26.363578],
                            [110.996378, 26.36177],
                            [111.008576, 26.358871],
                            [111.016534, 26.366],
                            [111.017827, 26.368694],
                            [111.019142, 26.377392],
                            [111.024266, 26.381007],
                            [111.025082, 26.384588],
                            [111.019006, 26.391033],
                            [111.018303, 26.393199],
                            [111.019187, 26.396677],
                            [111.019187, 26.401008],
                            [111.023087, 26.407282],
                            [111.025558, 26.419198],
                            [111.029163, 26.4245],
                            [111.029503, 26.429477],
                            [111.031748, 26.432477],
                            [111.039819, 26.436874],
                            [111.044943, 26.440948],
                            [111.045193, 26.44359],
                            [111.042699, 26.446811],
                            [111.043719, 26.451208],
                            [111.045714, 26.45298],
                            [111.052675, 26.457036],
                            [111.06027, 26.473752],
                            [111.060157, 26.480329],
                            [111.056438, 26.486599],
                            [111.057572, 26.489274],
                            [111.061086, 26.491216],
                            [111.061925, 26.492919],
                            [111.062152, 26.501079],
                            [111.063331, 26.503209],
                            [111.06587, 26.504265],
                            [111.067503, 26.507143],
                            [111.066687, 26.512066],
                            [111.066505, 26.521553],
                            [111.063444, 26.524959],
                            [111.063286, 26.526543],
                            [111.06621, 26.531482],
                            [111.066573, 26.541308],
                            [111.065508, 26.549635],
                            [111.064283, 26.554045],
                            [111.06392, 26.558387],
                            [111.065054, 26.566576],
                            [111.067117, 26.577267],
                            [111.069634, 26.584383],
                            [111.073579, 26.587821],
                            [111.081129, 26.587055],
                            [111.088702, 26.588894],
                            [111.098655, 26.586426],
                            [111.104754, 26.585898],
                            [111.108223, 26.588945],
                            [111.112848, 26.602306],
                            [111.113846, 26.609233],
                            [111.113211, 26.611105],
                            [111.109221, 26.615223],
                            [111.109221, 26.617453],
                            [111.111692, 26.618746],
                            [111.11668, 26.619393],
                            [111.120965, 26.6222],
                            [111.127427, 26.623085],
                            [111.133299, 26.620192],
                            [111.134523, 26.618133],
                            [111.137108, 26.610628],
                            [111.136632, 26.606101],
                            [111.133889, 26.600757],
                            [111.133821, 26.594868],
                            [111.133186, 26.590749],
                            [111.127132, 26.577489],
                            [111.125726, 26.5693],
                            [111.125137, 26.558847],
                            [111.12584, 26.553926],
                            [111.142255, 26.541308],
                            [111.149238, 26.533253],
                            [111.153999, 26.52702],
                            [111.156289, 26.520446],
                            [111.159214, 26.515745],
                            [111.164429, 26.513395],
                            [111.165971, 26.511743],
                            [111.165721, 26.507195],
                            [111.162388, 26.503907],
                            [111.155586, 26.502221],
                            [111.154408, 26.500398],
                            [111.158874, 26.494401],
                            [111.158511, 26.492647],
                            [111.155518, 26.488183],
                            [111.155518, 26.482442],
                            [111.157038, 26.480857],
                            [111.163273, 26.479051],
                            [111.168011, 26.474161],
                            [111.170823, 26.473343],
                            [111.173883, 26.474979],
                            [111.176695, 26.474553],
                            [111.179506, 26.472151],
                            [111.183678, 26.471179],
                            [111.184834, 26.468436],
                            [111.181071, 26.463375],
                            [111.182204, 26.461876],
                            [111.185515, 26.460751],
                            [111.194312, 26.459626],
                            [111.201181, 26.457326],
                            [111.202406, 26.45857],
                            [111.203335, 26.462881],
                            [111.208686, 26.464517],
                            [111.212903, 26.469663],
                            [111.222063, 26.474025],
                            [111.227051, 26.477944],
                            [111.229636, 26.481113],
                            [111.234329, 26.482663],
                            [111.237322, 26.481965],
                            [111.244486, 26.482067],
                            [111.247071, 26.483362],
                            [111.251356, 26.483839],
                            [111.2597, 26.479903],
                            [111.261287, 26.480482],
                            [111.260834, 26.485798],
                            [111.263418, 26.49343],
                            [111.267182, 26.499188],
                            [111.271875, 26.499427],
                            [111.274709, 26.493959],
                            [111.278292, 26.490211],
                            [111.283529, 26.489853],
                            [111.288766, 26.49234],
                            [111.289651, 26.494453],
                            [111.289991, 26.500636],
                            [111.291283, 26.503992],
                            [111.295523, 26.50682],
                            [111.296929, 26.511743],
                            [111.296044, 26.52622],
                            [111.293777, 26.532606],
                            [111.291351, 26.545957],
                            [111.288472, 26.557059],
                            [111.28854, 26.55963],
                            [111.291419, 26.564686],
                            [111.296997, 26.567751],
                            [111.297949, 26.569811],
                            [111.297699, 26.576944],
                            [111.304592, 26.583345],
                            [111.305431, 26.588638],
                            [111.304365, 26.591396],
                            [111.306655, 26.596128],
                            [111.304071, 26.600996],
                            [111.303889, 26.604042],
                            [111.306066, 26.61975],
                            [111.30831, 26.622524],
                            [111.314251, 26.626642],
                            [111.313774, 26.6311],
                            [111.311485, 26.634554],
                            [111.31255, 26.639029],
                            [111.310192, 26.640458],
                            [111.305544, 26.641275],
                            [111.301259, 26.644201],
                            [111.300647, 26.652129],
                            [111.299309, 26.656297],
                            [111.299355, 26.660669],
                            [111.296951, 26.668289],
                            [111.293709, 26.672814],
                            [111.290943, 26.674243],
                            [111.285297, 26.675365],
                            [111.281126, 26.678699],
                            [111.279539, 26.68358],
                            [111.276296, 26.687441],
                            [111.276251, 26.688853],
                            [111.272601, 26.69205],
                            [111.26988, 26.696046],
                            [111.269245, 26.702083],
                            [111.271898, 26.708902],
                            [111.271716, 26.712728],
                            [111.270356, 26.713952],
                            [111.262375, 26.716587],
                            [111.257999, 26.717165],
                            [111.253374, 26.716944],
                            [111.242423, 26.71453],
                            [111.23705, 26.712541],
                            [111.227618, 26.712047],
                            [111.223877, 26.711435],
                            [111.218118, 26.713748],
                            [111.212903, 26.717879],
                            [111.214853, 26.728913],
                            [111.210727, 26.741748],
                            [111.210115, 26.749415],
                            [111.208074, 26.755704],
                            [111.203086, 26.759817],
                            [111.201431, 26.764678],
                            [111.200705, 26.769487],
                            [111.201386, 26.771748],
                            [111.205421, 26.775707],
                            [111.206011, 26.778817],
                            [111.203358, 26.783303],
                            [111.199368, 26.784781],
                            [111.187578, 26.784883],
                            [111.18454, 26.786464],
                            [111.176559, 26.788843],
                            [111.167535, 26.792207],
                            [111.163023, 26.792224],
                            [111.157128, 26.793685],
                            [111.155813, 26.794993],
                            [111.153818, 26.80235],
                            [111.148694, 26.803743],
                            [111.1423, 26.803132],
                            [111.138831, 26.801773],
                            [111.13509, 26.796285],
                            [111.133435, 26.790338],
                            [111.13017, 26.790049],
                            [111.128084, 26.792088],
                            [111.127472, 26.79637],
                            [111.125658, 26.797542],
                            [111.114617, 26.7997],
                            [111.113302, 26.800991],
                            [111.112712, 26.804372],
                            [111.113279, 26.80811],
                            [111.108064, 26.808925],
                            [111.103983, 26.811541],
                            [111.09802, 26.817912],
                            [111.094302, 26.817317],
                            [111.09335, 26.814616],
                            [111.081038, 26.815092],
                            [111.073443, 26.813155],
                            [111.07036, 26.813308],
                            [111.064782, 26.818642],
                            [111.065167, 26.820919],
                            [111.070586, 26.825029],
                            [111.077275, 26.828597],
                            [111.080744, 26.830958],
                            [111.082059, 26.835255],
                            [111.081288, 26.839212],
                            [111.078952, 26.843],
                            [111.072151, 26.845174],
                            [111.070496, 26.847772],
                            [111.069997, 26.851933],
                            [111.067934, 26.860084],
                            [111.065303, 26.862971],
                            [111.064737, 26.866214],
                            [111.066188, 26.868897],
                            [111.068908, 26.87107],
                            [111.073874, 26.872429],
                            [111.0763, 26.873923],
                            [111.079111, 26.877692],
                            [111.076867, 26.882854],
                            [111.070768, 26.884314],
                            [111.066142, 26.884297],
                            [111.060542, 26.885264],
                            [111.055214, 26.886894],
                            [111.050725, 26.888948],
                            [111.039457, 26.890408],
                            [111.031929, 26.893515],
                            [111.018439, 26.900815],
                            [111.010662, 26.90808],
                            [111.005357, 26.914071],
                            [110.998373, 26.918077],
                            [110.99597, 26.916312],
                            [110.991481, 26.914903],
                            [110.988533, 26.912968],
                            [110.98681, 26.910303],
                            [110.981165, 26.89034],
                            [110.979691, 26.883516],
                            [110.980575, 26.868761],
                            [110.983047, 26.863124],
                            [110.982434, 26.853512],
                            [110.979056, 26.849301],
                            [110.975225, 26.845564],
                            [110.969896, 26.84283],
                            [110.960487, 26.840741],
                            [110.954819, 26.840792],
                            [110.948516, 26.842932],
                            [110.93498, 26.852986],
                            [110.931534, 26.851321],
                            [110.929471, 26.84728],
                            [110.9287, 26.843407],
                            [110.929017, 26.836036],
                            [110.93099, 26.826524],
                            [110.93684, 26.808772],
                            [110.939742, 26.801977],
                            [110.940195, 26.796676],
                            [110.942281, 26.788979],
                            [110.945433, 26.781944],
                            [110.946203, 26.777542],
                            [110.94516, 26.773447],
                            [110.938631, 26.759511],
                            [110.934232, 26.750757],
                            [110.932917, 26.746236],
                            [110.929312, 26.742275],
                            [110.925344, 26.740388],
                            [110.913555, 26.737057],
                            [110.908045, 26.735034],
                            [110.899475, 26.733844],
                            [110.896663, 26.73398],
                            [110.891562, 26.7384],
                            [110.888456, 26.744502],
                            [110.884874, 26.744961],
                            [110.880974, 26.7427],
                            [110.876485, 26.74151],
                            [110.865783, 26.739726],
                            [110.859729, 26.73942],
                            [110.849073, 26.737023],
                            [110.848053, 26.735136],
                            [110.847826, 26.730104],
                            [110.848506, 26.725598],
                            [110.850025, 26.722844],
                            [110.854469, 26.720617],
                            [110.858596, 26.720311],
                            [110.863652, 26.718866],
                            [110.868118, 26.712439],
                            [110.869479, 26.705008],
                            [110.868753, 26.697696],
                            [110.862201, 26.678954],
                            [110.858188, 26.672032],
                            [110.85515, 26.664156],
                            [110.850116, 26.661638],
                            [110.845514, 26.661877],
                            [110.84311, 26.66453],
                            [110.840117, 26.669956],
                            [110.836172, 26.672457],
                            [110.8303, 26.672525],
                            [110.820619, 26.669565],
                            [110.818805, 26.664836],
                            [110.817807, 26.656841],
                            [110.81876, 26.643248],
                            [110.819712, 26.638144],
                            [110.819145, 26.632886],
                            [110.817422, 26.625842],
                            [110.812207, 26.611871],
                            [110.812389, 26.606799],
                            [110.815858, 26.583651],
                            [110.817671, 26.579889],
                            [110.816628, 26.575565],
                            [110.813613, 26.572279],
                            [110.809328, 26.569283],
                            [110.805224, 26.569249],
                            [110.800758, 26.571309],
                            [110.795044, 26.58263],
                            [110.789852, 26.590017],
                            [110.786088, 26.591345],
                            [110.781622, 26.589728],
                            [110.777087, 26.586511],
                            [110.774253, 26.582051],
                            [110.767247, 26.568142],
                            [110.761375, 26.564533],
                            [110.739858, 26.560736],
                            [110.733306, 26.56266],
                            [110.726799, 26.565487],
                            [110.721448, 26.566202],
                            [110.717254, 26.564789],
                            [110.712651, 26.560856],
                            [110.709726, 26.555033],
                            [110.707278, 26.548698],
                            [110.7056, 26.546264],
                            [110.703015, 26.546144],
                            [110.699251, 26.547473],
                            [110.693606, 26.560583],
                            [110.690658, 26.562797],
                            [110.686577, 26.562984],
                            [110.68134, 26.561383],
                            [110.6771, 26.559289],
                            [110.671681, 26.559068],
                            [110.66302, 26.560379],
                            [110.659506, 26.56169],
                            [110.65606, 26.564142],
                            [110.652999, 26.564516],
                            [110.639327, 26.559408],
                            [110.633342, 26.557842],
                            [110.628467, 26.557825],
                            [110.621144, 26.560004],
                            [110.612823, 26.55837],
                            [110.617063, 26.553585],
                            [110.615838, 26.550197],
                            [110.614999, 26.544952],
                            [110.615906, 26.538464],
                            [110.611803, 26.534309],
                            [110.608152, 26.5331],
                            [110.604321, 26.533509],
                            [110.600239, 26.538294],
                            [110.595093, 26.541972],
                            [110.589629, 26.545003],
                            [110.584051, 26.545957],
                            [110.579879, 26.545003],
                            [110.576796, 26.540559],
                            [110.575458, 26.535331],
                            [110.572873, 26.521672],
                            [110.570493, 26.516052],
                            [110.560675, 26.501607],
                            [110.555778, 26.492425],
                            [110.548024, 26.473735],
                            [110.541744, 26.462557],
                            [110.532402, 26.442806],
                            [110.529251, 26.436772],
                            [110.526462, 26.432767],
                            [110.520635, 26.429392],
                            [110.515534, 26.429187],
                            [110.509208, 26.430176],
                            [110.503336, 26.430466],
                            [110.497963, 26.430057],
                            [110.49429, 26.428625],
                            [110.49209, 26.42138],
                            [110.49277, 26.415601],
                            [110.490843, 26.404213],
                            [110.488576, 26.396063],
                            [110.488281, 26.391255],
                            [110.488916, 26.384571],
                            [110.491342, 26.378244],
                            [110.498824, 26.366153],
                            [110.503767, 26.359246],
                            [110.507462, 26.3485],
                            [110.508007, 26.340432],
                            [110.504379, 26.326375],
                            [110.506215, 26.322622],
                            [110.510523, 26.318032],
                            [110.52152, 26.314039],
                            [110.52644, 26.311036],
                            [110.531314, 26.307129],
                            [110.536302, 26.305269],
                            [110.541653, 26.305013],
                            [110.563827, 26.30875],
                            [110.566593, 26.308153],
                            [110.584527, 26.300389],
                            [110.585525, 26.301327]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430529,
                "name": "城步苗族自治县",
                "center": [110.313226, 26.363575],
                "centroid": [110.318488, 26.318776],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430500
                },
                "subFeatureIndex": 9,
                "acroutes": [100000, 430000, 430500]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [109.991747, 26.268457],
                            [109.991113, 26.265282],
                            [109.994355, 26.261749],
                            [109.99533, 26.258676],
                            [109.990138, 26.249679],
                            [109.988823, 26.245172],
                            [109.989457, 26.243158],
                            [109.992768, 26.238616],
                            [109.98948, 26.236021],
                            [109.986714, 26.232299],
                            [109.978643, 26.224598],
                            [109.97438, 26.218348],
                            [109.971682, 26.208888],
                            [109.968485, 26.200126],
                            [109.966241, 26.195498],
                            [109.970095, 26.195105],
                            [109.977146, 26.184874],
                            [109.984379, 26.179937],
                            [109.994196, 26.180415],
                            [110.005759, 26.185779],
                            [110.014171, 26.185506],
                            [110.016733, 26.184225],
                            [110.02188, 26.177973],
                            [110.02757, 26.170388],
                            [110.030223, 26.166117],
                            [110.036776, 26.163298],
                            [110.04156, 26.162444],
                            [110.047001, 26.167074],
                            [110.051558, 26.169209],
                            [110.057703, 26.169534],
                            [110.062555, 26.16844],
                            [110.068449, 26.168065],
                            [110.080081, 26.169756],
                            [110.08398, 26.171498],
                            [110.094977, 26.170456],
                            [110.098718, 26.169141],
                            [110.096201, 26.163093],
                            [110.09441, 26.157404],
                            [110.09441, 26.147135],
                            [110.09611, 26.142145],
                            [110.100486, 26.133003],
                            [110.102481, 26.132474],
                            [110.101461, 26.128047],
                            [110.0984, 26.129671],
                            [110.09602, 26.126714],
                            [110.096609, 26.125416],
                            [110.093639, 26.121245],
                            [110.093684, 26.119502],
                            [110.090895, 26.114426],
                            [110.088084, 26.111264],
                            [110.088039, 26.109008],
                            [110.08593, 26.105333],
                            [110.080874, 26.099077],
                            [110.082416, 26.095487],
                            [110.079922, 26.094718],
                            [110.074412, 26.083093],
                            [110.074072, 26.071279],
                            [110.072122, 26.063909],
                            [110.067112, 26.056728],
                            [110.06489, 26.050041],
                            [110.068041, 26.043423],
                            [110.073188, 26.040276],
                            [110.083413, 26.039028],
                            [110.088311, 26.035419],
                            [110.09246, 26.029723],
                            [110.095362, 26.021496],
                            [110.097947, 26.019461],
                            [110.101008, 26.020196],
                            [110.10527, 26.02437],
                            [110.109011, 26.030613],
                            [110.112525, 26.034769],
                            [110.115337, 26.035043],
                            [110.119418, 26.033931],
                            [110.129643, 26.02921],
                            [110.134813, 26.024216],
                            [110.140436, 26.020367],
                            [110.147804, 26.017767],
                            [110.153427, 26.018999],
                            [110.156397, 26.018503],
                            [110.165829, 26.023822],
                            [110.168822, 26.028902],
                            [110.17152, 26.038104],
                            [110.172948, 26.044996],
                            [110.181655, 26.060455],
                            [110.185917, 26.064405],
                            [110.190973, 26.067654],
                            [110.196573, 26.068167],
                            [110.201176, 26.066149],
                            [110.204509, 26.062046],
                            [110.206595, 26.058164],
                            [110.207184, 26.051974],
                            [110.209043, 26.046706],
                            [110.212127, 26.043064],
                            [110.218022, 26.0399],
                            [110.234142, 26.032717],
                            [110.240558, 26.028646],
                            [110.245206, 26.022266],
                            [110.249673, 26.010839],
                            [110.251283, 26.003739],
                            [110.251101, 25.996622],
                            [110.24863, 25.990395],
                            [110.247972, 25.982575],
                            [110.245751, 25.97544],
                            [110.245841, 25.968561],
                            [110.248177, 25.965138],
                            [110.253301, 25.962434],
                            [110.261712, 25.961373],
                            [110.281551, 25.965258],
                            [110.287945, 25.966935],
                            [110.292434, 25.965069],
                            [110.297626, 25.965651],
                            [110.2991, 25.970186],
                            [110.301662, 25.967962],
                            [110.303249, 25.972633],
                            [110.304972, 25.971299],
                            [110.303838, 25.969604],
                            [110.305131, 25.967328],
                            [110.308123, 25.967534],
                            [110.309756, 25.970169],
                            [110.313361, 25.970443],
                            [110.320752, 25.973061],
                            [110.325559, 25.975645],
                            [110.329594, 25.980727],
                            [110.333086, 25.986973],
                            [110.335331, 25.992499],
                            [110.340251, 26.008393],
                            [110.343765, 26.013491],
                            [110.349252, 26.026183],
                            [110.352018, 26.030801],
                            [110.35399, 26.036565],
                            [110.356507, 26.039797],
                            [110.357981, 26.046467],
                            [110.355124, 26.049648],
                            [110.353288, 26.054675],
                            [110.356054, 26.059532],
                            [110.362878, 26.064422],
                            [110.368161, 26.071142],
                            [110.373194, 26.078545],
                            [110.373353, 26.089111],
                            [110.377117, 26.094427],
                            [110.382694, 26.099983],
                            [110.388498, 26.104871],
                            [110.393282, 26.109965],
                            [110.400379, 26.11646],
                            [110.406455, 26.120887],
                            [110.414549, 26.125774],
                            [110.423142, 26.133653],
                            [110.427178, 26.138044],
                            [110.43067, 26.143803],
                            [110.43797, 26.15397],
                            [110.456947, 26.164426],
                            [110.462004, 26.168594],
                            [110.467785, 26.175513],
                            [110.474111, 26.180142],
                            [110.47767, 26.179937],
                            [110.481525, 26.176521],
                            [110.484382, 26.170576],
                            [110.486944, 26.167364],
                            [110.490526, 26.165553],
                            [110.495106, 26.166493],
                            [110.500162, 26.169739],
                            [110.507712, 26.176674],
                            [110.517552, 26.186821],
                            [110.519774, 26.192116],
                            [110.52356, 26.196266],
                            [110.531065, 26.209383],
                            [110.536869, 26.215855],
                            [110.542673, 26.221166],
                            [110.546641, 26.23358],
                            [110.547072, 26.239316],
                            [110.546754, 26.248501],
                            [110.546074, 26.257276],
                            [110.548886, 26.264343],
                            [110.549044, 26.269003],
                            [110.552581, 26.278715],
                            [110.553171, 26.283426],
                            [110.555211, 26.286378],
                            [110.559633, 26.287829],
                            [110.569336, 26.289587],
                            [110.575231, 26.293699],
                            [110.584074, 26.296925],
                            [110.585525, 26.301327],
                            [110.584527, 26.300389],
                            [110.566593, 26.308153],
                            [110.563827, 26.30875],
                            [110.541653, 26.305013],
                            [110.536302, 26.305269],
                            [110.531314, 26.307129],
                            [110.52644, 26.311036],
                            [110.52152, 26.314039],
                            [110.510523, 26.318032],
                            [110.506215, 26.322622],
                            [110.504379, 26.326375],
                            [110.508007, 26.340432],
                            [110.507462, 26.3485],
                            [110.503767, 26.359246],
                            [110.498824, 26.366153],
                            [110.491342, 26.378244],
                            [110.488916, 26.384571],
                            [110.488281, 26.391255],
                            [110.488576, 26.396063],
                            [110.490843, 26.404213],
                            [110.49277, 26.415601],
                            [110.49209, 26.42138],
                            [110.49429, 26.428625],
                            [110.497963, 26.430057],
                            [110.503336, 26.430466],
                            [110.509208, 26.430176],
                            [110.515534, 26.429187],
                            [110.520635, 26.429392],
                            [110.526462, 26.432767],
                            [110.529251, 26.436772],
                            [110.532402, 26.442806],
                            [110.541744, 26.462557],
                            [110.548024, 26.473735],
                            [110.555778, 26.492425],
                            [110.560675, 26.501607],
                            [110.570493, 26.516052],
                            [110.572873, 26.521672],
                            [110.575458, 26.535331],
                            [110.576796, 26.540559],
                            [110.579879, 26.545003],
                            [110.584051, 26.545957],
                            [110.589629, 26.545003],
                            [110.595093, 26.541972],
                            [110.600239, 26.538294],
                            [110.604321, 26.533509],
                            [110.608152, 26.5331],
                            [110.611803, 26.534309],
                            [110.615906, 26.538464],
                            [110.614999, 26.544952],
                            [110.615838, 26.550197],
                            [110.617063, 26.553585],
                            [110.612823, 26.55837],
                            [110.61085, 26.563937],
                            [110.612573, 26.571207],
                            [110.613843, 26.579412],
                            [110.618038, 26.593932],
                            [110.620849, 26.606663],
                            [110.621575, 26.61444],
                            [110.623048, 26.621724],
                            [110.622164, 26.641292],
                            [110.618446, 26.647893],
                            [110.613027, 26.655735],
                            [110.60364, 26.662149],
                            [110.598675, 26.664683],
                            [110.592145, 26.662693],
                            [110.58616, 26.657232],
                            [110.578995, 26.644252],
                            [110.575322, 26.634316],
                            [110.571286, 26.631287],
                            [110.566208, 26.631763],
                            [110.560539, 26.63549],
                            [110.556413, 26.643503],
                            [110.551969, 26.650836],
                            [110.547072, 26.654528],
                            [110.53272, 26.658917],
                            [110.528117, 26.658917],
                            [110.523243, 26.654323],
                            [110.523583, 26.651091],
                            [110.528798, 26.648539],
                            [110.531949, 26.645409],
                            [110.533808, 26.642329],
                            [110.532584, 26.638927],
                            [110.526281, 26.636494],
                            [110.51957, 26.635439],
                            [110.51406, 26.637327],
                            [110.507462, 26.642483],
                            [110.506533, 26.648505],
                            [110.503291, 26.662897],
                            [110.500797, 26.668545],
                            [110.496557, 26.674498],
                            [110.480935, 26.68722],
                            [110.474859, 26.688427],
                            [110.468352, 26.690825],
                            [110.456018, 26.690264],
                            [110.453138, 26.689261],
                            [110.45035, 26.689856],
                            [110.447448, 26.692526],
                            [110.446359, 26.695791],
                            [110.443094, 26.701454],
                            [110.439535, 26.706453],
                            [110.4333, 26.709769],
                            [110.436565, 26.705484],
                            [110.429649, 26.700995],
                            [110.425614, 26.693359],
                            [110.421578, 26.681607],
                            [110.41727, 26.673766],
                            [110.411919, 26.665279],
                            [110.406818, 26.648284],
                            [110.404687, 26.642414],
                            [110.399064, 26.634163],
                            [110.39072, 26.627186],
                            [110.384281, 26.62266],
                            [110.380472, 26.618695],
                            [110.37401, 26.609097],
                            [110.367118, 26.601132],
                            [110.360384, 26.595685],
                            [110.354671, 26.590204],
                            [110.346735, 26.581813],
                            [110.339298, 26.572943],
                            [110.333358, 26.563324],
                            [110.32633, 26.553057],
                            [110.323564, 26.54468],
                            [110.317192, 26.532334],
                            [110.312114, 26.519918],
                            [110.309461, 26.50946],
                            [110.308259, 26.501931],
                            [110.305131, 26.491965],
                            [110.303906, 26.483975],
                            [110.304065, 26.477773],
                            [110.302274, 26.468658],
                            [110.296719, 26.46133],
                            [110.293726, 26.458468],
                            [110.288126, 26.454804],
                            [110.280621, 26.453543],
                            [110.27552, 26.453764],
                            [110.271552, 26.455997],
                            [110.265, 26.46709],
                            [110.263617, 26.474502],
                            [110.26407, 26.48251],
                            [110.263413, 26.493345],
                            [110.26534, 26.504963],
                            [110.264229, 26.512134],
                            [110.262823, 26.514723],
                            [110.255976, 26.51595],
                            [110.245592, 26.508608],
                            [110.238337, 26.502732],
                            [110.234641, 26.500602],
                            [110.225685, 26.50079],
                            [110.222284, 26.504163],
                            [110.219813, 26.514843],
                            [110.216299, 26.520276],
                            [110.204962, 26.528144],
                            [110.195893, 26.526049],
                            [110.189023, 26.522217],
                            [110.1834, 26.508472],
                            [110.179773, 26.493941],
                            [110.180952, 26.487451],
                            [110.18034, 26.480823],
                            [110.177007, 26.471571],
                            [110.172858, 26.461416],
                            [110.16796, 26.45177],
                            [110.164922, 26.442959],
                            [110.163471, 26.435221],
                            [110.163063, 26.428113],
                            [110.164877, 26.414715],
                            [110.167507, 26.407009],
                            [110.170091, 26.402764],
                            [110.170953, 26.395603],
                            [110.169253, 26.388101],
                            [110.166849, 26.372139],
                            [110.16626, 26.361821],
                            [110.166373, 26.35447],
                            [110.167983, 26.346829],
                            [110.166804, 26.343895],
                            [110.163108, 26.342001],
                            [110.15642, 26.340705],
                            [110.148575, 26.341984],
                            [110.140504, 26.343946],
                            [110.128759, 26.34862],
                            [110.120597, 26.348756],
                            [110.117105, 26.350291],
                            [110.110734, 26.355391],
                            [110.103842, 26.35592],
                            [110.097607, 26.354129],
                            [110.087767, 26.347443],
                            [110.066998, 26.337754],
                            [110.059244, 26.336014],
                            [110.054052, 26.333711],
                            [110.048656, 26.335246],
                            [110.040494, 26.326392],
                            [110.036209, 26.320421],
                            [110.035619, 26.316735],
                            [110.033987, 26.314705],
                            [110.024646, 26.307573],
                            [110.022424, 26.303563],
                            [110.022061, 26.29474],
                            [110.023353, 26.285405],
                            [110.029316, 26.281275],
                            [110.029656, 26.27653],
                            [110.02519, 26.267945],
                            [110.021789, 26.263558],
                            [110.018615, 26.262807],
                            [110.012742, 26.26733],
                            [110.003401, 26.270352],
                            [109.996531, 26.268867],
                            [109.991747, 26.268457]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430581,
                "name": "武冈市",
                "center": [110.636804, 26.732086],
                "centroid": [110.739073, 26.781347],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430500
                },
                "subFeatureIndex": 10,
                "acroutes": [100000, 430000, 430500]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [110.540021, 26.853631],
                            [110.538638, 26.848078],
                            [110.538547, 26.8448],
                            [110.536574, 26.836019],
                            [110.534466, 26.831858],
                            [110.533922, 26.828393],
                            [110.530475, 26.825403],
                            [110.53043, 26.822906],
                            [110.527278, 26.815279],
                            [110.518799, 26.805476],
                            [110.51762, 26.802792],
                            [110.51338, 26.799224],
                            [110.509843, 26.795146],
                            [110.508732, 26.792139],
                            [110.501772, 26.779361],
                            [110.498756, 26.774721],
                            [110.485901, 26.752015],
                            [110.480731, 26.745709],
                            [110.471526, 26.739097],
                            [110.461097, 26.730257],
                            [110.453002, 26.722861],
                            [110.447742, 26.720345],
                            [110.438174, 26.714139],
                            [110.4333, 26.709769],
                            [110.439535, 26.706453],
                            [110.443094, 26.701454],
                            [110.446359, 26.695791],
                            [110.447448, 26.692526],
                            [110.45035, 26.689856],
                            [110.453138, 26.689261],
                            [110.456018, 26.690264],
                            [110.468352, 26.690825],
                            [110.474859, 26.688427],
                            [110.480935, 26.68722],
                            [110.496557, 26.674498],
                            [110.500797, 26.668545],
                            [110.503291, 26.662897],
                            [110.506533, 26.648505],
                            [110.507462, 26.642483],
                            [110.51406, 26.637327],
                            [110.51957, 26.635439],
                            [110.526281, 26.636494],
                            [110.532584, 26.638927],
                            [110.533808, 26.642329],
                            [110.531949, 26.645409],
                            [110.528798, 26.648539],
                            [110.523583, 26.651091],
                            [110.523243, 26.654323],
                            [110.528117, 26.658917],
                            [110.53272, 26.658917],
                            [110.547072, 26.654528],
                            [110.551969, 26.650836],
                            [110.556413, 26.643503],
                            [110.560539, 26.63549],
                            [110.566208, 26.631763],
                            [110.571286, 26.631287],
                            [110.575322, 26.634316],
                            [110.578995, 26.644252],
                            [110.58616, 26.657232],
                            [110.592145, 26.662693],
                            [110.598675, 26.664683],
                            [110.60364, 26.662149],
                            [110.613027, 26.655735],
                            [110.618446, 26.647893],
                            [110.622164, 26.641292],
                            [110.623048, 26.621724],
                            [110.621575, 26.61444],
                            [110.620849, 26.606663],
                            [110.618038, 26.593932],
                            [110.613843, 26.579412],
                            [110.612573, 26.571207],
                            [110.61085, 26.563937],
                            [110.612823, 26.55837],
                            [110.621144, 26.560004],
                            [110.628467, 26.557825],
                            [110.633342, 26.557842],
                            [110.639327, 26.559408],
                            [110.652999, 26.564516],
                            [110.65606, 26.564142],
                            [110.659506, 26.56169],
                            [110.66302, 26.560379],
                            [110.671681, 26.559068],
                            [110.6771, 26.559289],
                            [110.68134, 26.561383],
                            [110.686577, 26.562984],
                            [110.690658, 26.562797],
                            [110.693606, 26.560583],
                            [110.699251, 26.547473],
                            [110.703015, 26.546144],
                            [110.7056, 26.546264],
                            [110.707278, 26.548698],
                            [110.709726, 26.555033],
                            [110.712651, 26.560856],
                            [110.717254, 26.564789],
                            [110.721448, 26.566202],
                            [110.726799, 26.565487],
                            [110.733306, 26.56266],
                            [110.739858, 26.560736],
                            [110.761375, 26.564533],
                            [110.767247, 26.568142],
                            [110.774253, 26.582051],
                            [110.777087, 26.586511],
                            [110.781622, 26.589728],
                            [110.786088, 26.591345],
                            [110.789852, 26.590017],
                            [110.795044, 26.58263],
                            [110.800758, 26.571309],
                            [110.805224, 26.569249],
                            [110.809328, 26.569283],
                            [110.813613, 26.572279],
                            [110.816628, 26.575565],
                            [110.817671, 26.579889],
                            [110.815858, 26.583651],
                            [110.812389, 26.606799],
                            [110.812207, 26.611871],
                            [110.817422, 26.625842],
                            [110.819145, 26.632886],
                            [110.819712, 26.638144],
                            [110.81876, 26.643248],
                            [110.817807, 26.656841],
                            [110.818805, 26.664836],
                            [110.820619, 26.669565],
                            [110.8303, 26.672525],
                            [110.836172, 26.672457],
                            [110.840117, 26.669956],
                            [110.84311, 26.66453],
                            [110.845514, 26.661877],
                            [110.850116, 26.661638],
                            [110.85515, 26.664156],
                            [110.858188, 26.672032],
                            [110.862201, 26.678954],
                            [110.868753, 26.697696],
                            [110.869479, 26.705008],
                            [110.868118, 26.712439],
                            [110.863652, 26.718866],
                            [110.858596, 26.720311],
                            [110.854469, 26.720617],
                            [110.850025, 26.722844],
                            [110.848506, 26.725598],
                            [110.847826, 26.730104],
                            [110.848053, 26.735136],
                            [110.849073, 26.737023],
                            [110.859729, 26.73942],
                            [110.865783, 26.739726],
                            [110.876485, 26.74151],
                            [110.880974, 26.7427],
                            [110.884874, 26.744961],
                            [110.888456, 26.744502],
                            [110.891562, 26.7384],
                            [110.896663, 26.73398],
                            [110.899475, 26.733844],
                            [110.908045, 26.735034],
                            [110.913555, 26.737057],
                            [110.925344, 26.740388],
                            [110.929312, 26.742275],
                            [110.932917, 26.746236],
                            [110.934232, 26.750757],
                            [110.938631, 26.759511],
                            [110.94516, 26.773447],
                            [110.946203, 26.777542],
                            [110.945433, 26.781944],
                            [110.942281, 26.788979],
                            [110.940195, 26.796676],
                            [110.939742, 26.801977],
                            [110.93684, 26.808772],
                            [110.93099, 26.826524],
                            [110.929017, 26.836036],
                            [110.9287, 26.843407],
                            [110.929471, 26.84728],
                            [110.931534, 26.851321],
                            [110.93498, 26.852986],
                            [110.948516, 26.842932],
                            [110.954819, 26.840792],
                            [110.960487, 26.840741],
                            [110.969896, 26.84283],
                            [110.975225, 26.845564],
                            [110.979056, 26.849301],
                            [110.982434, 26.853512],
                            [110.983047, 26.863124],
                            [110.980575, 26.868761],
                            [110.979691, 26.883516],
                            [110.981165, 26.89034],
                            [110.98681, 26.910303],
                            [110.988533, 26.912968],
                            [110.991481, 26.914903],
                            [110.99597, 26.916312],
                            [110.998373, 26.918077],
                            [111.000935, 26.921624],
                            [111.002205, 26.925477],
                            [111.004427, 26.928124],
                            [111.00912, 26.93106],
                            [111.015061, 26.928413],
                            [111.02388, 26.927377],
                            [111.030093, 26.929007],
                            [111.032655, 26.9333],
                            [111.030342, 26.940275],
                            [111.029843, 26.943923],
                            [111.033403, 26.94825],
                            [111.035511, 26.952611],
                            [111.031748, 26.962875],
                            [111.032043, 26.965047],
                            [111.034831, 26.966726],
                            [111.035307, 26.968796],
                            [111.031408, 26.972393],
                            [111.032043, 26.974615],
                            [111.035874, 26.977923],
                            [111.036305, 26.97967],
                            [111.028052, 26.980959],
                            [111.024152, 26.987439],
                            [111.022951, 26.991171],
                            [111.023722, 26.992901],
                            [111.020162, 26.99441],
                            [111.019641, 26.996666],
                            [111.017351, 26.997836],
                            [111.013882, 27.001958],
                            [111.009891, 27.008521],
                            [111.007873, 27.006011],
                            [111.003679, 27.005756],
                            [111.001548, 27.007673],
                            [111.000958, 27.01176],
                            [110.999348, 27.013371],
                            [110.999893, 27.017916],
                            [110.99724, 27.02614],
                            [110.992841, 27.030023],
                            [110.988579, 27.032057],
                            [110.984203, 27.032498],
                            [110.977424, 27.03143],
                            [110.970191, 27.031311],
                            [110.948629, 27.029327],
                            [110.938857, 27.030023],
                            [110.93523, 27.029734],
                            [110.930695, 27.027208],
                            [110.925979, 27.025817],
                            [110.919608, 27.027055],
                            [110.912784, 27.025088],
                            [110.91453, 27.016033],
                            [110.917976, 27.009199],
                            [110.9175, 26.997734],
                            [110.919359, 26.990289],
                            [110.922646, 26.985302],
                            [110.934844, 26.97414],
                            [110.941102, 26.965963],
                            [110.945002, 26.964165],
                            [110.956837, 26.964063],
                            [110.959467, 26.962298],
                            [110.957358, 26.957565],
                            [110.956678, 26.953764],
                            [110.95135, 26.951966],
                            [110.950058, 26.949404],
                            [110.95101, 26.944279],
                            [110.950511, 26.943363],
                            [110.941691, 26.93924],
                            [110.936363, 26.937611],
                            [110.933484, 26.938578],
                            [110.928723, 26.936796],
                            [110.925526, 26.936406],
                            [110.921377, 26.937729],
                            [110.913419, 26.932435],
                            [110.910244, 26.926851],
                            [110.908317, 26.924781],
                            [110.909723, 26.914462],
                            [110.907297, 26.913698],
                            [110.907252, 26.911644],
                            [110.905211, 26.913902],
                            [110.904576, 26.910863],
                            [110.884647, 26.912493],
                            [110.878344, 26.914631],
                            [110.872902, 26.918128],
                            [110.869819, 26.922184],
                            [110.868799, 26.926155],
                            [110.875714, 26.934301],
                            [110.876281, 26.937118],
                            [110.869252, 26.93929],
                            [110.867347, 26.935472],
                            [110.857507, 26.940716],
                            [110.857553, 26.944245],
                            [110.852021, 26.944687],
                            [110.8532, 26.950982],
                            [110.843972, 26.958973],
                            [110.840594, 26.958617],
                            [110.843541, 26.95636],
                            [110.83946, 26.952526],
                            [110.835424, 26.952628],
                            [110.832046, 26.959177],
                            [110.827806, 26.959499],
                            [110.827806, 26.95558],
                            [110.825267, 26.962129],
                            [110.818034, 26.96089],
                            [110.809555, 26.961077],
                            [110.806675, 26.965573],
                            [110.80332, 26.96525],
                            [110.801392, 26.968067],
                            [110.803569, 26.969356],
                            [110.802662, 26.970391],
                            [110.798354, 26.971069],
                            [110.793752, 26.970425],
                            [110.782007, 26.966591],
                            [110.782597, 26.961891],
                            [110.780987, 26.957412],
                            [110.781486, 26.952695],
                            [110.78042, 26.951033],
                            [110.776226, 26.948623],
                            [110.772757, 26.947961],
                            [110.766726, 26.948793],
                            [110.759584, 26.942532],
                            [110.750968, 26.932146],
                            [110.748225, 26.921013],
                            [110.74174, 26.91604],
                            [110.734077, 26.912493],
                            [110.718591, 26.912068],
                            [110.70458, 26.91066],
                            [110.699796, 26.908114],
                            [110.696213, 26.904142],
                            [110.694694, 26.900543],
                            [110.698458, 26.886113],
                            [110.69873, 26.882191],
                            [110.693969, 26.88012],
                            [110.688505, 26.87922],
                            [110.683358, 26.87922],
                            [110.677644, 26.882938],
                            [110.672316, 26.888456],
                            [110.666149, 26.89287],
                            [110.658577, 26.899881],
                            [110.653316, 26.90219],
                            [110.645064, 26.902105],
                            [110.639441, 26.902835],
                            [110.636198, 26.904821],
                            [110.632571, 26.908657],
                            [110.62858, 26.910677],
                            [110.620917, 26.911729],
                            [110.604048, 26.909302],
                            [110.598947, 26.905635],
                            [110.594299, 26.901018],
                            [110.584323, 26.893447],
                            [110.569949, 26.889084],
                            [110.564439, 26.885791],
                            [110.557751, 26.88866],
                            [110.556617, 26.888643],
                            [110.553307, 26.883872],
                            [110.5485, 26.880748],
                            [110.541109, 26.877421],
                            [110.538456, 26.870255],
                            [110.538161, 26.865518],
                            [110.540383, 26.861833],
                            [110.540814, 26.857027],
                            [110.540021, 26.853631]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430582,
                "name": "邵东市",
                "center": [111.743168, 27.257273],
                "centroid": [111.850315, 27.187043],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430500
                },
                "subFeatureIndex": 11,
                "acroutes": [100000, 430000, 430500]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [111.600585, 27.286758],
                            [111.604984, 27.283239],
                            [111.606571, 27.274188],
                            [111.603918, 27.267369],
                            [111.608838, 27.264747],
                            [111.609518, 27.260906],
                            [111.61403, 27.259451],
                            [111.612511, 27.256168],
                            [111.612942, 27.253596],
                            [111.611196, 27.25099],
                            [111.611831, 27.249281],
                            [111.614778, 27.248723],
                            [111.618383, 27.251802],
                            [111.626183, 27.254476],
                            [111.629538, 27.251447],
                            [111.627339, 27.247707],
                            [111.626251, 27.242512],
                            [111.624958, 27.240379],
                            [111.622532, 27.239347],
                            [111.622351, 27.235708],
                            [111.623757, 27.231562],
                            [111.62319, 27.228532],
                            [111.621059, 27.225469],
                            [111.61997, 27.219477],
                            [111.620016, 27.208981],
                            [111.618655, 27.20717],
                            [111.618293, 27.200923],
                            [111.614007, 27.197791],
                            [111.610743, 27.196606],
                            [111.608906, 27.192814],
                            [111.604235, 27.191713],
                            [111.602059, 27.193051],
                            [111.600812, 27.197571],
                            [111.598227, 27.199755],
                            [111.595983, 27.195895],
                            [111.592967, 27.192577],
                            [111.593987, 27.190697],
                            [111.590927, 27.187311],
                            [111.586097, 27.178675],
                            [111.584828, 27.170733],
                            [111.583286, 27.16523],
                            [111.577346, 27.154865],
                            [111.574988, 27.148937],
                            [111.57535, 27.143618],
                            [111.577073, 27.141382],
                            [111.582923, 27.139045],
                            [111.586664, 27.135386],
                            [111.585961, 27.132777],
                            [111.583649, 27.132015],
                            [111.589952, 27.12778],
                            [111.590586, 27.126001],
                            [111.586052, 27.122223],
                            [111.586392, 27.120088],
                            [111.589475, 27.119563],
                            [111.592582, 27.122596],
                            [111.594554, 27.122393],
                            [111.594962, 27.11814],
                            [111.593625, 27.112905],
                            [111.595098, 27.108703],
                            [111.591335, 27.108584],
                            [111.586188, 27.105704],
                            [111.585961, 27.104348],
                            [111.588523, 27.104382],
                            [111.588773, 27.101891],
                            [111.591856, 27.101891],
                            [111.594214, 27.098621],
                            [111.594917, 27.099824],
                            [111.600018, 27.097926],
                            [111.60215, 27.094334],
                            [111.603261, 27.096486],
                            [111.605664, 27.092232],
                            [111.610017, 27.088572],
                            [111.60809, 27.087555],
                            [111.605755, 27.08808],
                            [111.603963, 27.083318],
                            [111.602331, 27.082912],
                            [111.603215, 27.079997],
                            [111.601311, 27.073709],
                            [111.602036, 27.071031],
                            [111.604508, 27.068454],
                            [111.605641, 27.064522],
                            [111.607636, 27.064386],
                            [111.610493, 27.066878],
                            [111.614846, 27.067454],
                            [111.616773, 27.068624],
                            [111.616864, 27.073031],
                            [111.618179, 27.075014],
                            [111.620378, 27.07359],
                            [111.623643, 27.074048],
                            [111.622396, 27.076692],
                            [111.624369, 27.077861],
                            [111.624006, 27.080539],
                            [111.621331, 27.082674],
                            [111.623167, 27.084844],
                            [111.626341, 27.08564],
                            [111.627498, 27.087606],
                            [111.632871, 27.08425],
                            [111.636612, 27.083793],
                            [111.637066, 27.077386],
                            [111.638743, 27.076336],
                            [111.640897, 27.0782],
                            [111.64423, 27.077963],
                            [111.643935, 27.080454],
                            [111.646361, 27.082352],
                            [111.64745, 27.084657],
                            [111.650034, 27.083589],
                            [111.651463, 27.080878],
                            [111.653821, 27.080912],
                            [111.657675, 27.07859],
                            [111.660441, 27.078149],
                            [111.664069, 27.067658],
                            [111.668218, 27.063047],
                            [111.672095, 27.065285],
                            [111.670939, 27.061878],
                            [111.670916, 27.058742],
                            [111.67239, 27.058826],
                            [111.675723, 27.061861],
                            [111.676607, 27.060742],
                            [111.674793, 27.057758],
                            [111.672186, 27.056691],
                            [111.67205, 27.055233],
                            [111.668195, 27.046044],
                            [111.667833, 27.04362],
                            [111.670735, 27.043857],
                            [111.671687, 27.042705],
                            [111.669148, 27.041026],
                            [111.668581, 27.038144],
                            [111.670327, 27.035516],
                            [111.66976, 27.032549],
                            [111.674022, 27.031972],
                            [111.676992, 27.033041],
                            [111.678852, 27.032345],
                            [111.68452, 27.032312],
                            [111.687059, 27.030667],
                            [111.688283, 27.031379],
                            [111.690324, 27.03672],
                            [111.697307, 27.039636],
                            [111.69928, 27.046587],
                            [111.703973, 27.047774],
                            [111.706807, 27.050164],
                            [111.709868, 27.050435],
                            [111.712861, 27.052334],
                            [111.713813, 27.055063],
                            [111.718007, 27.059657],
                            [111.720093, 27.060742],
                            [111.72134, 27.06498],
                            [111.724787, 27.069065],
                            [111.723744, 27.071522],
                            [111.726532, 27.069539],
                            [111.732201, 27.066793],
                            [111.736486, 27.06359],
                            [111.737869, 27.061556],
                            [111.743968, 27.062454],
                            [111.745305, 27.060386],
                            [111.748842, 27.061403],
                            [111.753332, 27.061454],
                            [111.756188, 27.063183],
                            [111.758025, 27.062742],
                            [111.754352, 27.059708],
                            [111.753536, 27.057318],
                            [111.758546, 27.058437],
                            [111.762832, 27.057487],
                            [111.767797, 27.059352],
                            [111.770246, 27.059013],
                            [111.773624, 27.05686],
                            [111.775256, 27.05347],
                            [111.776934, 27.04579],
                            [111.779609, 27.04418],
                            [111.783872, 27.043247],
                            [111.786933, 27.039687],
                            [111.793417, 27.038907],
                            [111.796773, 27.036092],
                            [111.803166, 27.035448],
                            [111.80489, 27.033668],
                            [111.807837, 27.033125],
                            [111.810422, 27.037601],
                            [111.807837, 27.043671],
                            [111.809152, 27.046028],
                            [111.811397, 27.046451],
                            [111.816067, 27.045451],
                            [111.823232, 27.036771],
                            [111.823368, 27.034261],
                            [111.820375, 27.031396],
                            [111.81745, 27.020968],
                            [111.818425, 27.01956],
                            [111.82262, 27.020256],
                            [111.827132, 27.015694],
                            [111.829784, 27.016779],
                            [111.841937, 27.008724],
                            [111.844658, 27.008402],
                            [111.849056, 27.010471],
                            [111.854906, 27.011014],
                            [111.859984, 27.009437],
                            [111.863181, 27.009776],
                            [111.865607, 27.012099],
                            [111.868713, 27.013422],
                            [111.870346, 27.012591],
                            [111.871343, 27.009013],
                            [111.876513, 27.007062],
                            [111.877397, 27.003738],
                            [111.876422, 27.000686],
                            [111.877284, 26.997056],
                            [111.880934, 26.999464],
                            [111.883632, 26.996412],
                            [111.890116, 26.994682],
                            [111.898369, 26.986659],
                            [111.898279, 26.984827],
                            [111.894334, 26.978245],
                            [111.898233, 26.968847],
                            [111.899004, 26.960449],
                            [111.898052, 26.953357],
                            [111.899118, 26.947181],
                            [111.897621, 26.943685],
                            [111.898709, 26.940326],
                            [111.89905, 26.933707],
                            [111.89744, 26.929567],
                            [111.898528, 26.926648],
                            [111.903992, 26.923457],
                            [111.907439, 26.924764],
                            [111.912018, 26.930093],
                            [111.917823, 26.93515],
                            [111.922788, 26.936491],
                            [111.925214, 26.934929],
                            [111.929839, 26.938561],
                            [111.931222, 26.943041],
                            [111.93485, 26.951474],
                            [111.935621, 26.952237],
                            [111.94571, 26.954748],
                            [111.948408, 26.953272],
                            [111.953578, 26.95519],
                            [111.955391, 26.954799],
                            [111.961808, 26.951151],
                            [111.965821, 26.951287],
                            [111.968927, 26.952424],
                            [111.972509, 26.958108],
                            [111.97523, 26.959092],
                            [111.983778, 26.958227],
                            [111.985818, 26.9558],
                            [111.986362, 26.950015],
                            [111.980331, 26.944873],
                            [111.97947, 26.942294],
                            [111.988834, 26.942447],
                            [111.993867, 26.945111],
                            [112.000646, 26.943651],
                            [112.005272, 26.948827],
                            [112.007063, 26.952661],
                            [112.012708, 26.953374],
                            [112.016291, 26.955427],
                            [112.018921, 26.95972],
                            [112.019873, 26.964911],
                            [112.021913, 26.966353],
                            [112.02672, 26.965709],
                            [112.03105, 26.967863],
                            [112.034293, 26.971714],
                            [112.036197, 26.972579],
                            [112.042319, 26.970985],
                            [112.047125, 26.975277],
                            [112.047602, 26.976617],
                            [112.047647, 26.985726],
                            [112.047194, 26.987676],
                            [112.043793, 26.992968],
                            [112.043203, 26.995648],
                            [112.037308, 26.996683],
                            [112.046717, 26.998616],
                            [112.048554, 27.000838],
                            [112.048735, 27.004111],
                            [112.050164, 27.006452],
                            [112.055038, 27.011115],
                            [112.060094, 27.013032],
                            [112.067259, 27.010285],
                            [112.072315, 27.011607],
                            [112.080976, 27.008555],
                            [112.082835, 27.009301],
                            [112.086826, 27.013235],
                            [112.089388, 27.019662],
                            [112.089274, 27.025071],
                            [112.086123, 27.027344],
                            [112.085783, 27.029446],
                            [112.086848, 27.033922],
                            [112.084853, 27.036042],
                            [112.082178, 27.036516],
                            [112.079525, 27.038619],
                            [112.078051, 27.041789],
                            [112.07923, 27.04418],
                            [112.083447, 27.0464],
                            [112.082812, 27.049401],
                            [112.07753, 27.049655],
                            [112.075875, 27.05252],
                            [112.075761, 27.057013],
                            [112.074786, 27.060623],
                            [112.071816, 27.06498],
                            [112.060616, 27.067895],
                            [112.050572, 27.071098],
                            [112.046241, 27.074268],
                            [112.046921, 27.077268],
                            [112.054517, 27.079624],
                            [112.056195, 27.081268],
                            [112.05624, 27.085759],
                            [112.052114, 27.093724],
                            [112.051184, 27.100332],
                            [112.051705, 27.102874],
                            [112.054608, 27.107059],
                            [112.056648, 27.111244],
                            [112.056036, 27.116649],
                            [112.054041, 27.11936],
                            [112.04903, 27.123968],
                            [112.046445, 27.128661],
                            [112.046604, 27.132693],
                            [112.048667, 27.135454],
                            [112.052476, 27.142856],
                            [112.049075, 27.1477],
                            [112.049007, 27.1508],
                            [112.051728, 27.158422],
                            [112.052068, 27.161622],
                            [112.054245, 27.16777],
                            [112.058122, 27.175424],
                            [112.063926, 27.181605],
                            [112.068483, 27.185194],
                            [112.067531, 27.188124],
                            [112.070683, 27.19107],
                            [112.075285, 27.18809],
                            [112.074151, 27.18577],
                            [112.076736, 27.185161],
                            [112.084173, 27.187108],
                            [112.089954, 27.185499],
                            [112.092788, 27.187175],
                            [112.0917, 27.190291],
                            [112.089546, 27.19068],
                            [112.086803, 27.18941],
                            [112.086826, 27.191882],
                            [112.091269, 27.19261],
                            [112.092562, 27.195235],
                            [112.088526, 27.195252],
                            [112.09009, 27.200144],
                            [112.09229, 27.202261],
                            [112.092607, 27.205308],
                            [112.089524, 27.204851],
                            [112.086667, 27.20568],
                            [112.085329, 27.203987],
                            [112.07685, 27.208],
                            [112.076373, 27.209422],
                            [112.07329, 27.211318],
                            [112.068415, 27.218664],
                            [112.067939, 27.22601],
                            [112.069254, 27.235878],
                            [112.066715, 27.242546],
                            [112.065196, 27.250804],
                            [112.065286, 27.257894],
                            [112.063654, 27.268165],
                            [112.062407, 27.285844],
                            [112.058711, 27.289532],
                            [112.051275, 27.292391],
                            [112.041072, 27.28916],
                            [112.037217, 27.291443],
                            [112.031821, 27.295808],
                            [112.025133, 27.303605],
                            [112.019986, 27.312553],
                            [112.018036, 27.317542],
                            [112.000397, 27.318624],
                            [111.994502, 27.314887],
                            [111.990806, 27.317339],
                            [111.988494, 27.321702],
                            [111.988244, 27.326522],
                            [111.985682, 27.332728],
                            [111.990285, 27.341554],
                            [111.985342, 27.345646],
                            [111.975842, 27.350972],
                            [111.970491, 27.357531],
                            [111.965549, 27.36],
                            [111.958906, 27.362079],
                            [111.95351, 27.365528],
                            [111.949066, 27.366052],
                            [111.940858, 27.369196],
                            [111.929771, 27.376769],
                            [111.923604, 27.37998],
                            [111.918457, 27.383428],
                            [111.914331, 27.385034],
                            [111.909456, 27.388955],
                            [111.907643, 27.39171],
                            [111.907302, 27.395394],
                            [111.910771, 27.399044],
                            [111.913084, 27.402728],
                            [111.912857, 27.410299],
                            [111.905149, 27.417649],
                            [111.892792, 27.426841],
                            [111.882521, 27.438346],
                            [111.875583, 27.445018],
                            [111.869144, 27.44985],
                            [111.863748, 27.451927],
                            [111.858352, 27.458836],
                            [111.855291, 27.461133],
                            [111.847288, 27.460694],
                            [111.840214, 27.456623],
                            [111.838785, 27.451302],
                            [111.83518, 27.446032],
                            [111.831575, 27.44512],
                            [111.823753, 27.447383],
                            [111.819559, 27.440018],
                            [111.814979, 27.428496],
                            [111.814276, 27.423935],
                            [111.818357, 27.41792],
                            [111.825612, 27.412766],
                            [111.830306, 27.412022],
                            [111.835906, 27.410029],
                            [111.838536, 27.406649],
                            [111.836563, 27.400937],
                            [111.837992, 27.397456],
                            [111.835906, 27.394769],
                            [111.832528, 27.393501],
                            [111.827358, 27.398081],
                            [111.825159, 27.396695],
                            [111.823436, 27.393248],
                            [111.819332, 27.391879],
                            [111.816203, 27.394701],
                            [111.810376, 27.39411],
                            [111.80786, 27.39607],
                            [111.80659, 27.40408],
                            [111.803212, 27.405077],
                            [111.799199, 27.402627],
                            [111.795979, 27.402543],
                            [111.793666, 27.399095],
                            [111.793258, 27.393248],
                            [111.791649, 27.38958],
                            [111.785346, 27.386268],
                            [111.782534, 27.382938],
                            [111.778408, 27.383293],
                            [111.777093, 27.384915],
                            [111.773352, 27.385592],
                            [111.770926, 27.384746],
                            [111.768273, 27.385946],
                            [111.767683, 27.388549],
                            [111.763217, 27.390848],
                            [111.760133, 27.389039],
                            [111.74755, 27.38429],
                            [111.739683, 27.382634],
                            [111.73279, 27.38025],
                            [111.728777, 27.374723],
                            [111.727575, 27.364665],
                            [111.728664, 27.358174],
                            [111.725875, 27.351665],
                            [111.721726, 27.347742],
                            [111.7154, 27.346018],
                            [111.693521, 27.343718],
                            [111.68656, 27.340185],
                            [111.686061, 27.336752],
                            [111.691594, 27.312333],
                            [111.692365, 27.304942],
                            [111.691707, 27.299225],
                            [111.690165, 27.295639],
                            [111.686606, 27.292831],
                            [111.681436, 27.292864],
                            [111.677196, 27.294928],
                            [111.672412, 27.300696],
                            [111.669669, 27.301525],
                            [111.670123, 27.30555],
                            [111.666382, 27.306142],
                            [111.659716, 27.301745],
                            [111.654909, 27.30161],
                            [111.655567, 27.311233],
                            [111.654229, 27.314278],
                            [111.652211, 27.315089],
                            [111.649082, 27.314565],
                            [111.643165, 27.311419],
                            [111.63727, 27.304367],
                            [111.633959, 27.301322],
                            [111.629742, 27.300392],
                            [111.623757, 27.30325],
                            [111.622714, 27.306751],
                            [111.622056, 27.312299],
                            [111.62022, 27.319301],
                            [111.618066, 27.321939],
                            [111.614733, 27.322345],
                            [111.603351, 27.318134],
                            [111.597479, 27.315462],
                            [111.594622, 27.31147],
                            [111.595393, 27.307529],
                            [111.601605, 27.303978],
                            [111.601832, 27.299817],
                            [111.600041, 27.28982],
                            [111.600585, 27.286758]
                        ]
                    ]
                ]
            }
        }]
    });
}));