/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

(function(root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['exports', 'echarts'], factory);
    } else if (typeof exports === 'object' && typeof exports.nodeName !== 'string') {
        // CommonJS
        factory(exports, require('echarts'));
    } else {
        // Browser globals
        factory({}, root.echarts);
    }
}(this, function(exports, echarts) {
    var log = function(msg) {
        if (typeof console !== 'undefined') {
            console && console.error && console.error(msg);
        }
    }
    if (!echarts) {
        log('ECharts is not Loaded');
        return;
    }
    if (!echarts.registerMap) {
        log('ECharts Map is not loaded')
        return;
    }
    echarts.registerMap('永州市', {
        "type": "FeatureCollection",
        "features": [{
            "type": "Feature",
            "properties": {
                "adcode": 431102,
                "name": "零陵区",
                "center": [111.626348, 26.223347],
                "centroid": [111.554641, 26.129991],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431100
                },
                "subFeatureIndex": 0,
                "acroutes": [100000, 430000, 431100]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [111.815293, 26.356396],
                            [111.812626, 26.35291],
                            [111.804535, 26.349534],
                            [111.798674, 26.354575],
                            [111.78659, 26.352532],
                            [111.787946, 26.348957],
                            [111.781542, 26.338808],
                            [111.774189, 26.341095],
                            [111.76771, 26.335054],
                            [111.759107, 26.336454],
                            [111.752552, 26.33199],
                            [111.748379, 26.33199],
                            [111.745863, 26.337031],
                            [111.744748, 26.349423],
                            [111.740212, 26.352754],
                            [111.732814, 26.350689],
                            [111.72623, 26.35231],
                            [111.719299, 26.348668],
                            [111.713423, 26.34989],
                            [111.703764, 26.349401],
                            [111.688998, 26.342184],
                            [111.684282, 26.33672],
                            [111.675814, 26.339763],
                            [111.662404, 26.334744],
                            [111.661757, 26.331212],
                            [111.666337, 26.323216],
                            [111.666699, 26.317396],
                            [111.658457, 26.310399],
                            [111.642922, 26.31071],
                            [111.640813, 26.306711],
                            [111.635102, 26.307933],
                            [111.62552, 26.302935],
                            [111.62329, 26.299625],
                            [111.624525, 26.286449],
                            [111.62112, 26.276695],
                            [111.611025, 26.267717],
                            [111.60658, 26.261006],
                            [111.601261, 26.246581],
                            [111.596696, 26.245359],
                            [111.597072, 26.25136],
                            [111.594119, 26.256427],
                            [111.588635, 26.257872],
                            [111.579956, 26.252027],
                            [111.576792, 26.253916],
                            [111.576023, 26.258917],
                            [111.579745, 26.267228],
                            [111.573507, 26.268806],
                            [111.562056, 26.267273],
                            [111.55062, 26.275984],
                            [111.548616, 26.282761],
                            [111.543086, 26.293004],
                            [111.536682, 26.296203],
                            [111.530851, 26.29407],
                            [111.533157, 26.283827],
                            [111.528847, 26.280028],
                            [111.517863, 26.283339],
                            [111.501153, 26.29958],
                            [111.49371, 26.298069],
                            [111.488904, 26.291249],
                            [111.48815, 26.28525],
                            [111.49026, 26.27885],
                            [111.483585, 26.273717],
                            [111.465836, 26.262873],
                            [111.458211, 26.260317],
                            [111.450889, 26.253983],
                            [111.433471, 26.243936],
                            [111.430066, 26.239424],
                            [111.425606, 26.228932],
                            [111.415149, 26.222196],
                            [111.413808, 26.212592],
                            [111.405807, 26.202453],
                            [111.402869, 26.200808],
                            [111.395049, 26.201275],
                            [111.389655, 26.203609],
                            [111.386596, 26.208479],
                            [111.382769, 26.20919],
                            [111.372358, 26.20292],
                            [111.367114, 26.193292],
                            [111.354397, 26.187221],
                            [111.349983, 26.186687],
                            [111.344227, 26.190557],
                            [111.341334, 26.189956],
                            [111.338336, 26.183641],
                            [111.333378, 26.182751],
                            [111.32815, 26.186665],
                            [111.320496, 26.185887],
                            [111.314017, 26.181839],
                            [111.319788, 26.161155],
                            [111.314544, 26.153147],
                            [111.310717, 26.153125],
                            [111.302114, 26.160621],
                            [111.295876, 26.16051],
                            [111.287167, 26.152347],
                            [111.286941, 26.148943],
                            [111.291762, 26.143893],
                            [111.29565, 26.130144],
                            [111.294474, 26.122513],
                            [111.287528, 26.116282],
                            [111.282225, 26.116616],
                            [111.276695, 26.120733],
                            [111.270819, 26.119197],
                            [111.268905, 26.107337],
                            [111.261417, 26.102374],
                            [111.259367, 26.096677],
                            [111.266615, 26.09888],
                            [111.264415, 26.094585],
                            [111.251834, 26.086306],
                            [111.244632, 26.077959],
                            [111.245219, 26.075911],
                            [111.258358, 26.063601],
                            [111.264942, 26.061241],
                            [111.266992, 26.056121],
                            [111.252919, 26.056677],
                            [111.249287, 26.051802],
                            [111.236827, 26.048841],
                            [111.23416, 26.046302],
                            [111.230483, 26.034479],
                            [111.221594, 26.023857],
                            [111.213969, 26.019826],
                            [111.215371, 26.011787],
                            [111.21079, 26.007221],
                            [111.212463, 26.001831],
                            [111.206451, 25.987375],
                            [111.2063, 25.982407],
                            [111.202925, 25.976103],
                            [111.196687, 25.970867],
                            [111.1961, 25.960886],
                            [111.18953, 25.953287],
                            [111.19176, 25.95175],
                            [111.202428, 25.954201],
                            [111.205019, 25.952909],
                            [111.204432, 25.944686],
                            [111.206541, 25.939627],
                            [111.21968, 25.937042],
                            [111.220961, 25.935505],
                            [111.216621, 25.928952],
                            [111.221322, 25.922823],
                            [111.23077, 25.916114],
                            [111.232231, 25.911054],
                            [111.226189, 25.910363],
                            [111.222498, 25.90731],
                            [111.223959, 25.902963],
                            [111.229127, 25.897501],
                            [111.242688, 25.887781],
                            [111.246801, 25.880981],
                            [111.247268, 25.871973],
                            [111.251608, 25.864703],
                            [111.259895, 25.861046],
                            [111.28013, 25.859864],
                            [111.287769, 25.854713],
                            [111.291898, 25.854423],
                            [111.296539, 25.867223],
                            [111.297066, 25.874269],
                            [111.305308, 25.883701],
                            [111.317919, 25.891995],
                            [111.326508, 25.895495],
                            [111.327623, 25.900199],
                            [111.341409, 25.90673],
                            [111.346231, 25.906329],
                            [111.355, 25.892128],
                            [111.362564, 25.892329],
                            [111.365246, 25.900555],
                            [111.37507, 25.90566],
                            [111.37846, 25.904768],
                            [111.378837, 25.89409],
                            [111.384487, 25.889297],
                            [111.382332, 25.883366],
                            [111.385481, 25.882096],
                            [111.402764, 25.885351],
                            [111.405762, 25.897702],
                            [111.402327, 25.907822],
                            [111.403261, 25.911857],
                            [111.40861, 25.918366],
                            [111.409197, 25.92347],
                            [111.406199, 25.936418],
                            [111.408293, 25.940118],
                            [111.414607, 25.943416],
                            [111.418072, 25.950368],
                            [111.423587, 25.955894],
                            [111.420679, 25.964651],
                            [111.420709, 25.971491],
                            [111.422788, 25.974142],
                            [111.43222, 25.974899],
                            [111.43665, 25.983766],
                            [111.443717, 25.991206],
                            [111.448192, 25.991273],
                            [111.461918, 25.976437],
                            [111.477151, 25.983254],
                            [111.492791, 25.977929],
                            [111.496829, 25.974588],
                            [111.507587, 25.957365],
                            [111.513569, 25.953867],
                            [111.522459, 25.957632],
                            [111.529435, 25.955738],
                            [111.537044, 25.955716],
                            [111.535823, 25.950502],
                            [111.538686, 25.936351],
                            [111.543598, 25.936463],
                            [111.553618, 25.943951],
                            [111.565868, 25.950725],
                            [111.574667, 25.950146],
                            [111.580001, 25.943572],
                            [111.584928, 25.934078],
                            [111.591271, 25.93049],
                            [111.600779, 25.933833],
                            [111.606836, 25.942569],
                            [111.611763, 25.952463],
                            [111.609126, 25.959081],
                            [111.602903, 25.962267],
                            [111.600206, 25.966946],
                            [111.590397, 25.971201],
                            [111.585531, 25.975791],
                            [111.588047, 25.986283],
                            [111.584823, 25.992676],
                            [111.580182, 25.993924],
                            [111.564934, 25.98958],
                            [111.557114, 25.993701],
                            [111.550228, 26.005217],
                            [111.552488, 26.015016],
                            [111.560926, 26.021519],
                            [111.565883, 26.021964],
                            [111.571443, 26.029937],
                            [111.578991, 26.032876],
                            [111.58309, 26.037552],
                            [111.5877, 26.037418],
                            [111.589403, 26.032475],
                            [111.593984, 26.031673],
                            [111.597796, 26.065426],
                            [111.610482, 26.084814],
                            [111.623711, 26.086128],
                            [111.629753, 26.087775],
                            [111.638538, 26.08566],
                            [111.64583, 26.088286],
                            [111.650803, 26.092048],
                            [111.655925, 26.081921],
                            [111.654072, 26.079116],
                            [111.643977, 26.075154],
                            [111.642244, 26.066985],
                            [111.644354, 26.061642],
                            [111.651511, 26.056922],
                            [111.661998, 26.05828],
                            [111.674142, 26.069723],
                            [111.685277, 26.074954],
                            [111.692871, 26.08201],
                            [111.715276, 26.091714],
                            [111.718636, 26.091536],
                            [111.726742, 26.078003],
                            [111.734502, 26.076423],
                            [111.738434, 26.077825],
                            [111.745079, 26.090156],
                            [111.738495, 26.094852],
                            [111.744265, 26.101996],
                            [111.746782, 26.108494],
                            [111.752839, 26.11043],
                            [111.769277, 26.108138],
                            [111.775726, 26.111721],
                            [111.783712, 26.122357],
                            [111.789332, 26.122757],
                            [111.791924, 26.127163],
                            [111.782717, 26.131279],
                            [111.783004, 26.135328],
                            [111.788744, 26.13693],
                            [111.785354, 26.148543],
                            [111.780065, 26.152436],
                            [111.780005, 26.155995],
                            [111.795494, 26.155461],
                            [111.802561, 26.158508],
                            [111.81014, 26.157841],
                            [111.814479, 26.163846],
                            [111.82123, 26.165248],
                            [111.845217, 26.164692],
                            [111.848727, 26.154927],
                            [111.856819, 26.140645],
                            [111.861625, 26.136151],
                            [111.869716, 26.133014],
                            [111.892167, 26.117061],
                            [111.901569, 26.11379],
                            [111.905607, 26.109139],
                            [111.910835, 26.108383],
                            [111.915355, 26.112366],
                            [111.925631, 26.109317],
                            [111.929112, 26.106936],
                            [111.93422, 26.109785],
                            [111.943516, 26.109963],
                            [111.951457, 26.116549],
                            [111.960693, 26.121734],
                            [111.96223, 26.128898],
                            [111.957454, 26.132903],
                            [111.942929, 26.130656],
                            [111.934988, 26.137731],
                            [111.929172, 26.150411],
                            [111.924697, 26.165226],
                            [111.925179, 26.178125],
                            [111.935998, 26.180661],
                            [111.938107, 26.183485],
                            [111.932818, 26.192602],
                            [111.933165, 26.200407],
                            [111.927002, 26.209079],
                            [111.917133, 26.212592],
                            [111.910022, 26.208501],
                            [111.906963, 26.213592],
                            [111.907611, 26.221596],
                            [111.911483, 26.227176],
                            [111.918128, 26.229932],
                            [111.919137, 26.232822],
                            [111.916666, 26.251627],
                            [111.908967, 26.265917],
                            [111.911604, 26.275006],
                            [111.911197, 26.280161],
                            [111.905742, 26.288916],
                            [111.900167, 26.305445],
                            [111.894065, 26.309199],
                            [111.887134, 26.307156],
                            [111.882041, 26.31693],
                            [111.873649, 26.32275],
                            [111.873649, 26.331412],
                            [111.875698, 26.338053],
                            [111.8694, 26.339874],
                            [111.869083, 26.345026],
                            [111.86152, 26.344604],
                            [111.859666, 26.347536],
                            [111.861053, 26.353887],
                            [111.85367, 26.350933],
                            [111.845729, 26.353065],
                            [111.839054, 26.356862],
                            [111.834444, 26.356907],
                            [111.829592, 26.353643],
                            [111.821034, 26.353243],
                            [111.815293, 26.356396]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 431103,
                "name": "冷水滩区",
                "center": [111.607156, 26.434364],
                "centroid": [111.632415, 26.529754],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431100
                },
                "subFeatureIndex": 1,
                "acroutes": [100000, 430000, 431100]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [111.592055, 26.792199],
                            [111.588002, 26.797596],
                            [111.578404, 26.798392],
                            [111.57536, 26.809341],
                            [111.573055, 26.810315],
                            [111.558078, 26.808656],
                            [111.550002, 26.812349],
                            [111.54952, 26.817436],
                            [111.542574, 26.817215],
                            [111.538415, 26.81142],
                            [111.529224, 26.80724],
                            [111.528636, 26.80306],
                            [111.532087, 26.797773],
                            [111.534965, 26.788372],
                            [111.529631, 26.779014],
                            [111.524478, 26.775983],
                            [111.520741, 26.76596],
                            [111.515528, 26.765672],
                            [111.507738, 26.773328],
                            [111.502645, 26.775452],
                            [111.491812, 26.775695],
                            [111.484082, 26.770783],
                            [111.476654, 26.77304],
                            [111.473128, 26.77939],
                            [111.469617, 26.780164],
                            [111.469678, 26.766115],
                            [111.471637, 26.758127],
                            [111.480074, 26.757064],
                            [111.477965, 26.748788],
                            [111.482289, 26.744783],
                            [111.481626, 26.741419],
                            [111.471847, 26.73458],
                            [111.469994, 26.725705],
                            [111.471863, 26.720835],
                            [111.476172, 26.717138],
                            [111.48464, 26.713287],
                            [111.485167, 26.70908],
                            [111.478718, 26.704586],
                            [111.481792, 26.695886],
                            [111.491043, 26.693495],
                            [111.49582, 26.689221],
                            [111.496106, 26.678194],
                            [111.49362, 26.676046],
                            [111.481973, 26.674563],
                            [111.473354, 26.67226],
                            [111.469407, 26.668739],
                            [111.476759, 26.660988],
                            [111.477362, 26.653192],
                            [111.479773, 26.652195],
                            [111.49588, 26.654055],
                            [111.507994, 26.66112],
                            [111.518647, 26.656935],
                            [111.530188, 26.640013],
                            [111.53721, 26.636402],
                            [111.542182, 26.635937],
                            [111.548103, 26.628449],
                            [111.542709, 26.626057],
                            [111.53498, 26.629202],
                            [111.529239, 26.628804],
                            [111.518059, 26.620141],
                            [111.512544, 26.61848],
                            [111.512032, 26.611612],
                            [111.517878, 26.603967],
                            [111.520696, 26.592755],
                            [111.529827, 26.582406],
                            [111.532931, 26.571479],
                            [111.536381, 26.569396],
                            [111.541986, 26.558823],
                            [111.544111, 26.55818],
                            [111.558093, 26.560507],
                            [111.56638, 26.564652],
                            [111.572543, 26.557005],
                            [111.576385, 26.544746],
                            [111.574004, 26.539736],
                            [111.575179, 26.53508],
                            [111.573597, 26.528384],
                            [111.576731, 26.516809],
                            [111.566636, 26.513416],
                            [111.563623, 26.508072],
                            [111.558696, 26.504568],
                            [111.562478, 26.494122],
                            [111.557475, 26.48332],
                            [111.558877, 26.478729],
                            [111.553859, 26.477265],
                            [111.545753, 26.479173],
                            [111.533277, 26.474226],
                            [111.533217, 26.468746],
                            [111.523589, 26.465663],
                            [111.522534, 26.459673],
                            [111.518541, 26.45295],
                            [111.513011, 26.447625],
                            [111.518722, 26.442699],
                            [111.524252, 26.447359],
                            [111.527567, 26.444208],
                            [111.522233, 26.438017],
                            [111.51717, 26.436819],
                            [111.513599, 26.432337],
                            [111.506864, 26.42883],
                            [111.494403, 26.430916],
                            [111.496332, 26.423282],
                            [111.495157, 26.416158],
                            [111.494916, 26.401243],
                            [111.499376, 26.390344],
                            [111.517697, 26.385171],
                            [111.52377, 26.373449],
                            [111.522775, 26.365589],
                            [111.524312, 26.360415],
                            [111.523664, 26.345781],
                            [111.534498, 26.328613],
                            [111.539651, 26.325482],
                            [111.535613, 26.320284],
                            [111.535552, 26.310799],
                            [111.540464, 26.307733],
                            [111.542046, 26.300691],
                            [111.536682, 26.296203],
                            [111.543086, 26.293004],
                            [111.548616, 26.282761],
                            [111.55062, 26.275984],
                            [111.562056, 26.267273],
                            [111.573507, 26.268806],
                            [111.579745, 26.267228],
                            [111.576023, 26.258917],
                            [111.576792, 26.253916],
                            [111.579956, 26.252027],
                            [111.588635, 26.257872],
                            [111.594119, 26.256427],
                            [111.597072, 26.25136],
                            [111.596696, 26.245359],
                            [111.601261, 26.246581],
                            [111.60658, 26.261006],
                            [111.611025, 26.267717],
                            [111.62112, 26.276695],
                            [111.624525, 26.286449],
                            [111.62329, 26.299625],
                            [111.62552, 26.302935],
                            [111.635102, 26.307933],
                            [111.640813, 26.306711],
                            [111.642922, 26.31071],
                            [111.658457, 26.310399],
                            [111.666699, 26.317396],
                            [111.666337, 26.323216],
                            [111.661757, 26.331212],
                            [111.662404, 26.334744],
                            [111.675814, 26.339763],
                            [111.684282, 26.33672],
                            [111.688998, 26.342184],
                            [111.703764, 26.349401],
                            [111.713423, 26.34989],
                            [111.719299, 26.348668],
                            [111.72623, 26.35231],
                            [111.732814, 26.350689],
                            [111.740212, 26.352754],
                            [111.744748, 26.349423],
                            [111.745863, 26.337031],
                            [111.748379, 26.33199],
                            [111.752552, 26.33199],
                            [111.759107, 26.336454],
                            [111.76771, 26.335054],
                            [111.774189, 26.341095],
                            [111.781542, 26.338808],
                            [111.787946, 26.348957],
                            [111.78659, 26.352532],
                            [111.798674, 26.354575],
                            [111.804535, 26.349534],
                            [111.812626, 26.35291],
                            [111.815293, 26.356396],
                            [111.81344, 26.35986],
                            [111.813349, 26.375136],
                            [111.810773, 26.380598],
                            [111.801717, 26.387879],
                            [111.7989, 26.39394],
                            [111.80238, 26.405993],
                            [111.797182, 26.406992],
                            [111.796218, 26.415271],
                            [111.800437, 26.420442],
                            [111.799352, 26.427077],
                            [111.792376, 26.429008],
                            [111.791035, 26.4346],
                            [111.785806, 26.433668],
                            [111.784435, 26.43764],
                            [111.789061, 26.443365],
                            [111.788835, 26.44889],
                            [111.783079, 26.469944],
                            [111.784812, 26.478662],
                            [111.784556, 26.490884],
                            [111.776992, 26.506121],
                            [111.77101, 26.51111],
                            [111.758504, 26.509802],
                            [111.757148, 26.512019],
                            [111.758263, 26.523861],
                            [111.750398, 26.537075],
                            [111.751272, 26.539403],
                            [111.758971, 26.542706],
                            [111.759619, 26.560219],
                            [111.753215, 26.570548],
                            [111.751573, 26.585198],
                            [111.752281, 26.592711],
                            [111.749524, 26.595414],
                            [111.748394, 26.602239],
                            [111.751046, 26.603413],
                            [111.753698, 26.611013],
                            [111.753577, 26.61941],
                            [111.748997, 26.623575],
                            [111.73643, 26.630997],
                            [111.73078, 26.632104],
                            [111.725205, 26.636446],
                            [111.730599, 26.639038],
                            [111.72855, 26.648009],
                            [111.737485, 26.651863],
                            [111.735602, 26.656514],
                            [111.731021, 26.657976],
                            [111.731051, 26.665085],
                            [111.737771, 26.670909],
                            [111.736159, 26.67877],
                            [111.739248, 26.680962],
                            [111.740273, 26.68621],
                            [111.736747, 26.689044],
                            [111.721499, 26.68869],
                            [111.713001, 26.696572],
                            [111.706507, 26.697878],
                            [111.703644, 26.701952],
                            [111.705361, 26.708483],
                            [111.712066, 26.715323],
                            [111.710213, 26.719972],
                            [111.702288, 26.716895],
                            [111.695357, 26.717116],
                            [111.692434, 26.721145],
                            [111.69376, 26.727188],
                            [111.690882, 26.733872],
                            [111.685111, 26.736041],
                            [111.677231, 26.734912],
                            [111.672485, 26.736727],
                            [111.666066, 26.734292],
                            [111.651511, 26.738497],
                            [111.646312, 26.744406],
                            [111.63343, 26.74881],
                            [111.621376, 26.761291],
                            [111.61327, 26.762043],
                            [111.597871, 26.774301],
                            [111.590563, 26.78408],
                            [111.592055, 26.792199]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 431121,
                "name": "祁阳市",
                "center": [111.85734, 26.585929],
                "centroid": [111.966903, 26.452004],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431100
                },
                "subFeatureIndex": 2,
                "acroutes": [100000, 430000, 431100]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [111.592055, 26.792199],
                            [111.590563, 26.78408],
                            [111.597871, 26.774301],
                            [111.61327, 26.762043],
                            [111.621376, 26.761291],
                            [111.63343, 26.74881],
                            [111.646312, 26.744406],
                            [111.651511, 26.738497],
                            [111.666066, 26.734292],
                            [111.672485, 26.736727],
                            [111.677231, 26.734912],
                            [111.685111, 26.736041],
                            [111.690882, 26.733872],
                            [111.69376, 26.727188],
                            [111.692434, 26.721145],
                            [111.695357, 26.717116],
                            [111.702288, 26.716895],
                            [111.710213, 26.719972],
                            [111.712066, 26.715323],
                            [111.705361, 26.708483],
                            [111.703644, 26.701952],
                            [111.706507, 26.697878],
                            [111.713001, 26.696572],
                            [111.721499, 26.68869],
                            [111.736747, 26.689044],
                            [111.740273, 26.68621],
                            [111.739248, 26.680962],
                            [111.736159, 26.67877],
                            [111.737771, 26.670909],
                            [111.731051, 26.665085],
                            [111.731021, 26.657976],
                            [111.735602, 26.656514],
                            [111.737485, 26.651863],
                            [111.72855, 26.648009],
                            [111.730599, 26.639038],
                            [111.725205, 26.636446],
                            [111.73078, 26.632104],
                            [111.73643, 26.630997],
                            [111.748997, 26.623575],
                            [111.753577, 26.61941],
                            [111.753698, 26.611013],
                            [111.751046, 26.603413],
                            [111.748394, 26.602239],
                            [111.749524, 26.595414],
                            [111.752281, 26.592711],
                            [111.751573, 26.585198],
                            [111.753215, 26.570548],
                            [111.759619, 26.560219],
                            [111.758971, 26.542706],
                            [111.751272, 26.539403],
                            [111.750398, 26.537075],
                            [111.758263, 26.523861],
                            [111.757148, 26.512019],
                            [111.758504, 26.509802],
                            [111.77101, 26.51111],
                            [111.776992, 26.506121],
                            [111.784556, 26.490884],
                            [111.784812, 26.478662],
                            [111.783079, 26.469944],
                            [111.788835, 26.44889],
                            [111.789061, 26.443365],
                            [111.784435, 26.43764],
                            [111.785806, 26.433668],
                            [111.791035, 26.4346],
                            [111.792376, 26.429008],
                            [111.799352, 26.427077],
                            [111.800437, 26.420442],
                            [111.796218, 26.415271],
                            [111.797182, 26.406992],
                            [111.80238, 26.405993],
                            [111.7989, 26.39394],
                            [111.801717, 26.387879],
                            [111.810773, 26.380598],
                            [111.813349, 26.375136],
                            [111.81344, 26.35986],
                            [111.815293, 26.356396],
                            [111.821034, 26.353243],
                            [111.829592, 26.353643],
                            [111.834444, 26.356907],
                            [111.839054, 26.356862],
                            [111.845729, 26.353065],
                            [111.85367, 26.350933],
                            [111.861053, 26.353887],
                            [111.859666, 26.347536],
                            [111.86152, 26.344604],
                            [111.869083, 26.345026],
                            [111.8694, 26.339874],
                            [111.875698, 26.338053],
                            [111.873649, 26.331412],
                            [111.873649, 26.32275],
                            [111.882041, 26.31693],
                            [111.887134, 26.307156],
                            [111.894065, 26.309199],
                            [111.900167, 26.305445],
                            [111.905742, 26.288916],
                            [111.911197, 26.280161],
                            [111.911604, 26.275006],
                            [111.908967, 26.265917],
                            [111.916666, 26.251627],
                            [111.919137, 26.232822],
                            [111.918128, 26.229932],
                            [111.911483, 26.227176],
                            [111.907611, 26.221596],
                            [111.906963, 26.213592],
                            [111.910022, 26.208501],
                            [111.917133, 26.212592],
                            [111.927002, 26.209079],
                            [111.933165, 26.200407],
                            [111.932818, 26.192602],
                            [111.938107, 26.183485],
                            [111.935998, 26.180661],
                            [111.925179, 26.178125],
                            [111.924697, 26.165226],
                            [111.929172, 26.150411],
                            [111.934988, 26.137731],
                            [111.942929, 26.130656],
                            [111.957454, 26.132903],
                            [111.96223, 26.128898],
                            [111.960693, 26.121734],
                            [111.968619, 26.117929],
                            [111.983686, 26.106469],
                            [111.985509, 26.099259],
                            [111.993284, 26.096766],
                            [112.001932, 26.090623],
                            [112.009014, 26.089933],
                            [112.017256, 26.095186],
                            [112.027095, 26.093138],
                            [112.033152, 26.094207],
                            [112.035156, 26.097612],
                            [112.030154, 26.103398],
                            [112.04287, 26.107604],
                            [112.04388, 26.109317],
                            [112.04275, 26.120844],
                            [112.044874, 26.129766],
                            [112.048867, 26.135106],
                            [112.053824, 26.134839],
                            [112.06957, 26.125739],
                            [112.076441, 26.119531],
                            [112.073442, 26.115904],
                            [112.06847, 26.114547],
                            [112.063558, 26.118129],
                            [112.053749, 26.113256],
                            [112.051564, 26.109607],
                            [112.054126, 26.101595],
                            [112.046155, 26.095164],
                            [112.04611, 26.089978],
                            [112.053523, 26.087129],
                            [112.053855, 26.080897],
                            [112.060379, 26.075866],
                            [112.077827, 26.071459],
                            [112.07846, 26.062377],
                            [112.083914, 26.055653],
                            [112.093406, 26.047171],
                            [112.090393, 26.040046],
                            [112.093843, 26.035125],
                            [112.101015, 26.033655],
                            [112.113687, 26.04176],
                            [112.138503, 26.066406],
                            [112.144485, 26.06605],
                            [112.153736, 26.069433],
                            [112.176247, 26.089177],
                            [112.191359, 26.0969],
                            [112.202148, 26.098992],
                            [112.206246, 26.096388],
                            [112.209877, 26.09779],
                            [112.224538, 26.11092],
                            [112.229691, 26.111854],
                            [112.233126, 26.122869],
                            [112.231363, 26.134438],
                            [112.224327, 26.13891],
                            [112.223136, 26.149254],
                            [112.212574, 26.15119],
                            [112.211987, 26.154349],
                            [112.217215, 26.16547],
                            [112.215738, 26.167917],
                            [112.206125, 26.165537],
                            [112.195443, 26.172009],
                            [112.185935, 26.185664],
                            [112.186508, 26.189289],
                            [112.192866, 26.193914],
                            [112.194765, 26.197895],
                            [112.194885, 26.206878],
                            [112.191435, 26.213437],
                            [112.19029, 26.223686],
                            [112.19603, 26.229799],
                            [112.19817, 26.239335],
                            [112.198381, 26.255538],
                            [112.200837, 26.262384],
                            [112.197326, 26.277295],
                            [112.20501, 26.285272],
                            [112.209711, 26.294204],
                            [112.212484, 26.305623],
                            [112.208943, 26.313553],
                            [112.201907, 26.313087],
                            [112.198983, 26.307444],
                            [112.19041, 26.312221],
                            [112.184986, 26.310355],
                            [112.181204, 26.304357],
                            [112.177949, 26.305756],
                            [112.177422, 26.324438],
                            [112.181159, 26.334077],
                            [112.181475, 26.343361],
                            [112.177302, 26.348158],
                            [112.167658, 26.347247],
                            [112.167628, 26.351178],
                            [112.158754, 26.353021],
                            [112.153179, 26.349557],
                            [112.148191, 26.357351],
                            [112.149834, 26.362303],
                            [112.148704, 26.373915],
                            [112.152034, 26.379954],
                            [112.150135, 26.384616],
                            [112.141727, 26.38708],
                            [112.140612, 26.407946],
                            [112.143581, 26.407658],
                            [112.149623, 26.401731],
                            [112.153269, 26.40313],
                            [112.150617, 26.408701],
                            [112.152516, 26.411209],
                            [112.15012, 26.416314],
                            [112.154414, 26.418844],
                            [112.152516, 26.423171],
                            [112.152742, 26.430051],
                            [112.145585, 26.431693],
                            [112.146489, 26.435554],
                            [112.143219, 26.441479],
                            [112.148402, 26.444341],
                            [112.155273, 26.444519],
                            [112.161918, 26.450709],
                            [112.162596, 26.4565],
                            [112.157413, 26.458564],
                            [112.157955, 26.464997],
                            [112.152169, 26.4697],
                            [112.159085, 26.474803],
                            [112.166483, 26.477043],
                            [112.166182, 26.481058],
                            [112.159552, 26.486315],
                            [112.160019, 26.4939],
                            [112.171229, 26.496451],
                            [112.175689, 26.500177],
                            [112.191872, 26.488866],
                            [112.194569, 26.479616],
                            [112.195367, 26.486581],
                            [112.201756, 26.495276],
                            [112.211278, 26.50275],
                            [112.217998, 26.504346],
                            [112.227265, 26.502883],
                            [112.236682, 26.505389],
                            [112.243206, 26.51406],
                            [112.244366, 26.531266],
                            [112.242694, 26.535035],
                            [112.227822, 26.546453],
                            [112.222187, 26.548493],
                            [112.213132, 26.548537],
                            [112.190455, 26.536898],
                            [112.174198, 26.524127],
                            [112.161933, 26.511155],
                            [112.152455, 26.502705],
                            [112.144741, 26.504457],
                            [112.147619, 26.511243],
                            [112.148764, 26.520025],
                            [112.147348, 26.527453],
                            [112.15342, 26.532641],
                            [112.154339, 26.535811],
                            [112.160908, 26.534991],
                            [112.162746, 26.539337],
                            [112.15125, 26.544746],
                            [112.149126, 26.547163],
                            [112.154128, 26.55],
                            [112.156403, 26.55552],
                            [112.16026, 26.552993],
                            [112.166001, 26.553791],
                            [112.169497, 26.557072],
                            [112.168352, 26.560131],
                            [112.174318, 26.563145],
                            [112.172495, 26.565982],
                            [112.174695, 26.571191],
                            [112.168186, 26.574649],
                            [112.171787, 26.577619],
                            [112.170536, 26.585353],
                            [112.158573, 26.591824],
                            [112.149924, 26.591913],
                            [112.141592, 26.596079],
                            [112.141456, 26.603369],
                            [112.145841, 26.606693],
                            [112.14453, 26.613273],
                            [112.150301, 26.617018],
                            [112.151084, 26.625724],
                            [112.146881, 26.626433],
                            [112.142345, 26.630598],
                            [112.137569, 26.630509],
                            [112.133531, 26.636092],
                            [112.126329, 26.635737],
                            [112.115736, 26.643402],
                            [112.117816, 26.65049],
                            [112.111502, 26.650467],
                            [112.1098, 26.654033],
                            [112.102085, 26.652173],
                            [112.101241, 26.646325],
                            [112.09419, 26.645107],
                            [112.093452, 26.634032],
                            [112.085632, 26.636801],
                            [112.076878, 26.643955],
                            [112.056145, 26.647942],
                            [112.045824, 26.643224],
                            [112.028511, 26.651818],
                            [112.018883, 26.648563],
                            [112.006483, 26.648474],
                            [111.996147, 26.644066],
                            [111.995679, 26.639392],
                            [112.004795, 26.630022],
                            [112.005503, 26.624816],
                            [112.013986, 26.627585],
                            [112.013534, 26.623044],
                            [112.018085, 26.622423],
                            [112.019742, 26.619189],
                            [112.014936, 26.605341],
                            [112.010144, 26.597696],
                            [112.006031, 26.596899],
                            [111.997518, 26.60512],
                            [111.995664, 26.612454],
                            [111.991792, 26.615733],
                            [111.990195, 26.624151],
                            [111.980687, 26.627076],
                            [111.973561, 26.630908],
                            [111.969598, 26.637443],
                            [111.960964, 26.645284],
                            [111.959322, 26.656447],
                            [111.951909, 26.664243],
                            [111.951442, 26.673345],
                            [111.949016, 26.681494],
                            [111.943381, 26.689908],
                            [111.940337, 26.699428],
                            [111.928223, 26.709457],
                            [111.916395, 26.733673],
                            [111.917992, 26.748921],
                            [111.903317, 26.751776],
                            [111.898947, 26.754188],
                            [111.896732, 26.760295],
                            [111.888641, 26.767575],
                            [111.885914, 26.774478],
                            [111.874749, 26.785872],
                            [111.871509, 26.79649],
                            [111.867938, 26.800007],
                            [111.859169, 26.799565],
                            [111.850189, 26.789809],
                            [111.845066, 26.787465],
                            [111.831837, 26.786159],
                            [111.827528, 26.780894],
                            [111.817071, 26.783991],
                            [111.818427, 26.790871],
                            [111.822435, 26.791778],
                            [111.824032, 26.798459],
                            [111.820552, 26.804497],
                            [111.813605, 26.804121],
                            [111.802501, 26.801533],
                            [111.799171, 26.794256],
                            [111.792481, 26.790274],
                            [111.781271, 26.789168],
                            [111.779719, 26.793128],
                            [111.771718, 26.799874],
                            [111.772125, 26.808766],
                            [111.761487, 26.810115],
                            [111.753683, 26.807019],
                            [111.744702, 26.812172],
                            [111.743, 26.81821],
                            [111.744989, 26.825508],
                            [111.749901, 26.827256],
                            [111.752311, 26.831568],
                            [111.748183, 26.833116],
                            [111.750985, 26.838644],
                            [111.746736, 26.841518],
                            [111.739022, 26.832872],
                            [111.731262, 26.832629],
                            [111.726215, 26.835968],
                            [111.725039, 26.84112],
                            [111.719254, 26.847267],
                            [111.712684, 26.849169],
                            [111.70961, 26.846073],
                            [111.70057, 26.843177],
                            [111.701474, 26.836212],
                            [111.693699, 26.837118],
                            [111.689224, 26.833602],
                            [111.684614, 26.833536],
                            [111.665975, 26.843309],
                            [111.661154, 26.841098],
                            [111.650848, 26.840413],
                            [111.648768, 26.838268],
                            [111.647638, 26.830573],
                            [111.644158, 26.825287],
                            [111.642455, 26.817237],
                            [111.643043, 26.805891],
                            [111.644504, 26.803237],
                            [111.637995, 26.80233],
                            [111.63905, 26.797242],
                            [111.632857, 26.796335],
                            [111.629663, 26.789301],
                            [111.618498, 26.791292],
                            [111.612788, 26.790186],
                            [111.60325, 26.785429],
                            [111.592055, 26.792199]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 431122,
                "name": "东安县",
                "center": [111.313035, 26.397278],
                "centroid": [111.336206, 26.489712],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431100
                },
                "subFeatureIndex": 3,
                "acroutes": [100000, 430000, 431100]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [111.54952, 26.817436],
                            [111.545738, 26.825575],
                            [111.549128, 26.83232],
                            [111.542137, 26.842779],
                            [111.533654, 26.847113],
                            [111.532192, 26.859273],
                            [111.52826, 26.864977],
                            [111.5172, 26.865131],
                            [111.507949, 26.85956],
                            [111.495247, 26.86071],
                            [111.48253, 26.856752],
                            [111.476805, 26.858057],
                            [111.471471, 26.86197],
                            [111.462506, 26.861064],
                            [111.46011, 26.867121],
                            [111.44551, 26.867585],
                            [111.440281, 26.865352],
                            [111.438172, 26.860842],
                            [111.433893, 26.862434],
                            [111.43002, 26.867895],
                            [111.419624, 26.867784],
                            [111.414637, 26.860268],
                            [111.40641, 26.858212],
                            [111.396722, 26.862655],
                            [111.392427, 26.863009],
                            [111.375552, 26.858145],
                            [111.3729, 26.858189],
                            [111.366376, 26.864932],
                            [111.353011, 26.862368],
                            [111.349651, 26.859825],
                            [111.349711, 26.851557],
                            [111.329868, 26.836676],
                            [111.321626, 26.818542],
                            [111.322515, 26.813079],
                            [111.315147, 26.802573],
                            [111.324097, 26.788349],
                            [111.321686, 26.780761],
                            [111.324037, 26.775894],
                            [111.32922, 26.772133],
                            [111.326688, 26.764831],
                            [111.329386, 26.757618],
                            [111.323976, 26.737723],
                            [111.317211, 26.733186],
                            [111.316729, 26.724664],
                            [111.321972, 26.719861],
                            [111.321445, 26.714238],
                            [111.313731, 26.710475],
                            [111.309075, 26.71094],
                            [111.302957, 26.700004],
                            [111.276243, 26.688845],
                            [111.281125, 26.678704],
                            [111.285298, 26.67536],
                            [111.293721, 26.672813],
                            [111.296945, 26.668296],
                            [111.299356, 26.660678],
                            [111.301255, 26.644199],
                            [111.31254, 26.639016],
                            [111.311485, 26.634563],
                            [111.314258, 26.626633],
                            [111.306061, 26.619765],
                            [111.303892, 26.604034],
                            [111.306649, 26.596123],
                            [111.304359, 26.591403],
                            [111.3046, 26.583359],
                            [111.297714, 26.576932],
                            [111.297006, 26.567756],
                            [111.291416, 26.564697],
                            [111.288478, 26.557049],
                            [111.293766, 26.532619],
                            [111.296056, 26.526211],
                            [111.296945, 26.511753],
                            [111.295529, 26.506808],
                            [111.29128, 26.503992],
                            [111.288764, 26.492326],
                            [111.28352, 26.489864],
                            [111.278292, 26.490219],
                            [111.271873, 26.499423],
                            [111.267172, 26.499179],
                            [111.263406, 26.493435],
                            [111.259714, 26.479905],
                            [111.251367, 26.483831],
                            [111.244496, 26.482078],
                            [111.234325, 26.482655],
                            [111.22964, 26.481102],
                            [111.222061, 26.474026],
                            [111.212915, 26.469656],
                            [111.208681, 26.464509],
                            [111.203347, 26.462868],
                            [111.201177, 26.457321],
                            [111.185522, 26.46076],
                            [111.181062, 26.463378],
                            [111.184648, 26.470499],
                            [111.173875, 26.47498],
                            [111.168014, 26.474159],
                            [111.163268, 26.479039],
                            [111.155523, 26.482433],
                            [111.155523, 26.488178],
                            [111.158868, 26.494411],
                            [111.154408, 26.500399],
                            [111.162394, 26.503903],
                            [111.165724, 26.507185],
                            [111.165965, 26.511731],
                            [111.159215, 26.515745],
                            [111.154001, 26.527031],
                            [111.142264, 26.54131],
                            [111.12584, 26.553924],
                            [111.125132, 26.558845],
                            [111.127121, 26.577486],
                            [111.133178, 26.59076],
                            [111.133886, 26.600754],
                            [111.137111, 26.610615],
                            [111.133299, 26.620186],
                            [111.127423, 26.623088],
                            [111.120974, 26.622202],
                            [111.109221, 26.617461],
                            [111.113862, 26.609241],
                            [111.112852, 26.602305],
                            [111.108212, 26.588943],
                            [111.104746, 26.585907],
                            [111.088699, 26.588877],
                            [111.081121, 26.58706],
                            [111.073587, 26.587813],
                            [111.069639, 26.584378],
                            [111.067108, 26.577264],
                            [111.063929, 26.558379],
                            [111.066581, 26.54131],
                            [111.066219, 26.531466],
                            [111.063281, 26.526544],
                            [111.06652, 26.521555],
                            [111.067515, 26.507141],
                            [111.062512, 26.502506],
                            [111.061925, 26.492925],
                            [111.05644, 26.486603],
                            [111.060162, 26.480326],
                            [111.060267, 26.47376],
                            [111.052673, 26.457033],
                            [111.043708, 26.451198],
                            [111.042714, 26.446804],
                            [111.044944, 26.440947],
                            [111.031745, 26.432492],
                            [111.029153, 26.424503],
                            [111.025552, 26.419199],
                            [111.023081, 26.40728],
                            [111.019194, 26.401021],
                            [111.019013, 26.391032],
                            [111.025085, 26.384594],
                            [111.024256, 26.38102],
                            [111.019133, 26.377401],
                            [111.016527, 26.365989],
                            [111.008571, 26.358861],
                            [111.001791, 26.34547],
                            [111.00262, 26.34014],
                            [111.00827, 26.337031],
                            [111.018184, 26.338075],
                            [111.027677, 26.336898],
                            [111.034095, 26.331967],
                            [111.043181, 26.332478],
                            [111.040348, 26.323505],
                            [111.046179, 26.32295],
                            [111.053487, 26.319507],
                            [111.061171, 26.325348],
                            [111.064818, 26.318885],
                            [111.068705, 26.319573],
                            [111.071914, 26.316219],
                            [111.079358, 26.31824],
                            [111.092587, 26.306711],
                            [111.106856, 26.304068],
                            [111.11109, 26.308489],
                            [111.128432, 26.309311],
                            [111.13583, 26.306511],
                            [111.157828, 26.307467],
                            [111.168933, 26.31051],
                            [111.177793, 26.307111],
                            [111.189515, 26.306511],
                            [111.204748, 26.307844],
                            [111.209223, 26.30269],
                            [111.208847, 26.29096],
                            [111.203332, 26.28065],
                            [111.204507, 26.276606],
                            [111.212839, 26.269117],
                            [111.219047, 26.266984],
                            [111.222046, 26.270628],
                            [111.225451, 26.269451],
                            [111.227862, 26.261339],
                            [111.240548, 26.267095],
                            [111.247826, 26.26365],
                            [111.252467, 26.269228],
                            [111.267865, 26.27305],
                            [111.279859, 26.271762],
                            [111.282677, 26.266228],
                            [111.292621, 26.252449],
                            [111.291913, 26.244603],
                            [111.29568, 26.237579],
                            [111.291446, 26.220818],
                            [111.278036, 26.217394],
                            [111.271677, 26.217216],
                            [111.26892, 26.214615],
                            [111.270683, 26.200785],
                            [111.265922, 26.196338],
                            [111.266509, 26.191357],
                            [111.273154, 26.185731],
                            [111.27439, 26.182551],
                            [111.270623, 26.180238],
                            [111.26892, 26.168806],
                            [111.261567, 26.159442],
                            [111.258162, 26.152035],
                            [111.258915, 26.14594],
                            [111.262381, 26.135884],
                            [111.268619, 26.129299],
                            [111.270819, 26.119197],
                            [111.276695, 26.120733],
                            [111.282225, 26.116616],
                            [111.287528, 26.116282],
                            [111.294474, 26.122513],
                            [111.29565, 26.130144],
                            [111.291762, 26.143893],
                            [111.286941, 26.148943],
                            [111.287167, 26.152347],
                            [111.295876, 26.16051],
                            [111.302114, 26.160621],
                            [111.310717, 26.153125],
                            [111.314544, 26.153147],
                            [111.319788, 26.161155],
                            [111.314017, 26.181839],
                            [111.320496, 26.185887],
                            [111.32815, 26.186665],
                            [111.333378, 26.182751],
                            [111.338336, 26.183641],
                            [111.341334, 26.189956],
                            [111.344227, 26.190557],
                            [111.349983, 26.186687],
                            [111.354397, 26.187221],
                            [111.367114, 26.193292],
                            [111.372358, 26.20292],
                            [111.382769, 26.20919],
                            [111.386596, 26.208479],
                            [111.389655, 26.203609],
                            [111.395049, 26.201275],
                            [111.402869, 26.200808],
                            [111.405807, 26.202453],
                            [111.413808, 26.212592],
                            [111.415149, 26.222196],
                            [111.425606, 26.228932],
                            [111.430066, 26.239424],
                            [111.433471, 26.243936],
                            [111.450889, 26.253983],
                            [111.458211, 26.260317],
                            [111.465836, 26.262873],
                            [111.483585, 26.273717],
                            [111.49026, 26.27885],
                            [111.48815, 26.28525],
                            [111.488904, 26.291249],
                            [111.49371, 26.298069],
                            [111.501153, 26.29958],
                            [111.517863, 26.283339],
                            [111.528847, 26.280028],
                            [111.533157, 26.283827],
                            [111.530851, 26.29407],
                            [111.536682, 26.296203],
                            [111.542046, 26.300691],
                            [111.540464, 26.307733],
                            [111.535552, 26.310799],
                            [111.535613, 26.320284],
                            [111.539651, 26.325482],
                            [111.534498, 26.328613],
                            [111.523664, 26.345781],
                            [111.524312, 26.360415],
                            [111.522775, 26.365589],
                            [111.52377, 26.373449],
                            [111.517697, 26.385171],
                            [111.499376, 26.390344],
                            [111.494916, 26.401243],
                            [111.495157, 26.416158],
                            [111.496332, 26.423282],
                            [111.494403, 26.430916],
                            [111.506864, 26.42883],
                            [111.513599, 26.432337],
                            [111.51717, 26.436819],
                            [111.522233, 26.438017],
                            [111.527567, 26.444208],
                            [111.524252, 26.447359],
                            [111.518722, 26.442699],
                            [111.513011, 26.447625],
                            [111.518541, 26.45295],
                            [111.522534, 26.459673],
                            [111.523589, 26.465663],
                            [111.533217, 26.468746],
                            [111.533277, 26.474226],
                            [111.545753, 26.479173],
                            [111.553859, 26.477265],
                            [111.558877, 26.478729],
                            [111.557475, 26.48332],
                            [111.562478, 26.494122],
                            [111.558696, 26.504568],
                            [111.563623, 26.508072],
                            [111.566636, 26.513416],
                            [111.576731, 26.516809],
                            [111.573597, 26.528384],
                            [111.575179, 26.53508],
                            [111.574004, 26.539736],
                            [111.576385, 26.544746],
                            [111.572543, 26.557005],
                            [111.56638, 26.564652],
                            [111.558093, 26.560507],
                            [111.544111, 26.55818],
                            [111.541986, 26.558823],
                            [111.536381, 26.569396],
                            [111.532931, 26.571479],
                            [111.529827, 26.582406],
                            [111.520696, 26.592755],
                            [111.517878, 26.603967],
                            [111.512032, 26.611612],
                            [111.512544, 26.61848],
                            [111.518059, 26.620141],
                            [111.529239, 26.628804],
                            [111.53498, 26.629202],
                            [111.542709, 26.626057],
                            [111.548103, 26.628449],
                            [111.542182, 26.635937],
                            [111.53721, 26.636402],
                            [111.530188, 26.640013],
                            [111.518647, 26.656935],
                            [111.507994, 26.66112],
                            [111.49588, 26.654055],
                            [111.479773, 26.652195],
                            [111.477362, 26.653192],
                            [111.476759, 26.660988],
                            [111.469407, 26.668739],
                            [111.473354, 26.67226],
                            [111.481973, 26.674563],
                            [111.49362, 26.676046],
                            [111.496106, 26.678194],
                            [111.49582, 26.689221],
                            [111.491043, 26.693495],
                            [111.481792, 26.695886],
                            [111.478718, 26.704586],
                            [111.485167, 26.70908],
                            [111.48464, 26.713287],
                            [111.476172, 26.717138],
                            [111.471863, 26.720835],
                            [111.469994, 26.725705],
                            [111.471847, 26.73458],
                            [111.481626, 26.741419],
                            [111.482289, 26.744783],
                            [111.477965, 26.748788],
                            [111.480074, 26.757064],
                            [111.471637, 26.758127],
                            [111.469678, 26.766115],
                            [111.469617, 26.780164],
                            [111.473128, 26.77939],
                            [111.476654, 26.77304],
                            [111.484082, 26.770783],
                            [111.491812, 26.775695],
                            [111.502645, 26.775452],
                            [111.507738, 26.773328],
                            [111.515528, 26.765672],
                            [111.520741, 26.76596],
                            [111.524478, 26.775983],
                            [111.529631, 26.779014],
                            [111.534965, 26.788372],
                            [111.532087, 26.797773],
                            [111.528636, 26.80306],
                            [111.529224, 26.80724],
                            [111.538415, 26.81142],
                            [111.542574, 26.817215],
                            [111.54952, 26.817436]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 431123,
                "name": "双牌县",
                "center": [111.662146, 25.959397],
                "centroid": [111.709161, 25.910052],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431100
                },
                "subFeatureIndex": 4,
                "acroutes": [100000, 430000, 431100]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [111.456886, 25.848089],
                            [111.458618, 25.843896],
                            [111.468759, 25.838923],
                            [111.478025, 25.836469],
                            [111.495518, 25.823956],
                            [111.500671, 25.816906],
                            [111.514609, 25.81124],
                            [111.520304, 25.811686],
                            [111.529058, 25.815813],
                            [111.534377, 25.821078],
                            [111.538807, 25.818959],
                            [111.53498, 25.810972],
                            [111.537918, 25.805239],
                            [111.544231, 25.80158],
                            [111.545888, 25.791852],
                            [111.550514, 25.787255],
                            [111.560398, 25.78547],
                            [111.570026, 25.788058],
                            [111.574351, 25.783908],
                            [111.568957, 25.777437],
                            [111.574727, 25.772282],
                            [111.578931, 25.766167],
                            [111.568942, 25.748088],
                            [111.570991, 25.730743],
                            [111.578133, 25.721188],
                            [111.587279, 25.719826],
                            [111.58526, 25.711587],
                            [111.588213, 25.702075],
                            [111.586917, 25.69618],
                            [111.58984, 25.687582],
                            [111.595068, 25.684612],
                            [111.600673, 25.685929],
                            [111.604049, 25.695666],
                            [111.608132, 25.692852],
                            [111.605164, 25.687537],
                            [111.606384, 25.681373],
                            [111.610362, 25.677308],
                            [111.617172, 25.676214],
                            [111.623079, 25.671635],
                            [111.632149, 25.667593],
                            [111.641084, 25.652738],
                            [111.649507, 25.648739],
                            [111.657538, 25.646639],
                            [111.65796, 25.638462],
                            [111.667633, 25.636228],
                            [111.668612, 25.632229],
                            [111.664152, 25.627135],
                            [111.665147, 25.624029],
                            [111.673057, 25.61842],
                            [111.677306, 25.605839],
                            [111.688456, 25.600699],
                            [111.694392, 25.604074],
                            [111.695251, 25.609593],
                            [111.686437, 25.62032],
                            [111.686964, 25.624096],
                            [111.694528, 25.629391],
                            [111.70283, 25.638529],
                            [111.706974, 25.639714],
                            [111.725974, 25.637144],
                            [111.73643, 25.64684],
                            [111.743859, 25.656714],
                            [111.740649, 25.662947],
                            [111.751151, 25.67302],
                            [111.755641, 25.674852],
                            [111.758775, 25.679319],
                            [111.762211, 25.691512],
                            [111.761186, 25.697609],
                            [111.765239, 25.708461],
                            [111.770965, 25.710895],
                            [111.77541, 25.716008],
                            [111.784314, 25.722438],
                            [111.78329, 25.729314],
                            [111.788774, 25.72927],
                            [111.790191, 25.735944],
                            [111.787207, 25.745632],
                            [111.791306, 25.745789],
                            [111.795464, 25.739695],
                            [111.805725, 25.743847],
                            [111.806825, 25.750699],
                            [111.816965, 25.754628],
                            [111.822269, 25.760118],
                            [111.824424, 25.770385],
                            [111.826835, 25.775495],
                            [111.829517, 25.788192],
                            [111.835559, 25.809433],
                            [111.850204, 25.818691],
                            [111.853775, 25.822617],
                            [111.864699, 25.840618],
                            [111.882539, 25.849383],
                            [111.883352, 25.851256],
                            [111.878018, 25.863745],
                            [111.874899, 25.895383],
                            [111.876075, 25.908647],
                            [111.875035, 25.92104],
                            [111.876316, 25.928418],
                            [111.882795, 25.931694],
                            [111.89292, 25.930669],
                            [111.89961, 25.931649],
                            [111.9052, 25.935014],
                            [111.907098, 25.950992],
                            [111.911694, 25.972805],
                            [111.920267, 25.989536],
                            [111.923341, 26.0027],
                            [111.926264, 26.007911],
                            [111.924682, 26.015506],
                            [111.928991, 26.019938],
                            [111.936947, 26.017666],
                            [111.952059, 26.026641],
                            [111.960226, 26.019225],
                            [111.96559, 26.019426],
                            [111.97121, 26.022521],
                            [111.977704, 26.029937],
                            [111.979618, 26.035014],
                            [111.977297, 26.044321],
                            [111.9779, 26.057746],
                            [111.979648, 26.066962],
                            [111.978533, 26.076178],
                            [111.979603, 26.08891],
                            [111.985509, 26.099259],
                            [111.983686, 26.106469],
                            [111.968619, 26.117929],
                            [111.960693, 26.121734],
                            [111.951457, 26.116549],
                            [111.943516, 26.109963],
                            [111.93422, 26.109785],
                            [111.929112, 26.106936],
                            [111.925631, 26.109317],
                            [111.915355, 26.112366],
                            [111.910835, 26.108383],
                            [111.905607, 26.109139],
                            [111.901569, 26.11379],
                            [111.892167, 26.117061],
                            [111.869716, 26.133014],
                            [111.861625, 26.136151],
                            [111.856819, 26.140645],
                            [111.848727, 26.154927],
                            [111.845217, 26.164692],
                            [111.82123, 26.165248],
                            [111.814479, 26.163846],
                            [111.81014, 26.157841],
                            [111.802561, 26.158508],
                            [111.795494, 26.155461],
                            [111.780005, 26.155995],
                            [111.780065, 26.152436],
                            [111.785354, 26.148543],
                            [111.788744, 26.13693],
                            [111.783004, 26.135328],
                            [111.782717, 26.131279],
                            [111.791924, 26.127163],
                            [111.789332, 26.122757],
                            [111.783712, 26.122357],
                            [111.775726, 26.111721],
                            [111.769277, 26.108138],
                            [111.752839, 26.11043],
                            [111.746782, 26.108494],
                            [111.744265, 26.101996],
                            [111.738495, 26.094852],
                            [111.745079, 26.090156],
                            [111.738434, 26.077825],
                            [111.734502, 26.076423],
                            [111.726742, 26.078003],
                            [111.718636, 26.091536],
                            [111.715276, 26.091714],
                            [111.692871, 26.08201],
                            [111.685277, 26.074954],
                            [111.674142, 26.069723],
                            [111.661998, 26.05828],
                            [111.651511, 26.056922],
                            [111.644354, 26.061642],
                            [111.642244, 26.066985],
                            [111.643977, 26.075154],
                            [111.654072, 26.079116],
                            [111.655925, 26.081921],
                            [111.650803, 26.092048],
                            [111.64583, 26.088286],
                            [111.638538, 26.08566],
                            [111.629753, 26.087775],
                            [111.623711, 26.086128],
                            [111.610482, 26.084814],
                            [111.597796, 26.065426],
                            [111.593984, 26.031673],
                            [111.589403, 26.032475],
                            [111.5877, 26.037418],
                            [111.58309, 26.037552],
                            [111.578991, 26.032876],
                            [111.571443, 26.029937],
                            [111.565883, 26.021964],
                            [111.560926, 26.021519],
                            [111.552488, 26.015016],
                            [111.550228, 26.005217],
                            [111.557114, 25.993701],
                            [111.564934, 25.98958],
                            [111.580182, 25.993924],
                            [111.584823, 25.992676],
                            [111.588047, 25.986283],
                            [111.585531, 25.975791],
                            [111.590397, 25.971201],
                            [111.600206, 25.966946],
                            [111.602903, 25.962267],
                            [111.609126, 25.959081],
                            [111.611763, 25.952463],
                            [111.606836, 25.942569],
                            [111.600779, 25.933833],
                            [111.591271, 25.93049],
                            [111.584928, 25.934078],
                            [111.580001, 25.943572],
                            [111.574667, 25.950146],
                            [111.565868, 25.950725],
                            [111.553618, 25.943951],
                            [111.543598, 25.936463],
                            [111.538686, 25.936351],
                            [111.535823, 25.950502],
                            [111.537044, 25.955716],
                            [111.529435, 25.955738],
                            [111.522459, 25.957632],
                            [111.513569, 25.953867],
                            [111.507587, 25.957365],
                            [111.496829, 25.974588],
                            [111.492791, 25.977929],
                            [111.477151, 25.983254],
                            [111.461918, 25.976437],
                            [111.448192, 25.991273],
                            [111.443717, 25.991206],
                            [111.43665, 25.983766],
                            [111.43222, 25.974899],
                            [111.422788, 25.974142],
                            [111.420709, 25.971491],
                            [111.420679, 25.964651],
                            [111.423587, 25.955894],
                            [111.418072, 25.950368],
                            [111.414607, 25.943416],
                            [111.408293, 25.940118],
                            [111.406199, 25.936418],
                            [111.409197, 25.92347],
                            [111.40861, 25.918366],
                            [111.403261, 25.911857],
                            [111.402327, 25.907822],
                            [111.405762, 25.897702],
                            [111.402764, 25.885351],
                            [111.4087, 25.885083],
                            [111.415857, 25.876878],
                            [111.419745, 25.878149],
                            [111.425139, 25.884147],
                            [111.43219, 25.886956],
                            [111.440628, 25.884949],
                            [111.448146, 25.885507],
                            [111.454234, 25.888427],
                            [111.460095, 25.884793],
                            [111.455288, 25.879509],
                            [111.454821, 25.87282],
                            [111.45681, 25.871059],
                            [111.470989, 25.875251],
                            [111.492068, 25.868561],
                            [111.486207, 25.859307],
                            [111.476142, 25.856051],
                            [111.468638, 25.857255],
                            [111.465127, 25.85585],
                            [111.456886, 25.848089]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 431124,
                "name": "道县",
                "center": [111.591614, 25.518444],
                "centroid": [111.596062, 25.493276],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431100
                },
                "subFeatureIndex": 5,
                "acroutes": [100000, 430000, 431100]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [111.73643, 25.64684],
                            [111.725974, 25.637144],
                            [111.706974, 25.639714],
                            [111.70283, 25.638529],
                            [111.694528, 25.629391],
                            [111.686964, 25.624096],
                            [111.686437, 25.62032],
                            [111.695251, 25.609593],
                            [111.694392, 25.604074],
                            [111.688456, 25.600699],
                            [111.677306, 25.605839],
                            [111.673057, 25.61842],
                            [111.665147, 25.624029],
                            [111.664152, 25.627135],
                            [111.668612, 25.632229],
                            [111.667633, 25.636228],
                            [111.65796, 25.638462],
                            [111.657538, 25.646639],
                            [111.649507, 25.648739],
                            [111.641084, 25.652738],
                            [111.632149, 25.667593],
                            [111.623079, 25.671635],
                            [111.617172, 25.676214],
                            [111.610362, 25.677308],
                            [111.606384, 25.681373],
                            [111.605164, 25.687537],
                            [111.608132, 25.692852],
                            [111.604049, 25.695666],
                            [111.600673, 25.685929],
                            [111.595068, 25.684612],
                            [111.58984, 25.687582],
                            [111.586917, 25.69618],
                            [111.588213, 25.702075],
                            [111.58526, 25.711587],
                            [111.587279, 25.719826],
                            [111.578133, 25.721188],
                            [111.570991, 25.730743],
                            [111.568942, 25.748088],
                            [111.578931, 25.766167],
                            [111.574727, 25.772282],
                            [111.568957, 25.777437],
                            [111.574351, 25.783908],
                            [111.570026, 25.788058],
                            [111.560398, 25.78547],
                            [111.550514, 25.787255],
                            [111.545888, 25.791852],
                            [111.544231, 25.80158],
                            [111.537918, 25.805239],
                            [111.53498, 25.810972],
                            [111.538807, 25.818959],
                            [111.534377, 25.821078],
                            [111.529058, 25.815813],
                            [111.520304, 25.811686],
                            [111.514609, 25.81124],
                            [111.500671, 25.816906],
                            [111.495518, 25.823956],
                            [111.478025, 25.836469],
                            [111.468759, 25.838923],
                            [111.458618, 25.843896],
                            [111.456886, 25.848089],
                            [111.43326, 25.846283],
                            [111.42758, 25.835778],
                            [111.425063, 25.826253],
                            [111.427128, 25.821948],
                            [111.43326, 25.818557],
                            [111.436078, 25.813582],
                            [111.43888, 25.793927],
                            [111.442285, 25.778084],
                            [111.440221, 25.769603],
                            [111.432853, 25.766613],
                            [111.424204, 25.769135],
                            [111.416083, 25.768197],
                            [111.410749, 25.76177],
                            [111.399599, 25.744606],
                            [111.391358, 25.740833],
                            [111.37617, 25.737864],
                            [111.370173, 25.733578],
                            [111.362835, 25.734806],
                            [111.346803, 25.729649],
                            [111.337974, 25.72927],
                            [111.327291, 25.732707],
                            [111.318688, 25.729515],
                            [111.315041, 25.723219],
                            [111.308743, 25.720027],
                            [111.307553, 25.716343],
                            [111.308743, 25.692339],
                            [111.313926, 25.682646],
                            [111.313444, 25.672551],
                            [111.309783, 25.658077],
                            [111.310009, 25.644941],
                            [111.317648, 25.631268],
                            [111.32818, 25.622129],
                            [111.334704, 25.618353],
                            [111.342419, 25.607806],
                            [111.343157, 25.602777],
                            [111.336844, 25.595804],
                            [111.324911, 25.564131],
                            [111.323871, 25.548861],
                            [111.327728, 25.53739],
                            [111.32812, 25.521446],
                            [111.321475, 25.496351],
                            [111.317301, 25.494383],
                            [111.305022, 25.477136],
                            [111.311696, 25.473824],
                            [111.322455, 25.463779],
                            [111.339074, 25.450868],
                            [111.344709, 25.443148],
                            [111.352574, 25.435987],
                            [111.355467, 25.430459],
                            [111.361313, 25.41027],
                            [111.368892, 25.405659],
                            [111.370143, 25.401808],
                            [111.369103, 25.393883],
                            [111.374814, 25.39301],
                            [111.385105, 25.394645],
                            [111.39074, 25.401674],
                            [111.406591, 25.403196],
                            [111.420362, 25.392092],
                            [111.428348, 25.391958],
                            [111.435746, 25.394667],
                            [111.439739, 25.385533],
                            [111.44786, 25.374875],
                            [111.456208, 25.369456],
                            [111.456283, 25.364575],
                            [111.460939, 25.362156],
                            [111.462792, 25.354901],
                            [111.472375, 25.349996],
                            [111.47575, 25.338216],
                            [111.472495, 25.335573],
                            [111.470627, 25.341306],
                            [111.464856, 25.34171],
                            [111.463395, 25.323163],
                            [111.464058, 25.314584],
                            [111.468533, 25.306003],
                            [111.476368, 25.299081],
                            [111.476157, 25.292],
                            [111.472812, 25.288998],
                            [111.472767, 25.278041],
                            [111.468503, 25.275867],
                            [111.468111, 25.268606],
                            [111.47007, 25.265244],
                            [111.475479, 25.262331],
                            [111.48253, 25.265155],
                            [111.487909, 25.271542],
                            [111.488874, 25.279632],
                            [111.493364, 25.293927],
                            [111.499873, 25.301478],
                            [111.508672, 25.305309],
                            [111.513991, 25.304928],
                            [111.518044, 25.299663],
                            [111.523513, 25.299282],
                            [111.533548, 25.305488],
                            [111.538716, 25.312948],
                            [111.546687, 25.318706],
                            [111.554507, 25.319871],
                            [111.563819, 25.310977],
                            [111.565069, 25.30439],
                            [111.570674, 25.306228],
                            [111.57423, 25.299349],
                            [111.594888, 25.296168],
                            [111.59882, 25.298991],
                            [111.595385, 25.302777],
                            [111.59323, 25.310686],
                            [111.596349, 25.318459],
                            [111.600116, 25.322379],
                            [111.614626, 25.325426],
                            [111.626559, 25.319288],
                            [111.628262, 25.313732],
                            [111.623742, 25.305824],
                            [111.62573, 25.301814],
                            [111.634334, 25.298565],
                            [111.647232, 25.298789],
                            [111.658653, 25.295249],
                            [111.662947, 25.287407],
                            [111.668808, 25.281312],
                            [111.679777, 25.273491],
                            [111.683017, 25.267463],
                            [111.689676, 25.264662],
                            [111.698129, 25.255809],
                            [111.703132, 25.233618],
                            [111.707817, 25.227857],
                            [111.717988, 25.220638],
                            [111.731307, 25.218755],
                            [111.737576, 25.214047],
                            [111.740589, 25.19871],
                            [111.74413, 25.194831],
                            [111.770814, 25.184829],
                            [111.781165, 25.177114],
                            [111.785414, 25.172517],
                            [111.799713, 25.152374],
                            [111.806418, 25.150602],
                            [111.812581, 25.155762],
                            [111.839371, 25.17375],
                            [111.844343, 25.175993],
                            [111.866311, 25.175634],
                            [111.877099, 25.188798],
                            [111.889334, 25.193351],
                            [111.900619, 25.195728],
                            [111.906963, 25.20297],
                            [111.925285, 25.218105],
                            [111.924697, 25.225929],
                            [111.918444, 25.234717],
                            [111.924803, 25.241554],
                            [111.921563, 25.247135],
                            [111.916802, 25.249264],
                            [111.909509, 25.257916],
                            [111.90755, 25.262622],
                            [111.908665, 25.267844],
                            [111.914979, 25.278063],
                            [111.915747, 25.28194],
                            [111.913397, 25.288259],
                            [111.904929, 25.297714],
                            [111.902473, 25.306138],
                            [111.895316, 25.301724],
                            [111.887526, 25.29917],
                            [111.882418, 25.301993],
                            [111.865241, 25.342762],
                            [111.854785, 25.360163],
                            [111.843424, 25.407942],
                            [111.83699, 25.432227],
                            [111.83021, 25.451472],
                            [111.825373, 25.454739],
                            [111.813395, 25.453822],
                            [111.800572, 25.440776],
                            [111.785655, 25.43404],
                            [111.773632, 25.432898],
                            [111.765812, 25.433323],
                            [111.761186, 25.436345],
                            [111.760448, 25.443976],
                            [111.761668, 25.450577],
                            [111.76991, 25.470558],
                            [111.779613, 25.482438],
                            [111.790733, 25.489149],
                            [111.796745, 25.495233],
                            [111.799005, 25.503666],
                            [111.797257, 25.505388],
                            [111.789935, 25.503621],
                            [111.787976, 25.509078],
                            [111.789362, 25.516906],
                            [111.792677, 25.517577],
                            [111.801265, 25.514804],
                            [111.811692, 25.515385],
                            [111.817659, 25.52196],
                            [111.827076, 25.523146],
                            [111.830044, 25.525717],
                            [111.831882, 25.537569],
                            [111.84145, 25.547586],
                            [111.848953, 25.564153],
                            [111.8504, 25.570278],
                            [111.849556, 25.576291],
                            [111.840757, 25.584852],
                            [111.838361, 25.590507],
                            [111.838, 25.599961],
                            [111.83253, 25.60166],
                            [111.826789, 25.606576],
                            [111.830993, 25.61947],
                            [111.818804, 25.6249],
                            [111.814826, 25.630441],
                            [111.813831, 25.640741],
                            [111.803706, 25.640607],
                            [111.798041, 25.643311],
                            [111.7934, 25.642015],
                            [111.794816, 25.633056],
                            [111.78769, 25.622487],
                            [111.781874, 25.619292],
                            [111.77324, 25.609884],
                            [111.770859, 25.604163],
                            [111.7642, 25.598576],
                            [111.765601, 25.591513],
                            [111.754315, 25.587132],
                            [111.749916, 25.592564],
                            [111.752628, 25.603783],
                            [111.750805, 25.609169],
                            [111.754285, 25.617526],
                            [111.753381, 25.620163],
                            [111.744446, 25.625101],
                            [111.733251, 25.626531],
                            [111.73298, 25.630263],
                            [111.736837, 25.642082],
                            [111.73643, 25.64684]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 431125,
                "name": "江永县",
                "center": [111.346803, 25.268154],
                "centroid": [111.24556, 25.193638],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431100
                },
                "subFeatureIndex": 6,
                "acroutes": [100000, 430000, 431100]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [111.305022, 25.477136],
                            [111.300863, 25.469753],
                            [111.30124, 25.451092],
                            [111.292109, 25.435808],
                            [111.288628, 25.432339],
                            [111.280838, 25.430101],
                            [111.279, 25.423297],
                            [111.265877, 25.425423],
                            [111.256911, 25.407628],
                            [111.257499, 25.395943],
                            [111.25105, 25.394107],
                            [111.242176, 25.387861],
                            [111.236706, 25.378166],
                            [111.227967, 25.375435],
                            [111.210519, 25.3635],
                            [111.185161, 25.367239],
                            [111.181846, 25.36659],
                            [111.178004, 25.360387],
                            [111.169867, 25.351228],
                            [111.169717, 25.346278],
                            [111.16387, 25.342404],
                            [111.155463, 25.324351],
                            [111.138452, 25.303561],
                            [111.134384, 25.300089],
                            [111.122842, 25.294107],
                            [111.119512, 25.290858],
                            [111.111677, 25.290858],
                            [111.1033, 25.285077],
                            [111.10345, 25.281245],
                            [111.109523, 25.266298],
                            [111.110999, 25.257849],
                            [111.109809, 25.252738],
                            [111.11781, 25.23929],
                            [111.117358, 25.232923],
                            [111.113214, 25.226557],
                            [111.112777, 25.217073],
                            [111.102848, 25.21102],
                            [111.092632, 25.2088],
                            [111.086846, 25.204361],
                            [111.077881, 25.201042],
                            [111.065496, 25.187498],
                            [111.054949, 25.1877],
                            [111.049313, 25.184201],
                            [111.047234, 25.179021],
                            [111.034608, 25.179133],
                            [111.014478, 25.169646],
                            [111.002725, 25.165765],
                            [110.998943, 25.161616],
                            [111.001324, 25.153631],
                            [111.00051, 25.149997],
                            [110.994483, 25.146273],
                            [110.982821, 25.126665],
                            [110.982821, 25.117645],
                            [110.977623, 25.108961],
                            [110.983047, 25.104989],
                            [110.983936, 25.10169],
                            [110.98029, 25.096506],
                            [110.96685, 25.08396],
                            [110.961425, 25.077855],
                            [110.966699, 25.073119],
                            [110.956152, 25.058775],
                            [110.952159, 25.047819],
                            [110.951933, 25.041174],
                            [110.956001, 25.03199],
                            [110.957493, 25.024895],
                            [110.955413, 25.0169],
                            [110.960747, 25.001516],
                            [110.975453, 24.987793],
                            [110.975167, 24.980829],
                            [110.968778, 24.975528],
                            [110.969004, 24.971821],
                            [110.973449, 24.968294],
                            [110.985563, 24.966025],
                            [110.992103, 24.959015],
                            [110.987266, 24.942523],
                            [110.988155, 24.934164],
                            [110.99492, 24.930344],
                            [110.991274, 24.924051],
                            [110.983725, 24.924186],
                            [110.97913, 24.914702],
                            [110.981796, 24.914455],
                            [110.989692, 24.919714],
                            [110.997225, 24.92704],
                            [111.003448, 24.921467],
                            [111.009325, 24.92095],
                            [111.014538, 24.923782],
                            [111.018546, 24.929782],
                            [111.030359, 24.931962],
                            [111.038224, 24.92922],
                            [111.050263, 24.928276],
                            [111.06206, 24.933917],
                            [111.077565, 24.937355],
                            [111.086093, 24.94113],
                            [111.097423, 24.940883],
                            [111.100422, 24.945714],
                            [111.099096, 24.966519],
                            [111.096956, 24.974876],
                            [111.102064, 24.982312],
                            [111.10791, 25.009736],
                            [111.101838, 25.035179],
                            [111.103917, 25.037693],
                            [111.123008, 25.042588],
                            [111.140019, 25.042408],
                            [111.14933, 25.045619],
                            [111.152841, 25.050671],
                            [111.167185, 25.060593],
                            [111.194638, 25.072805],
                            [111.20077, 25.074645],
                            [111.206692, 25.084993],
                            [111.219318, 25.093544],
                            [111.222061, 25.106403],
                            [111.230875, 25.115334],
                            [111.249513, 25.128661],
                            [111.257966, 25.128863],
                            [111.263737, 25.13362],
                            [111.263225, 25.143827],
                            [111.274781, 25.151163],
                            [111.280191, 25.149907],
                            [111.28908, 25.142481],
                            [111.290647, 25.13196],
                            [111.296719, 25.122065],
                            [111.310958, 25.116164],
                            [111.318823, 25.109814],
                            [111.321792, 25.104922],
                            [111.347165, 25.101129],
                            [111.354729, 25.100994],
                            [111.360364, 25.103081],
                            [111.367883, 25.108849],
                            [111.36963, 25.118722],
                            [111.37513, 25.128482],
                            [111.387078, 25.130277],
                            [111.395185, 25.12846],
                            [111.39975, 25.124578],
                            [111.402824, 25.10757],
                            [111.412693, 25.100703],
                            [111.429116, 25.103485],
                            [111.433923, 25.102363],
                            [111.43671, 25.098459],
                            [111.434495, 25.091524],
                            [111.418825, 25.065577],
                            [111.41658, 25.047662],
                            [111.418358, 25.041465],
                            [111.423421, 25.035493],
                            [111.439332, 25.027477],
                            [111.452968, 25.024962],
                            [111.465323, 25.023817],
                            [111.475117, 25.044092],
                            [111.478221, 25.046158],
                            [111.499466, 25.050828],
                            [111.500551, 25.053701],
                            [111.501982, 25.073613],
                            [111.501108, 25.085015],
                            [111.502871, 25.088404],
                            [111.509651, 25.091793],
                            [111.522579, 25.091501],
                            [111.532132, 25.09581],
                            [111.539892, 25.106178],
                            [111.542227, 25.111093],
                            [111.541534, 25.119417],
                            [111.530429, 25.128101],
                            [111.522986, 25.127248],
                            [111.514322, 25.118744],
                            [111.500641, 25.112327],
                            [111.502736, 25.117735],
                            [111.503142, 25.125655],
                            [111.497583, 25.129963],
                            [111.496166, 25.136828],
                            [111.499511, 25.144837],
                            [111.505523, 25.150109],
                            [111.508039, 25.158655],
                            [111.507858, 25.164554],
                            [111.504514, 25.168973],
                            [111.498261, 25.182721],
                            [111.49805, 25.18669],
                            [111.491541, 25.190301],
                            [111.468096, 25.192678],
                            [111.462099, 25.18752],
                            [111.458257, 25.187139],
                            [111.453661, 25.190794],
                            [111.449397, 25.198777],
                            [111.446218, 25.212231],
                            [111.462144, 25.223194],
                            [111.466935, 25.229336],
                            [111.466634, 25.250721],
                            [111.461436, 25.255787],
                            [111.464992, 25.263048],
                            [111.47007, 25.265244],
                            [111.468111, 25.268606],
                            [111.468503, 25.275867],
                            [111.472767, 25.278041],
                            [111.472812, 25.288998],
                            [111.476157, 25.292],
                            [111.476368, 25.299081],
                            [111.468533, 25.306003],
                            [111.464058, 25.314584],
                            [111.463395, 25.323163],
                            [111.464856, 25.34171],
                            [111.470627, 25.341306],
                            [111.472495, 25.335573],
                            [111.47575, 25.338216],
                            [111.472375, 25.349996],
                            [111.462792, 25.354901],
                            [111.460939, 25.362156],
                            [111.456283, 25.364575],
                            [111.456208, 25.369456],
                            [111.44786, 25.374875],
                            [111.439739, 25.385533],
                            [111.435746, 25.394667],
                            [111.428348, 25.391958],
                            [111.420362, 25.392092],
                            [111.406591, 25.403196],
                            [111.39074, 25.401674],
                            [111.385105, 25.394645],
                            [111.374814, 25.39301],
                            [111.369103, 25.393883],
                            [111.370143, 25.401808],
                            [111.368892, 25.405659],
                            [111.361313, 25.41027],
                            [111.355467, 25.430459],
                            [111.352574, 25.435987],
                            [111.344709, 25.443148],
                            [111.339074, 25.450868],
                            [111.322455, 25.463779],
                            [111.311696, 25.473824],
                            [111.305022, 25.477136]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 431126,
                "name": "宁远县",
                "center": [111.944529, 25.584112],
                "centroid": [111.979334, 25.645455],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431100
                },
                "subFeatureIndex": 7,
                "acroutes": [100000, 430000, 431100]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [111.924697, 25.225929],
                            [111.929142, 25.229157],
                            [111.93104, 25.225996],
                            [111.939644, 25.222835],
                            [111.940563, 25.218441],
                            [111.951984, 25.214271],
                            [111.959895, 25.215706],
                            [111.964219, 25.221692],
                            [111.969221, 25.222118],
                            [111.981139, 25.215482],
                            [111.980898, 25.212253],
                            [111.990903, 25.208172],
                            [111.992229, 25.200257],
                            [111.999356, 25.198463],
                            [112.004856, 25.204831],
                            [112.01379, 25.20797],
                            [112.017407, 25.228059],
                            [112.025709, 25.230368],
                            [112.033694, 25.228328],
                            [112.04058, 25.228395],
                            [112.051414, 25.230794],
                            [112.062217, 25.23985],
                            [112.063136, 25.256123],
                            [112.068214, 25.263183],
                            [112.08408, 25.264819],
                            [112.088962, 25.272819],
                            [112.088736, 25.283867],
                            [112.08191, 25.301971],
                            [112.086777, 25.313195],
                            [112.087274, 25.337768],
                            [112.086792, 25.341934],
                            [112.078414, 25.366545],
                            [112.077465, 25.381771],
                            [112.080705, 25.388018],
                            [112.091056, 25.39066],
                            [112.093437, 25.395562],
                            [112.087274, 25.405345],
                            [112.083899, 25.407606],
                            [112.068696, 25.422737],
                            [112.06838, 25.425289],
                            [112.079409, 25.433614],
                            [112.076968, 25.438829],
                            [112.065833, 25.442007],
                            [112.053026, 25.441447],
                            [112.049847, 25.447243],
                            [112.051836, 25.453553],
                            [112.056898, 25.456731],
                            [112.062413, 25.455724],
                            [112.076923, 25.455836],
                            [112.079575, 25.45729],
                            [112.076426, 25.462996],
                            [112.05179, 25.467359],
                            [112.045191, 25.467493],
                            [112.041936, 25.464674],
                            [112.04043, 25.457872],
                            [112.033951, 25.456373],
                            [112.028346, 25.460132],
                            [112.026492, 25.473332],
                            [112.030154, 25.485323],
                            [112.035518, 25.489171],
                            [112.040806, 25.488187],
                            [112.045221, 25.478746],
                            [112.051052, 25.478433],
                            [112.05274, 25.491207],
                            [112.06184, 25.501049],
                            [112.074211, 25.503106],
                            [112.080991, 25.508944],
                            [112.081368, 25.521334],
                            [112.084487, 25.52574],
                            [112.104571, 25.53976],
                            [112.106605, 25.547877],
                            [112.114169, 25.554003],
                            [112.123963, 25.555277],
                            [112.156915, 25.575509],
                            [112.184368, 25.603962],
                            [112.19496, 25.606532],
                            [112.205221, 25.604543],
                            [112.211233, 25.606934],
                            [112.222745, 25.614979],
                            [112.228666, 25.612543],
                            [112.238837, 25.603895],
                            [112.257445, 25.634083],
                            [112.258876, 25.643199],
                            [112.257912, 25.650482],
                            [112.245632, 25.672082],
                            [112.243056, 25.684857],
                            [112.243462, 25.691445],
                            [112.242558, 25.697944],
                            [112.237224, 25.700154],
                            [112.234407, 25.696113],
                            [112.223393, 25.691624],
                            [112.217185, 25.691981],
                            [112.20388, 25.699842],
                            [112.201078, 25.700378],
                            [112.198275, 25.695867],
                            [112.196723, 25.686666],
                            [112.193619, 25.680391],
                            [112.187487, 25.676482],
                            [112.185212, 25.669737],
                            [112.186026, 25.660132],
                            [112.181144, 25.660802],
                            [112.178778, 25.671792],
                            [112.17132, 25.673512],
                            [112.171395, 25.681932],
                            [112.165052, 25.687135],
                            [112.149954, 25.690731],
                            [112.144455, 25.690954],
                            [112.138322, 25.68622],
                            [112.133546, 25.688408],
                            [112.13329, 25.694214],
                            [112.139091, 25.697877],
                            [112.145811, 25.696649],
                            [112.147739, 25.698569],
                            [112.14899, 25.70922],
                            [112.141607, 25.714601],
                            [112.128574, 25.726881],
                            [112.128785, 25.727952],
                            [112.144259, 25.729314],
                            [112.150467, 25.733824],
                            [112.156961, 25.744271],
                            [112.154233, 25.751012],
                            [112.147438, 25.748289],
                            [112.144048, 25.752284],
                            [112.140206, 25.752597],
                            [112.126088, 25.741659],
                            [112.106636, 25.738221],
                            [112.100579, 25.739025],
                            [112.09095, 25.74503],
                            [112.088268, 25.751927],
                            [112.086219, 25.776633],
                            [112.087967, 25.786876],
                            [112.091026, 25.793815],
                            [112.076697, 25.79676],
                            [112.075476, 25.797363],
                            [112.073231, 25.805395],
                            [112.065592, 25.803788],
                            [112.063422, 25.799661],
                            [112.057682, 25.80564],
                            [112.050525, 25.80535],
                            [112.050118, 25.811418],
                            [112.046487, 25.815233],
                            [112.056989, 25.822528],
                            [112.064191, 25.818713],
                            [112.062684, 25.814698],
                            [112.067144, 25.81211],
                            [112.069269, 25.807737],
                            [112.075702, 25.813917],
                            [112.080328, 25.814564],
                            [112.11206, 25.820788],
                            [112.113898, 25.836536],
                            [112.113973, 25.849584],
                            [112.110568, 25.85284],
                            [112.097113, 25.849361],
                            [112.093783, 25.854535],
                            [112.098228, 25.864971],
                            [112.110659, 25.877659],
                            [112.110719, 25.88816],
                            [112.113024, 25.893555],
                            [112.114124, 25.907488],
                            [112.11774, 25.918031],
                            [112.107223, 25.916047],
                            [112.107163, 25.912659],
                            [112.102206, 25.910297],
                            [112.100081, 25.913306],
                            [112.093211, 25.911567],
                            [112.08533, 25.915245],
                            [112.086701, 25.92075],
                            [112.097565, 25.919525],
                            [112.100187, 25.921375],
                            [112.09749, 25.926456],
                            [112.094973, 25.924495],
                            [112.092307, 25.929443],
                            [112.094747, 25.936463],
                            [112.108655, 25.953889],
                            [112.109393, 25.957298],
                            [112.107404, 25.966612],
                            [112.11429, 25.977172],
                            [112.115164, 25.982028],
                            [112.107404, 25.991407],
                            [112.104662, 26.004437],
                            [112.097716, 26.013479],
                            [112.093843, 26.035125],
                            [112.090393, 26.040046],
                            [112.093406, 26.047171],
                            [112.083914, 26.055653],
                            [112.07846, 26.062377],
                            [112.077827, 26.071459],
                            [112.060379, 26.075866],
                            [112.053855, 26.080897],
                            [112.053523, 26.087129],
                            [112.04611, 26.089978],
                            [112.046155, 26.095164],
                            [112.054126, 26.101595],
                            [112.051564, 26.109607],
                            [112.053749, 26.113256],
                            [112.063558, 26.118129],
                            [112.06847, 26.114547],
                            [112.073442, 26.115904],
                            [112.076441, 26.119531],
                            [112.06957, 26.125739],
                            [112.053824, 26.134839],
                            [112.048867, 26.135106],
                            [112.044874, 26.129766],
                            [112.04275, 26.120844],
                            [112.04388, 26.109317],
                            [112.04287, 26.107604],
                            [112.030154, 26.103398],
                            [112.035156, 26.097612],
                            [112.033152, 26.094207],
                            [112.027095, 26.093138],
                            [112.017256, 26.095186],
                            [112.009014, 26.089933],
                            [112.001932, 26.090623],
                            [111.993284, 26.096766],
                            [111.985509, 26.099259],
                            [111.979603, 26.08891],
                            [111.978533, 26.076178],
                            [111.979648, 26.066962],
                            [111.9779, 26.057746],
                            [111.977297, 26.044321],
                            [111.979618, 26.035014],
                            [111.977704, 26.029937],
                            [111.97121, 26.022521],
                            [111.96559, 26.019426],
                            [111.960226, 26.019225],
                            [111.952059, 26.026641],
                            [111.936947, 26.017666],
                            [111.928991, 26.019938],
                            [111.924682, 26.015506],
                            [111.926264, 26.007911],
                            [111.923341, 26.0027],
                            [111.920267, 25.989536],
                            [111.911694, 25.972805],
                            [111.907098, 25.950992],
                            [111.9052, 25.935014],
                            [111.89961, 25.931649],
                            [111.89292, 25.930669],
                            [111.882795, 25.931694],
                            [111.876316, 25.928418],
                            [111.875035, 25.92104],
                            [111.876075, 25.908647],
                            [111.874899, 25.895383],
                            [111.878018, 25.863745],
                            [111.883352, 25.851256],
                            [111.882539, 25.849383],
                            [111.864699, 25.840618],
                            [111.853775, 25.822617],
                            [111.850204, 25.818691],
                            [111.835559, 25.809433],
                            [111.829517, 25.788192],
                            [111.826835, 25.775495],
                            [111.824424, 25.770385],
                            [111.822269, 25.760118],
                            [111.816965, 25.754628],
                            [111.806825, 25.750699],
                            [111.805725, 25.743847],
                            [111.795464, 25.739695],
                            [111.791306, 25.745789],
                            [111.787207, 25.745632],
                            [111.790191, 25.735944],
                            [111.788774, 25.72927],
                            [111.78329, 25.729314],
                            [111.784314, 25.722438],
                            [111.77541, 25.716008],
                            [111.770965, 25.710895],
                            [111.765239, 25.708461],
                            [111.761186, 25.697609],
                            [111.762211, 25.691512],
                            [111.758775, 25.679319],
                            [111.755641, 25.674852],
                            [111.751151, 25.67302],
                            [111.740649, 25.662947],
                            [111.743859, 25.656714],
                            [111.73643, 25.64684],
                            [111.736837, 25.642082],
                            [111.73298, 25.630263],
                            [111.733251, 25.626531],
                            [111.744446, 25.625101],
                            [111.753381, 25.620163],
                            [111.754285, 25.617526],
                            [111.750805, 25.609169],
                            [111.752628, 25.603783],
                            [111.749916, 25.592564],
                            [111.754315, 25.587132],
                            [111.765601, 25.591513],
                            [111.7642, 25.598576],
                            [111.770859, 25.604163],
                            [111.77324, 25.609884],
                            [111.781874, 25.619292],
                            [111.78769, 25.622487],
                            [111.794816, 25.633056],
                            [111.7934, 25.642015],
                            [111.798041, 25.643311],
                            [111.803706, 25.640607],
                            [111.813831, 25.640741],
                            [111.814826, 25.630441],
                            [111.818804, 25.6249],
                            [111.830993, 25.61947],
                            [111.826789, 25.606576],
                            [111.83253, 25.60166],
                            [111.838, 25.599961],
                            [111.838361, 25.590507],
                            [111.840757, 25.584852],
                            [111.849556, 25.576291],
                            [111.8504, 25.570278],
                            [111.848953, 25.564153],
                            [111.84145, 25.547586],
                            [111.831882, 25.537569],
                            [111.830044, 25.525717],
                            [111.827076, 25.523146],
                            [111.817659, 25.52196],
                            [111.811692, 25.515385],
                            [111.801265, 25.514804],
                            [111.792677, 25.517577],
                            [111.789362, 25.516906],
                            [111.787976, 25.509078],
                            [111.789935, 25.503621],
                            [111.797257, 25.505388],
                            [111.799005, 25.503666],
                            [111.796745, 25.495233],
                            [111.790733, 25.489149],
                            [111.779613, 25.482438],
                            [111.76991, 25.470558],
                            [111.761668, 25.450577],
                            [111.760448, 25.443976],
                            [111.761186, 25.436345],
                            [111.765812, 25.433323],
                            [111.773632, 25.432898],
                            [111.785655, 25.43404],
                            [111.800572, 25.440776],
                            [111.813395, 25.453822],
                            [111.825373, 25.454739],
                            [111.83021, 25.451472],
                            [111.83699, 25.432227],
                            [111.843424, 25.407942],
                            [111.854785, 25.360163],
                            [111.865241, 25.342762],
                            [111.882418, 25.301993],
                            [111.887526, 25.29917],
                            [111.895316, 25.301724],
                            [111.902473, 25.306138],
                            [111.904929, 25.297714],
                            [111.913397, 25.288259],
                            [111.915747, 25.28194],
                            [111.914979, 25.278063],
                            [111.908665, 25.267844],
                            [111.90755, 25.262622],
                            [111.909509, 25.257916],
                            [111.916802, 25.249264],
                            [111.921563, 25.247135],
                            [111.924803, 25.241554],
                            [111.918444, 25.234717],
                            [111.924697, 25.225929]
                        ]
                    ],
                    [
                        [
                            [112.076697, 25.79676],
                            [112.091026, 25.793815],
                            [112.09526, 25.799549],
                            [112.105551, 25.807916],
                            [112.11206, 25.820788],
                            [112.080328, 25.814564],
                            [112.076667, 25.805596],
                            [112.073231, 25.805395],
                            [112.075476, 25.797363],
                            [112.076697, 25.79676]
                        ]
                    ],
                    [
                        [
                            [112.09749, 25.926456],
                            [112.100187, 25.921375],
                            [112.10308, 25.922712],
                            [112.107223, 25.916047],
                            [112.11774, 25.918031],
                            [112.119594, 25.933945],
                            [112.11664, 25.939427],
                            [112.104812, 25.934635],
                            [112.09749, 25.926456]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 431127,
                "name": "蓝山县",
                "center": [112.194195, 25.375255],
                "centroid": [112.188061, 25.312407],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431100
                },
                "subFeatureIndex": 8,
                "acroutes": [100000, 430000, 431100]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.152576, 25.053679],
                            [112.152169, 25.055969],
                            [112.156855, 25.064499],
                            [112.164253, 25.071682],
                            [112.16582, 25.084993],
                            [112.172239, 25.100187],
                            [112.177121, 25.10739],
                            [112.177452, 25.123187],
                            [112.174152, 25.129514],
                            [112.184519, 25.14219],
                            [112.183313, 25.148852],
                            [112.18696, 25.156434],
                            [112.187623, 25.162154],
                            [112.193167, 25.172203],
                            [112.187156, 25.182542],
                            [112.197492, 25.187206],
                            [112.201741, 25.185793],
                            [112.223152, 25.186018],
                            [112.246566, 25.184964],
                            [112.252216, 25.175387],
                            [112.254929, 25.173459],
                            [112.256059, 25.159373],
                            [112.269559, 25.16424],
                            [112.274396, 25.162872],
                            [112.28199, 25.164734],
                            [112.286977, 25.158992],
                            [112.302557, 25.157197],
                            [112.307845, 25.159642],
                            [112.30661, 25.163837],
                            [112.313661, 25.173885],
                            [112.315982, 25.17523],
                            [112.32356, 25.173279],
                            [112.33242, 25.174558],
                            [112.345348, 25.182295],
                            [112.350863, 25.187027],
                            [112.364182, 25.191714],
                            [112.36489, 25.199517],
                            [112.36156, 25.207858],
                            [112.361877, 25.212006],
                            [112.368085, 25.222835],
                            [112.368145, 25.232699],
                            [112.357613, 25.244266],
                            [112.356965, 25.2509],
                            [112.360506, 25.258543],
                            [112.355413, 25.263967],
                            [112.357598, 25.278646],
                            [112.344881, 25.284673],
                            [112.345167, 25.289267],
                            [112.353047, 25.292224],
                            [112.362208, 25.298184],
                            [112.37491, 25.300806],
                            [112.386075, 25.31315],
                            [112.394, 25.314606],
                            [112.403161, 25.320319],
                            [112.40952, 25.325784],
                            [112.419992, 25.339067],
                            [112.429228, 25.358752],
                            [112.432859, 25.368404],
                            [112.440453, 25.366411],
                            [112.444461, 25.362739],
                            [112.452552, 25.365493],
                            [112.458428, 25.375099],
                            [112.455189, 25.381099],
                            [112.435722, 25.379958],
                            [112.428007, 25.385734],
                            [112.421152, 25.395137],
                            [112.426455, 25.398495],
                            [112.440965, 25.399323],
                            [112.439941, 25.4083],
                            [112.426892, 25.423297],
                            [112.420639, 25.435517],
                            [112.41181, 25.440597],
                            [112.400012, 25.457178],
                            [112.398023, 25.476867],
                            [112.39391, 25.483914],
                            [112.392027, 25.490961],
                            [112.372168, 25.512075],
                            [112.367949, 25.52044],
                            [112.37146, 25.524689],
                            [112.372409, 25.530033],
                            [112.369923, 25.539425],
                            [112.357824, 25.541661],
                            [112.342892, 25.537726],
                            [112.334198, 25.532448],
                            [112.325595, 25.529989],
                            [112.312863, 25.535042],
                            [112.300462, 25.544702],
                            [112.294405, 25.551141],
                            [112.285787, 25.555747],
                            [112.275902, 25.556239],
                            [112.270297, 25.554875],
                            [112.258695, 25.556283],
                            [112.250981, 25.559011],
                            [112.246265, 25.567596],
                            [112.257686, 25.573028],
                            [112.26079, 25.577074],
                            [112.260504, 25.582327],
                            [112.249926, 25.584808],
                            [112.243643, 25.589904],
                            [112.23843, 25.599648],
                            [112.238837, 25.603895],
                            [112.228666, 25.612543],
                            [112.222745, 25.614979],
                            [112.211233, 25.606934],
                            [112.205221, 25.604543],
                            [112.19496, 25.606532],
                            [112.184368, 25.603962],
                            [112.156915, 25.575509],
                            [112.123963, 25.555277],
                            [112.114169, 25.554003],
                            [112.106605, 25.547877],
                            [112.104571, 25.53976],
                            [112.084487, 25.52574],
                            [112.081368, 25.521334],
                            [112.080991, 25.508944],
                            [112.074211, 25.503106],
                            [112.06184, 25.501049],
                            [112.05274, 25.491207],
                            [112.051052, 25.478433],
                            [112.045221, 25.478746],
                            [112.040806, 25.488187],
                            [112.035518, 25.489171],
                            [112.030154, 25.485323],
                            [112.026492, 25.473332],
                            [112.028346, 25.460132],
                            [112.033951, 25.456373],
                            [112.04043, 25.457872],
                            [112.041936, 25.464674],
                            [112.045191, 25.467493],
                            [112.05179, 25.467359],
                            [112.076426, 25.462996],
                            [112.079575, 25.45729],
                            [112.076923, 25.455836],
                            [112.062413, 25.455724],
                            [112.056898, 25.456731],
                            [112.051836, 25.453553],
                            [112.049847, 25.447243],
                            [112.053026, 25.441447],
                            [112.065833, 25.442007],
                            [112.076968, 25.438829],
                            [112.079409, 25.433614],
                            [112.06838, 25.425289],
                            [112.068696, 25.422737],
                            [112.083899, 25.407606],
                            [112.087274, 25.405345],
                            [112.093437, 25.395562],
                            [112.091056, 25.39066],
                            [112.080705, 25.388018],
                            [112.077465, 25.381771],
                            [112.078414, 25.366545],
                            [112.086792, 25.341934],
                            [112.087274, 25.337768],
                            [112.086777, 25.313195],
                            [112.08191, 25.301971],
                            [112.088736, 25.283867],
                            [112.088962, 25.272819],
                            [112.08408, 25.264819],
                            [112.068214, 25.263183],
                            [112.063136, 25.256123],
                            [112.062217, 25.23985],
                            [112.051414, 25.230794],
                            [112.04058, 25.228395],
                            [112.033694, 25.228328],
                            [112.025709, 25.230368],
                            [112.017407, 25.228059],
                            [112.01379, 25.20797],
                            [112.004856, 25.204831],
                            [111.999356, 25.198463],
                            [111.992229, 25.200257],
                            [111.990903, 25.208172],
                            [111.980898, 25.212253],
                            [111.973666, 25.202163],
                            [111.972988, 25.195122],
                            [111.976243, 25.189583],
                            [111.979166, 25.179402],
                            [111.978699, 25.166842],
                            [111.974751, 25.159261],
                            [111.969312, 25.155111],
                            [111.957348, 25.150939],
                            [111.952813, 25.140844],
                            [111.947343, 25.124354],
                            [111.941241, 25.120562],
                            [111.932095, 25.123815],
                            [111.92765, 25.121998],
                            [111.918821, 25.100658],
                            [111.914331, 25.054689],
                            [111.91751, 25.042319],
                            [111.926249, 25.03309],
                            [111.930634, 25.030778],
                            [111.935711, 25.023817],
                            [111.93993, 25.011398],
                            [111.959141, 25.007109],
                            [111.963707, 25.007109],
                            [111.970336, 25.011331],
                            [111.975821, 25.024378],
                            [111.976077, 25.038614],
                            [111.981712, 25.046584],
                            [111.985614, 25.047976],
                            [111.993555, 25.04526],
                            [111.999597, 25.038771],
                            [112.011199, 25.039041],
                            [112.019878, 25.040612],
                            [112.026357, 25.039377],
                            [112.02821, 25.034415],
                            [112.028948, 25.01955],
                            [112.033815, 25.021436],
                            [112.043292, 25.021616],
                            [112.059656, 25.01434],
                            [112.069856, 25.012948],
                            [112.07403, 25.015193],
                            [112.083507, 25.029026],
                            [112.089866, 25.032911],
                            [112.095064, 25.028779],
                            [112.101452, 25.028712],
                            [112.106123, 25.032305],
                            [112.110704, 25.044788],
                            [112.117379, 25.05213],
                            [112.122667, 25.050873],
                            [112.12657, 25.046652],
                            [112.134405, 25.04764],
                            [112.142601, 25.052669],
                            [112.152576, 25.053679]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 431128,
                "name": "新田县",
                "center": [112.220341, 25.906927],
                "centroid": [112.227845, 25.883762],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431100
                },
                "subFeatureIndex": 9,
                "acroutes": [100000, 430000, 431100]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.229691, 26.111854],
                            [112.224538, 26.11092],
                            [112.209877, 26.09779],
                            [112.206246, 26.096388],
                            [112.202148, 26.098992],
                            [112.191359, 26.0969],
                            [112.176247, 26.089177],
                            [112.153736, 26.069433],
                            [112.144485, 26.06605],
                            [112.138503, 26.066406],
                            [112.113687, 26.04176],
                            [112.101015, 26.033655],
                            [112.093843, 26.035125],
                            [112.097716, 26.013479],
                            [112.104662, 26.004437],
                            [112.107404, 25.991407],
                            [112.115164, 25.982028],
                            [112.11429, 25.977172],
                            [112.107404, 25.966612],
                            [112.109393, 25.957298],
                            [112.108655, 25.953889],
                            [112.094747, 25.936463],
                            [112.092307, 25.929443],
                            [112.094973, 25.924495],
                            [112.09749, 25.926456],
                            [112.104812, 25.934635],
                            [112.11664, 25.939427],
                            [112.119594, 25.933945],
                            [112.11774, 25.918031],
                            [112.114124, 25.907488],
                            [112.113024, 25.893555],
                            [112.110719, 25.88816],
                            [112.110659, 25.877659],
                            [112.098228, 25.864971],
                            [112.093783, 25.854535],
                            [112.097113, 25.849361],
                            [112.110568, 25.85284],
                            [112.113973, 25.849584],
                            [112.113898, 25.836536],
                            [112.11206, 25.820788],
                            [112.105551, 25.807916],
                            [112.09526, 25.799549],
                            [112.091026, 25.793815],
                            [112.087967, 25.786876],
                            [112.086219, 25.776633],
                            [112.088268, 25.751927],
                            [112.09095, 25.74503],
                            [112.100579, 25.739025],
                            [112.106636, 25.738221],
                            [112.126088, 25.741659],
                            [112.140206, 25.752597],
                            [112.144048, 25.752284],
                            [112.147438, 25.748289],
                            [112.154233, 25.751012],
                            [112.156961, 25.744271],
                            [112.150467, 25.733824],
                            [112.144259, 25.729314],
                            [112.128785, 25.727952],
                            [112.128574, 25.726881],
                            [112.141607, 25.714601],
                            [112.14899, 25.70922],
                            [112.147739, 25.698569],
                            [112.145811, 25.696649],
                            [112.139091, 25.697877],
                            [112.13329, 25.694214],
                            [112.133546, 25.688408],
                            [112.138322, 25.68622],
                            [112.144455, 25.690954],
                            [112.149954, 25.690731],
                            [112.165052, 25.687135],
                            [112.171395, 25.681932],
                            [112.17132, 25.673512],
                            [112.178778, 25.671792],
                            [112.181144, 25.660802],
                            [112.186026, 25.660132],
                            [112.185212, 25.669737],
                            [112.187487, 25.676482],
                            [112.193619, 25.680391],
                            [112.196723, 25.686666],
                            [112.198275, 25.695867],
                            [112.201078, 25.700378],
                            [112.20388, 25.699842],
                            [112.217185, 25.691981],
                            [112.223393, 25.691624],
                            [112.234407, 25.696113],
                            [112.237224, 25.700154],
                            [112.242558, 25.697944],
                            [112.24738, 25.703951],
                            [112.278117, 25.711185],
                            [112.280739, 25.716454],
                            [112.289147, 25.723353],
                            [112.292492, 25.728421],
                            [112.297388, 25.730274],
                            [112.310572, 25.72889],
                            [112.317021, 25.723264],
                            [112.31794, 25.706965],
                            [112.321451, 25.698457],
                            [112.336428, 25.690865],
                            [112.343314, 25.694281],
                            [112.347457, 25.705067],
                            [112.352083, 25.712391],
                            [112.361801, 25.720183],
                            [112.383513, 25.730587],
                            [112.392072, 25.74032],
                            [112.389992, 25.744048],
                            [112.377592, 25.750766],
                            [112.377035, 25.760498],
                            [112.386858, 25.789353],
                            [112.385728, 25.793414],
                            [112.387732, 25.802093],
                            [112.385382, 25.805551],
                            [112.37708, 25.803677],
                            [112.37381, 25.806644],
                            [112.361711, 25.809098],
                            [112.364966, 25.814676],
                            [112.359376, 25.815501],
                            [112.356302, 25.82119],
                            [112.36034, 25.826298],
                            [112.352309, 25.836893],
                            [112.361575, 25.834105],
                            [112.368808, 25.837228],
                            [112.380515, 25.837294],
                            [112.387024, 25.84626],
                            [112.385367, 25.852081],
                            [112.388335, 25.857233],
                            [112.386874, 25.859775],
                            [112.377984, 25.859307],
                            [112.370616, 25.86865],
                            [112.368642, 25.878305],
                            [112.361334, 25.889564],
                            [112.359571, 25.89438],
                            [112.360415, 25.905861],
                            [112.357115, 25.908848],
                            [112.349717, 25.906574],
                            [112.34583, 25.897412],
                            [112.336835, 25.88573],
                            [112.331471, 25.885039],
                            [112.312396, 25.89059],
                            [112.304877, 25.890813],
                            [112.288664, 25.886287],
                            [112.272105, 25.886332],
                            [112.265777, 25.890256],
                            [112.265551, 25.896676],
                            [112.296816, 25.931471],
                            [112.305766, 25.936039],
                            [112.315198, 25.935794],
                            [112.31678, 25.944062],
                            [112.31449, 25.945889],
                            [112.309834, 25.956117],
                            [112.304485, 25.953978],
                            [112.297449, 25.959058],
                            [112.299332, 25.962512],
                            [112.30896, 25.958813],
                            [112.311537, 25.962423],
                            [112.30453, 25.965431],
                            [112.301035, 25.964072],
                            [112.301005, 25.968038],
                            [112.293004, 25.974298],
                            [112.296575, 25.97354],
                            [112.300402, 25.978754],
                            [112.306429, 26.006954],
                            [112.313887, 26.01613],
                            [112.328563, 26.039044],
                            [112.331426, 26.047527],
                            [112.333641, 26.066206],
                            [112.336639, 26.072483],
                            [112.33581, 26.076646],
                            [112.321813, 26.093762],
                            [112.315288, 26.098391],
                            [112.309804, 26.099014],
                            [112.299694, 26.090356],
                            [112.295641, 26.090935],
                            [112.291648, 26.099948],
                            [112.287821, 26.102241],
                            [112.282592, 26.101707],
                            [112.280829, 26.096944],
                            [112.271141, 26.089043],
                            [112.26186, 26.092871],
                            [112.251704, 26.089577],
                            [112.246822, 26.092515],
                            [112.2406, 26.093316],
                            [112.232855, 26.100705],
                            [112.229691, 26.111854]
                        ]
                    ],
                    [
                        [
                            [112.073231, 25.805395],
                            [112.076667, 25.805596],
                            [112.080328, 25.814564],
                            [112.075702, 25.813917],
                            [112.069269, 25.807737],
                            [112.067144, 25.81211],
                            [112.062684, 25.814698],
                            [112.064191, 25.818713],
                            [112.056989, 25.822528],
                            [112.046487, 25.815233],
                            [112.050118, 25.811418],
                            [112.050525, 25.80535],
                            [112.057682, 25.80564],
                            [112.063422, 25.799661],
                            [112.065592, 25.803788],
                            [112.073231, 25.805395]
                        ]
                    ],
                    [
                        [
                            [112.107223, 25.916047],
                            [112.10308, 25.922712],
                            [112.100187, 25.921375],
                            [112.097565, 25.919525],
                            [112.086701, 25.92075],
                            [112.08533, 25.915245],
                            [112.093211, 25.911567],
                            [112.100081, 25.913306],
                            [112.102206, 25.910297],
                            [112.107163, 25.912659],
                            [112.107223, 25.916047]
                        ]
                    ],
                    [
                        [
                            [112.243056, 25.684857],
                            [112.251388, 25.687091],
                            [112.250845, 25.689837],
                            [112.243462, 25.691445],
                            [112.243056, 25.684857]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 431129,
                "name": "江华瑶族自治县",
                "center": [111.577276, 25.182596],
                "centroid": [111.744092, 24.971202],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431100
                },
                "subFeatureIndex": 10,
                "acroutes": [100000, 430000, 431100]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [111.465323, 25.023817],
                            [111.468337, 25.019438],
                            [111.468864, 25.013015],
                            [111.467342, 25.00246],
                            [111.460502, 24.992757],
                            [111.455786, 24.990286],
                            [111.438398, 24.984289],
                            [111.43335, 24.979706],
                            [111.43109, 24.972585],
                            [111.431377, 24.959712],
                            [111.440221, 24.944568],
                            [111.446278, 24.939287],
                            [111.454098, 24.93767],
                            [111.462159, 24.930793],
                            [111.47022, 24.92895],
                            [111.469271, 24.918927],
                            [111.463952, 24.90897],
                            [111.455906, 24.907127],
                            [111.453134, 24.90461],
                            [111.447107, 24.893123],
                            [111.450648, 24.886694],
                            [111.459462, 24.882333],
                            [111.461481, 24.877724],
                            [111.460487, 24.872216],
                            [111.449427, 24.857061],
                            [111.452968, 24.844199],
                            [111.471124, 24.820606],
                            [111.477031, 24.808302],
                            [111.479441, 24.797572],
                            [111.475946, 24.784005],
                            [111.470431, 24.773453],
                            [111.467192, 24.758061],
                            [111.46124, 24.750095],
                            [111.464163, 24.733867],
                            [111.459854, 24.727767],
                            [111.455138, 24.724571],
                            [111.453827, 24.719033],
                            [111.443009, 24.704063],
                            [111.437283, 24.697421],
                            [111.431105, 24.685825],
                            [111.435475, 24.681029],
                            [111.440462, 24.680308],
                            [111.44673, 24.682515],
                            [111.450919, 24.676953],
                            [111.451808, 24.665761],
                            [111.45458, 24.664815],
                            [111.468035, 24.666729],
                            [111.471576, 24.674701],
                            [111.485363, 24.672967],
                            [111.493077, 24.670603],
                            [111.504137, 24.664883],
                            [111.505418, 24.661842],
                            [111.514322, 24.655131],
                            [111.52154, 24.64218],
                            [111.529887, 24.636548],
                            [111.536592, 24.637427],
                            [111.537903, 24.643711],
                            [111.54613, 24.648802],
                            [111.549896, 24.648554],
                            [111.560082, 24.638914],
                            [111.570689, 24.64468],
                            [111.573402, 24.656077],
                            [111.583165, 24.681659],
                            [111.589147, 24.690666],
                            [111.59772, 24.693909],
                            [111.611823, 24.686568],
                            [111.619282, 24.683731],
                            [111.636624, 24.681389],
                            [111.641657, 24.684654],
                            [111.643857, 24.696498],
                            [111.643359, 24.701316],
                            [111.637814, 24.715544],
                            [111.639577, 24.724728],
                            [111.64235, 24.730018],
                            [111.652204, 24.740597],
                            [111.666427, 24.760829],
                            [111.68151, 24.774443],
                            [111.689586, 24.778583],
                            [111.697421, 24.77631],
                            [111.70399, 24.778156],
                            [111.708435, 24.78882],
                            [111.733281, 24.780271],
                            [111.742382, 24.779213],
                            [111.749599, 24.781801],
                            [111.761849, 24.782116],
                            [111.774626, 24.786705],
                            [111.783441, 24.785783],
                            [111.794244, 24.774285],
                            [111.814118, 24.770325],
                            [111.820657, 24.771248],
                            [111.835257, 24.776941],
                            [111.848592, 24.775995],
                            [111.856382, 24.772058],
                            [111.868707, 24.77181],
                            [111.869927, 24.758489],
                            [111.875186, 24.756643],
                            [111.903693, 24.763485],
                            [111.908726, 24.763462],
                            [111.924592, 24.757026],
                            [111.938213, 24.757026],
                            [111.945098, 24.760514],
                            [111.95096, 24.769605],
                            [111.963571, 24.770078],
                            [111.970095, 24.763732],
                            [111.985539, 24.762292],
                            [111.997699, 24.758939],
                            [112.007824, 24.752233],
                            [112.015629, 24.744986],
                            [112.024127, 24.740169],
                            [112.030485, 24.745076],
                            [112.034252, 24.758961],
                            [112.033815, 24.771158],
                            [112.043172, 24.780023],
                            [112.054201, 24.788888],
                            [112.058857, 24.790755],
                            [112.057154, 24.794715],
                            [112.059219, 24.799529],
                            [112.071242, 24.803353],
                            [112.083809, 24.804838],
                            [112.09648, 24.817232],
                            [112.095109, 24.824115],
                            [112.097249, 24.826387],
                            [112.112617, 24.831537],
                            [112.118931, 24.835068],
                            [112.124053, 24.841095],
                            [112.132642, 24.841365],
                            [112.141441, 24.844514],
                            [112.149487, 24.836935],
                            [112.153555, 24.837475],
                            [112.156674, 24.84213],
                            [112.161104, 24.844199],
                            [112.161029, 24.849348],
                            [112.16683, 24.853621],
                            [112.166438, 24.858928],
                            [112.171064, 24.862728],
                            [112.165685, 24.867202],
                            [112.167101, 24.876712],
                            [112.165308, 24.882468],
                            [112.168171, 24.890808],
                            [112.172344, 24.911533],
                            [112.175945, 24.923692],
                            [112.175192, 24.927512],
                            [112.165172, 24.929198],
                            [112.157307, 24.933512],
                            [112.152471, 24.932434],
                            [112.149201, 24.936299],
                            [112.142119, 24.937939],
                            [112.137961, 24.946163],
                            [112.12886, 24.951062],
                            [112.124777, 24.961374],
                            [112.12003, 24.963711],
                            [112.122577, 24.970878],
                            [112.1211, 24.98476],
                            [112.122049, 24.989522],
                            [112.134661, 24.995834],
                            [112.155725, 25.026983],
                            [112.148869, 25.035426],
                            [112.1489, 25.038838],
                            [112.15357, 25.046046],
                            [112.152576, 25.053679],
                            [112.142601, 25.052669],
                            [112.134405, 25.04764],
                            [112.12657, 25.046652],
                            [112.122667, 25.050873],
                            [112.117379, 25.05213],
                            [112.110704, 25.044788],
                            [112.106123, 25.032305],
                            [112.101452, 25.028712],
                            [112.095064, 25.028779],
                            [112.089866, 25.032911],
                            [112.083507, 25.029026],
                            [112.07403, 25.015193],
                            [112.069856, 25.012948],
                            [112.059656, 25.01434],
                            [112.043292, 25.021616],
                            [112.033815, 25.021436],
                            [112.028948, 25.01955],
                            [112.02821, 25.034415],
                            [112.026357, 25.039377],
                            [112.019878, 25.040612],
                            [112.011199, 25.039041],
                            [111.999597, 25.038771],
                            [111.993555, 25.04526],
                            [111.985614, 25.047976],
                            [111.981712, 25.046584],
                            [111.976077, 25.038614],
                            [111.975821, 25.024378],
                            [111.970336, 25.011331],
                            [111.963707, 25.007109],
                            [111.959141, 25.007109],
                            [111.93993, 25.011398],
                            [111.935711, 25.023817],
                            [111.930634, 25.030778],
                            [111.926249, 25.03309],
                            [111.91751, 25.042319],
                            [111.914331, 25.054689],
                            [111.918821, 25.100658],
                            [111.92765, 25.121998],
                            [111.932095, 25.123815],
                            [111.941241, 25.120562],
                            [111.947343, 25.124354],
                            [111.952813, 25.140844],
                            [111.957348, 25.150939],
                            [111.969312, 25.155111],
                            [111.974751, 25.159261],
                            [111.978699, 25.166842],
                            [111.979166, 25.179402],
                            [111.976243, 25.189583],
                            [111.972988, 25.195122],
                            [111.973666, 25.202163],
                            [111.980898, 25.212253],
                            [111.981139, 25.215482],
                            [111.969221, 25.222118],
                            [111.964219, 25.221692],
                            [111.959895, 25.215706],
                            [111.951984, 25.214271],
                            [111.940563, 25.218441],
                            [111.939644, 25.222835],
                            [111.93104, 25.225996],
                            [111.929142, 25.229157],
                            [111.924697, 25.225929],
                            [111.925285, 25.218105],
                            [111.906963, 25.20297],
                            [111.900619, 25.195728],
                            [111.889334, 25.193351],
                            [111.877099, 25.188798],
                            [111.866311, 25.175634],
                            [111.844343, 25.175993],
                            [111.839371, 25.17375],
                            [111.812581, 25.155762],
                            [111.806418, 25.150602],
                            [111.799713, 25.152374],
                            [111.785414, 25.172517],
                            [111.781165, 25.177114],
                            [111.770814, 25.184829],
                            [111.74413, 25.194831],
                            [111.740589, 25.19871],
                            [111.737576, 25.214047],
                            [111.731307, 25.218755],
                            [111.717988, 25.220638],
                            [111.707817, 25.227857],
                            [111.703132, 25.233618],
                            [111.698129, 25.255809],
                            [111.689676, 25.264662],
                            [111.683017, 25.267463],
                            [111.679777, 25.273491],
                            [111.668808, 25.281312],
                            [111.662947, 25.287407],
                            [111.658653, 25.295249],
                            [111.647232, 25.298789],
                            [111.634334, 25.298565],
                            [111.62573, 25.301814],
                            [111.623742, 25.305824],
                            [111.628262, 25.313732],
                            [111.626559, 25.319288],
                            [111.614626, 25.325426],
                            [111.600116, 25.322379],
                            [111.596349, 25.318459],
                            [111.59323, 25.310686],
                            [111.595385, 25.302777],
                            [111.59882, 25.298991],
                            [111.594888, 25.296168],
                            [111.57423, 25.299349],
                            [111.570674, 25.306228],
                            [111.565069, 25.30439],
                            [111.563819, 25.310977],
                            [111.554507, 25.319871],
                            [111.546687, 25.318706],
                            [111.538716, 25.312948],
                            [111.533548, 25.305488],
                            [111.523513, 25.299282],
                            [111.518044, 25.299663],
                            [111.513991, 25.304928],
                            [111.508672, 25.305309],
                            [111.499873, 25.301478],
                            [111.493364, 25.293927],
                            [111.488874, 25.279632],
                            [111.487909, 25.271542],
                            [111.48253, 25.265155],
                            [111.475479, 25.262331],
                            [111.47007, 25.265244],
                            [111.464992, 25.263048],
                            [111.461436, 25.255787],
                            [111.466634, 25.250721],
                            [111.466935, 25.229336],
                            [111.462144, 25.223194],
                            [111.446218, 25.212231],
                            [111.449397, 25.198777],
                            [111.453661, 25.190794],
                            [111.458257, 25.187139],
                            [111.462099, 25.18752],
                            [111.468096, 25.192678],
                            [111.491541, 25.190301],
                            [111.49805, 25.18669],
                            [111.498261, 25.182721],
                            [111.504514, 25.168973],
                            [111.507858, 25.164554],
                            [111.508039, 25.158655],
                            [111.505523, 25.150109],
                            [111.499511, 25.144837],
                            [111.496166, 25.136828],
                            [111.497583, 25.129963],
                            [111.503142, 25.125655],
                            [111.502736, 25.117735],
                            [111.500641, 25.112327],
                            [111.514322, 25.118744],
                            [111.522986, 25.127248],
                            [111.530429, 25.128101],
                            [111.541534, 25.119417],
                            [111.542227, 25.111093],
                            [111.539892, 25.106178],
                            [111.532132, 25.09581],
                            [111.522579, 25.091501],
                            [111.509651, 25.091793],
                            [111.502871, 25.088404],
                            [111.501108, 25.085015],
                            [111.501982, 25.073613],
                            [111.500551, 25.053701],
                            [111.499466, 25.050828],
                            [111.478221, 25.046158],
                            [111.475117, 25.044092],
                            [111.465323, 25.023817]
                        ]
                    ]
                ]
            }
        }]
    });
}));