/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

(function(root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['exports', 'echarts'], factory);
    } else if (typeof exports === 'object' && typeof exports.nodeName !== 'string') {
        // CommonJS
        factory(exports, require('echarts'));
    } else {
        // Browser globals
        factory({}, root.echarts);
    }
}(this, function(exports, echarts) {
    var log = function(msg) {
        if (typeof console !== 'undefined') {
            console && console.error && console.error(msg);
        }
    }
    if (!echarts) {
        log('ECharts is not Loaded');
        return;
    }
    if (!echarts.registerMap) {
        log('ECharts Map is not loaded')
        return;
    }
    echarts.registerMap('娄底市', {
        "type": "FeatureCollection",
        "features": [{
            "type": "Feature",
            "properties": {
                "adcode": 431302,
                "name": "娄星区",
                "center": [112.008486, 27.726643],
                "centroid": [112.008845, 27.71829],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431300
                },
                "subFeatureIndex": 0,
                "acroutes": [100000, 430000, 431300]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.154865, 27.649486],
                            [112.150825, 27.653342],
                            [112.15014, 27.658909],
                            [112.145784, 27.665136],
                            [112.142184, 27.666734],
                            [112.137951, 27.666249],
                            [112.134332, 27.66027],
                            [112.13579, 27.658002],
                            [112.134596, 27.654713],
                            [112.124865, 27.646125],
                            [112.121422, 27.644825],
                            [112.117207, 27.645444],
                            [112.114502, 27.647898],
                            [112.113677, 27.653455],
                            [112.112588, 27.653981],
                            [112.109321, 27.651991],
                            [112.105825, 27.653754],
                            [112.104262, 27.653362],
                            [112.095129, 27.646372],
                            [112.09267, 27.643269],
                            [112.089438, 27.642505],
                            [112.084133, 27.644341],
                            [112.078056, 27.645753],
                            [112.076914, 27.650383],
                            [112.073753, 27.653342],
                            [112.071979, 27.656239],
                            [112.067394, 27.65693],
                            [112.06699, 27.658455],
                            [112.070609, 27.666146],
                            [112.07096, 27.669599],
                            [112.067833, 27.671465],
                            [112.065111, 27.670311],
                            [112.062617, 27.672465],
                            [112.06288, 27.678031],
                            [112.060123, 27.678475],
                            [112.05805, 27.680381],
                            [112.056539, 27.677176],
                            [112.052675, 27.676114],
                            [112.051253, 27.679279],
                            [112.049215, 27.678021],
                            [112.049549, 27.674795],
                            [112.048161, 27.674042],
                            [112.047265, 27.676372],
                            [112.048249, 27.677598],
                            [112.047107, 27.681629],
                            [112.047529, 27.686123],
                            [112.048794, 27.688328],
                            [112.052974, 27.690544],
                            [112.052904, 27.69309],
                            [112.049742, 27.694749],
                            [112.04925, 27.697027],
                            [112.050287, 27.699294],
                            [112.049391, 27.70185],
                            [112.044789, 27.702509],
                            [112.045333, 27.706332],
                            [112.051288, 27.71267],
                            [112.053466, 27.714112],
                            [112.060966, 27.716441],
                            [112.071768, 27.720655],
                            [112.078723, 27.719522],
                            [112.081077, 27.718409],
                            [112.08329, 27.715205],
                            [112.088718, 27.715349],
                            [112.092107, 27.716328],
                            [112.091317, 27.719522],
                            [112.081815, 27.721779],
                            [112.081744, 27.723963],
                            [112.085573, 27.728846],
                            [112.085205, 27.731669],
                            [112.087154, 27.73373],
                            [112.094092, 27.735203],
                            [112.099133, 27.734678],
                            [112.101715, 27.731649],
                            [112.102383, 27.7259],
                            [112.100714, 27.722273],
                            [112.101908, 27.718893],
                            [112.103419, 27.71743],
                            [112.106387, 27.717132],
                            [112.108355, 27.72012],
                            [112.109321, 27.723386],
                            [112.110919, 27.738716],
                            [112.111973, 27.739952],
                            [112.118735, 27.743383],
                            [112.124198, 27.74398],
                            [112.12829, 27.745175],
                            [112.130784, 27.747308],
                            [112.125708, 27.752994],
                            [112.11733, 27.755723],
                            [112.114871, 27.757814],
                            [112.112658, 27.761996],
                            [112.106054, 27.76419],
                            [112.098677, 27.765014],
                            [112.097096, 27.768299],
                            [112.097746, 27.769823],
                            [112.097763, 27.776528],
                            [112.10291, 27.783397],
                            [112.10651, 27.790646],
                            [112.104034, 27.800263],
                            [112.101223, 27.807377],
                            [112.096569, 27.811526],
                            [112.091932, 27.814285],
                            [112.087014, 27.813832],
                            [112.080778, 27.809488],
                            [112.076879, 27.806049],
                            [112.069871, 27.80399],
                            [112.067043, 27.804453],
                            [112.063161, 27.806512],
                            [112.056434, 27.811567],
                            [112.051253, 27.813626],
                            [112.046862, 27.817065],
                            [112.040714, 27.835192],
                            [112.040538, 27.839504],
                            [112.031229, 27.840225],
                            [112.029824, 27.836561],
                            [112.026961, 27.835182],
                            [112.022816, 27.83725],
                            [112.018425, 27.842057],
                            [112.014806, 27.8471],
                            [112.013261, 27.853522],
                            [112.012453, 27.867805],
                            [112.014262, 27.871674],
                            [112.012295, 27.87866],
                            [112.006376, 27.889442],
                            [112.00339, 27.896056],
                            [112.002476, 27.899626],
                            [112.000474, 27.903062],
                            [112.00014, 27.907012],
                            [111.998296, 27.911002],
                            [111.998261, 27.916248],
                            [111.99466, 27.923602],
                            [111.99271, 27.928949],
                            [111.990269, 27.94096],
                            [111.987371, 27.946328],
                            [111.986018, 27.950019],
                            [111.98226, 27.954337],
                            [111.975322, 27.957021],
                            [111.970263, 27.962716],
                            [111.964151, 27.965153],
                            [111.962236, 27.96835],
                            [111.959654, 27.966325],
                            [111.956071, 27.965708],
                            [111.950626, 27.967404],
                            [111.946815, 27.969409],
                            [111.945199, 27.968761],
                            [111.943706, 27.961462],
                            [111.942037, 27.958964],
                            [111.936803, 27.954224],
                            [111.937031, 27.951448],
                            [111.941089, 27.943808],
                            [111.941721, 27.939664],
                            [111.944443, 27.930194],
                            [111.94685, 27.919354],
                            [111.944724, 27.912298],
                            [111.940649, 27.903751],
                            [111.937277, 27.894853],
                            [111.935099, 27.887785],
                            [111.935538, 27.879431],
                            [111.934713, 27.876859],
                            [111.930919, 27.873608],
                            [111.928407, 27.867856],
                            [111.923103, 27.862722],
                            [111.922839, 27.858163],
                            [111.926247, 27.855261],
                            [111.936733, 27.852822],
                            [111.94209, 27.853501],
                            [111.945181, 27.856588],
                            [111.956493, 27.855611],
                            [111.960638, 27.856084],
                            [111.963431, 27.853604],
                            [111.962921, 27.851474],
                            [111.959795, 27.849169],
                            [111.959707, 27.846174],
                            [111.961534, 27.843426],
                            [111.963167, 27.839052],
                            [111.964309, 27.838465],
                            [111.970913, 27.839906],
                            [111.975128, 27.83829],
                            [111.975972, 27.834543],
                            [111.975005, 27.832083],
                            [111.972318, 27.829098],
                            [111.967295, 27.825588],
                            [111.956001, 27.820359],
                            [111.956844, 27.81656],
                            [111.962833, 27.812648],
                            [111.968981, 27.809642],
                            [111.97079, 27.805987],
                            [111.968647, 27.802487],
                            [111.965538, 27.800798],
                            [111.953032, 27.797514],
                            [111.949502, 27.798327],
                            [111.945251, 27.803208],
                            [111.937997, 27.807202],
                            [111.935889, 27.809169],
                            [111.926738, 27.807233],
                            [111.922049, 27.803496],
                            [111.920854, 27.80155],
                            [111.923788, 27.797874],
                            [111.925579, 27.789318],
                            [111.925649, 27.78316],
                            [111.924631, 27.780987],
                            [111.92045, 27.781306],
                            [111.919449, 27.784035],
                            [111.915866, 27.783211],
                            [111.910122, 27.777589],
                            [111.904607, 27.767001],
                            [111.904888, 27.762078],
                            [111.907716, 27.758401],
                            [111.907558, 27.751181],
                            [111.906416, 27.748894],
                            [111.894209, 27.748049],
                            [111.892418, 27.745278],
                            [111.893296, 27.742662],
                            [111.901569, 27.732535],
                            [111.904818, 27.72456],
                            [111.903624, 27.716287],
                            [111.901323, 27.713237],
                            [111.893208, 27.706827],
                            [111.890608, 27.702375],
                            [111.887008, 27.698098],
                            [111.884496, 27.701695],
                            [111.880386, 27.700675],
                            [111.880017, 27.696264],
                            [111.877628, 27.691966],
                            [111.880685, 27.690225],
                            [111.881334, 27.687658],
                            [111.879648, 27.686205],
                            [111.879771, 27.682855],
                            [111.884443, 27.687081],
                            [111.8849, 27.684473],
                            [111.888079, 27.682577],
                            [111.886077, 27.680361],
                            [111.881738, 27.672785],
                            [111.875415, 27.663528],
                            [111.875363, 27.661806],
                            [111.872025, 27.654868],
                            [111.867985, 27.649104],
                            [111.865193, 27.642712],
                            [111.867845, 27.642897],
                            [111.868548, 27.639608],
                            [111.869988, 27.638391],
                            [111.867318, 27.637577],
                            [111.87004, 27.636607],
                            [111.871182, 27.63273],
                            [111.873975, 27.628265],
                            [111.87322, 27.626192],
                            [111.874151, 27.623109],
                            [111.877874, 27.621345],
                            [111.87733, 27.62051],
                            [111.880245, 27.615364],
                            [111.878507, 27.612826],
                            [111.882476, 27.614136],
                            [111.884654, 27.612156],
                            [111.89175, 27.612012],
                            [111.893278, 27.613662],
                            [111.893717, 27.616869],
                            [111.895175, 27.61558],
                            [111.900339, 27.615652],
                            [111.905099, 27.613817],
                            [111.906715, 27.610877],
                            [111.907962, 27.611352],
                            [111.913477, 27.609144],
                            [111.915199, 27.606071],
                            [111.911844, 27.604689],
                            [111.911159, 27.60243],
                            [111.914847, 27.597953],
                            [111.917587, 27.597819],
                            [111.91764, 27.594735],
                            [111.923401, 27.596591],
                            [111.931235, 27.598283],
                            [111.938507, 27.598758],
                            [111.945831, 27.600274],
                            [111.949221, 27.601945],
                            [111.953384, 27.6021],
                            [111.956932, 27.60342],
                            [111.961147, 27.603141],
                            [111.9612, 27.604781],
                            [111.970825, 27.610011],
                            [111.977412, 27.612362],
                            [111.978729, 27.612104],
                            [111.98384, 27.604936],
                            [111.98918, 27.606267],
                            [111.99185, 27.606772],
                            [111.996698, 27.605462],
                            [111.998138, 27.603152],
                            [112.001387, 27.602956],
                            [112.003671, 27.597582],
                            [112.00548, 27.599696],
                            [112.007851, 27.599542],
                            [112.01161, 27.597221],
                            [112.013682, 27.598397],
                            [112.018407, 27.59885],
                            [112.023167, 27.596107],
                            [112.027049, 27.591073],
                            [112.030369, 27.595632],
                            [112.033232, 27.595147],
                            [112.03418, 27.597509],
                            [112.03685, 27.599376],
                            [112.036692, 27.604049],
                            [112.038237, 27.6054],
                            [112.038835, 27.608732],
                            [112.040591, 27.610846],
                            [112.040538, 27.613497],
                            [112.037219, 27.613992],
                            [112.037587, 27.616704],
                            [112.034057, 27.620644],
                            [112.033495, 27.624016],
                            [112.038167, 27.625646],
                            [112.043015, 27.625852],
                            [112.048917, 27.625233],
                            [112.050743, 27.626935],
                            [112.049602, 27.630864],
                            [112.045175, 27.631183],
                            [112.043911, 27.633617],
                            [112.045685, 27.636381],
                            [112.043507, 27.638402],
                            [112.049338, 27.634865],
                            [112.053554, 27.629998],
                            [112.058015, 27.629843],
                            [112.063249, 27.631751],
                            [112.073981, 27.632277],
                            [112.074209, 27.628729],
                            [112.073436, 27.620046],
                            [112.067447, 27.61888],
                            [112.064057, 27.613022],
                            [112.064566, 27.609031],
                            [112.066393, 27.607463],
                            [112.070644, 27.599026],
                            [112.072277, 27.594539],
                            [112.068237, 27.593847],
                            [112.066428, 27.59001],
                            [112.064584, 27.591],
                            [112.061967, 27.589515],
                            [112.060983, 27.587575],
                            [112.057769, 27.587163],
                            [112.053993, 27.58317],
                            [112.05394, 27.576279],
                            [112.057681, 27.57081],
                            [112.066604, 27.564227],
                            [112.073981, 27.556767],
                            [112.077687, 27.55137],
                            [112.080427, 27.553114],
                            [112.082307, 27.551855],
                            [112.086136, 27.552691],
                            [112.088366, 27.551473],
                            [112.097465, 27.550029],
                            [112.105667, 27.547934],
                            [112.115011, 27.547665],
                            [112.118665, 27.548739],
                            [112.121159, 27.546293],
                            [112.121616, 27.542381],
                            [112.124233, 27.539832],
                            [112.125392, 27.540328],
                            [112.126428, 27.544012],
                            [112.131487, 27.547892],
                            [112.132558, 27.55136],
                            [112.134157, 27.552918],
                            [112.132435, 27.55623],
                            [112.134016, 27.56235],
                            [112.139004, 27.56528],
                            [112.140427, 27.564413],
                            [112.149771, 27.563794],
                            [112.154233, 27.561937],
                            [112.158448, 27.565775],
                            [112.157623, 27.566539],
                            [112.160802, 27.57046],
                            [112.163682, 27.572131],
                            [112.164034, 27.574721],
                            [112.167968, 27.574494],
                            [112.172008, 27.570718],
                            [112.175064, 27.5695],
                            [112.180246, 27.569851],
                            [112.18411, 27.566497],
                            [112.190328, 27.566879],
                            [112.19038, 27.574226],
                            [112.187921, 27.575474],
                            [112.189045, 27.579663],
                            [112.191188, 27.580457],
                            [112.190029, 27.58544],
                            [112.18685, 27.587534],
                            [112.180544, 27.589628],
                            [112.176944, 27.592558],
                            [112.177119, 27.59393],
                            [112.17466, 27.59621],
                            [112.172693, 27.599789],
                            [112.170111, 27.601646],
                            [112.169865, 27.603853],
                            [112.166089, 27.608835],
                            [112.1663, 27.616704],
                            [112.163718, 27.618798],
                            [112.163893, 27.621376],
                            [112.162049, 27.622748],
                            [112.162014, 27.625409],
                            [112.163981, 27.628337],
                            [112.161627, 27.631153],
                            [112.161153, 27.63404],
                            [112.157939, 27.637505],
                            [112.159309, 27.642598],
                            [112.157711, 27.644042],
                            [112.154865, 27.649486]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 431321,
                "name": "双峰县",
                "center": [112.198245, 27.459126],
                "centroid": [112.184985, 27.448684],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431300
                },
                "subFeatureIndex": 1,
                "acroutes": [100000, 430000, 431300]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.154865, 27.649486],
                            [112.157711, 27.644042],
                            [112.159309, 27.642598],
                            [112.157939, 27.637505],
                            [112.161153, 27.63404],
                            [112.161627, 27.631153],
                            [112.163981, 27.628337],
                            [112.162014, 27.625409],
                            [112.162049, 27.622748],
                            [112.163893, 27.621376],
                            [112.163718, 27.618798],
                            [112.1663, 27.616704],
                            [112.166089, 27.608835],
                            [112.169865, 27.603853],
                            [112.170111, 27.601646],
                            [112.172693, 27.599789],
                            [112.17466, 27.59621],
                            [112.177119, 27.59393],
                            [112.176944, 27.592558],
                            [112.180544, 27.589628],
                            [112.18685, 27.587534],
                            [112.190029, 27.58544],
                            [112.191188, 27.580457],
                            [112.189045, 27.579663],
                            [112.187921, 27.575474],
                            [112.19038, 27.574226],
                            [112.190328, 27.566879],
                            [112.18411, 27.566497],
                            [112.180246, 27.569851],
                            [112.175064, 27.5695],
                            [112.172008, 27.570718],
                            [112.167968, 27.574494],
                            [112.164034, 27.574721],
                            [112.163682, 27.572131],
                            [112.160802, 27.57046],
                            [112.157623, 27.566539],
                            [112.158448, 27.565775],
                            [112.154233, 27.561937],
                            [112.149771, 27.563794],
                            [112.140427, 27.564413],
                            [112.139004, 27.56528],
                            [112.134016, 27.56235],
                            [112.132435, 27.55623],
                            [112.134157, 27.552918],
                            [112.132558, 27.55136],
                            [112.131487, 27.547892],
                            [112.126428, 27.544012],
                            [112.125392, 27.540328],
                            [112.124233, 27.539832],
                            [112.121616, 27.542381],
                            [112.121159, 27.546293],
                            [112.118665, 27.548739],
                            [112.115011, 27.547665],
                            [112.105667, 27.547934],
                            [112.097465, 27.550029],
                            [112.088366, 27.551473],
                            [112.086136, 27.552691],
                            [112.082307, 27.551855],
                            [112.080427, 27.553114],
                            [112.077687, 27.55137],
                            [112.073981, 27.556767],
                            [112.066604, 27.564227],
                            [112.057681, 27.57081],
                            [112.05394, 27.576279],
                            [112.053993, 27.58317],
                            [112.057769, 27.587163],
                            [112.060983, 27.587575],
                            [112.061967, 27.589515],
                            [112.064584, 27.591],
                            [112.066428, 27.59001],
                            [112.068237, 27.593847],
                            [112.072277, 27.594539],
                            [112.070644, 27.599026],
                            [112.066393, 27.607463],
                            [112.064566, 27.609031],
                            [112.064057, 27.613022],
                            [112.067447, 27.61888],
                            [112.073436, 27.620046],
                            [112.074209, 27.628729],
                            [112.073981, 27.632277],
                            [112.063249, 27.631751],
                            [112.058015, 27.629843],
                            [112.053554, 27.629998],
                            [112.049338, 27.634865],
                            [112.043507, 27.638402],
                            [112.045685, 27.636381],
                            [112.043911, 27.633617],
                            [112.045175, 27.631183],
                            [112.049602, 27.630864],
                            [112.050743, 27.626935],
                            [112.048917, 27.625233],
                            [112.043015, 27.625852],
                            [112.038167, 27.625646],
                            [112.033495, 27.624016],
                            [112.034057, 27.620644],
                            [112.037587, 27.616704],
                            [112.037219, 27.613992],
                            [112.040538, 27.613497],
                            [112.040591, 27.610846],
                            [112.038835, 27.608732],
                            [112.038237, 27.6054],
                            [112.036692, 27.604049],
                            [112.03685, 27.599376],
                            [112.03418, 27.597509],
                            [112.033232, 27.595147],
                            [112.030369, 27.595632],
                            [112.027049, 27.591073],
                            [112.023167, 27.596107],
                            [112.018407, 27.59885],
                            [112.013682, 27.598397],
                            [112.01161, 27.597221],
                            [112.007851, 27.599542],
                            [112.00548, 27.599696],
                            [112.003671, 27.597582],
                            [112.001387, 27.602956],
                            [111.998138, 27.603152],
                            [111.996698, 27.605462],
                            [111.99185, 27.606772],
                            [111.98918, 27.606267],
                            [111.993571, 27.601398],
                            [111.998313, 27.594394],
                            [111.998928, 27.590546],
                            [111.99682, 27.58708],
                            [111.998155, 27.585048],
                            [112.000491, 27.585223],
                            [112.003425, 27.581458],
                            [112.011206, 27.577042],
                            [112.009028, 27.575175],
                            [112.007377, 27.576372],
                            [112.006639, 27.570841],
                            [112.005041, 27.568272],
                            [111.999683, 27.569521],
                            [111.999174, 27.571811],
                            [111.994203, 27.567178],
                            [111.991762, 27.567127],
                            [111.990164, 27.565507],
                            [111.990515, 27.562081],
                            [111.988934, 27.560616],
                            [111.990462, 27.55557],
                            [111.998647, 27.541556],
                            [112.004303, 27.536726],
                            [112.013366, 27.537396],
                            [112.026083, 27.540121],
                            [112.032529, 27.537799],
                            [112.035023, 27.527467],
                            [112.031106, 27.521057],
                            [112.022535, 27.516495],
                            [112.015, 27.513067],
                            [112.001299, 27.514709],
                            [111.992535, 27.518167],
                            [111.982909, 27.513831],
                            [111.969947, 27.509041],
                            [111.961165, 27.509754],
                            [111.950521, 27.503363],
                            [111.94685, 27.49626],
                            [111.94216, 27.490767],
                            [111.92247, 27.47914],
                            [111.916252, 27.476414],
                            [111.904379, 27.473946],
                            [111.894543, 27.468483],
                            [111.883196, 27.465106],
                            [111.873641, 27.460107],
                            [111.869478, 27.45485],
                            [111.869162, 27.449851],
                            [111.875573, 27.445017],
                            [111.882511, 27.438344],
                            [111.892786, 27.426846],
                            [111.905134, 27.417651],
                            [111.912863, 27.410294],
                            [111.913091, 27.40272],
                            [111.910772, 27.399051],
                            [111.907295, 27.395403],
                            [111.907646, 27.391704],
                            [111.909455, 27.388944],
                            [111.914338, 27.385038],
                            [111.918448, 27.383436],
                            [111.923612, 27.379984],
                            [111.929777, 27.376769],
                            [111.94086, 27.369193],
                            [111.949063, 27.366061],
                            [111.953507, 27.365523],
                            [111.958916, 27.362081],
                            [111.965556, 27.359993],
                            [111.970491, 27.357532],
                            [111.975849, 27.350978],
                            [111.985333, 27.345654],
                            [111.990287, 27.341559],
                            [111.985685, 27.332719],
                            [111.988249, 27.326525],
                            [111.988495, 27.321706],
                            [111.990813, 27.317342],
                            [111.994502, 27.314881],
                            [112.000404, 27.318625],
                            [112.018038, 27.317539],
                            [112.019988, 27.312554],
                            [112.025134, 27.303608],
                            [112.031809, 27.295809],
                            [112.037219, 27.291455],
                            [112.041083, 27.289158],
                            [112.051288, 27.292396],
                            [112.0587, 27.289541],
                            [112.062406, 27.285848],
                            [112.063653, 27.268168],
                            [112.065287, 27.257894],
                            [112.065199, 27.250806],
                            [112.066709, 27.242548],
                            [112.069256, 27.235884],
                            [112.067939, 27.22601],
                            [112.068431, 27.218662],
                            [112.073296, 27.211313],
                            [112.07637, 27.209429],
                            [112.076862, 27.20799],
                            [112.085328, 27.203984],
                            [112.086768, 27.205681],
                            [112.089525, 27.204853],
                            [112.09216, 27.205681],
                            [112.094584, 27.208652],
                            [112.097148, 27.207493],
                            [112.098027, 27.209522],
                            [112.09959, 27.206654],
                            [112.100855, 27.208487],
                            [112.107195, 27.214149],
                            [112.109426, 27.210971],
                            [112.114203, 27.210733],
                            [112.11466, 27.212141],
                            [112.111832, 27.214842],
                            [112.112623, 27.222326],
                            [112.115029, 27.221912],
                            [112.118647, 27.223175],
                            [112.125989, 27.2201],
                            [112.12952, 27.220897],
                            [112.130187, 27.222108],
                            [112.127851, 27.226073],
                            [112.127956, 27.227646],
                            [112.132804, 27.229602],
                            [112.134596, 27.234694],
                            [112.137933, 27.236981],
                            [112.146153, 27.238285],
                            [112.152318, 27.238461],
                            [112.154092, 27.239392],
                            [112.15685, 27.24377],
                            [112.160064, 27.247516],
                            [112.155761, 27.248902],
                            [112.15072, 27.254624],
                            [112.149719, 27.259353],
                            [112.150527, 27.265851],
                            [112.155937, 27.268944],
                            [112.15894, 27.271748],
                            [112.16305, 27.277303],
                            [112.162822, 27.284193],
                            [112.161188, 27.287752],
                            [112.158396, 27.290069],
                            [112.153706, 27.300195],
                            [112.155304, 27.302419],
                            [112.157956, 27.303029],
                            [112.160872, 27.305046],
                            [112.161083, 27.308355],
                            [112.15952, 27.308841],
                            [112.153671, 27.305666],
                            [112.15281, 27.307724],
                            [112.153794, 27.309689],
                            [112.159239, 27.316401],
                            [112.159116, 27.32152],
                            [112.160099, 27.322927],
                            [112.163893, 27.32455],
                            [112.174836, 27.319628],
                            [112.177629, 27.31756],
                            [112.180228, 27.314302],
                            [112.182389, 27.309793],
                            [112.185901, 27.309276],
                            [112.190626, 27.311789],
                            [112.191856, 27.311148],
                            [112.191996, 27.307921],
                            [112.194701, 27.305521],
                            [112.195456, 27.303153],
                            [112.192804, 27.30066],
                            [112.193911, 27.297971],
                            [112.19767, 27.295809],
                            [112.205363, 27.296533],
                            [112.208577, 27.291248],
                            [112.206223, 27.285124],
                            [112.207084, 27.282672],
                            [112.209701, 27.281783],
                            [112.212564, 27.277655],
                            [112.217553, 27.275565],
                            [112.214022, 27.27481],
                            [112.213952, 27.268903],
                            [112.216622, 27.266316],
                            [112.220732, 27.264226],
                            [112.22377, 27.261205],
                            [112.226598, 27.260864],
                            [112.230849, 27.263026],
                            [112.233062, 27.265364],
                            [112.233202, 27.269461],
                            [112.236575, 27.273207],
                            [112.235925, 27.277779],
                            [112.23798, 27.281069],
                            [112.237242, 27.284938],
                            [112.239034, 27.287834],
                            [112.244128, 27.2898],
                            [112.248483, 27.293854],
                            [112.25493, 27.295685],
                            [112.255843, 27.297806],
                            [112.255913, 27.303153],
                            [112.25377, 27.305263],
                            [112.254245, 27.308179],
                            [112.256106, 27.310672],
                            [112.257529, 27.310351],
                            [112.265135, 27.30548],
                            [112.26726, 27.305066],
                            [112.267998, 27.308841],
                            [112.272968, 27.311944],
                            [112.279854, 27.312203],
                            [112.281276, 27.312989],
                            [112.280152, 27.315202],
                            [112.280784, 27.319876],
                            [112.286387, 27.32424],
                            [112.287828, 27.328935],
                            [112.28572, 27.333774],
                            [112.282576, 27.337124],
                            [112.283402, 27.33974],
                            [112.286616, 27.343731],
                            [112.292149, 27.348414],
                            [112.296013, 27.353976],
                            [112.298788, 27.356478],
                            [112.299719, 27.358752],
                            [112.30346, 27.359827],
                            [112.30743, 27.359],
                            [112.309116, 27.360406],
                            [112.308361, 27.362939],
                            [112.309362, 27.365523],
                            [112.312119, 27.367084],
                            [112.321534, 27.364014],
                            [112.325328, 27.367766],
                            [112.331054, 27.370185],
                            [112.335462, 27.368872],
                            [112.339256, 27.365926],
                            [112.345703, 27.365492],
                            [112.352078, 27.365833],
                            [112.35647, 27.363115],
                            [112.358753, 27.362474],
                            [112.361071, 27.363559],
                            [112.360299, 27.366009],
                            [112.362617, 27.367404],
                            [112.368097, 27.367353],
                            [112.370521, 27.363869],
                            [112.368132, 27.356095],
                            [112.382957, 27.358783],
                            [112.391634, 27.35929],
                            [112.40853, 27.36145],
                            [112.415504, 27.358256],
                            [112.418753, 27.357429],
                            [112.42418, 27.354855],
                            [112.432365, 27.354214],
                            [112.435334, 27.348352],
                            [112.438109, 27.344599],
                            [112.440427, 27.344827],
                            [112.449649, 27.349128],
                            [112.451897, 27.34981],
                            [112.454409, 27.347411],
                            [112.456007, 27.347866],
                            [112.456394, 27.350854],
                            [112.455023, 27.354338],
                            [112.456183, 27.356292],
                            [112.459924, 27.355165],
                            [112.465281, 27.361357],
                            [112.464947, 27.364551],
                            [112.468004, 27.369989],
                            [112.47185, 27.372418],
                            [112.47394, 27.36757],
                            [112.477541, 27.36757],
                            [112.481353, 27.36206],
                            [112.48267, 27.36237],
                            [112.485726, 27.359848],
                            [112.488396, 27.360985],
                            [112.489801, 27.363631],
                            [112.488835, 27.370299],
                            [112.48959, 27.371922],
                            [112.495826, 27.374392],
                            [112.499901, 27.37494],
                            [112.501358, 27.378485],
                            [112.499303, 27.383074],
                            [112.499391, 27.385554],
                            [112.496704, 27.388024],
                            [112.498987, 27.390898],
                            [112.497442, 27.394122],
                            [112.49421, 27.395052],
                            [112.493138, 27.400271],
                            [112.495492, 27.400756],
                            [112.496265, 27.399103],
                            [112.499005, 27.397708],
                            [112.501394, 27.400105],
                            [112.504889, 27.401294],
                            [112.504538, 27.40397],
                            [112.507102, 27.404197],
                            [112.51281, 27.403143],
                            [112.513232, 27.405634],
                            [112.509368, 27.410759],
                            [112.506575, 27.41358],
                            [112.50712, 27.416307],
                            [112.512477, 27.418673],
                            [112.516552, 27.419655],
                            [112.520855, 27.4198],
                            [112.524052, 27.422796],
                            [112.524139, 27.425792],
                            [112.522488, 27.428705],
                            [112.518466, 27.432022],
                            [112.513531, 27.435028],
                            [112.509456, 27.438509],
                            [112.50798, 27.440709],
                            [112.500954, 27.445833],
                            [112.495316, 27.448632],
                            [112.489678, 27.45075],
                            [112.480966, 27.453115],
                            [112.476856, 27.455212],
                            [112.473027, 27.458228],
                            [112.470234, 27.461915],
                            [112.468495, 27.471344],
                            [112.4637, 27.483312],
                            [112.460135, 27.490457],
                            [112.455287, 27.494608],
                            [112.450386, 27.493937],
                            [112.446364, 27.491469],
                            [112.443642, 27.492812],
                            [112.441991, 27.495362],
                            [112.437793, 27.495083],
                            [112.435123, 27.497675],
                            [112.4301, 27.49849],
                            [112.430293, 27.500442],
                            [112.433384, 27.502001],
                            [112.43428, 27.503993],
                            [112.432348, 27.506946],
                            [112.432787, 27.512727],
                            [112.434631, 27.513852],
                            [112.436423, 27.511715],
                            [112.437529, 27.512345],
                            [112.436001, 27.514575],
                            [112.432629, 27.521697],
                            [112.430205, 27.524422],
                            [112.427096, 27.526095],
                            [112.419947, 27.527818],
                            [112.417576, 27.527705],
                            [112.410603, 27.525093],
                            [112.406001, 27.524092],
                            [112.407266, 27.520882],
                            [112.40623, 27.517599],
                            [112.402313, 27.51474],
                            [112.401838, 27.512593],
                            [112.399608, 27.51027],
                            [112.402207, 27.508246],
                            [112.402734, 27.505149],
                            [112.401206, 27.503766],
                            [112.395427, 27.503518],
                            [112.392933, 27.502186],
                            [112.391932, 27.499698],
                            [112.39404, 27.497633],
                            [112.394145, 27.493287],
                            [112.388893, 27.490943],
                            [112.384186, 27.490932],
                            [112.381569, 27.487752],
                            [112.378759, 27.486678],
                            [112.373753, 27.486575],
                            [112.370697, 27.484262],
                            [112.369608, 27.482166],
                            [112.365814, 27.480927],
                            [112.360386, 27.482352],
                            [112.356592, 27.485047],
                            [112.35784, 27.487339],
                            [112.356522, 27.490199],
                            [112.353554, 27.487628],
                            [112.352096, 27.48895],
                            [112.348109, 27.488186],
                            [112.347512, 27.492388],
                            [112.346089, 27.49626],
                            [112.344473, 27.497664],
                            [112.338888, 27.493586],
                            [112.333864, 27.495734],
                            [112.328718, 27.490509],
                            [112.324977, 27.489239],
                            [112.324116, 27.491882],
                            [112.327155, 27.493792],
                            [112.326013, 27.495228],
                            [112.321622, 27.496467],
                            [112.315861, 27.492884],
                            [112.311452, 27.491222],
                            [112.306393, 27.492812],
                            [112.306235, 27.497912],
                            [112.305252, 27.501928],
                            [112.303969, 27.503425],
                            [112.301423, 27.503208],
                            [112.300843, 27.504685],
                            [112.30353, 27.508979],
                            [112.302793, 27.515906],
                            [112.304321, 27.519354],
                            [112.304198, 27.521542],
                            [112.306183, 27.525258],
                            [112.305533, 27.529212],
                            [112.308589, 27.534569],
                            [112.31298, 27.536643],
                            [112.314368, 27.538294],
                            [112.315685, 27.544002],
                            [112.325328, 27.556189],
                            [112.330299, 27.56399],
                            [112.332406, 27.569944],
                            [112.333724, 27.575918],
                            [112.334286, 27.581417],
                            [112.33353, 27.586007],
                            [112.331756, 27.591753],
                            [112.332318, 27.597953],
                            [112.33079, 27.602543],
                            [112.324854, 27.606917],
                            [112.319444, 27.608763],
                            [112.307851, 27.615673],
                            [112.29805, 27.620974],
                            [112.290322, 27.626955],
                            [112.283893, 27.633627],
                            [112.282119, 27.638453],
                            [112.281873, 27.643279],
                            [112.283437, 27.644877],
                            [112.288882, 27.646929],
                            [112.296399, 27.647826],
                            [112.306832, 27.649733],
                            [112.309854, 27.649403],
                            [112.313472, 27.651001],
                            [112.314543, 27.653754],
                            [112.315123, 27.664311],
                            [112.319549, 27.670269],
                            [112.320621, 27.67531],
                            [112.319092, 27.679907],
                            [112.315755, 27.685886],
                            [112.309063, 27.691173],
                            [112.30418, 27.697841],
                            [112.295416, 27.704282],
                            [112.290515, 27.705673],
                            [112.280152, 27.702035],
                            [112.273934, 27.700902],
                            [112.268507, 27.700912],
                            [112.263606, 27.704374],
                            [112.259584, 27.703694],
                            [112.256616, 27.704158],
                            [112.252664, 27.706023],
                            [112.248115, 27.710156],
                            [112.245076, 27.710557],
                            [112.243355, 27.707744],
                            [112.243144, 27.7012],
                            [112.241177, 27.696027],
                            [112.240861, 27.69109],
                            [112.23877, 27.687679],
                            [112.235837, 27.689039],
                            [112.233501, 27.688545],
                            [112.23062, 27.685844],
                            [112.231183, 27.684401],
                            [112.229918, 27.678743],
                            [112.222629, 27.671527],
                            [112.218079, 27.670641],
                            [112.216586, 27.675867],
                            [112.214092, 27.673867],
                            [112.207084, 27.670527],
                            [112.206223, 27.669084],
                            [112.207875, 27.665249],
                            [112.205679, 27.656662],
                            [112.206838, 27.65161],
                            [112.204906, 27.65163],
                            [112.199391, 27.65429],
                            [112.195228, 27.653579],
                            [112.186639, 27.650723],
                            [112.17928, 27.650507],
                            [112.175591, 27.65095],
                            [112.175767, 27.654311],
                            [112.172342, 27.656187],
                            [112.169689, 27.656569],
                            [112.166651, 27.660064],
                            [112.162558, 27.659074],
                            [112.160591, 27.656538],
                            [112.159414, 27.650249],
                            [112.156622, 27.649001],
                            [112.154865, 27.649486]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 431322,
                "name": "新化县",
                "center": [111.306747, 27.737456],
                "centroid": [111.238328, 27.867886],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431300
                },
                "subFeatureIndex": 2,
                "acroutes": [100000, 430000, 431300]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [111.684911, 28.002978],
                            [111.691059, 28.00789],
                            [111.691305, 28.009904],
                            [111.68442, 28.013501],
                            [111.683489, 28.014498],
                            [111.683067, 28.025954],
                            [111.685351, 28.03627],
                            [111.685474, 28.040482],
                            [111.684033, 28.04078],
                            [111.679379, 28.037913],
                            [111.674584, 28.037985],
                            [111.671931, 28.034544],
                            [111.670632, 28.029807],
                            [111.665116, 28.024454],
                            [111.664835, 28.017406],
                            [111.662974, 28.01421],
                            [111.658811, 28.015042],
                            [111.655702, 28.014744],
                            [111.652294, 28.015741],
                            [111.65124, 28.01868],
                            [111.651592, 28.021156],
                            [111.656088, 28.021752],
                            [111.66062, 28.019954],
                            [111.662851, 28.021084],
                            [111.663272, 28.023273],
                            [111.661551, 28.025821],
                            [111.659109, 28.026704],
                            [111.651504, 28.026232],
                            [111.645708, 28.022071],
                            [111.634273, 28.015309],
                            [111.632043, 28.015525],
                            [111.628319, 28.019255],
                            [111.621715, 28.016059],
                            [111.619958, 28.016902],
                            [111.61353, 28.023643],
                            [111.608717, 28.026437],
                            [111.603219, 28.034235],
                            [111.601709, 28.034533],
                            [111.597212, 28.029746],
                            [111.589835, 28.027126],
                            [111.587815, 28.023478],
                            [111.588992, 28.018618],
                            [111.592558, 28.016224],
                            [111.597546, 28.015659],
                            [111.600075, 28.013388],
                            [111.599777, 28.009699],
                            [111.597089, 28.0079],
                            [111.592769, 28.006451],
                            [111.587938, 28.001005],
                            [111.583793, 27.994468],
                            [111.580614, 27.991868],
                            [111.574273, 27.990049],
                            [111.570883, 27.987664],
                            [111.567968, 27.987037],
                            [111.561978, 27.982607],
                            [111.56038, 27.982278],
                            [111.551914, 27.975165],
                            [111.550245, 27.974826],
                            [111.541621, 27.978557],
                            [111.537774, 27.981765],
                            [111.534578, 27.983368],
                            [111.532066, 27.983121],
                            [111.529414, 27.985146],
                            [111.529519, 27.986575],
                            [111.536281, 27.992495],
                            [111.540005, 27.994191],
                            [111.54206, 27.994139],
                            [111.546838, 27.992156],
                            [111.551422, 27.991878],
                            [111.555725, 28.001149],
                            [111.558026, 28.003687],
                            [111.558395, 28.006266],
                            [111.55706, 28.00788],
                            [111.557113, 28.010356],
                            [111.561574, 28.014652],
                            [111.564244, 28.018125],
                            [111.568793, 28.019255],
                            [111.572973, 28.018382],
                            [111.575784, 28.018875],
                            [111.575942, 28.021259],
                            [111.57343, 28.028893],
                            [111.570146, 28.029828],
                            [111.568108, 28.032592],
                            [111.565702, 28.032448],
                            [111.561732, 28.029345],
                            [111.557991, 28.028348],
                            [111.554478, 28.025769],
                            [111.549946, 28.024105],
                            [111.547487, 28.021505],
                            [111.544379, 28.020118],
                            [111.541656, 28.025245],
                            [111.540743, 28.030937],
                            [111.54069, 28.03555],
                            [111.542253, 28.039732],
                            [111.545432, 28.04304],
                            [111.543939, 28.047529],
                            [111.537897, 28.052542],
                            [111.533366, 28.053918],
                            [111.529923, 28.050241],
                            [111.527886, 28.049388],
                            [111.524794, 28.052603],
                            [111.521492, 28.060359],
                            [111.518208, 28.064159],
                            [111.514712, 28.065309],
                            [111.51206, 28.064251],
                            [111.508055, 28.059527],
                            [111.507001, 28.056928],
                            [111.506896, 28.053343],
                            [111.50867, 28.052408],
                            [111.51603, 28.050621],
                            [111.518067, 28.049111],
                            [111.522019, 28.044478],
                            [111.523442, 28.03967],
                            [111.522405, 28.034163],
                            [111.524425, 28.031205],
                            [111.52367, 28.029448],
                            [111.521194, 28.029366],
                            [111.517856, 28.030989],
                            [111.514589, 28.0344],
                            [111.507651, 28.039814],
                            [111.50196, 28.041643],
                            [111.494812, 28.037862],
                            [111.49158, 28.043512],
                            [111.494759, 28.049214],
                            [111.491949, 28.068781],
                            [111.493284, 28.074902],
                            [111.495216, 28.07712],
                            [111.510989, 28.090254],
                            [111.51127, 28.091599],
                            [111.507722, 28.092452],
                            [111.503541, 28.091579],
                            [111.500397, 28.092636],
                            [111.499501, 28.094372],
                            [111.500749, 28.096395],
                            [111.50045, 28.100368],
                            [111.497183, 28.104763],
                            [111.48264, 28.120419],
                            [111.481867, 28.122728],
                            [111.482727, 28.128302],
                            [111.480813, 28.134389],
                            [111.481393, 28.138351],
                            [111.486592, 28.144838],
                            [111.489016, 28.146326],
                            [111.489771, 28.150729],
                            [111.490983, 28.153007],
                            [111.490017, 28.155737],
                            [111.486451, 28.158159],
                            [111.486082, 28.161299],
                            [111.488928, 28.165198],
                            [111.489982, 28.168164],
                            [111.489437, 28.173643],
                            [111.490684, 28.17788],
                            [111.493617, 28.179081],
                            [111.494566, 28.180886],
                            [111.494742, 28.186396],
                            [111.495708, 28.189956],
                            [111.487768, 28.198122],
                            [111.485801, 28.203179],
                            [111.483149, 28.204194],
                            [111.477476, 28.199342],
                            [111.470222, 28.195885],
                            [111.458295, 28.1917],
                            [111.448266, 28.186847],
                            [111.446035, 28.187904],
                            [111.44528, 28.191043],
                            [111.445825, 28.194254],
                            [111.448459, 28.202891],
                            [111.44658, 28.205456],
                            [111.443893, 28.206923],
                            [111.436656, 28.208359],
                            [111.434864, 28.208112],
                            [111.426135, 28.200214],
                            [111.410573, 28.188314],
                            [111.398032, 28.180045],
                            [111.39148, 28.177696],
                            [111.388442, 28.177778],
                            [111.386176, 28.181635],
                            [111.386527, 28.191033],
                            [111.385596, 28.193875],
                            [111.382909, 28.196306],
                            [111.35946, 28.199168],
                            [111.352048, 28.199076],
                            [111.341843, 28.193146],
                            [111.338506, 28.19251],
                            [111.334132, 28.193003],
                            [111.33213, 28.195937],
                            [111.333974, 28.200912],
                            [111.337452, 28.204389],
                            [111.336785, 28.210349],
                            [111.332341, 28.21361],
                            [111.331024, 28.2204],
                            [111.328916, 28.222102],
                            [111.325983, 28.226533],
                            [111.321012, 28.227876],
                            [111.318992, 28.229168],
                            [111.314372, 28.234214],
                            [111.296035, 28.225466],
                            [111.289431, 28.220277],
                            [111.285198, 28.21481],
                            [111.284408, 28.211169],
                            [111.2836, 28.200491],
                            [111.281334, 28.194654],
                            [111.279068, 28.191012],
                            [111.274572, 28.187022],
                            [111.263734, 28.175079],
                            [111.260379, 28.172709],
                            [111.257762, 28.167538],
                            [111.248313, 28.16096],
                            [111.245134, 28.159883],
                            [111.237458, 28.154875],
                            [111.233646, 28.153828],
                            [111.230327, 28.153869],
                            [111.22706, 28.156548],
                            [111.225145, 28.161504],
                            [111.221018, 28.16253],
                            [111.216029, 28.159318],
                            [111.212271, 28.15977],
                            [111.206299, 28.159544],
                            [111.202768, 28.160539],
                            [111.193705, 28.16016],
                            [111.191299, 28.161525],
                            [111.187399, 28.161555],
                            [111.186521, 28.160611],
                            [111.188787, 28.158036],
                            [111.189542, 28.154977],
                            [111.185713, 28.149518],
                            [111.175859, 28.138433],
                            [111.171556, 28.13479],
                            [111.164232, 28.129647],
                            [111.162388, 28.12472],
                            [111.16093, 28.122831],
                            [111.158049, 28.122123],
                            [111.151585, 28.122975],
                            [111.150321, 28.122143],
                            [111.14932, 28.116528],
                            [111.145596, 28.11043],
                            [111.14391, 28.106652],
                            [111.140941, 28.103428],
                            [111.140344, 28.094485],
                            [111.138254, 28.092215],
                            [111.139044, 28.089782],
                            [111.137885, 28.085972],
                            [111.127013, 28.074974],
                            [111.123289, 28.06877],
                            [111.120391, 28.066798],
                            [111.114191, 28.064118],
                            [111.113752, 28.060472],
                            [111.118371, 28.052521],
                            [111.118459, 28.050169],
                            [111.113383, 28.049542],
                            [111.110994, 28.04758],
                            [111.110098, 28.042485],
                            [111.101281, 28.033198],
                            [111.102546, 28.031256],
                            [111.099999, 28.029653],
                            [111.093781, 28.027157],
                            [111.092938, 28.025811],
                            [111.095537, 28.021824],
                            [111.094361, 28.014302],
                            [111.095256, 28.011322],
                            [111.097891, 28.006791],
                            [111.097224, 28.002772],
                            [111.093816, 28.000635],
                            [111.085368, 27.999802],
                            [111.081978, 27.995609],
                            [111.078693, 27.994386],
                            [111.074407, 27.994129],
                            [111.070613, 27.99526],
                            [111.068102, 28.000583],
                            [111.065116, 28.003307],
                            [111.063219, 28.007222],
                            [111.061164, 28.009483],
                            [111.056105, 28.013367],
                            [111.051732, 28.014539],
                            [111.048289, 28.018238],
                            [111.041544, 28.022297],
                            [111.040034, 28.024197],
                            [111.037891, 28.030896],
                            [111.035028, 28.033701],
                            [111.029636, 28.033187],
                            [111.024507, 28.028708],
                            [111.022013, 28.027526],
                            [111.017674, 28.027383],
                            [111.013213, 28.028924],
                            [111.0084, 28.029581],
                            [110.995262, 28.030105],
                            [110.987639, 28.029119],
                            [110.980403, 28.029098],
                            [110.978506, 28.028256],
                            [110.977663, 28.025441],
                            [110.97624, 28.014498],
                            [110.974466, 28.01088],
                            [110.968986, 28.006677],
                            [110.968986, 28.004406],
                            [110.971129, 28.002762],
                            [110.98237, 27.998487],
                            [110.986884, 27.994777],
                            [110.990976, 27.992978],
                            [110.998301, 27.993554],
                            [111.000531, 27.992228],
                            [110.99593, 27.988322],
                            [110.996017, 27.986739],
                            [110.993892, 27.982803],
                            [110.991732, 27.980695],
                            [110.987428, 27.972894],
                            [110.982985, 27.967743],
                            [110.975168, 27.961431],
                            [110.972446, 27.957432],
                            [110.968178, 27.953227],
                            [110.966685, 27.949248],
                            [110.96802, 27.942379],
                            [110.969776, 27.938852],
                            [110.970338, 27.930543],
                            [110.969003, 27.928702],
                            [110.969302, 27.926029],
                            [110.970777, 27.925247],
                            [110.978295, 27.924095],
                            [110.98042, 27.922409],
                            [110.98042, 27.920002],
                            [110.977627, 27.914232],
                            [110.973939, 27.9109],
                            [110.963488, 27.905098],
                            [110.958921, 27.904245],
                            [110.957463, 27.903062],
                            [110.956866, 27.899935],
                            [110.954513, 27.897517],
                            [110.950104, 27.896602],
                            [110.947522, 27.897373],
                            [110.9448, 27.896231],
                            [110.941427, 27.892312],
                            [110.941568, 27.889966],
                            [110.937018, 27.887292],
                            [110.929677, 27.879102],
                            [110.929905, 27.875059],
                            [110.933576, 27.87474],
                            [110.932768, 27.873824],
                            [110.926954, 27.873032],
                            [110.923511, 27.870192],
                            [110.924091, 27.865181],
                            [110.922335, 27.863287],
                            [110.917557, 27.865829],
                            [110.914624, 27.865623],
                            [110.912797, 27.864255],
                            [110.909425, 27.858585],
                            [110.903769, 27.855549],
                            [110.898482, 27.856002],
                            [110.896743, 27.851309],
                            [110.89453, 27.849879],
                            [110.891966, 27.851896],
                            [110.890578, 27.850208],
                            [110.888154, 27.849745],
                            [110.882077, 27.850496],
                            [110.87665, 27.84639],
                            [110.873365, 27.845813],
                            [110.868377, 27.847841],
                            [110.862247, 27.847275],
                            [110.858628, 27.848633],
                            [110.845174, 27.849261],
                            [110.837165, 27.84923],
                            [110.828102, 27.852637],
                            [110.821093, 27.858111],
                            [110.812276, 27.862423],
                            [110.806568, 27.864234],
                            [110.799858, 27.862824],
                            [110.795221, 27.860972],
                            [110.791638, 27.855673],
                            [110.789899, 27.847172],
                            [110.785859, 27.832927],
                            [110.781275, 27.823035],
                            [110.776445, 27.811763],
                            [110.77388, 27.807614],
                            [110.768505, 27.800706],
                            [110.767768, 27.795424],
                            [110.774021, 27.789956],
                            [110.780748, 27.787927],
                            [110.785701, 27.782676],
                            [110.789108, 27.775354],
                            [110.790197, 27.767558],
                            [110.792569, 27.761605],
                            [110.795695, 27.758175],
                            [110.800613, 27.756825],
                            [110.806287, 27.758],
                            [110.81145, 27.757794],
                            [110.816123, 27.75391],
                            [110.81823, 27.749337],
                            [110.818265, 27.743599],
                            [110.8209, 27.736491],
                            [110.827188, 27.72252],
                            [110.830332, 27.714968],
                            [110.832739, 27.704189],
                            [110.833301, 27.696625],
                            [110.834635, 27.690431],
                            [110.837534, 27.681495],
                            [110.842188, 27.678526],
                            [110.849179, 27.675805],
                            [110.85227, 27.675815],
                            [110.860016, 27.677227],
                            [110.864407, 27.676331],
                            [110.868289, 27.672908],
                            [110.86987, 27.668094],
                            [110.871749, 27.658229],
                            [110.869185, 27.655466],
                            [110.871257, 27.653641],
                            [110.876491, 27.652919],
                            [110.877475, 27.651599],
                            [110.882903, 27.650713],
                            [110.889349, 27.651197],
                            [110.907932, 27.654981],
                            [110.917504, 27.656414],
                            [110.928886, 27.655569],
                            [110.938476, 27.653558],
                            [110.94573, 27.653837],
                            [110.957376, 27.656218],
                            [110.966966, 27.657218],
                            [110.975502, 27.659806],
                            [110.983793, 27.660105],
                            [110.990045, 27.656476],
                            [110.994735, 27.652383],
                            [110.996843, 27.646207],
                            [110.998441, 27.63934],
                            [110.994085, 27.630121],
                            [110.996948, 27.626924],
                            [111.008102, 27.625873],
                            [111.01172, 27.627966],
                            [111.017657, 27.632833],
                            [111.025754, 27.635659],
                            [111.028793, 27.635442],
                            [111.034519, 27.633875],
                            [111.037382, 27.63207],
                            [111.040227, 27.631854],
                            [111.05059, 27.632854],
                            [111.059934, 27.631307],
                            [111.06617, 27.625615],
                            [111.070859, 27.620592],
                            [111.073213, 27.612569],
                            [111.073775, 27.605462],
                            [111.071474, 27.599253],
                            [111.072019, 27.593971],
                            [111.074109, 27.591454],
                            [111.080309, 27.588741],
                            [111.084964, 27.589226],
                            [111.093236, 27.58904],
                            [111.098664, 27.590216],
                            [111.106937, 27.590711],
                            [111.111872, 27.584532],
                            [111.11319, 27.578796],
                            [111.116299, 27.577207],
                            [111.123781, 27.5786],
                            [111.13021, 27.581386],
                            [111.134847, 27.581396],
                            [111.143155, 27.578012],
                            [111.146404, 27.578971],
                            [111.147704, 27.581582],
                            [111.15011, 27.582159],
                            [111.15754, 27.578569],
                            [111.155239, 27.576588],
                            [111.154343, 27.573349],
                            [111.157399, 27.571172],
                            [111.162054, 27.572812],
                            [111.163459, 27.576444],
                            [111.167604, 27.576206],
                            [111.169203, 27.578198],
                            [111.170608, 27.577228],
                            [111.165936, 27.572234],
                            [111.166199, 27.569892],
                            [111.168605, 27.566301],
                            [111.169484, 27.562638],
                            [111.173875, 27.559976],
                            [111.176755, 27.560843],
                            [111.182148, 27.563732],
                            [111.182411, 27.558645],
                            [111.184571, 27.555343],
                            [111.187276, 27.553805],
                            [111.192563, 27.55298],
                            [111.19583, 27.551349],
                            [111.197393, 27.549182],
                            [111.19987, 27.549977],
                            [111.204156, 27.552774],
                            [111.20795, 27.553124],
                            [111.212253, 27.552],
                            [111.220789, 27.551752],
                            [111.223968, 27.549203],
                            [111.225005, 27.546344],
                            [111.22381, 27.536189],
                            [111.244642, 27.525104],
                            [111.247101, 27.525991],
                            [111.248225, 27.529903],
                            [111.251685, 27.530894],
                            [111.2523, 27.53238],
                            [111.252089, 27.538336],
                            [111.255637, 27.53783],
                            [111.256111, 27.542784],
                            [111.257657, 27.543878],
                            [111.261012, 27.542185],
                            [111.266018, 27.543568],
                            [111.268687, 27.541576],
                            [111.27069, 27.541772],
                            [111.276504, 27.551174],
                            [111.278383, 27.555776],
                            [111.292856, 27.563866],
                            [111.295772, 27.566074],
                            [111.300883, 27.567086],
                            [111.303711, 27.564207],
                            [111.3172, 27.553713],
                            [111.319273, 27.551422],
                            [111.326123, 27.548677],
                            [111.332675, 27.54974],
                            [111.337452, 27.551566],
                            [111.340333, 27.55556],
                            [111.340719, 27.557603],
                            [111.338734, 27.562762],
                            [111.339209, 27.565951],
                            [111.342072, 27.573844],
                            [111.350942, 27.586503],
                            [111.355631, 27.594539],
                            [111.356615, 27.597705],
                            [111.361287, 27.605503],
                            [111.361006, 27.60835],
                            [111.365766, 27.611341],
                            [111.363325, 27.613043],
                            [111.365766, 27.614951],
                            [111.367522, 27.613786],
                            [111.371369, 27.614869],
                            [111.37511, 27.61262],
                            [111.381592, 27.616354],
                            [111.382364, 27.620984],
                            [111.381469, 27.622954],
                            [111.381627, 27.627584],
                            [111.383243, 27.62908],
                            [111.386527, 27.628193],
                            [111.386685, 27.630297],
                            [111.388459, 27.631452],
                            [111.389302, 27.635463],
                            [111.391533, 27.636618],
                            [111.389777, 27.638598],
                            [111.392394, 27.642],
                            [111.396135, 27.639804],
                            [111.394835, 27.637092],
                            [111.397277, 27.636061],
                            [111.398049, 27.637896],
                            [111.402827, 27.637938],
                            [111.406744, 27.639783],
                            [111.408887, 27.64166],
                            [111.406375, 27.64632],
                            [111.407148, 27.649042],
                            [111.409607, 27.652496],
                            [111.409695, 27.655765],
                            [111.407271, 27.661631],
                            [111.408606, 27.66495],
                            [111.417388, 27.670269],
                            [111.413647, 27.672898],
                            [111.414964, 27.680309],
                            [111.419548, 27.681691],
                            [111.419829, 27.683515],
                            [111.416141, 27.683773],
                            [111.413348, 27.684937],
                            [111.406709, 27.683072],
                            [111.403143, 27.680948],
                            [111.396521, 27.681155],
                            [111.390497, 27.679526],
                            [111.386545, 27.684339],
                            [111.387792, 27.687916],
                            [111.393008, 27.692049],
                            [111.396785, 27.696109],
                            [111.399841, 27.700685],
                            [111.399806, 27.702818],
                            [111.402318, 27.70861],
                            [111.409431, 27.713051],
                            [111.410485, 27.714535],
                            [111.409905, 27.723973],
                            [111.410169, 27.730608],
                            [111.408395, 27.733483],
                            [111.405725, 27.734286],
                            [111.404285, 27.73647],
                            [111.405286, 27.738047],
                            [111.412329, 27.739911],
                            [111.425344, 27.737243],
                            [111.430438, 27.735801],
                            [111.437657, 27.735646],
                            [111.443489, 27.736975],
                            [111.450655, 27.742126],
                            [111.452692, 27.74534],
                            [111.449654, 27.753416],
                            [111.450936, 27.758247],
                            [111.455942, 27.760894],
                            [111.457786, 27.76591],
                            [111.460473, 27.770719],
                            [111.466234, 27.774005],
                            [111.468342, 27.771523],
                            [111.465883, 27.76765],
                            [111.467148, 27.76593],
                            [111.471205, 27.767331],
                            [111.473436, 27.769216],
                            [111.474841, 27.773191],
                            [111.478881, 27.773737],
                            [111.480708, 27.775055],
                            [111.48004, 27.783211],
                            [111.482007, 27.788813],
                            [111.482359, 27.792211],
                            [111.487891, 27.803486],
                            [111.492054, 27.808407],
                            [111.495216, 27.81062],
                            [111.500468, 27.810281],
                            [111.510567, 27.813709],
                            [111.513869, 27.815531],
                            [111.515994, 27.818269],
                            [111.51963, 27.821265],
                            [111.522616, 27.828121],
                            [111.522915, 27.831219],
                            [111.52562, 27.835439],
                            [111.531486, 27.836715],
                            [111.53795, 27.834307],
                            [111.541428, 27.833782],
                            [111.548717, 27.833946],
                            [111.551738, 27.833144],
                            [111.553916, 27.831456],
                            [111.556217, 27.825424],
                            [111.55973, 27.823602],
                            [111.567862, 27.825043],
                            [111.571445, 27.823241],
                            [111.571024, 27.82111],
                            [111.567985, 27.814151],
                            [111.569461, 27.807913],
                            [111.570497, 27.806368],
                            [111.571621, 27.800963],
                            [111.571463, 27.795352],
                            [111.569671, 27.784416],
                            [111.570795, 27.774252],
                            [111.572394, 27.771183],
                            [111.575116, 27.769422],
                            [111.580509, 27.768897],
                            [111.585585, 27.767475],
                            [111.59124, 27.767383],
                            [111.595544, 27.769669],
                            [111.597792, 27.772079],
                            [111.603202, 27.775467],
                            [111.607347, 27.77938],
                            [111.611931, 27.785569],
                            [111.622716, 27.802909],
                            [111.627581, 27.809313],
                            [111.633202, 27.815449],
                            [111.637593, 27.818897],
                            [111.648764, 27.826576],
                            [111.654701, 27.829716],
                            [111.658916, 27.834554],
                            [111.661604, 27.839237],
                            [111.661621, 27.843364],
                            [111.6599, 27.84851],
                            [111.655157, 27.854962],
                            [111.649256, 27.859861],
                            [111.644408, 27.861034],
                            [111.632798, 27.862094],
                            [111.628635, 27.865531],
                            [111.62716, 27.870655],
                            [111.626246, 27.876221],
                            [111.626457, 27.882868],
                            [111.624876, 27.893978],
                            [111.622804, 27.897754],
                            [111.61901, 27.899112],
                            [111.615392, 27.899297],
                            [111.606715, 27.905273],
                            [111.604115, 27.909089],
                            [111.604484, 27.914345],
                            [111.609964, 27.918912],
                            [111.613863, 27.920742],
                            [111.618395, 27.920537],
                            [111.621205, 27.921462],
                            [111.623471, 27.924651],
                            [111.624613, 27.930101],
                            [111.626211, 27.933392],
                            [111.640579, 27.947603],
                            [111.644109, 27.950461],
                            [111.644022, 27.952682],
                            [111.642002, 27.955951],
                            [111.635907, 27.962819],
                            [111.634062, 27.965883],
                            [111.626756, 27.97055],
                            [111.616762, 27.977961],
                            [111.607663, 27.984293],
                            [111.603202, 27.987788],
                            [111.600233, 27.99194],
                            [111.600567, 27.994797],
                            [111.608717, 28.006112],
                            [111.611562, 28.006595],
                            [111.618746, 28.004972],
                            [111.625965, 28.00715],
                            [111.631674, 28.012525],
                            [111.635661, 28.01274],
                            [111.639226, 28.008424],
                            [111.640526, 28.004427],
                            [111.648342, 28.00268],
                            [111.650959, 27.999956],
                            [111.653015, 27.994777],
                            [111.654068, 27.99377],
                            [111.663483, 27.988795],
                            [111.666803, 27.989391],
                            [111.679502, 27.999268],
                            [111.681803, 28.001642],
                            [111.684911, 28.002978]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 431381,
                "name": "冷水江市",
                "center": [111.434674, 27.685759],
                "centroid": [111.48582, 27.681492],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431300
                },
                "subFeatureIndex": 3,
                "acroutes": [100000, 430000, 431300]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [111.556972, 27.629204],
                            [111.560397, 27.632225],
                            [111.563295, 27.636071],
                            [111.568091, 27.64035],
                            [111.572482, 27.64067],
                            [111.574291, 27.642134],
                            [111.576293, 27.645939],
                            [111.579859, 27.646733],
                            [111.585304, 27.645702],
                            [111.587201, 27.647877],
                            [111.586147, 27.649445],
                            [111.588219, 27.650218],
                            [111.590731, 27.648527],
                            [111.593928, 27.647898],
                            [111.604888, 27.651991],
                            [111.605731, 27.656022],
                            [111.61295, 27.661394],
                            [111.613126, 27.663424],
                            [111.611194, 27.671702],
                            [111.608085, 27.675918],
                            [111.604607, 27.67532],
                            [111.603975, 27.678784],
                            [111.604888, 27.680804],
                            [111.602218, 27.683968],
                            [111.60466, 27.686391],
                            [111.60473, 27.688009],
                            [111.601305, 27.696346],
                            [111.601533, 27.700005],
                            [111.603904, 27.702396],
                            [111.608963, 27.705189],
                            [111.609139, 27.706992],
                            [111.606961, 27.710609],
                            [111.606644, 27.714896],
                            [111.601937, 27.719574],
                            [111.598214, 27.724179],
                            [111.594841, 27.725539],
                            [111.590766, 27.722252],
                            [111.587798, 27.721068],
                            [111.584935, 27.723334],
                            [111.582019, 27.728517],
                            [111.577505, 27.733215],
                            [111.574414, 27.737284],
                            [111.574343, 27.740045],
                            [111.579033, 27.751737],
                            [111.583143, 27.758854],
                            [111.587956, 27.764797],
                            [111.59124, 27.767383],
                            [111.585585, 27.767475],
                            [111.580509, 27.768897],
                            [111.575116, 27.769422],
                            [111.572394, 27.771183],
                            [111.570795, 27.774252],
                            [111.569671, 27.784416],
                            [111.571463, 27.795352],
                            [111.571621, 27.800963],
                            [111.570497, 27.806368],
                            [111.569461, 27.807913],
                            [111.567985, 27.814151],
                            [111.571024, 27.82111],
                            [111.571445, 27.823241],
                            [111.567862, 27.825043],
                            [111.55973, 27.823602],
                            [111.556217, 27.825424],
                            [111.553916, 27.831456],
                            [111.551738, 27.833144],
                            [111.548717, 27.833946],
                            [111.541428, 27.833782],
                            [111.53795, 27.834307],
                            [111.531486, 27.836715],
                            [111.52562, 27.835439],
                            [111.522915, 27.831219],
                            [111.522616, 27.828121],
                            [111.51963, 27.821265],
                            [111.515994, 27.818269],
                            [111.513869, 27.815531],
                            [111.510567, 27.813709],
                            [111.500468, 27.810281],
                            [111.495216, 27.81062],
                            [111.492054, 27.808407],
                            [111.487891, 27.803486],
                            [111.482359, 27.792211],
                            [111.482007, 27.788813],
                            [111.48004, 27.783211],
                            [111.480708, 27.775055],
                            [111.478881, 27.773737],
                            [111.474841, 27.773191],
                            [111.473436, 27.769216],
                            [111.471205, 27.767331],
                            [111.467148, 27.76593],
                            [111.465883, 27.76765],
                            [111.468342, 27.771523],
                            [111.466234, 27.774005],
                            [111.460473, 27.770719],
                            [111.457786, 27.76591],
                            [111.455942, 27.760894],
                            [111.450936, 27.758247],
                            [111.449654, 27.753416],
                            [111.452692, 27.74534],
                            [111.450655, 27.742126],
                            [111.443489, 27.736975],
                            [111.437657, 27.735646],
                            [111.430438, 27.735801],
                            [111.425344, 27.737243],
                            [111.412329, 27.739911],
                            [111.405286, 27.738047],
                            [111.404285, 27.73647],
                            [111.405725, 27.734286],
                            [111.408395, 27.733483],
                            [111.410169, 27.730608],
                            [111.409905, 27.723973],
                            [111.410485, 27.714535],
                            [111.409431, 27.713051],
                            [111.402318, 27.70861],
                            [111.399806, 27.702818],
                            [111.399841, 27.700685],
                            [111.396785, 27.696109],
                            [111.393008, 27.692049],
                            [111.387792, 27.687916],
                            [111.386545, 27.684339],
                            [111.390497, 27.679526],
                            [111.396521, 27.681155],
                            [111.403143, 27.680948],
                            [111.406709, 27.683072],
                            [111.413348, 27.684937],
                            [111.416141, 27.683773],
                            [111.419829, 27.683515],
                            [111.419548, 27.681691],
                            [111.414964, 27.680309],
                            [111.413647, 27.672898],
                            [111.417388, 27.670269],
                            [111.408606, 27.66495],
                            [111.407271, 27.661631],
                            [111.409695, 27.655765],
                            [111.409607, 27.652496],
                            [111.407148, 27.649042],
                            [111.406375, 27.64632],
                            [111.408887, 27.64166],
                            [111.406744, 27.639783],
                            [111.402827, 27.637938],
                            [111.398049, 27.637896],
                            [111.397277, 27.636061],
                            [111.394835, 27.637092],
                            [111.396135, 27.639804],
                            [111.392394, 27.642],
                            [111.389777, 27.638598],
                            [111.391533, 27.636618],
                            [111.389302, 27.635463],
                            [111.388459, 27.631452],
                            [111.386685, 27.630297],
                            [111.386527, 27.628193],
                            [111.383243, 27.62908],
                            [111.381627, 27.627584],
                            [111.381469, 27.622954],
                            [111.382364, 27.620984],
                            [111.381592, 27.616354],
                            [111.37511, 27.61262],
                            [111.371369, 27.614869],
                            [111.367522, 27.613786],
                            [111.365766, 27.614951],
                            [111.363325, 27.613043],
                            [111.365766, 27.611341],
                            [111.361006, 27.60835],
                            [111.361287, 27.605503],
                            [111.356615, 27.597705],
                            [111.355631, 27.594539],
                            [111.350942, 27.586503],
                            [111.342072, 27.573844],
                            [111.339209, 27.565951],
                            [111.338734, 27.562762],
                            [111.340719, 27.557603],
                            [111.340333, 27.55556],
                            [111.337452, 27.551566],
                            [111.332675, 27.54974],
                            [111.326123, 27.548677],
                            [111.319273, 27.551422],
                            [111.322136, 27.54685],
                            [111.335081, 27.540482],
                            [111.338278, 27.540462],
                            [111.339226, 27.538893],
                            [111.348026, 27.537995],
                            [111.3498, 27.537035],
                            [111.355666, 27.528675],
                            [111.355737, 27.526827],
                            [111.351135, 27.531152],
                            [111.350011, 27.529594],
                            [111.353348, 27.524918],
                            [111.357107, 27.522915],
                            [111.35795, 27.52563],
                            [111.360303, 27.526569],
                            [111.36278, 27.524484],
                            [111.367751, 27.524908],
                            [111.371035, 27.529078],
                            [111.375268, 27.531193],
                            [111.376533, 27.532824],
                            [111.377885, 27.539977],
                            [111.374618, 27.542949],
                            [111.376551, 27.545219],
                            [111.380274, 27.544074],
                            [111.382681, 27.545354],
                            [111.388407, 27.54491],
                            [111.389706, 27.547738],
                            [111.392376, 27.550658],
                            [111.396592, 27.553909],
                            [111.402915, 27.556447],
                            [111.407341, 27.562886],
                            [111.414384, 27.575195],
                            [111.425977, 27.58577],
                            [111.43035, 27.590598],
                            [111.44196, 27.597499],
                            [111.449952, 27.598644],
                            [111.45408, 27.597499],
                            [111.457944, 27.595426],
                            [111.460561, 27.591599],
                            [111.463617, 27.584873],
                            [111.468272, 27.57959],
                            [111.472874, 27.578002],
                            [111.477809, 27.578652],
                            [111.482411, 27.587844],
                            [111.485239, 27.591743],
                            [111.489367, 27.59426],
                            [111.508916, 27.601347],
                            [111.517927, 27.602945],
                            [111.522265, 27.605555],
                            [111.526726, 27.6054],
                            [111.525848, 27.607515],
                            [111.527411, 27.608835],
                            [111.531188, 27.605916],
                            [111.533752, 27.607226],
                            [111.54264, 27.609639],
                            [111.548963, 27.615786],
                            [111.551615, 27.62018],
                            [111.5536, 27.626058],
                            [111.556972, 27.629204]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 431382,
                "name": "涟源市",
                "center": [111.670847, 27.692301],
                "centroid": [111.782213, 27.742378],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431300
                },
                "subFeatureIndex": 4,
                "acroutes": [100000, 430000, 431300]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [111.684911, 28.002978],
                            [111.681803, 28.001642],
                            [111.679502, 27.999268],
                            [111.666803, 27.989391],
                            [111.663483, 27.988795],
                            [111.654068, 27.99377],
                            [111.653015, 27.994777],
                            [111.650959, 27.999956],
                            [111.648342, 28.00268],
                            [111.640526, 28.004427],
                            [111.639226, 28.008424],
                            [111.635661, 28.01274],
                            [111.631674, 28.012525],
                            [111.625965, 28.00715],
                            [111.618746, 28.004972],
                            [111.611562, 28.006595],
                            [111.608717, 28.006112],
                            [111.600567, 27.994797],
                            [111.600233, 27.99194],
                            [111.603202, 27.987788],
                            [111.607663, 27.984293],
                            [111.616762, 27.977961],
                            [111.626756, 27.97055],
                            [111.634062, 27.965883],
                            [111.635907, 27.962819],
                            [111.642002, 27.955951],
                            [111.644022, 27.952682],
                            [111.644109, 27.950461],
                            [111.640579, 27.947603],
                            [111.626211, 27.933392],
                            [111.624613, 27.930101],
                            [111.623471, 27.924651],
                            [111.621205, 27.921462],
                            [111.618395, 27.920537],
                            [111.613863, 27.920742],
                            [111.609964, 27.918912],
                            [111.604484, 27.914345],
                            [111.604115, 27.909089],
                            [111.606715, 27.905273],
                            [111.615392, 27.899297],
                            [111.61901, 27.899112],
                            [111.622804, 27.897754],
                            [111.624876, 27.893978],
                            [111.626457, 27.882868],
                            [111.626246, 27.876221],
                            [111.62716, 27.870655],
                            [111.628635, 27.865531],
                            [111.632798, 27.862094],
                            [111.644408, 27.861034],
                            [111.649256, 27.859861],
                            [111.655157, 27.854962],
                            [111.6599, 27.84851],
                            [111.661621, 27.843364],
                            [111.661604, 27.839237],
                            [111.658916, 27.834554],
                            [111.654701, 27.829716],
                            [111.648764, 27.826576],
                            [111.637593, 27.818897],
                            [111.633202, 27.815449],
                            [111.627581, 27.809313],
                            [111.622716, 27.802909],
                            [111.611931, 27.785569],
                            [111.607347, 27.77938],
                            [111.603202, 27.775467],
                            [111.597792, 27.772079],
                            [111.595544, 27.769669],
                            [111.59124, 27.767383],
                            [111.587956, 27.764797],
                            [111.583143, 27.758854],
                            [111.579033, 27.751737],
                            [111.574343, 27.740045],
                            [111.574414, 27.737284],
                            [111.577505, 27.733215],
                            [111.582019, 27.728517],
                            [111.584935, 27.723334],
                            [111.587798, 27.721068],
                            [111.590766, 27.722252],
                            [111.594841, 27.725539],
                            [111.598214, 27.724179],
                            [111.601937, 27.719574],
                            [111.606644, 27.714896],
                            [111.606961, 27.710609],
                            [111.609139, 27.706992],
                            [111.608963, 27.705189],
                            [111.603904, 27.702396],
                            [111.601533, 27.700005],
                            [111.601305, 27.696346],
                            [111.60473, 27.688009],
                            [111.60466, 27.686391],
                            [111.602218, 27.683968],
                            [111.604888, 27.680804],
                            [111.603975, 27.678784],
                            [111.604607, 27.67532],
                            [111.608085, 27.675918],
                            [111.611194, 27.671702],
                            [111.613126, 27.663424],
                            [111.61295, 27.661394],
                            [111.605731, 27.656022],
                            [111.604888, 27.651991],
                            [111.593928, 27.647898],
                            [111.590731, 27.648527],
                            [111.588219, 27.650218],
                            [111.586147, 27.649445],
                            [111.587201, 27.647877],
                            [111.585304, 27.645702],
                            [111.579859, 27.646733],
                            [111.576293, 27.645939],
                            [111.574291, 27.642134],
                            [111.572482, 27.64067],
                            [111.568091, 27.64035],
                            [111.563295, 27.636071],
                            [111.560397, 27.632225],
                            [111.556972, 27.629204],
                            [111.560924, 27.630575],
                            [111.563524, 27.629956],
                            [111.565561, 27.62777],
                            [111.568003, 27.627069],
                            [111.568916, 27.622397],
                            [111.568143, 27.61689],
                            [111.570462, 27.61162],
                            [111.57408, 27.607711],
                            [111.580526, 27.607484],
                            [111.589045, 27.606566],
                            [111.590591, 27.601976],
                            [111.590854, 27.597613],
                            [111.590204, 27.59394],
                            [111.593137, 27.589618],
                            [111.595509, 27.590866],
                            [111.596545, 27.589773],
                            [111.594718, 27.584315],
                            [111.595544, 27.580612],
                            [111.598372, 27.579838],
                            [111.599584, 27.576815],
                            [111.602394, 27.575113],
                            [111.60401, 27.57696],
                            [111.607681, 27.575856],
                            [111.609226, 27.572059],
                            [111.611949, 27.569696],
                            [111.611492, 27.567096],
                            [111.615567, 27.566167],
                            [111.617517, 27.56951],
                            [111.620643, 27.57177],
                            [111.611246, 27.578528],
                            [111.612388, 27.580705],
                            [111.616164, 27.579683],
                            [111.623313, 27.575794],
                            [111.625473, 27.575195],
                            [111.627845, 27.577032],
                            [111.628055, 27.579982],
                            [111.632218, 27.58022],
                            [111.631358, 27.577444],
                            [111.635134, 27.578775],
                            [111.636188, 27.580705],
                            [111.635222, 27.584068],
                            [111.637382, 27.584738],
                            [111.640087, 27.577331],
                            [111.639701, 27.57599],
                            [111.635643, 27.575495],
                            [111.635151, 27.572895],
                            [111.637698, 27.572719],
                            [111.641387, 27.574638],
                            [111.641861, 27.572699],
                            [111.640315, 27.568757],
                            [111.635608, 27.56238],
                            [111.630602, 27.562319],
                            [111.628407, 27.5588],
                            [111.626229, 27.557293],
                            [111.626685, 27.554517],
                            [111.630637, 27.553073],
                            [111.636047, 27.555487],
                            [111.635116, 27.560368],
                            [111.637681, 27.561029],
                            [111.639841, 27.559182],
                            [111.640491, 27.555033],
                            [111.643442, 27.553723],
                            [111.643986, 27.550534],
                            [111.641685, 27.547799],
                            [111.645409, 27.546076],
                            [111.649238, 27.542113],
                            [111.652821, 27.540121],
                            [111.652259, 27.536808],
                            [111.650661, 27.534331],
                            [111.647166, 27.536251],
                            [111.643301, 27.536199],
                            [111.641387, 27.538501],
                            [111.638664, 27.537933],
                            [111.642968, 27.527282],
                            [111.642546, 27.52404],
                            [111.644267, 27.522182],
                            [111.643881, 27.520149],
                            [111.649308, 27.517641],
                            [111.651662, 27.514255],
                            [111.654033, 27.51476],
                            [111.658512, 27.521377],
                            [111.666065, 27.531555],
                            [111.668928, 27.53718],
                            [111.671123, 27.538356],
                            [111.675374, 27.537159],
                            [111.682505, 27.529676],
                            [111.686756, 27.526301],
                            [111.68781, 27.522513],
                            [111.689636, 27.521429],
                            [111.690743, 27.5237],
                            [111.689812, 27.531276],
                            [111.692991, 27.533856],
                            [111.700702, 27.529191],
                            [111.708184, 27.523927],
                            [111.717739, 27.516598],
                            [111.723167, 27.51569],
                            [111.728348, 27.517992],
                            [111.73093, 27.518229],
                            [111.733758, 27.5157],
                            [111.737113, 27.507906],
                            [111.74045, 27.50262],
                            [111.744068, 27.499409],
                            [111.748442, 27.498263],
                            [111.754379, 27.498728],
                            [111.770204, 27.495641],
                            [111.779285, 27.49498],
                            [111.785766, 27.49562],
                            [111.78747, 27.494618],
                            [111.787821, 27.491552],
                            [111.787365, 27.484923],
                            [111.78891, 27.479419],
                            [111.790175, 27.470466],
                            [111.79223, 27.46839],
                            [111.796358, 27.468163],
                            [111.799449, 27.470445],
                            [111.801258, 27.473203],
                            [111.803577, 27.474803],
                            [111.80846, 27.474101],
                            [111.810778, 27.471344],
                            [111.812043, 27.459859],
                            [111.813588, 27.454354],
                            [111.81949, 27.449283],
                            [111.825146, 27.446752],
                            [111.831574, 27.44512],
                            [111.835175, 27.446029],
                            [111.838793, 27.451307],
                            [111.840216, 27.456616],
                            [111.847295, 27.460696],
                            [111.855286, 27.46114],
                            [111.85836, 27.458837],
                            [111.863752, 27.451927],
                            [111.869162, 27.449851],
                            [111.869478, 27.45485],
                            [111.873641, 27.460107],
                            [111.883196, 27.465106],
                            [111.894543, 27.468483],
                            [111.904379, 27.473946],
                            [111.916252, 27.476414],
                            [111.92247, 27.47914],
                            [111.94216, 27.490767],
                            [111.94685, 27.49626],
                            [111.950521, 27.503363],
                            [111.961165, 27.509754],
                            [111.969947, 27.509041],
                            [111.982909, 27.513831],
                            [111.992535, 27.518167],
                            [112.001299, 27.514709],
                            [112.015, 27.513067],
                            [112.022535, 27.516495],
                            [112.031106, 27.521057],
                            [112.035023, 27.527467],
                            [112.032529, 27.537799],
                            [112.026083, 27.540121],
                            [112.013366, 27.537396],
                            [112.004303, 27.536726],
                            [111.998647, 27.541556],
                            [111.990462, 27.55557],
                            [111.988934, 27.560616],
                            [111.990515, 27.562081],
                            [111.990164, 27.565507],
                            [111.991762, 27.567127],
                            [111.994203, 27.567178],
                            [111.999174, 27.571811],
                            [111.999683, 27.569521],
                            [112.005041, 27.568272],
                            [112.006639, 27.570841],
                            [112.007377, 27.576372],
                            [112.009028, 27.575175],
                            [112.011206, 27.577042],
                            [112.003425, 27.581458],
                            [112.000491, 27.585223],
                            [111.998155, 27.585048],
                            [111.99682, 27.58708],
                            [111.998928, 27.590546],
                            [111.998313, 27.594394],
                            [111.993571, 27.601398],
                            [111.98918, 27.606267],
                            [111.98384, 27.604936],
                            [111.978729, 27.612104],
                            [111.977412, 27.612362],
                            [111.970825, 27.610011],
                            [111.9612, 27.604781],
                            [111.961147, 27.603141],
                            [111.956932, 27.60342],
                            [111.953384, 27.6021],
                            [111.949221, 27.601945],
                            [111.945831, 27.600274],
                            [111.938507, 27.598758],
                            [111.931235, 27.598283],
                            [111.923401, 27.596591],
                            [111.91764, 27.594735],
                            [111.917587, 27.597819],
                            [111.914847, 27.597953],
                            [111.911159, 27.60243],
                            [111.911844, 27.604689],
                            [111.915199, 27.606071],
                            [111.913477, 27.609144],
                            [111.907962, 27.611352],
                            [111.906715, 27.610877],
                            [111.905099, 27.613817],
                            [111.900339, 27.615652],
                            [111.895175, 27.61558],
                            [111.893717, 27.616869],
                            [111.893278, 27.613662],
                            [111.89175, 27.612012],
                            [111.884654, 27.612156],
                            [111.882476, 27.614136],
                            [111.878507, 27.612826],
                            [111.880245, 27.615364],
                            [111.87733, 27.62051],
                            [111.877874, 27.621345],
                            [111.874151, 27.623109],
                            [111.87322, 27.626192],
                            [111.873975, 27.628265],
                            [111.871182, 27.63273],
                            [111.87004, 27.636607],
                            [111.867318, 27.637577],
                            [111.869988, 27.638391],
                            [111.868548, 27.639608],
                            [111.867845, 27.642897],
                            [111.865193, 27.642712],
                            [111.867985, 27.649104],
                            [111.872025, 27.654868],
                            [111.875363, 27.661806],
                            [111.875415, 27.663528],
                            [111.881738, 27.672785],
                            [111.886077, 27.680361],
                            [111.888079, 27.682577],
                            [111.8849, 27.684473],
                            [111.884443, 27.687081],
                            [111.879771, 27.682855],
                            [111.879648, 27.686205],
                            [111.881334, 27.687658],
                            [111.880685, 27.690225],
                            [111.877628, 27.691966],
                            [111.880017, 27.696264],
                            [111.880386, 27.700675],
                            [111.884496, 27.701695],
                            [111.887008, 27.698098],
                            [111.890608, 27.702375],
                            [111.893208, 27.706827],
                            [111.901323, 27.713237],
                            [111.903624, 27.716287],
                            [111.904818, 27.72456],
                            [111.901569, 27.732535],
                            [111.893296, 27.742662],
                            [111.892418, 27.745278],
                            [111.894209, 27.748049],
                            [111.906416, 27.748894],
                            [111.907558, 27.751181],
                            [111.907716, 27.758401],
                            [111.904888, 27.762078],
                            [111.904607, 27.767001],
                            [111.910122, 27.777589],
                            [111.915866, 27.783211],
                            [111.919449, 27.784035],
                            [111.92045, 27.781306],
                            [111.924631, 27.780987],
                            [111.925649, 27.78316],
                            [111.925579, 27.789318],
                            [111.923788, 27.797874],
                            [111.920854, 27.80155],
                            [111.922049, 27.803496],
                            [111.926738, 27.807233],
                            [111.935889, 27.809169],
                            [111.937997, 27.807202],
                            [111.945251, 27.803208],
                            [111.949502, 27.798327],
                            [111.953032, 27.797514],
                            [111.965538, 27.800798],
                            [111.968647, 27.802487],
                            [111.97079, 27.805987],
                            [111.968981, 27.809642],
                            [111.962833, 27.812648],
                            [111.956844, 27.81656],
                            [111.956001, 27.820359],
                            [111.967295, 27.825588],
                            [111.972318, 27.829098],
                            [111.975005, 27.832083],
                            [111.975972, 27.834543],
                            [111.975128, 27.83829],
                            [111.970913, 27.839906],
                            [111.964309, 27.838465],
                            [111.963167, 27.839052],
                            [111.961534, 27.843426],
                            [111.959707, 27.846174],
                            [111.959795, 27.849169],
                            [111.962921, 27.851474],
                            [111.963431, 27.853604],
                            [111.960638, 27.856084],
                            [111.956493, 27.855611],
                            [111.945181, 27.856588],
                            [111.94209, 27.853501],
                            [111.936733, 27.852822],
                            [111.926247, 27.855261],
                            [111.922839, 27.858163],
                            [111.923103, 27.862722],
                            [111.928407, 27.867856],
                            [111.930919, 27.873608],
                            [111.934713, 27.876859],
                            [111.935538, 27.879431],
                            [111.935099, 27.887785],
                            [111.937277, 27.894853],
                            [111.940649, 27.903751],
                            [111.944724, 27.912298],
                            [111.94685, 27.919354],
                            [111.944443, 27.930194],
                            [111.941721, 27.939664],
                            [111.941089, 27.943808],
                            [111.937031, 27.951448],
                            [111.936803, 27.954224],
                            [111.942037, 27.958964],
                            [111.943706, 27.961462],
                            [111.945199, 27.968761],
                            [111.946815, 27.969409],
                            [111.950626, 27.967404],
                            [111.956071, 27.965708],
                            [111.959654, 27.966325],
                            [111.962236, 27.96835],
                            [111.960743, 27.972616],
                            [111.956949, 27.980151],
                            [111.951223, 27.987099],
                            [111.940333, 27.99157],
                            [111.936311, 27.99121],
                            [111.926844, 27.98087],
                            [111.921452, 27.977201],
                            [111.912634, 27.973798],
                            [111.908331, 27.974518],
                            [111.902148, 27.978609],
                            [111.900076, 27.981364],
                            [111.900603, 27.986092],
                            [111.90524, 27.994222],
                            [111.907119, 27.996483],
                            [111.908296, 28.000183],
                            [111.907541, 28.001447],
                            [111.899338, 28.005352],
                            [111.896405, 28.008681],
                            [111.896615, 28.011322],
                            [111.899197, 28.016286],
                            [111.899724, 28.020016],
                            [111.902587, 28.021999],
                            [111.89182, 28.026961],
                            [111.887043, 28.027413],
                            [111.882511, 28.026376],
                            [111.874941, 28.027074],
                            [111.870163, 28.026663],
                            [111.866721, 28.028523],
                            [111.864806, 28.028256],
                            [111.858975, 28.030886],
                            [111.85425, 28.029479],
                            [111.85014, 28.029592],
                            [111.848366, 28.028646],
                            [111.843747, 28.023848],
                            [111.838337, 28.019954],
                            [111.829958, 28.023077],
                            [111.826797, 28.023447],
                            [111.823389, 28.022605],
                            [111.820614, 28.018454],
                            [111.817751, 28.016532],
                            [111.812605, 28.015227],
                            [111.80853, 28.011682],
                            [111.804508, 28.009226],
                            [111.798588, 28.00825],
                            [111.796129, 28.005187],
                            [111.79692, 27.99267],
                            [111.795462, 27.990676],
                            [111.793073, 27.98972],
                            [111.789332, 27.990254],
                            [111.782833, 27.992947],
                            [111.778881, 27.996647],
                            [111.778583, 28.000172],
                            [111.779601, 28.004026],
                            [111.779443, 28.007068],
                            [111.777072, 28.009565],
                            [111.773893, 28.010675],
                            [111.770661, 28.009832],
                            [111.765638, 28.006112],
                            [111.761369, 28.005763],
                            [111.755239, 28.004314],
                            [111.751481, 28.005753],
                            [111.747458, 28.003194],
                            [111.743383, 27.994222],
                            [111.737517, 27.991981],
                            [111.73425, 27.988158],
                            [111.730456, 27.98752],
                            [111.726768, 27.98346],
                            [111.724256, 27.982947],
                            [111.721604, 27.98492],
                            [111.715122, 27.993338],
                            [111.711943, 27.993441],
                            [111.708834, 27.990789],
                            [111.704847, 27.991159],
                            [111.700878, 27.994088],
                            [111.697698, 27.99417],
                            [111.69278, 27.991375],
                            [111.688495, 27.990614],
                            [111.686053, 27.991817],
                            [111.684999, 27.994335],
                            [111.684911, 28.002978]
                        ]
                    ]
                ]
            }
        }]
    });
}));