/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

(function(root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['exports', 'echarts'], factory);
    } else if (typeof exports === 'object' && typeof exports.nodeName !== 'string') {
        // CommonJS
        factory(exports, require('echarts'));
    } else {
        // Browser globals
        factory({}, root.echarts);
    }
}(this, function(exports, echarts) {
    var log = function(msg) {
        if (typeof console !== 'undefined') {
            console && console.error && console.error(msg);
        }
    }
    if (!echarts) {
        log('ECharts is not Loaded');
        return;
    }
    if (!echarts.registerMap) {
        log('ECharts Map is not loaded')
        return;
    }
    echarts.registerMap('湘西土家族苗族自治州', {
        "type": "FeatureCollection",
        "features": [{
            "type": "Feature",
            "properties": {
                "adcode": 433101,
                "name": "吉首市",
                "center": [109.738273, 28.314827],
                "centroid": [109.762507, 28.309997],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 433100
                },
                "subFeatureIndex": 0,
                "acroutes": [100000, 430000, 433100]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [109.698612, 28.16019],
                            [109.703657, 28.156227],
                            [109.704751, 28.145491],
                            [109.713152, 28.148569],
                            [109.716264, 28.15361],
                            [109.722489, 28.154072],
                            [109.727522, 28.146088],
                            [109.736336, 28.14728],
                            [109.743132, 28.144221],
                            [109.745661, 28.135447],
                            [109.751108, 28.135447],
                            [109.760676, 28.138795],
                            [109.766913, 28.146588],
                            [109.770146, 28.148473],
                            [109.785489, 28.150493],
                            [109.794534, 28.147338],
                            [109.803665, 28.149416],
                            [109.809269, 28.147723],
                            [109.817354, 28.149204],
                            [109.821074, 28.152648],
                            [109.822035, 28.159132],
                            [109.827895, 28.161979],
                            [109.835931, 28.160574],
                            [109.842009, 28.161517],
                            [109.845766, 28.171482],
                            [109.845645, 28.182445],
                            [109.842289, 28.187619],
                            [109.848951, 28.19812],
                            [109.847565, 28.20637],
                            [109.853304, 28.213946],
                            [109.85176, 28.222252],
                            [109.844806, 28.230923],
                            [109.843797, 28.236459],
                            [109.845438, 28.243168],
                            [109.857328, 28.258507],
                            [109.863309, 28.26164],
                            [109.868087, 28.26927],
                            [109.877303, 28.275246],
                            [109.886226, 28.271787],
                            [109.890579, 28.273056],
                            [109.900815, 28.271345],
                            [109.911964, 28.281453],
                            [109.917386, 28.277168],
                            [109.928498, 28.276515],
                            [109.942576, 28.288505],
                            [109.953251, 28.287103],
                            [109.96553, 28.296633],
                            [109.977177, 28.289735],
                            [109.989468, 28.285412],
                            [109.993419, 28.290331],
                            [109.995948, 28.299476],
                            [110.00137, 28.301705],
                            [110.003522, 28.306431],
                            [110.010318, 28.312367],
                            [110.01754, 28.313789],
                            [110.017053, 28.316805],
                            [110.010671, 28.322836],
                            [110.01867, 28.326851],
                            [110.017819, 28.330846],
                            [110.012944, 28.334553],
                            [110.012652, 28.338164],
                            [110.019132, 28.350704],
                            [110.018962, 28.355198],
                            [110.010051, 28.362706],
                            [110.005881, 28.372498],
                            [110.008239, 28.400276],
                            [110.021114, 28.411581],
                            [110.031424, 28.411945],
                            [110.037478, 28.415649],
                            [110.053258, 28.414114],
                            [110.059726, 28.419584],
                            [110.059118, 28.424285],
                            [110.055921, 28.426281],
                            [110.059629, 28.430196],
                            [110.057939, 28.436892],
                            [110.052055, 28.4419],
                            [110.045162, 28.443358],
                            [110.036663, 28.442418],
                            [110.031156, 28.435875],
                            [110.010792, 28.434667],
                            [110.007461, 28.433515],
                            [109.998671, 28.435377],
                            [109.994586, 28.434398],
                            [109.991547, 28.427931],
                            [109.984362, 28.426358],
                            [109.980447, 28.416993],
                            [109.970903, 28.409239],
                            [109.963706, 28.408107],
                            [109.95719, 28.401926],
                            [109.947257, 28.404594],
                            [109.933641, 28.396437],
                            [109.919076, 28.394421],
                            [109.891843, 28.403481],
                            [109.884622, 28.410851],
                            [109.883868, 28.415899],
                            [109.891454, 28.436087],
                            [109.903478, 28.443857],
                            [109.904195, 28.450688],
                            [109.885789, 28.453757],
                            [109.875783, 28.44852],
                            [109.8734, 28.449095],
                            [109.865194, 28.463714],
                            [109.860003, 28.468413],
                            [109.854471, 28.468682],
                            [109.848113, 28.466418],
                            [109.842204, 28.461584],
                            [109.838362, 28.461584],
                            [109.833378, 28.469718],
                            [109.823785, 28.473017],
                            [109.806838, 28.46755],
                            [109.805756, 28.465344],
                            [109.808175, 28.456731],
                            [109.803178, 28.44497],
                            [109.795531, 28.436605],
                            [109.785793, 28.433151],
                            [109.77693, 28.435856],
                            [109.768882, 28.433515],
                            [109.763776, 28.434532],
                            [109.758086, 28.43292],
                            [109.746804, 28.436125],
                            [109.729139, 28.432901],
                            [109.717042, 28.426895],
                            [109.704812, 28.428987],
                            [109.692983, 28.420332],
                            [109.67871, 28.414786],
                            [109.672971, 28.414037],
                            [109.654808, 28.407781],
                            [109.649313, 28.400794],
                            [109.644693, 28.400564],
                            [109.637544, 28.389488],
                            [109.628876, 28.387952],
                            [109.624001, 28.381713],
                            [109.630213, 28.377278],
                            [109.634687, 28.378757],
                            [109.635769, 28.372171],
                            [109.639599, 28.37292],
                            [109.633472, 28.365586],
                            [109.629314, 28.364222],
                            [109.635599, 28.360613],
                            [109.6289, 28.357636],
                            [109.625873, 28.362686],
                            [109.61763, 28.356158],
                            [109.613934, 28.346268],
                            [109.604245, 28.3455],
                            [109.601473, 28.348054],
                            [109.604318, 28.355659],
                            [109.59937, 28.359614],
                            [109.593364, 28.354353],
                            [109.589474, 28.344309],
                            [109.585535, 28.343752],
                            [109.582374, 28.349072],
                            [109.567128, 28.356926],
                            [109.561754, 28.35489],
                            [109.552454, 28.358846],
                            [109.551007, 28.361707],
                            [109.541135, 28.367947],
                            [109.527713, 28.3686],
                            [109.519993, 28.36261],
                            [109.509684, 28.36261],
                            [109.506304, 28.360709],
                            [109.511872, 28.350186],
                            [109.508249, 28.342888],
                            [109.518571, 28.339009],
                            [109.516066, 28.332555],
                            [109.515823, 28.324411],
                            [109.517696, 28.3145],
                            [109.533719, 28.316805],
                            [109.53564, 28.314961],
                            [109.531324, 28.30524],
                            [109.525306, 28.297478],
                            [109.517525, 28.293482],
                            [109.519288, 28.270077],
                            [109.521002, 28.265868],
                            [109.530996, 28.260083],
                            [109.529488, 28.254644],
                            [109.53564, 28.253241],
                            [109.540758, 28.246532],
                            [109.537148, 28.241131],
                            [109.528005, 28.236729],
                            [109.521574, 28.235517],
                            [109.52347, 28.222444],
                            [109.530704, 28.22181],
                            [109.534461, 28.216215],
                            [109.54302, 28.215484],
                            [109.539263, 28.211465],
                            [109.529659, 28.211619],
                            [109.525671, 28.205889],
                            [109.529282, 28.191773],
                            [109.529282, 28.187715],
                            [109.538436, 28.185811],
                            [109.54308, 28.186465],
                            [109.555384, 28.192697],
                            [109.562666, 28.1926],
                            [109.567943, 28.186311],
                            [109.572417, 28.185061],
                            [109.57435, 28.189658],
                            [109.580124, 28.190716],
                            [109.592452, 28.200831],
                            [109.592452, 28.206023],
                            [109.600172, 28.208677],
                            [109.607309, 28.208408],
                            [109.614141, 28.212369],
                            [109.62405, 28.212888],
                            [109.630663, 28.211081],
                            [109.638079, 28.211273],
                            [109.651173, 28.208485],
                            [109.653459, 28.200639],
                            [109.658224, 28.199774],
                            [109.666127, 28.202254],
                            [109.675828, 28.197928],
                            [109.678916, 28.202312],
                            [109.688302, 28.203947],
                            [109.687548, 28.199081],
                            [109.691074, 28.197408],
                            [109.692752, 28.190293],
                            [109.695366, 28.187619],
                            [109.693761, 28.176617],
                            [109.695961, 28.171347],
                            [109.694612, 28.166307],
                            [109.698612, 28.16019]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 433122,
                "name": "泸溪县",
                "center": [110.214428, 28.214516],
                "centroid": [109.996128, 28.158502],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 433100
                },
                "subFeatureIndex": 1,
                "acroutes": [100000, 430000, 433100]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [109.792346, 28.029794],
                            [109.785732, 28.023515],
                            [109.784055, 28.012612],
                            [109.784468, 28.006736],
                            [109.775921, 27.999107],
                            [109.775338, 27.993827],
                            [109.78262, 27.991091],
                            [109.79293, 27.973884],
                            [109.797866, 27.979337],
                            [109.816126, 27.980898],
                            [109.822898, 27.984656],
                            [109.833207, 27.986197],
                            [109.842824, 27.982902],
                            [109.837475, 27.97504],
                            [109.838885, 27.97319],
                            [109.847517, 27.973421],
                            [109.857146, 27.970877],
                            [109.863504, 27.964344],
                            [109.876816, 27.956307],
                            [109.882603, 27.946901],
                            [109.891649, 27.945725],
                            [109.897436, 27.940694],
                            [109.904402, 27.93759],
                            [109.90935, 27.937417],
                            [109.913459, 27.945301],
                            [109.916815, 27.946978],
                            [109.914383, 27.951257],
                            [109.92034, 27.956133],
                            [109.926711, 27.955247],
                            [109.933604, 27.959275],
                            [109.943768, 27.956442],
                            [109.947804, 27.956866],
                            [109.9515, 27.948327],
                            [109.957895, 27.9517],
                            [109.958393, 27.961164],
                            [109.96294, 27.961607],
                            [109.970259, 27.956885],
                            [109.972533, 27.951276],
                            [109.970928, 27.94372],
                            [109.974125, 27.938111],
                            [109.984143, 27.945937],
                            [109.988775, 27.945532],
                            [109.994829, 27.93892],
                            [110.001723, 27.936163],
                            [110.002987, 27.931517],
                            [109.999291, 27.923786],
                            [110.000373, 27.919776],
                            [110.007789, 27.915766],
                            [110.016044, 27.908573],
                            [110.022512, 27.908014],
                            [110.044298, 27.918677],
                            [110.048262, 27.923478],
                            [110.048323, 27.927527],
                            [110.054316, 27.934293],
                            [110.050778, 27.945667],
                            [110.047836, 27.950004],
                            [110.051532, 27.955305],
                            [110.056675, 27.955575],
                            [110.062474, 27.959275],
                            [110.067592, 27.967081],
                            [110.077501, 27.969817],
                            [110.081792, 27.969528],
                            [110.084698, 27.974481],
                            [110.090023, 27.973826],
                            [110.09925, 27.977063],
                            [110.10353, 27.98323],
                            [110.110326, 27.988529],
                            [110.111165, 28.005541],
                            [110.117705, 28.016445],
                            [110.124404, 28.016734],
                            [110.128222, 28.020606],
                            [110.128599, 28.024844],
                            [110.122192, 28.040021],
                            [110.130823, 28.039617],
                            [110.131699, 28.044682],
                            [110.137522, 28.047282],
                            [110.135759, 28.051287],
                            [110.137437, 28.057796],
                            [110.124502, 28.065749],
                            [110.116283, 28.075568],
                            [110.112344, 28.085252],
                            [110.11176, 28.090931],
                            [110.114605, 28.093106],
                            [110.123201, 28.093664],
                            [110.147321, 28.092182],
                            [110.158506, 28.095916],
                            [110.166749, 28.103115],
                            [110.166336, 28.110622],
                            [110.151807, 28.120899],
                            [110.150044, 28.127269],
                            [110.154907, 28.130406],
                            [110.177885, 28.133985],
                            [110.184073, 28.139161],
                            [110.183915, 28.142682],
                            [110.178639, 28.148993],
                            [110.16918, 28.155592],
                            [110.168585, 28.160632],
                            [110.174542, 28.16573],
                            [110.194298, 28.173501],
                            [110.199988, 28.178772],
                            [110.201994, 28.187869],
                            [110.197811, 28.193485],
                            [110.191283, 28.197024],
                            [110.191453, 28.201216],
                            [110.1969, 28.202716],
                            [110.209288, 28.20287],
                            [110.216655, 28.20437],
                            [110.223026, 28.198062],
                            [110.231232, 28.196601],
                            [110.235937, 28.197678],
                            [110.242964, 28.207235],
                            [110.243475, 28.217849],
                            [110.237688, 28.227827],
                            [110.24029, 28.230846],
                            [110.235342, 28.236479],
                            [110.22654, 28.240842],
                            [110.220266, 28.240016],
                            [110.216327, 28.242957],
                            [110.208826, 28.243784],
                            [110.205981, 28.25251],
                            [110.195465, 28.254298],
                            [110.186517, 28.25941],
                            [110.182165, 28.265695],
                            [110.18338, 28.26952],
                            [110.177265, 28.275458],
                            [110.175052, 28.28055],
                            [110.176937, 28.284643],
                            [110.185763, 28.285681],
                            [110.185557, 28.289831],
                            [110.178444, 28.302685],
                            [110.178104, 28.316401],
                            [110.18321, 28.32201],
                            [110.185058, 28.33317],
                            [110.191502, 28.337856],
                            [110.203051, 28.339758],
                            [110.212777, 28.34863],
                            [110.205823, 28.36261],
                            [110.194346, 28.361707],
                            [110.19042, 28.363224],
                            [110.179782, 28.37651],
                            [110.17008, 28.378027],
                            [110.161947, 28.386397],
                            [110.152075, 28.391696],
                            [110.148148, 28.389603],
                            [110.145789, 28.383153],
                            [110.141437, 28.383326],
                            [110.125827, 28.402502],
                            [110.119991, 28.405688],
                            [110.111736, 28.415899],
                            [110.11035, 28.425264],
                            [110.11463, 28.437506],
                            [110.114204, 28.442553],
                            [110.106666, 28.451033],
                            [110.103019, 28.452683],
                            [110.088211, 28.451551],
                            [110.070814, 28.447311],
                            [110.067057, 28.441478],
                            [110.063872, 28.426876],
                            [110.055921, 28.426281],
                            [110.059118, 28.424285],
                            [110.059726, 28.419584],
                            [110.053258, 28.414114],
                            [110.037478, 28.415649],
                            [110.031424, 28.411945],
                            [110.021114, 28.411581],
                            [110.008239, 28.400276],
                            [110.005881, 28.372498],
                            [110.010051, 28.362706],
                            [110.018962, 28.355198],
                            [110.019132, 28.350704],
                            [110.012652, 28.338164],
                            [110.012944, 28.334553],
                            [110.017819, 28.330846],
                            [110.01867, 28.326851],
                            [110.010671, 28.322836],
                            [110.017053, 28.316805],
                            [110.01754, 28.313789],
                            [110.010318, 28.312367],
                            [110.003522, 28.306431],
                            [110.00137, 28.301705],
                            [109.995948, 28.299476],
                            [109.993419, 28.290331],
                            [109.989468, 28.285412],
                            [109.977177, 28.289735],
                            [109.96553, 28.296633],
                            [109.953251, 28.287103],
                            [109.942576, 28.288505],
                            [109.928498, 28.276515],
                            [109.917386, 28.277168],
                            [109.911964, 28.281453],
                            [109.900815, 28.271345],
                            [109.890579, 28.273056],
                            [109.886226, 28.271787],
                            [109.877303, 28.275246],
                            [109.868087, 28.26927],
                            [109.863309, 28.26164],
                            [109.857328, 28.258507],
                            [109.845438, 28.243168],
                            [109.843797, 28.236459],
                            [109.844806, 28.230923],
                            [109.85176, 28.222252],
                            [109.853304, 28.213946],
                            [109.847565, 28.20637],
                            [109.848951, 28.19812],
                            [109.842289, 28.187619],
                            [109.845645, 28.182445],
                            [109.845766, 28.171482],
                            [109.842009, 28.161517],
                            [109.835931, 28.160574],
                            [109.827895, 28.161979],
                            [109.822035, 28.159132],
                            [109.821074, 28.152648],
                            [109.817354, 28.149204],
                            [109.809269, 28.147723],
                            [109.803665, 28.149416],
                            [109.794534, 28.147338],
                            [109.785489, 28.150493],
                            [109.770146, 28.148473],
                            [109.766913, 28.146588],
                            [109.760676, 28.138795],
                            [109.751108, 28.135447],
                            [109.745661, 28.135447],
                            [109.743132, 28.144221],
                            [109.736336, 28.14728],
                            [109.727522, 28.146088],
                            [109.722489, 28.154072],
                            [109.716264, 28.15361],
                            [109.713152, 28.148569],
                            [109.704751, 28.145491],
                            [109.703657, 28.156227],
                            [109.698612, 28.16019],
                            [109.687609, 28.161652],
                            [109.6755, 28.154341],
                            [109.667355, 28.134523],
                            [109.660364, 28.12827],
                            [109.660948, 28.124402],
                            [109.673895, 28.117377],
                            [109.67398, 28.114105],
                            [109.667671, 28.109409],
                            [109.669616, 28.097168],
                            [109.675743, 28.092317],
                            [109.683986, 28.091739],
                            [109.702186, 28.099939],
                            [109.706052, 28.099304],
                            [109.714039, 28.09453],
                            [109.718319, 28.09455],
                            [109.727899, 28.103558],
                            [109.744555, 28.098707],
                            [109.749345, 28.094665],
                            [109.757527, 28.095878],
                            [109.766499, 28.093279],
                            [109.771982, 28.083923],
                            [109.780918, 28.082557],
                            [109.791884, 28.083057],
                            [109.793732, 28.081709],
                            [109.793562, 28.066769],
                            [109.803774, 28.056198],
                            [109.803859, 28.049362],
                            [109.795312, 28.032259],
                            [109.792346, 28.029794]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 433123,
                "name": "凤凰县",
                "center": [109.599191, 27.948308],
                "centroid": [109.511279, 28.006103],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 433100
                },
                "subFeatureIndex": 2,
                "acroutes": [100000, 430000, 433100]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [109.342056, 28.17881],
                            [109.343235, 28.173617],
                            [109.341801, 28.162152],
                            [109.335831, 28.158208],
                            [109.33819, 28.141778],
                            [109.337011, 28.138584],
                            [109.328099, 28.126865],
                            [109.3254, 28.115202],
                            [109.314373, 28.103905],
                            [109.311772, 28.094088],
                            [109.313121, 28.086022],
                            [109.304963, 28.076415],
                            [109.301511, 28.0577],
                            [109.298739, 28.032491],
                            [109.301012, 28.029389],
                            [109.305887, 28.029987],
                            [109.311516, 28.03796],
                            [109.323625, 28.041177],
                            [109.33002, 28.052],
                            [109.334567, 28.062206],
                            [109.338433, 28.06288],
                            [109.347016, 28.060396],
                            [109.350372, 28.056371],
                            [109.358785, 28.054792],
                            [109.362225, 28.050844],
                            [109.370553, 28.046088],
                            [109.372985, 28.040194],
                            [109.379161, 28.033107],
                            [109.373033, 28.02573],
                            [109.368535, 28.016195],
                            [109.362189, 28.007622],
                            [109.342263, 28.00533],
                            [109.321607, 27.991804],
                            [109.320014, 27.988432],
                            [109.322397, 27.976215],
                            [109.320172, 27.970974],
                            [109.313911, 27.967832],
                            [109.30714, 27.968044],
                            [109.301766, 27.95835],
                            [109.30844, 27.947074],
                            [109.308866, 27.939768],
                            [109.313826, 27.93333],
                            [109.312063, 27.92716],
                            [109.314835, 27.918099],
                            [109.311881, 27.907783],
                            [109.318191, 27.901091],
                            [109.320634, 27.895595],
                            [109.317777, 27.885528],
                            [109.318701, 27.87681],
                            [109.323151, 27.872181],
                            [109.321887, 27.867995],
                            [109.330312, 27.87002],
                            [109.337533, 27.869519],
                            [109.337363, 27.866259],
                            [109.327528, 27.862381],
                            [109.326847, 27.8586],
                            [109.333242, 27.851655],
                            [109.345521, 27.840735],
                            [109.34687, 27.836374],
                            [109.34022, 27.821438],
                            [109.343004, 27.811171],
                            [109.338628, 27.804512],
                            [109.342664, 27.800748],
                            [109.336524, 27.797911],
                            [109.331224, 27.791618],
                            [109.339965, 27.791965],
                            [109.341071, 27.790209],
                            [109.333035, 27.782796],
                            [109.337108, 27.776715],
                            [109.346676, 27.775749],
                            [109.349873, 27.771058],
                            [109.364292, 27.759782],
                            [109.366225, 27.749798],
                            [109.362979, 27.74499],
                            [109.373276, 27.742576],
                            [109.377738, 27.739679],
                            [109.377519, 27.736627],
                            [109.372231, 27.730061],
                            [109.365508, 27.726063],
                            [109.365921, 27.722199],
                            [109.382978, 27.724904],
                            [109.389409, 27.724112],
                            [109.392388, 27.726372],
                            [109.39533, 27.721717],
                            [109.400327, 27.720287],
                            [109.404144, 27.723513],
                            [109.414174, 27.725039],
                            [109.418405, 27.72135],
                            [109.423767, 27.728264],
                            [109.426417, 27.738945],
                            [109.433456, 27.744468],
                            [109.442672, 27.7492],
                            [109.446781, 27.758816],
                            [109.451133, 27.765053],
                            [109.461516, 27.773336],
                            [109.461771, 27.777622],
                            [109.457747, 27.787854],
                            [109.45382, 27.790672],
                            [109.454489, 27.794861],
                            [109.461346, 27.803412],
                            [109.475156, 27.804705],
                            [109.479168, 27.799416],
                            [109.476494, 27.790112],
                            [109.4785, 27.787255],
                            [109.485867, 27.787912],
                            [109.492639, 27.795768],
                            [109.507277, 27.802813],
                            [109.511957, 27.800111],
                            [109.515045, 27.807156],
                            [109.521233, 27.812502],
                            [109.525087, 27.809974],
                            [109.532783, 27.811537],
                            [109.53395, 27.804087],
                            [109.533026, 27.795865],
                            [109.537379, 27.79351],
                            [109.543494, 27.785016],
                            [109.549755, 27.786174],
                            [109.557888, 27.795015],
                            [109.559639, 27.799204],
                            [109.565754, 27.799938],
                            [109.567517, 27.805052],
                            [109.573803, 27.808893],
                            [109.585194, 27.805612],
                            [109.585948, 27.802775],
                            [109.59627, 27.804609],
                            [109.597777, 27.800073],
                            [109.603394, 27.798142],
                            [109.610774, 27.808681],
                            [109.613387, 27.815648],
                            [109.619174, 27.812464],
                            [109.626651, 27.817076],
                            [109.621193, 27.820936],
                            [109.623174, 27.826629],
                            [109.636815, 27.827324],
                            [109.647404, 27.834965],
                            [109.647647, 27.84332],
                            [109.642274, 27.850343],
                            [109.640511, 27.856054],
                            [109.643125, 27.861918],
                            [109.63673, 27.871563],
                            [109.636985, 27.876],
                            [109.624463, 27.877543],
                            [109.621958, 27.880725],
                            [109.622749, 27.893242],
                            [109.624852, 27.899587],
                            [109.628147, 27.901033],
                            [109.630444, 27.909962],
                            [109.649884, 27.903984],
                            [109.655768, 27.90516],
                            [109.65493, 27.911273],
                            [109.661531, 27.919525],
                            [109.670734, 27.921685],
                            [109.680242, 27.930978],
                            [109.682004, 27.935951],
                            [109.691037, 27.938361],
                            [109.696083, 27.936973],
                            [109.700083, 27.941715],
                            [109.706332, 27.944356],
                            [109.711255, 27.949811],
                            [109.719109, 27.952683],
                            [109.719535, 27.958678],
                            [109.713395, 27.967485],
                            [109.714611, 27.973903],
                            [109.71979, 27.981765],
                            [109.728519, 27.983731],
                            [109.73309, 27.987411],
                            [109.730829, 28.005175],
                            [109.736032, 28.012419],
                            [109.730622, 28.016542],
                            [109.732178, 28.02365],
                            [109.752445, 28.021473],
                            [109.770219, 28.025595],
                            [109.781745, 28.026173],
                            [109.792346, 28.029794],
                            [109.795312, 28.032259],
                            [109.803859, 28.049362],
                            [109.803774, 28.056198],
                            [109.793562, 28.066769],
                            [109.793732, 28.081709],
                            [109.791884, 28.083057],
                            [109.780918, 28.082557],
                            [109.771982, 28.083923],
                            [109.766499, 28.093279],
                            [109.757527, 28.095878],
                            [109.749345, 28.094665],
                            [109.744555, 28.098707],
                            [109.727899, 28.103558],
                            [109.718319, 28.09455],
                            [109.714039, 28.09453],
                            [109.706052, 28.099304],
                            [109.702186, 28.099939],
                            [109.683986, 28.091739],
                            [109.675743, 28.092317],
                            [109.669616, 28.097168],
                            [109.667671, 28.109409],
                            [109.67398, 28.114105],
                            [109.673895, 28.117377],
                            [109.660948, 28.124402],
                            [109.660364, 28.12827],
                            [109.667355, 28.134523],
                            [109.6755, 28.154341],
                            [109.687609, 28.161652],
                            [109.698612, 28.16019],
                            [109.694612, 28.166307],
                            [109.695961, 28.171347],
                            [109.693761, 28.176617],
                            [109.695366, 28.187619],
                            [109.692752, 28.190293],
                            [109.691074, 28.197408],
                            [109.687548, 28.199081],
                            [109.688302, 28.203947],
                            [109.678916, 28.202312],
                            [109.675828, 28.197928],
                            [109.666127, 28.202254],
                            [109.658224, 28.199774],
                            [109.653459, 28.200639],
                            [109.651173, 28.208485],
                            [109.638079, 28.211273],
                            [109.630663, 28.211081],
                            [109.62405, 28.212888],
                            [109.614141, 28.212369],
                            [109.607309, 28.208408],
                            [109.600172, 28.208677],
                            [109.592452, 28.206023],
                            [109.592452, 28.200831],
                            [109.580124, 28.190716],
                            [109.57435, 28.189658],
                            [109.572417, 28.185061],
                            [109.567943, 28.186311],
                            [109.562666, 28.1926],
                            [109.555384, 28.192697],
                            [109.54308, 28.186465],
                            [109.538436, 28.185811],
                            [109.529282, 28.187715],
                            [109.529282, 28.191773],
                            [109.525671, 28.205889],
                            [109.529659, 28.211619],
                            [109.539263, 28.211465],
                            [109.54302, 28.215484],
                            [109.534461, 28.216215],
                            [109.530704, 28.22181],
                            [109.52347, 28.222444],
                            [109.521574, 28.235517],
                            [109.528005, 28.236729],
                            [109.537148, 28.241131],
                            [109.540758, 28.246532],
                            [109.53564, 28.253241],
                            [109.529488, 28.254644],
                            [109.530996, 28.260083],
                            [109.521002, 28.265868],
                            [109.519288, 28.270077],
                            [109.517525, 28.293482],
                            [109.525306, 28.297478],
                            [109.531324, 28.30524],
                            [109.53564, 28.314961],
                            [109.533719, 28.316805],
                            [109.517696, 28.3145],
                            [109.518486, 28.303338],
                            [109.514377, 28.297286],
                            [109.505319, 28.295672],
                            [109.501246, 28.289889],
                            [109.502912, 28.28766],
                            [109.498037, 28.284067],
                            [109.493393, 28.277879],
                            [109.489685, 28.275996],
                            [109.491083, 28.270481],
                            [109.477393, 28.268213],
                            [109.471558, 28.260833],
                            [109.466075, 28.260813],
                            [109.465297, 28.253337],
                            [109.458975, 28.240035],
                            [109.451814, 28.241035],
                            [109.452215, 28.237498],
                            [109.444252, 28.230135],
                            [109.436046, 28.228904],
                            [109.430222, 28.225693],
                            [109.427499, 28.217618],
                            [109.425651, 28.202543],
                            [109.420545, 28.197504],
                            [109.421797, 28.186427],
                            [109.41618, 28.1851],
                            [109.406065, 28.187831],
                            [109.382395, 28.184234],
                            [109.376754, 28.184927],
                            [109.367599, 28.17983],
                            [109.363903, 28.180195],
                            [109.362092, 28.189754],
                            [109.358554, 28.190504],
                            [109.35532, 28.186138],
                            [109.349265, 28.184792],
                            [109.342056, 28.17881]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 433124,
                "name": "花垣县",
                "center": [109.479063, 28.581352],
                "centroid": [109.431936, 28.426002],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 433100
                },
                "subFeatureIndex": 3,
                "acroutes": [100000, 430000, 433100]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [109.342056, 28.17881],
                            [109.349265, 28.184792],
                            [109.35532, 28.186138],
                            [109.358554, 28.190504],
                            [109.362092, 28.189754],
                            [109.363903, 28.180195],
                            [109.367599, 28.17983],
                            [109.376754, 28.184927],
                            [109.382395, 28.184234],
                            [109.406065, 28.187831],
                            [109.41618, 28.1851],
                            [109.421797, 28.186427],
                            [109.420545, 28.197504],
                            [109.425651, 28.202543],
                            [109.427499, 28.217618],
                            [109.430222, 28.225693],
                            [109.436046, 28.228904],
                            [109.444252, 28.230135],
                            [109.452215, 28.237498],
                            [109.451814, 28.241035],
                            [109.458975, 28.240035],
                            [109.465297, 28.253337],
                            [109.466075, 28.260813],
                            [109.471558, 28.260833],
                            [109.477393, 28.268213],
                            [109.491083, 28.270481],
                            [109.489685, 28.275996],
                            [109.493393, 28.277879],
                            [109.498037, 28.284067],
                            [109.502912, 28.28766],
                            [109.501246, 28.289889],
                            [109.505319, 28.295672],
                            [109.514377, 28.297286],
                            [109.518486, 28.303338],
                            [109.517696, 28.3145],
                            [109.515823, 28.324411],
                            [109.516066, 28.332555],
                            [109.518571, 28.339009],
                            [109.508249, 28.342888],
                            [109.511872, 28.350186],
                            [109.506304, 28.360709],
                            [109.509684, 28.36261],
                            [109.519993, 28.36261],
                            [109.527713, 28.3686],
                            [109.541135, 28.367947],
                            [109.551007, 28.361707],
                            [109.552454, 28.358846],
                            [109.561754, 28.35489],
                            [109.567128, 28.356926],
                            [109.582374, 28.349072],
                            [109.585535, 28.343752],
                            [109.589474, 28.344309],
                            [109.593364, 28.354353],
                            [109.59937, 28.359614],
                            [109.604318, 28.355659],
                            [109.601473, 28.348054],
                            [109.604245, 28.3455],
                            [109.613934, 28.346268],
                            [109.61763, 28.356158],
                            [109.625873, 28.362686],
                            [109.6289, 28.357636],
                            [109.635599, 28.360613],
                            [109.629314, 28.364222],
                            [109.633472, 28.365586],
                            [109.639599, 28.37292],
                            [109.635769, 28.372171],
                            [109.634687, 28.378757],
                            [109.630213, 28.377278],
                            [109.624001, 28.381713],
                            [109.628876, 28.387952],
                            [109.637544, 28.389488],
                            [109.644693, 28.400564],
                            [109.637459, 28.404019],
                            [109.633192, 28.402579],
                            [109.625253, 28.403136],
                            [109.624013, 28.405516],
                            [109.636766, 28.410295],
                            [109.647319, 28.418375],
                            [109.648741, 28.424554],
                            [109.638456, 28.43576],
                            [109.634079, 28.438696],
                            [109.615807, 28.440231],
                            [109.604901, 28.436125],
                            [109.602263, 28.439732],
                            [109.603528, 28.445776],
                            [109.601376, 28.449843],
                            [109.592331, 28.454525],
                            [109.585073, 28.455465],
                            [109.574556, 28.454064],
                            [109.569025, 28.447234],
                            [109.563712, 28.447618],
                            [109.560016, 28.453489],
                            [109.560903, 28.460126],
                            [109.552952, 28.465958],
                            [109.553037, 28.47225],
                            [109.545913, 28.478809],
                            [109.545293, 28.482012],
                            [109.547846, 28.491026],
                            [109.558144, 28.498908],
                            [109.564295, 28.514421],
                            [109.566763, 28.518428],
                            [109.566399, 28.53873],
                            [109.558642, 28.549158],
                            [109.559992, 28.554276],
                            [109.564928, 28.556614],
                            [109.581425, 28.556346],
                            [109.584696, 28.566541],
                            [109.593595, 28.572444],
                            [109.599625, 28.57185],
                            [109.603309, 28.567327],
                            [109.610956, 28.565238],
                            [109.62287, 28.565315],
                            [109.618846, 28.570278],
                            [109.621022, 28.572118],
                            [109.61977, 28.588655],
                            [109.613047, 28.592506],
                            [109.605838, 28.60128],
                            [109.598701, 28.602871],
                            [109.59661, 28.60925],
                            [109.600561, 28.614537],
                            [109.599127, 28.617468],
                            [109.593668, 28.616797],
                            [109.588306, 28.609595],
                            [109.582106, 28.609595],
                            [109.575067, 28.615054],
                            [109.564259, 28.618387],
                            [109.55756, 28.617812],
                            [109.549986, 28.622582],
                            [109.541828, 28.619498],
                            [109.535348, 28.614652],
                            [109.527191, 28.613943],
                            [109.518413, 28.610265],
                            [109.509416, 28.603369],
                            [109.497332, 28.598043],
                            [109.489685, 28.591663],
                            [109.483241, 28.593464],
                            [109.478633, 28.592199],
                            [109.477114, 28.595954],
                            [109.47061, 28.597545],
                            [109.462391, 28.589919],
                            [109.457637, 28.588597],
                            [109.447291, 28.593617],
                            [109.435669, 28.595246],
                            [109.427523, 28.593081],
                            [109.421359, 28.599154],
                            [109.411293, 28.601089],
                            [109.39313, 28.597353],
                            [109.384936, 28.594192],
                            [109.383428, 28.588291],
                            [109.374055, 28.584937],
                            [109.367271, 28.584765],
                            [109.365629, 28.58237],
                            [109.368341, 28.571064],
                            [109.362602, 28.562421],
                            [109.3569, 28.560639],
                            [109.352366, 28.556537],
                            [109.347381, 28.56083],
                            [109.341983, 28.556326],
                            [109.337035, 28.561175],
                            [109.326458, 28.55786],
                            [109.329911, 28.567251],
                            [109.320452, 28.579783],
                            [109.305839, 28.579362],
                            [109.30072, 28.576257],
                            [109.299906, 28.571696],
                            [109.294836, 28.566292],
                            [109.294471, 28.557553],
                            [109.292064, 28.555828],
                            [109.288928, 28.546321],
                            [109.274181, 28.538903],
                            [109.274521, 28.525177],
                            [109.280065, 28.520863],
                            [109.279202, 28.517259],
                            [109.271968, 28.51398],
                            [109.274387, 28.503913],
                            [109.273998, 28.488111],
                            [109.275919, 28.484026],
                            [109.270217, 28.485062],
                            [109.266363, 28.481801],
                            [109.263762, 28.471425],
                            [109.260795, 28.464711],
                            [109.260564, 28.457958],
                            [109.264637, 28.457671],
                            [109.260139, 28.451301],
                            [109.256893, 28.43718],
                            [109.259895, 28.43198],
                            [109.258315, 28.429006],
                            [109.261257, 28.414882],
                            [109.261439, 28.407224],
                            [109.264345, 28.404364],
                            [109.264686, 28.392502],
                            [109.26764, 28.385821],
                            [109.277791, 28.380868],
                            [109.275554, 28.377624],
                            [109.284612, 28.376818],
                            [109.288356, 28.374322],
                            [109.287761, 28.364453],
                            [109.285001, 28.358366],
                            [109.279457, 28.354122],
                            [109.277718, 28.349667],
                            [109.268576, 28.338106],
                            [109.271506, 28.323931],
                            [109.276539, 28.322971],
                            [109.272928, 28.319091],
                            [109.275238, 28.314039],
                            [109.273074, 28.30939],
                            [109.285511, 28.304145],
                            [109.28821, 28.298016],
                            [109.297584, 28.292809],
                            [109.298082, 28.288947],
                            [109.294921, 28.278552],
                            [109.306398, 28.274209],
                            [109.31796, 28.277898],
                            [109.329193, 28.286334],
                            [109.333825, 28.293309],
                            [109.340974, 28.293732],
                            [109.351575, 28.288544],
                            [109.356609, 28.287833],
                            [109.363088, 28.282991],
                            [109.352195, 28.26852],
                            [109.353156, 28.265465],
                            [109.359514, 28.265887],
                            [109.365897, 28.274209],
                            [109.376912, 28.276918],
                            [109.390479, 28.272614],
                            [109.400339, 28.273498],
                            [109.39967, 28.269308],
                            [109.387999, 28.268117],
                            [109.373471, 28.256854],
                            [109.36766, 28.25524],
                            [109.373252, 28.249723],
                            [109.367283, 28.239035],
                            [109.356779, 28.23348],
                            [109.361022, 28.225655],
                            [109.359551, 28.220445],
                            [109.345764, 28.206696],
                            [109.342311, 28.199312],
                            [109.340463, 28.190389],
                            [109.342056, 28.17881]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 433125,
                "name": "保靖县",
                "center": [109.651445, 28.709605],
                "centroid": [109.563869, 28.666425],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 433100
                },
                "subFeatureIndex": 4,
                "acroutes": [100000, 430000, 433100]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [109.320452, 28.579783],
                            [109.329911, 28.567251],
                            [109.326458, 28.55786],
                            [109.337035, 28.561175],
                            [109.341983, 28.556326],
                            [109.347381, 28.56083],
                            [109.352366, 28.556537],
                            [109.3569, 28.560639],
                            [109.362602, 28.562421],
                            [109.368341, 28.571064],
                            [109.365629, 28.58237],
                            [109.367271, 28.584765],
                            [109.374055, 28.584937],
                            [109.383428, 28.588291],
                            [109.384936, 28.594192],
                            [109.39313, 28.597353],
                            [109.411293, 28.601089],
                            [109.421359, 28.599154],
                            [109.427523, 28.593081],
                            [109.435669, 28.595246],
                            [109.447291, 28.593617],
                            [109.457637, 28.588597],
                            [109.462391, 28.589919],
                            [109.47061, 28.597545],
                            [109.477114, 28.595954],
                            [109.478633, 28.592199],
                            [109.483241, 28.593464],
                            [109.489685, 28.591663],
                            [109.497332, 28.598043],
                            [109.509416, 28.603369],
                            [109.518413, 28.610265],
                            [109.527191, 28.613943],
                            [109.535348, 28.614652],
                            [109.541828, 28.619498],
                            [109.549986, 28.622582],
                            [109.55756, 28.617812],
                            [109.564259, 28.618387],
                            [109.575067, 28.615054],
                            [109.582106, 28.609595],
                            [109.588306, 28.609595],
                            [109.593668, 28.616797],
                            [109.599127, 28.617468],
                            [109.600561, 28.614537],
                            [109.59661, 28.60925],
                            [109.598701, 28.602871],
                            [109.605838, 28.60128],
                            [109.613047, 28.592506],
                            [109.61977, 28.588655],
                            [109.621022, 28.572118],
                            [109.618846, 28.570278],
                            [109.62287, 28.565315],
                            [109.610956, 28.565238],
                            [109.603309, 28.567327],
                            [109.599625, 28.57185],
                            [109.593595, 28.572444],
                            [109.584696, 28.566541],
                            [109.581425, 28.556346],
                            [109.564928, 28.556614],
                            [109.559992, 28.554276],
                            [109.558642, 28.549158],
                            [109.566399, 28.53873],
                            [109.566763, 28.518428],
                            [109.564295, 28.514421],
                            [109.558144, 28.498908],
                            [109.547846, 28.491026],
                            [109.545293, 28.482012],
                            [109.545913, 28.478809],
                            [109.553037, 28.47225],
                            [109.552952, 28.465958],
                            [109.560903, 28.460126],
                            [109.560016, 28.453489],
                            [109.563712, 28.447618],
                            [109.569025, 28.447234],
                            [109.574556, 28.454064],
                            [109.585073, 28.455465],
                            [109.592331, 28.454525],
                            [109.601376, 28.449843],
                            [109.603528, 28.445776],
                            [109.602263, 28.439732],
                            [109.604901, 28.436125],
                            [109.615807, 28.440231],
                            [109.634079, 28.438696],
                            [109.638456, 28.43576],
                            [109.648741, 28.424554],
                            [109.647319, 28.418375],
                            [109.636766, 28.410295],
                            [109.624013, 28.405516],
                            [109.625253, 28.403136],
                            [109.633192, 28.402579],
                            [109.637459, 28.404019],
                            [109.644693, 28.400564],
                            [109.649313, 28.400794],
                            [109.654808, 28.407781],
                            [109.672971, 28.414037],
                            [109.67871, 28.414786],
                            [109.692983, 28.420332],
                            [109.704812, 28.428987],
                            [109.717042, 28.426895],
                            [109.729139, 28.432901],
                            [109.746804, 28.436125],
                            [109.758086, 28.43292],
                            [109.763776, 28.434532],
                            [109.768882, 28.433515],
                            [109.77693, 28.435856],
                            [109.785793, 28.433151],
                            [109.795531, 28.436605],
                            [109.803178, 28.44497],
                            [109.808175, 28.456731],
                            [109.805756, 28.465344],
                            [109.806838, 28.46755],
                            [109.823785, 28.473017],
                            [109.830739, 28.490202],
                            [109.830034, 28.498966],
                            [109.821293, 28.498314],
                            [109.817184, 28.499752],
                            [109.811154, 28.497643],
                            [109.805002, 28.499618],
                            [109.802777, 28.505256],
                            [109.797002, 28.503741],
                            [109.789963, 28.511564],
                            [109.788966, 28.521017],
                            [109.789805, 28.532021],
                            [109.791921, 28.536833],
                            [109.807057, 28.550059],
                            [109.810485, 28.556921],
                            [109.810522, 28.564721],
                            [109.816552, 28.576621],
                            [109.825171, 28.583462],
                            [109.829183, 28.602468],
                            [109.833913, 28.610476],
                            [109.835007, 28.618272],
                            [109.831651, 28.622658],
                            [109.829341, 28.629784],
                            [109.822412, 28.642443],
                            [109.818789, 28.646579],
                            [109.812576, 28.649834],
                            [109.801926, 28.65196],
                            [109.794887, 28.65106],
                            [109.771374, 28.641083],
                            [109.764311, 28.641753],
                            [109.75625, 28.652534],
                            [109.758755, 28.655828],
                            [109.761563, 28.669786],
                            [109.755594, 28.674323],
                            [109.757357, 28.680851],
                            [109.766536, 28.692643],
                            [109.767022, 28.700299],
                            [109.78217, 28.712108],
                            [109.785355, 28.716222],
                            [109.78493, 28.719992],
                            [109.780116, 28.726173],
                            [109.758706, 28.722748],
                            [109.74943, 28.72271],
                            [109.736835, 28.728048],
                            [109.721674, 28.729713],
                            [109.720252, 28.732526],
                            [109.72227, 28.744637],
                            [109.720045, 28.748865],
                            [109.712362, 28.755962],
                            [109.707693, 28.758047],
                            [109.71365, 28.762963],
                            [109.729115, 28.766865],
                            [109.737516, 28.779221],
                            [109.733066, 28.788803],
                            [109.721954, 28.794998],
                            [109.723109, 28.803316],
                            [109.718635, 28.803909],
                            [109.718635, 28.807886],
                            [109.707632, 28.819281],
                            [109.704605, 28.827922],
                            [109.705955, 28.832797],
                            [109.702429, 28.844705],
                            [109.694855, 28.851796],
                            [109.676108, 28.859899],
                            [109.676448, 28.863855],
                            [109.68074, 28.866301],
                            [109.680862, 28.871135],
                            [109.684351, 28.877861],
                            [109.683317, 28.883402],
                            [109.685469, 28.88709],
                            [109.681457, 28.893662],
                            [109.677482, 28.89391],
                            [109.659695, 28.889038],
                            [109.654759, 28.892229],
                            [109.649836, 28.892458],
                            [109.647343, 28.896413],
                            [109.638517, 28.89712],
                            [109.622846, 28.904875],
                            [109.614202, 28.903194],
                            [109.607649, 28.906728],
                            [109.591151, 28.912077],
                            [109.582386, 28.900711],
                            [109.571322, 28.884109],
                            [109.56663, 28.879084],
                            [109.559931, 28.860377],
                            [109.553062, 28.847954],
                            [109.549962, 28.83769],
                            [109.544941, 28.827922],
                            [109.54105, 28.82754],
                            [109.528674, 28.83683],
                            [109.518936, 28.840768],
                            [109.515045, 28.848031],
                            [109.502292, 28.854643],
                            [109.49749, 28.860835],
                            [109.493806, 28.860874],
                            [109.490001, 28.857243],
                            [109.476445, 28.863683],
                            [109.477333, 28.859192],
                            [109.482816, 28.853841],
                            [109.484068, 28.849598],
                            [109.473892, 28.850209],
                            [109.467449, 28.854854],
                            [109.462975, 28.85537],
                            [109.454355, 28.853134],
                            [109.450258, 28.85772],
                            [109.445188, 28.867333],
                            [109.44058, 28.866415],
                            [109.429809, 28.855198],
                            [109.418028, 28.850802],
                            [109.409554, 28.845412],
                            [109.403597, 28.839047],
                            [109.396424, 28.827291],
                            [109.392814, 28.819205],
                            [109.38361, 28.809722],
                            [109.382528, 28.805859],
                            [109.38327, 28.795802],
                            [109.381471, 28.791595],
                            [109.369909, 28.78253],
                            [109.365046, 28.78144],
                            [109.342299, 28.783869],
                            [109.329838, 28.782989],
                            [109.324513, 28.784576],
                            [109.318786, 28.794272],
                            [109.312063, 28.795056],
                            [109.308027, 28.793201],
                            [109.298727, 28.778514],
                            [109.295164, 28.77641],
                            [109.282387, 28.777309],
                            [109.273937, 28.769601],
                            [109.270254, 28.76853],
                            [109.261318, 28.774956],
                            [109.260175, 28.767975],
                            [109.2562, 28.765622],
                            [109.270387, 28.760496],
                            [109.273585, 28.76063],
                            [109.279943, 28.755828],
                            [109.278472, 28.751524],
                            [109.292599, 28.750242],
                            [109.297669, 28.748157],
                            [109.300647, 28.739682],
                            [109.29914, 28.736735],
                            [109.289414, 28.730268],
                            [109.288235, 28.727302],
                            [109.294459, 28.722289],
                            [109.294654, 28.719131],
                            [109.284685, 28.713639],
                            [109.277731, 28.705505],
                            [109.273257, 28.705754],
                            [109.270704, 28.698557],
                            [109.265002, 28.699457],
                            [109.257379, 28.696088],
                            [109.253343, 28.692126],
                            [109.254157, 28.686671],
                            [109.258437, 28.682976],
                            [109.266181, 28.680181],
                            [109.270898, 28.671796],
                            [109.266995, 28.668178],
                            [109.251762, 28.660845],
                            [109.23434, 28.653607],
                            [109.221077, 28.649126],
                            [109.211958, 28.644358],
                            [109.202913, 28.637981],
                            [109.195825, 28.637846],
                            [109.190999, 28.632618],
                            [109.189491, 28.627294],
                            [109.181407, 28.62172],
                            [109.183777, 28.615629],
                            [109.188203, 28.615533],
                            [109.186343, 28.610648],
                            [109.200202, 28.602372],
                            [109.201479, 28.598426],
                            [109.212664, 28.607219],
                            [109.21715, 28.607468],
                            [109.226438, 28.613732],
                            [109.229575, 28.617985],
                            [109.235495, 28.619594],
                            [109.244881, 28.614001],
                            [109.249391, 28.608656],
                            [109.258886, 28.605591],
                            [109.269755, 28.612008],
                            [109.278047, 28.612334],
                            [109.278655, 28.617008],
                            [109.286995, 28.626834],
                            [109.294058, 28.625302],
                            [109.300149, 28.626432],
                            [109.306629, 28.620666],
                            [109.309048, 28.607622],
                            [109.304599, 28.601721],
                            [109.309352, 28.598828],
                            [109.311455, 28.592697],
                            [109.315881, 28.586854],
                            [109.320768, 28.586298],
                            [109.320452, 28.579783]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 433126,
                "name": "古丈县",
                "center": [109.949592, 28.616973],
                "centroid": [109.998289, 28.59819],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 433100
                },
                "subFeatureIndex": 5,
                "acroutes": [100000, 430000, 433100]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [110.274464, 28.715533],
                            [110.257432, 28.712969],
                            [110.238685, 28.704051],
                            [110.228327, 28.705601],
                            [110.200231, 28.721313],
                            [110.190857, 28.722939],
                            [110.168378, 28.718461],
                            [110.161521, 28.718098],
                            [110.1472, 28.719954],
                            [110.140002, 28.722059],
                            [110.132708, 28.726269],
                            [110.123954, 28.734076],
                            [110.11452, 28.740619],
                            [110.096527, 28.750644],
                            [110.078266, 28.757454],
                            [110.070036, 28.757148],
                            [110.052638, 28.750491],
                            [110.044943, 28.741212],
                            [110.040238, 28.738591],
                            [110.032834, 28.740007],
                            [110.02695, 28.739241],
                            [110.023156, 28.735243],
                            [110.022026, 28.720643],
                            [110.020251, 28.716624],
                            [110.008081, 28.71406],
                            [109.999048, 28.710098],
                            [109.992945, 28.709007],
                            [109.984569, 28.710117],
                            [109.972362, 28.716892],
                            [109.961931, 28.728125],
                            [109.944789, 28.736238],
                            [109.936255, 28.743967],
                            [109.92958, 28.744885],
                            [109.911283, 28.738017],
                            [109.902396, 28.737328],
                            [109.897691, 28.740657],
                            [109.886713, 28.752194],
                            [109.882166, 28.758965],
                            [109.876245, 28.761414],
                            [109.863844, 28.763403],
                            [109.851164, 28.761318],
                            [109.83818, 28.76172],
                            [109.815834, 28.749687],
                            [109.79034, 28.738266],
                            [109.780116, 28.726173],
                            [109.78493, 28.719992],
                            [109.785355, 28.716222],
                            [109.78217, 28.712108],
                            [109.767022, 28.700299],
                            [109.766536, 28.692643],
                            [109.757357, 28.680851],
                            [109.755594, 28.674323],
                            [109.761563, 28.669786],
                            [109.758755, 28.655828],
                            [109.75625, 28.652534],
                            [109.764311, 28.641753],
                            [109.771374, 28.641083],
                            [109.794887, 28.65106],
                            [109.801926, 28.65196],
                            [109.812576, 28.649834],
                            [109.818789, 28.646579],
                            [109.822412, 28.642443],
                            [109.829341, 28.629784],
                            [109.831651, 28.622658],
                            [109.835007, 28.618272],
                            [109.833913, 28.610476],
                            [109.829183, 28.602468],
                            [109.825171, 28.583462],
                            [109.816552, 28.576621],
                            [109.810522, 28.564721],
                            [109.810485, 28.556921],
                            [109.807057, 28.550059],
                            [109.791921, 28.536833],
                            [109.789805, 28.532021],
                            [109.788966, 28.521017],
                            [109.789963, 28.511564],
                            [109.797002, 28.503741],
                            [109.802777, 28.505256],
                            [109.805002, 28.499618],
                            [109.811154, 28.497643],
                            [109.817184, 28.499752],
                            [109.821293, 28.498314],
                            [109.830034, 28.498966],
                            [109.830739, 28.490202],
                            [109.823785, 28.473017],
                            [109.833378, 28.469718],
                            [109.838362, 28.461584],
                            [109.842204, 28.461584],
                            [109.848113, 28.466418],
                            [109.854471, 28.468682],
                            [109.860003, 28.468413],
                            [109.865194, 28.463714],
                            [109.8734, 28.449095],
                            [109.875783, 28.44852],
                            [109.885789, 28.453757],
                            [109.904195, 28.450688],
                            [109.903478, 28.443857],
                            [109.891454, 28.436087],
                            [109.883868, 28.415899],
                            [109.884622, 28.410851],
                            [109.891843, 28.403481],
                            [109.919076, 28.394421],
                            [109.933641, 28.396437],
                            [109.947257, 28.404594],
                            [109.95719, 28.401926],
                            [109.963706, 28.408107],
                            [109.970903, 28.409239],
                            [109.980447, 28.416993],
                            [109.984362, 28.426358],
                            [109.991547, 28.427931],
                            [109.994586, 28.434398],
                            [109.998671, 28.435377],
                            [110.007461, 28.433515],
                            [110.010792, 28.434667],
                            [110.031156, 28.435875],
                            [110.036663, 28.442418],
                            [110.045162, 28.443358],
                            [110.052055, 28.4419],
                            [110.057939, 28.436892],
                            [110.059629, 28.430196],
                            [110.055921, 28.426281],
                            [110.063872, 28.426876],
                            [110.067057, 28.441478],
                            [110.070814, 28.447311],
                            [110.088211, 28.451551],
                            [110.103019, 28.452683],
                            [110.106046, 28.462064],
                            [110.10922, 28.463637],
                            [110.121717, 28.462102],
                            [110.127371, 28.459954],
                            [110.128368, 28.455503],
                            [110.126362, 28.448635],
                            [110.135626, 28.446735],
                            [110.138142, 28.447733],
                            [110.141656, 28.4565],
                            [110.157728, 28.461412],
                            [110.1636, 28.467531],
                            [110.155053, 28.473343],
                            [110.14861, 28.483662],
                            [110.150871, 28.517987],
                            [110.150871, 28.527803],
                            [110.155807, 28.532826],
                            [110.167114, 28.535031],
                            [110.167199, 28.542507],
                            [110.165448, 28.556997],
                            [110.167539, 28.574763],
                            [110.172475, 28.584248],
                            [110.177581, 28.585742],
                            [110.18586, 28.582715],
                            [110.188632, 28.574973],
                            [110.207549, 28.575107],
                            [110.211075, 28.566561],
                            [110.224217, 28.563303],
                            [110.233858, 28.568247],
                            [110.23663, 28.573364],
                            [110.229919, 28.587543],
                            [110.230843, 28.593176],
                            [110.227245, 28.597966],
                            [110.224558, 28.612066],
                            [110.230843, 28.615916],
                            [110.222053, 28.620801],
                            [110.213166, 28.623329],
                            [110.212084, 28.625666],
                            [110.217434, 28.637961],
                            [110.218613, 28.644166],
                            [110.223135, 28.651462],
                            [110.228667, 28.653147],
                            [110.235962, 28.646005],
                            [110.243341, 28.644741],
                            [110.242575, 28.653051],
                            [110.243998, 28.658164],
                            [110.251049, 28.659926],
                            [110.257675, 28.67239],
                            [110.259863, 28.673309],
                            [110.27027, 28.671394],
                            [110.273711, 28.689427],
                            [110.271535, 28.70072],
                            [110.274464, 28.715533]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 433127,
                "name": "永顺县",
                "center": [109.853292, 28.998068],
                "centroid": [109.970385, 29.010212],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 433100
                },
                "subFeatureIndex": 6,
                "acroutes": [100000, 430000, 433100]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [109.828916, 29.373306],
                            [109.776104, 29.334887],
                            [109.771909, 29.328419],
                            [109.781137, 29.319478],
                            [109.785574, 29.310155],
                            [109.785817, 29.304694],
                            [109.779277, 29.30203],
                            [109.77828, 29.298414],
                            [109.763946, 29.284313],
                            [109.750232, 29.268743],
                            [109.739206, 29.2617],
                            [109.731425, 29.25894],
                            [109.710635, 29.259816],
                            [109.705432, 29.249154],
                            [109.701979, 29.248469],
                            [109.695888, 29.251801],
                            [109.690673, 29.250449],
                            [109.672181, 29.233865],
                            [109.664109, 29.222364],
                            [109.664194, 29.209813],
                            [109.662589, 29.204766],
                            [109.656547, 29.17442],
                            [109.655355, 29.158338],
                            [109.652668, 29.150868],
                            [109.648717, 29.123613],
                            [109.646614, 29.120335],
                            [109.638553, 29.115035],
                            [109.63594, 29.11025],
                            [109.633362, 29.089506],
                            [109.634578, 29.064028],
                            [109.63662, 29.04957],
                            [109.636596, 29.042683],
                            [109.632852, 29.024787],
                            [109.619588, 28.979806],
                            [109.615345, 28.968104],
                            [109.611357, 28.94884],
                            [109.606336, 28.934155],
                            [109.596877, 28.919029],
                            [109.591151, 28.912077],
                            [109.607649, 28.906728],
                            [109.614202, 28.903194],
                            [109.622846, 28.904875],
                            [109.638517, 28.89712],
                            [109.647343, 28.896413],
                            [109.649836, 28.892458],
                            [109.654759, 28.892229],
                            [109.659695, 28.889038],
                            [109.677482, 28.89391],
                            [109.681457, 28.893662],
                            [109.685469, 28.88709],
                            [109.683317, 28.883402],
                            [109.684351, 28.877861],
                            [109.680862, 28.871135],
                            [109.68074, 28.866301],
                            [109.676448, 28.863855],
                            [109.676108, 28.859899],
                            [109.694855, 28.851796],
                            [109.702429, 28.844705],
                            [109.705955, 28.832797],
                            [109.704605, 28.827922],
                            [109.707632, 28.819281],
                            [109.718635, 28.807886],
                            [109.718635, 28.803909],
                            [109.723109, 28.803316],
                            [109.721954, 28.794998],
                            [109.733066, 28.788803],
                            [109.737516, 28.779221],
                            [109.729115, 28.766865],
                            [109.71365, 28.762963],
                            [109.707693, 28.758047],
                            [109.712362, 28.755962],
                            [109.720045, 28.748865],
                            [109.72227, 28.744637],
                            [109.720252, 28.732526],
                            [109.721674, 28.729713],
                            [109.736835, 28.728048],
                            [109.74943, 28.72271],
                            [109.758706, 28.722748],
                            [109.780116, 28.726173],
                            [109.79034, 28.738266],
                            [109.815834, 28.749687],
                            [109.83818, 28.76172],
                            [109.851164, 28.761318],
                            [109.863844, 28.763403],
                            [109.876245, 28.761414],
                            [109.882166, 28.758965],
                            [109.886713, 28.752194],
                            [109.897691, 28.740657],
                            [109.902396, 28.737328],
                            [109.911283, 28.738017],
                            [109.92958, 28.744885],
                            [109.936255, 28.743967],
                            [109.944789, 28.736238],
                            [109.961931, 28.728125],
                            [109.972362, 28.716892],
                            [109.984569, 28.710117],
                            [109.992945, 28.709007],
                            [109.999048, 28.710098],
                            [110.008081, 28.71406],
                            [110.020251, 28.716624],
                            [110.022026, 28.720643],
                            [110.023156, 28.735243],
                            [110.02695, 28.739241],
                            [110.032834, 28.740007],
                            [110.040238, 28.738591],
                            [110.044943, 28.741212],
                            [110.052638, 28.750491],
                            [110.070036, 28.757148],
                            [110.078266, 28.757454],
                            [110.096527, 28.750644],
                            [110.11452, 28.740619],
                            [110.123954, 28.734076],
                            [110.132708, 28.726269],
                            [110.140002, 28.722059],
                            [110.1472, 28.719954],
                            [110.161521, 28.718098],
                            [110.168378, 28.718461],
                            [110.190857, 28.722939],
                            [110.200231, 28.721313],
                            [110.228327, 28.705601],
                            [110.238685, 28.704051],
                            [110.257432, 28.712969],
                            [110.274464, 28.715533],
                            [110.278209, 28.717466],
                            [110.279218, 28.726881],
                            [110.282659, 28.731416],
                            [110.285516, 28.743508],
                            [110.282914, 28.754087],
                            [110.285358, 28.765507],
                            [110.292907, 28.766521],
                            [110.303412, 28.759157],
                            [110.310888, 28.757148],
                            [110.330875, 28.760037],
                            [110.340395, 28.769849],
                            [110.342607, 28.781994],
                            [110.340492, 28.801748],
                            [110.343167, 28.809072],
                            [110.344784, 28.820753],
                            [110.365901, 28.846119],
                            [110.372624, 28.847266],
                            [110.377585, 28.850248],
                            [110.383578, 28.857395],
                            [110.395031, 28.861084],
                            [110.396331, 28.864199],
                            [110.389754, 28.874422],
                            [110.390423, 28.881129],
                            [110.384672, 28.885447],
                            [110.358753, 28.891236],
                            [110.358911, 28.897693],
                            [110.364795, 28.907951],
                            [110.365476, 28.918437],
                            [110.361099, 28.927452],
                            [110.355543, 28.931061],
                            [110.337136, 28.939502],
                            [110.331009, 28.941163],
                            [110.314572, 28.938318],
                            [110.308129, 28.944314],
                            [110.3065, 28.958272],
                            [110.319192, 28.967283],
                            [110.318985, 28.973144],
                            [110.325502, 28.975778],
                            [110.331933, 28.981333],
                            [110.327982, 28.983852],
                            [110.335836, 28.994751],
                            [110.336845, 28.999789],
                            [110.346218, 29.011125],
                            [110.347361, 29.019426],
                            [110.341623, 29.027268],
                            [110.344261, 29.036349],
                            [110.340334, 29.041329],
                            [110.332565, 29.038639],
                            [110.323107, 29.041729],
                            [110.314001, 29.035892],
                            [110.305296, 29.038581],
                            [110.299874, 29.047319],
                            [110.299618, 29.057142],
                            [110.303412, 29.07713],
                            [110.304956, 29.079686],
                            [110.30419, 29.091356],
                            [110.299448, 29.090631],
                            [110.292287, 29.086093],
                            [110.284421, 29.085445],
                            [110.27675, 29.080239],
                            [110.272094, 29.073049],
                            [110.263705, 29.069711],
                            [110.249639, 29.07692],
                            [110.248678, 29.082279],
                            [110.250514, 29.093663],
                            [110.253116, 29.098525],
                            [110.259644, 29.102014],
                            [110.264058, 29.110155],
                            [110.261638, 29.11961],
                            [110.256082, 29.122241],
                            [110.239013, 29.122241],
                            [110.236764, 29.124872],
                            [110.226576, 29.126949],
                            [110.221251, 29.125748],
                            [110.216728, 29.129122],
                            [110.223974, 29.136441],
                            [110.221482, 29.139129],
                            [110.215902, 29.136079],
                            [110.214856, 29.141092],
                            [110.205799, 29.151364],
                            [110.203927, 29.161044],
                            [110.200036, 29.163807],
                            [110.189629, 29.162816],
                            [110.185253, 29.154965],
                            [110.179064, 29.153975],
                            [110.171709, 29.16093],
                            [110.164852, 29.165503],
                            [110.158178, 29.165198],
                            [110.147005, 29.153403],
                            [110.140817, 29.15226],
                            [110.140404, 29.145399],
                            [110.134118, 29.137966],
                            [110.128404, 29.136403],
                            [110.119152, 29.141244],
                            [110.112137, 29.142826],
                            [110.105001, 29.142254],
                            [110.099846, 29.139605],
                            [110.093038, 29.14376],
                            [110.089038, 29.151249],
                            [110.090594, 29.15912],
                            [110.090521, 29.167313],
                            [110.084807, 29.184441],
                            [110.079847, 29.189013],
                            [110.076881, 29.200842],
                            [110.071957, 29.205242],
                            [110.07006, 29.211889],
                            [110.07316, 29.22562],
                            [110.080613, 29.23259],
                            [110.081926, 29.236226],
                            [110.079677, 29.243443],
                            [110.08285, 29.251192],
                            [110.087287, 29.251934],
                            [110.096187, 29.250316],
                            [110.103542, 29.253419],
                            [110.11142, 29.27196],
                            [110.113535, 29.279022],
                            [110.112563, 29.289699],
                            [110.103335, 29.302601],
                            [110.097196, 29.308842],
                            [110.080564, 29.318831],
                            [110.068443, 29.323777],
                            [110.063057, 29.322541],
                            [110.049198, 29.314569],
                            [110.035436, 29.304066],
                            [110.01636, 29.28829],
                            [110.0105, 29.289013],
                            [110.00154, 29.295408],
                            [109.991778, 29.297444],
                            [109.975499, 29.299176],
                            [109.967584, 29.303742],
                            [109.959439, 29.311943],
                            [109.951281, 29.318051],
                            [109.941105, 29.320733],
                            [109.936145, 29.325147],
                            [109.952922, 29.333822],
                            [109.960363, 29.34126],
                            [109.960776, 29.347365],
                            [109.954795, 29.358168],
                            [109.947208, 29.362086],
                            [109.945154, 29.369902],
                            [109.952667, 29.383497],
                            [109.952412, 29.38692],
                            [109.945275, 29.398422],
                            [109.940315, 29.422829],
                            [109.935878, 29.434403],
                            [109.929981, 29.441833],
                            [109.925617, 29.444417],
                            [109.915137, 29.445082],
                            [109.908414, 29.441738],
                            [109.851894, 29.392566],
                            [109.828916, 29.373306]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 433130,
                "name": "龙山县",
                "center": [109.441189, 29.453438],
                "centroid": [109.509485, 29.219406],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 433100
                },
                "subFeatureIndex": 7,
                "acroutes": [100000, 430000, 433100]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [109.828916, 29.373306],
                            [109.82122, 29.38865],
                            [109.814862, 29.392566],
                            [109.80364, 29.404676],
                            [109.79451, 29.411652],
                            [109.795264, 29.417108],
                            [109.802972, 29.424976],
                            [109.808248, 29.427105],
                            [109.807579, 29.435087],
                            [109.802716, 29.439629],
                            [109.80189, 29.448864],
                            [109.788395, 29.46395],
                            [109.77935, 29.478046],
                            [109.778766, 29.486897],
                            [109.77056, 29.490525],
                            [109.76577, 29.500002],
                            [109.752944, 29.50908],
                            [109.749843, 29.518309],
                            [109.744847, 29.523872],
                            [109.740944, 29.534277],
                            [109.734646, 29.540997],
                            [109.738756, 29.547622],
                            [109.749175, 29.549483],
                            [109.750427, 29.553678],
                            [109.76008, 29.56165],
                            [109.782377, 29.576605],
                            [109.779362, 29.582488],
                            [109.768554, 29.587326],
                            [109.76487, 29.598654],
                            [109.769563, 29.606528],
                            [109.766961, 29.612409],
                            [109.761673, 29.615178],
                            [109.742877, 29.614761],
                            [109.731376, 29.60888],
                            [109.726926, 29.607856],
                            [109.71996, 29.609696],
                            [109.715547, 29.613149],
                            [109.705006, 29.608387],
                            [109.678771, 29.607988],
                            [109.663476, 29.59966],
                            [109.657714, 29.601804],
                            [109.651027, 29.610113],
                            [109.652133, 29.62379],
                            [109.65082, 29.626465],
                            [109.641447, 29.623885],
                            [109.634566, 29.625213],
                            [109.609461, 29.634488],
                            [109.591467, 29.632288],
                            [109.578349, 29.629689],
                            [109.575407, 29.627887],
                            [109.575626, 29.619845],
                            [109.569718, 29.61385],
                            [109.558654, 29.60704],
                            [109.550411, 29.612788],
                            [109.53113, 29.615615],
                            [109.533002, 29.620945],
                            [109.529768, 29.625194],
                            [109.524601, 29.624284],
                            [109.516504, 29.625991],
                            [109.51383, 29.623468],
                            [109.514291, 29.618005],
                            [109.518158, 29.612466],
                            [109.525027, 29.608804],
                            [109.519823, 29.608083],
                            [109.518559, 29.602733],
                            [109.514522, 29.600286],
                            [109.50679, 29.604422],
                            [109.501477, 29.605446],
                            [109.496469, 29.603018],
                            [109.495921, 29.59873],
                            [109.503654, 29.597724],
                            [109.504031, 29.588617],
                            [109.497624, 29.568103],
                            [109.491448, 29.560777],
                            [109.488676, 29.553184],
                            [109.479898, 29.552748],
                            [109.467035, 29.55996],
                            [109.461832, 29.554247],
                            [109.459291, 29.543712],
                            [109.458598, 29.530536],
                            [109.465114, 29.516942],
                            [109.463668, 29.512802],
                            [109.458574, 29.513182],
                            [109.454708, 29.517226],
                            [109.44762, 29.52072],
                            [109.446513, 29.52879],
                            [109.435717, 29.531125],
                            [109.430988, 29.529055],
                            [109.429906, 29.521802],
                            [109.432107, 29.518859],
                            [109.431341, 29.505282],
                            [109.439462, 29.496413],
                            [109.440605, 29.493165],
                            [109.434879, 29.489063],
                            [109.418308, 29.498008],
                            [109.413846, 29.495824],
                            [109.409992, 29.490601],
                            [109.412837, 29.484713],
                            [109.41088, 29.476393],
                            [109.405384, 29.469915],
                            [109.408241, 29.46205],
                            [109.417129, 29.454792],
                            [109.418709, 29.450707],
                            [109.416411, 29.448161],
                            [109.411329, 29.450612],
                            [109.40226, 29.444588],
                            [109.406418, 29.441282],
                            [109.404764, 29.434403],
                            [109.397057, 29.439116],
                            [109.389507, 29.438089],
                            [109.386662, 29.432711],
                            [109.373605, 29.425775],
                            [109.368596, 29.411348],
                            [109.376681, 29.405418],
                            [109.385276, 29.393555],
                            [109.382054, 29.382908],
                            [109.386419, 29.381919],
                            [109.391902, 29.37496],
                            [109.391464, 29.372203],
                            [109.376766, 29.376557],
                            [109.373106, 29.381463],
                            [109.36879, 29.382908],
                            [109.347381, 29.3738],
                            [109.343977, 29.369426],
                            [109.343515, 29.352805],
                            [109.340147, 29.335971],
                            [109.344111, 29.323226],
                            [109.345278, 29.314664],
                            [109.344098, 29.306425],
                            [109.352256, 29.305227],
                            [109.354274, 29.296416],
                            [109.351308, 29.288804],
                            [109.352183, 29.284636],
                            [109.326823, 29.260691],
                            [109.315054, 29.252105],
                            [109.286071, 29.242034],
                            [109.275408, 29.235351],
                            [109.261379, 29.227791],
                            [109.257853, 29.222592],
                            [109.259361, 29.21806],
                            [109.263482, 29.215698],
                            [109.275238, 29.202404],
                            [109.276077, 29.200309],
                            [109.271032, 29.183222],
                            [109.271202, 29.17743],
                            [109.273889, 29.172572],
                            [109.266327, 29.167275],
                            [109.261464, 29.161063],
                            [109.26037, 29.152336],
                            [109.265986, 29.141854],
                            [109.277147, 29.134059],
                            [109.276515, 29.124262],
                            [109.274156, 29.122127],
                            [109.263531, 29.121974],
                            [109.255956, 29.123232],
                            [109.250875, 29.118028],
                            [109.245343, 29.115092],
                            [109.232565, 29.119458],
                            [109.231787, 29.115607],
                            [109.225988, 29.113262],
                            [109.228371, 29.111261],
                            [109.230207, 29.103292],
                            [109.234511, 29.096828],
                            [109.235033, 29.088839],
                            [109.23727, 29.086722],
                            [109.257768, 29.08676],
                            [109.269305, 29.07856],
                            [109.275019, 29.078122],
                            [109.287833, 29.070989],
                            [109.294593, 29.069197],
                            [109.301158, 29.070665],
                            [109.312246, 29.066336],
                            [109.314227, 29.055559],
                            [109.319577, 29.046632],
                            [109.319625, 29.042569],
                            [109.312136, 29.035147],
                            [109.30483, 29.020819],
                            [109.295456, 29.016411],
                            [109.294946, 29.009828],
                            [109.292368, 29.004675],
                            [109.292441, 28.987651],
                            [109.288247, 28.977019],
                            [109.284174, 28.972323],
                            [109.272345, 28.970948],
                            [109.268467, 28.966596],
                            [109.26105, 28.95052],
                            [109.255422, 28.926134],
                            [109.257573, 28.918857],
                            [109.256844, 28.907741],
                            [109.251422, 28.90734],
                            [109.247458, 28.897387],
                            [109.239921, 28.892038],
                            [109.235629, 28.882275],
                            [109.234887, 28.868403],
                            [109.238061, 28.865747],
                            [109.234061, 28.863817],
                            [109.240152, 28.859077],
                            [109.242668, 28.851069],
                            [109.240614, 28.839162],
                            [109.242571, 28.834555],
                            [109.239665, 28.827157],
                            [109.242389, 28.817235],
                            [109.245829, 28.812972],
                            [109.246413, 28.801672],
                            [109.241902, 28.794291],
                            [109.239738, 28.782798],
                            [109.241307, 28.776524],
                            [109.249635, 28.773407],
                            [109.256212, 28.775683],
                            [109.261318, 28.774956],
                            [109.270254, 28.76853],
                            [109.273937, 28.769601],
                            [109.282387, 28.777309],
                            [109.295164, 28.77641],
                            [109.298727, 28.778514],
                            [109.308027, 28.793201],
                            [109.312063, 28.795056],
                            [109.318786, 28.794272],
                            [109.324513, 28.784576],
                            [109.329838, 28.782989],
                            [109.342299, 28.783869],
                            [109.365046, 28.78144],
                            [109.369909, 28.78253],
                            [109.381471, 28.791595],
                            [109.38327, 28.795802],
                            [109.382528, 28.805859],
                            [109.38361, 28.809722],
                            [109.392814, 28.819205],
                            [109.396424, 28.827291],
                            [109.403597, 28.839047],
                            [109.409554, 28.845412],
                            [109.418028, 28.850802],
                            [109.429809, 28.855198],
                            [109.44058, 28.866415],
                            [109.445188, 28.867333],
                            [109.450258, 28.85772],
                            [109.454355, 28.853134],
                            [109.462975, 28.85537],
                            [109.467449, 28.854854],
                            [109.473892, 28.850209],
                            [109.484068, 28.849598],
                            [109.482816, 28.853841],
                            [109.477333, 28.859192],
                            [109.476445, 28.863683],
                            [109.490001, 28.857243],
                            [109.493806, 28.860874],
                            [109.49749, 28.860835],
                            [109.502292, 28.854643],
                            [109.515045, 28.848031],
                            [109.518936, 28.840768],
                            [109.528674, 28.83683],
                            [109.54105, 28.82754],
                            [109.544941, 28.827922],
                            [109.549962, 28.83769],
                            [109.553062, 28.847954],
                            [109.559931, 28.860377],
                            [109.56663, 28.879084],
                            [109.571322, 28.884109],
                            [109.582386, 28.900711],
                            [109.591151, 28.912077],
                            [109.596877, 28.919029],
                            [109.606336, 28.934155],
                            [109.611357, 28.94884],
                            [109.615345, 28.968104],
                            [109.619588, 28.979806],
                            [109.632852, 29.024787],
                            [109.636596, 29.042683],
                            [109.63662, 29.04957],
                            [109.634578, 29.064028],
                            [109.633362, 29.089506],
                            [109.63594, 29.11025],
                            [109.638553, 29.115035],
                            [109.646614, 29.120335],
                            [109.648717, 29.123613],
                            [109.652668, 29.150868],
                            [109.655355, 29.158338],
                            [109.656547, 29.17442],
                            [109.662589, 29.204766],
                            [109.664194, 29.209813],
                            [109.664109, 29.222364],
                            [109.672181, 29.233865],
                            [109.690673, 29.250449],
                            [109.695888, 29.251801],
                            [109.701979, 29.248469],
                            [109.705432, 29.249154],
                            [109.710635, 29.259816],
                            [109.731425, 29.25894],
                            [109.739206, 29.2617],
                            [109.750232, 29.268743],
                            [109.763946, 29.284313],
                            [109.77828, 29.298414],
                            [109.779277, 29.30203],
                            [109.785817, 29.304694],
                            [109.785574, 29.310155],
                            [109.781137, 29.319478],
                            [109.771909, 29.328419],
                            [109.776104, 29.334887],
                            [109.828916, 29.373306]
                        ]
                    ]
                ]
            }
        }]
    });
}));