<template>
  <div class="visualization">
    <div class="visualization_top">
      <!-- <img src="../../../assets/svg/top.svg" alt="" /> -->
      <div style="color: #fff">{{ nowTime }}</div>
    </div>
    <div class="visualization_view">
      <div class="visualization_view_left">
        <div class="visualization_view_left1">
          <div class="visualization_view_circular">
            <div class="visualization_view_circular_c"></div>
          </div>
          <div class="visualization_view_data">
            <p>总数据</p>
            <span>{{ newData.total }}</span>
          </div>
          <div class="visualization_view_add">
            <p>今日新增</p>
            <span>{{ newData.totalNowAdd }} ↑</span>
          </div>
        </div>
        <div class="visualization_view_left1">
          <div class="visualization_view_circular">
            <div class="visualization_view_circular_c"></div>
          </div>
          <div class="visualization_view_data">
            <p>应用场景</p>
            <span>{{ newData.scenarioBasicTotal }}</span>
          </div>
          <div class="visualization_view_add">
            <p>今日新增</p>
            <span>{{ newData.scenarioBasicNowAdd }} ↑</span>
          </div>
        </div>
        <div class="visualization_view_left1">
          <div class="visualization_view_circular">
            <div class="visualization_view_circular_c"></div>
          </div>
          <div class="visualization_view_data">
            <p>知识数据</p>
            <span>{{ newData.knowledgeTotal }}</span>
          </div>
          <div class="visualization_view_add">
            <p>今日新增</p>
            <span>{{ newData.knowledgeNowAdd }} ↑</span>
          </div>
        </div>
        <div class="visualization_view_left1">
          <div class="visualization_view_circular">
            <div class="visualization_view_circular_c"></div>
          </div>
          <div class="visualization_view_data">
            <p>案例数据</p>
            <span>{{ newData.planTotal }}</span>
          </div>
          <div class="visualization_view_add">
            <p>今日新增</p>
            <span>{{ newData.planNowAdd }} ↑</span>
          </div>
        </div>
        <div class="visualization_view_round">
          <div class="visualization_view_round_img">
            <div class="visualization_view_round_img_text">各实体占比</div>
          </div>
          <div id="main" ref="myEacharts"></div>
        </div>
      </div>
      <div class="visualization_view_conter">
        <div class="visualization_view_conter_left">
          <div class="visualization_view_conter_left_back" @click="back"></div>
          <div
            class="visualization_view_conter_left_btn"
            :class="type === 0 ? 'new' : ''"
            @click="productClick"
          >
            产品视图
          </div>
          <div
            class="visualization_view_conter_left_btn"
            :class="type === 1 ? 'new' : ''"
            @click="enterpriseClick"
          >
            企业视图
          </div>
          <div
            class="visualization_view_conter_left_btn"
            :class="type === 2 ? 'new' : ''"
            @click="activityClick"
          >
            活动视图
          </div>
          <div
            class="visualization_view_conter_left_btn"
            :class="type === 3 ? 'new' : ''"
            @click="projectClick"
          >
            项目视图
          </div>
        </div>
        <div class="visualization_view_conter_hunan">
          <div class="title">{{ title }}</div>
          <div class="backimg"></div>
          <div class="visualization_view_map" ref="myEchart"></div>
        </div>
      </div>
      <div class="visualization_view_right">
        <div class="visualization_view_right_1">
          <div class="visualization_view_right_1_img">
            <div class="visualization_view_right_1_img_text">活动资讯</div>
          </div>
          <div class="visualization_view_right_1_img_height">
            <div class="visualization_view_right_1_ul inner-container">
              <div class="div_p" v-for="(item, index) in arrText" :key="index">
                <div
                  class="div_span"
                  :class="item.type === '资讯' ? 'activity' : 'real'"
                >
                  {{ item.type }}
                </div>
                <div class="div_text">{{ item.title }}</div>
                <div class="div_place">{{ item.source }}</div>
                <div class="div_time">
                  {{ parseTime(item.time, "{y}-{m}-{d}") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="visualization_view_right_3">
          <div class="visualization_view_right_3_img">
            <div class="visualization_view_right_3_img_text">知识数据</div>
          </div>
          <div class="visualization_view_right_3_conter">
            <div class="visualization_view_right_3_conter_round">
              <div class="visualization_view_right_3_conter_round1">
                <div class="visualization_view_right_round_text">
                  <div class="new_tag color1">关联分析</div>
                  <div class="new_tag color2">模具制造</div>
                  <div class="new_tag color1">智问</div>
                  <div class="new_tag color4">指标管理</div>
                  <div class="new_tag color1">其他材料合成</div>
                  <div class="new_tag color3">网络安全产业链</div>
                  <div class="new_tag color2">AR产业链</div>
                  <div class="new_tag color4">R语言</div>
                  <div class="new_tag color2">自助式</div>
                  <div class="new_tag color1">其他材料合成</div>
                  <div class="new_tag color3">网络安全产业链</div>
                  <div class="new_tag color2">AR产业链</div>
                  <div class="new_tag color4">R语言</div>
                  <div class="new_tag color2">自助式</div>
                  <div class="new_tag color2">AR产业链</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import "@/assets/js/common.js";
import { getLeftData, getMapData, textData } from "@/api/visualization";

export default {
  name: "Visualization",
  data() {
    return {
      path: `${process.env.VUE_APP_INDEX_API}`,
      socket: "",
      name: "产品视图",
      title: "湖南省",
      type: 0, //地图数据类型type
      nowTime: "",
      arrText: [],
      mapList: [],
      newData: {
        companyNowAdd: "",
        companyTotal: "",
        knowledgeNowAdd: "",
        knowledgeTotal: "",
        planNowAdd: "",
        planTotal: "",
        policyNowAdd: "",
        policyTotal: "",
        productNowAdd: "",
        productTotal: "",
        projectNowAdd: "",
        projectTotal: "",
        reportNowAdd: "",
        reportTotal: "",
        scenarioBasicNowAdd: "",
        scenarioBasicTotal: "",
        total: "",
        totalNowAdd: "",
      },
    };
  },
  created() {
    this.nowTimes();
    this.getData();
    this.getDataText();
  },
  mounted() {
    // 初始化
    this.init();
    this.getDataMap();
  },
  destroyed() {
    // 销毁监听
    this.socket.onclose = this.close;
  },
  methods: {
    //十六进制颜色随机
    color16() {
      var r = Math.floor(Math.random() * 256);
      var g = Math.floor(Math.random() * 256);
      var b = Math.floor(Math.random() * 256);
      var color = "#" + r.toString(16) + g.toString(16) + b.toString(16);
      return color;
    },
    //解析时间
    parseTime(time, fm) {
      // 解析时间  time: 时间戳或者实践对象 fm: 格式 默认是{y}-{m}-{d} {h}:{i}:{s}
      if (arguments.length === 0) {
        return null;
      }
      const format = fm || "{y}-{m}-{d} {h}:{i}:{s}";
      let date;
      if (typeof time === "object") {
        date = time;
      } else {
        if (("" + time).length === 10) time = parseInt(time) * 1000;
        date = new Date(time);
      }
      const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay(),
      };
      const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key];
        if (key === "a")
          return ["一", "二", "三", "四", "五", "六", "日"][value - 1];
        if (result.length > 0 && value < 10) {
          value = "0" + value;
        }
        return value || 0;
      });
      return time_str;
    },
    //获取右侧文字滚动数据
    async getDataText() {
      const query = {
        size: 15,
      };
      const res = await textData(query);
      if (res.code === 200) {
        this.arrText = res.data;
      }
    },
    //返回按钮
    back() {
      this.title = "湖南省";
      this.hunanMap();
    },
    //画湖南地图
    hunanMap() {
      const mapList = JSON.parse(JSON.stringify(this.mapList));
      const nameItem = this.name;
      const option = {
        series: [
          {
            type: "map",
            map: "湖南",
            roam: true,
            zoom: 1.2,
            label: {
              show: true,
              color: "#333",
              fontSize: 12,
            },
            name: nameItem,
            data: mapList,
          },
        ],
        tooltip: {
          trigger: "item",
        },
        visualMap: [
          {
            type: "piecewise",
            show: true,
            pieces: [
              { min: 41 },
              { min: 31, max: 40 },
              { min: 21, max: 30 },
              { min: 11, max: 20 },
              { min: 0, max: 10 },
            ],
            inRange: {
              symbol: "rect",
              color: ["#D9F9FF", "#C4E8FF", "#2A8BE2", "#336CAC", "#f00"],
            },
          },
        ],
      };
      let mycharts = echarts.init(this.$refs.myEchart);
      mycharts.setOption(option);
      let that = this;
      mycharts.on("click", function (chinaParam) {
        if (
          chinaParam.name == "常德市" ||
          chinaParam.name == "郴州市" ||
          chinaParam.name == "衡阳市" ||
          chinaParam.name == "怀化市" ||
          chinaParam.name == "娄底市" ||
          chinaParam.name == "邵阳市" ||
          chinaParam.name == "湘潭市" ||
          chinaParam.name == "湘西土家族苗族自治州" ||
          chinaParam.name == "益阳市" ||
          chinaParam.name == "永州市" ||
          chinaParam.name == "岳阳市" ||
          chinaParam.name == "张家界市" ||
          chinaParam.name == "长沙市" ||
          chinaParam.name == "株洲市"
        ) {
          var option = mycharts.getOption();
          option.series[0].map = chinaParam.name;
          option.series[0].mapType = chinaParam.name;
          that.title = chinaParam.name;
          mycharts.clear();
          mycharts.setOption(option, true);
        } else {
          alert(chinaParam.name + "区域还未开通！");
        }
      });
    },
    //产品视图
    productClick() {
      this.name = "产品视图";
      this.type = 0;
      this.getDataMap();
    },
    //企业视图
    enterpriseClick() {
      this.name = "企业视图";
      this.type = 1;
      this.getDataMap();
    },
    //活动视图
    activityClick() {
      this.name = "活动视图";
      this.type = 2;
      this.getDataMap();
    },
    //项目视图
    projectClick() {
      this.name = "项目视图";
      this.type = 3;
      this.getDataMap();
    },
    //获取地图数据
    async getDataMap() {
      const res = await getMapData({
        provinceId: 251,
        type: this.type,
      });
      if (res.code === 200) {
        this.mapList = res.data;
        this.hunanMap();
      }
    },
    //websocket
    init: function () {
      if (typeof WebSocket === "undefined") {
        alert("您的浏览器不支持socket");
      } else {
        // 实例化socket
        this.socket = new WebSocket(this.path);
        // 监听socket连接
        this.socket.onopen = this.open;
        // 监听socket错误信息
        this.socket.onerror = this.error;
        // 监听socket消息
        this.socket.onmessage = this.getMessage;
      }
    },
    open: function () {
      console.log("socket连接成功");
    },
    error: function () {
      console.log("连接错误");
    },
    getMessage: function (msg) {
      const obj = JSON.parse(msg.data);
      const name = obj.name;
      const val = obj.val;
      this.newData.total = this.newData.total + val;
      this.newData.totalNowAdd = this.newData.totalNowAdd + val;
      if (name === "scenarioBasicNowAdd") {
        this.newData.scenarioBasicTotal = this.newData.scenarioBasicTotal + val;
        this.newData.scenarioBasicNowAdd =
          this.newData.scenarioBasicNowAdd + val;
      } else if (name === "knowledgeNowAdd") {
        this.newData.knowledgeTotal = this.newData.knowledgeTotal + val;
        this.newData.knowledgeNowAdd = this.newData.knowledgeNowAdd + val;
      } else if (name === "planNowAdd") {
        this.newData.planTotal = this.newData.planTotal + val;
        this.newData.planNowAdd = this.newData.planNowAdd + val;
      }
    },
    send: function () {
      this.socket.send(params);
    },
    close: function () {
      console.log("socket已经关闭");
    },
    //圆环数据
    roundData() {
      const pieChart = this.newData.pieChart;
      const option = {
        tooltip: {
          trigger: "item",
        },
        toolbox: {
          show: true,
        },
        color: [
          "#5470C6",
          "#91CC75",
          "#9A60B4",
          "#7ED3F4",
          "#3BA272",
          "#FC8452",
        ],
        series: [
          {
            type: "pie",
            radius: ["30%", "70%"],
            data: pieChart,
          },
        ],
      };
      var myChart = echarts.init(this.$refs.myEacharts);
      myChart.setOption(option);
    },
    //显示当前时间（年月日时分秒）
    timeFormate(timeStamp) {
      let year = new Date(timeStamp).getFullYear();
      let month =
        new Date(timeStamp).getMonth() + 1 < 10
          ? "0" + (new Date(timeStamp).getMonth() + 1)
          : new Date(timeStamp).getMonth() + 1;
      let date =
        new Date(timeStamp).getDate() < 10
          ? "0" + new Date(timeStamp).getDate()
          : new Date(timeStamp).getDate();
      let hh =
        new Date(timeStamp).getHours() < 10
          ? "0" + new Date(timeStamp).getHours()
          : new Date(timeStamp).getHours();
      let mm =
        new Date(timeStamp).getMinutes() < 10
          ? "0" + new Date(timeStamp).getMinutes()
          : new Date(timeStamp).getMinutes();
      let ss =
        new Date(timeStamp).getSeconds() < 10
          ? "0" + new Date(timeStamp).getSeconds()
          : new Date(timeStamp).getSeconds();
      this.nowTime =
        year + "-" + month + "-" + date + " " + hh + ":" + mm + ":" + ss;
    },
    nowTimes() {
      this.timeFormate(new Date());
      setInterval(this.nowTimes, 1000);
      this.clear();
    },
    clear() {
      clearInterval(this.nowTimes);
      this.nowTimes = null;
    },

    //获取数据
    async getData() {
      const res = await getLeftData();
      if (res.code === 200) {
        this.newData = res.data;
        this.roundData();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.visualization {
  width: 100vw;
  overflow: auto;
  font-family: "PingFang SC";
  height: 100vh;
  background-color: #001542;

  .visualization_top {
    width: 100%;
    overflow: hidden;

    div {
      width: 100%;
      line-height: 74px;
      text-align: right;
      box-sizing: border-box;
      padding-right: 64px;
      height: 60px;
      background-image: url("../../../assets/svg/top.svg");
      background-repeat: no-repeat;
    }
  }

  .visualization_view {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    padding: 20px;

    .visualization_view_left {
      width: 500px;
      margin-right: 60px;

      .visualization_view_left1 {
        width: 100%;
        overflow: hidden;
        margin-top: 16px;
        padding: 20px 50px;
        display: flex;
        box-sizing: border-box;
        border-radius: 4px;
        box-shadow: #0ba7e2 0px 0px 10px inset;

        .visualization_view_circular {
          width: 98px;
          height: 98px;
          margin-right: 20px;
          border: 1px solid #5793fe;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          .visualization_view_circular_c {
            width: 68px;
            height: 68px;
            background-color: #013668;
            border-radius: 50%;
          }
        }

        .visualization_view_data {
          flex: 1;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          align-items: center;

          p {
            color: #04baff;
            font-size: 18px;
            box-sizing: border-box;
            padding: 15px 0;
          }
          span {
            color: #fff;
            font-size: 16px;
          }
        }

        .visualization_view_add {
          display: flex;
          flex-direction: column;
          align-items: center;

          p {
            color: #04baff;
            font-size: 18px;
            box-sizing: border-box;
            padding: 15px 0;
          }
          span {
            color: #ff6c66;
            font-size: 16px;
          }
        }
      }

      .visualization_view_round {
        width: 100%;
        overflow: hidden;
        display: flex;
        margin-top: 20px;
        height: 366px;
        box-sizing: border-box;
        border-radius: 4px;
        box-shadow: #0ba7e2 0px 0px 10px inset;
        flex-direction: column;
        align-items: center;
        #main {
          width: 500px;
          height: 300px;
        }

        .visualization_view_round_img {
          width: 100%;
          overflow: hidden;
          margin-bottom: 16px;
          display: flex;
          justify-content: center;
          align-items: center;

          .visualization_view_round_img_text {
            width: 234px;
            height: 38px;
            line-height: 38px;
            text-align: center;
            font-size: 22px;
            color: #04baff;
            background-repeat: no-repeat;
            background-image: url("../../../assets/svg/foo.svg");
          }
        }
      }
    }

    .visualization_view_conter {
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .visualization_view_conter_left {
      width: 128px;
      margin-right: 20px;

      .visualization_view_conter_left_back {
        width: 68px;
        height: 68px;
        cursor: pointer;
        display: flex;
        margin: 60px auto;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: rgba(4, 186, 255, 0.2);
      }

      .visualization_view_conter_left_btn {
        width: 128px;
        height: 40px;
        margin-bottom: 20px;
        text-align: center;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
        font-weight: 500;
        line-height: 40px;
        background-size: 128px 40px;
        background-repeat: no-repeat;
        background-image: url("../../../assets/svg/oder-botton.svg");
      }

      .new {
        background-image: url("../../../assets/svg/select-botton.svg");
      }
    }

    .visualization_view_conter_hunan {
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        width: 100%;
        text-align: center;
        color: #fff;
        box-sizing: border-box;
        padding: 40px 0 16px 0;
        font-size: 24px;
        font-weight: 500;
      }

      .backimg {
        width: 160px;
        margin: 0 auto;
        height: 18px;
        background-size: 160px 18px;
        background-repeat: no-repeat;
        background-image: url("../../../assets/svg/Ellipse32.svg");
      }
    }

    .visualization_view_map {
      width: 566px;
      height: 640px;
      margin-top: 120px;
    }

    .visualization_view_right {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      width: 500px;
      margin-left: 40px;

      .visualization_view_right_1 {
        width: 100%;
        overflow: hidden;
        margin-top: 20px;
        height: 186px;
        box-sizing: border-box;
        border-radius: 4px;
        box-shadow: #0ba7e2 0px 0px 10px inset;

        .visualization_view_right_1_img {
          width: 100%;
          overflow: hidden;
          margin-bottom: 16px;
          display: flex;
          justify-content: center;
          align-items: center;

          .visualization_view_right_1_img_text {
            width: 234px;
            height: 38px;
            line-height: 38px;
            text-align: center;
            font-size: 22px;
            color: #04baff;
            background-repeat: no-repeat;
            background-image: url("../../../assets/svg/foo.svg");
          }
        }

        .visualization_view_right_1_img_height {
          overflow: hidden;
          width: 100%;
        }

        .visualization_view_right_1_ul {
          width: 100%;
          overflow: hidden;
          box-sizing: border-box;
          padding: 0 24px 20px 24px;

          .div_p:hover {
            background-color: rgba(4, 186, 255, 0.2);
          }

          .div_p {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            overflow: hidden;
            box-sizing: border-box;
            padding: 0 10px;
            border-radius: 4px;
            height: 30px;
            margin-bottom: 12px;
            white-space: nowrap;
            cursor: pointer;

            .div_span {
              box-sizing: border-box;
              padding: 0 5px;
              text-align: center;
              font-size: 10px;
              line-height: 20px;
              height: 20px;
              margin-right: 8px;
            }

            .activity {
              color: #04baff;
              border: 1px solid #04baff;
            }

            .real {
              color: #00ff00;
              border: 1px solid #00ff00;
            }

            .div_text {
              flex: 1;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              font-size: 10px;
              color: #fff;
            }

            .div_place {
              font-size: 10px;
              color: #fff;
              max-width: 120px;
              margin: 0 8px 0 20px;
            }

            .div_time {
              font-size: 10px;
              color: #fff;
              max-width: 90px;
            }
          }
        }
        .inner-container {
          animation: myMove 20s linear infinite; //规定滚动时长为8s
          animation-fill-mode: forwards;
          font-size: 15px;
          color: #fff;
        }

        @keyframes myMove {
          0% {
            transform: translateY(0);
          }
          100% {
            transform: translateY(-500px);
          }
        }
      }

      .visualization_view_right_3 {
        width: 100%;
        overflow: hidden;
        margin-top: 20px;
        height: 492px;
        box-sizing: border-box;
        border-radius: 4px;
        box-shadow: #0ba7e2 0px 0px 10px inset;

        .visualization_view_right_3_img {
          width: 100%;
          overflow: hidden;
          margin-bottom: 16px;
          display: flex;
          justify-content: center;
          align-items: center;

          .visualization_view_right_3_img_text {
            width: 234px;
            height: 38px;
            line-height: 38px;
            text-align: center;
            font-size: 22px;
            color: #04baff;
            background-repeat: no-repeat;
            background-image: url("../../../assets/svg/foo.svg");
          }
        }

        .visualization_view_right_3_conter {
          width: 100%;
          overflow: hidden;
          box-sizing: border-box;
          padding: 10px 60px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .visualization_view_right_3_conter_round {
            width: 204px;
            height: 204px;
            border-radius: 50%;
            box-sizing: border-box;
            cursor: pointer;
            border: 1px solid #93aeff;
            display: flex;
            justify-content: center;
            align-items: center;

            .visualization_view_right_3_conter_round1 {
              width: 182px;
              height: 182px;
              border-radius: 50%;
              box-sizing: border-box;
              border: 1px solid #93aeff;
              display: flex;
              justify-content: center;
              align-items: center;

              .visualization_view_right_round_text {
                width: 164px;
                height: 164px;
                border-radius: 50%;
                background: linear-gradient(180deg, #d1daf8 0%, #98d2ff 100%);

                .new_tag {
                  display: inline-block;
                  color: #fff;
                  margin-right: 6px;
                  font-size: 12px;
                  margin-bottom: 4px;
                }

                .color1 {
                  color: #ff0;
                }

                .color2 {
                  color: #4bc7fe;
                }

                .color3 {
                  color: #3ba272;
                }

                .color4 {
                  color: #f97d5c;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
