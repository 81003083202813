/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

(function(root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['exports', 'echarts'], factory);
    } else if (typeof exports === 'object' && typeof exports.nodeName !== 'string') {
        // CommonJS
        factory(exports, require('echarts'));
    } else {
        // Browser globals
        factory({}, root.echarts);
    }
}(this, function(exports, echarts) {
    var log = function(msg) {
        if (typeof console !== 'undefined') {
            console && console.error && console.error(msg);
        }
    }
    if (!echarts) {
        log('ECharts is not Loaded');
        return;
    }
    if (!echarts.registerMap) {
        log('ECharts Map is not loaded')
        return;
    }
    echarts.registerMap('常德市', {
        "type": "FeatureCollection",
        "features": [{
            "type": "Feature",
            "properties": {
                "adcode": 430702,
                "name": "武陵区",
                "center": [111.690718, 29.040477],
                "centroid": [111.705096, 29.014803],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430700
                },
                "subFeatureIndex": 0,
                "acroutes": [100000, 430000, 430700]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [111.810558, 28.989809],
                            [111.797582, 28.990948],
                            [111.794206, 28.994176],
                            [111.786312, 29.006379],
                            [111.791012, 29.009986],
                            [111.79829, 29.01166],
                            [111.803553, 29.018494],
                            [111.80809, 29.027657],
                            [111.809233, 29.038735],
                            [111.793843, 29.044808],
                            [111.790177, 29.051744],
                            [111.793408, 29.059869],
                            [111.791738, 29.068597],
                            [111.766512, 29.07779],
                            [111.749598, 29.071461],
                            [111.745805, 29.072064],
                            [111.744262, 29.077221],
                            [111.747729, 29.098382],
                            [111.752865, 29.099261],
                            [111.754189, 29.103193],
                            [111.750396, 29.102382],
                            [111.74546, 29.109055],
                            [111.734698, 29.111417],
                            [111.724045, 29.116383],
                            [111.715788, 29.11671],
                            [111.708655, 29.114297],
                            [111.700253, 29.109469],
                            [111.692503, 29.109676],
                            [111.688366, 29.112573],
                            [111.687095, 29.1189],
                            [111.688456, 29.123865],
                            [111.697222, 29.130537],
                            [111.697712, 29.137242],
                            [111.691378, 29.137846],
                            [111.677567, 29.129847],
                            [111.674373, 29.109935],
                            [111.667531, 29.099796],
                            [111.666098, 29.079981],
                            [111.665771, 29.065234],
                            [111.657967, 29.064544],
                            [111.654628, 29.069115],
                            [111.643739, 29.074151],
                            [111.634266, 29.073565],
                            [111.629365, 29.071667],
                            [111.613722, 29.081499],
                            [111.608277, 29.081326],
                            [111.603486, 29.07867],
                            [111.592125, 29.066355],
                            [111.592506, 29.053969],
                            [111.591036, 29.043911],
                            [111.592615, 29.038804],
                            [111.605519, 29.026329],
                            [111.612397, 29.0216],
                            [111.615918, 29.016596],
                            [111.615645, 29.009486],
                            [111.608731, 28.998198],
                            [111.60031, 28.992795],
                            [111.592016, 28.984595],
                            [111.588532, 28.978519],
                            [111.589839, 28.966105],
                            [111.599475, 28.961633],
                            [111.612796, 28.957057],
                            [111.622397, 28.957178],
                            [111.630291, 28.95925],
                            [111.644211, 28.966727],
                            [111.649909, 28.971682],
                            [111.651652, 28.976343],
                            [111.651706, 28.984181],
                            [111.648911, 28.996886],
                            [111.650472, 29.009934],
                            [111.65784, 29.018667],
                            [111.671215, 29.023861],
                            [111.685752, 29.024793],
                            [111.699164, 29.019858],
                            [111.705316, 29.012178],
                            [111.705661, 29.004135],
                            [111.702721, 28.992553],
                            [111.70312, 28.976395],
                            [111.69675, 28.968799],
                            [111.695806, 28.958629],
                            [111.691378, 28.949926],
                            [111.686787, 28.9449],
                            [111.678674, 28.943968],
                            [111.666352, 28.936818],
                            [111.661306, 28.93592],
                            [111.665934, 28.932638],
                            [111.664065, 28.928648],
                            [111.665934, 28.922102],
                            [111.669528, 28.919632],
                            [111.667949, 28.909371],
                            [111.670925, 28.909682],
                            [111.677204, 28.900007],
                            [111.676968, 28.895791],
                            [111.683193, 28.891316],
                            [111.691469, 28.887653],
                            [111.697712, 28.887515],
                            [111.704046, 28.901596],
                            [111.708801, 28.905812],
                            [111.715062, 28.908248],
                            [111.720234, 28.907764],
                            [111.727929, 28.90322],
                            [111.733029, 28.904291],
                            [111.73517, 28.91077],
                            [111.74272, 28.91559],
                            [111.757619, 28.919062],
                            [111.759507, 28.922655],
                            [111.754371, 28.924796],
                            [111.742847, 28.9268],
                            [111.744625, 28.938631],
                            [111.746458, 28.941135],
                            [111.753826, 28.942189],
                            [111.758599, 28.957921],
                            [111.762392, 28.962462],
                            [111.769815, 28.965397],
                            [111.782464, 28.964637],
                            [111.798689, 28.95571],
                            [111.810377, 28.955227],
                            [111.816148, 28.957437],
                            [111.820775, 28.964603],
                            [111.812246, 28.981695],
                            [111.810558, 28.989809]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430703,
                "name": "鼎城区",
                "center": [111.685327, 29.014426],
                "centroid": [111.856233, 29.178493],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430700
                },
                "subFeatureIndex": 1,
                "acroutes": [100000, 430000, 430700]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.045397, 29.390852],
                            [112.037738, 29.382031],
                            [112.037194, 29.371816],
                            [112.025561, 29.354531],
                            [112.020007, 29.341079],
                            [112.01763, 29.339943],
                            [112.011024, 29.331066],
                            [112.007794, 29.329965],
                            [111.998847, 29.331582],
                            [111.982803, 29.322859],
                            [111.972931, 29.320261],
                            [111.966198, 29.316406],
                            [111.963947, 29.312139],
                            [111.96814, 29.300179],
                            [111.967196, 29.295463],
                            [111.958104, 29.284396],
                            [111.95255, 29.270022],
                            [111.950028, 29.272846],
                            [111.950209, 29.279852],
                            [111.94765, 29.296565],
                            [111.939756, 29.305755],
                            [111.936689, 29.31312],
                            [111.92237, 29.312862],
                            [111.913804, 29.31164],
                            [111.907724, 29.316114],
                            [111.902497, 29.31577],
                            [111.890175, 29.317903],
                            [111.886781, 29.322584],
                            [111.876037, 29.324786],
                            [111.868034, 29.322446],
                            [111.866291, 29.32821],
                            [111.85945, 29.329879],
                            [111.851537, 29.326421],
                            [111.848071, 29.322842],
                            [111.840085, 29.324115],
                            [111.836982, 29.322532],
                            [111.829596, 29.312397],
                            [111.822191, 29.308422],
                            [111.81426, 29.315064],
                            [111.808598, 29.314221],
                            [111.801992, 29.317439],
                            [111.790776, 29.315546],
                            [111.787582, 29.310607],
                            [111.789706, 29.303689],
                            [111.780377, 29.300609],
                            [111.771067, 29.292899],
                            [111.765205, 29.281366],
                            [111.758745, 29.280093],
                            [111.755079, 29.284878],
                            [111.751413, 29.295566],
                            [111.736477, 29.295979],
                            [111.725025, 29.295394],
                            [111.725733, 29.292365],
                            [111.719345, 29.294689],
                            [111.715425, 29.288458],
                            [111.707675, 29.28968],
                            [111.704772, 29.286616],
                            [111.693846, 29.284809],
                            [111.686006, 29.287804],
                            [111.679382, 29.28789],
                            [111.672667, 29.281332],
                            [111.653285, 29.275118],
                            [111.642614, 29.279352],
                            [111.631543, 29.278698],
                            [111.626099, 29.284138],
                            [111.620309, 29.285945],
                            [111.615482, 29.29295],
                            [111.611362, 29.292899],
                            [111.610128, 29.287838],
                            [111.606317, 29.283948],
                            [111.596717, 29.283621],
                            [111.591635, 29.278061],
                            [111.587552, 29.276409],
                            [111.578314, 29.27739],
                            [111.57434, 29.276202],
                            [111.571164, 29.279507],
                            [111.562217, 29.278664],
                            [111.55238, 29.273982],
                            [111.549513, 29.264651],
                            [111.560003, 29.254149],
                            [111.556446, 29.24826],
                            [111.5567, 29.243215],
                            [111.560801, 29.236378],
                            [111.564104, 29.234519],
                            [111.571472, 29.238944],
                            [111.584721, 29.234674],
                            [111.590764, 29.225098],
                            [111.587461, 29.218347],
                            [111.581926, 29.217193],
                            [111.5714, 29.210148],
                            [111.571999, 29.205273],
                            [111.58336, 29.196953],
                            [111.582561, 29.185186],
                            [111.578423, 29.165525],
                            [111.567516, 29.161975],
                            [111.570039, 29.152238],
                            [111.564322, 29.145654],
                            [111.566427, 29.136777],
                            [111.57267, 29.133105],
                            [111.581436, 29.132054],
                            [111.58002, 29.126175],
                            [111.583233, 29.121452],
                            [111.576209, 29.115124],
                            [111.574213, 29.110176],
                            [111.577153, 29.100744],
                            [111.573705, 29.095933],
                            [111.568551, 29.092846],
                            [111.57218, 29.081171],
                            [111.572053, 29.072564],
                            [111.57454, 29.068873],
                            [111.578423, 29.069546],
                            [111.585519, 29.06582],
                            [111.592125, 29.066355],
                            [111.603486, 29.07867],
                            [111.608277, 29.081326],
                            [111.613722, 29.081499],
                            [111.629365, 29.071667],
                            [111.634266, 29.073565],
                            [111.643739, 29.074151],
                            [111.654628, 29.069115],
                            [111.657967, 29.064544],
                            [111.665771, 29.065234],
                            [111.666098, 29.079981],
                            [111.667531, 29.099796],
                            [111.674373, 29.109935],
                            [111.677567, 29.129847],
                            [111.691378, 29.137846],
                            [111.697712, 29.137242],
                            [111.697222, 29.130537],
                            [111.688456, 29.123865],
                            [111.687095, 29.1189],
                            [111.688366, 29.112573],
                            [111.692503, 29.109676],
                            [111.700253, 29.109469],
                            [111.708655, 29.114297],
                            [111.715788, 29.11671],
                            [111.724045, 29.116383],
                            [111.734698, 29.111417],
                            [111.74546, 29.109055],
                            [111.750396, 29.102382],
                            [111.754189, 29.103193],
                            [111.752865, 29.099261],
                            [111.747729, 29.098382],
                            [111.744262, 29.077221],
                            [111.745805, 29.072064],
                            [111.749598, 29.071461],
                            [111.766512, 29.07779],
                            [111.791738, 29.068597],
                            [111.793408, 29.059869],
                            [111.790177, 29.051744],
                            [111.793843, 29.044808],
                            [111.809233, 29.038735],
                            [111.80809, 29.027657],
                            [111.803553, 29.018494],
                            [111.79829, 29.01166],
                            [111.791012, 29.009986],
                            [111.786312, 29.006379],
                            [111.794206, 28.994176],
                            [111.797582, 28.990948],
                            [111.810558, 28.989809],
                            [111.813044, 28.998836],
                            [111.826891, 29.004584],
                            [111.84072, 29.004964],
                            [111.858996, 29.001322],
                            [111.855929, 28.990326],
                            [111.861264, 28.981937],
                            [111.887997, 28.977828],
                            [111.897325, 28.968799],
                            [111.915618, 28.96614],
                            [111.928268, 28.970473],
                            [111.936253, 28.976654],
                            [111.941425, 28.984958],
                            [111.934946, 28.991846],
                            [111.935963, 28.996869],
                            [111.934093, 29.000804],
                            [111.928685, 29.003755],
                            [111.927433, 29.009175],
                            [111.940463, 29.01594],
                            [111.946961, 29.023188],
                            [111.948304, 29.027209],
                            [111.954256, 29.026726],
                            [111.959955, 29.029538],
                            [111.954746, 29.036958],
                            [111.964873, 29.044377],
                            [111.972767, 29.05823],
                            [111.979972, 29.062439],
                            [111.98097, 29.066872],
                            [111.97745, 29.073668],
                            [111.979083, 29.078946],
                            [111.975145, 29.09395],
                            [111.976452, 29.099468],
                            [111.980353, 29.103417],
                            [111.994473, 29.10771],
                            [112.000607, 29.105141],
                            [112.003329, 29.099123],
                            [112.02106, 29.092467],
                            [112.033419, 29.090363],
                            [112.038446, 29.088121],
                            [112.044725, 29.093433],
                            [112.050968, 29.095933],
                            [112.062003, 29.112417],
                            [112.064326, 29.118245],
                            [112.072093, 29.119779],
                            [112.084888, 29.11509],
                            [112.092492, 29.107469],
                            [112.096067, 29.108917],
                            [112.097192, 29.113228],
                            [112.105704, 29.107521],
                            [112.128226, 29.10909],
                            [112.142109, 29.111659],
                            [112.158316, 29.117969],
                            [112.169549, 29.128847],
                            [112.180057, 29.128675],
                            [112.183342, 29.134915],
                            [112.150584, 29.148756],
                            [112.135775, 29.157804],
                            [112.128825, 29.166524],
                            [112.129042, 29.179586],
                            [112.12632, 29.189803],
                            [112.121602, 29.198348],
                            [112.120912, 29.205893],
                            [112.122418, 29.213489],
                            [112.116919, 29.225977],
                            [112.108535, 29.238135],
                            [112.093998, 29.244317],
                            [112.077755, 29.256972],
                            [112.071784, 29.263584],
                            [112.065269, 29.279335],
                            [112.061476, 29.295067],
                            [112.060877, 29.303018],
                            [112.062474, 29.32076],
                            [112.056631, 29.334731],
                            [112.059734, 29.342816],
                            [112.058645, 29.345999],
                            [112.0632, 29.350265],
                            [112.065995, 29.35914],
                            [112.060805, 29.367052],
                            [112.058554, 29.376821],
                            [112.045397, 29.390852]
                        ]
                    ],
                    [
                        [
                            [111.816148, 28.957437],
                            [111.810377, 28.955227],
                            [111.798689, 28.95571],
                            [111.782464, 28.964637],
                            [111.769815, 28.965397],
                            [111.762392, 28.962462],
                            [111.758599, 28.957921],
                            [111.753826, 28.942189],
                            [111.746458, 28.941135],
                            [111.744625, 28.938631],
                            [111.742847, 28.9268],
                            [111.754371, 28.924796],
                            [111.759507, 28.922655],
                            [111.757619, 28.919062],
                            [111.74272, 28.91559],
                            [111.73517, 28.91077],
                            [111.733029, 28.904291],
                            [111.727929, 28.90322],
                            [111.720234, 28.907764],
                            [111.715062, 28.908248],
                            [111.708801, 28.905812],
                            [111.704046, 28.901596],
                            [111.697712, 28.887515],
                            [111.691469, 28.887653],
                            [111.683193, 28.891316],
                            [111.676968, 28.895791],
                            [111.677204, 28.900007],
                            [111.670925, 28.909682],
                            [111.667949, 28.909371],
                            [111.669528, 28.919632],
                            [111.665934, 28.922102],
                            [111.664065, 28.928648],
                            [111.665934, 28.932638],
                            [111.661306, 28.93592],
                            [111.666352, 28.936818],
                            [111.678674, 28.943968],
                            [111.686787, 28.9449],
                            [111.691378, 28.949926],
                            [111.695806, 28.958629],
                            [111.69675, 28.968799],
                            [111.70312, 28.976395],
                            [111.702721, 28.992553],
                            [111.705661, 29.004135],
                            [111.705316, 29.012178],
                            [111.699164, 29.019858],
                            [111.685752, 29.024793],
                            [111.671215, 29.023861],
                            [111.65784, 29.018667],
                            [111.650472, 29.009934],
                            [111.648911, 28.996886],
                            [111.651706, 28.984181],
                            [111.651652, 28.976343],
                            [111.649909, 28.971682],
                            [111.644211, 28.966727],
                            [111.630291, 28.95925],
                            [111.622397, 28.957178],
                            [111.612796, 28.957057],
                            [111.599475, 28.961633],
                            [111.589839, 28.966105],
                            [111.581436, 28.96115],
                            [111.556482, 28.948026],
                            [111.54474, 28.946817],
                            [111.536337, 28.942258],
                            [111.528534, 28.925712],
                            [111.530838, 28.912774],
                            [111.528062, 28.90379],
                            [111.524559, 28.899679],
                            [111.524468, 28.88361],
                            [111.515322, 28.868299],
                            [111.518788, 28.856874],
                            [111.519695, 28.846037],
                            [111.528534, 28.835284],
                            [111.527681, 28.82913],
                            [111.529205, 28.820606],
                            [111.528407, 28.814001],
                            [111.525176, 28.806219],
                            [111.52543, 28.799561],
                            [111.522272, 28.797797],
                            [111.510748, 28.803643],
                            [111.508062, 28.797867],
                            [111.502582, 28.795982],
                            [111.500132, 28.788147],
                            [111.493271, 28.793526],
                            [111.484143, 28.795376],
                            [111.48104, 28.791485],
                            [111.476829, 28.790586],
                            [111.476049, 28.794477],
                            [111.471657, 28.797279],
                            [111.463109, 28.794892],
                            [111.462383, 28.792021],
                            [111.46957, 28.780052],
                            [111.470259, 28.773808],
                            [111.474597, 28.767892],
                            [111.474869, 28.757616],
                            [111.478934, 28.755488],
                            [111.486611, 28.758394],
                            [111.490422, 28.755453],
                            [111.500186, 28.757391],
                            [111.501892, 28.747408],
                            [111.507028, 28.74611],
                            [111.502019, 28.738531],
                            [111.493271, 28.735278],
                            [111.492128, 28.727006],
                            [111.482836, 28.717816],
                            [111.478444, 28.715688],
                            [111.47378, 28.706687],
                            [111.477646, 28.702914],
                            [111.479043, 28.694484],
                            [111.471512, 28.682123],
                            [111.471366, 28.677344],
                            [111.475051, 28.672375],
                            [111.481421, 28.66983],
                            [111.481657, 28.662678],
                            [111.487718, 28.65466],
                            [111.493907, 28.652946],
                            [111.49623, 28.649673],
                            [111.494397, 28.640459],
                            [111.495413, 28.637393],
                            [111.507754, 28.633461],
                            [111.506647, 28.627156],
                            [111.501783, 28.613349],
                            [111.500113, 28.605241],
                            [111.495667, 28.593754],
                            [111.494215, 28.581242],
                            [111.509351, 28.585679],
                            [111.518661, 28.595521],
                            [111.539223, 28.609711],
                            [111.557117, 28.62894],
                            [111.567226, 28.638762],
                            [111.574485, 28.639298],
                            [111.592506, 28.632647],
                            [111.60541, 28.624939],
                            [111.624411, 28.618339],
                            [111.628984, 28.621076],
                            [111.631235, 28.625615],
                            [111.636062, 28.628473],
                            [111.641017, 28.62804],
                            [111.644937, 28.623726],
                            [111.65735, 28.626377],
                            [111.662849, 28.624644],
                            [111.665245, 28.614614],
                            [111.663648, 28.607892],
                            [111.669891, 28.593858],
                            [111.67352, 28.591449],
                            [111.688402, 28.589196],
                            [111.694554, 28.591016],
                            [111.702757, 28.598986],
                            [111.708891, 28.594256],
                            [111.712775, 28.596318],
                            [111.713483, 28.600633],
                            [111.723428, 28.609833],
                            [111.724735, 28.616554],
                            [111.721141, 28.621284],
                            [111.719072, 28.636458],
                            [111.728274, 28.642537],
                            [111.722503, 28.645049],
                            [111.723628, 28.647872],
                            [111.730651, 28.652219],
                            [111.742121, 28.651612],
                            [111.751576, 28.657639],
                            [111.751304, 28.664877],
                            [111.754843, 28.669241],
                            [111.752992, 28.676357],
                            [111.754534, 28.681188],
                            [111.753282, 28.692164],
                            [111.744752, 28.699071],
                            [111.740524, 28.704887],
                            [111.732865, 28.700023],
                            [111.724118, 28.700767],
                            [111.719907, 28.699227],
                            [111.710016, 28.704264],
                            [111.710924, 28.712918],
                            [111.70704, 28.712988],
                            [111.705643, 28.71709],
                            [111.709363, 28.722005],
                            [111.715552, 28.725656],
                            [111.713465, 28.730969],
                            [111.716405, 28.73334],
                            [111.720597, 28.728564],
                            [111.723192, 28.731315],
                            [111.731722, 28.732111],
                            [111.742121, 28.729741],
                            [111.745224, 28.727491],
                            [111.748999, 28.732977],
                            [111.753972, 28.73469],
                            [111.753645, 28.731298],
                            [111.762247, 28.730675],
                            [111.765532, 28.734361],
                            [111.767946, 28.744536],
                            [111.778544, 28.74874],
                            [111.791321, 28.747287],
                            [111.793735, 28.749882],
                            [111.793898, 28.756681],
                            [111.797763, 28.759086],
                            [111.801665, 28.768065],
                            [111.801012, 28.771092],
                            [111.806348, 28.773099],
                            [111.807455, 28.784186],
                            [111.813498, 28.786746],
                            [111.813915, 28.792453],
                            [111.828652, 28.797901],
                            [111.83043, 28.802605],
                            [111.837653, 28.801049],
                            [111.837454, 28.807949],
                            [111.843152, 28.806288],
                            [111.841864, 28.81324],
                            [111.843824, 28.814606],
                            [111.848234, 28.810802],
                            [111.850738, 28.813309],
                            [111.851374, 28.822041],
                            [111.829069, 28.837445],
                            [111.819904, 28.844602],
                            [111.815476, 28.845293],
                            [111.800958, 28.851948],
                            [111.786366, 28.862889],
                            [111.780777, 28.863287],
                            [111.764715, 28.873207],
                            [111.76938, 28.887411],
                            [111.776421, 28.892664],
                            [111.778436, 28.899454],
                            [111.779307, 28.910995],
                            [111.782973, 28.922067],
                            [111.780468, 28.926126],
                            [111.786929, 28.925522],
                            [111.796929, 28.91844],
                            [111.809233, 28.918785],
                            [111.81593, 28.922465],
                            [111.820231, 28.928355],
                            [111.821864, 28.941895],
                            [111.819759, 28.951083],
                            [111.816148, 28.957437]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430721,
                "name": "安乡县",
                "center": [112.172289, 29.414483],
                "centroid": [112.155575, 29.442438],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430700
                },
                "subFeatureIndex": 2,
                "acroutes": [100000, 430000, 430700]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.187734, 29.134501],
                            [112.190002, 29.14524],
                            [112.194594, 29.14686],
                            [112.201037, 29.146016],
                            [112.195774, 29.160028],
                            [112.19601, 29.168386],
                            [112.201617, 29.179241],
                            [112.208114, 29.187081],
                            [112.216209, 29.194351],
                            [112.223903, 29.199054],
                            [112.236408, 29.213145],
                            [112.228495, 29.216073],
                            [112.226517, 29.228439],
                            [112.228477, 29.242027],
                            [112.228749, 29.251738],
                            [112.223468, 29.266631],
                            [112.223468, 29.280489],
                            [112.222216, 29.287322],
                            [112.21639, 29.290162],
                            [112.213414, 29.2961],
                            [112.215882, 29.296943],
                            [112.226626, 29.294052],
                            [112.23423, 29.298647],
                            [112.241943, 29.293897],
                            [112.252723, 29.291883],
                            [112.258222, 29.292296],
                            [112.263684, 29.298699],
                            [112.256915, 29.307682],
                            [112.251217, 29.318936],
                            [112.249384, 29.32852],
                            [112.252632, 29.351968],
                            [112.259656, 29.366502],
                            [112.261706, 29.3756],
                            [112.268367, 29.384525],
                            [112.281143, 29.395134],
                            [112.28421, 29.403507],
                            [112.294283, 29.40612],
                            [112.295535, 29.408286],
                            [112.293375, 29.415558],
                            [112.294464, 29.428536],
                            [112.299981, 29.445551],
                            [112.299564, 29.449727],
                            [112.293557, 29.45636],
                            [112.291705, 29.478267],
                            [112.28804, 29.486616],
                            [112.281561, 29.497232],
                            [112.278239, 29.508482],
                            [112.279328, 29.529141],
                            [112.282704, 29.537555],
                            [112.29156, 29.542774],
                            [112.30274, 29.566018],
                            [112.303937, 29.575321],
                            [112.30283, 29.586202],
                            [112.29519, 29.595315],
                            [112.282032, 29.596259],
                            [112.265372, 29.603225],
                            [112.255173, 29.610243],
                            [112.250999, 29.611667],
                            [112.24089, 29.610638],
                            [112.234411, 29.615802],
                            [112.229021, 29.625563],
                            [112.22835, 29.631036],
                            [112.231471, 29.639664],
                            [112.239493, 29.648188],
                            [112.244175, 29.65961],
                            [112.236099, 29.658444],
                            [112.22149, 29.649286],
                            [112.218223, 29.645152],
                            [112.220455, 29.637245],
                            [112.217116, 29.634209],
                            [112.203904, 29.634552],
                            [112.199458, 29.629869],
                            [112.198314, 29.617449],
                            [112.191037, 29.624603],
                            [112.185828, 29.634346],
                            [112.184032, 29.648017],
                            [112.17817, 29.65666],
                            [112.172054, 29.660622],
                            [112.164559, 29.662337],
                            [112.160185, 29.658615],
                            [112.150566, 29.654551],
                            [112.143997, 29.657415],
                            [112.124995, 29.657021],
                            [112.118498, 29.662697],
                            [112.111529, 29.659644],
                            [112.1075, 29.671168],
                            [112.091875, 29.685656],
                            [112.087483, 29.681764],
                            [112.072728, 29.679673],
                            [112.065451, 29.681781],
                            [112.063309, 29.689548],
                            [112.060097, 29.694725],
                            [112.064634, 29.701085],
                            [112.064489, 29.710341],
                            [112.06948, 29.712021],
                            [112.07398, 29.717077],
                            [112.072583, 29.722219],
                            [112.073309, 29.734695],
                            [112.076412, 29.743486],
                            [112.06957, 29.747838],
                            [112.065923, 29.754657],
                            [112.06663, 29.76127],
                            [112.064453, 29.769818],
                            [112.054689, 29.758837],
                            [112.064689, 29.745953],
                            [112.062529, 29.742937],
                            [112.055959, 29.74854],
                            [112.050732, 29.749157],
                            [112.051222, 29.74035],
                            [112.043618, 29.731319],
                            [112.045125, 29.720608],
                            [112.043255, 29.711473],
                            [112.034453, 29.697742],
                            [112.034109, 29.690834],
                            [112.030334, 29.688022],
                            [112.029807, 29.683445],
                            [112.03527, 29.68353],
                            [112.03204, 29.678524],
                            [112.036105, 29.675455],
                            [112.038863, 29.667447],
                            [112.039372, 29.658427],
                            [112.043074, 29.656112],
                            [112.047375, 29.645976],
                            [112.0485, 29.629629],
                            [112.056558, 29.620074],
                            [112.057847, 29.607772],
                            [112.053273, 29.602093],
                            [112.052166, 29.589892],
                            [112.049099, 29.583971],
                            [112.0485, 29.572627],
                            [112.053273, 29.560028],
                            [112.052003, 29.548182],
                            [112.044036, 29.534069],
                            [112.041749, 29.523612],
                            [112.04997, 29.510234],
                            [112.055469, 29.505047],
                            [112.063509, 29.500014],
                            [112.075505, 29.494415],
                            [112.076884, 29.491598],
                            [112.073091, 29.481858],
                            [112.066957, 29.47813],
                            [112.063708, 29.473972],
                            [112.061331, 29.462855],
                            [112.058518, 29.459831],
                            [112.049698, 29.457116],
                            [112.034472, 29.454882],
                            [112.016777, 29.458749],
                            [112.011369, 29.459127],
                            [111.994854, 29.456068],
                            [111.986397, 29.451875],
                            [111.980499, 29.443764],
                            [111.983765, 29.430685],
                            [111.987849, 29.423276],
                            [112.000008, 29.41365],
                            [112.004164, 29.403713],
                            [112.012512, 29.403438],
                            [112.03164, 29.399381],
                            [112.041931, 29.394085],
                            [112.045397, 29.390852],
                            [112.058554, 29.376821],
                            [112.060805, 29.367052],
                            [112.065995, 29.35914],
                            [112.0632, 29.350265],
                            [112.058645, 29.345999],
                            [112.059734, 29.342816],
                            [112.056631, 29.334731],
                            [112.062474, 29.32076],
                            [112.060877, 29.303018],
                            [112.061476, 29.295067],
                            [112.065269, 29.279335],
                            [112.071784, 29.263584],
                            [112.077755, 29.256972],
                            [112.093998, 29.244317],
                            [112.108535, 29.238135],
                            [112.116919, 29.225977],
                            [112.122418, 29.213489],
                            [112.120912, 29.205893],
                            [112.121602, 29.198348],
                            [112.12632, 29.189803],
                            [112.129042, 29.179586],
                            [112.128825, 29.166524],
                            [112.135775, 29.157804],
                            [112.150584, 29.148756],
                            [112.183342, 29.134915],
                            [112.187734, 29.134501]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430722,
                "name": "汉寿县",
                "center": [111.968506, 28.907319],
                "centroid": [112.037017, 28.858908],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430700
                },
                "subFeatureIndex": 3,
                "acroutes": [100000, 430000, 430700]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [111.751576, 28.657639],
                            [111.759071, 28.653431],
                            [111.762429, 28.646469],
                            [111.768272, 28.639021],
                            [111.776221, 28.634137],
                            [111.780033, 28.635661],
                            [111.782737, 28.645049],
                            [111.787873, 28.64872],
                            [111.791339, 28.643126],
                            [111.810576, 28.640979],
                            [111.812754, 28.639506],
                            [111.814859, 28.629495],
                            [111.820231, 28.62215],
                            [111.828489, 28.61574],
                            [111.83818, 28.614406],
                            [111.84406, 28.616693],
                            [111.850158, 28.63334],
                            [111.854767, 28.636995],
                            [111.873097, 28.630984],
                            [111.888069, 28.637099],
                            [111.901209, 28.635973],
                            [111.904294, 28.643247],
                            [111.910591, 28.643421],
                            [111.918214, 28.655388],
                            [111.91678, 28.660773],
                            [111.910156, 28.664133],
                            [111.910228, 28.671475],
                            [111.923241, 28.676963],
                            [111.932006, 28.674366],
                            [111.951498, 28.674401],
                            [111.960209, 28.670643],
                            [111.973874, 28.667561],
                            [111.98411, 28.663856],
                            [111.979573, 28.656219],
                            [111.98146, 28.652288],
                            [111.985145, 28.652548],
                            [111.992005, 28.657518],
                            [111.995997, 28.65544],
                            [111.998429, 28.646746],
                            [112.004509, 28.645759],
                            [112.014055, 28.640719],
                            [112.031477, 28.638952],
                            [112.034599, 28.640026],
                            [112.040751, 28.657708],
                            [112.032076, 28.661795],
                            [112.025851, 28.669276],
                            [112.033419, 28.673397],
                            [112.041804, 28.671717],
                            [112.051186, 28.67241],
                            [112.060024, 28.669241],
                            [112.066757, 28.672704],
                            [112.07046, 28.672358],
                            [112.074507, 28.667405],
                            [112.075323, 28.659163],
                            [112.078263, 28.655111],
                            [112.086539, 28.652686],
                            [112.094361, 28.641983],
                            [112.099225, 28.643109],
                            [112.104197, 28.650798],
                            [112.107355, 28.652323],
                            [112.136973, 28.649084],
                            [112.14808, 28.652842],
                            [112.149459, 28.656409],
                            [112.143597, 28.658817],
                            [112.142962, 28.662263],
                            [112.151002, 28.663648],
                            [112.154486, 28.669345],
                            [112.158787, 28.667423],
                            [112.161491, 28.675717],
                            [112.167862, 28.679145],
                            [112.179204, 28.669985],
                            [112.182725, 28.668358],
                            [112.189984, 28.669553],
                            [112.19973, 28.674089],
                            [112.20198, 28.680997],
                            [112.207969, 28.691021],
                            [112.216499, 28.693601],
                            [112.219493, 28.697582],
                            [112.230854, 28.705216],
                            [112.231036, 28.711845],
                            [112.239438, 28.715238],
                            [112.245427, 28.714303],
                            [112.2649, 28.719184],
                            [112.283212, 28.728425],
                            [112.283757, 28.741732],
                            [112.282486, 28.751751],
                            [112.283593, 28.757495],
                            [112.28176, 28.773514],
                            [112.28343, 28.776904],
                            [112.296351, 28.781886],
                            [112.299582, 28.784843],
                            [112.30597, 28.797694],
                            [112.304772, 28.809954],
                            [112.305934, 28.817995],
                            [112.304681, 28.823026],
                            [112.276443, 28.84073],
                            [112.266062, 28.853435],
                            [112.259274, 28.85786],
                            [112.257006, 28.861455],
                            [112.258657, 28.900819],
                            [112.261743, 28.908127],
                            [112.261416, 28.917144],
                            [112.259547, 28.922724],
                            [112.257351, 28.967745],
                            [112.255391, 28.973132],
                            [112.249728, 28.976067],
                            [112.246897, 28.980832],
                            [112.24766, 28.997041],
                            [112.250908, 29.000752],
                            [112.249856, 29.014818],
                            [112.252106, 29.039494],
                            [112.254011, 29.04976],
                            [112.243848, 29.062042],
                            [112.241453, 29.067907],
                            [112.24178, 29.080912],
                            [112.243885, 29.09314],
                            [112.242632, 29.096364],
                            [112.245282, 29.100675],
                            [112.235736, 29.1144],
                            [112.231018, 29.114297],
                            [112.222851, 29.118641],
                            [112.216209, 29.11509],
                            [112.211218, 29.117279],
                            [112.207879, 29.12221],
                            [112.207389, 29.12733],
                            [112.187734, 29.134501],
                            [112.183342, 29.134915],
                            [112.180057, 29.128675],
                            [112.169549, 29.128847],
                            [112.158316, 29.117969],
                            [112.142109, 29.111659],
                            [112.128226, 29.10909],
                            [112.105704, 29.107521],
                            [112.097192, 29.113228],
                            [112.096067, 29.108917],
                            [112.092492, 29.107469],
                            [112.084888, 29.11509],
                            [112.072093, 29.119779],
                            [112.064326, 29.118245],
                            [112.062003, 29.112417],
                            [112.050968, 29.095933],
                            [112.044725, 29.093433],
                            [112.038446, 29.088121],
                            [112.033419, 29.090363],
                            [112.02106, 29.092467],
                            [112.003329, 29.099123],
                            [112.000607, 29.105141],
                            [111.994473, 29.10771],
                            [111.980353, 29.103417],
                            [111.976452, 29.099468],
                            [111.975145, 29.09395],
                            [111.979083, 29.078946],
                            [111.97745, 29.073668],
                            [111.98097, 29.066872],
                            [111.979972, 29.062439],
                            [111.972767, 29.05823],
                            [111.964873, 29.044377],
                            [111.954746, 29.036958],
                            [111.959955, 29.029538],
                            [111.954256, 29.026726],
                            [111.948304, 29.027209],
                            [111.946961, 29.023188],
                            [111.940463, 29.01594],
                            [111.927433, 29.009175],
                            [111.928685, 29.003755],
                            [111.934093, 29.000804],
                            [111.935963, 28.996869],
                            [111.934946, 28.991846],
                            [111.941425, 28.984958],
                            [111.936253, 28.976654],
                            [111.928268, 28.970473],
                            [111.915618, 28.96614],
                            [111.897325, 28.968799],
                            [111.887997, 28.977828],
                            [111.861264, 28.981937],
                            [111.855929, 28.990326],
                            [111.858996, 29.001322],
                            [111.84072, 29.004964],
                            [111.826891, 29.004584],
                            [111.813044, 28.998836],
                            [111.810558, 28.989809],
                            [111.812246, 28.981695],
                            [111.820775, 28.964603],
                            [111.816148, 28.957437],
                            [111.819759, 28.951083],
                            [111.821864, 28.941895],
                            [111.820231, 28.928355],
                            [111.81593, 28.922465],
                            [111.809233, 28.918785],
                            [111.796929, 28.91844],
                            [111.786929, 28.925522],
                            [111.780468, 28.926126],
                            [111.782973, 28.922067],
                            [111.779307, 28.910995],
                            [111.778436, 28.899454],
                            [111.776421, 28.892664],
                            [111.76938, 28.887411],
                            [111.764715, 28.873207],
                            [111.780777, 28.863287],
                            [111.786366, 28.862889],
                            [111.800958, 28.851948],
                            [111.815476, 28.845293],
                            [111.819904, 28.844602],
                            [111.829069, 28.837445],
                            [111.851374, 28.822041],
                            [111.850738, 28.813309],
                            [111.848234, 28.810802],
                            [111.843824, 28.814606],
                            [111.841864, 28.81324],
                            [111.843152, 28.806288],
                            [111.837454, 28.807949],
                            [111.837653, 28.801049],
                            [111.83043, 28.802605],
                            [111.828652, 28.797901],
                            [111.813915, 28.792453],
                            [111.813498, 28.786746],
                            [111.807455, 28.784186],
                            [111.806348, 28.773099],
                            [111.801012, 28.771092],
                            [111.801665, 28.768065],
                            [111.797763, 28.759086],
                            [111.793898, 28.756681],
                            [111.793735, 28.749882],
                            [111.791321, 28.747287],
                            [111.778544, 28.74874],
                            [111.767946, 28.744536],
                            [111.765532, 28.734361],
                            [111.762247, 28.730675],
                            [111.753645, 28.731298],
                            [111.753972, 28.73469],
                            [111.748999, 28.732977],
                            [111.745224, 28.727491],
                            [111.742121, 28.729741],
                            [111.731722, 28.732111],
                            [111.723192, 28.731315],
                            [111.720597, 28.728564],
                            [111.716405, 28.73334],
                            [111.713465, 28.730969],
                            [111.715552, 28.725656],
                            [111.709363, 28.722005],
                            [111.705643, 28.71709],
                            [111.70704, 28.712988],
                            [111.710924, 28.712918],
                            [111.710016, 28.704264],
                            [111.719907, 28.699227],
                            [111.724118, 28.700767],
                            [111.732865, 28.700023],
                            [111.740524, 28.704887],
                            [111.744752, 28.699071],
                            [111.753282, 28.692164],
                            [111.754534, 28.681188],
                            [111.752992, 28.676357],
                            [111.754843, 28.669241],
                            [111.751304, 28.664877],
                            [111.751576, 28.657639]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430723,
                "name": "澧县",
                "center": [111.761682, 29.64264],
                "centroid": [111.704793, 29.743602],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430700
                },
                "subFeatureIndex": 4,
                "acroutes": [100000, 430000, 430700]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [111.771267, 29.507108],
                            [111.771594, 29.512999],
                            [111.787836, 29.540697],
                            [111.809868, 29.569777],
                            [111.813643, 29.577673],
                            [111.816837, 29.589068],
                            [111.8174, 29.597374],
                            [111.824823, 29.6006],
                            [111.837036, 29.617552],
                            [111.842735, 29.62124],
                            [111.848669, 29.622424],
                            [111.866092, 29.622939],
                            [111.868179, 29.625306],
                            [111.868905, 29.636628],
                            [111.871772, 29.641773],
                            [111.87072, 29.649303],
                            [111.873641, 29.654431],
                            [111.874513, 29.664257],
                            [111.878796, 29.673397],
                            [111.878469, 29.678027],
                            [111.87326, 29.682176],
                            [111.87856, 29.685193],
                            [111.886182, 29.686856],
                            [111.8913, 29.695788],
                            [111.900664, 29.722682],
                            [111.903586, 29.725578],
                            [111.909267, 29.72587],
                            [111.913332, 29.730291],
                            [111.916417, 29.72856],
                            [111.923803, 29.730874],
                            [111.928667, 29.728697],
                            [111.932369, 29.722836],
                            [111.936035, 29.720659],
                            [111.936852, 29.705405],
                            [111.940336, 29.701977],
                            [111.93393, 29.694434],
                            [111.939792, 29.681181],
                            [111.935436, 29.675009],
                            [111.926217, 29.669539],
                            [111.916217, 29.672471],
                            [111.90669, 29.668321],
                            [111.903713, 29.664858],
                            [111.907198, 29.657501],
                            [111.906218, 29.653025],
                            [111.908559, 29.648994],
                            [111.903278, 29.650143],
                            [111.901263, 29.647965],
                            [111.907198, 29.64354],
                            [111.908432, 29.638017],
                            [111.912914, 29.633832],
                            [111.909593, 29.620383],
                            [111.912188, 29.61563],
                            [111.911517, 29.610603],
                            [111.906272, 29.603877],
                            [111.903913, 29.593101],
                            [111.897361, 29.589497],
                            [111.883714, 29.59063],
                            [111.879649, 29.588691],
                            [111.875075, 29.575939],
                            [111.876001, 29.570413],
                            [111.88473, 29.565709],
                            [111.893586, 29.555084],
                            [111.899757, 29.549813],
                            [111.90825, 29.539787],
                            [111.912352, 29.528042],
                            [111.918558, 29.52332],
                            [111.924475, 29.5215],
                            [111.93373, 29.508808],
                            [111.93344, 29.5046],
                            [111.939302, 29.508757],
                            [111.940463, 29.517258],
                            [111.935727, 29.523251],
                            [111.936398, 29.518082],
                            [111.926217, 29.523921],
                            [111.921426, 29.529742],
                            [111.9205, 29.535906],
                            [111.922769, 29.543203],
                            [111.91845, 29.545092],
                            [111.924711, 29.548646],
                            [111.929066, 29.548543],
                            [111.934692, 29.53352],
                            [111.939356, 29.528729],
                            [111.945291, 29.52605],
                            [111.949991, 29.521397],
                            [111.952695, 29.508671],
                            [111.963004, 29.502196],
                            [111.969682, 29.5012],
                            [111.971134, 29.494827],
                            [111.959664, 29.488626],
                            [111.954655, 29.483438],
                            [111.953748, 29.478851],
                            [111.956307, 29.474573],
                            [111.971098, 29.460347],
                            [111.976397, 29.4527],
                            [111.980499, 29.443764],
                            [111.986397, 29.451875],
                            [111.994854, 29.456068],
                            [112.011369, 29.459127],
                            [112.016777, 29.458749],
                            [112.034472, 29.454882],
                            [112.049698, 29.457116],
                            [112.058518, 29.459831],
                            [112.061331, 29.462855],
                            [112.063708, 29.473972],
                            [112.066957, 29.47813],
                            [112.073091, 29.481858],
                            [112.076884, 29.491598],
                            [112.075505, 29.494415],
                            [112.063509, 29.500014],
                            [112.055469, 29.505047],
                            [112.04997, 29.510234],
                            [112.041749, 29.523612],
                            [112.044036, 29.534069],
                            [112.052003, 29.548182],
                            [112.053273, 29.560028],
                            [112.0485, 29.572627],
                            [112.049099, 29.583971],
                            [112.052166, 29.589892],
                            [112.053273, 29.602093],
                            [112.057847, 29.607772],
                            [112.056558, 29.620074],
                            [112.0485, 29.629629],
                            [112.047375, 29.645976],
                            [112.043074, 29.656112],
                            [112.039372, 29.658427],
                            [112.038863, 29.667447],
                            [112.036105, 29.675455],
                            [112.03204, 29.678524],
                            [112.03527, 29.68353],
                            [112.029807, 29.683445],
                            [112.030334, 29.688022],
                            [112.034109, 29.690834],
                            [112.034453, 29.697742],
                            [112.043255, 29.711473],
                            [112.045125, 29.720608],
                            [112.043618, 29.731319],
                            [112.051222, 29.74035],
                            [112.050732, 29.749157],
                            [112.045578, 29.758409],
                            [112.031205, 29.762264],
                            [112.025506, 29.768448],
                            [112.016341, 29.772525],
                            [112.009754, 29.778041],
                            [112.00175, 29.777715],
                            [111.999119, 29.78049],
                            [111.991279, 29.782785],
                            [111.987105, 29.793969],
                            [111.980607, 29.794825],
                            [111.97705, 29.79051],
                            [111.965544, 29.792753],
                            [111.954855, 29.796761],
                            [111.956035, 29.8001],
                            [111.951552, 29.814227],
                            [111.952859, 29.818028],
                            [111.965254, 29.83116],
                            [111.965563, 29.834533],
                            [111.960935, 29.837443],
                            [111.950264, 29.837152],
                            [111.936199, 29.832513],
                            [111.925981, 29.836827],
                            [111.919284, 29.842424],
                            [111.914602, 29.852677],
                            [111.90306, 29.856101],
                            [111.884313, 29.85848],
                            [111.880629, 29.860003],
                            [111.879776, 29.866027],
                            [111.877834, 29.859626],
                            [111.863388, 29.856563],
                            [111.859232, 29.858788],
                            [111.849976, 29.870973],
                            [111.83965, 29.876808],
                            [111.828979, 29.873985],
                            [111.830666, 29.887913],
                            [111.824351, 29.887896],
                            [111.81034, 29.893782],
                            [111.810758, 29.900899],
                            [111.80299, 29.905124],
                            [111.794642, 29.905723],
                            [111.78339, 29.912908],
                            [111.780468, 29.911505],
                            [111.765296, 29.917646],
                            [111.747329, 29.921341],
                            [111.742937, 29.918604],
                            [111.736658, 29.919545],
                            [111.724898, 29.915525],
                            [111.722738, 29.906904],
                            [111.719, 29.901002],
                            [111.714826, 29.901036],
                            [111.711105, 29.896998],
                            [111.705371, 29.896742],
                            [111.707349, 29.894517],
                            [111.70508, 29.890035],
                            [111.699962, 29.887742],
                            [111.693012, 29.887263],
                            [111.681778, 29.88456],
                            [111.675988, 29.887143],
                            [111.664029, 29.887297],
                            [111.662377, 29.885809],
                            [111.650799, 29.889385],
                            [111.65147, 29.892961],
                            [111.644919, 29.89515],
                            [111.637115, 29.893542],
                            [111.6306, 29.895698],
                            [111.627079, 29.893525],
                            [111.621689, 29.894945],
                            [111.620037, 29.891421],
                            [111.612215, 29.892054],
                            [111.597933, 29.889385],
                            [111.592198, 29.894449],
                            [111.58875, 29.892807],
                            [111.583868, 29.897973],
                            [111.577933, 29.894415],
                            [111.56748, 29.898435],
                            [111.551382, 29.895236],
                            [111.546119, 29.904218],
                            [111.538443, 29.904645],
                            [111.533887, 29.914208],
                            [111.530657, 29.917646],
                            [111.527427, 29.925651],
                            [111.524069, 29.927772],
                            [111.514487, 29.927686],
                            [111.505195, 29.924505],
                            [111.494705, 29.923273],
                            [111.483453, 29.918176],
                            [111.474924, 29.919066],
                            [111.468535, 29.922196],
                            [111.463563, 29.926882],
                            [111.45594, 29.927943],
                            [111.449806, 29.923701],
                            [111.436068, 29.929465],
                            [111.425651, 29.922948],
                            [111.418083, 29.915166],
                            [111.404309, 29.911009],
                            [111.390843, 29.914824],
                            [111.382839, 29.92365],
                            [111.384055, 29.928062],
                            [111.389354, 29.932834],
                            [111.390117, 29.937503],
                            [111.381206, 29.93374],
                            [111.378229, 29.935211],
                            [111.383293, 29.945865],
                            [111.383111, 29.949388],
                            [111.377249, 29.950756],
                            [111.375362, 29.95421],
                            [111.368593, 29.954124],
                            [111.35941, 29.950602],
                            [111.351697, 29.951405],
                            [111.349301, 29.942685],
                            [111.341933, 29.945831],
                            [111.336452, 29.944224],
                            [111.331225, 29.940051],
                            [111.319356, 29.937417],
                            [111.313985, 29.93439],
                            [111.307197, 29.927891],
                            [111.293967, 29.930012],
                            [111.28845, 29.934442],
                            [111.283223, 29.930901],
                            [111.271844, 29.926438],
                            [111.266799, 29.925651],
                            [111.26032, 29.92772],
                            [111.247398, 29.921768],
                            [111.235021, 29.923342],
                            [111.224622, 29.923496],
                            [111.215621, 29.921717],
                            [111.219577, 29.915268],
                            [111.223406, 29.897922],
                            [111.226582, 29.890309],
                            [111.22386, 29.880213],
                            [111.231501, 29.869604],
                            [111.231428, 29.853653],
                            [111.243497, 29.845471],
                            [111.252716, 29.83544],
                            [111.258106, 29.832564],
                            [111.264131, 29.832495],
                            [111.273478, 29.839566],
                            [111.278305, 29.845437],
                            [111.282661, 29.845095],
                            [111.284076, 29.83871],
                            [111.292588, 29.838607],
                            [111.297052, 29.833677],
                            [111.304239, 29.829208],
                            [111.307215, 29.821949],
                            [111.316779, 29.81034],
                            [111.317905, 29.804039],
                            [111.313422, 29.797411],
                            [111.314347, 29.790715],
                            [111.312805, 29.787461],
                            [111.303459, 29.780387],
                            [111.299157, 29.773467],
                            [111.302134, 29.77172],
                            [111.318776, 29.770966],
                            [111.328086, 29.771411],
                            [111.338448, 29.77333],
                            [111.345889, 29.76939],
                            [111.345508, 29.761921],
                            [111.353439, 29.753115],
                            [111.356669, 29.746775],
                            [111.365326, 29.742663],
                            [111.367159, 29.736254],
                            [111.366215, 29.727703],
                            [111.369319, 29.726247],
                            [111.392966, 29.737608],
                            [111.400606, 29.74244],
                            [111.406759, 29.744034],
                            [111.411913, 29.741704],
                            [111.416323, 29.72832],
                            [111.422529, 29.71826],
                            [111.42772, 29.717489],
                            [111.4334, 29.721654],
                            [111.441204, 29.722322],
                            [111.449806, 29.72748],
                            [111.452547, 29.736477],
                            [111.451331, 29.746347],
                            [111.455777, 29.76139],
                            [111.463127, 29.760448],
                            [111.474488, 29.766975],
                            [111.48104, 29.766015],
                            [111.484343, 29.762863],
                            [111.493562, 29.758083],
                            [111.503997, 29.749346],
                            [111.505032, 29.742989],
                            [111.510149, 29.742081],
                            [111.509387, 29.738756],
                            [111.514251, 29.736306],
                            [111.533815, 29.731833],
                            [111.548116, 29.736529],
                            [111.553778, 29.733855],
                            [111.561854, 29.733478],
                            [111.568551, 29.740967],
                            [111.566772, 29.749328],
                            [111.578514, 29.756936],
                            [111.58287, 29.761321],
                            [111.598241, 29.762709],
                            [111.602252, 29.761989],
                            [111.611308, 29.756713],
                            [111.623213, 29.755171],
                            [111.626389, 29.753646],
                            [111.625954, 29.748078],
                            [111.617424, 29.738962],
                            [111.61305, 29.725989],
                            [111.613449, 29.717506],
                            [111.622088, 29.713084],
                            [111.629946, 29.710787],
                            [111.627115, 29.706348],
                            [111.620291, 29.704942],
                            [111.619638, 29.699765],
                            [111.630073, 29.701188],
                            [111.631797, 29.681833],
                            [111.631453, 29.673569],
                            [111.623195, 29.663606],
                            [111.623903, 29.657123],
                            [111.620654, 29.65143],
                            [111.630999, 29.648514],
                            [111.636843, 29.640401],
                            [111.648185, 29.63589],
                            [111.649546, 29.630504],
                            [111.641924, 29.619731],
                            [111.638911, 29.61949],
                            [111.626425, 29.627656],
                            [111.617279, 29.631173],
                            [111.614266, 29.629492],
                            [111.61423, 29.624551],
                            [111.609384, 29.623436],
                            [111.603305, 29.61702],
                            [111.607696, 29.612919],
                            [111.616117, 29.601252],
                            [111.621362, 29.596224],
                            [111.63363, 29.589841],
                            [111.643104, 29.589909],
                            [111.650418, 29.591883],
                            [111.660326, 29.599262],
                            [111.674337, 29.60676],
                            [111.67539, 29.603105],
                            [111.666352, 29.580436],
                            [111.672812, 29.576506],
                            [111.679473, 29.579784],
                            [111.684155, 29.575579],
                            [111.687494, 29.56504],
                            [111.692993, 29.562242],
                            [111.702558, 29.565006],
                            [111.705969, 29.561761],
                            [111.712757, 29.560903],
                            [111.717838, 29.557315],
                            [111.719635, 29.553264],
                            [111.717584, 29.547633],
                            [111.710942, 29.54638],
                            [111.709708, 29.537881],
                            [111.712902, 29.529072],
                            [111.719889, 29.5153],
                            [111.720216, 29.508808],
                            [111.718183, 29.505992],
                            [111.722285, 29.500736],
                            [111.731359, 29.499705],
                            [111.736767, 29.504944],
                            [111.742339, 29.505717],
                            [111.749435, 29.503398],
                            [111.763627, 29.507589],
                            [111.771267, 29.507108]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430724,
                "name": "临澧县",
                "center": [111.645602, 29.443217],
                "centroid": [111.618874, 29.480004],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430700
                },
                "subFeatureIndex": 5,
                "acroutes": [100000, 430000, 430700]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [111.441204, 29.722322],
                            [111.438827, 29.713135],
                            [111.442983, 29.701428],
                            [111.436667, 29.696491],
                            [111.433945, 29.691777],
                            [111.44634, 29.677169],
                            [111.457973, 29.67326],
                            [111.472002, 29.665818],
                            [111.480785, 29.65678],
                            [111.495649, 29.643231],
                            [111.506247, 29.635701],
                            [111.502382, 29.627862],
                            [111.502418, 29.616402],
                            [111.509405, 29.603002],
                            [111.511783, 29.594028],
                            [111.516048, 29.592209],
                            [111.518116, 29.587112],
                            [111.523888, 29.584727],
                            [111.532925, 29.590476],
                            [111.540203, 29.589652],
                            [111.543379, 29.585979],
                            [111.539568, 29.579372],
                            [111.539513, 29.569451],
                            [111.535902, 29.5648],
                            [111.534287, 29.570069],
                            [111.530004, 29.57206],
                            [111.521147, 29.568301],
                            [111.515303, 29.571219],
                            [111.493471, 29.556526],
                            [111.492255, 29.553247],
                            [111.503053, 29.557985],
                            [111.504596, 29.555976],
                            [111.50191, 29.547599],
                            [111.505866, 29.545435],
                            [111.517046, 29.545006],
                            [111.521311, 29.541693],
                            [111.519677, 29.534533],
                            [111.503979, 29.522135],
                            [111.490295, 29.53036],
                            [111.488263, 29.540044],
                            [111.482455, 29.545538],
                            [111.477864, 29.547564],
                            [111.475867, 29.552903],
                            [111.468953, 29.55256],
                            [111.463635, 29.544079],
                            [111.471112, 29.540886],
                            [111.475541, 29.534361],
                            [111.476847, 29.52308],
                            [111.465922, 29.524076],
                            [111.463653, 29.519422],
                            [111.473454, 29.514064],
                            [111.484687, 29.515146],
                            [111.494324, 29.513909],
                            [111.497464, 29.511865],
                            [111.496665, 29.499173],
                            [111.49848, 29.489416],
                            [111.50779, 29.477339],
                            [111.509006, 29.471532],
                            [111.508335, 29.462495],
                            [111.502327, 29.454917],
                            [111.503253, 29.446617],
                            [111.496211, 29.444606],
                            [111.49358, 29.435978],
                            [111.496429, 29.429258],
                            [111.502019, 29.432661],
                            [111.511982, 29.429482],
                            [111.508589, 29.424634],
                            [111.508933, 29.420956],
                            [111.502799, 29.419168],
                            [111.503035, 29.415146],
                            [111.48966, 29.404899],
                            [111.488008, 29.400704],
                            [111.490858, 29.394859],
                            [111.484651, 29.390354],
                            [111.488753, 29.380019],
                            [111.473417, 29.360946],
                            [111.4532, 29.362701],
                            [111.447629, 29.364765],
                            [111.439063, 29.371747],
                            [111.429281, 29.371799],
                            [111.428101, 29.366485],
                            [111.435505, 29.358504],
                            [111.435669, 29.354152],
                            [111.431585, 29.348957],
                            [111.429371, 29.341595],
                            [111.431948, 29.332924],
                            [111.427484, 29.330154],
                            [111.428391, 29.326679],
                            [111.437701, 29.329087],
                            [111.455142, 29.328588],
                            [111.456812, 29.336554],
                            [111.467809, 29.352002],
                            [111.477337, 29.356646],
                            [111.480822, 29.356612],
                            [111.493562, 29.348785],
                            [111.501946, 29.345018],
                            [111.511148, 29.344812],
                            [111.511347, 29.337982],
                            [111.52367, 29.3375],
                            [111.520603, 29.331186],
                            [111.529514, 29.333509],
                            [111.532073, 29.331393],
                            [111.530312, 29.325251],
                            [111.525684, 29.324494],
                            [111.518116, 29.311089],
                            [111.519568, 29.306632],
                            [111.511057, 29.303276],
                            [111.508099, 29.299834],
                            [111.503398, 29.30307],
                            [111.496701, 29.297718],
                            [111.487773, 29.296719],
                            [111.477628, 29.290024],
                            [111.48456, 29.288613],
                            [111.488263, 29.282055],
                            [111.498117, 29.269747],
                            [111.507482, 29.267199],
                            [111.512382, 29.262723],
                            [111.520004, 29.263584],
                            [111.526084, 29.258126],
                            [111.533143, 29.264565],
                            [111.535085, 29.269867],
                            [111.542508, 29.270556],
                            [111.546827, 29.274446],
                            [111.55238, 29.273982],
                            [111.562217, 29.278664],
                            [111.571164, 29.279507],
                            [111.57434, 29.276202],
                            [111.578314, 29.27739],
                            [111.587552, 29.276409],
                            [111.591635, 29.278061],
                            [111.596717, 29.283621],
                            [111.606317, 29.283948],
                            [111.610128, 29.287838],
                            [111.611362, 29.292899],
                            [111.615482, 29.29295],
                            [111.620309, 29.285945],
                            [111.626099, 29.284138],
                            [111.631543, 29.278698],
                            [111.642614, 29.279352],
                            [111.653285, 29.275118],
                            [111.672667, 29.281332],
                            [111.679382, 29.28789],
                            [111.686006, 29.287804],
                            [111.693846, 29.284809],
                            [111.704772, 29.286616],
                            [111.707675, 29.28968],
                            [111.715425, 29.288458],
                            [111.719345, 29.294689],
                            [111.725733, 29.292365],
                            [111.725025, 29.295394],
                            [111.736477, 29.295979],
                            [111.751413, 29.295566],
                            [111.755079, 29.284878],
                            [111.758745, 29.280093],
                            [111.765205, 29.281366],
                            [111.771067, 29.292899],
                            [111.780377, 29.300609],
                            [111.789706, 29.303689],
                            [111.784733, 29.307269],
                            [111.78633, 29.316131],
                            [111.79152, 29.318901],
                            [111.794134, 29.325079],
                            [111.793644, 29.33086],
                            [111.798126, 29.339565],
                            [111.796638, 29.344313],
                            [111.802101, 29.344278],
                            [111.802827, 29.353808],
                            [111.800903, 29.359037],
                            [111.803571, 29.373157],
                            [111.809759, 29.377921],
                            [111.815549, 29.389305],
                            [111.814206, 29.400533],
                            [111.816184, 29.406447],
                            [111.822481, 29.413427],
                            [111.82357, 29.426216],
                            [111.819614, 29.427814],
                            [111.826456, 29.431561],
                            [111.827617, 29.441788],
                            [111.824968, 29.442647],
                            [111.827327, 29.448198],
                            [111.832263, 29.44727],
                            [111.837, 29.45258],
                            [111.825313, 29.453594],
                            [111.833534, 29.456841],
                            [111.835004, 29.460811],
                            [111.828652, 29.460553],
                            [111.827381, 29.465811],
                            [111.830176, 29.467512],
                            [111.831211, 29.473869],
                            [111.83386, 29.46679],
                            [111.839867, 29.462117],
                            [111.843824, 29.479298],
                            [111.841519, 29.482768],
                            [111.834713, 29.482476],
                            [111.83533, 29.486307],
                            [111.830939, 29.489382],
                            [111.828071, 29.485637],
                            [111.821411, 29.494758],
                            [111.814278, 29.490206],
                            [111.818561, 29.485963],
                            [111.816547, 29.48349],
                            [111.803317, 29.492388],
                            [111.797999, 29.492525],
                            [111.792972, 29.48866],
                            [111.787129, 29.492422],
                            [111.776476, 29.505579],
                            [111.771267, 29.507108],
                            [111.763627, 29.507589],
                            [111.749435, 29.503398],
                            [111.742339, 29.505717],
                            [111.736767, 29.504944],
                            [111.731359, 29.499705],
                            [111.722285, 29.500736],
                            [111.718183, 29.505992],
                            [111.720216, 29.508808],
                            [111.719889, 29.5153],
                            [111.712902, 29.529072],
                            [111.709708, 29.537881],
                            [111.710942, 29.54638],
                            [111.717584, 29.547633],
                            [111.719635, 29.553264],
                            [111.717838, 29.557315],
                            [111.712757, 29.560903],
                            [111.705969, 29.561761],
                            [111.702558, 29.565006],
                            [111.692993, 29.562242],
                            [111.687494, 29.56504],
                            [111.684155, 29.575579],
                            [111.679473, 29.579784],
                            [111.672812, 29.576506],
                            [111.666352, 29.580436],
                            [111.67539, 29.603105],
                            [111.674337, 29.60676],
                            [111.660326, 29.599262],
                            [111.650418, 29.591883],
                            [111.643104, 29.589909],
                            [111.63363, 29.589841],
                            [111.621362, 29.596224],
                            [111.616117, 29.601252],
                            [111.607696, 29.612919],
                            [111.603305, 29.61702],
                            [111.609384, 29.623436],
                            [111.61423, 29.624551],
                            [111.614266, 29.629492],
                            [111.617279, 29.631173],
                            [111.626425, 29.627656],
                            [111.638911, 29.61949],
                            [111.641924, 29.619731],
                            [111.649546, 29.630504],
                            [111.648185, 29.63589],
                            [111.636843, 29.640401],
                            [111.630999, 29.648514],
                            [111.620654, 29.65143],
                            [111.623903, 29.657123],
                            [111.623195, 29.663606],
                            [111.631453, 29.673569],
                            [111.631797, 29.681833],
                            [111.630073, 29.701188],
                            [111.619638, 29.699765],
                            [111.620291, 29.704942],
                            [111.627115, 29.706348],
                            [111.629946, 29.710787],
                            [111.622088, 29.713084],
                            [111.613449, 29.717506],
                            [111.61305, 29.725989],
                            [111.617424, 29.738962],
                            [111.625954, 29.748078],
                            [111.626389, 29.753646],
                            [111.623213, 29.755171],
                            [111.611308, 29.756713],
                            [111.602252, 29.761989],
                            [111.598241, 29.762709],
                            [111.58287, 29.761321],
                            [111.578514, 29.756936],
                            [111.566772, 29.749328],
                            [111.568551, 29.740967],
                            [111.561854, 29.733478],
                            [111.553778, 29.733855],
                            [111.548116, 29.736529],
                            [111.533815, 29.731833],
                            [111.514251, 29.736306],
                            [111.509387, 29.738756],
                            [111.510149, 29.742081],
                            [111.505032, 29.742989],
                            [111.503997, 29.749346],
                            [111.493562, 29.758083],
                            [111.484343, 29.762863],
                            [111.48104, 29.766015],
                            [111.474488, 29.766975],
                            [111.463127, 29.760448],
                            [111.455777, 29.76139],
                            [111.451331, 29.746347],
                            [111.452547, 29.736477],
                            [111.449806, 29.72748],
                            [111.441204, 29.722322]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430725,
                "name": "桃源县",
                "center": [111.484503, 28.902734],
                "centroid": [111.263299, 28.912234],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430700
                },
                "subFeatureIndex": 6,
                "acroutes": [100000, 430000, 430700]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [111.494215, 28.581242],
                            [111.495667, 28.593754],
                            [111.500113, 28.605241],
                            [111.501783, 28.613349],
                            [111.506647, 28.627156],
                            [111.507754, 28.633461],
                            [111.495413, 28.637393],
                            [111.494397, 28.640459],
                            [111.49623, 28.649673],
                            [111.493907, 28.652946],
                            [111.487718, 28.65466],
                            [111.481657, 28.662678],
                            [111.481421, 28.66983],
                            [111.475051, 28.672375],
                            [111.471366, 28.677344],
                            [111.471512, 28.682123],
                            [111.479043, 28.694484],
                            [111.477646, 28.702914],
                            [111.47378, 28.706687],
                            [111.478444, 28.715688],
                            [111.482836, 28.717816],
                            [111.492128, 28.727006],
                            [111.493271, 28.735278],
                            [111.502019, 28.738531],
                            [111.507028, 28.74611],
                            [111.501892, 28.747408],
                            [111.500186, 28.757391],
                            [111.490422, 28.755453],
                            [111.486611, 28.758394],
                            [111.478934, 28.755488],
                            [111.474869, 28.757616],
                            [111.474597, 28.767892],
                            [111.470259, 28.773808],
                            [111.46957, 28.780052],
                            [111.462383, 28.792021],
                            [111.463109, 28.794892],
                            [111.471657, 28.797279],
                            [111.476049, 28.794477],
                            [111.476829, 28.790586],
                            [111.48104, 28.791485],
                            [111.484143, 28.795376],
                            [111.493271, 28.793526],
                            [111.500132, 28.788147],
                            [111.502582, 28.795982],
                            [111.508062, 28.797867],
                            [111.510748, 28.803643],
                            [111.522272, 28.797797],
                            [111.52543, 28.799561],
                            [111.525176, 28.806219],
                            [111.528407, 28.814001],
                            [111.529205, 28.820606],
                            [111.527681, 28.82913],
                            [111.528534, 28.835284],
                            [111.519695, 28.846037],
                            [111.518788, 28.856874],
                            [111.515322, 28.868299],
                            [111.524468, 28.88361],
                            [111.524559, 28.899679],
                            [111.528062, 28.90379],
                            [111.530838, 28.912774],
                            [111.528534, 28.925712],
                            [111.536337, 28.942258],
                            [111.54474, 28.946817],
                            [111.556482, 28.948026],
                            [111.581436, 28.96115],
                            [111.589839, 28.966105],
                            [111.588532, 28.978519],
                            [111.592016, 28.984595],
                            [111.60031, 28.992795],
                            [111.608731, 28.998198],
                            [111.615645, 29.009486],
                            [111.615918, 29.016596],
                            [111.612397, 29.0216],
                            [111.605519, 29.026329],
                            [111.592615, 29.038804],
                            [111.591036, 29.043911],
                            [111.592506, 29.053969],
                            [111.592125, 29.066355],
                            [111.585519, 29.06582],
                            [111.578423, 29.069546],
                            [111.57454, 29.068873],
                            [111.572053, 29.072564],
                            [111.57218, 29.081171],
                            [111.568551, 29.092846],
                            [111.573705, 29.095933],
                            [111.577153, 29.100744],
                            [111.574213, 29.110176],
                            [111.576209, 29.115124],
                            [111.583233, 29.121452],
                            [111.58002, 29.126175],
                            [111.581436, 29.132054],
                            [111.57267, 29.133105],
                            [111.566427, 29.136777],
                            [111.564322, 29.145654],
                            [111.570039, 29.152238],
                            [111.567516, 29.161975],
                            [111.578423, 29.165525],
                            [111.582561, 29.185186],
                            [111.58336, 29.196953],
                            [111.571999, 29.205273],
                            [111.5714, 29.210148],
                            [111.581926, 29.217193],
                            [111.587461, 29.218347],
                            [111.590764, 29.225098],
                            [111.584721, 29.234674],
                            [111.571472, 29.238944],
                            [111.564104, 29.234519],
                            [111.560801, 29.236378],
                            [111.5567, 29.243215],
                            [111.556446, 29.24826],
                            [111.560003, 29.254149],
                            [111.549513, 29.264651],
                            [111.55238, 29.273982],
                            [111.546827, 29.274446],
                            [111.542508, 29.270556],
                            [111.535085, 29.269867],
                            [111.533143, 29.264565],
                            [111.526084, 29.258126],
                            [111.520004, 29.263584],
                            [111.512382, 29.262723],
                            [111.507482, 29.267199],
                            [111.498117, 29.269747],
                            [111.488263, 29.282055],
                            [111.48456, 29.288613],
                            [111.475178, 29.280781],
                            [111.467356, 29.280196],
                            [111.456757, 29.285876],
                            [111.450314, 29.280592],
                            [111.435995, 29.276891],
                            [111.429988, 29.277665],
                            [111.422548, 29.281711],
                            [111.410824, 29.284448],
                            [111.397067, 29.27121],
                            [111.388102, 29.272415],
                            [111.389209, 29.278681],
                            [111.386305, 29.289267],
                            [111.37803, 29.29758],
                            [111.377939, 29.302055],
                            [111.382258, 29.308714],
                            [111.389863, 29.314221],
                            [111.387013, 29.324614],
                            [111.380244, 29.329001],
                            [111.373475, 29.329363],
                            [111.361479, 29.336296],
                            [111.354836, 29.345723],
                            [111.35656, 29.351039],
                            [111.35264, 29.355717],
                            [111.346252, 29.359037],
                            [111.345944, 29.364782],
                            [111.341298, 29.367774],
                            [111.334583, 29.375995],
                            [111.326743, 29.391403],
                            [111.321607, 29.396956],
                            [111.31716, 29.398727],
                            [111.312986, 29.404023],
                            [111.303676, 29.406756],
                            [111.297506, 29.403318],
                            [111.300881, 29.397059],
                            [111.286199, 29.389511],
                            [111.269467, 29.386571],
                            [111.259231, 29.380638],
                            [111.248015, 29.357558],
                            [111.244386, 29.352174],
                            [111.240756, 29.340305],
                            [111.235475, 29.335023],
                            [111.23611, 29.329638],
                            [111.240139, 29.327505],
                            [111.245565, 29.321052],
                            [111.248052, 29.310521],
                            [111.25199, 29.302777],
                            [111.250338, 29.293226],
                            [111.243932, 29.282692],
                            [111.234876, 29.273517],
                            [111.222118, 29.265684],
                            [111.220158, 29.257454],
                            [111.22729, 29.244024],
                            [111.232771, 29.239857],
                            [111.231446, 29.231729],
                            [111.223207, 29.223669],
                            [111.214405, 29.21907],
                            [111.20642, 29.217554],
                            [111.192954, 29.218106],
                            [111.180468, 29.221912],
                            [111.173571, 29.231367],
                            [111.165659, 29.236172],
                            [111.153898, 29.237739],
                            [111.12183, 29.218571],
                            [111.112176, 29.214867],
                            [111.092829, 29.204119],
                            [111.085969, 29.19647],
                            [111.080761, 29.188407],
                            [111.071832, 29.169833],
                            [111.065063, 29.164301],
                            [111.055281, 29.152152],
                            [111.050925, 29.14362],
                            [111.043484, 29.139259],
                            [111.030617, 29.140224],
                            [111.025808, 29.137708],
                            [111.024519, 29.124813],
                            [111.022323, 29.120452],
                            [111.001652, 29.117952],
                            [110.985355, 29.110986],
                            [110.980074, 29.11209],
                            [110.983813, 29.118952],
                            [110.97472, 29.119228],
                            [110.971835, 29.12302],
                            [110.95846, 29.133881],
                            [110.947189, 29.138242],
                            [110.941636, 29.138087],
                            [110.945756, 29.13095],
                            [110.943632, 29.127537],
                            [110.938079, 29.131623],
                            [110.935774, 29.129399],
                            [110.929767, 29.130847],
                            [110.926137, 29.125279],
                            [110.925375, 29.118676],
                            [110.913143, 29.111314],
                            [110.901946, 29.110917],
                            [110.89877, 29.109314],
                            [110.889605, 29.109883],
                            [110.889224, 29.104883],
                            [110.882763, 29.09852],
                            [110.873471, 29.099313],
                            [110.86868, 29.108072],
                            [110.864669, 29.107607],
                            [110.862872, 29.102951],
                            [110.856012, 29.095674],
                            [110.852564, 29.094226],
                            [110.852165, 29.08681],
                            [110.855069, 29.080671],
                            [110.861003, 29.073047],
                            [110.861892, 29.064647],
                            [110.868444, 29.065372],
                            [110.870259, 29.061283],
                            [110.873199, 29.066424],
                            [110.874905, 29.061542],
                            [110.882491, 29.054711],
                            [110.886828, 29.05547],
                            [110.89171, 29.050743],
                            [110.89857, 29.051243],
                            [110.909713, 29.048725],
                            [110.911945, 29.045326],
                            [110.910802, 29.039822],
                            [110.914414, 29.03644],
                            [110.913887, 29.029952],
                            [110.919078, 29.022532],
                            [110.928315, 29.018581],
                            [110.936772, 29.013265],
                            [110.943523, 29.011798],
                            [110.954558, 29.001926],
                            [110.952398, 28.998491],
                            [110.962017, 28.99837],
                            [110.965428, 28.99371],
                            [110.970583, 28.993019],
                            [110.967624, 28.988186],
                            [110.973486, 28.990171],
                            [110.978332, 28.987668],
                            [110.979947, 28.991138],
                            [110.98296, 28.987893],
                            [110.987025, 28.988393],
                            [110.986372, 28.983232],
                            [110.982597, 28.976827],
                            [110.974575, 28.976792],
                            [110.965356, 28.965155],
                            [110.958659, 28.963809],
                            [110.954921, 28.960752],
                            [110.95679, 28.951048],
                            [110.950129, 28.939253],
                            [110.955138, 28.930427],
                            [110.955937, 28.926178],
                            [110.96287, 28.917922],
                            [110.957443, 28.910597],
                            [110.958278, 28.906382],
                            [110.964213, 28.904205],
                            [110.97267, 28.90424],
                            [110.989711, 28.897277],
                            [110.992451, 28.888241],
                            [111.002088, 28.87573],
                            [111.003086, 28.872187],
                            [111.000182, 28.867711],
                            [111.003867, 28.86721],
                            [111.012451, 28.854524],
                            [111.013122, 28.851326],
                            [111.022577, 28.846901],
                            [111.030472, 28.839658],
                            [111.03372, 28.828818],
                            [111.04029, 28.822698],
                            [111.048457, 28.825412],
                            [111.059455, 28.825101],
                            [111.064409, 28.823424],
                            [111.071215, 28.816888],
                            [111.072757, 28.809833],
                            [111.079363, 28.805701],
                            [111.078746, 28.793681],
                            [111.086296, 28.78538],
                            [111.09243, 28.781315],
                            [111.101014, 28.778997],
                            [111.09871, 28.76765],
                            [111.10615, 28.76073],
                            [111.108401, 28.751041],
                            [111.115152, 28.743342],
                            [111.102575, 28.734049],
                            [111.105479, 28.727093],
                            [111.101976, 28.722801],
                            [111.101123, 28.713645],
                            [111.096096, 28.709734],
                            [111.096332, 28.700525],
                            [111.085062, 28.695315],
                            [111.081178, 28.691852],
                            [111.075806, 28.692302],
                            [111.064119, 28.696388],
                            [111.051578, 28.692112],
                            [111.04666, 28.684702],
                            [111.055426, 28.673916],
                            [111.061887, 28.668583],
                            [111.064645, 28.659769],
                            [111.051705, 28.652323],
                            [111.038312, 28.650556],
                            [111.02706, 28.652115],
                            [111.015264, 28.649881],
                            [111.005917, 28.642295],
                            [110.995355, 28.637844],
                            [110.995028, 28.630569],
                            [110.991725, 28.620002],
                            [110.987152, 28.618668],
                            [110.978368, 28.620435],
                            [110.970855, 28.620279],
                            [110.962761, 28.622826],
                            [110.955392, 28.61917],
                            [110.946137, 28.622029],
                            [110.934722, 28.617577],
                            [110.93728, 28.610785],
                            [110.941999, 28.608429],
                            [110.945919, 28.601395],
                            [110.943868, 28.591622],
                            [110.950039, 28.577447],
                            [110.950674, 28.568227],
                            [110.956155, 28.568851],
                            [110.97276, 28.57996],
                            [110.978386, 28.585523],
                            [110.989239, 28.587827],
                            [111.000182, 28.586909],
                            [111.005718, 28.583582],
                            [111.01011, 28.587377],
                            [111.008023, 28.598189],
                            [111.015772, 28.599316],
                            [111.015899, 28.593927],
                            [111.020182, 28.589716],
                            [111.026298, 28.59008],
                            [111.031035, 28.594637],
                            [111.033793, 28.591588],
                            [111.036261, 28.596457],
                            [111.040163, 28.59436],
                            [111.043539, 28.603024],
                            [111.050508, 28.600355],
                            [111.049745, 28.608169],
                            [111.05744, 28.607944],
                            [111.055081, 28.602331],
                            [111.057186, 28.596959],
                            [111.055353, 28.590167],
                            [111.0645, 28.582802],
                            [111.062086, 28.574882],
                            [111.054754, 28.570618],
                            [111.054809, 28.565107],
                            [111.059273, 28.563859],
                            [111.064845, 28.554863],
                            [111.068819, 28.552055],
                            [111.079291, 28.537683],
                            [111.075933, 28.530176],
                            [111.067549, 28.530124],
                            [111.059636, 28.534216],
                            [111.049274, 28.536834],
                            [111.041869, 28.535031],
                            [111.039092, 28.539677],
                            [111.029673, 28.543664],
                            [111.020309, 28.542208],
                            [111.013195, 28.545779],
                            [111.002034, 28.537146],
                            [110.993177, 28.521767],
                            [110.982306, 28.515229],
                            [110.981671, 28.511969],
                            [110.969893, 28.512542],
                            [110.966572, 28.509542],
                            [110.962942, 28.498286],
                            [110.959331, 28.492251],
                            [110.952616, 28.487567],
                            [110.943814, 28.478443],
                            [110.93895, 28.479293],
                            [110.929749, 28.484792],
                            [110.925611, 28.482485],
                            [110.923451, 28.473291],
                            [110.917481, 28.4697],
                            [110.905358, 28.455127],
                            [110.889224, 28.447301],
                            [110.888643, 28.438937],
                            [110.87289, 28.427362],
                            [110.870241, 28.423666],
                            [110.862147, 28.418788],
                            [110.860459, 28.406031],
                            [110.865087, 28.407471],
                            [110.875631, 28.406742],
                            [110.882273, 28.408096],
                            [110.889478, 28.404902],
                            [110.894196, 28.407766],
                            [110.907009, 28.411099],
                            [110.919967, 28.417591],
                            [110.931001, 28.421288],
                            [110.940384, 28.421756],
                            [110.944612, 28.42646],
                            [110.96737, 28.430347],
                            [110.972361, 28.433037],
                            [110.978713, 28.43951],
                            [110.992832, 28.441714],
                            [111.001398, 28.440135],
                            [111.00775, 28.445549],
                            [111.020109, 28.445653],
                            [111.034809, 28.454919],
                            [111.044119, 28.458475],
                            [111.050217, 28.458892],
                            [111.062703, 28.451448],
                            [111.068874, 28.451414],
                            [111.07655, 28.454103],
                            [111.083792, 28.453704],
                            [111.093519, 28.456966],
                            [111.096877, 28.463628],
                            [111.101958, 28.466734],
                            [111.109326, 28.468139],
                            [111.11664, 28.464392],
                            [111.126349, 28.470516],
                            [111.140487, 28.474575],
                            [111.144933, 28.473725],
                            [111.148345, 28.483734],
                            [111.147873, 28.487862],
                            [111.154352, 28.496535],
                            [111.152265, 28.505969],
                            [111.152174, 28.514328],
                            [111.162392, 28.515594],
                            [111.167655, 28.517674],
                            [111.175205, 28.513894],
                            [111.180341, 28.514917],
                            [111.184787, 28.518836],
                            [111.192209, 28.518836],
                            [111.197491, 28.522928],
                            [111.198253, 28.526552],
                            [111.211846, 28.53243],
                            [111.215185, 28.53621],
                            [111.221174, 28.53328],
                            [111.221283, 28.527697],
                            [111.228651, 28.52246],
                            [111.245947, 28.52787],
                            [111.252625, 28.531528],
                            [111.258106, 28.527281],
                            [111.264694, 28.526674],
                            [111.266599, 28.523518],
                            [111.273949, 28.523171],
                            [111.281027, 28.517917],
                            [111.287905, 28.517345],
                            [111.29825, 28.52116],
                            [111.309157, 28.520969],
                            [111.31569, 28.52442],
                            [111.324129, 28.518836],
                            [111.334365, 28.52031],
                            [111.339229, 28.527853],
                            [111.348158, 28.528702],
                            [111.35627, 28.536071],
                            [111.363366, 28.539504],
                            [111.373874, 28.551829],
                            [111.38322, 28.550547],
                            [111.396577, 28.540787],
                            [111.403855, 28.542399],
                            [111.412276, 28.54786],
                            [111.426032, 28.547045],
                            [111.43576, 28.548934],
                            [111.437937, 28.554395],
                            [111.443382, 28.55729],
                            [111.467846, 28.556908],
                            [111.477573, 28.561259],
                            [111.483399, 28.568244],
                            [111.491475, 28.571641],
                            [111.494215, 28.581242]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430726,
                "name": "石门县",
                "center": [111.379087, 29.584703],
                "centroid": [111.037894, 29.795422],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430700
                },
                "subFeatureIndex": 7,
                "acroutes": [100000, 430000, 430700]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [111.297506, 29.403318],
                            [111.303676, 29.406756],
                            [111.312986, 29.404023],
                            [111.31716, 29.398727],
                            [111.321607, 29.396956],
                            [111.326743, 29.391403],
                            [111.334583, 29.375995],
                            [111.341298, 29.367774],
                            [111.345944, 29.364782],
                            [111.346252, 29.359037],
                            [111.35264, 29.355717],
                            [111.35656, 29.351039],
                            [111.354836, 29.345723],
                            [111.361479, 29.336296],
                            [111.373475, 29.329363],
                            [111.380244, 29.329001],
                            [111.387013, 29.324614],
                            [111.389863, 29.314221],
                            [111.382258, 29.308714],
                            [111.377939, 29.302055],
                            [111.37803, 29.29758],
                            [111.386305, 29.289267],
                            [111.389209, 29.278681],
                            [111.388102, 29.272415],
                            [111.397067, 29.27121],
                            [111.410824, 29.284448],
                            [111.422548, 29.281711],
                            [111.429988, 29.277665],
                            [111.435995, 29.276891],
                            [111.450314, 29.280592],
                            [111.456757, 29.285876],
                            [111.467356, 29.280196],
                            [111.475178, 29.280781],
                            [111.48456, 29.288613],
                            [111.477628, 29.290024],
                            [111.487773, 29.296719],
                            [111.496701, 29.297718],
                            [111.503398, 29.30307],
                            [111.508099, 29.299834],
                            [111.511057, 29.303276],
                            [111.519568, 29.306632],
                            [111.518116, 29.311089],
                            [111.525684, 29.324494],
                            [111.530312, 29.325251],
                            [111.532073, 29.331393],
                            [111.529514, 29.333509],
                            [111.520603, 29.331186],
                            [111.52367, 29.3375],
                            [111.511347, 29.337982],
                            [111.511148, 29.344812],
                            [111.501946, 29.345018],
                            [111.493562, 29.348785],
                            [111.480822, 29.356612],
                            [111.477337, 29.356646],
                            [111.467809, 29.352002],
                            [111.456812, 29.336554],
                            [111.455142, 29.328588],
                            [111.437701, 29.329087],
                            [111.428391, 29.326679],
                            [111.427484, 29.330154],
                            [111.431948, 29.332924],
                            [111.429371, 29.341595],
                            [111.431585, 29.348957],
                            [111.435669, 29.354152],
                            [111.435505, 29.358504],
                            [111.428101, 29.366485],
                            [111.429281, 29.371799],
                            [111.439063, 29.371747],
                            [111.447629, 29.364765],
                            [111.4532, 29.362701],
                            [111.473417, 29.360946],
                            [111.488753, 29.380019],
                            [111.484651, 29.390354],
                            [111.490858, 29.394859],
                            [111.488008, 29.400704],
                            [111.48966, 29.404899],
                            [111.503035, 29.415146],
                            [111.502799, 29.419168],
                            [111.508933, 29.420956],
                            [111.508589, 29.424634],
                            [111.511982, 29.429482],
                            [111.502019, 29.432661],
                            [111.496429, 29.429258],
                            [111.49358, 29.435978],
                            [111.496211, 29.444606],
                            [111.503253, 29.446617],
                            [111.502327, 29.454917],
                            [111.508335, 29.462495],
                            [111.509006, 29.471532],
                            [111.50779, 29.477339],
                            [111.49848, 29.489416],
                            [111.496665, 29.499173],
                            [111.497464, 29.511865],
                            [111.494324, 29.513909],
                            [111.484687, 29.515146],
                            [111.473454, 29.514064],
                            [111.463653, 29.519422],
                            [111.465922, 29.524076],
                            [111.476847, 29.52308],
                            [111.475541, 29.534361],
                            [111.471112, 29.540886],
                            [111.463635, 29.544079],
                            [111.468953, 29.55256],
                            [111.475867, 29.552903],
                            [111.477864, 29.547564],
                            [111.482455, 29.545538],
                            [111.488263, 29.540044],
                            [111.490295, 29.53036],
                            [111.503979, 29.522135],
                            [111.519677, 29.534533],
                            [111.521311, 29.541693],
                            [111.517046, 29.545006],
                            [111.505866, 29.545435],
                            [111.50191, 29.547599],
                            [111.504596, 29.555976],
                            [111.503053, 29.557985],
                            [111.492255, 29.553247],
                            [111.493471, 29.556526],
                            [111.515303, 29.571219],
                            [111.521147, 29.568301],
                            [111.530004, 29.57206],
                            [111.534287, 29.570069],
                            [111.535902, 29.5648],
                            [111.539513, 29.569451],
                            [111.539568, 29.579372],
                            [111.543379, 29.585979],
                            [111.540203, 29.589652],
                            [111.532925, 29.590476],
                            [111.523888, 29.584727],
                            [111.518116, 29.587112],
                            [111.516048, 29.592209],
                            [111.511783, 29.594028],
                            [111.509405, 29.603002],
                            [111.502418, 29.616402],
                            [111.502382, 29.627862],
                            [111.506247, 29.635701],
                            [111.495649, 29.643231],
                            [111.480785, 29.65678],
                            [111.472002, 29.665818],
                            [111.457973, 29.67326],
                            [111.44634, 29.677169],
                            [111.433945, 29.691777],
                            [111.436667, 29.696491],
                            [111.442983, 29.701428],
                            [111.438827, 29.713135],
                            [111.441204, 29.722322],
                            [111.4334, 29.721654],
                            [111.42772, 29.717489],
                            [111.422529, 29.71826],
                            [111.416323, 29.72832],
                            [111.411913, 29.741704],
                            [111.406759, 29.744034],
                            [111.400606, 29.74244],
                            [111.392966, 29.737608],
                            [111.369319, 29.726247],
                            [111.366215, 29.727703],
                            [111.367159, 29.736254],
                            [111.365326, 29.742663],
                            [111.356669, 29.746775],
                            [111.353439, 29.753115],
                            [111.345508, 29.761921],
                            [111.345889, 29.76939],
                            [111.338448, 29.77333],
                            [111.328086, 29.771411],
                            [111.318776, 29.770966],
                            [111.302134, 29.77172],
                            [111.299157, 29.773467],
                            [111.303459, 29.780387],
                            [111.312805, 29.787461],
                            [111.314347, 29.790715],
                            [111.313422, 29.797411],
                            [111.317905, 29.804039],
                            [111.316779, 29.81034],
                            [111.307215, 29.821949],
                            [111.304239, 29.829208],
                            [111.297052, 29.833677],
                            [111.292588, 29.838607],
                            [111.284076, 29.83871],
                            [111.282661, 29.845095],
                            [111.278305, 29.845437],
                            [111.273478, 29.839566],
                            [111.264131, 29.832495],
                            [111.258106, 29.832564],
                            [111.252716, 29.83544],
                            [111.243497, 29.845471],
                            [111.231428, 29.853653],
                            [111.231501, 29.869604],
                            [111.22386, 29.880213],
                            [111.226582, 29.890309],
                            [111.223406, 29.897922],
                            [111.219577, 29.915268],
                            [111.215621, 29.921717],
                            [111.224622, 29.923496],
                            [111.235021, 29.923342],
                            [111.247398, 29.921768],
                            [111.26032, 29.92772],
                            [111.266799, 29.925651],
                            [111.271844, 29.926438],
                            [111.283223, 29.930901],
                            [111.28845, 29.934442],
                            [111.293967, 29.930012],
                            [111.307197, 29.927891],
                            [111.313985, 29.93439],
                            [111.319356, 29.937417],
                            [111.331225, 29.940051],
                            [111.336452, 29.944224],
                            [111.341933, 29.945831],
                            [111.336017, 29.958057],
                            [111.336234, 29.96293],
                            [111.329519, 29.965785],
                            [111.331625, 29.969495],
                            [111.324111, 29.978162],
                            [111.319193, 29.972127],
                            [111.316217, 29.973239],
                            [111.311643, 29.980179],
                            [111.303586, 29.982812],
                            [111.297234, 29.990196],
                            [111.297687, 30.004621],
                            [111.285673, 30.01132],
                            [111.276563, 30.010277],
                            [111.266999, 30.011405],
                            [111.257961, 30.031517],
                            [111.25562, 30.034541],
                            [111.247634, 30.035378],
                            [111.24219, 30.040384],
                            [111.223733, 30.040025],
                            [111.213316, 30.041767],
                            [111.199741, 30.040999],
                            [111.179742, 30.044262],
                            [111.178961, 30.04052],
                            [111.165586, 30.043442],
                            [111.156784, 30.042844],
                            [111.133463, 30.045287],
                            [111.124026, 30.044432],
                            [111.10664, 30.047747],
                            [111.090543, 30.049199],
                            [111.078892, 30.049096],
                            [111.072032, 30.0472],
                            [111.066006, 30.048361],
                            [111.031543, 30.048874],
                            [111.015917, 30.052888],
                            [111.005827, 30.05446],
                            [110.998386, 30.059379],
                            [110.982452, 30.0582],
                            [110.970927, 30.058969],
                            [110.963632, 30.062078],
                            [110.951618, 30.06037],
                            [110.941001, 30.061241],
                            [110.935938, 30.063632],
                            [110.929912, 30.063427],
                            [110.925411, 30.067526],
                            [110.922998, 30.075724],
                            [110.92523, 30.094507],
                            [110.923669, 30.100808],
                            [110.924268, 30.111307],
                            [110.921455, 30.112929],
                            [110.907172, 30.112161],
                            [110.897899, 30.109805],
                            [110.884378, 30.113356],
                            [110.873943, 30.113441],
                            [110.858045, 30.118545],
                            [110.854415, 30.124656],
                            [110.848608, 30.12609],
                            [110.836086, 30.122693],
                            [110.822946, 30.126329],
                            [110.814344, 30.125971],
                            [110.812311, 30.121857],
                            [110.802983, 30.114431],
                            [110.788319, 30.119996],
                            [110.76959, 30.120201],
                            [110.763002, 30.119023],
                            [110.74636, 30.112605],
                            [110.745707, 30.109925],
                            [110.752295, 30.104683],
                            [110.753112, 30.101303],
                            [110.748847, 30.092168],
                            [110.748974, 30.088326],
                            [110.755997, 30.082999],
                            [110.754182, 30.06411],
                            [110.756161, 30.054306],
                            [110.748284, 30.045885],
                            [110.742077, 30.041767],
                            [110.735653, 30.039922],
                            [110.730626, 30.041323],
                            [110.722368, 30.039632],
                            [110.712351, 30.033157],
                            [110.705109, 30.034353],
                            [110.694057, 30.041511],
                            [110.682206, 30.05405],
                            [110.686362, 30.05914],
                            [110.681807, 30.06259],
                            [110.676036, 30.063615],
                            [110.659612, 30.071215],
                            [110.657289, 30.075433],
                            [110.650973, 30.077636],
                            [110.631373, 30.068124],
                            [110.626582, 30.063803],
                            [110.618996, 30.062078],
                            [110.613388, 30.058781],
                            [110.597653, 30.054443],
                            [110.590721, 30.056595],
                            [110.576384, 30.059055],
                            [110.570758, 30.05832],
                            [110.567999, 30.054357],
                            [110.562246, 30.057415],
                            [110.551067, 30.055638],
                            [110.53134, 30.061104],
                            [110.521249, 30.058935],
                            [110.519543, 30.055331],
                            [110.5082, 30.050428],
                            [110.502847, 30.054255],
                            [110.497566, 30.055263],
                            [110.499489, 30.048703],
                            [110.495969, 30.04023],
                            [110.496404, 30.036403],
                            [110.500596, 30.03085],
                            [110.499816, 30.026955],
                            [110.491577, 30.01971],
                            [110.497747, 30.01337],
                            [110.50468, 30.008808],
                            [110.520106, 30.001767],
                            [110.541503, 30.001767],
                            [110.547256, 29.999579],
                            [110.557183, 29.988264],
                            [110.551067, 29.979683],
                            [110.540214, 29.977495],
                            [110.535296, 29.975102],
                            [110.52938, 29.96876],
                            [110.517347, 29.961357],
                            [110.514189, 29.957578],
                            [110.51399, 29.950807],
                            [110.508092, 29.944138],
                            [110.506894, 29.938974],
                            [110.508527, 29.92837],
                            [110.506767, 29.919032],
                            [110.500977, 29.914858],
                            [110.498092, 29.910051],
                            [110.507874, 29.908922],
                            [110.515551, 29.902473],
                            [110.518636, 29.901788],
                            [110.526966, 29.90468],
                            [110.537855, 29.896776],
                            [110.540033, 29.889094],
                            [110.535677, 29.880333],
                            [110.547619, 29.863374],
                            [110.54996, 29.848005],
                            [110.556148, 29.845985],
                            [110.570195, 29.846344],
                            [110.575385, 29.844838],
                            [110.579923, 29.835885],
                            [110.584006, 29.835063],
                            [110.597817, 29.840028],
                            [110.599849, 29.83734],
                            [110.60651, 29.836981],
                            [110.609268, 29.833026],
                            [110.614658, 29.830886],
                            [110.618415, 29.826589],
                            [110.62925, 29.805135],
                            [110.629703, 29.798422],
                            [110.640012, 29.782477],
                            [110.64277, 29.773947],
                            [110.635039, 29.760413],
                            [110.63179, 29.757741],
                            [110.634676, 29.749568],
                            [110.640211, 29.746484],
                            [110.652988, 29.744634],
                            [110.657071, 29.742063],
                            [110.661082, 29.735809],
                            [110.660791, 29.719631],
                            [110.664258, 29.713221],
                            [110.672551, 29.706845],
                            [110.699356, 29.69968],
                            [110.721443, 29.687508],
                            [110.726053, 29.687268],
                            [110.73186, 29.690302],
                            [110.741025, 29.697914],
                            [110.747703, 29.707068],
                            [110.751714, 29.710547],
                            [110.761787, 29.715929],
                            [110.764218, 29.713392],
                            [110.766324, 29.693765],
                            [110.76656, 29.680496],
                            [110.78135, 29.683222],
                            [110.786867, 29.681644],
                            [110.800243, 29.682776],
                            [110.814489, 29.680581],
                            [110.829988, 29.685879],
                            [110.848989, 29.694091],
                            [110.856865, 29.696285],
                            [110.864724, 29.692],
                            [110.866738, 29.688091],
                            [110.874996, 29.684902],
                            [110.890385, 29.686891],
                            [110.904704, 29.68449],
                            [110.921183, 29.679501],
                            [110.927372, 29.676586],
                            [110.927934, 29.671614],
                            [110.942471, 29.66803],
                            [110.95287, 29.673569],
                            [110.959784, 29.67434],
                            [110.965066, 29.677495],
                            [110.986081, 29.675918],
                            [110.998549, 29.667173],
                            [111.020472, 29.669968],
                            [111.032087, 29.669042],
                            [111.038983, 29.658615],
                            [111.039219, 29.653282],
                            [111.030744, 29.645393],
                            [111.03265, 29.6433],
                            [111.039183, 29.643026],
                            [111.049709, 29.636954],
                            [111.051814, 29.631036],
                            [111.05176, 29.623865],
                            [111.056188, 29.617397],
                            [111.077004, 29.619302],
                            [111.09312, 29.615836],
                            [111.110633, 29.613794],
                            [111.124407, 29.607755],
                            [111.133826, 29.608973],
                            [111.14232, 29.607652],
                            [111.150214, 29.602985],
                            [111.151303, 29.596808],
                            [111.158962, 29.594611],
                            [111.168835, 29.599107],
                            [111.179415, 29.60096],
                            [111.190848, 29.598867],
                            [111.200558, 29.599107],
                            [111.208833, 29.601441],
                            [111.213262, 29.59921],
                            [111.224804, 29.602848],
                            [111.232753, 29.602608],
                            [111.243279, 29.600514],
                            [111.25228, 29.600531],
                            [111.257344, 29.596979],
                            [111.254567, 29.587026],
                            [111.261953, 29.585001],
                            [111.267107, 29.579801],
                            [111.273804, 29.580865],
                            [111.278668, 29.573365],
                            [111.286889, 29.555255],
                            [111.294693, 29.556869],
                            [111.303967, 29.549144],
                            [111.304801, 29.546397],
                            [111.301027, 29.541075],
                            [111.301281, 29.537692],
                            [111.3058, 29.535014],
                            [111.315781, 29.535958],
                            [111.319738, 29.532009],
                            [111.320463, 29.52083],
                            [111.329628, 29.508705],
                            [111.332659, 29.497386],
                            [111.337523, 29.493453],
                            [111.329429, 29.486736],
                            [111.322061, 29.475827],
                            [111.317614, 29.46435],
                            [111.318685, 29.459831],
                            [111.325182, 29.452545],
                            [111.326616, 29.445964],
                            [111.329556, 29.444348],
                            [111.327432, 29.437439],
                            [111.31952, 29.432266],
                            [111.318195, 29.428571],
                            [111.31295, 29.4246],
                            [111.311244, 29.419271],
                            [111.294838, 29.412894],
                            [111.291717, 29.407822],
                            [111.297506, 29.403318]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430781,
                "name": "津市市",
                "center": [111.879609, 29.630867],
                "centroid": [111.895477, 29.460085],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430700
                },
                "subFeatureIndex": 8,
                "acroutes": [100000, 430000, 430700]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [111.980499, 29.443764],
                            [111.976397, 29.4527],
                            [111.971098, 29.460347],
                            [111.956307, 29.474573],
                            [111.953748, 29.478851],
                            [111.954655, 29.483438],
                            [111.959664, 29.488626],
                            [111.971134, 29.494827],
                            [111.969682, 29.5012],
                            [111.963004, 29.502196],
                            [111.952695, 29.508671],
                            [111.949991, 29.521397],
                            [111.945291, 29.52605],
                            [111.939356, 29.528729],
                            [111.934692, 29.53352],
                            [111.929066, 29.548543],
                            [111.924711, 29.548646],
                            [111.91845, 29.545092],
                            [111.922769, 29.543203],
                            [111.9205, 29.535906],
                            [111.921426, 29.529742],
                            [111.926217, 29.523921],
                            [111.936398, 29.518082],
                            [111.935727, 29.523251],
                            [111.940463, 29.517258],
                            [111.939302, 29.508757],
                            [111.93344, 29.5046],
                            [111.93373, 29.508808],
                            [111.924475, 29.5215],
                            [111.918558, 29.52332],
                            [111.912352, 29.528042],
                            [111.90825, 29.539787],
                            [111.899757, 29.549813],
                            [111.893586, 29.555084],
                            [111.88473, 29.565709],
                            [111.876001, 29.570413],
                            [111.875075, 29.575939],
                            [111.879649, 29.588691],
                            [111.883714, 29.59063],
                            [111.897361, 29.589497],
                            [111.903913, 29.593101],
                            [111.906272, 29.603877],
                            [111.911517, 29.610603],
                            [111.912188, 29.61563],
                            [111.909593, 29.620383],
                            [111.912914, 29.633832],
                            [111.908432, 29.638017],
                            [111.907198, 29.64354],
                            [111.901263, 29.647965],
                            [111.903278, 29.650143],
                            [111.908559, 29.648994],
                            [111.906218, 29.653025],
                            [111.907198, 29.657501],
                            [111.903713, 29.664858],
                            [111.90669, 29.668321],
                            [111.916217, 29.672471],
                            [111.926217, 29.669539],
                            [111.935436, 29.675009],
                            [111.939792, 29.681181],
                            [111.93393, 29.694434],
                            [111.940336, 29.701977],
                            [111.936852, 29.705405],
                            [111.936035, 29.720659],
                            [111.932369, 29.722836],
                            [111.928667, 29.728697],
                            [111.923803, 29.730874],
                            [111.916417, 29.72856],
                            [111.913332, 29.730291],
                            [111.909267, 29.72587],
                            [111.903586, 29.725578],
                            [111.900664, 29.722682],
                            [111.8913, 29.695788],
                            [111.886182, 29.686856],
                            [111.87856, 29.685193],
                            [111.87326, 29.682176],
                            [111.878469, 29.678027],
                            [111.878796, 29.673397],
                            [111.874513, 29.664257],
                            [111.873641, 29.654431],
                            [111.87072, 29.649303],
                            [111.871772, 29.641773],
                            [111.868905, 29.636628],
                            [111.868179, 29.625306],
                            [111.866092, 29.622939],
                            [111.848669, 29.622424],
                            [111.842735, 29.62124],
                            [111.837036, 29.617552],
                            [111.824823, 29.6006],
                            [111.8174, 29.597374],
                            [111.816837, 29.589068],
                            [111.813643, 29.577673],
                            [111.809868, 29.569777],
                            [111.787836, 29.540697],
                            [111.771594, 29.512999],
                            [111.771267, 29.507108],
                            [111.776476, 29.505579],
                            [111.787129, 29.492422],
                            [111.792972, 29.48866],
                            [111.797999, 29.492525],
                            [111.803317, 29.492388],
                            [111.816547, 29.48349],
                            [111.818561, 29.485963],
                            [111.814278, 29.490206],
                            [111.821411, 29.494758],
                            [111.828071, 29.485637],
                            [111.830939, 29.489382],
                            [111.83533, 29.486307],
                            [111.834713, 29.482476],
                            [111.841519, 29.482768],
                            [111.843824, 29.479298],
                            [111.839867, 29.462117],
                            [111.83386, 29.46679],
                            [111.831211, 29.473869],
                            [111.830176, 29.467512],
                            [111.827381, 29.465811],
                            [111.828652, 29.460553],
                            [111.835004, 29.460811],
                            [111.833534, 29.456841],
                            [111.825313, 29.453594],
                            [111.837, 29.45258],
                            [111.832263, 29.44727],
                            [111.827327, 29.448198],
                            [111.824968, 29.442647],
                            [111.827617, 29.441788],
                            [111.826456, 29.431561],
                            [111.819614, 29.427814],
                            [111.82357, 29.426216],
                            [111.822481, 29.413427],
                            [111.816184, 29.406447],
                            [111.814206, 29.400533],
                            [111.815549, 29.389305],
                            [111.809759, 29.377921],
                            [111.803571, 29.373157],
                            [111.800903, 29.359037],
                            [111.802827, 29.353808],
                            [111.802101, 29.344278],
                            [111.796638, 29.344313],
                            [111.798126, 29.339565],
                            [111.793644, 29.33086],
                            [111.794134, 29.325079],
                            [111.79152, 29.318901],
                            [111.78633, 29.316131],
                            [111.784733, 29.307269],
                            [111.789706, 29.303689],
                            [111.787582, 29.310607],
                            [111.790776, 29.315546],
                            [111.801992, 29.317439],
                            [111.808598, 29.314221],
                            [111.81426, 29.315064],
                            [111.822191, 29.308422],
                            [111.829596, 29.312397],
                            [111.836982, 29.322532],
                            [111.840085, 29.324115],
                            [111.848071, 29.322842],
                            [111.851537, 29.326421],
                            [111.85945, 29.329879],
                            [111.866291, 29.32821],
                            [111.868034, 29.322446],
                            [111.876037, 29.324786],
                            [111.886781, 29.322584],
                            [111.890175, 29.317903],
                            [111.902497, 29.31577],
                            [111.907724, 29.316114],
                            [111.913804, 29.31164],
                            [111.92237, 29.312862],
                            [111.936689, 29.31312],
                            [111.939756, 29.305755],
                            [111.94765, 29.296565],
                            [111.950209, 29.279852],
                            [111.950028, 29.272846],
                            [111.95255, 29.270022],
                            [111.958104, 29.284396],
                            [111.967196, 29.295463],
                            [111.96814, 29.300179],
                            [111.963947, 29.312139],
                            [111.966198, 29.316406],
                            [111.972931, 29.320261],
                            [111.982803, 29.322859],
                            [111.998847, 29.331582],
                            [112.007794, 29.329965],
                            [112.011024, 29.331066],
                            [112.01763, 29.339943],
                            [112.020007, 29.341079],
                            [112.025561, 29.354531],
                            [112.037194, 29.371816],
                            [112.037738, 29.382031],
                            [112.045397, 29.390852],
                            [112.041931, 29.394085],
                            [112.03164, 29.399381],
                            [112.012512, 29.403438],
                            [112.004164, 29.403713],
                            [112.000008, 29.41365],
                            [111.987849, 29.423276],
                            [111.983765, 29.430685],
                            [111.980499, 29.443764]
                        ]
                    ]
                ]
            }
        }]
    });
}));