/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

(function(root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['exports', 'echarts'], factory);
    } else if (typeof exports === 'object' && typeof exports.nodeName !== 'string') {
        // CommonJS
        factory(exports, require('echarts'));
    } else {
        // Browser globals
        factory({}, root.echarts);
    }
}(this, function(exports, echarts) {
    var log = function(msg) {
        if (typeof console !== 'undefined') {
            console && console.error && console.error(msg);
        }
    }
    if (!echarts) {
        log('ECharts is not Loaded');
        return;
    }
    if (!echarts.registerMap) {
        log('ECharts Map is not loaded')
        return;
    }
    echarts.registerMap('郴州市', {
        "type": "FeatureCollection",
        "features": [{
            "type": "Feature",
            "properties": {
                "adcode": 431002,
                "name": "北湖区",
                "center": [113.032208, 25.792628],
                "centroid": [112.889908, 25.656767],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431000
                },
                "subFeatureIndex": 0,
                "acroutes": [100000, 430000, 431000]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.911996, 25.876649],
                            [112.906735, 25.875578],
                            [112.905048, 25.87182],
                            [112.894367, 25.876649],
                            [112.88748, 25.873455],
                            [112.873204, 25.864129],
                            [112.860836, 25.846038],
                            [112.854391, 25.837956],
                            [112.847283, 25.826328],
                            [112.847283, 25.823328],
                            [112.841541, 25.815712],
                            [112.841882, 25.801919],
                            [112.839191, 25.796581],
                            [112.833188, 25.791691],
                            [112.836983, 25.784774],
                            [112.845255, 25.780097],
                            [112.847805, 25.77546],
                            [112.845556, 25.764858],
                            [112.839934, 25.761994],
                            [112.828911, 25.762539],
                            [112.822928, 25.759148],
                            [112.816302, 25.758213],
                            [112.818711, 25.751528],
                            [112.81578, 25.742464],
                            [112.808572, 25.733381],
                            [112.800581, 25.727377],
                            [112.796786, 25.71371],
                            [112.801585, 25.703377],
                            [112.804938, 25.702012],
                            [112.816543, 25.711351],
                            [112.820458, 25.712755],
                            [112.822506, 25.705833],
                            [112.828249, 25.701427],
                            [112.833931, 25.699652],
                            [112.838107, 25.694388],
                            [112.84913, 25.691307],
                            [112.852463, 25.686548],
                            [112.859169, 25.683565],
                            [112.860816, 25.679586],
                            [112.853066, 25.681712],
                            [112.846781, 25.676836],
                            [112.839011, 25.677285],
                            [112.83375, 25.670849],
                            [112.839794, 25.669484],
                            [112.842022, 25.665758],
                            [112.838589, 25.662989],
                            [112.842545, 25.661526],
                            [112.84905, 25.669893],
                            [112.853688, 25.669211],
                            [112.855174, 25.665017],
                            [112.852825, 25.658015],
                            [112.836621, 25.646604],
                            [112.830678, 25.649179],
                            [112.830738, 25.653743],
                            [112.824695, 25.655031],
                            [112.822767, 25.645062],
                            [112.818832, 25.64397],
                            [112.819454, 25.661253],
                            [112.811443, 25.660434],
                            [112.810198, 25.648398],
                            [112.811202, 25.639717],
                            [112.806323, 25.632206],
                            [112.802749, 25.631738],
                            [112.791084, 25.642214],
                            [112.783454, 25.654133],
                            [112.780763, 25.666422],
                            [112.77466, 25.668079],
                            [112.761167, 25.658112],
                            [112.758396, 25.650154],
                            [112.758798, 25.628675],
                            [112.761448, 25.62249],
                            [112.771949, 25.617378],
                            [112.776647, 25.620246],
                            [112.786104, 25.6197],
                            [112.793553, 25.621963],
                            [112.803111, 25.621065],
                            [112.812768, 25.612656],
                            [112.814977, 25.602606],
                            [112.822024, 25.598703],
                            [112.829012, 25.590194],
                            [112.828048, 25.585959],
                            [112.823089, 25.583655],
                            [112.819715, 25.576726],
                            [112.816784, 25.562028],
                            [112.808431, 25.561403],
                            [112.811704, 25.570246],
                            [112.808632, 25.575184],
                            [112.80556, 25.574111],
                            [112.805379, 25.56884],
                            [112.798894, 25.559529],
                            [112.791043, 25.556933],
                            [112.793433, 25.553946],
                            [112.801886, 25.553087],
                            [112.807407, 25.547366],
                            [112.809114, 25.539947],
                            [112.799055, 25.534812],
                            [112.794316, 25.537838],
                            [112.799818, 25.544047],
                            [112.799557, 25.547757],
                            [112.787931, 25.546624],
                            [112.7851, 25.540728],
                            [112.781125, 25.540318],
                            [112.779097, 25.548303],
                            [112.769961, 25.544242],
                            [112.763155, 25.544965],
                            [112.761548, 25.550236],
                            [112.762673, 25.560974],
                            [112.765082, 25.564312],
                            [112.768074, 25.575614],
                            [112.767612, 25.584885],
                            [112.763677, 25.592555],
                            [112.759259, 25.594722],
                            [112.734824, 25.590565],
                            [112.732134, 25.58629],
                            [112.71854, 25.574247],
                            [112.706373, 25.56193],
                            [112.697599, 25.547815],
                            [112.697478, 25.542309],
                            [112.703361, 25.537702],
                            [112.7183, 25.534617],
                            [112.725126, 25.5287],
                            [112.72601, 25.520303],
                            [112.721351, 25.502609],
                            [112.724243, 25.494094],
                            [112.7491, 25.493371],
                            [112.765604, 25.491027],
                            [112.781868, 25.493195],
                            [112.785903, 25.489015],
                            [112.781948, 25.480361],
                            [112.79008, 25.473661],
                            [112.790642, 25.46989],
                            [112.785221, 25.453049],
                            [112.785843, 25.440465],
                            [112.782209, 25.436713],
                            [112.784899, 25.432433],
                            [112.791566, 25.433156],
                            [112.806966, 25.43216],
                            [112.817206, 25.433332],
                            [112.835698, 25.439488],
                            [112.850074, 25.43855],
                            [112.862623, 25.443025],
                            [112.869951, 25.449219],
                            [112.885914, 25.458305],
                            [112.886315, 25.46358],
                            [112.890612, 25.472958],
                            [112.898423, 25.478662],
                            [112.906293, 25.481416],
                            [112.915329, 25.488136],
                            [112.921553, 25.488214],
                            [112.929203, 25.49214],
                            [112.93109, 25.485479],
                            [112.939985, 25.487999],
                            [112.941149, 25.493058],
                            [112.938419, 25.49759],
                            [112.94131, 25.506008],
                            [112.93105, 25.503137],
                            [112.928761, 25.507297],
                            [112.921171, 25.513976],
                            [112.925368, 25.523096],
                            [112.933399, 25.527275],
                            [112.92838, 25.531785],
                            [112.924525, 25.538307],
                            [112.931753, 25.540826],
                            [112.93115, 25.54639],
                            [112.934383, 25.561872],
                            [112.937897, 25.567825],
                            [112.935788, 25.570226],
                            [112.934082, 25.581157],
                            [112.938539, 25.584182],
                            [112.942294, 25.590526],
                            [112.937495, 25.592965],
                            [112.935367, 25.602918],
                            [112.937094, 25.609338],
                            [112.949964, 25.61289],
                            [112.95424, 25.617143],
                            [112.967914, 25.618139],
                            [112.976266, 25.625631],
                            [112.979519, 25.626665],
                            [112.985282, 25.63722],
                            [112.994036, 25.642273],
                            [112.994518, 25.651266],
                            [113.011624, 25.653509],
                            [113.018973, 25.650661],
                            [113.021804, 25.653002],
                            [113.021101, 25.666266],
                            [113.01568, 25.667787],
                            [113.011745, 25.67356],
                            [113.006123, 25.675062],
                            [112.998052, 25.684676],
                            [112.993715, 25.699028],
                            [113.010058, 25.721333],
                            [113.011404, 25.725544],
                            [113.018792, 25.731022],
                            [113.034755, 25.735778],
                            [113.039614, 25.741314],
                            [113.047043, 25.73802],
                            [113.053046, 25.73763],
                            [113.067342, 25.731646],
                            [113.072422, 25.73416],
                            [113.075594, 25.73188],
                            [113.082782, 25.734199],
                            [113.083947, 25.738566],
                            [113.077241, 25.742698],
                            [113.071036, 25.757453],
                            [113.076518, 25.764975],
                            [113.068647, 25.76554],
                            [113.061078, 25.768931],
                            [113.056419, 25.766963],
                            [113.056881, 25.774232],
                            [113.051882, 25.778655],
                            [113.047705, 25.777018],
                            [113.043489, 25.768873],
                            [113.039292, 25.766768],
                            [113.033068, 25.770978],
                            [113.036341, 25.782825],
                            [113.035237, 25.788729],
                            [113.037987, 25.798802],
                            [113.034273, 25.804764],
                            [113.032365, 25.814777],
                            [113.026663, 25.820504],
                            [113.025338, 25.826776],
                            [113.021463, 25.832989],
                            [113.023872, 25.840234],
                            [113.029956, 25.844383],
                            [113.030036, 25.852192],
                            [113.034454, 25.870476],
                            [113.0309, 25.876279],
                            [113.025619, 25.87219],
                            [113.018371, 25.873631],
                            [113.014195, 25.869113],
                            [113.014094, 25.863194],
                            [113.008814, 25.861928],
                            [113.003493, 25.855093],
                            [112.99759, 25.855833],
                            [112.990281, 25.861325],
                            [112.989257, 25.864538],
                            [112.982872, 25.866251],
                            [112.980142, 25.857197],
                            [112.983695, 25.854412],
                            [112.983113, 25.848141],
                            [112.985141, 25.84152],
                            [112.979579, 25.834138],
                            [112.974058, 25.831626],
                            [112.967974, 25.832619],
                            [112.967914, 25.829211],
                            [112.962934, 25.826659],
                            [112.955003, 25.825471],
                            [112.948076, 25.822705],
                            [112.948056, 25.815497],
                            [112.940587, 25.80716],
                            [112.934544, 25.806556],
                            [112.926613, 25.810004],
                            [112.922898, 25.814504],
                            [112.920007, 25.813452],
                            [112.916373, 25.806868],
                            [112.911393, 25.808523],
                            [112.913441, 25.823718],
                            [112.907016, 25.837566],
                            [112.912116, 25.846466],
                            [112.909787, 25.850108],
                            [112.915248, 25.851822],
                            [112.920509, 25.861987],
                            [112.920649, 25.867069],
                            [112.914325, 25.869406],
                            [112.911996, 25.876649]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 431003,
                "name": "苏仙区",
                "center": [113.038698, 25.793157],
                "centroid": [113.088391, 25.79316],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431000
                },
                "subFeatureIndex": 1,
                "acroutes": [100000, 430000, 431000]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.94131, 25.506008],
                            [112.951972, 25.510461],
                            [112.95916, 25.511847],
                            [112.971729, 25.516632],
                            [112.981467, 25.523838],
                            [112.986205, 25.525615],
                            [113.002629, 25.528407],
                            [113.012769, 25.538717],
                            [113.018291, 25.540689],
                            [113.032406, 25.541665],
                            [113.035076, 25.543325],
                            [113.052243, 25.547327],
                            [113.07202, 25.542856],
                            [113.082461, 25.542661],
                            [113.091316, 25.545316],
                            [113.093604, 25.548381],
                            [113.096476, 25.562008],
                            [113.099307, 25.565502],
                            [113.111655, 25.575009],
                            [113.119044, 25.589511],
                            [113.123822, 25.59517],
                            [113.134464, 25.594468],
                            [113.139986, 25.590428],
                            [113.154663, 25.586446],
                            [113.172814, 25.576551],
                            [113.182471, 25.57538],
                            [113.188455, 25.577859],
                            [113.191386, 25.594546],
                            [113.194799, 25.608577],
                            [113.205622, 25.632226],
                            [113.209557, 25.636791],
                            [113.213452, 25.653548],
                            [113.216223, 25.659341],
                            [113.229154, 25.677792],
                            [113.236201, 25.689201],
                            [113.253087, 25.695577],
                            [113.257444, 25.708056],
                            [113.254573, 25.711195],
                            [113.245698, 25.714607],
                            [113.24359, 25.720475],
                            [113.246903, 25.723907],
                            [113.251822, 25.717804],
                            [113.260857, 25.720475],
                            [113.263608, 25.725427],
                            [113.262263, 25.740496],
                            [113.25634, 25.742581],
                            [113.252103, 25.747513],
                            [113.25397, 25.756848],
                            [113.271519, 25.775771],
                            [113.273226, 25.780584],
                            [113.271941, 25.787326],
                            [113.277462, 25.796639],
                            [113.275936, 25.802211],
                            [113.270776, 25.809829],
                            [113.27176, 25.821069],
                            [113.267062, 25.82773],
                            [113.268708, 25.839864],
                            [113.260255, 25.846466],
                            [113.258669, 25.849758],
                            [113.259853, 25.859709],
                            [113.25138, 25.879238],
                            [113.244995, 25.885137],
                            [113.247987, 25.8916],
                            [113.24112, 25.89489],
                            [113.228391, 25.914219],
                            [113.205501, 25.944131],
                            [113.200964, 25.948762],
                            [113.191306, 25.945532],
                            [113.188716, 25.946778],
                            [113.190603, 25.954911],
                            [113.199859, 25.961292],
                            [113.196004, 25.966915],
                            [113.187069, 25.966507],
                            [113.174621, 25.967771],
                            [113.166268, 25.963997],
                            [113.160506, 25.965651],
                            [113.155426, 25.963841],
                            [113.159963, 25.972051],
                            [113.149503, 25.972946],
                            [113.146411, 25.968666],
                            [113.142315, 25.974113],
                            [113.13862, 25.973549],
                            [113.13595, 25.976837],
                            [113.131352, 25.976214],
                            [113.131814, 25.980825],
                            [113.128842, 25.980688],
                            [113.131693, 25.988041],
                            [113.123361, 25.983801],
                            [113.118582, 25.987497],
                            [113.109246, 25.984754],
                            [113.109045, 25.987497],
                            [113.114084, 25.992223],
                            [113.118462, 25.991912],
                            [113.115871, 25.998603],
                            [113.119485, 26.000742],
                            [113.126834, 25.998447],
                            [113.130127, 26.001073],
                            [113.128521, 26.00436],
                            [113.132637, 26.008483],
                            [113.117257, 26.009883],
                            [113.115711, 26.01739],
                            [113.116353, 26.023457],
                            [113.112558, 26.030399],
                            [113.113382, 26.032965],
                            [113.107579, 26.034657],
                            [113.107057, 26.037379],
                            [113.100712, 26.033918],
                            [113.088846, 26.048636],
                            [113.083927, 26.045623],
                            [113.081317, 26.050522],
                            [113.074008, 26.053185],
                            [113.067101, 26.053166],
                            [113.056279, 26.049006],
                            [113.053387, 26.038837],
                            [113.054291, 26.030729],
                            [113.049854, 26.025499],
                            [113.042144, 26.025013],
                            [113.031482, 26.018226],
                            [113.026061, 26.017876],
                            [113.022065, 26.024604],
                            [113.011886, 26.031429],
                            [112.997831, 26.031021],
                            [112.990341, 26.023185],
                            [112.974259, 26.02966],
                            [112.970604, 26.028474],
                            [112.958377, 26.020054],
                            [112.951289, 26.017954],
                            [112.936953, 26.017681],
                            [112.926332, 26.012839],
                            [112.917597, 26.001559],
                            [112.912899, 25.99162],
                            [112.91324, 25.986485],
                            [112.916895, 25.976331],
                            [112.924344, 25.977984],
                            [112.928861, 25.975455],
                            [112.930006, 25.971117],
                            [112.935266, 25.965651],
                            [112.930468, 25.962674],
                            [112.925589, 25.962791],
                            [112.929263, 25.958004],
                            [112.93621, 25.958355],
                            [112.93619, 25.953899],
                            [112.929524, 25.950105],
                            [112.927315, 25.945785],
                            [112.932094, 25.943158],
                            [112.931913, 25.939811],
                            [112.925468, 25.933915],
                            [112.923581, 25.935102],
                            [112.918421, 25.926617],
                            [112.91561, 25.925586],
                            [112.914525, 25.908147],
                            [112.911634, 25.908867],
                            [112.90794, 25.903962],
                            [112.915108, 25.895786],
                            [112.909747, 25.882528],
                            [112.911996, 25.876649],
                            [112.914325, 25.869406],
                            [112.920649, 25.867069],
                            [112.920509, 25.861987],
                            [112.915248, 25.851822],
                            [112.909787, 25.850108],
                            [112.912116, 25.846466],
                            [112.907016, 25.837566],
                            [112.913441, 25.823718],
                            [112.911393, 25.808523],
                            [112.916373, 25.806868],
                            [112.920007, 25.813452],
                            [112.922898, 25.814504],
                            [112.926613, 25.810004],
                            [112.934544, 25.806556],
                            [112.940587, 25.80716],
                            [112.948056, 25.815497],
                            [112.948076, 25.822705],
                            [112.955003, 25.825471],
                            [112.962934, 25.826659],
                            [112.967914, 25.829211],
                            [112.967974, 25.832619],
                            [112.974058, 25.831626],
                            [112.979579, 25.834138],
                            [112.985141, 25.84152],
                            [112.983113, 25.848141],
                            [112.983695, 25.854412],
                            [112.980142, 25.857197],
                            [112.982872, 25.866251],
                            [112.989257, 25.864538],
                            [112.990281, 25.861325],
                            [112.99759, 25.855833],
                            [113.003493, 25.855093],
                            [113.008814, 25.861928],
                            [113.014094, 25.863194],
                            [113.014195, 25.869113],
                            [113.018371, 25.873631],
                            [113.025619, 25.87219],
                            [113.0309, 25.876279],
                            [113.034454, 25.870476],
                            [113.030036, 25.852192],
                            [113.029956, 25.844383],
                            [113.023872, 25.840234],
                            [113.021463, 25.832989],
                            [113.025338, 25.826776],
                            [113.026663, 25.820504],
                            [113.032365, 25.814777],
                            [113.034273, 25.804764],
                            [113.037987, 25.798802],
                            [113.035237, 25.788729],
                            [113.036341, 25.782825],
                            [113.033068, 25.770978],
                            [113.039292, 25.766768],
                            [113.043489, 25.768873],
                            [113.047705, 25.777018],
                            [113.051882, 25.778655],
                            [113.056881, 25.774232],
                            [113.056419, 25.766963],
                            [113.061078, 25.768931],
                            [113.068647, 25.76554],
                            [113.076518, 25.764975],
                            [113.071036, 25.757453],
                            [113.077241, 25.742698],
                            [113.083947, 25.738566],
                            [113.082782, 25.734199],
                            [113.075594, 25.73188],
                            [113.072422, 25.73416],
                            [113.067342, 25.731646],
                            [113.053046, 25.73763],
                            [113.047043, 25.73802],
                            [113.039614, 25.741314],
                            [113.034755, 25.735778],
                            [113.018792, 25.731022],
                            [113.011404, 25.725544],
                            [113.010058, 25.721333],
                            [112.993715, 25.699028],
                            [112.998052, 25.684676],
                            [113.006123, 25.675062],
                            [113.011745, 25.67356],
                            [113.01568, 25.667787],
                            [113.021101, 25.666266],
                            [113.021804, 25.653002],
                            [113.018973, 25.650661],
                            [113.011624, 25.653509],
                            [112.994518, 25.651266],
                            [112.994036, 25.642273],
                            [112.985282, 25.63722],
                            [112.979519, 25.626665],
                            [112.976266, 25.625631],
                            [112.967914, 25.618139],
                            [112.95424, 25.617143],
                            [112.949964, 25.61289],
                            [112.937094, 25.609338],
                            [112.935367, 25.602918],
                            [112.937495, 25.592965],
                            [112.942294, 25.590526],
                            [112.938539, 25.584182],
                            [112.934082, 25.581157],
                            [112.935788, 25.570226],
                            [112.937897, 25.567825],
                            [112.934383, 25.561872],
                            [112.93115, 25.54639],
                            [112.931753, 25.540826],
                            [112.924525, 25.538307],
                            [112.92838, 25.531785],
                            [112.933399, 25.527275],
                            [112.925368, 25.523096],
                            [112.921171, 25.513976],
                            [112.928761, 25.507297],
                            [112.93105, 25.503137],
                            [112.94131, 25.506008]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 431021,
                "name": "桂阳县",
                "center": [112.734466, 25.737447],
                "centroid": [112.601309, 25.886825],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431000
                },
                "subFeatureIndex": 2,
                "acroutes": [100000, 430000, 431000]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.386846, 25.789353],
                            [112.39301, 25.779435],
                            [112.413771, 25.765599],
                            [112.426119, 25.760727],
                            [112.429994, 25.757336],
                            [112.43909, 25.754958],
                            [112.445956, 25.750085],
                            [112.45706, 25.736714],
                            [112.458887, 25.738137],
                            [112.456859, 25.746635],
                            [112.457461, 25.75451],
                            [112.460092, 25.759967],
                            [112.471998, 25.768444],
                            [112.476636, 25.758719],
                            [112.478644, 25.749403],
                            [112.488744, 25.747357],
                            [112.490751, 25.743517],
                            [112.481877, 25.738079],
                            [112.477841, 25.728995],
                            [112.478243, 25.724335],
                            [112.485912, 25.72028],
                            [112.491755, 25.715406],
                            [112.495992, 25.717415],
                            [112.49822, 25.727922],
                            [112.507095, 25.731119],
                            [112.516371, 25.730691],
                            [112.520407, 25.723614],
                            [112.527455, 25.718935],
                            [112.534121, 25.718721],
                            [112.535325, 25.715484],
                            [112.527455, 25.710844],
                            [112.524222, 25.706204],
                            [112.524443, 25.69548],
                            [112.532695, 25.697293],
                            [112.540967, 25.712229],
                            [112.539963, 25.71991],
                            [112.545425, 25.721119],
                            [112.554299, 25.713808],
                            [112.552472, 25.70634],
                            [112.556106, 25.70248],
                            [112.562371, 25.701251],
                            [112.570663, 25.694563],
                            [112.572671, 25.690936],
                            [112.583795, 25.686665],
                            [112.590661, 25.678572],
                            [112.59532, 25.678377],
                            [112.596323, 25.674126],
                            [112.591464, 25.662404],
                            [112.575, 25.649257],
                            [112.567551, 25.648106],
                            [112.565925, 25.653724],
                            [112.558134, 25.651461],
                            [112.552713, 25.64397],
                            [112.536811, 25.637337],
                            [112.529422, 25.640088],
                            [112.521451, 25.637727],
                            [112.520206, 25.631114],
                            [112.52615, 25.624304],
                            [112.531691, 25.621163],
                            [112.536189, 25.614295],
                            [112.54177, 25.609143],
                            [112.5442, 25.595229],
                            [112.547433, 25.588184],
                            [112.557652, 25.574482],
                            [112.576968, 25.584748],
                            [112.583855, 25.581801],
                            [112.589657, 25.570851],
                            [112.594998, 25.564312],
                            [112.595239, 25.560856],
                            [112.590501, 25.558611],
                            [112.592529, 25.552052],
                            [112.60046, 25.545824],
                            [112.606182, 25.537409],
                            [112.607146, 25.526786],
                            [112.613189, 25.522725],
                            [112.617606, 25.527294],
                            [112.61588, 25.534519],
                            [112.62353, 25.53819],
                            [112.64662, 25.53036],
                            [112.647845, 25.522939],
                            [112.641179, 25.505851],
                            [112.642162, 25.500715],
                            [112.661819, 25.491183],
                            [112.664409, 25.48378],
                            [112.65941, 25.474638],
                            [112.659108, 25.47075],
                            [112.66218, 25.457465],
                            [112.665012, 25.451544],
                            [112.670854, 25.451193],
                            [112.674127, 25.454592],
                            [112.683423, 25.450645],
                            [112.689206, 25.45174],
                            [112.690632, 25.46231],
                            [112.698944, 25.463893],
                            [112.708742, 25.459711],
                            [112.709224, 25.454885],
                            [112.720147, 25.453225],
                            [112.723942, 25.462877],
                            [112.730828, 25.466022],
                            [112.730487, 25.468484],
                            [112.723058, 25.469324],
                            [112.735707, 25.476689],
                            [112.7491, 25.493371],
                            [112.724243, 25.494094],
                            [112.721351, 25.502609],
                            [112.72601, 25.520303],
                            [112.725126, 25.5287],
                            [112.7183, 25.534617],
                            [112.703361, 25.537702],
                            [112.697478, 25.542309],
                            [112.697599, 25.547815],
                            [112.706373, 25.56193],
                            [112.71854, 25.574247],
                            [112.732134, 25.58629],
                            [112.734824, 25.590565],
                            [112.759259, 25.594722],
                            [112.763677, 25.592555],
                            [112.767612, 25.584885],
                            [112.768074, 25.575614],
                            [112.765082, 25.564312],
                            [112.762673, 25.560974],
                            [112.761548, 25.550236],
                            [112.763155, 25.544965],
                            [112.769961, 25.544242],
                            [112.779097, 25.548303],
                            [112.781125, 25.540318],
                            [112.7851, 25.540728],
                            [112.787931, 25.546624],
                            [112.799557, 25.547757],
                            [112.799818, 25.544047],
                            [112.794316, 25.537838],
                            [112.799055, 25.534812],
                            [112.809114, 25.539947],
                            [112.807407, 25.547366],
                            [112.801886, 25.553087],
                            [112.793433, 25.553946],
                            [112.791043, 25.556933],
                            [112.798894, 25.559529],
                            [112.805379, 25.56884],
                            [112.80556, 25.574111],
                            [112.808632, 25.575184],
                            [112.811704, 25.570246],
                            [112.808431, 25.561403],
                            [112.816784, 25.562028],
                            [112.819715, 25.576726],
                            [112.823089, 25.583655],
                            [112.828048, 25.585959],
                            [112.829012, 25.590194],
                            [112.822024, 25.598703],
                            [112.814977, 25.602606],
                            [112.812768, 25.612656],
                            [112.803111, 25.621065],
                            [112.793553, 25.621963],
                            [112.786104, 25.6197],
                            [112.776647, 25.620246],
                            [112.771949, 25.617378],
                            [112.761448, 25.62249],
                            [112.758798, 25.628675],
                            [112.758396, 25.650154],
                            [112.761167, 25.658112],
                            [112.77466, 25.668079],
                            [112.780763, 25.666422],
                            [112.783454, 25.654133],
                            [112.791084, 25.642214],
                            [112.802749, 25.631738],
                            [112.806323, 25.632206],
                            [112.811202, 25.639717],
                            [112.810198, 25.648398],
                            [112.811443, 25.660434],
                            [112.819454, 25.661253],
                            [112.818832, 25.64397],
                            [112.822767, 25.645062],
                            [112.824695, 25.655031],
                            [112.830738, 25.653743],
                            [112.830678, 25.649179],
                            [112.836621, 25.646604],
                            [112.852825, 25.658015],
                            [112.855174, 25.665017],
                            [112.853688, 25.669211],
                            [112.84905, 25.669893],
                            [112.842545, 25.661526],
                            [112.838589, 25.662989],
                            [112.842022, 25.665758],
                            [112.839794, 25.669484],
                            [112.83375, 25.670849],
                            [112.839011, 25.677285],
                            [112.846781, 25.676836],
                            [112.853066, 25.681712],
                            [112.860816, 25.679586],
                            [112.859169, 25.683565],
                            [112.852463, 25.686548],
                            [112.84913, 25.691307],
                            [112.838107, 25.694388],
                            [112.833931, 25.699652],
                            [112.828249, 25.701427],
                            [112.822506, 25.705833],
                            [112.820458, 25.712755],
                            [112.816543, 25.711351],
                            [112.804938, 25.702012],
                            [112.801585, 25.703377],
                            [112.796786, 25.71371],
                            [112.800581, 25.727377],
                            [112.808572, 25.733381],
                            [112.81578, 25.742464],
                            [112.818711, 25.751528],
                            [112.816302, 25.758213],
                            [112.822928, 25.759148],
                            [112.828911, 25.762539],
                            [112.839934, 25.761994],
                            [112.845556, 25.764858],
                            [112.847805, 25.77546],
                            [112.845255, 25.780097],
                            [112.836983, 25.784774],
                            [112.833188, 25.791691],
                            [112.839191, 25.796581],
                            [112.841882, 25.801919],
                            [112.841541, 25.815712],
                            [112.847283, 25.823328],
                            [112.847283, 25.826328],
                            [112.854391, 25.837956],
                            [112.860836, 25.846038],
                            [112.873204, 25.864129],
                            [112.88748, 25.873455],
                            [112.894367, 25.876649],
                            [112.905048, 25.87182],
                            [112.906735, 25.875578],
                            [112.911996, 25.876649],
                            [112.909747, 25.882528],
                            [112.915108, 25.895786],
                            [112.90794, 25.903962],
                            [112.911634, 25.908867],
                            [112.914525, 25.908147],
                            [112.91561, 25.925586],
                            [112.918421, 25.926617],
                            [112.923581, 25.935102],
                            [112.925468, 25.933915],
                            [112.931913, 25.939811],
                            [112.932094, 25.943158],
                            [112.927315, 25.945785],
                            [112.929524, 25.950105],
                            [112.93619, 25.953899],
                            [112.93621, 25.958355],
                            [112.929263, 25.958004],
                            [112.925589, 25.962791],
                            [112.930468, 25.962674],
                            [112.935266, 25.965651],
                            [112.930006, 25.971117],
                            [112.928861, 25.975455],
                            [112.924344, 25.977984],
                            [112.916895, 25.976331],
                            [112.898382, 25.97958],
                            [112.891516, 25.978354],
                            [112.873224, 25.981097],
                            [112.868586, 25.983859],
                            [112.867241, 25.9888],
                            [112.86953, 25.993779],
                            [112.869289, 26.000237],
                            [112.847122, 26.002337],
                            [112.84138, 26.003621],
                            [112.832405, 26.002998],
                            [112.82857, 26.009455],
                            [112.832746, 26.023671],
                            [112.842243, 26.037535],
                            [112.84389, 26.045467],
                            [112.841922, 26.052952],
                            [112.838669, 26.05684],
                            [112.834192, 26.055071],
                            [112.825277, 26.045526],
                            [112.801223, 26.034657],
                            [112.796043, 26.02719],
                            [112.792047, 26.024915],
                            [112.784036, 26.027035],
                            [112.781225, 26.033413],
                            [112.775182, 26.03771],
                            [112.768315, 26.038137],
                            [112.762833, 26.043076],
                            [112.749883, 26.063877],
                            [112.74663, 26.071439],
                            [112.737013, 26.089437],
                            [112.732917, 26.102166],
                            [112.725206, 26.116448],
                            [112.721894, 26.125968],
                            [112.718199, 26.131855],
                            [112.71334, 26.134419],
                            [112.710308, 26.131738],
                            [112.707718, 26.122451],
                            [112.699125, 26.117283],
                            [112.688543, 26.114213],
                            [112.686114, 26.115651],
                            [112.686415, 26.123831],
                            [112.681877, 26.127192],
                            [112.676135, 26.125987],
                            [112.676436, 26.11773],
                            [112.673806, 26.111027],
                            [112.665333, 26.109841],
                            [112.66214, 26.104031],
                            [112.654912, 26.101058],
                            [112.642283, 26.105994],
                            [112.646178, 26.112426],
                            [112.658205, 26.11499],
                            [112.670654, 26.127678],
                            [112.664791, 26.131214],
                            [112.663124, 26.128707],
                            [112.654229, 26.129271],
                            [112.642102, 26.13405],
                            [112.637303, 26.138907],
                            [112.630115, 26.154408],
                            [112.633669, 26.163265],
                            [112.631601, 26.173015],
                            [112.628188, 26.17686],
                            [112.625317, 26.189637],
                            [112.623409, 26.192123],
                            [112.613731, 26.194492],
                            [112.608973, 26.198453],
                            [112.602829, 26.217091],
                            [112.597809, 26.220527],
                            [112.588071, 26.221071],
                            [112.581064, 26.219091],
                            [112.580562, 26.208996],
                            [112.572591, 26.195696],
                            [112.574077, 26.186647],
                            [112.57743, 26.181987],
                            [112.576225, 26.168353],
                            [112.582028, 26.159244],
                            [112.578393, 26.15365],
                            [112.573494, 26.155748],
                            [112.57245, 26.160021],
                            [112.566547, 26.158487],
                            [112.554821, 26.147279],
                            [112.548557, 26.149144],
                            [112.538277, 26.146735],
                            [112.529101, 26.14759],
                            [112.524443, 26.137489],
                            [112.512898, 26.135565],
                            [112.50824, 26.14386],
                            [112.509886, 26.150135],
                            [112.507798, 26.159594],
                            [112.498401, 26.157516],
                            [112.494205, 26.153243],
                            [112.487077, 26.151145],
                            [112.484146, 26.153359],
                            [112.482037, 26.16074],
                            [112.485049, 26.167965],
                            [112.479507, 26.175772],
                            [112.471817, 26.178297],
                            [112.464549, 26.177054],
                            [112.456718, 26.180316],
                            [112.450936, 26.180394],
                            [112.444591, 26.175928],
                            [112.436901, 26.18321],
                            [112.430597, 26.184064],
                            [112.429251, 26.189715],
                            [112.418891, 26.186472],
                            [112.413289, 26.188026],
                            [112.40576, 26.184802],
                            [112.393793, 26.186919],
                            [112.391845, 26.189016],
                            [112.388974, 26.201191],
                            [112.384838, 26.206588],
                            [112.381264, 26.206433],
                            [112.377268, 26.201521],
                            [112.37506, 26.19057],
                            [112.364338, 26.181268],
                            [112.361728, 26.176899],
                            [112.360282, 26.166042],
                            [112.357029, 26.161323],
                            [112.350845, 26.161711],
                            [112.340083, 26.167305],
                            [112.3291, 26.164877],
                            [112.327052, 26.161109],
                            [112.315628, 26.161886],
                            [112.310407, 26.158565],
                            [112.311853, 26.148969],
                            [112.306833, 26.136576],
                            [112.304243, 26.132904],
                            [112.299806, 26.13234],
                            [112.29577, 26.138013],
                            [112.292397, 26.1344],
                            [112.291574, 26.142909],
                            [112.286514, 26.144307],
                            [112.276455, 26.138596],
                            [112.266958, 26.131155],
                            [112.258505, 26.130728],
                            [112.257722, 26.123287],
                            [112.249831, 26.12284],
                            [112.247743, 26.127542],
                            [112.243004, 26.122141],
                            [112.23178, 26.117322],
                            [112.229692, 26.111862],
                            [112.232865, 26.100708],
                            [112.240595, 26.093304],
                            [112.246819, 26.092507],
                            [112.251698, 26.089573],
                            [112.263625, 26.092799],
                            [112.271134, 26.089048],
                            [112.280832, 26.096958],
                            [112.282599, 26.101719],
                            [112.287819, 26.102243],
                            [112.291634, 26.09995],
                            [112.29563, 26.090933],
                            [112.299706, 26.09035],
                            [112.309805, 26.099018],
                            [112.315286, 26.098376],
                            [112.321812, 26.093751],
                            [112.335807, 26.076648],
                            [112.33665, 26.072488],
                            [112.333638, 26.06621],
                            [112.331429, 26.047528],
                            [112.328558, 26.039051],
                            [112.313881, 26.016126],
                            [112.306432, 26.006966],
                            [112.300408, 25.978743],
                            [112.296573, 25.973549],
                            [112.292999, 25.974288],
                            [112.301011, 25.968043],
                            [112.301051, 25.964055],
                            [112.304545, 25.965436],
                            [112.311532, 25.962421],
                            [112.308962, 25.958802],
                            [112.299324, 25.962518],
                            [112.297457, 25.959074],
                            [112.304484, 25.953977],
                            [112.309845, 25.956117],
                            [112.314483, 25.945902],
                            [112.316772, 25.944053],
                            [112.315206, 25.935803],
                            [112.305769, 25.936056],
                            [112.296814, 25.931482],
                            [112.265552, 25.896681],
                            [112.265773, 25.890257],
                            [112.272098, 25.886325],
                            [112.288663, 25.886286],
                            [112.304886, 25.890802],
                            [112.312395, 25.890588],
                            [112.33147, 25.88504],
                            [112.336831, 25.885721],
                            [112.345826, 25.897402],
                            [112.349701, 25.90657],
                            [112.35711, 25.908847],
                            [112.360403, 25.905869],
                            [112.359579, 25.894384],
                            [112.361326, 25.889556],
                            [112.368655, 25.878303],
                            [112.370622, 25.868646],
                            [112.377971, 25.8593],
                            [112.386866, 25.859767],
                            [112.388332, 25.857236],
                            [112.38536, 25.852075],
                            [112.387026, 25.846252],
                            [112.380521, 25.837293],
                            [112.368815, 25.837235],
                            [112.361567, 25.834119],
                            [112.352311, 25.836884],
                            [112.360342, 25.826308],
                            [112.356307, 25.821185],
                            [112.359379, 25.815497],
                            [112.365603, 25.814075],
                            [112.361708, 25.809108],
                            [112.373815, 25.806634],
                            [112.377068, 25.803692],
                            [112.38538, 25.805543],
                            [112.387729, 25.802095],
                            [112.385721, 25.793405],
                            [112.386846, 25.789353]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 431022,
                "name": "宜章县",
                "center": [112.947884, 25.394345],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431000
                },
                "subFeatureIndex": 3,
                "acroutes": [100000, 430000, 431000]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.657281, 25.133397],
                            [112.663204, 25.129185],
                            [112.667903, 25.122171],
                            [112.669871, 25.11304],
                            [112.679749, 25.109905],
                            [112.695229, 25.095522],
                            [112.697277, 25.089976],
                            [112.703562, 25.086272],
                            [112.712135, 25.083469],
                            [112.715629, 25.078374],
                            [112.7185, 25.062732],
                            [112.719183, 25.048245],
                            [112.715127, 25.026051],
                            [112.724865, 25.010854],
                            [112.73368, 25.006834],
                            [112.734985, 25.000362],
                            [112.741851, 24.998578],
                            [112.745526, 24.98171],
                            [112.742193, 24.977473],
                            [112.743157, 24.960308],
                            [112.748437, 24.955756],
                            [112.75936, 24.954481],
                            [112.764841, 24.950891],
                            [112.773455, 24.949969],
                            [112.781868, 24.944475],
                            [112.784659, 24.936646],
                            [112.784297, 24.928444],
                            [112.782229, 24.922713],
                            [112.786707, 24.91241],
                            [112.786305, 24.908976],
                            [112.780723, 24.897297],
                            [112.792088, 24.891448],
                            [112.804436, 24.890722],
                            [112.808632, 24.892842],
                            [112.816162, 24.891919],
                            [112.825357, 24.894824],
                            [112.830819, 24.89292],
                            [112.842083, 24.89716],
                            [112.857162, 24.897788],
                            [112.867863, 24.895727],
                            [112.873505, 24.896708],
                            [112.879308, 24.907798],
                            [112.888082, 24.912979],
                            [112.899386, 24.917022],
                            [112.904386, 24.92183],
                            [112.924565, 24.918867],
                            [112.931692, 24.921202],
                            [112.937375, 24.920476],
                            [112.941792, 24.915923],
                            [112.95922, 24.923479],
                            [112.96681, 24.919318],
                            [112.974058, 24.918769],
                            [112.978816, 24.921634],
                            [112.984037, 24.921026],
                            [112.990261, 24.926619],
                            [112.994799, 24.927364],
                            [112.994076, 24.934507],
                            [112.991647, 24.937509],
                            [112.99514, 24.940531],
                            [113.003995, 24.941963],
                            [113.011906, 24.946104],
                            [113.010661, 24.949792],
                            [113.004015, 24.954167],
                            [113.00279, 24.95711],
                            [113.008492, 24.961956],
                            [113.008573, 24.972647],
                            [113.009978, 24.977375],
                            [113.007528, 24.981828],
                            [113.003754, 24.982455],
                            [113.002047, 24.991027],
                            [112.996265, 24.991576],
                            [112.994678, 24.996087],
                            [112.990522, 24.99542],
                            [112.991506, 25.000009],
                            [112.989418, 25.006226],
                            [112.99267, 25.011521],
                            [112.992048, 25.017953],
                            [112.986466, 25.020012],
                            [112.978455, 25.026483],
                            [112.97968, 25.034149],
                            [112.985201, 25.038345],
                            [112.991606, 25.047402],
                            [112.996867, 25.048637],
                            [113.002409, 25.058204],
                            [112.999718, 25.062379],
                            [113.002027, 25.071122],
                            [113.018451, 25.083136],
                            [113.008974, 25.086154],
                            [113.004537, 25.089172],
                            [112.999598, 25.103047],
                            [112.991124, 25.109454],
                            [112.986808, 25.119232],
                            [112.981949, 25.125286],
                            [112.972813, 25.132123],
                            [112.968014, 25.141624],
                            [112.971207, 25.163093],
                            [112.974319, 25.168263],
                            [112.991566, 25.176802],
                            [113.003131, 25.184733],
                            [113.008573, 25.190118],
                            [113.021021, 25.190608],
                            [113.030498, 25.194504],
                            [113.034474, 25.201397],
                            [113.029775, 25.21052],
                            [113.0207, 25.209855],
                            [113.01562, 25.213438],
                            [113.006665, 25.231487],
                            [112.998794, 25.237007],
                            [112.999015, 25.244073],
                            [112.99255, 25.247205],
                            [112.979921, 25.245091],
                            [112.973616, 25.250493],
                            [112.965886, 25.249318],
                            [112.959581, 25.254094],
                            [112.953337, 25.25153],
                            [112.951851, 25.245756],
                            [112.944221, 25.249455],
                            [112.934102, 25.250375],
                            [112.931793, 25.249044],
                            [112.933339, 25.243173],
                            [112.926673, 25.243819],
                            [112.924002, 25.248673],
                            [112.910731, 25.24515],
                            [112.904526, 25.238436],
                            [112.896937, 25.239278],
                            [112.891716, 25.246187],
                            [112.884187, 25.247205],
                            [112.880954, 25.249632],
                            [112.876336, 25.248085],
                            [112.868325, 25.24881],
                            [112.866357, 25.251197],
                            [112.865896, 25.259809],
                            [112.873947, 25.27429],
                            [112.866438, 25.276579],
                            [112.863486, 25.285365],
                            [112.856399, 25.291587],
                            [112.858246, 25.297515],
                            [112.857523, 25.304851],
                            [112.855174, 25.30982],
                            [112.858788, 25.320227],
                            [112.851801, 25.33343],
                            [112.852483, 25.336402],
                            [112.857362, 25.336833],
                            [112.868727, 25.327836],
                            [112.87463, 25.328853],
                            [112.877702, 25.333743],
                            [112.885532, 25.332843],
                            [112.891154, 25.339864],
                            [112.894829, 25.337596],
                            [112.898142, 25.326251],
                            [112.900591, 25.311346],
                            [112.91318, 25.299882],
                            [112.924163, 25.296576],
                            [112.927837, 25.299902],
                            [112.92858, 25.310466],
                            [112.933018, 25.316549],
                            [112.931833, 25.322828],
                            [112.937716, 25.326486],
                            [112.945085, 25.326349],
                            [112.951148, 25.337459],
                            [112.960244, 25.339219],
                            [112.964701, 25.342133],
                            [112.968115, 25.349838],
                            [112.97713, 25.349995],
                            [112.987972, 25.353788],
                            [112.995421, 25.348958],
                            [112.999979, 25.351481],
                            [113.004798, 25.34974],
                            [113.009235, 25.352869],
                            [113.014054, 25.351285],
                            [113.016483, 25.347198],
                            [113.023672, 25.345966],
                            [113.025619, 25.353573],
                            [113.029053, 25.35549],
                            [113.029775, 25.361239],
                            [113.034012, 25.361415],
                            [113.034976, 25.366401],
                            [113.041622, 25.36601],
                            [113.05152, 25.373166],
                            [113.055817, 25.37432],
                            [113.059572, 25.379481],
                            [113.064591, 25.381808],
                            [113.075775, 25.381984],
                            [113.080152, 25.383352],
                            [113.080634, 25.390937],
                            [113.088746, 25.396528],
                            [113.090432, 25.406771],
                            [113.096877, 25.41072],
                            [113.091115, 25.414785],
                            [113.09238, 25.417482],
                            [113.103423, 25.421294],
                            [113.109627, 25.419613],
                            [113.114406, 25.42057],
                            [113.1284, 25.413222],
                            [113.131553, 25.414766],
                            [113.130167, 25.42917],
                            [113.126212, 25.438413],
                            [113.119546, 25.443631],
                            [113.118421, 25.447285],
                            [113.127959, 25.449708],
                            [113.129244, 25.454612],
                            [113.126352, 25.455393],
                            [113.124706, 25.450958],
                            [113.122357, 25.455726],
                            [113.127376, 25.463111],
                            [113.134605, 25.467702],
                            [113.149985, 25.468015],
                            [113.15388, 25.47032],
                            [113.151229, 25.473544],
                            [113.144624, 25.471492],
                            [113.140447, 25.472274],
                            [113.14382, 25.477568],
                            [113.149904, 25.476552],
                            [113.151932, 25.481963],
                            [113.149804, 25.489796],
                            [113.151992, 25.492238],
                            [113.162734, 25.49216],
                            [113.16934, 25.488038],
                            [113.172272, 25.475868],
                            [113.17707, 25.47157],
                            [113.182953, 25.472293],
                            [113.189679, 25.4762],
                            [113.200823, 25.479404],
                            [113.209236, 25.48882],
                            [113.210681, 25.496691],
                            [113.215299, 25.501672],
                            [113.226202, 25.509679],
                            [113.243249, 25.515636],
                            [113.249071, 25.514093],
                            [113.253288, 25.506457],
                            [113.260817, 25.499465],
                            [113.263327, 25.499348],
                            [113.266038, 25.504738],
                            [113.269411, 25.505266],
                            [113.27957, 25.50009],
                            [113.277864, 25.497434],
                            [113.282622, 25.494211],
                            [113.288867, 25.496535],
                            [113.291798, 25.50091],
                            [113.288806, 25.509386],
                            [113.291738, 25.516378],
                            [113.303363, 25.515851],
                            [113.305652, 25.514327],
                            [113.306516, 25.519307],
                            [113.304227, 25.522842],
                            [113.297822, 25.52499],
                            [113.290272, 25.531395],
                            [113.287361, 25.552052],
                            [113.278827, 25.559744],
                            [113.277281, 25.5656],
                            [113.284831, 25.586271],
                            [113.288385, 25.592243],
                            [113.300231, 25.603562],
                            [113.317237, 25.614919],
                            [113.339705, 25.642526],
                            [113.346773, 25.650095],
                            [113.347014, 25.656376],
                            [113.329485, 25.661799],
                            [113.296697, 25.66845],
                            [113.291236, 25.673482],
                            [113.288666, 25.679352],
                            [113.28975, 25.684286],
                            [113.286578, 25.685573],
                            [113.288144, 25.692009],
                            [113.281659, 25.693569],
                            [113.27945, 25.686139],
                            [113.272182, 25.679118],
                            [113.268166, 25.6806],
                            [113.267825, 25.686002],
                            [113.25909, 25.69273],
                            [113.253087, 25.695577],
                            [113.236201, 25.689201],
                            [113.229154, 25.677792],
                            [113.216223, 25.659341],
                            [113.213452, 25.653548],
                            [113.209557, 25.636791],
                            [113.205622, 25.632226],
                            [113.194799, 25.608577],
                            [113.191386, 25.594546],
                            [113.188455, 25.577859],
                            [113.182471, 25.57538],
                            [113.172814, 25.576551],
                            [113.154663, 25.586446],
                            [113.139986, 25.590428],
                            [113.134464, 25.594468],
                            [113.123822, 25.59517],
                            [113.119044, 25.589511],
                            [113.111655, 25.575009],
                            [113.099307, 25.565502],
                            [113.096476, 25.562008],
                            [113.093604, 25.548381],
                            [113.091316, 25.545316],
                            [113.082461, 25.542661],
                            [113.07202, 25.542856],
                            [113.052243, 25.547327],
                            [113.035076, 25.543325],
                            [113.032406, 25.541665],
                            [113.018291, 25.540689],
                            [113.012769, 25.538717],
                            [113.002629, 25.528407],
                            [112.986205, 25.525615],
                            [112.981467, 25.523838],
                            [112.971729, 25.516632],
                            [112.95916, 25.511847],
                            [112.951972, 25.510461],
                            [112.94131, 25.506008],
                            [112.938419, 25.49759],
                            [112.941149, 25.493058],
                            [112.939985, 25.487999],
                            [112.93109, 25.485479],
                            [112.929203, 25.49214],
                            [112.921553, 25.488214],
                            [112.915329, 25.488136],
                            [112.906293, 25.481416],
                            [112.898423, 25.478662],
                            [112.890612, 25.472958],
                            [112.886315, 25.46358],
                            [112.885914, 25.458305],
                            [112.869951, 25.449219],
                            [112.862623, 25.443025],
                            [112.850074, 25.43855],
                            [112.835698, 25.439488],
                            [112.817206, 25.433332],
                            [112.806966, 25.43216],
                            [112.791566, 25.433156],
                            [112.784899, 25.432433],
                            [112.783594, 25.422173],
                            [112.785883, 25.402471],
                            [112.783454, 25.39733],
                            [112.777671, 25.394945],
                            [112.76454, 25.402608],
                            [112.760143, 25.401318],
                            [112.75161, 25.379579],
                            [112.749481, 25.371895],
                            [112.73902, 25.348391],
                            [112.735527, 25.33918],
                            [112.735888, 25.334095],
                            [112.739522, 25.327581],
                            [112.750887, 25.32097],
                            [112.750947, 25.315219],
                            [112.748056, 25.310564],
                            [112.734623, 25.2946],
                            [112.722677, 25.287987],
                            [112.716071, 25.288163],
                            [112.710589, 25.292859],
                            [112.706393, 25.291274],
                            [112.695711, 25.281334],
                            [112.689587, 25.274349],
                            [112.693242, 25.269065],
                            [112.705068, 25.267421],
                            [112.708923, 25.264447],
                            [112.709124, 25.257304],
                            [112.706534, 25.253311],
                            [112.685451, 25.250199],
                            [112.664329, 25.243055],
                            [112.655434, 25.241509],
                            [112.642022, 25.24421],
                            [112.637103, 25.239552],
                            [112.634071, 25.229862],
                            [112.632786, 25.220094],
                            [112.635838, 25.206761],
                            [112.638187, 25.203139],
                            [112.652463, 25.194465],
                            [112.670372, 25.189492],
                            [112.677219, 25.186084],
                            [112.681837, 25.180053],
                            [112.688684, 25.167676],
                            [112.69033, 25.154925],
                            [112.685953, 25.150812],
                            [112.677038, 25.147207],
                            [112.669288, 25.145621],
                            [112.658888, 25.137961],
                            [112.657281, 25.133397]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 431023,
                "name": "永兴县",
                "center": [113.114819, 26.129392],
                "centroid": [113.192019, 26.209137],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431000
                },
                "subFeatureIndex": 4,
                "acroutes": [100000, 430000, 431000]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [113.132637, 26.008483],
                            [113.137556, 26.016631],
                            [113.142375, 26.018926],
                            [113.14894, 26.018576],
                            [113.157193, 26.035124],
                            [113.16175, 26.040626],
                            [113.161148, 26.043368],
                            [113.167935, 26.055538],
                            [113.181829, 26.069553],
                            [113.193073, 26.082362],
                            [113.198273, 26.086619],
                            [113.206706, 26.096161],
                            [113.21297, 26.113669],
                            [113.212328, 26.124414],
                            [113.201486, 26.123947],
                            [113.196004, 26.127872],
                            [113.196386, 26.132884],
                            [113.203975, 26.131563],
                            [113.209657, 26.137819],
                            [113.211605, 26.148756],
                            [113.219355, 26.148503],
                            [113.223431, 26.14658],
                            [113.225138, 26.15198],
                            [113.235438, 26.158467],
                            [113.237064, 26.169946],
                            [113.245538, 26.167829],
                            [113.248188, 26.169441],
                            [113.242044, 26.173286],
                            [113.254513, 26.180821],
                            [113.260155, 26.181132],
                            [113.267021, 26.171267],
                            [113.268668, 26.160118],
                            [113.273928, 26.155632],
                            [113.28214, 26.15602],
                            [113.294187, 26.146735],
                            [113.298303, 26.140713],
                            [113.300392, 26.131855],
                            [113.304287, 26.126531],
                            [113.305291, 26.120042],
                            [113.311515, 26.114816],
                            [113.317438, 26.115107],
                            [113.32824, 26.127989],
                            [113.336995, 26.131563],
                            [113.354824, 26.127425],
                            [113.363699, 26.129387],
                            [113.372332, 26.128358],
                            [113.384359, 26.119071],
                            [113.391748, 26.117244],
                            [113.397852, 26.117594],
                            [113.405301, 26.113863],
                            [113.41267, 26.112911],
                            [113.41783, 26.108209],
                            [113.421143, 26.108015],
                            [113.4254, 26.11567],
                            [113.423291, 26.121247],
                            [113.420079, 26.140811],
                            [113.420862, 26.150892],
                            [113.42558, 26.156564],
                            [113.450457, 26.163828],
                            [113.467062, 26.164061],
                            [113.476479, 26.157885],
                            [113.480575, 26.151844],
                            [113.48453, 26.150407],
                            [113.490654, 26.156661],
                            [113.498123, 26.157496],
                            [113.515612, 26.162255],
                            [113.52796, 26.169655],
                            [113.547858, 26.185035],
                            [113.55356, 26.192006],
                            [113.560085, 26.211772],
                            [113.567615, 26.225283],
                            [113.566008, 26.239473],
                            [113.573277, 26.259385],
                            [113.579441, 26.26542],
                            [113.583015, 26.274017],
                            [113.586067, 26.274948],
                            [113.591448, 26.269728],
                            [113.595504, 26.270136],
                            [113.604318, 26.283097],
                            [113.593516, 26.290257],
                            [113.588115, 26.297454],
                            [113.577453, 26.301509],
                            [113.566249, 26.300364],
                            [113.558981, 26.298056],
                            [113.553138, 26.29889],
                            [113.550086, 26.301645],
                            [113.546573, 26.309676],
                            [113.548159, 26.313924],
                            [113.557596, 26.326337],
                            [113.553098, 26.330003],
                            [113.536754, 26.331574],
                            [113.527779, 26.34224],
                            [113.52035, 26.344742],
                            [113.513403, 26.350462],
                            [113.487181, 26.352711],
                            [113.476318, 26.347359],
                            [113.463348, 26.354515],
                            [113.450518, 26.347806],
                            [113.440117, 26.345769],
                            [113.436403, 26.347166],
                            [113.434515, 26.35213],
                            [113.42817, 26.358994],
                            [113.419958, 26.361611],
                            [113.411887, 26.369289],
                            [113.4101, 26.376909],
                            [113.418854, 26.387435],
                            [113.42301, 26.394704],
                            [113.421625, 26.398212],
                            [113.409819, 26.39953],
                            [113.400743, 26.403058],
                            [113.402169, 26.409628],
                            [113.39982, 26.412691],
                            [113.399117, 26.420346],
                            [113.391929, 26.421528],
                            [113.388676, 26.412458],
                            [113.383657, 26.40928],
                            [113.373457, 26.407283],
                            [113.370686, 26.414978],
                            [113.367654, 26.416974],
                            [113.355487, 26.418447],
                            [113.341592, 26.416063],
                            [113.321956, 26.41647],
                            [113.314908, 26.418815],
                            [113.304287, 26.433077],
                            [113.296296, 26.43889],
                            [113.283405, 26.441332],
                            [113.260335, 26.44168],
                            [113.252565, 26.447009],
                            [113.25142, 26.45718],
                            [113.258528, 26.466576],
                            [113.258167, 26.474382],
                            [113.24873, 26.484299],
                            [113.231804, 26.48459],
                            [113.227929, 26.486468],
                            [113.230077, 26.482091],
                            [113.226845, 26.46706],
                            [113.217388, 26.46274],
                            [113.210119, 26.465161],
                            [113.204477, 26.462062],
                            [113.19492, 26.451678],
                            [113.19229, 26.445517],
                            [113.211685, 26.433116],
                            [113.212207, 26.425249],
                            [113.191226, 26.406179],
                            [113.185463, 26.403795],
                            [113.179078, 26.404938],
                            [113.17187, 26.41209],
                            [113.16685, 26.412594],
                            [113.163578, 26.409551],
                            [113.160425, 26.387726],
                            [113.165706, 26.381658],
                            [113.167091, 26.374272],
                            [113.157333, 26.368339],
                            [113.15657, 26.362445],
                            [113.162453, 26.353972],
                            [113.168898, 26.349105],
                            [113.164782, 26.34449],
                            [113.167694, 26.335995],
                            [113.173797, 26.329692],
                            [113.170906, 26.326337],
                            [113.165826, 26.326783],
                            [113.162694, 26.324301],
                            [113.158337, 26.326628],
                            [113.149483, 26.322924],
                            [113.142054, 26.325872],
                            [113.136833, 26.323273],
                            [113.127336, 26.313574],
                            [113.130649, 26.306552],
                            [113.126593, 26.300403],
                            [113.128802, 26.29305],
                            [113.124405, 26.291246],
                            [113.119204, 26.293012],
                            [113.117056, 26.287308],
                            [113.103543, 26.290567],
                            [113.096255, 26.285852],
                            [113.087119, 26.273609],
                            [113.086597, 26.266274],
                            [113.091677, 26.250089],
                            [113.090211, 26.24844],
                            [113.073526, 26.248537],
                            [113.068145, 26.252923],
                            [113.062403, 26.252379],
                            [113.052042, 26.248478],
                            [113.045276, 26.254534],
                            [113.039995, 26.255019],
                            [113.032104, 26.252011],
                            [113.02588, 26.245276],
                            [113.02576, 26.238813],
                            [113.031261, 26.232815],
                            [113.03361, 26.224216],
                            [113.039895, 26.213597],
                            [113.035478, 26.210238],
                            [113.034935, 26.206627],
                            [113.038007, 26.195171],
                            [113.035839, 26.19123],
                            [113.037285, 26.17919],
                            [113.035658, 26.17282],
                            [113.029775, 26.173481],
                            [113.02596, 26.165809],
                            [113.016263, 26.160837],
                            [113.012488, 26.155612],
                            [113.008653, 26.155651],
                            [113.000983, 26.161964],
                            [112.992972, 26.162333],
                            [112.984579, 26.156583],
                            [112.975102, 26.153689],
                            [112.968617, 26.154214],
                            [112.958899, 26.159419],
                            [112.949904, 26.160177],
                            [112.942595, 26.170781],
                            [112.930126, 26.176782],
                            [112.922677, 26.17686],
                            [112.921071, 26.174413],
                            [112.921011, 26.157943],
                            [112.910851, 26.150484],
                            [112.903623, 26.148464],
                            [112.891074, 26.156545],
                            [112.875855, 26.149824],
                            [112.870052, 26.155826],
                            [112.857925, 26.154194],
                            [112.84646, 26.158273],
                            [112.838589, 26.155768],
                            [112.82853, 26.160254],
                            [112.823189, 26.158506],
                            [112.818772, 26.161808],
                            [112.820278, 26.165498],
                            [112.820298, 26.179074],
                            [112.818531, 26.186666],
                            [112.809736, 26.192142],
                            [112.802649, 26.190239],
                            [112.795601, 26.180103],
                            [112.789839, 26.17985],
                            [112.791726, 26.193249],
                            [112.786024, 26.197016],
                            [112.775965, 26.18389],
                            [112.777169, 26.177326],
                            [112.788112, 26.175345],
                            [112.793051, 26.170917],
                            [112.791385, 26.166295],
                            [112.783635, 26.164217],
                            [112.781627, 26.157244],
                            [112.782149, 26.146036],
                            [112.780121, 26.14456],
                            [112.768355, 26.145512],
                            [112.765102, 26.151378],
                            [112.758757, 26.149843],
                            [112.758436, 26.142656],
                            [112.760384, 26.133079],
                            [112.752513, 26.129873],
                            [112.747393, 26.133953],
                            [112.746208, 26.143783],
                            [112.739984, 26.144463],
                            [112.735045, 26.134555],
                            [112.729182, 26.132302],
                            [112.721894, 26.125968],
                            [112.725206, 26.116448],
                            [112.732917, 26.102166],
                            [112.737013, 26.089437],
                            [112.74663, 26.071439],
                            [112.749883, 26.063877],
                            [112.762833, 26.043076],
                            [112.768315, 26.038137],
                            [112.775182, 26.03771],
                            [112.781225, 26.033413],
                            [112.784036, 26.027035],
                            [112.792047, 26.024915],
                            [112.796043, 26.02719],
                            [112.801223, 26.034657],
                            [112.825277, 26.045526],
                            [112.834192, 26.055071],
                            [112.838669, 26.05684],
                            [112.841922, 26.052952],
                            [112.84389, 26.045467],
                            [112.842243, 26.037535],
                            [112.832746, 26.023671],
                            [112.82857, 26.009455],
                            [112.832405, 26.002998],
                            [112.84138, 26.003621],
                            [112.847122, 26.002337],
                            [112.869289, 26.000237],
                            [112.86953, 25.993779],
                            [112.867241, 25.9888],
                            [112.868586, 25.983859],
                            [112.873224, 25.981097],
                            [112.891516, 25.978354],
                            [112.898382, 25.97958],
                            [112.916895, 25.976331],
                            [112.91324, 25.986485],
                            [112.912899, 25.99162],
                            [112.917597, 26.001559],
                            [112.926332, 26.012839],
                            [112.936953, 26.017681],
                            [112.951289, 26.017954],
                            [112.958377, 26.020054],
                            [112.970604, 26.028474],
                            [112.974259, 26.02966],
                            [112.990341, 26.023185],
                            [112.997831, 26.031021],
                            [113.011886, 26.031429],
                            [113.022065, 26.024604],
                            [113.026061, 26.017876],
                            [113.031482, 26.018226],
                            [113.042144, 26.025013],
                            [113.049854, 26.025499],
                            [113.054291, 26.030729],
                            [113.053387, 26.038837],
                            [113.056279, 26.049006],
                            [113.067101, 26.053166],
                            [113.074008, 26.053185],
                            [113.081317, 26.050522],
                            [113.083927, 26.045623],
                            [113.088846, 26.048636],
                            [113.100712, 26.033918],
                            [113.107057, 26.037379],
                            [113.107579, 26.034657],
                            [113.113382, 26.032965],
                            [113.112558, 26.030399],
                            [113.116353, 26.023457],
                            [113.115711, 26.01739],
                            [113.117257, 26.009883],
                            [113.132637, 26.008483]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 431024,
                "name": "嘉禾县",
                "center": [112.370618, 25.587309],
                "centroid": [112.407365, 25.630813],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431000
                },
                "subFeatureIndex": 5,
                "acroutes": [100000, 430000, 431000]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.420638, 25.435501],
                            [112.419553, 25.447793],
                            [112.411522, 25.455862],
                            [112.410076, 25.460317],
                            [112.409474, 25.472762],
                            [112.413008, 25.479228],
                            [112.420276, 25.483604],
                            [112.433167, 25.483741],
                            [112.434572, 25.490402],
                            [112.425778, 25.502512],
                            [112.423589, 25.508469],
                            [112.428408, 25.518741],
                            [112.437483, 25.521182],
                            [112.441981, 25.510324],
                            [112.447884, 25.507648],
                            [112.457421, 25.506359],
                            [112.476536, 25.507453],
                            [112.476917, 25.512492],
                            [112.474387, 25.519327],
                            [112.48264, 25.521319],
                            [112.484768, 25.526025],
                            [112.480993, 25.536608],
                            [112.481074, 25.544418],
                            [112.484868, 25.551076],
                            [112.490611, 25.556679],
                            [112.499244, 25.561774],
                            [112.517215, 25.562145],
                            [112.524523, 25.560993],
                            [112.529563, 25.557518],
                            [112.537574, 25.54723],
                            [112.549701, 25.546702],
                            [112.556388, 25.547659],
                            [112.560263, 25.555449],
                            [112.557652, 25.574482],
                            [112.547433, 25.588184],
                            [112.5442, 25.595229],
                            [112.54177, 25.609143],
                            [112.536189, 25.614295],
                            [112.531691, 25.621163],
                            [112.52615, 25.624304],
                            [112.520206, 25.631114],
                            [112.521451, 25.637727],
                            [112.529422, 25.640088],
                            [112.536811, 25.637337],
                            [112.552713, 25.64397],
                            [112.558134, 25.651461],
                            [112.565925, 25.653724],
                            [112.567551, 25.648106],
                            [112.575, 25.649257],
                            [112.591464, 25.662404],
                            [112.596323, 25.674126],
                            [112.59532, 25.678377],
                            [112.590661, 25.678572],
                            [112.583795, 25.686665],
                            [112.572671, 25.690936],
                            [112.570663, 25.694563],
                            [112.562371, 25.701251],
                            [112.556106, 25.70248],
                            [112.552472, 25.70634],
                            [112.554299, 25.713808],
                            [112.545425, 25.721119],
                            [112.539963, 25.71991],
                            [112.540967, 25.712229],
                            [112.532695, 25.697293],
                            [112.524443, 25.69548],
                            [112.524222, 25.706204],
                            [112.527455, 25.710844],
                            [112.535325, 25.715484],
                            [112.534121, 25.718721],
                            [112.527455, 25.718935],
                            [112.520407, 25.723614],
                            [112.516371, 25.730691],
                            [112.507095, 25.731119],
                            [112.49822, 25.727922],
                            [112.495992, 25.717415],
                            [112.491755, 25.715406],
                            [112.485912, 25.72028],
                            [112.478243, 25.724335],
                            [112.477841, 25.728995],
                            [112.481877, 25.738079],
                            [112.490751, 25.743517],
                            [112.488744, 25.747357],
                            [112.478644, 25.749403],
                            [112.476636, 25.758719],
                            [112.471998, 25.768444],
                            [112.460092, 25.759967],
                            [112.457461, 25.75451],
                            [112.456859, 25.746635],
                            [112.458887, 25.738137],
                            [112.45706, 25.736714],
                            [112.445956, 25.750085],
                            [112.43909, 25.754958],
                            [112.429994, 25.757336],
                            [112.426119, 25.760727],
                            [112.413771, 25.765599],
                            [112.39301, 25.779435],
                            [112.386846, 25.789353],
                            [112.377027, 25.760512],
                            [112.37759, 25.750768],
                            [112.389998, 25.744063],
                            [112.392086, 25.74032],
                            [112.383513, 25.730593],
                            [112.361788, 25.720183],
                            [112.35209, 25.712404],
                            [112.347452, 25.705073],
                            [112.343316, 25.69429],
                            [112.336429, 25.690858],
                            [112.321451, 25.698463],
                            [112.317937, 25.706964],
                            [112.317013, 25.723263],
                            [112.310588, 25.728878],
                            [112.297397, 25.730262],
                            [112.292497, 25.72841],
                            [112.289144, 25.723341],
                            [112.280752, 25.716459],
                            [112.278121, 25.711195],
                            [112.247381, 25.703942],
                            [112.242542, 25.697956],
                            [112.243466, 25.691463],
                            [112.250855, 25.689844],
                            [112.251377, 25.687075],
                            [112.243044, 25.684852],
                            [112.245635, 25.672078],
                            [112.257923, 25.650485],
                            [112.258866, 25.64319],
                            [112.257441, 25.634079],
                            [112.238848, 25.603894],
                            [112.238426, 25.599659],
                            [112.243647, 25.589901],
                            [112.249931, 25.584807],
                            [112.260493, 25.582328],
                            [112.260794, 25.577078],
                            [112.257682, 25.573037],
                            [112.246277, 25.567591],
                            [112.250995, 25.559002],
                            [112.258706, 25.556269],
                            [112.270311, 25.554883],
                            [112.275893, 25.556249],
                            [112.285791, 25.555761],
                            [112.294405, 25.551154],
                            [112.300469, 25.544711],
                            [112.312857, 25.535027],
                            [112.325587, 25.529969],
                            [112.3342, 25.532469],
                            [112.342894, 25.537741],
                            [112.357833, 25.541665],
                            [112.36992, 25.53942],
                            [112.372409, 25.530047],
                            [112.371466, 25.524697],
                            [112.367952, 25.520459],
                            [112.372168, 25.512062],
                            [112.392026, 25.490949],
                            [112.393913, 25.483917],
                            [112.398009, 25.476865],
                            [112.400017, 25.457191],
                            [112.411803, 25.440602],
                            [112.420638, 25.435501]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 431025,
                "name": "临武县",
                "center": [112.564589, 25.279119],
                "centroid": [112.560747, 25.339025],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431000
                },
                "subFeatureIndex": 6,
                "acroutes": [100000, 430000, 431000]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.420638, 25.435501],
                            [112.426882, 25.423307],
                            [112.439953, 25.408296],
                            [112.440977, 25.399324],
                            [112.42644, 25.398503],
                            [112.42116, 25.39514],
                            [112.428006, 25.385737],
                            [112.435737, 25.37995],
                            [112.455193, 25.381104],
                            [112.458425, 25.375102],
                            [112.452542, 25.365501],
                            [112.444471, 25.362725],
                            [112.440455, 25.366401],
                            [112.432865, 25.368395],
                            [112.429231, 25.358755],
                            [112.419995, 25.339062],
                            [112.409514, 25.325802],
                            [112.403169, 25.320305],
                            [112.394014, 25.314613],
                            [112.386083, 25.313146],
                            [112.374899, 25.300802],
                            [112.36221, 25.29818],
                            [112.353054, 25.292232],
                            [112.345163, 25.289258],
                            [112.344882, 25.28468],
                            [112.357592, 25.278634],
                            [112.355403, 25.263977],
                            [112.360503, 25.258537],
                            [112.356969, 25.250904],
                            [112.357612, 25.244269],
                            [112.368153, 25.232701],
                            [112.368093, 25.222835],
                            [112.361868, 25.212008],
                            [112.361567, 25.207858],
                            [112.3649, 25.199517],
                            [112.364197, 25.191724],
                            [112.371907, 25.185399],
                            [112.374558, 25.176038],
                            [112.383352, 25.17028],
                            [112.39315, 25.160135],
                            [112.387388, 25.152183],
                            [112.392568, 25.145288],
                            [112.399475, 25.140665],
                            [112.409976, 25.139842],
                            [112.413349, 25.141331],
                            [112.426119, 25.156257],
                            [112.429773, 25.165756],
                            [112.430074, 25.174119],
                            [112.443005, 25.185614],
                            [112.448245, 25.18442],
                            [112.454831, 25.173512],
                            [112.452683, 25.159861],
                            [112.458265, 25.152085],
                            [112.470332, 25.150518],
                            [112.47748, 25.151399],
                            [112.48541, 25.149029],
                            [112.491032, 25.144484],
                            [112.500208, 25.14564],
                            [112.504686, 25.142584],
                            [112.507697, 25.136727],
                            [112.521732, 25.137197],
                            [112.525868, 25.134435],
                            [112.537012, 25.134239],
                            [112.548517, 25.13038],
                            [112.553336, 25.127186],
                            [112.562672, 25.124502],
                            [112.574057, 25.125521],
                            [112.584136, 25.125207],
                            [112.587469, 25.127539],
                            [112.596524, 25.126265],
                            [112.612727, 25.133201],
                            [112.624815, 25.134082],
                            [112.628208, 25.140606],
                            [112.64164, 25.138314],
                            [112.648005, 25.133612],
                            [112.657281, 25.133397],
                            [112.658888, 25.137961],
                            [112.669288, 25.145621],
                            [112.677038, 25.147207],
                            [112.685953, 25.150812],
                            [112.69033, 25.154925],
                            [112.688684, 25.167676],
                            [112.681837, 25.180053],
                            [112.677219, 25.186084],
                            [112.670372, 25.189492],
                            [112.652463, 25.194465],
                            [112.638187, 25.203139],
                            [112.635838, 25.206761],
                            [112.632786, 25.220094],
                            [112.634071, 25.229862],
                            [112.637103, 25.239552],
                            [112.642022, 25.24421],
                            [112.655434, 25.241509],
                            [112.664329, 25.243055],
                            [112.685451, 25.250199],
                            [112.706534, 25.253311],
                            [112.709124, 25.257304],
                            [112.708923, 25.264447],
                            [112.705068, 25.267421],
                            [112.693242, 25.269065],
                            [112.689587, 25.274349],
                            [112.695711, 25.281334],
                            [112.706393, 25.291274],
                            [112.710589, 25.292859],
                            [112.716071, 25.288163],
                            [112.722677, 25.287987],
                            [112.734623, 25.2946],
                            [112.748056, 25.310564],
                            [112.750947, 25.315219],
                            [112.750887, 25.32097],
                            [112.739522, 25.327581],
                            [112.735888, 25.334095],
                            [112.735527, 25.33918],
                            [112.73902, 25.348391],
                            [112.749481, 25.371895],
                            [112.75161, 25.379579],
                            [112.760143, 25.401318],
                            [112.76454, 25.402608],
                            [112.777671, 25.394945],
                            [112.783454, 25.39733],
                            [112.785883, 25.402471],
                            [112.783594, 25.422173],
                            [112.784899, 25.432433],
                            [112.782209, 25.436713],
                            [112.785843, 25.440465],
                            [112.785221, 25.453049],
                            [112.790642, 25.46989],
                            [112.79008, 25.473661],
                            [112.781948, 25.480361],
                            [112.785903, 25.489015],
                            [112.781868, 25.493195],
                            [112.765604, 25.491027],
                            [112.7491, 25.493371],
                            [112.735707, 25.476689],
                            [112.723058, 25.469324],
                            [112.730487, 25.468484],
                            [112.730828, 25.466022],
                            [112.723942, 25.462877],
                            [112.720147, 25.453225],
                            [112.709224, 25.454885],
                            [112.708742, 25.459711],
                            [112.698944, 25.463893],
                            [112.690632, 25.46231],
                            [112.689206, 25.45174],
                            [112.683423, 25.450645],
                            [112.674127, 25.454592],
                            [112.670854, 25.451193],
                            [112.665012, 25.451544],
                            [112.66218, 25.457465],
                            [112.659108, 25.47075],
                            [112.65941, 25.474638],
                            [112.664409, 25.48378],
                            [112.661819, 25.491183],
                            [112.642162, 25.500715],
                            [112.641179, 25.505851],
                            [112.647845, 25.522939],
                            [112.64662, 25.53036],
                            [112.62353, 25.53819],
                            [112.61588, 25.534519],
                            [112.617606, 25.527294],
                            [112.613189, 25.522725],
                            [112.607146, 25.526786],
                            [112.606182, 25.537409],
                            [112.60046, 25.545824],
                            [112.592529, 25.552052],
                            [112.590501, 25.558611],
                            [112.595239, 25.560856],
                            [112.594998, 25.564312],
                            [112.589657, 25.570851],
                            [112.583855, 25.581801],
                            [112.576968, 25.584748],
                            [112.557652, 25.574482],
                            [112.560263, 25.555449],
                            [112.556388, 25.547659],
                            [112.549701, 25.546702],
                            [112.537574, 25.54723],
                            [112.529563, 25.557518],
                            [112.524523, 25.560993],
                            [112.517215, 25.562145],
                            [112.499244, 25.561774],
                            [112.490611, 25.556679],
                            [112.484868, 25.551076],
                            [112.481074, 25.544418],
                            [112.480993, 25.536608],
                            [112.484768, 25.526025],
                            [112.48264, 25.521319],
                            [112.474387, 25.519327],
                            [112.476917, 25.512492],
                            [112.476536, 25.507453],
                            [112.457421, 25.506359],
                            [112.447884, 25.507648],
                            [112.441981, 25.510324],
                            [112.437483, 25.521182],
                            [112.428408, 25.518741],
                            [112.423589, 25.508469],
                            [112.425778, 25.502512],
                            [112.434572, 25.490402],
                            [112.433167, 25.483741],
                            [112.420276, 25.483604],
                            [112.413008, 25.479228],
                            [112.409474, 25.472762],
                            [112.410076, 25.460317],
                            [112.411522, 25.455862],
                            [112.419553, 25.447793],
                            [112.420638, 25.435501]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 431026,
                "name": "汝城县",
                "center": [113.685686, 25.553759],
                "centroid": [113.66981, 25.550089],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431000
                },
                "subFeatureIndex": 7,
                "acroutes": [100000, 430000, 431000]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [113.305652, 25.514327],
                            [113.30505, 25.511476],
                            [113.296838, 25.50839],
                            [113.300753, 25.502375],
                            [113.305411, 25.500441],
                            [113.308262, 25.492179],
                            [113.311354, 25.490441],
                            [113.309166, 25.481026],
                            [113.315952, 25.467526],
                            [113.313483, 25.453029],
                            [113.313924, 25.442927],
                            [113.317739, 25.443337],
                            [113.323201, 25.448692],
                            [113.329746, 25.450821],
                            [113.332919, 25.449121],
                            [113.340087, 25.449923],
                            [113.351732, 25.437592],
                            [113.359743, 25.438394],
                            [113.366931, 25.431183],
                            [113.371851, 25.40681],
                            [113.376509, 25.400731],
                            [113.389359, 25.403644],
                            [113.393595, 25.397564],
                            [113.398234, 25.397564],
                            [113.407068, 25.401396],
                            [113.418432, 25.398561],
                            [113.424135, 25.392677],
                            [113.42562, 25.37954],
                            [113.427749, 25.373244],
                            [113.435961, 25.368884],
                            [113.442265, 25.361258],
                            [113.449835, 25.359557],
                            [113.462424, 25.369842],
                            [113.468929, 25.365853],
                            [113.475214, 25.367554],
                            [113.480033, 25.37518],
                            [113.483808, 25.376314],
                            [113.490955, 25.373244],
                            [113.498505, 25.372873],
                            [113.504147, 25.374945],
                            [113.510452, 25.371133],
                            [113.518403, 25.370566],
                            [113.51507, 25.360672],
                            [113.520752, 25.354179],
                            [113.524326, 25.353612],
                            [113.532899, 25.362725],
                            [113.536092, 25.368493],
                            [113.544324, 25.366596],
                            [113.54595, 25.360163],
                            [113.551411, 25.358657],
                            [113.554544, 25.351852],
                            [113.562515, 25.348821],
                            [113.576389, 25.345575],
                            [113.581429, 25.34272],
                            [113.583336, 25.331865],
                            [113.579782, 25.316628],
                            [113.580826, 25.308783],
                            [113.586006, 25.307336],
                            [113.591347, 25.314359],
                            [113.601467, 25.320872],
                            [113.609077, 25.321772],
                            [113.610763, 25.327014],
                            [113.620501, 25.325665],
                            [113.631042, 25.330554],
                            [113.632568, 25.328227],
                            [113.644796, 25.329557],
                            [113.646121, 25.326369],
                            [113.657485, 25.329127],
                            [113.66367, 25.337713],
                            [113.671038, 25.337185],
                            [113.67909, 25.334486],
                            [113.685254, 25.340353],
                            [113.68423, 25.346181],
                            [113.687141, 25.352067],
                            [113.69184, 25.35326],
                            [113.707942, 25.352615],
                            [113.711014, 25.354355],
                            [113.706557, 25.360887],
                            [113.712862, 25.360339],
                            [113.716456, 25.357152],
                            [113.719628, 25.359479],
                            [113.72525, 25.358618],
                            [113.73298, 25.349819],
                            [113.738361, 25.349134],
                            [113.737679, 25.354844],
                            [113.744003, 25.363937],
                            [113.748039, 25.365658],
                            [113.754103, 25.36118],
                            [113.757817, 25.350581],
                            [113.758419, 25.343776],
                            [113.764142, 25.338241],
                            [113.764162, 25.333606],
                            [113.757817, 25.333723],
                            [113.758098, 25.329987],
                            [113.771049, 25.330418],
                            [113.780205, 25.334075],
                            [113.789802, 25.331396],
                            [113.796508, 25.331982],
                            [113.800484, 25.329498],
                            [113.81496, 25.328716],
                            [113.823152, 25.331924],
                            [113.83281, 25.343893],
                            [113.833452, 25.353984],
                            [113.839757, 25.363468],
                            [113.847306, 25.367652],
                            [113.86096, 25.369314],
                            [113.867686, 25.376353],
                            [113.876922, 25.380322],
                            [113.877364, 25.388122],
                            [113.883247, 25.395336],
                            [113.883889, 25.400282],
                            [113.881801, 25.406947],
                            [113.883688, 25.409801],
                            [113.892081, 25.411873],
                            [113.889913, 25.417717],
                            [113.884471, 25.420277],
                            [113.883427, 25.424342],
                            [113.88664, 25.42614],
                            [113.884351, 25.433],
                            [113.886881, 25.436615],
                            [113.895294, 25.437553],
                            [113.903707, 25.440973],
                            [113.912521, 25.43853],
                            [113.913404, 25.44281],
                            [113.926556, 25.442165],
                            [113.935049, 25.437514],
                            [113.943502, 25.438843],
                            [113.944767, 25.441696],
                            [113.941193, 25.447597],
                            [113.942036, 25.454241],
                            [113.939948, 25.459379],
                            [113.942679, 25.465143],
                            [113.951393, 25.470867],
                            [113.958982, 25.483663],
                            [113.957998, 25.486144],
                            [113.952055, 25.486964],
                            [113.952015, 25.490968],
                            [113.955609, 25.496652],
                            [113.953903, 25.50007],
                            [113.947518, 25.497043],
                            [113.945851, 25.499543],
                            [113.9533, 25.505012],
                            [113.959344, 25.517022],
                            [113.960107, 25.523857],
                            [113.962857, 25.528231],
                            [113.967716, 25.530516],
                            [113.972334, 25.528583],
                            [113.986108, 25.528973],
                            [113.987694, 25.530457],
                            [113.9841, 25.5386],
                            [113.988578, 25.551564],
                            [113.988959, 25.556718],
                            [113.992855, 25.560427],
                            [113.991449, 25.565912],
                            [113.979482, 25.573271],
                            [113.979824, 25.586954],
                            [113.983076, 25.599366],
                            [113.978438, 25.605631],
                            [113.965347, 25.60848],
                            [113.957115, 25.611894],
                            [113.950389, 25.621787],
                            [113.949545, 25.625202],
                            [113.951252, 25.637552],
                            [113.943241, 25.642702],
                            [113.942618, 25.65029],
                            [113.937579, 25.662657],
                            [113.932941, 25.663418],
                            [113.929588, 25.678065],
                            [113.926656, 25.685495],
                            [113.920331, 25.694622],
                            [113.915914, 25.696143],
                            [113.913605, 25.70129],
                            [113.918283, 25.717434],
                            [113.91491, 25.724667],
                            [113.915211, 25.73073],
                            [113.909449, 25.731724],
                            [113.90461, 25.735525],
                            [113.903345, 25.741665],
                            [113.894651, 25.751567],
                            [113.894069, 25.758797],
                            [113.88921, 25.766339],
                            [113.883949, 25.768249],
                            [113.863148, 25.763085],
                            [113.851643, 25.765287],
                            [113.842066, 25.769204],
                            [113.833713, 25.769808],
                            [113.819257, 25.752444],
                            [113.812691, 25.747162],
                            [113.800644, 25.743186],
                            [113.790444, 25.742991],
                            [113.787854, 25.748234],
                            [113.793155, 25.759596],
                            [113.792131, 25.768405],
                            [113.783276, 25.775011],
                            [113.769302, 25.781286],
                            [113.759283, 25.789119],
                            [113.741453, 25.81014],
                            [113.74328, 25.819432],
                            [113.757797, 25.8281],
                            [113.760849, 25.8311],
                            [113.75862, 25.837702],
                            [113.749806, 25.839007],
                            [113.735631, 25.834216],
                            [113.725471, 25.831937],
                            [113.712279, 25.838793],
                            [113.704469, 25.848998],
                            [113.698666, 25.849836],
                            [113.689852, 25.854334],
                            [113.684049, 25.860935],
                            [113.679552, 25.877116],
                            [113.674612, 25.882042],
                            [113.666119, 25.884436],
                            [113.663148, 25.882567],
                            [113.651562, 25.86923],
                            [113.639796, 25.865336],
                            [113.635801, 25.867906],
                            [113.628171, 25.869269],
                            [113.625019, 25.864421],
                            [113.624698, 25.855249],
                            [113.612952, 25.852698],
                            [113.60478, 25.844129],
                            [113.596427, 25.842299],
                            [113.588858, 25.83669],
                            [113.590504, 25.833866],
                            [113.601688, 25.828607],
                            [113.611365, 25.831373],
                            [113.616767, 25.830029],
                            [113.619638, 25.81988],
                            [113.624416, 25.818244],
                            [113.631886, 25.825977],
                            [113.637769, 25.821439],
                            [113.642627, 25.820504],
                            [113.648771, 25.814036],
                            [113.651221, 25.803692],
                            [113.65094, 25.796678],
                            [113.660497, 25.792158],
                            [113.665396, 25.78415],
                            [113.659212, 25.777389],
                            [113.65106, 25.771621],
                            [113.645358, 25.769769],
                            [113.64072, 25.772634],
                            [113.637046, 25.771893],
                            [113.636363, 25.765424],
                            [113.648912, 25.750319],
                            [113.641383, 25.732698],
                            [113.631665, 25.728],
                            [113.621445, 25.725349],
                            [113.622549, 25.713827],
                            [113.632548, 25.701758],
                            [113.631906, 25.696435],
                            [113.624938, 25.689571],
                            [113.616084, 25.685242],
                            [113.603816, 25.684618],
                            [113.596628, 25.678962],
                            [113.587191, 25.676427],
                            [113.585926, 25.672429],
                            [113.586147, 25.661584],
                            [113.578979, 25.66254],
                            [113.572875, 25.66767],
                            [113.559403, 25.663691],
                            [113.55605, 25.659517],
                            [113.554323, 25.652553],
                            [113.556893, 25.64557],
                            [113.55621, 25.640556],
                            [113.541673, 25.630548],
                            [113.537537, 25.623699],
                            [113.537397, 25.619114],
                            [113.54595, 25.613865],
                            [113.547536, 25.608597],
                            [113.542758, 25.599932],
                            [113.538481, 25.597434],
                            [113.53053, 25.596166],
                            [113.5228, 25.589608],
                            [113.517981, 25.583128],
                            [113.51519, 25.572413],
                            [113.507882, 25.566049],
                            [113.503665, 25.565053],
                            [113.49469, 25.566967],
                            [113.485916, 25.56111],
                            [113.481197, 25.562809],
                            [113.470215, 25.56193],
                            [113.46134, 25.566166],
                            [113.460798, 25.569407],
                            [113.464713, 25.574267],
                            [113.464211, 25.577234],
                            [113.457947, 25.576453],
                            [113.45367, 25.570714],
                            [113.441161, 25.563707],
                            [113.437005, 25.568196],
                            [113.440017, 25.576024],
                            [113.434796, 25.58588],
                            [113.430238, 25.589764],
                            [113.433592, 25.596556],
                            [113.437768, 25.600225],
                            [113.437045, 25.610607],
                            [113.431664, 25.623036],
                            [113.435599, 25.625787],
                            [113.430439, 25.630079],
                            [113.42556, 25.639151],
                            [113.419958, 25.642058],
                            [113.41532, 25.641453],
                            [113.405703, 25.636128],
                            [113.399438, 25.62206],
                            [113.396065, 25.608928],
                            [113.386407, 25.605435],
                            [113.373095, 25.611602],
                            [113.365887, 25.61369],
                            [113.357254, 25.614002],
                            [113.346893, 25.612148],
                            [113.343239, 25.614236],
                            [113.340568, 25.625221],
                            [113.334525, 25.623075],
                            [113.330529, 25.614295],
                            [113.325891, 25.610646],
                            [113.317237, 25.614919],
                            [113.300231, 25.603562],
                            [113.288385, 25.592243],
                            [113.284831, 25.586271],
                            [113.277281, 25.5656],
                            [113.278827, 25.559744],
                            [113.287361, 25.552052],
                            [113.290272, 25.531395],
                            [113.297822, 25.52499],
                            [113.304227, 25.522842],
                            [113.306516, 25.519307],
                            [113.305652, 25.514327]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 431027,
                "name": "桂东县",
                "center": [113.945879, 26.073917],
                "centroid": [113.900394, 25.979584],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431000
                },
                "subFeatureIndex": 8,
                "acroutes": [100000, 430000, 431000]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [113.915211, 25.73073],
                            [113.918946, 25.739502],
                            [113.93015, 25.748214],
                            [113.937338, 25.756205],
                            [113.946855, 25.761331],
                            [113.961713, 25.777291],
                            [113.958761, 25.785456],
                            [113.960689, 25.796873],
                            [113.964504, 25.801607],
                            [113.97131, 25.814738],
                            [113.971973, 25.824049],
                            [113.970086, 25.833943],
                            [113.977294, 25.839825],
                            [113.981771, 25.849134],
                            [113.985807, 25.851802],
                            [113.991329, 25.860546],
                            [113.997693, 25.862259],
                            [114.003195, 25.869289],
                            [114.010262, 25.87108],
                            [114.013957, 25.885604],
                            [114.028293, 25.893333],
                            [114.024699, 25.898063],
                            [114.02251, 25.906784],
                            [114.02512, 25.912234],
                            [114.021828, 25.919221],
                            [114.031425, 25.938897],
                            [114.030642, 25.943333],
                            [114.026486, 25.944151],
                            [114.028032, 25.949229],
                            [114.023775, 25.949424],
                            [114.020824, 25.954385],
                            [114.023293, 25.959036],
                            [114.019017, 25.967596],
                            [114.021085, 25.975222],
                            [114.028233, 25.981389],
                            [114.027148, 25.991056],
                            [114.017591, 25.992923],
                            [114.017069, 25.996522],
                            [114.009058, 26.004185],
                            [114.00715, 26.012411],
                            [114.008455, 26.015659],
                            [114.018836, 26.025324],
                            [114.028092, 26.031177],
                            [114.031405, 26.040957],
                            [114.036605, 26.046789],
                            [114.0352, 26.058998],
                            [114.037348, 26.063702],
                            [114.045861, 26.0707],
                            [114.043813, 26.076687],
                            [114.054555, 26.078164],
                            [114.057888, 26.075987],
                            [114.069855, 26.078553],
                            [114.081742, 26.067823],
                            [114.085597, 26.066346],
                            [114.100053, 26.071828],
                            [114.10692, 26.069456],
                            [114.10943, 26.073635],
                            [114.114931, 26.076356],
                            [114.116598, 26.081565],
                            [114.120212, 26.084228],
                            [114.118967, 26.089884],
                            [114.107482, 26.09515],
                            [114.108747, 26.1003],
                            [114.129026, 26.105974],
                            [114.156694, 26.115806],
                            [114.162416, 26.120275],
                            [114.17107, 26.123248],
                            [114.182455, 26.121227],
                            [114.1889, 26.121305],
                            [114.204802, 26.133118],
                            [114.214962, 26.137469],
                            [114.225322, 26.147707],
                            [114.237249, 26.152174],
                            [114.235702, 26.16006],
                            [114.225382, 26.159672],
                            [114.219399, 26.163964],
                            [114.218475, 26.168509],
                            [114.232209, 26.179947],
                            [114.230562, 26.185152],
                            [114.222129, 26.18888],
                            [114.216789, 26.203132],
                            [114.211608, 26.202938],
                            [114.192253, 26.196725],
                            [114.186611, 26.197152],
                            [114.18374, 26.201074],
                            [114.18129, 26.214509],
                            [114.165508, 26.209889],
                            [114.148823, 26.203287],
                            [114.141936, 26.203385],
                            [114.136154, 26.198472],
                            [114.128163, 26.196977],
                            [114.116818, 26.191773],
                            [114.102804, 26.187812],
                            [114.099651, 26.182394],
                            [114.100374, 26.178413],
                            [114.094029, 26.176394],
                            [114.088588, 26.168412],
                            [114.080938, 26.168023],
                            [114.074694, 26.171772],
                            [114.070397, 26.171131],
                            [114.059113, 26.166159],
                            [114.058973, 26.172529],
                            [114.055359, 26.181326],
                            [114.040962, 26.177404],
                            [114.042107, 26.183462],
                            [114.036364, 26.188569],
                            [114.027911, 26.180045],
                            [114.024538, 26.17987],
                            [114.013375, 26.184064],
                            [114.008696, 26.179559],
                            [114.005825, 26.180685],
                            [114.003837, 26.176549],
                            [113.992112, 26.16781],
                            [113.985466, 26.166392],
                            [113.972154, 26.154758],
                            [113.966492, 26.154136],
                            [113.962677, 26.150853],
                            [113.957617, 26.151883],
                            [113.955449, 26.156078],
                            [113.944506, 26.16375],
                            [113.944626, 26.166101],
                            [113.951152, 26.169577],
                            [113.951794, 26.17787],
                            [113.946112, 26.18453],
                            [113.942538, 26.179579],
                            [113.93023, 26.172762],
                            [113.916938, 26.171325],
                            [113.914489, 26.158759],
                            [113.902803, 26.148056],
                            [113.896458, 26.135507],
                            [113.888467, 26.130359],
                            [113.860257, 26.119343],
                            [113.849615, 26.107568],
                            [113.847146, 26.097968],
                            [113.845078, 26.095714],
                            [113.824778, 26.084111],
                            [113.814037, 26.083956],
                            [113.811085, 26.086385],
                            [113.805784, 26.100009],
                            [113.799058, 26.104245],
                            [113.791609, 26.104323],
                            [113.780767, 26.093712],
                            [113.780124, 26.087221],
                            [113.782353, 26.073791],
                            [113.780927, 26.066152],
                            [113.761532, 26.047897],
                            [113.750729, 26.041618],
                            [113.744063, 26.039868],
                            [113.736454, 26.041132],
                            [113.728643, 26.046498],
                            [113.719889, 26.056024],
                            [113.707039, 26.036718],
                            [113.693827, 26.025635],
                            [113.689992, 26.016709],
                            [113.687442, 25.9902],
                            [113.687663, 25.974775],
                            [113.679431, 25.949871],
                            [113.678026, 25.941777],
                            [113.679612, 25.934265],
                            [113.678026, 25.92833],
                            [113.670316, 25.921927],
                            [113.65867, 25.926422],
                            [113.641804, 25.923425],
                            [113.633151, 25.920545],
                            [113.626525, 25.916244],
                            [113.62265, 25.905324],
                            [113.6254, 25.890335],
                            [113.625822, 25.881574],
                            [113.628171, 25.869269],
                            [113.635801, 25.867906],
                            [113.639796, 25.865336],
                            [113.651562, 25.86923],
                            [113.663148, 25.882567],
                            [113.666119, 25.884436],
                            [113.674612, 25.882042],
                            [113.679552, 25.877116],
                            [113.684049, 25.860935],
                            [113.689852, 25.854334],
                            [113.698666, 25.849836],
                            [113.704469, 25.848998],
                            [113.712279, 25.838793],
                            [113.725471, 25.831937],
                            [113.735631, 25.834216],
                            [113.749806, 25.839007],
                            [113.75862, 25.837702],
                            [113.760849, 25.8311],
                            [113.757797, 25.8281],
                            [113.74328, 25.819432],
                            [113.741453, 25.81014],
                            [113.759283, 25.789119],
                            [113.769302, 25.781286],
                            [113.783276, 25.775011],
                            [113.792131, 25.768405],
                            [113.793155, 25.759596],
                            [113.787854, 25.748234],
                            [113.790444, 25.742991],
                            [113.800644, 25.743186],
                            [113.812691, 25.747162],
                            [113.819257, 25.752444],
                            [113.833713, 25.769808],
                            [113.842066, 25.769204],
                            [113.851643, 25.765287],
                            [113.863148, 25.763085],
                            [113.883949, 25.768249],
                            [113.88921, 25.766339],
                            [113.894069, 25.758797],
                            [113.894651, 25.751567],
                            [113.903345, 25.741665],
                            [113.90461, 25.735525],
                            [113.909449, 25.731724],
                            [113.915211, 25.73073]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 431028,
                "name": "安仁县",
                "center": [113.27217, 26.708625],
                "centroid": [113.358845, 26.575557],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431000
                },
                "subFeatureIndex": 9,
                "acroutes": [100000, 430000, 431000]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [113.614638, 26.284591],
                            [113.616746, 26.288491],
                            [113.617048, 26.308259],
                            [113.607069, 26.315087],
                            [113.598957, 26.326647],
                            [113.59715, 26.345847],
                            [113.594098, 26.349454],
                            [113.592512, 26.360196],
                            [113.589239, 26.366013],
                            [113.583537, 26.370336],
                            [113.582613, 26.377839],
                            [113.584481, 26.38348],
                            [113.583216, 26.394646],
                            [113.588938, 26.401217],
                            [113.595504, 26.405578],
                            [113.607711, 26.419067],
                            [113.609619, 26.431023],
                            [113.603254, 26.448326],
                            [113.593476, 26.45902],
                            [113.589018, 26.467118],
                            [113.591126, 26.471438],
                            [113.596829, 26.475273],
                            [113.596628, 26.484493],
                            [113.603615, 26.507675],
                            [113.59705, 26.512206],
                            [113.589159, 26.511412],
                            [113.591026, 26.520706],
                            [113.57912, 26.518518],
                            [113.572976, 26.519119],
                            [113.569602, 26.523746],
                            [113.567374, 26.533156],
                            [113.55872, 26.549495],
                            [113.558077, 26.557315],
                            [113.562314, 26.566896],
                            [113.562214, 26.575179],
                            [113.554965, 26.585378],
                            [113.541312, 26.592693],
                            [113.523482, 26.595498],
                            [113.509167, 26.592731],
                            [113.495754, 26.584198],
                            [113.491036, 26.575315],
                            [113.48455, 26.568522],
                            [113.473708, 26.560876],
                            [113.467303, 26.559367],
                            [113.458167, 26.561786],
                            [113.439715, 26.558592],
                            [113.429496, 26.558728],
                            [113.425078, 26.564515],
                            [113.421826, 26.574889],
                            [113.414597, 26.587371],
                            [113.399117, 26.596969],
                            [113.397892, 26.600665],
                            [113.400282, 26.605231],
                            [113.409016, 26.607417],
                            [113.411365, 26.610145],
                            [113.412268, 26.620244],
                            [113.418051, 26.629356],
                            [113.418392, 26.634636],
                            [113.415481, 26.6457],
                            [113.40769, 26.655196],
                            [113.400984, 26.658233],
                            [113.39988, 26.666587],
                            [113.39219, 26.674902],
                            [113.381428, 26.693889],
                            [113.38203, 26.699147],
                            [113.385504, 26.701718],
                            [113.393595, 26.703342],
                            [113.400783, 26.706957],
                            [113.413252, 26.729823],
                            [113.427648, 26.764568],
                            [113.42813, 26.769785],
                            [113.416746, 26.778189],
                            [113.41271, 26.782593],
                            [113.408996, 26.791827],
                            [113.404719, 26.797622],
                            [113.394298, 26.804787],
                            [113.38444, 26.7971],
                            [113.372312, 26.797892],
                            [113.363679, 26.800055],
                            [113.338139, 26.810273],
                            [113.326293, 26.807569],
                            [113.315089, 26.796192],
                            [113.311113, 26.788176],
                            [113.306275, 26.773533],
                            [113.299267, 26.768084],
                            [113.283385, 26.771446],
                            [113.278085, 26.777706],
                            [113.278948, 26.783424],
                            [113.285453, 26.79003],
                            [113.285333, 26.799669],
                            [113.275735, 26.809346],
                            [113.273788, 26.813054],
                            [113.274972, 26.819852],
                            [113.280614, 26.825201],
                            [113.278044, 26.8269],
                            [113.267624, 26.826166],
                            [113.259793, 26.827788],
                            [113.261279, 26.824621],
                            [113.258508, 26.815487],
                            [113.254332, 26.812667],
                            [113.242305, 26.809307],
                            [113.238068, 26.805212],
                            [113.232005, 26.811064],
                            [113.224616, 26.814425],
                            [113.223692, 26.820914],
                            [113.217528, 26.821454],
                            [113.211786, 26.824776],
                            [113.206324, 26.82551],
                            [113.207108, 26.829681],
                            [113.191145, 26.828754],
                            [113.183857, 26.82522],
                            [113.17438, 26.825355],
                            [113.160506, 26.827537],
                            [113.157976, 26.83445],
                            [113.153157, 26.837559],
                            [113.144122, 26.83447],
                            [113.143017, 26.826649],
                            [113.142917, 26.812513],
                            [113.145346, 26.80726],
                            [113.144644, 26.802431],
                            [113.15127, 26.79739],
                            [113.149061, 26.792136],
                            [113.139162, 26.790455],
                            [113.133661, 26.787616],
                            [113.126433, 26.788157],
                            [113.116072, 26.784467],
                            [113.108462, 26.785104],
                            [113.097259, 26.781627],
                            [113.095592, 26.759911],
                            [113.096897, 26.756298],
                            [113.101957, 26.753245],
                            [113.111775, 26.754289],
                            [113.115349, 26.744124],
                            [113.120008, 26.742366],
                            [113.129063, 26.744124],
                            [113.132195, 26.742482],
                            [113.131412, 26.738076],
                            [113.121373, 26.731002],
                            [113.120108, 26.724875],
                            [113.122317, 26.723194],
                            [113.13354, 26.722073],
                            [113.139182, 26.717956],
                            [113.134805, 26.712602],
                            [113.140608, 26.70659],
                            [113.152273, 26.70052],
                            [113.160887, 26.687895],
                            [113.165405, 26.686986],
                            [113.174159, 26.689945],
                            [113.182993, 26.687779],
                            [113.181829, 26.680973],
                            [113.18466, 26.676816],
                            [113.185102, 26.670822],
                            [113.188154, 26.662468],
                            [113.186346, 26.651986],
                            [113.17958, 26.63837],
                            [113.17956, 26.630168],
                            [113.183857, 26.620109],
                            [113.181829, 26.615137],
                            [113.186668, 26.608094],
                            [113.184078, 26.602677],
                            [113.184178, 26.593293],
                            [113.179239, 26.578489],
                            [113.179138, 26.564438],
                            [113.17446, 26.556657],
                            [113.164782, 26.549243],
                            [113.15918, 26.541616],
                            [113.160004, 26.534221],
                            [113.168738, 26.528006],
                            [113.171649, 26.52121],
                            [113.179399, 26.51937],
                            [113.183977, 26.512787],
                            [113.189057, 26.511025],
                            [113.189639, 26.506145],
                            [113.200482, 26.502988],
                            [113.209597, 26.50508],
                            [113.214336, 26.502466],
                            [113.227929, 26.486468],
                            [113.231804, 26.48459],
                            [113.24873, 26.484299],
                            [113.258167, 26.474382],
                            [113.258528, 26.466576],
                            [113.25142, 26.45718],
                            [113.252565, 26.447009],
                            [113.260335, 26.44168],
                            [113.283405, 26.441332],
                            [113.296296, 26.43889],
                            [113.304287, 26.433077],
                            [113.314908, 26.418815],
                            [113.321956, 26.41647],
                            [113.341592, 26.416063],
                            [113.355487, 26.418447],
                            [113.367654, 26.416974],
                            [113.370686, 26.414978],
                            [113.373457, 26.407283],
                            [113.383657, 26.40928],
                            [113.388676, 26.412458],
                            [113.391929, 26.421528],
                            [113.399117, 26.420346],
                            [113.39982, 26.412691],
                            [113.402169, 26.409628],
                            [113.400743, 26.403058],
                            [113.409819, 26.39953],
                            [113.421625, 26.398212],
                            [113.42301, 26.394704],
                            [113.418854, 26.387435],
                            [113.4101, 26.376909],
                            [113.411887, 26.369289],
                            [113.419958, 26.361611],
                            [113.42817, 26.358994],
                            [113.434515, 26.35213],
                            [113.436403, 26.347166],
                            [113.440117, 26.345769],
                            [113.450518, 26.347806],
                            [113.463348, 26.354515],
                            [113.476318, 26.347359],
                            [113.487181, 26.352711],
                            [113.513403, 26.350462],
                            [113.52035, 26.344742],
                            [113.527779, 26.34224],
                            [113.536754, 26.331574],
                            [113.553098, 26.330003],
                            [113.557596, 26.326337],
                            [113.548159, 26.313924],
                            [113.546573, 26.309676],
                            [113.550086, 26.301645],
                            [113.553138, 26.29889],
                            [113.558981, 26.298056],
                            [113.566249, 26.300364],
                            [113.577453, 26.301509],
                            [113.588115, 26.297454],
                            [113.593516, 26.290257],
                            [113.604318, 26.283097],
                            [113.614638, 26.284591]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 431081,
                "name": "资兴市",
                "center": [113.23682, 25.974152],
                "centroid": [113.45746, 25.932234],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 431000
                },
                "subFeatureIndex": 10,
                "acroutes": [100000, 430000, 431000]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [113.614638, 26.284591],
                            [113.604318, 26.283097],
                            [113.595504, 26.270136],
                            [113.591448, 26.269728],
                            [113.586067, 26.274948],
                            [113.583015, 26.274017],
                            [113.579441, 26.26542],
                            [113.573277, 26.259385],
                            [113.566008, 26.239473],
                            [113.567615, 26.225283],
                            [113.560085, 26.211772],
                            [113.55356, 26.192006],
                            [113.547858, 26.185035],
                            [113.52796, 26.169655],
                            [113.515612, 26.162255],
                            [113.498123, 26.157496],
                            [113.490654, 26.156661],
                            [113.48453, 26.150407],
                            [113.480575, 26.151844],
                            [113.476479, 26.157885],
                            [113.467062, 26.164061],
                            [113.450457, 26.163828],
                            [113.42558, 26.156564],
                            [113.420862, 26.150892],
                            [113.420079, 26.140811],
                            [113.423291, 26.121247],
                            [113.4254, 26.11567],
                            [113.421143, 26.108015],
                            [113.41783, 26.108209],
                            [113.41267, 26.112911],
                            [113.405301, 26.113863],
                            [113.397852, 26.117594],
                            [113.391748, 26.117244],
                            [113.384359, 26.119071],
                            [113.372332, 26.128358],
                            [113.363699, 26.129387],
                            [113.354824, 26.127425],
                            [113.336995, 26.131563],
                            [113.32824, 26.127989],
                            [113.317438, 26.115107],
                            [113.311515, 26.114816],
                            [113.305291, 26.120042],
                            [113.304287, 26.126531],
                            [113.300392, 26.131855],
                            [113.298303, 26.140713],
                            [113.294187, 26.146735],
                            [113.28214, 26.15602],
                            [113.273928, 26.155632],
                            [113.268668, 26.160118],
                            [113.267021, 26.171267],
                            [113.260155, 26.181132],
                            [113.254513, 26.180821],
                            [113.242044, 26.173286],
                            [113.248188, 26.169441],
                            [113.245538, 26.167829],
                            [113.237064, 26.169946],
                            [113.235438, 26.158467],
                            [113.225138, 26.15198],
                            [113.223431, 26.14658],
                            [113.219355, 26.148503],
                            [113.211605, 26.148756],
                            [113.209657, 26.137819],
                            [113.203975, 26.131563],
                            [113.196386, 26.132884],
                            [113.196004, 26.127872],
                            [113.201486, 26.123947],
                            [113.212328, 26.124414],
                            [113.21297, 26.113669],
                            [113.206706, 26.096161],
                            [113.198273, 26.086619],
                            [113.193073, 26.082362],
                            [113.181829, 26.069553],
                            [113.167935, 26.055538],
                            [113.161148, 26.043368],
                            [113.16175, 26.040626],
                            [113.157193, 26.035124],
                            [113.14894, 26.018576],
                            [113.142375, 26.018926],
                            [113.137556, 26.016631],
                            [113.132637, 26.008483],
                            [113.128521, 26.00436],
                            [113.130127, 26.001073],
                            [113.126834, 25.998447],
                            [113.119485, 26.000742],
                            [113.115871, 25.998603],
                            [113.118462, 25.991912],
                            [113.114084, 25.992223],
                            [113.109045, 25.987497],
                            [113.109246, 25.984754],
                            [113.118582, 25.987497],
                            [113.123361, 25.983801],
                            [113.131693, 25.988041],
                            [113.128842, 25.980688],
                            [113.131814, 25.980825],
                            [113.131352, 25.976214],
                            [113.13595, 25.976837],
                            [113.13862, 25.973549],
                            [113.142315, 25.974113],
                            [113.146411, 25.968666],
                            [113.149503, 25.972946],
                            [113.159963, 25.972051],
                            [113.155426, 25.963841],
                            [113.160506, 25.965651],
                            [113.166268, 25.963997],
                            [113.174621, 25.967771],
                            [113.187069, 25.966507],
                            [113.196004, 25.966915],
                            [113.199859, 25.961292],
                            [113.190603, 25.954911],
                            [113.188716, 25.946778],
                            [113.191306, 25.945532],
                            [113.200964, 25.948762],
                            [113.205501, 25.944131],
                            [113.228391, 25.914219],
                            [113.24112, 25.89489],
                            [113.247987, 25.8916],
                            [113.244995, 25.885137],
                            [113.25138, 25.879238],
                            [113.259853, 25.859709],
                            [113.258669, 25.849758],
                            [113.260255, 25.846466],
                            [113.268708, 25.839864],
                            [113.267062, 25.82773],
                            [113.27176, 25.821069],
                            [113.270776, 25.809829],
                            [113.275936, 25.802211],
                            [113.277462, 25.796639],
                            [113.271941, 25.787326],
                            [113.273226, 25.780584],
                            [113.271519, 25.775771],
                            [113.25397, 25.756848],
                            [113.252103, 25.747513],
                            [113.25634, 25.742581],
                            [113.262263, 25.740496],
                            [113.263608, 25.725427],
                            [113.260857, 25.720475],
                            [113.251822, 25.717804],
                            [113.246903, 25.723907],
                            [113.24359, 25.720475],
                            [113.245698, 25.714607],
                            [113.254573, 25.711195],
                            [113.257444, 25.708056],
                            [113.253087, 25.695577],
                            [113.25909, 25.69273],
                            [113.267825, 25.686002],
                            [113.268166, 25.6806],
                            [113.272182, 25.679118],
                            [113.27945, 25.686139],
                            [113.281659, 25.693569],
                            [113.288144, 25.692009],
                            [113.286578, 25.685573],
                            [113.28975, 25.684286],
                            [113.288666, 25.679352],
                            [113.291236, 25.673482],
                            [113.296697, 25.66845],
                            [113.329485, 25.661799],
                            [113.347014, 25.656376],
                            [113.346773, 25.650095],
                            [113.339705, 25.642526],
                            [113.317237, 25.614919],
                            [113.325891, 25.610646],
                            [113.330529, 25.614295],
                            [113.334525, 25.623075],
                            [113.340568, 25.625221],
                            [113.343239, 25.614236],
                            [113.346893, 25.612148],
                            [113.357254, 25.614002],
                            [113.365887, 25.61369],
                            [113.373095, 25.611602],
                            [113.386407, 25.605435],
                            [113.396065, 25.608928],
                            [113.399438, 25.62206],
                            [113.405703, 25.636128],
                            [113.41532, 25.641453],
                            [113.419958, 25.642058],
                            [113.42556, 25.639151],
                            [113.430439, 25.630079],
                            [113.435599, 25.625787],
                            [113.431664, 25.623036],
                            [113.437045, 25.610607],
                            [113.437768, 25.600225],
                            [113.433592, 25.596556],
                            [113.430238, 25.589764],
                            [113.434796, 25.58588],
                            [113.440017, 25.576024],
                            [113.437005, 25.568196],
                            [113.441161, 25.563707],
                            [113.45367, 25.570714],
                            [113.457947, 25.576453],
                            [113.464211, 25.577234],
                            [113.464713, 25.574267],
                            [113.460798, 25.569407],
                            [113.46134, 25.566166],
                            [113.470215, 25.56193],
                            [113.481197, 25.562809],
                            [113.485916, 25.56111],
                            [113.49469, 25.566967],
                            [113.503665, 25.565053],
                            [113.507882, 25.566049],
                            [113.51519, 25.572413],
                            [113.517981, 25.583128],
                            [113.5228, 25.589608],
                            [113.53053, 25.596166],
                            [113.538481, 25.597434],
                            [113.542758, 25.599932],
                            [113.547536, 25.608597],
                            [113.54595, 25.613865],
                            [113.537397, 25.619114],
                            [113.537537, 25.623699],
                            [113.541673, 25.630548],
                            [113.55621, 25.640556],
                            [113.556893, 25.64557],
                            [113.554323, 25.652553],
                            [113.55605, 25.659517],
                            [113.559403, 25.663691],
                            [113.572875, 25.66767],
                            [113.578979, 25.66254],
                            [113.586147, 25.661584],
                            [113.585926, 25.672429],
                            [113.587191, 25.676427],
                            [113.596628, 25.678962],
                            [113.603816, 25.684618],
                            [113.616084, 25.685242],
                            [113.624938, 25.689571],
                            [113.631906, 25.696435],
                            [113.632548, 25.701758],
                            [113.622549, 25.713827],
                            [113.621445, 25.725349],
                            [113.631665, 25.728],
                            [113.641383, 25.732698],
                            [113.648912, 25.750319],
                            [113.636363, 25.765424],
                            [113.637046, 25.771893],
                            [113.64072, 25.772634],
                            [113.645358, 25.769769],
                            [113.65106, 25.771621],
                            [113.659212, 25.777389],
                            [113.665396, 25.78415],
                            [113.660497, 25.792158],
                            [113.65094, 25.796678],
                            [113.651221, 25.803692],
                            [113.648771, 25.814036],
                            [113.642627, 25.820504],
                            [113.637769, 25.821439],
                            [113.631886, 25.825977],
                            [113.624416, 25.818244],
                            [113.619638, 25.81988],
                            [113.616767, 25.830029],
                            [113.611365, 25.831373],
                            [113.601688, 25.828607],
                            [113.590504, 25.833866],
                            [113.588858, 25.83669],
                            [113.596427, 25.842299],
                            [113.60478, 25.844129],
                            [113.612952, 25.852698],
                            [113.624698, 25.855249],
                            [113.625019, 25.864421],
                            [113.628171, 25.869269],
                            [113.625822, 25.881574],
                            [113.6254, 25.890335],
                            [113.62265, 25.905324],
                            [113.626525, 25.916244],
                            [113.633151, 25.920545],
                            [113.641804, 25.923425],
                            [113.65867, 25.926422],
                            [113.670316, 25.921927],
                            [113.678026, 25.92833],
                            [113.679612, 25.934265],
                            [113.678026, 25.941777],
                            [113.679431, 25.949871],
                            [113.687663, 25.974775],
                            [113.687442, 25.9902],
                            [113.689992, 26.016709],
                            [113.693827, 26.025635],
                            [113.707039, 26.036718],
                            [113.719889, 26.056024],
                            [113.709087, 26.065802],
                            [113.707922, 26.071322],
                            [113.693988, 26.091458],
                            [113.693285, 26.095811],
                            [113.697582, 26.107704],
                            [113.700112, 26.121441],
                            [113.702481, 26.124414],
                            [113.722017, 26.133118],
                            [113.729286, 26.139218],
                            [113.736534, 26.159322],
                            [113.738823, 26.173306],
                            [113.738743, 26.183171],
                            [113.736333, 26.191696],
                            [113.730932, 26.203249],
                            [113.727659, 26.220042],
                            [113.72517, 26.22375],
                            [113.708505, 26.234057],
                            [113.691498, 26.241375],
                            [113.683105, 26.243995],
                            [113.678307, 26.247256],
                            [113.671581, 26.256746],
                            [113.655478, 26.270602],
                            [113.650297, 26.268098],
                            [113.644133, 26.267963],
                            [113.615221, 26.278906],
                            [113.614638, 26.284591]
                        ]
                    ]
                ]
            }
        }]
    });
}));