/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

(function(root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['exports', 'echarts'], factory);
    } else if (typeof exports === 'object' && typeof exports.nodeName !== 'string') {
        // CommonJS
        factory(exports, require('echarts'));
    } else {
        // Browser globals
        factory({}, root.echarts);
    }
}(this, function(exports, echarts) {
    var log = function(msg) {
        if (typeof console !== 'undefined') {
            console && console.error && console.error(msg);
        }
    }
    if (!echarts) {
        log('ECharts is not Loaded');
        return;
    }
    if (!echarts.registerMap) {
        log('ECharts Map is not loaded')
        return;
    }
    echarts.registerMap('益阳市', {
        "type": "FeatureCollection",
        "features": [{
            "type": "Feature",
            "properties": {
                "adcode": 430902,
                "name": "资阳区",
                "center": [112.33084, 28.592771],
                "centroid": [112.360152, 28.680037],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430900
                },
                "subFeatureIndex": 0,
                "acroutes": [100000, 430000, 430900]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.621571, 28.77936],
                            [112.620145, 28.791411],
                            [112.618451, 28.793254],
                            [112.615087, 28.791303],
                            [112.613259, 28.791659],
                            [112.610184, 28.797823],
                            [112.605861, 28.801355],
                            [112.594363, 28.80408],
                            [112.589595, 28.806264],
                            [112.583044, 28.811483],
                            [112.571857, 28.82175],
                            [112.56622, 28.824166],
                            [112.563991, 28.823624],
                            [112.558822, 28.820279],
                            [112.554031, 28.819381],
                            [112.551624, 28.814875],
                            [112.552159, 28.813078],
                            [112.552048, 28.805134],
                            [112.549931, 28.800116],
                            [112.547591, 28.797095],
                            [112.546187, 28.792945],
                            [112.542956, 28.787415],
                            [112.540416, 28.784224],
                            [112.541953, 28.783728],
                            [112.533865, 28.776959],
                            [112.530723, 28.775472],
                            [112.527313, 28.775518],
                            [112.521475, 28.777997],
                            [112.512205, 28.781002],
                            [112.508194, 28.783852],
                            [112.503493, 28.784069],
                            [112.501487, 28.782551],
                            [112.49576, 28.781389],
                            [112.490992, 28.779298],
                            [112.480229, 28.767989],
                            [112.479516, 28.766564],
                            [112.473901, 28.762102],
                            [112.470023, 28.762194],
                            [112.466837, 28.764023],
                            [112.461734, 28.765216],
                            [112.455183, 28.768237],
                            [112.450659, 28.768671],
                            [112.43798, 28.7685],
                            [112.434638, 28.765448],
                            [112.431407, 28.76503],
                            [112.428131, 28.767633],
                            [112.422471, 28.767741],
                            [112.419329, 28.769089],
                            [112.41719, 28.772311],
                            [112.414226, 28.774294],
                            [112.404912, 28.775766],
                            [112.403553, 28.775224],
                            [112.403865, 28.772699],
                            [112.406851, 28.764379],
                            [112.406472, 28.763062],
                            [112.400099, 28.76283],
                            [112.397313, 28.761807],
                            [112.396199, 28.759948],
                            [112.397469, 28.757267],
                            [112.395976, 28.753921],
                            [112.393102, 28.752201],
                            [112.392389, 28.750016],
                            [112.394439, 28.747087],
                            [112.392233, 28.741106],
                            [112.386929, 28.735511],
                            [112.379866, 28.729777],
                            [112.363911, 28.723004],
                            [112.361348, 28.721361],
                            [112.357159, 28.717223],
                            [112.350719, 28.713286],
                            [112.343923, 28.710759],
                            [112.33677, 28.70924],
                            [112.323155, 28.705101],
                            [112.319389, 28.706775],
                            [112.315846, 28.710464],
                            [112.312994, 28.711875],
                            [112.306108, 28.71158],
                            [112.299802, 28.703086],
                            [112.288371, 28.710371],
                            [112.285853, 28.713642],
                            [112.280973, 28.71806],
                            [112.278299, 28.719594],
                            [112.2744, 28.723438],
                            [112.264907, 28.719176],
                            [112.256239, 28.71761],
                            [112.245431, 28.714309],
                            [112.239437, 28.715239],
                            [112.235694, 28.713255],
                            [112.231037, 28.711844],
                            [112.232463, 28.70879],
                            [112.230858, 28.705225],
                            [112.22687, 28.702357],
                            [112.225377, 28.700419],
                            [112.219494, 28.697582],
                            [112.216508, 28.693598],
                            [112.213722, 28.692094],
                            [112.209578, 28.691784],
                            [112.207127, 28.689691],
                            [112.206013, 28.686125],
                            [112.201979, 28.680993],
                            [112.199729, 28.674092],
                            [112.198258, 28.672759],
                            [112.193645, 28.672619],
                            [112.189991, 28.669549],
                            [112.182727, 28.668355],
                            [112.179206, 28.669983],
                            [112.172142, 28.675209],
                            [112.167864, 28.679147],
                            [112.165658, 28.679473],
                            [112.161491, 28.675705],
                            [112.161067, 28.672557],
                            [112.158795, 28.667424],
                            [112.157123, 28.666959],
                            [112.154494, 28.669347],
                            [112.150995, 28.66364],
                            [112.142951, 28.66226],
                            [112.141882, 28.660818],
                            [112.143597, 28.658817],
                            [112.149458, 28.656398],
                            [112.148076, 28.652831],
                            [112.14538, 28.651264],
                            [112.139787, 28.649341],
                            [112.136979, 28.649077],
                            [112.121493, 28.650442],
                            [112.116991, 28.651373],
                            [112.107365, 28.652319],
                            [112.104201, 28.650799],
                            [112.101148, 28.645541],
                            [112.097427, 28.642175],
                            [112.09326, 28.642718],
                            [112.093237, 28.637956],
                            [112.094485, 28.633426],
                            [112.096714, 28.63127],
                            [112.099967, 28.632108],
                            [112.103109, 28.631363],
                            [112.105382, 28.62826],
                            [112.108234, 28.62688],
                            [112.110596, 28.624165],
                            [112.112067, 28.619433],
                            [112.111354, 28.6183],
                            [112.105137, 28.61422],
                            [112.104134, 28.612963],
                            [112.099856, 28.604243],
                            [112.0999, 28.599014],
                            [112.104156, 28.591379],
                            [112.105293, 28.583588],
                            [112.106184, 28.58171],
                            [112.11298, 28.578637],
                            [112.116278, 28.575999],
                            [112.118796, 28.575905],
                            [112.122027, 28.578932],
                            [112.127063, 28.579227],
                            [112.12976, 28.584255],
                            [112.131743, 28.585761],
                            [112.135731, 28.58469],
                            [112.138584, 28.582455],
                            [112.140745, 28.579165],
                            [112.147876, 28.57842],
                            [112.152444, 28.576852],
                            [112.161357, 28.579367],
                            [112.168176, 28.577675],
                            [112.172031, 28.577939],
                            [112.174125, 28.575874],
                            [112.178025, 28.57769],
                            [112.183685, 28.588492],
                            [112.188944, 28.595553],
                            [112.193601, 28.598859],
                            [112.199929, 28.600488],
                            [112.204141, 28.599309],
                            [112.210269, 28.595274],
                            [112.222747, 28.584069],
                            [112.226335, 28.581167],
                            [112.231616, 28.580965],
                            [112.237231, 28.577846],
                            [112.240908, 28.578249],
                            [112.258534, 28.588182],
                            [112.2746, 28.603064],
                            [112.283068, 28.608991],
                            [112.291803, 28.615632],
                            [112.296438, 28.617571],
                            [112.301295, 28.618331],
                            [112.305507, 28.617742],
                            [112.308359, 28.61633],
                            [112.310788, 28.612218],
                            [112.314576, 28.591581],
                            [112.316515, 28.588135],
                            [112.318698, 28.58677],
                            [112.324336, 28.585093],
                            [112.329951, 28.585109],
                            [112.351054, 28.588508],
                            [112.355599, 28.590603],
                            [112.367365, 28.597167],
                            [112.384255, 28.607983],
                            [112.401369, 28.616795],
                            [112.405558, 28.620581],
                            [112.409502, 28.62553],
                            [112.414627, 28.635102],
                            [112.41621, 28.639926],
                            [112.418482, 28.64278],
                            [112.424009, 28.647387],
                            [112.428443, 28.649992],
                            [112.43339, 28.650396],
                            [112.446136, 28.648658],
                            [112.449723, 28.647449],
                            [112.459038, 28.645603],
                            [112.467572, 28.644657],
                            [112.481767, 28.645649],
                            [112.492084, 28.646053],
                            [112.499482, 28.645743],
                            [112.501665, 28.646192],
                            [112.508707, 28.65235],
                            [112.510601, 28.6548],
                            [112.518266, 28.661655],
                            [112.521119, 28.665486],
                            [112.525308, 28.678372],
                            [112.525664, 28.682652],
                            [112.525085, 28.693474],
                            [112.526422, 28.702527],
                            [112.529029, 28.705969],
                            [112.534555, 28.709581],
                            [112.548237, 28.712356],
                            [112.552449, 28.714851],
                            [112.555836, 28.719656],
                            [112.555658, 28.721284],
                            [112.552003, 28.727638],
                            [112.552248, 28.729607],
                            [112.558086, 28.731404],
                            [112.56281, 28.729979],
                            [112.569094, 28.731637],
                            [112.573907, 28.735449],
                            [112.580592, 28.739695],
                            [112.583467, 28.74078],
                            [112.586185, 28.740362],
                            [112.589706, 28.742175],
                            [112.599132, 28.744174],
                            [112.602118, 28.741617],
                            [112.604992, 28.742593],
                            [112.603789, 28.746901],
                            [112.603678, 28.75045],
                            [112.606107, 28.758011],
                            [112.608157, 28.760521],
                            [112.613973, 28.766021],
                            [112.617493, 28.770034],
                            [112.618541, 28.772807],
                            [112.61912, 28.778554],
                            [112.621571, 28.77936]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430903,
                "name": "赫山区",
                "center": [112.360946, 28.568327],
                "centroid": [112.436827, 28.473368],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430900
                },
                "subFeatureIndex": 1,
                "acroutes": [100000, 430000, 430900]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.316849, 28.284064],
                            [112.317919, 28.284873],
                            [112.324046, 28.283955],
                            [112.329862, 28.284764],
                            [112.332937, 28.28439],
                            [112.340001, 28.279752],
                            [112.341115, 28.278336],
                            [112.346775, 28.274803],
                            [112.350251, 28.27446],
                            [112.356803, 28.277293],
                            [112.358697, 28.280453],
                            [112.358652, 28.283488],
                            [112.352859, 28.282009],
                            [112.349137, 28.281838],
                            [112.349026, 28.285013],
                            [112.345282, 28.289495],
                            [112.347087, 28.2913],
                            [112.352792, 28.291238],
                            [112.362908, 28.287488],
                            [112.366028, 28.286772],
                            [112.370329, 28.284406],
                            [112.375365, 28.287083],
                            [112.377994, 28.29004],
                            [112.378841, 28.294055],
                            [112.379955, 28.295393],
                            [112.381893, 28.294429],
                            [112.383097, 28.290476],
                            [112.387665, 28.289371],
                            [112.393748, 28.290273],
                            [112.396155, 28.293759],
                            [112.401525, 28.29446],
                            [112.404801, 28.298693],
                            [112.406316, 28.297961],
                            [112.408477, 28.294366],
                            [112.411575, 28.296172],
                            [112.412845, 28.29835],
                            [112.422293, 28.304606],
                            [112.422672, 28.305586],
                            [112.420443, 28.309632],
                            [112.420087, 28.312557],
                            [112.422872, 28.312806],
                            [112.42568, 28.309725],
                            [112.429312, 28.311126],
                            [112.432966, 28.317925],
                            [112.434393, 28.318781],
                            [112.435663, 28.315871],
                            [112.435373, 28.311966],
                            [112.438337, 28.310628],
                            [112.441857, 28.313149],
                            [112.443752, 28.317147],
                            [112.449255, 28.317879],
                            [112.453133, 28.317225],
                            [112.456564, 28.314704],
                            [112.459082, 28.314051],
                            [112.461333, 28.314922],
                            [112.466012, 28.312884],
                            [112.468441, 28.314098],
                            [112.471583, 28.317598],
                            [112.473789, 28.317272],
                            [112.477466, 28.313071],
                            [112.480608, 28.312355],
                            [112.485243, 28.309414],
                            [112.486914, 28.309243],
                            [112.49302, 28.312542],
                            [112.497276, 28.313024],
                            [112.500975, 28.311888],
                            [112.503092, 28.313506],
                            [112.503181, 28.318034],
                            [112.499303, 28.325347],
                            [112.499615, 28.327182],
                            [112.502334, 28.326451],
                            [112.505298, 28.320726],
                            [112.508061, 28.321021],
                            [112.51615, 28.324273],
                            [112.522433, 28.324958],
                            [112.525976, 28.325953],
                            [112.532305, 28.330776],
                            [112.536984, 28.332519],
                            [112.539681, 28.331087],
                            [112.53997, 28.329392],
                            [112.535781, 28.325456],
                            [112.537474, 28.323713],
                            [112.543981, 28.322671],
                            [112.54719, 28.320664],
                            [112.553919, 28.318952],
                            [112.556437, 28.320321],
                            [112.560493, 28.325393],
                            [112.562877, 28.32684],
                            [112.566064, 28.327105],
                            [112.571412, 28.324973],
                            [112.572526, 28.326451],
                            [112.56925, 28.330652],
                            [112.564437, 28.333125],
                            [112.564237, 28.337248],
                            [112.562521, 28.342754],
                            [112.564058, 28.345943],
                            [112.570832, 28.348961],
                            [112.573395, 28.351185],
                            [112.575178, 28.354591],
                            [112.575579, 28.361201],
                            [112.575445, 28.369055],
                            [112.580347, 28.372896],
                            [112.579835, 28.379614],
                            [112.578453, 28.382351],
                            [112.579612, 28.384403],
                            [112.58242, 28.393017],
                            [112.582397, 28.398863],
                            [112.584269, 28.402424],
                            [112.590575, 28.403932],
                            [112.594363, 28.4076],
                            [112.594787, 28.414611],
                            [112.599154, 28.418715],
                            [112.603745, 28.420347],
                            [112.612123, 28.424544],
                            [112.612858, 28.42672],
                            [112.624111, 28.445525],
                            [112.625694, 28.455005],
                            [112.627298, 28.468258],
                            [112.635765, 28.467854],
                            [112.639843, 28.46933],
                            [112.643899, 28.473292],
                            [112.650963, 28.481402],
                            [112.654416, 28.484509],
                            [112.651119, 28.488517],
                            [112.652166, 28.493581],
                            [112.651765, 28.495662],
                            [112.657224, 28.498598],
                            [112.660945, 28.503444],
                            [112.663218, 28.501083],
                            [112.672577, 28.51753],
                            [112.678014, 28.521179],
                            [112.6808, 28.524689],
                            [112.68539, 28.525419],
                            [112.687462, 28.534052],
                            [112.685702, 28.538804],
                            [112.685925, 28.541024],
                            [112.688265, 28.5443],
                            [112.6929, 28.547374],
                            [112.698002, 28.549128],
                            [112.701746, 28.552404],
                            [112.709389, 28.552171],
                            [112.713979, 28.555773],
                            [112.720241, 28.55495],
                            [112.726547, 28.557201],
                            [112.731316, 28.562293],
                            [112.732118, 28.564388],
                            [112.735393, 28.565925],
                            [112.736107, 28.568238],
                            [112.733901, 28.571094],
                            [112.731806, 28.575362],
                            [112.726213, 28.581695],
                            [112.724764, 28.581757],
                            [112.717857, 28.578668],
                            [112.715673, 28.580655],
                            [112.713199, 28.587158],
                            [112.71389, 28.588663],
                            [112.719617, 28.589687],
                            [112.719728, 28.594498],
                            [112.7175, 28.596189],
                            [112.711328, 28.595631],
                            [112.705356, 28.599557],
                            [112.700364, 28.599805],
                            [112.697601, 28.599014],
                            [112.694259, 28.596857],
                            [112.689334, 28.595926],
                            [112.684009, 28.596376],
                            [112.682382, 28.595351],
                            [112.681669, 28.592589],
                            [112.676878, 28.582657],
                            [112.67514, 28.581213],
                            [112.668366, 28.584255],
                            [112.662884, 28.582967],
                            [112.658695, 28.580515],
                            [112.648823, 28.564264],
                            [112.645325, 28.564016],
                            [112.642049, 28.561268],
                            [112.635454, 28.559033],
                            [112.635476, 28.55613],
                            [112.632824, 28.55006],
                            [112.629348, 28.547188],
                            [112.621593, 28.5443],
                            [112.609739, 28.540806],
                            [112.601583, 28.539906],
                            [112.594831, 28.540837],
                            [112.591912, 28.542933],
                            [112.589216, 28.551271],
                            [112.585873, 28.555059],
                            [112.582531, 28.556875],
                            [112.568025, 28.560709],
                            [112.562476, 28.563581],
                            [112.561986, 28.565382],
                            [112.563746, 28.570939],
                            [112.569785, 28.579925],
                            [112.574398, 28.585109],
                            [112.578787, 28.588399],
                            [112.583133, 28.590867],
                            [112.583266, 28.593319],
                            [112.585918, 28.594979],
                            [112.590152, 28.601698],
                            [112.585049, 28.605236],
                            [112.588236, 28.609069],
                            [112.588547, 28.61062],
                            [112.585517, 28.613723],
                            [112.585004, 28.620922],
                            [112.588726, 28.627407],
                            [112.587567, 28.629548],
                            [112.580526, 28.629734],
                            [112.576737, 28.624258],
                            [112.574063, 28.623808],
                            [112.570699, 28.625902],
                            [112.564816, 28.628198],
                            [112.563679, 28.629362],
                            [112.561964, 28.635086],
                            [112.556549, 28.64036],
                            [112.554989, 28.643571],
                            [112.552582, 28.644098],
                            [112.549463, 28.641508],
                            [112.544115, 28.639429],
                            [112.533775, 28.640422],
                            [112.53226, 28.641663],
                            [112.528205, 28.64278],
                            [112.526756, 28.646766],
                            [112.52123, 28.649124],
                            [112.518445, 28.652086],
                            [112.514902, 28.65235],
                            [112.510601, 28.6548],
                            [112.508707, 28.65235],
                            [112.501665, 28.646192],
                            [112.499482, 28.645743],
                            [112.492084, 28.646053],
                            [112.481767, 28.645649],
                            [112.467572, 28.644657],
                            [112.459038, 28.645603],
                            [112.449723, 28.647449],
                            [112.446136, 28.648658],
                            [112.43339, 28.650396],
                            [112.428443, 28.649992],
                            [112.424009, 28.647387],
                            [112.418482, 28.64278],
                            [112.41621, 28.639926],
                            [112.414627, 28.635102],
                            [112.409502, 28.62553],
                            [112.405558, 28.620581],
                            [112.401369, 28.616795],
                            [112.384255, 28.607983],
                            [112.367365, 28.597167],
                            [112.355599, 28.590603],
                            [112.351054, 28.588508],
                            [112.329951, 28.585109],
                            [112.324336, 28.585093],
                            [112.318698, 28.58677],
                            [112.316515, 28.588135],
                            [112.314576, 28.591581],
                            [112.310788, 28.612218],
                            [112.308359, 28.61633],
                            [112.305507, 28.617742],
                            [112.301295, 28.618331],
                            [112.296438, 28.617571],
                            [112.291803, 28.615632],
                            [112.283068, 28.608991],
                            [112.2746, 28.603064],
                            [112.258534, 28.588182],
                            [112.259782, 28.581213],
                            [112.260361, 28.57367],
                            [112.257219, 28.570814],
                            [112.257375, 28.56622],
                            [112.256172, 28.56296],
                            [112.256484, 28.55776],
                            [112.252495, 28.555229],
                            [112.253854, 28.547715],
                            [112.251091, 28.544005],
                            [112.250869, 28.542111],
                            [112.252807, 28.537406],
                            [112.25225, 28.535667],
                            [112.24942, 28.53385],
                            [112.245833, 28.534099],
                            [112.242824, 28.532593],
                            [112.240551, 28.529021],
                            [112.238256, 28.526894],
                            [112.237365, 28.524456],
                            [112.237164, 28.517282],
                            [112.235449, 28.51197],
                            [112.23507, 28.507528],
                            [112.232262, 28.502791],
                            [112.231393, 28.498054],
                            [112.229945, 28.495367],
                            [112.223282, 28.487383],
                            [112.220697, 28.480765],
                            [112.220363, 28.478031],
                            [112.218112, 28.475265],
                            [112.219182, 28.472733],
                            [112.222034, 28.473137],
                            [112.222591, 28.470636],
                            [112.220318, 28.466145],
                            [112.218112, 28.45979],
                            [112.218781, 28.455098],
                            [112.218313, 28.450436],
                            [112.216107, 28.445448],
                            [112.213923, 28.443567],
                            [112.208397, 28.440677],
                            [112.202314, 28.438377],
                            [112.19808, 28.434849],
                            [112.196587, 28.430124],
                            [112.196743, 28.42787],
                            [112.200709, 28.424233],
                            [112.201244, 28.421078],
                            [112.203517, 28.416477],
                            [112.213366, 28.41228],
                            [112.211873, 28.40872],
                            [112.205567, 28.402532],
                            [112.20316, 28.396656],
                            [112.203762, 28.391929],
                            [112.206726, 28.390685],
                            [112.211138, 28.390592],
                            [112.215305, 28.387886],
                            [112.217667, 28.387669],
                            [112.218981, 28.389161],
                            [112.219226, 28.392722],
                            [112.222146, 28.395707],
                            [112.225622, 28.396858],
                            [112.227026, 28.39507],
                            [112.226981, 28.391323],
                            [112.230078, 28.388229],
                            [112.232552, 28.383921],
                            [112.235003, 28.381962],
                            [112.238078, 28.382475],
                            [112.240329, 28.385072],
                            [112.242445, 28.391074],
                            [112.246635, 28.391214],
                            [112.251916, 28.388151],
                            [112.25479, 28.382429],
                            [112.257687, 28.380096],
                            [112.263949, 28.376411],
                            [112.264974, 28.374934],
                            [112.264795, 28.370968],
                            [112.268116, 28.369382],
                            [112.274845, 28.368775],
                            [112.277965, 28.370222],
                            [112.279502, 28.369584],
                            [112.282689, 28.365541],
                            [112.280438, 28.361932],
                            [112.273798, 28.358589],
                            [112.273709, 28.356411],
                            [112.276583, 28.352632],
                            [112.282644, 28.350921],
                            [112.285563, 28.348619],
                            [112.287948, 28.344497],
                            [112.29597, 28.342101],
                            [112.306309, 28.337388],
                            [112.307445, 28.336423],
                            [112.307891, 28.32768],
                            [112.30954, 28.323697],
                            [112.307512, 28.32029],
                            [112.307512, 28.317707],
                            [112.314732, 28.306582],
                            [112.317451, 28.301136],
                            [112.31725, 28.2993],
                            [112.314999, 28.296156],
                            [112.315713, 28.292312],
                            [112.315579, 28.286803],
                            [112.316849, 28.284064]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430921,
                "name": "南县",
                "center": [112.410399, 29.372181],
                "centroid": [112.437841, 29.236152],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430900
                },
                "subFeatureIndex": 2,
                "acroutes": [100000, 430000, 430900]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.798499, 29.159801],
                            [112.801218, 29.164786],
                            [112.806477, 29.171299],
                            [112.813474, 29.17866],
                            [112.81305, 29.182888],
                            [112.810376, 29.185511],
                            [112.799056, 29.190464],
                            [112.793842, 29.190942],
                            [112.78787, 29.185758],
                            [112.783369, 29.182811],
                            [112.783013, 29.183922],
                            [112.775592, 29.176592],
                            [112.770824, 29.174262],
                            [112.766434, 29.173243],
                            [112.757276, 29.170095],
                            [112.748585, 29.168351],
                            [112.741945, 29.168289],
                            [112.739939, 29.17065],
                            [112.736329, 29.170635],
                            [112.733277, 29.171839],
                            [112.73263, 29.174231],
                            [112.727594, 29.176947],
                            [112.724586, 29.177641],
                            [112.723049, 29.181159],
                            [112.715829, 29.187332],
                            [112.711239, 29.185357],
                            [112.706047, 29.186313],
                            [112.696888, 29.191035],
                            [112.693189, 29.192562],
                            [112.686705, 29.193179],
                            [112.682404, 29.192238],
                            [112.678415, 29.195926],
                            [112.677368, 29.20219],
                            [112.677569, 29.205399],
                            [112.680844, 29.212017],
                            [112.679886, 29.216212],
                            [112.676811, 29.220269],
                            [112.676388, 29.224696],
                            [112.678415, 29.227596],
                            [112.681669, 29.230418],
                            [112.682493, 29.2323],
                            [112.682716, 29.236881],
                            [112.678549, 29.237914],
                            [112.679106, 29.244777],
                            [112.688554, 29.250112],
                            [112.693679, 29.253998],
                            [112.694682, 29.257314],
                            [112.693724, 29.259457],
                            [112.691273, 29.261384],
                            [112.688421, 29.261168],
                            [112.686749, 29.262679],
                            [112.685435, 29.265886],
                            [112.682805, 29.269587],
                            [112.682137, 29.272238],
                            [112.683295, 29.274967],
                            [112.686014, 29.276833],
                            [112.690515, 29.278482],
                            [112.694704, 29.281242],
                            [112.699874, 29.287377],
                            [112.696264, 29.288703],
                            [112.689245, 29.295732],
                            [112.687552, 29.295747],
                            [112.683607, 29.293789],
                            [112.680755, 29.294961],
                            [112.677702, 29.2996],
                            [112.674672, 29.305611],
                            [112.673647, 29.306258],
                            [112.667764, 29.300725],
                            [112.664778, 29.300402],
                            [112.660834, 29.305411],
                            [112.652567, 29.321438],
                            [112.644679, 29.329867],
                            [112.641381, 29.332194],
                            [112.634428, 29.335984],
                            [112.632245, 29.336492],
                            [112.62224, 29.336261],
                            [112.617092, 29.335214],
                            [112.611789, 29.333272],
                            [112.601583, 29.326739],
                            [112.594007, 29.321269],
                            [112.590419, 29.317848],
                            [112.579411, 29.303669],
                            [112.574487, 29.301789],
                            [112.570632, 29.301912],
                            [112.560226, 29.304609],
                            [112.553942, 29.304178],
                            [112.55022, 29.301296],
                            [112.547346, 29.301789],
                            [112.532416, 29.308925],
                            [112.527269, 29.310589],
                            [112.519826, 29.31173],
                            [112.504741, 29.311498],
                            [112.499593, 29.313856],
                            [112.497008, 29.317031],
                            [112.491861, 29.329004],
                            [112.49097, 29.337602],
                            [112.488719, 29.340652],
                            [112.485755, 29.342917],
                            [112.47682, 29.347816],
                            [112.464252, 29.353485],
                            [112.457099, 29.355858],
                            [112.446804, 29.359971],
                            [112.439941, 29.360633],
                            [112.423095, 29.371723],
                            [112.415496, 29.378284],
                            [112.408878, 29.382289],
                            [112.406093, 29.385538],
                            [112.398829, 29.390836],
                            [112.392032, 29.396456],
                            [112.388512, 29.400306],
                            [112.386618, 29.406018],
                            [112.387197, 29.412639],
                            [112.388846, 29.420998],
                            [112.386172, 29.426863],
                            [112.382562, 29.430065],
                            [112.371688, 29.437331],
                            [112.368947, 29.438162],
                            [112.366785, 29.442533],
                            [112.366741, 29.449244],
                            [112.366184, 29.458416],
                            [112.362931, 29.458431],
                            [112.357538, 29.456354],
                            [112.347109, 29.450783],
                            [112.343633, 29.447797],
                            [112.342007, 29.444411],
                            [112.338441, 29.444519],
                            [112.335233, 29.445781],
                            [112.32828, 29.450059],
                            [112.327255, 29.452799],
                            [112.325851, 29.466464],
                            [112.324202, 29.474327],
                            [112.324425, 29.48042],
                            [112.326364, 29.488081],
                            [112.327723, 29.49582],
                            [112.330887, 29.505326],
                            [112.331132, 29.510587],
                            [112.320058, 29.515985],
                            [112.317473, 29.518646],
                            [112.315735, 29.524275],
                            [112.308738, 29.520953],
                            [112.298398, 29.516923],
                            [112.293942, 29.517631],
                            [112.290755, 29.516939],
                            [112.288973, 29.512401],
                            [112.286232, 29.507833],
                            [112.285875, 29.502219],
                            [112.283402, 29.495204],
                            [112.288037, 29.48662],
                            [112.291714, 29.478266],
                            [112.293229, 29.46571],
                            [112.293073, 29.459847],
                            [112.293563, 29.456354],
                            [112.295145, 29.453491],
                            [112.298777, 29.450644],
                            [112.300293, 29.448182],
                            [112.294477, 29.428526],
                            [112.293362, 29.415548],
                            [112.295546, 29.408281],
                            [112.294276, 29.406126],
                            [112.289285, 29.405772],
                            [112.284204, 29.403493],
                            [112.282421, 29.397149],
                            [112.281151, 29.395132],
                            [112.271926, 29.388079],
                            [112.268361, 29.384537],
                            [112.261698, 29.375605],
                            [112.25967, 29.366502],
                            [112.254478, 29.355303],
                            [112.252629, 29.35196],
                            [112.24971, 29.334844],
                            [112.249376, 29.328526],
                            [112.251203, 29.318926],
                            [112.253788, 29.313024],
                            [112.256907, 29.307692],
                            [112.263681, 29.298691],
                            [112.259804, 29.293327],
                            [112.258222, 29.292294],
                            [112.252718, 29.291893],
                            [112.245275, 29.292972],
                            [112.23712, 29.296163],
                            [112.234223, 29.29866],
                            [112.226624, 29.294051],
                            [112.224463, 29.294113],
                            [112.215884, 29.296934],
                            [112.213433, 29.296101],
                            [112.216396, 29.290167],
                            [112.222212, 29.287315],
                            [112.223148, 29.286005],
                            [112.223483, 29.280502],
                            [112.222881, 29.272855],
                            [112.223483, 29.266642],
                            [112.225644, 29.25867],
                            [112.228741, 29.251732],
                            [112.228474, 29.242032],
                            [112.226513, 29.228444],
                            [112.228496, 29.216058],
                            [112.233376, 29.214917],
                            [112.236407, 29.213143],
                            [112.229544, 29.204781],
                            [112.223906, 29.199058],
                            [112.216218, 29.194352],
                            [112.208129, 29.187069],
                            [112.201623, 29.179246],
                            [112.197723, 29.172456],
                            [112.196007, 29.168382],
                            [112.195785, 29.160032],
                            [112.199149, 29.15037],
                            [112.201043, 29.146017],
                            [112.197679, 29.145817],
                            [112.194604, 29.146866],
                            [112.189991, 29.145245],
                            [112.188543, 29.143347],
                            [112.189434, 29.141479],
                            [112.18774, 29.134501],
                            [112.190771, 29.132494],
                            [112.192598, 29.132386],
                            [112.207394, 29.127338],
                            [112.208196, 29.126334],
                            [112.207884, 29.122212],
                            [112.208664, 29.120019],
                            [112.211204, 29.117271],
                            [112.216196, 29.115094],
                            [112.222859, 29.118645],
                            [112.227427, 29.117024],
                            [112.231014, 29.114306],
                            [112.235738, 29.114399],
                            [112.238011, 29.109798],
                            [112.240707, 29.106076],
                            [112.244161, 29.10413],
                            [112.245275, 29.100687],
                            [112.242646, 29.096363],
                            [112.243894, 29.093135],
                            [112.243716, 29.090447],
                            [112.241777, 29.080902],
                            [112.241153, 29.074291],
                            [112.241465, 29.067911],
                            [112.243849, 29.062041],
                            [112.25401, 29.049759],
                            [112.254256, 29.048167],
                            [112.266868, 29.046097],
                            [112.274021, 29.045247],
                            [112.278366, 29.045355],
                            [112.279592, 29.047333],
                            [112.286232, 29.055212],
                            [112.289909, 29.057901],
                            [112.293028, 29.058828],
                            [112.303613, 29.059507],
                            [112.311256, 29.058889],
                            [112.318565, 29.057236],
                            [112.324113, 29.05288],
                            [112.327968, 29.049017],
                            [112.33218, 29.048615],
                            [112.366897, 29.077087],
                            [112.384278, 29.092131],
                            [112.38664, 29.094401],
                            [112.394194, 29.10847],
                            [112.394951, 29.115109],
                            [112.394862, 29.121239],
                            [112.397469, 29.127955],
                            [112.401235, 29.131568],
                            [112.40284, 29.134254],
                            [112.40226, 29.135752],
                            [112.398561, 29.139333],
                            [112.400233, 29.142343],
                            [112.40645, 29.149398],
                            [112.405937, 29.156961],
                            [112.404778, 29.160403],
                            [112.390495, 29.169292],
                            [112.390339, 29.170465],
                            [112.399074, 29.17869],
                            [112.402082, 29.1792],
                            [112.420198, 29.16744],
                            [112.423385, 29.167271],
                            [112.426059, 29.168783],
                            [112.441011, 29.184415],
                            [112.44208, 29.185958],
                            [112.447963, 29.185465],
                            [112.4616, 29.182301],
                            [112.468464, 29.181005],
                            [112.477199, 29.178721],
                            [112.482168, 29.178212],
                            [112.488563, 29.175759],
                            [112.504206, 29.171669],
                            [112.506701, 29.17062],
                            [112.52455, 29.168289],
                            [112.537474, 29.165357],
                            [112.539903, 29.161221],
                            [112.543469, 29.156884],
                            [112.548705, 29.158674],
                            [112.565328, 29.166021],
                            [112.56925, 29.165187],
                            [112.577005, 29.157192],
                            [112.570922, 29.118275],
                            [112.569562, 29.116561],
                            [112.568582, 29.110122],
                            [112.567557, 29.107867],
                            [112.561897, 29.10847],
                            [112.556237, 29.102169],
                            [112.557262, 29.096919],
                            [112.560003, 29.092162],
                            [112.563523, 29.087621],
                            [112.569629, 29.083914],
                            [112.574398, 29.086926],
                            [112.579434, 29.089027],
                            [112.591244, 29.091034],
                            [112.593695, 29.085567],
                            [112.617092, 29.089227],
                            [112.617337, 29.082431],
                            [112.64245, 29.082787],
                            [112.650851, 29.084316],
                            [112.653793, 29.083899],
                            [112.66128, 29.077195],
                            [112.670104, 29.08637],
                            [112.68568, 29.096764],
                            [112.695685, 29.101984],
                            [112.704175, 29.104516],
                            [112.70618, 29.105783],
                            [112.707205, 29.108809],
                            [112.711395, 29.11023],
                            [112.717121, 29.114276],
                            [112.725255, 29.123092],
                            [112.72989, 29.132803],
                            [112.732318, 29.140012],
                            [112.736107, 29.145137],
                            [112.74736, 29.146496],
                            [112.751036, 29.148348],
                            [112.761955, 29.149552],
                            [112.764206, 29.150308],
                            [112.771425, 29.155263],
                            [112.78074, 29.155001],
                            [112.786444, 29.15676],
                            [112.791146, 29.155201],
                            [112.798499, 29.159801]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430922,
                "name": "桃江县",
                "center": [112.139732, 28.520993],
                "centroid": [111.98392, 28.458267],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430900
                },
                "subFeatureIndex": 3,
                "acroutes": [100000, 430000, 430900]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.316849, 28.284064],
                            [112.315579, 28.286803],
                            [112.315713, 28.292312],
                            [112.314999, 28.296156],
                            [112.31725, 28.2993],
                            [112.317451, 28.301136],
                            [112.314732, 28.306582],
                            [112.307512, 28.317707],
                            [112.307512, 28.32029],
                            [112.30954, 28.323697],
                            [112.307891, 28.32768],
                            [112.307445, 28.336423],
                            [112.306309, 28.337388],
                            [112.29597, 28.342101],
                            [112.287948, 28.344497],
                            [112.285563, 28.348619],
                            [112.282644, 28.350921],
                            [112.276583, 28.352632],
                            [112.273709, 28.356411],
                            [112.273798, 28.358589],
                            [112.280438, 28.361932],
                            [112.282689, 28.365541],
                            [112.279502, 28.369584],
                            [112.277965, 28.370222],
                            [112.274845, 28.368775],
                            [112.268116, 28.369382],
                            [112.264795, 28.370968],
                            [112.264974, 28.374934],
                            [112.263949, 28.376411],
                            [112.257687, 28.380096],
                            [112.25479, 28.382429],
                            [112.251916, 28.388151],
                            [112.246635, 28.391214],
                            [112.242445, 28.391074],
                            [112.240329, 28.385072],
                            [112.238078, 28.382475],
                            [112.235003, 28.381962],
                            [112.232552, 28.383921],
                            [112.230078, 28.388229],
                            [112.226981, 28.391323],
                            [112.227026, 28.39507],
                            [112.225622, 28.396858],
                            [112.222146, 28.395707],
                            [112.219226, 28.392722],
                            [112.218981, 28.389161],
                            [112.217667, 28.387669],
                            [112.215305, 28.387886],
                            [112.211138, 28.390592],
                            [112.206726, 28.390685],
                            [112.203762, 28.391929],
                            [112.20316, 28.396656],
                            [112.205567, 28.402532],
                            [112.211873, 28.40872],
                            [112.213366, 28.41228],
                            [112.203517, 28.416477],
                            [112.201244, 28.421078],
                            [112.200709, 28.424233],
                            [112.196743, 28.42787],
                            [112.196587, 28.430124],
                            [112.19808, 28.434849],
                            [112.202314, 28.438377],
                            [112.208397, 28.440677],
                            [112.213923, 28.443567],
                            [112.216107, 28.445448],
                            [112.218313, 28.450436],
                            [112.218781, 28.455098],
                            [112.218112, 28.45979],
                            [112.220318, 28.466145],
                            [112.222591, 28.470636],
                            [112.222034, 28.473137],
                            [112.219182, 28.472733],
                            [112.218112, 28.475265],
                            [112.220363, 28.478031],
                            [112.220697, 28.480765],
                            [112.223282, 28.487383],
                            [112.229945, 28.495367],
                            [112.231393, 28.498054],
                            [112.232262, 28.502791],
                            [112.23507, 28.507528],
                            [112.235449, 28.51197],
                            [112.237164, 28.517282],
                            [112.237365, 28.524456],
                            [112.238256, 28.526894],
                            [112.240551, 28.529021],
                            [112.242824, 28.532593],
                            [112.245833, 28.534099],
                            [112.24942, 28.53385],
                            [112.25225, 28.535667],
                            [112.252807, 28.537406],
                            [112.250869, 28.542111],
                            [112.251091, 28.544005],
                            [112.253854, 28.547715],
                            [112.252495, 28.555229],
                            [112.256484, 28.55776],
                            [112.256172, 28.56296],
                            [112.257375, 28.56622],
                            [112.257219, 28.570814],
                            [112.260361, 28.57367],
                            [112.259782, 28.581213],
                            [112.258534, 28.588182],
                            [112.240908, 28.578249],
                            [112.237231, 28.577846],
                            [112.231616, 28.580965],
                            [112.226335, 28.581167],
                            [112.222747, 28.584069],
                            [112.210269, 28.595274],
                            [112.204141, 28.599309],
                            [112.199929, 28.600488],
                            [112.193601, 28.598859],
                            [112.188944, 28.595553],
                            [112.183685, 28.588492],
                            [112.178025, 28.57769],
                            [112.174125, 28.575874],
                            [112.172031, 28.577939],
                            [112.168176, 28.577675],
                            [112.161357, 28.579367],
                            [112.152444, 28.576852],
                            [112.147876, 28.57842],
                            [112.140745, 28.579165],
                            [112.138584, 28.582455],
                            [112.135731, 28.58469],
                            [112.131743, 28.585761],
                            [112.12976, 28.584255],
                            [112.127063, 28.579227],
                            [112.122027, 28.578932],
                            [112.118796, 28.575905],
                            [112.116278, 28.575999],
                            [112.11298, 28.578637],
                            [112.106184, 28.58171],
                            [112.105293, 28.583588],
                            [112.104156, 28.591379],
                            [112.0999, 28.599014],
                            [112.099856, 28.604243],
                            [112.104134, 28.612963],
                            [112.105137, 28.61422],
                            [112.111354, 28.6183],
                            [112.112067, 28.619433],
                            [112.110596, 28.624165],
                            [112.108234, 28.62688],
                            [112.105382, 28.62826],
                            [112.103109, 28.631363],
                            [112.099967, 28.632108],
                            [112.096714, 28.63127],
                            [112.094485, 28.633426],
                            [112.093237, 28.637956],
                            [112.09326, 28.642718],
                            [112.086553, 28.652676],
                            [112.083723, 28.654428],
                            [112.078263, 28.655111],
                            [112.075322, 28.659158],
                            [112.074497, 28.662477],
                            [112.074497, 28.667409],
                            [112.072848, 28.670603],
                            [112.070464, 28.672356],
                            [112.066743, 28.672712],
                            [112.062086, 28.670882],
                            [112.060036, 28.669239],
                            [112.056292, 28.670014],
                            [112.05491, 28.671596],
                            [112.051189, 28.672402],
                            [112.047468, 28.671844],
                            [112.044326, 28.67279],
                            [112.041808, 28.67172],
                            [112.035992, 28.672278],
                            [112.033429, 28.673395],
                            [112.02875, 28.672418],
                            [112.025853, 28.66927],
                            [112.027056, 28.66637],
                            [112.032092, 28.661779],
                            [112.035301, 28.659872],
                            [112.038844, 28.659345],
                            [112.040761, 28.657716],
                            [112.039312, 28.65328],
                            [112.035769, 28.644967],
                            [112.034588, 28.640019],
                            [112.031491, 28.638949],
                            [112.021174, 28.639554],
                            [112.014043, 28.640732],
                            [112.009163, 28.643757],
                            [112.004506, 28.645758],
                            [111.998445, 28.646751],
                            [111.996016, 28.655436],
                            [111.993609, 28.657654],
                            [111.992005, 28.657515],
                            [111.985142, 28.652551],
                            [111.981465, 28.652288],
                            [111.979571, 28.656212],
                            [111.980953, 28.660477],
                            [111.984095, 28.663842],
                            [111.973867, 28.667548],
                            [111.965822, 28.668944],
                            [111.960207, 28.67065],
                            [111.955015, 28.673348],
                            [111.953656, 28.671658],
                            [111.951494, 28.674402],
                            [111.946169, 28.674046],
                            [111.941667, 28.675007],
                            [111.938392, 28.674325],
                            [111.931997, 28.674371],
                            [111.923239, 28.676961],
                            [111.921412, 28.676651],
                            [111.913457, 28.673348],
                            [111.910226, 28.671472],
                            [111.909067, 28.666819],
                            [111.910137, 28.664137],
                            [111.914125, 28.663315],
                            [111.916777, 28.660771],
                            [111.918226, 28.65539],
                            [111.917668, 28.653668],
                            [111.913658, 28.647588],
                            [111.912722, 28.644998],
                            [111.910582, 28.643431],
                            [111.907396, 28.644021],
                            [111.904299, 28.643245],
                            [111.902382, 28.640127],
                            [111.901201, 28.635986],
                            [111.899196, 28.635272],
                            [111.893647, 28.637211],
                            [111.888076, 28.637103],
                            [111.88001, 28.633349],
                            [111.873102, 28.630975],
                            [111.867509, 28.631844],
                            [111.864345, 28.634574],
                            [111.85795, 28.637087],
                            [111.854763, 28.636994],
                            [111.850151, 28.633333],
                            [111.84683, 28.625794],
                            [111.845493, 28.620162],
                            [111.844045, 28.616702],
                            [111.836959, 28.614282],
                            [111.833037, 28.615787],
                            [111.828491, 28.61574],
                            [111.825394, 28.619666],
                            [111.820224, 28.622148],
                            [111.816213, 28.626414],
                            [111.814854, 28.629486],
                            [111.812759, 28.639507],
                            [111.810576, 28.640981],
                            [111.804158, 28.641198],
                            [111.800504, 28.642656],
                            [111.795646, 28.64202],
                            [111.791345, 28.643137],
                            [111.789785, 28.647076],
                            [111.787869, 28.648721],
                            [111.785151, 28.648116],
                            [111.782744, 28.645045],
                            [111.780025, 28.63566],
                            [111.776215, 28.634124],
                            [111.771825, 28.636312],
                            [111.76826, 28.639026],
                            [111.764895, 28.642718],
                            [111.762422, 28.646472],
                            [111.759079, 28.653436],
                            [111.755714, 28.656088],
                            [111.75157, 28.657639],
                            [111.747336, 28.655638],
                            [111.745487, 28.652691],
                            [111.742122, 28.651621],
                            [111.73566, 28.652567],
                            [111.730646, 28.65221],
                            [111.72717, 28.650613],
                            [111.722423, 28.646068],
                            [111.723493, 28.644021],
                            [111.727682, 28.643742],
                            [111.728284, 28.642547],
                            [111.725209, 28.639693],
                            [111.719059, 28.636451],
                            [111.718591, 28.634373],
                            [111.721153, 28.621279],
                            [111.724741, 28.616563],
                            [111.723426, 28.609829],
                            [111.716474, 28.604119],
                            [111.713488, 28.600628],
                            [111.712775, 28.596314],
                            [111.708898, 28.59425],
                            [111.707115, 28.594979],
                            [111.70277, 28.598983],
                            [111.69867, 28.594048],
                            [111.694547, 28.591006],
                            [111.688397, 28.589206],
                            [111.677367, 28.591394],
                            [111.673512, 28.591456],
                            [111.671016, 28.589113],
                            [111.670169, 28.585838],
                            [111.673712, 28.577473],
                            [111.674849, 28.572522],
                            [111.678191, 28.569076],
                            [111.68091, 28.565226],
                            [111.681155, 28.560243],
                            [111.679439, 28.557729],
                            [111.67418, 28.555617],
                            [111.672064, 28.551519],
                            [111.674515, 28.542732],
                            [111.674381, 28.535605],
                            [111.675139, 28.519487],
                            [111.673289, 28.513974],
                            [111.668743, 28.510557],
                            [111.663351, 28.509299],
                            [111.661167, 28.509718],
                            [111.65778, 28.512514],
                            [111.653301, 28.513865],
                            [111.650248, 28.511753],
                            [111.65007, 28.505634],
                            [111.651964, 28.49838],
                            [111.6529, 28.497433],
                            [111.659741, 28.494683],
                            [111.66188, 28.492757],
                            [111.661434, 28.490489],
                            [111.655997, 28.48788],
                            [111.655262, 28.482676],
                            [111.6568, 28.478248],
                            [111.654883, 28.47483],
                            [111.641402, 28.47295],
                            [111.638951, 28.470931],
                            [111.638683, 28.468585],
                            [111.641558, 28.465897],
                            [111.646549, 28.466751],
                            [111.650805, 28.463224],
                            [111.653658, 28.462214],
                            [111.658025, 28.458656],
                            [111.664487, 28.456574],
                            [111.669412, 28.452627],
                            [111.669857, 28.450498],
                            [111.667963, 28.448618],
                            [111.668409, 28.446209],
                            [111.658092, 28.447173],
                            [111.64724, 28.447499],
                            [111.641335, 28.447033],
                            [111.624734, 28.448229],
                            [111.620545, 28.447421],
                            [111.618584, 28.439775],
                            [111.616133, 28.435284],
                            [111.613838, 28.433263],
                            [111.607687, 28.429642],
                            [111.607398, 28.426767],
                            [111.610384, 28.423285],
                            [111.611052, 28.421093],
                            [111.608735, 28.416073],
                            [111.609225, 28.412622],
                            [111.613637, 28.407663],
                            [111.614796, 28.404413],
                            [111.622417, 28.403776],
                            [111.634494, 28.400713],
                            [111.639084, 28.400651],
                            [111.642271, 28.397697],
                            [111.644477, 28.397651],
                            [111.65036, 28.404071],
                            [111.652432, 28.404258],
                            [111.653546, 28.399812],
                            [111.655284, 28.397977],
                            [111.657156, 28.398008],
                            [111.663997, 28.400045],
                            [111.667273, 28.399221],
                            [111.667941, 28.396003],
                            [111.666872, 28.392256],
                            [111.661212, 28.38445],
                            [111.659563, 28.379008],
                            [111.660967, 28.372057],
                            [111.657802, 28.367002],
                            [111.659674, 28.365447],
                            [111.664465, 28.365649],
                            [111.67115, 28.368091],
                            [111.67418, 28.368169],
                            [111.678637, 28.370144],
                            [111.686146, 28.367438],
                            [111.689177, 28.367189],
                            [111.698135, 28.369864],
                            [111.701656, 28.37173],
                            [111.709254, 28.372461],
                            [111.720262, 28.375851],
                            [111.72454, 28.375556],
                            [111.728061, 28.373036],
                            [111.731181, 28.372228],
                            [111.745954, 28.373798],
                            [111.750456, 28.3747],
                            [111.759391, 28.379117],
                            [111.763447, 28.3819],
                            [111.767079, 28.385414],
                            [111.773028, 28.387078],
                            [111.776549, 28.394168],
                            [111.785061, 28.398708],
                            [111.789808, 28.399221],
                            [111.795735, 28.399081],
                            [111.801974, 28.400294],
                            [111.805495, 28.405222],
                            [111.807813, 28.405999],
                            [111.811467, 28.400449],
                            [111.811957, 28.396034],
                            [111.807545, 28.386005],
                            [111.80857, 28.382724],
                            [111.81403, 28.382926],
                            [111.814765, 28.382258],
                            [111.812782, 28.378837],
                            [111.812291, 28.373658],
                            [111.813807, 28.370968],
                            [111.81608, 28.370455],
                            [111.821829, 28.373798],
                            [111.82994, 28.381853],
                            [111.833683, 28.382864],
                            [111.838608, 28.382786],
                            [111.84224, 28.381978],
                            [111.846095, 28.380174],
                            [111.850284, 28.381216],
                            [111.856523, 28.38078],
                            [111.862429, 28.381309],
                            [111.873347, 28.384217],
                            [111.87874, 28.387404],
                            [111.882572, 28.387187],
                            [111.884868, 28.384994],
                            [111.889057, 28.37568],
                            [111.8928, 28.372212],
                            [111.898817, 28.369102],
                            [111.901201, 28.360657],
                            [111.897235, 28.356582],
                            [111.896878, 28.354825],
                            [111.899396, 28.350578],
                            [111.902694, 28.348385],
                            [111.906928, 28.339814],
                            [111.909669, 28.339519],
                            [111.915128, 28.340966],
                            [111.921969, 28.341246],
                            [111.927696, 28.342739],
                            [111.931974, 28.345352],
                            [111.936319, 28.346877],
                            [111.942938, 28.347452],
                            [111.945768, 28.347017],
                            [111.947327, 28.34473],
                            [111.947907, 28.340406],
                            [111.949556, 28.338306],
                            [111.950224, 28.335241],
                            [111.948865, 28.330978],
                            [111.954436, 28.32824],
                            [111.957555, 28.328551],
                            [111.962792, 28.332176],
                            [111.965845, 28.331694],
                            [111.968964, 28.324304],
                            [111.973933, 28.321862],
                            [111.97556, 28.317956],
                            [111.975293, 28.314549],
                            [111.968764, 28.304777],
                            [111.968184, 28.297261],
                            [111.965377, 28.290491],
                            [111.965065, 28.282507],
                            [111.965845, 28.278087],
                            [111.962614, 28.274678],
                            [111.961834, 28.27018],
                            [111.959293, 28.265152],
                            [111.959561, 28.263284],
                            [111.962123, 28.258909],
                            [111.962658, 28.254488],
                            [111.969365, 28.252573],
                            [111.97244, 28.249833],
                            [111.978323, 28.242624],
                            [111.98434, 28.238747],
                            [111.987103, 28.234419],
                            [111.986479, 28.228984],
                            [111.984184, 28.225122],
                            [111.98434, 28.223113],
                            [111.986613, 28.224172],
                            [111.996217, 28.223954],
                            [112.003503, 28.224515],
                            [112.007046, 28.225932],
                            [112.007782, 28.229109],
                            [112.005732, 28.231304],
                            [112.003993, 28.23501],
                            [112.005553, 28.239448],
                            [112.009653, 28.244041],
                            [112.017608, 28.247062],
                            [112.023112, 28.24742],
                            [112.026589, 28.250393],
                            [112.033118, 28.252464],
                            [112.043078, 28.251639],
                            [112.046242, 28.253056],
                            [112.051702, 28.251296],
                            [112.056671, 28.254784],
                            [112.06291, 28.253601],
                            [112.065361, 28.255126],
                            [112.070999, 28.255765],
                            [112.075054, 28.257415],
                            [112.080647, 28.260808],
                            [112.081739, 28.262474],
                            [112.08116, 28.266942],
                            [112.082252, 28.267876],
                            [112.090341, 28.264809],
                            [112.093438, 28.265012],
                            [112.096825, 28.268825],
                            [112.099076, 28.270055],
                            [112.103688, 28.268779],
                            [112.112913, 28.271674],
                            [112.116278, 28.271705],
                            [112.123164, 28.266164],
                            [112.125882, 28.265837],
                            [112.131297, 28.266506],
                            [112.137314, 28.266615],
                            [112.142662, 28.263003],
                            [112.144355, 28.257508],
                            [112.146205, 28.254535],
                            [112.149837, 28.253134],
                            [112.155965, 28.249849],
                            [112.16207, 28.247264],
                            [112.169513, 28.245598],
                            [112.171006, 28.244633],
                            [112.173189, 28.24046],
                            [112.17798, 28.235742],
                            [112.183373, 28.234403],
                            [112.190459, 28.234465],
                            [112.195517, 28.233235],
                            [112.201378, 28.235197],
                            [112.204319, 28.238778],
                            [112.210113, 28.239246],
                            [112.213366, 28.238358],
                            [112.223282, 28.233936],
                            [112.226691, 28.227193],
                            [112.227271, 28.22126],
                            [112.228519, 28.218846],
                            [112.231371, 28.216386],
                            [112.235983, 28.213941],
                            [112.237944, 28.210452],
                            [112.241688, 28.212959],
                            [112.242535, 28.216292],
                            [112.245855, 28.219874],
                            [112.251693, 28.22439],
                            [112.252473, 28.228221],
                            [112.255726, 28.233469],
                            [112.264439, 28.23568],
                            [112.265999, 28.236754],
                            [112.269297, 28.241597],
                            [112.272082, 28.242905],
                            [112.27616, 28.247342],
                            [112.280639, 28.248447],
                            [112.279547, 28.249771],
                            [112.280683, 28.252635],
                            [112.284249, 28.255017],
                            [112.288304, 28.254068],
                            [112.29031, 28.252246],
                            [112.292248, 28.248572],
                            [112.29412, 28.247093],
                            [112.295725, 28.248011],
                            [112.299802, 28.252542],
                            [112.30163, 28.253663],
                            [112.301674, 28.256667],
                            [112.305485, 28.25933],
                            [112.306443, 28.261353],
                            [112.316426, 28.262318],
                            [112.320258, 28.261851],
                            [112.322464, 28.263237],
                            [112.322843, 28.267891],
                            [112.319144, 28.274678],
                            [112.316849, 28.284064]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430923,
                "name": "安化县",
                "center": [111.221824, 28.377421],
                "centroid": [111.384121, 28.280621],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430900
                },
                "subFeatureIndex": 4,
                "acroutes": [100000, 430000, 430900]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [111.673512, 28.591456],
                            [111.668543, 28.595615],
                            [111.663663, 28.607889],
                            [111.665245, 28.614623],
                            [111.66451, 28.621589],
                            [111.662861, 28.624646],
                            [111.657334, 28.626383],
                            [111.650025, 28.623808],
                            [111.644945, 28.62373],
                            [111.641023, 28.628028],
                            [111.636076, 28.628478],
                            [111.631241, 28.625608],
                            [111.62899, 28.621078],
                            [111.6244, 28.618331],
                            [111.619765, 28.619542],
                            [111.615598, 28.621605],
                            [111.605415, 28.624925],
                            [111.597682, 28.628726],
                            [111.592513, 28.632635],
                            [111.586786, 28.635133],
                            [111.574486, 28.639305],
                            [111.570274, 28.639647],
                            [111.567221, 28.638762],
                            [111.561049, 28.631797],
                            [111.557127, 28.628943],
                            [111.549484, 28.620814],
                            [111.546632, 28.617245],
                            [111.539234, 28.60972],
                            [111.528827, 28.602629],
                            [111.526688, 28.600162],
                            [111.518666, 28.595522],
                            [111.509352, 28.585683],
                            [111.505319, 28.583774],
                            [111.496539, 28.581213],
                            [111.494222, 28.581245],
                            [111.493397, 28.575176],
                            [111.491481, 28.571637],
                            [111.483392, 28.568253],
                            [111.480829, 28.565801],
                            [111.477576, 28.561252],
                            [111.467838, 28.556906],
                            [111.459727, 28.557434],
                            [111.450324, 28.55703],
                            [111.443371, 28.557294],
                            [111.437934, 28.554407],
                            [111.437489, 28.551146],
                            [111.435751, 28.548926],
                            [111.431383, 28.547436],
                            [111.426035, 28.547048],
                            [111.420843, 28.547871],
                            [111.412264, 28.547855],
                            [111.408654, 28.546861],
                            [111.403841, 28.542406],
                            [111.399295, 28.540574],
                            [111.396577, 28.540791],
                            [111.391117, 28.545185],
                            [111.383207, 28.550556],
                            [111.37387, 28.551829],
                            [111.371152, 28.549578],
                            [111.365336, 28.54253],
                            [111.363375, 28.539518],
                            [111.359186, 28.536738],
                            [111.356267, 28.536071],
                            [111.352545, 28.533245],
                            [111.349693, 28.52941],
                            [111.348156, 28.528695],
                            [111.339242, 28.527857],
                            [111.334362, 28.52031],
                            [111.328613, 28.519999],
                            [111.324134, 28.518834],
                            [111.320458, 28.5209],
                            [111.319165, 28.522577],
                            [111.315689, 28.524425],
                            [111.30916, 28.520978],
                            [111.298264, 28.521148],
                            [111.293651, 28.519906],
                            [111.287902, 28.517344],
                            [111.285027, 28.517002],
                            [111.281039, 28.517918],
                            [111.273953, 28.523183],
                            [111.269139, 28.522701],
                            [111.266599, 28.523524],
                            [111.264683, 28.526677],
                            [111.258332, 28.527236],
                            [111.255123, 28.531335],
                            [111.252628, 28.531537],
                            [111.245943, 28.527857],
                            [111.235626, 28.524736],
                            [111.232417, 28.522639],
                            [111.228651, 28.522453],
                            [111.221275, 28.527701],
                            [111.221164, 28.533276],
                            [111.217398, 28.536242],
                            [111.21517, 28.536211],
                            [111.211849, 28.532437],
                            [111.20151, 28.527655],
                            [111.198257, 28.526552],
                            [111.197499, 28.522919],
                            [111.192218, 28.518834],
                            [111.184798, 28.518834],
                            [111.180341, 28.514905],
                            [111.175194, 28.513896],
                            [111.16764, 28.517685],
                            [111.162403, 28.515589],
                            [111.153601, 28.515154],
                            [111.152175, 28.514331],
                            [111.152665, 28.509858],
                            [111.152264, 28.50596],
                            [111.154715, 28.501005],
                            [111.154359, 28.496532],
                            [111.149078, 28.490256],
                            [111.147875, 28.487864],
                            [111.148342, 28.483732],
                            [111.146627, 28.480874],
                            [111.145624, 28.474597],
                            [111.144933, 28.473727],
                            [111.140499, 28.474582],
                            [111.13397, 28.473292],
                            [111.128756, 28.470729],
                            [111.126349, 28.470527],
                            [111.121157, 28.46714],
                            [111.120199, 28.465648],
                            [111.116656, 28.46439],
                            [111.109325, 28.468134],
                            [111.101949, 28.466736],
                            [111.096868, 28.463628],
                            [111.093526, 28.456962],
                            [111.090986, 28.455424],
                            [111.083788, 28.453699],
                            [111.076546, 28.454119],
                            [111.068858, 28.451399],
                            [111.062708, 28.451446],
                            [111.05843, 28.453342],
                            [111.053572, 28.457382],
                            [111.05023, 28.458889],
                            [111.044102, 28.458485],
                            [111.039668, 28.455921],
                            [111.03481, 28.454911],
                            [111.027345, 28.450374],
                            [111.025027, 28.447421],
                            [111.020103, 28.445665],
                            [111.007758, 28.445557],
                            [111.001385, 28.440133],
                            [110.992828, 28.441718],
                            [110.987792, 28.440786],
                            [110.981598, 28.440568],
                            [110.978723, 28.439511],
                            [110.972372, 28.433046],
                            [110.967381, 28.430342],
                            [110.95459, 28.427963],
                            [110.947883, 28.42815],
                            [110.944608, 28.426471],
                            [110.940374, 28.421762],
                            [110.931015, 28.421295],
                            [110.925912, 28.418715],
                            [110.919962, 28.41758],
                            [110.907016, 28.411098],
                            [110.89915, 28.409419],
                            [110.894203, 28.407756],
                            [110.889479, 28.404895],
                            [110.887139, 28.405284],
                            [110.882259, 28.408082],
                            [110.879028, 28.407958],
                            [110.875619, 28.406745],
                            [110.865079, 28.40746],
                            [110.860466, 28.406046],
                            [110.858193, 28.402252],
                            [110.853915, 28.398444],
                            [110.846339, 28.398832],
                            [110.838451, 28.395723],
                            [110.835866, 28.393888],
                            [110.833348, 28.390359],
                            [110.83259, 28.387513],
                            [110.832768, 28.380516],
                            [110.83083, 28.376318],
                            [110.825905, 28.371715],
                            [110.812067, 28.363006],
                            [110.793973, 28.354358],
                            [110.782654, 28.35047],
                            [110.769908, 28.345399],
                            [110.766498, 28.343408],
                            [110.758855, 28.342366],
                            [110.75324, 28.338679],
                            [110.752326, 28.334945],
                            [110.750477, 28.331663],
                            [110.744059, 28.328054],
                            [110.741073, 28.325487],
                            [110.736862, 28.319559],
                            [110.73704, 28.315576],
                            [110.733163, 28.311344],
                            [110.727124, 28.309321],
                            [110.724249, 28.306255],
                            [110.721085, 28.300793],
                            [110.728015, 28.29281],
                            [110.732049, 28.288779],
                            [110.733475, 28.285153],
                            [110.739536, 28.281589],
                            [110.742098, 28.281044],
                            [110.749697, 28.280904],
                            [110.765473, 28.274273],
                            [110.77022, 28.274771],
                            [110.774609, 28.27309],
                            [110.783322, 28.278227],
                            [110.787823, 28.278429],
                            [110.793015, 28.275908],
                            [110.802708, 28.264358],
                            [110.80409, 28.261167],
                            [110.803956, 28.257633],
                            [110.806296, 28.253009],
                            [110.788559, 28.233515],
                            [110.787868, 28.231242],
                            [110.78945, 28.229405],
                            [110.792347, 28.228346],
                            [110.799856, 28.223176],
                            [110.802486, 28.215358],
                            [110.80458, 28.213255],
                            [110.810017, 28.211729],
                            [110.815722, 28.206605],
                            [110.820847, 28.205966],
                            [110.824613, 28.203786],
                            [110.826797, 28.209066],
                            [110.83005, 28.209813],
                            [110.833192, 28.209237],
                            [110.836378, 28.210109],
                            [110.840055, 28.214641],
                            [110.844512, 28.215093],
                            [110.848055, 28.213022],
                            [110.846562, 28.209066],
                            [110.846562, 28.206589],
                            [110.848901, 28.204705],
                            [110.853715, 28.20444],
                            [110.858528, 28.205141],
                            [110.861425, 28.203552],
                            [110.860511, 28.198599],
                            [110.855898, 28.193334],
                            [110.853247, 28.188396],
                            [110.851665, 28.18165],
                            [110.849102, 28.177273],
                            [110.849147, 28.172287],
                            [110.84801, 28.170527],
                            [110.841994, 28.164621],
                            [110.836713, 28.157391],
                            [110.829626, 28.152296],
                            [110.821538, 28.143397],
                            [110.818641, 28.139673],
                            [110.81902, 28.137693],
                            [110.821137, 28.135465],
                            [110.826284, 28.131724],
                            [110.828401, 28.12803],
                            [110.834105, 28.125334],
                            [110.833014, 28.118865],
                            [110.835242, 28.118724],
                            [110.840523, 28.120065],
                            [110.84186, 28.119675],
                            [110.844333, 28.116371],
                            [110.843709, 28.114781],
                            [110.834997, 28.104725],
                            [110.824724, 28.089914],
                            [110.823454, 28.08745],
                            [110.826975, 28.086936],
                            [110.833192, 28.087466],
                            [110.843308, 28.083817],
                            [110.854294, 28.077892],
                            [110.859909, 28.076457],
                            [110.868043, 28.078562],
                            [110.871474, 28.082367],
                            [110.874059, 28.083193],
                            [110.876377, 28.081821],
                            [110.889501, 28.067864],
                            [110.895384, 28.060565],
                            [110.89534, 28.057212],
                            [110.88988, 28.054872],
                            [110.884087, 28.050989],
                            [110.882415, 28.045997],
                            [110.872811, 28.036669],
                            [110.866438, 28.025468],
                            [110.866773, 28.021567],
                            [110.86577, 28.015638],
                            [110.866193, 28.01375],
                            [110.869914, 28.011488],
                            [110.873591, 28.011831],
                            [110.876265, 28.013563],
                            [110.878159, 28.013079],
                            [110.886203, 28.005418],
                            [110.890103, 28.000721],
                            [110.894894, 27.998864],
                            [110.899039, 27.998598],
                            [110.904164, 27.999597],
                            [110.907684, 27.998208],
                            [110.911517, 27.998832],
                            [110.920831, 27.998879],
                            [110.922057, 28.000643],
                            [110.917422, 28.005761],
                            [110.917912, 28.006822],
                            [110.92199, 28.004107],
                            [110.924954, 28.004466],
                            [110.922168, 28.012814],
                            [110.923283, 28.01531],
                            [110.927026, 28.016075],
                            [110.927806, 28.01751],
                            [110.925444, 28.01943],
                            [110.925778, 28.021317],
                            [110.931817, 28.020257],
                            [110.937232, 28.018119],
                            [110.938056, 28.018509],
                            [110.939148, 28.023736],
                            [110.938636, 28.028089],
                            [110.941287, 28.032644],
                            [110.943003, 28.038354],
                            [110.954635, 28.049179],
                            [110.955727, 28.050739],
                            [110.952295, 28.054186],
                            [110.953454, 28.057103],
                            [110.958869, 28.055871],
                            [110.962679, 28.058101],
                            [110.96444, 28.063575],
                            [110.969074, 28.066944],
                            [110.970924, 28.065821],
                            [110.970835, 28.056042],
                            [110.972283, 28.053983],
                            [110.97674, 28.053157],
                            [110.983848, 28.053094],
                            [110.99216, 28.054046],
                            [110.99519, 28.053001],
                            [111.000917, 28.048758],
                            [111.008248, 28.045857],
                            [111.010477, 28.044515],
                            [111.022019, 28.041536],
                            [111.021551, 28.040054],
                            [111.017629, 28.037808],
                            [111.014086, 28.033596],
                            [111.012905, 28.031084],
                            [111.013217, 28.028915],
                            [111.017674, 28.027387],
                            [111.022019, 28.027527],
                            [111.024493, 28.028713],
                            [111.02964, 28.03319],
                            [111.035033, 28.033705],
                            [111.037885, 28.030897],
                            [111.040046, 28.024204],
                            [111.041562, 28.022285],
                            [111.048291, 28.018244],
                            [111.051745, 28.01453],
                            [111.056112, 28.013376],
                            [111.063221, 28.007228],
                            [111.065115, 28.003295],
                            [111.068101, 28.00058],
                            [111.070619, 27.995259],
                            [111.074407, 27.994135],
                            [111.078685, 27.994385],
                            [111.081961, 27.995618],
                            [111.08537, 27.9998],
                            [111.093816, 28.000627],
                            [111.097225, 28.002765],
                            [111.097893, 28.006791],
                            [111.095242, 28.011332],
                            [111.094373, 28.014296],
                            [111.095531, 28.021832],
                            [111.092947, 28.025811],
                            [111.093793, 28.027153],
                            [111.099988, 28.029649],
                            [111.102551, 28.031256],
                            [111.10128, 28.03319],
                            [111.110105, 28.042488],
                            [111.110996, 28.047573],
                            [111.11338, 28.049554],
                            [111.118461, 28.050178],
                            [111.118372, 28.052517],
                            [111.113759, 28.060472],
                            [111.114182, 28.064121],
                            [111.120399, 28.066804],
                            [111.123274, 28.068769],
                            [111.127017, 28.074976],
                            [111.137869, 28.085969],
                            [111.138961, 28.088308],
                            [111.138248, 28.092222],
                            [111.140343, 28.094482],
                            [111.140944, 28.103432],
                            [111.143908, 28.106659],
                            [111.145602, 28.110431],
                            [111.149323, 28.116527],
                            [111.150326, 28.122138],
                            [111.151574, 28.12298],
                            [111.158058, 28.122123],
                            [111.160932, 28.12284],
                            [111.162381, 28.124726],
                            [111.16423, 28.129651],
                            [111.171562, 28.134795],
                            [111.175862, 28.138426],
                            [111.185711, 28.149506],
                            [111.189544, 28.154976],
                            [111.188786, 28.15803],
                            [111.186514, 28.160617],
                            [111.18863, 28.161723],
                            [111.191304, 28.161536],
                            [111.194981, 28.159994],
                            [111.20278, 28.160539],
                            [111.206301, 28.159542],
                            [111.212273, 28.15976],
                            [111.216039, 28.159324],
                            [111.22103, 28.162533],
                            [111.225153, 28.161505],
                            [111.226645, 28.157516],
                            [111.229475, 28.154119],
                            [111.235648, 28.154119],
                            [111.245141, 28.159885],
                            [111.248305, 28.16096],
                            [111.257775, 28.167535],
                            [111.260382, 28.172708],
                            [111.263725, 28.175076],
                            [111.274554, 28.187025],
                            [111.279055, 28.191013],
                            [111.281328, 28.194658],
                            [111.283601, 28.200484],
                            [111.284403, 28.211168],
                            [111.285183, 28.214813],
                            [111.289439, 28.220279],
                            [111.296035, 28.225465],
                            [111.314374, 28.234216],
                            [111.318987, 28.229171],
                            [111.321015, 28.227879],
                            [111.325984, 28.226539],
                            [111.328925, 28.222101],
                            [111.33102, 28.220404],
                            [111.332335, 28.213613],
                            [111.336791, 28.210343],
                            [111.33746, 28.204393],
                            [111.333961, 28.200904],
                            [111.332112, 28.195935],
                            [111.334139, 28.193007],
                            [111.338507, 28.192508],
                            [111.341849, 28.193147],
                            [111.352055, 28.199082],
                            [111.359453, 28.199175],
                            [111.382895, 28.196309],
                            [111.385591, 28.193879],
                            [111.386527, 28.191028],
                            [111.386171, 28.181635],
                            [111.388443, 28.177771],
                            [111.391474, 28.177693],
                            [111.398025, 28.180046],
                            [111.410571, 28.188318],
                            [111.426147, 28.200219],
                            [111.434859, 28.208116],
                            [111.436642, 28.208365],
                            [111.443906, 28.206932],
                            [111.44658, 28.205452],
                            [111.448474, 28.202898],
                            [111.445823, 28.194253],
                            [111.445332, 28.189502],
                            [111.448251, 28.186854],
                            [111.458301, 28.191698],
                            [111.470223, 28.195889],
                            [111.477465, 28.199347],
                            [111.483147, 28.204191],
                            [111.485799, 28.203178],
                            [111.48776, 28.198116],
                            [111.495715, 28.189954],
                            [111.494756, 28.186402],
                            [111.494578, 28.180887],
                            [111.49362, 28.17908],
                            [111.490679, 28.17788],
                            [111.489431, 28.173643],
                            [111.489966, 28.168174],
                            [111.488918, 28.165198],
                            [111.486088, 28.161302],
                            [111.485754, 28.159495],
                            [111.49001, 28.15574],
                            [111.490968, 28.153013],
                            [111.489765, 28.150737],
                            [111.48903, 28.146327],
                            [111.486579, 28.144847],
                            [111.481387, 28.138348],
                            [111.480807, 28.134389],
                            [111.482724, 28.128311],
                            [111.481854, 28.122731],
                            [111.482657, 28.120424],
                            [111.497185, 28.104757],
                            [111.500439, 28.10036],
                            [111.500751, 28.096384],
                            [111.499503, 28.094373],
                            [111.500394, 28.092643],
                            [111.503536, 28.091582],
                            [111.507725, 28.092455],
                            [111.511268, 28.091598],
                            [111.510979, 28.090257],
                            [111.495202, 28.077112],
                            [111.493286, 28.074898],
                            [111.49186, 28.066851],
                            [111.492706, 28.063497],
                            [111.494756, 28.049211],
                            [111.49157, 28.043517],
                            [111.494801, 28.03787],
                            [111.501976, 28.041645],
                            [111.507658, 28.03982],
                            [111.514588, 28.034407],
                            [111.517864, 28.03099],
                            [111.521207, 28.029368],
                            [111.523658, 28.029446],
                            [111.524415, 28.031209],
                            [111.52241, 28.034173],
                            [111.523435, 28.039664],
                            [111.522655, 28.043174],
                            [111.520872, 28.046184],
                            [111.516037, 28.05063],
                            [111.508683, 28.052408],
                            [111.506901, 28.053344],
                            [111.507012, 28.056931],
                            [111.508059, 28.059536],
                            [111.51207, 28.064262],
                            [111.514722, 28.065307],
                            [111.518221, 28.064168],
                            [111.521496, 28.060363],
                            [111.524794, 28.052595],
                            [111.527891, 28.049382],
                            [111.529919, 28.05024],
                            [111.533373, 28.053921],
                            [111.537897, 28.052548],
                            [111.543935, 28.047526],
                            [111.545428, 28.043033],
                            [111.542264, 28.039726],
                            [111.540704, 28.035546],
                            [111.540727, 28.030944],
                            [111.541662, 28.025249],
                            [111.544381, 28.020116],
                            [111.547501, 28.021505],
                            [111.549952, 28.02411],
                            [111.554475, 28.02578],
                            [111.557996, 28.028338],
                            [111.561717, 28.029337],
                            [111.566152, 28.032769],
                            [111.56809, 28.032597],
                            [111.57014, 28.02982],
                            [111.573416, 28.0289],
                            [111.575934, 28.021255],
                            [111.5758, 28.018883],
                            [111.57297, 28.018384],
                            [111.568803, 28.019258],
                            [111.564258, 28.018135],
                            [111.561584, 28.014655],
                            [111.557105, 28.010349],
                            [111.55706, 28.007883],
                            [111.558397, 28.00626],
                            [111.558041, 28.003686],
                            [111.555723, 28.001158],
                            [111.551423, 27.991888],
                            [111.546832, 27.992153],
                            [111.542064, 27.994135],
                            [111.539991, 27.994182],
                            [111.53627, 27.992496],
                            [111.529518, 27.986566],
                            [111.529407, 27.985146],
                            [111.532058, 27.983132],
                            [111.534576, 27.983366],
                            [111.537785, 27.981759],
                            [111.541618, 27.978559],
                            [111.550241, 27.974829],
                            [111.551913, 27.975156],
                            [111.56038, 27.982274],
                            [111.561962, 27.982602],
                            [111.567957, 27.987034],
                            [111.570898, 27.987658],
                            [111.574285, 27.990046],
                            [111.580613, 27.991872],
                            [111.5838, 27.994463],
                            [111.587945, 28.001002],
                            [111.59278, 28.006463],
                            [111.597081, 28.007899],
                            [111.599777, 28.009709],
                            [111.600067, 28.013391],
                            [111.597549, 28.015654],
                            [111.592557, 28.016231],
                            [111.588992, 28.018618],
                            [111.587833, 28.023486],
                            [111.589839, 28.027121],
                            [111.597214, 28.029742],
                            [111.601693, 28.034532],
                            [111.603209, 28.034235],
                            [111.608712, 28.026435],
                            [111.613526, 28.023642],
                            [111.619965, 28.016902],
                            [111.621726, 28.016059],
                            [111.628299, 28.019258],
                            [111.632043, 28.015529],
                            [111.634271, 28.01531],
                            [111.645703, 28.022066],
                            [111.651496, 28.026232],
                            [111.659117, 28.0267],
                            [111.661546, 28.025826],
                            [111.663262, 28.023268],
                            [111.662861, 28.021083],
                            [111.660632, 28.01996],
                            [111.656086, 28.021754],
                            [111.651585, 28.021146],
                            [111.651251, 28.018681],
                            [111.652298, 28.015747],
                            [111.655708, 28.014749],
                            [111.658827, 28.015045],
                            [111.662972, 28.014218],
                            [111.664821, 28.017401],
                            [111.665133, 28.024453],
                            [111.670637, 28.029805],
                            [111.67193, 28.034547],
                            [111.674582, 28.037995],
                            [111.679372, 28.037917],
                            [111.68403, 28.040772],
                            [111.685456, 28.040491],
                            [111.685344, 28.036263],
                            [111.683071, 28.025951],
                            [111.683495, 28.014499],
                            [111.684408, 28.0135],
                            [111.691316, 28.009896],
                            [111.691049, 28.007883],
                            [111.684921, 28.002968],
                            [111.68501, 27.994338],
                            [111.686057, 27.991825],
                            [111.688486, 27.990608],
                            [111.692787, 27.991373],
                            [111.697689, 27.994166],
                            [111.700876, 27.994088],
                            [111.704842, 27.991154],
                            [111.708831, 27.990795],
                            [111.71195, 27.993433],
                            [111.715137, 27.993339],
                            [111.721599, 27.984911],
                            [111.724251, 27.982945],
                            [111.726769, 27.98346],
                            [111.730468, 27.987518],
                            [111.734234, 27.988158],
                            [111.737509, 27.991981],
                            [111.743392, 27.994229],
                            [111.74747, 28.003202],
                            [111.751481, 28.005761],
                            [111.755247, 28.00431],
                            [111.761374, 28.005761],
                            [111.76563, 28.00612],
                            [111.770666, 28.009834],
                            [111.773875, 28.010676],
                            [111.777062, 28.009568],
                            [111.779446, 28.007072],
                            [111.779602, 28.004029],
                            [111.778577, 28.000174],
                            [111.778889, 27.996648],
                            [111.782833, 27.992949],
                            [111.789317, 27.990249],
                            [111.793083, 27.989718],
                            [111.795468, 27.99067],
                            [111.796916, 27.992668],
                            [111.796114, 28.005184],
                            [111.798587, 28.008258],
                            [111.804515, 28.009225],
                            [111.808526, 28.011675],
                            [111.812603, 28.015217],
                            [111.817751, 28.016543],
                            [111.820625, 28.018462],
                            [111.823388, 28.022612],
                            [111.826798, 28.023455],
                            [111.829962, 28.02308],
                            [111.83834, 28.019944],
                            [111.843755, 28.023845],
                            [111.848368, 28.02865],
                            [111.850128, 28.029586],
                            [111.854251, 28.029477],
                            [111.858975, 28.030881],
                            [111.863699, 28.028588],
                            [111.866729, 28.028525],
                            [111.870161, 28.026669],
                            [111.874952, 28.027075],
                            [111.882506, 28.026372],
                            [111.887051, 28.027418],
                            [111.89182, 28.026965],
                            [111.902583, 28.022004],
                            [111.91085, 28.02631],
                            [111.9131, 28.027964],
                            [111.91339, 28.030054],
                            [111.912053, 28.033081],
                            [111.909223, 28.035358],
                            [111.908109, 28.037449],
                            [111.90753, 28.041832],
                            [111.911073, 28.052315],
                            [111.917446, 28.058288],
                            [111.919251, 28.061922],
                            [111.92627, 28.065494],
                            [111.926158, 28.067506],
                            [111.921234, 28.06894],
                            [111.917067, 28.074445],
                            [111.917022, 28.07772],
                            [111.914081, 28.079342],
                            [111.915262, 28.082804],
                            [111.910003, 28.087902],
                            [111.901825, 28.094077],
                            [111.899173, 28.099815],
                            [111.902895, 28.103712],
                            [111.908844, 28.116059],
                            [111.913167, 28.119753],
                            [111.916064, 28.121219],
                            [111.926359, 28.128217],
                            [111.931863, 28.133329],
                            [111.933222, 28.137538],
                            [111.933334, 28.143491],
                            [111.931239, 28.147106],
                            [111.931105, 28.150395],
                            [111.932687, 28.153106],
                            [111.934091, 28.158763],
                            [111.933222, 28.168143],
                            [111.935406, 28.170464],
                            [111.938637, 28.170511],
                            [111.941801, 28.172272],
                            [111.944921, 28.178971],
                            [111.944141, 28.180747],
                            [111.939796, 28.183987],
                            [111.938503, 28.186495],
                            [111.944765, 28.186745],
                            [111.949868, 28.191916],
                            [111.951851, 28.193038],
                            [111.956218, 28.192571],
                            [111.961121, 28.198147],
                            [111.96092, 28.20377],
                            [111.962146, 28.208863],
                            [111.966201, 28.210981],
                            [111.972374, 28.213022],
                            [111.974602, 28.216152],
                            [111.976006, 28.219672],
                            [111.981777, 28.221525],
                            [111.98434, 28.223113],
                            [111.984184, 28.225122],
                            [111.986479, 28.228984],
                            [111.987103, 28.234419],
                            [111.98434, 28.238747],
                            [111.978323, 28.242624],
                            [111.97244, 28.249833],
                            [111.969365, 28.252573],
                            [111.962658, 28.254488],
                            [111.962123, 28.258909],
                            [111.959561, 28.263284],
                            [111.959293, 28.265152],
                            [111.961834, 28.27018],
                            [111.962614, 28.274678],
                            [111.965845, 28.278087],
                            [111.965065, 28.282507],
                            [111.965377, 28.290491],
                            [111.968184, 28.297261],
                            [111.968764, 28.304777],
                            [111.975293, 28.314549],
                            [111.97556, 28.317956],
                            [111.973933, 28.321862],
                            [111.968964, 28.324304],
                            [111.965845, 28.331694],
                            [111.962792, 28.332176],
                            [111.957555, 28.328551],
                            [111.954436, 28.32824],
                            [111.948865, 28.330978],
                            [111.950224, 28.335241],
                            [111.949556, 28.338306],
                            [111.947907, 28.340406],
                            [111.947327, 28.34473],
                            [111.945768, 28.347017],
                            [111.942938, 28.347452],
                            [111.936319, 28.346877],
                            [111.931974, 28.345352],
                            [111.927696, 28.342739],
                            [111.921969, 28.341246],
                            [111.915128, 28.340966],
                            [111.909669, 28.339519],
                            [111.906928, 28.339814],
                            [111.902694, 28.348385],
                            [111.899396, 28.350578],
                            [111.896878, 28.354825],
                            [111.897235, 28.356582],
                            [111.901201, 28.360657],
                            [111.898817, 28.369102],
                            [111.8928, 28.372212],
                            [111.889057, 28.37568],
                            [111.884868, 28.384994],
                            [111.882572, 28.387187],
                            [111.87874, 28.387404],
                            [111.873347, 28.384217],
                            [111.862429, 28.381309],
                            [111.856523, 28.38078],
                            [111.850284, 28.381216],
                            [111.846095, 28.380174],
                            [111.84224, 28.381978],
                            [111.838608, 28.382786],
                            [111.833683, 28.382864],
                            [111.82994, 28.381853],
                            [111.821829, 28.373798],
                            [111.81608, 28.370455],
                            [111.813807, 28.370968],
                            [111.812291, 28.373658],
                            [111.812782, 28.378837],
                            [111.814765, 28.382258],
                            [111.81403, 28.382926],
                            [111.80857, 28.382724],
                            [111.807545, 28.386005],
                            [111.811957, 28.396034],
                            [111.811467, 28.400449],
                            [111.807813, 28.405999],
                            [111.805495, 28.405222],
                            [111.801974, 28.400294],
                            [111.795735, 28.399081],
                            [111.789808, 28.399221],
                            [111.785061, 28.398708],
                            [111.776549, 28.394168],
                            [111.773028, 28.387078],
                            [111.767079, 28.385414],
                            [111.763447, 28.3819],
                            [111.759391, 28.379117],
                            [111.750456, 28.3747],
                            [111.745954, 28.373798],
                            [111.731181, 28.372228],
                            [111.728061, 28.373036],
                            [111.72454, 28.375556],
                            [111.720262, 28.375851],
                            [111.709254, 28.372461],
                            [111.701656, 28.37173],
                            [111.698135, 28.369864],
                            [111.689177, 28.367189],
                            [111.686146, 28.367438],
                            [111.678637, 28.370144],
                            [111.67418, 28.368169],
                            [111.67115, 28.368091],
                            [111.664465, 28.365649],
                            [111.659674, 28.365447],
                            [111.657802, 28.367002],
                            [111.660967, 28.372057],
                            [111.659563, 28.379008],
                            [111.661212, 28.38445],
                            [111.666872, 28.392256],
                            [111.667941, 28.396003],
                            [111.667273, 28.399221],
                            [111.663997, 28.400045],
                            [111.657156, 28.398008],
                            [111.655284, 28.397977],
                            [111.653546, 28.399812],
                            [111.652432, 28.404258],
                            [111.65036, 28.404071],
                            [111.644477, 28.397651],
                            [111.642271, 28.397697],
                            [111.639084, 28.400651],
                            [111.634494, 28.400713],
                            [111.622417, 28.403776],
                            [111.614796, 28.404413],
                            [111.613637, 28.407663],
                            [111.609225, 28.412622],
                            [111.608735, 28.416073],
                            [111.611052, 28.421093],
                            [111.610384, 28.423285],
                            [111.607398, 28.426767],
                            [111.607687, 28.429642],
                            [111.613838, 28.433263],
                            [111.616133, 28.435284],
                            [111.618584, 28.439775],
                            [111.620545, 28.447421],
                            [111.624734, 28.448229],
                            [111.641335, 28.447033],
                            [111.64724, 28.447499],
                            [111.658092, 28.447173],
                            [111.668409, 28.446209],
                            [111.667963, 28.448618],
                            [111.669857, 28.450498],
                            [111.669412, 28.452627],
                            [111.664487, 28.456574],
                            [111.658025, 28.458656],
                            [111.653658, 28.462214],
                            [111.650805, 28.463224],
                            [111.646549, 28.466751],
                            [111.641558, 28.465897],
                            [111.638683, 28.468585],
                            [111.638951, 28.470931],
                            [111.641402, 28.47295],
                            [111.654883, 28.47483],
                            [111.6568, 28.478248],
                            [111.655262, 28.482676],
                            [111.655997, 28.48788],
                            [111.661434, 28.490489],
                            [111.66188, 28.492757],
                            [111.659741, 28.494683],
                            [111.6529, 28.497433],
                            [111.651964, 28.49838],
                            [111.65007, 28.505634],
                            [111.650248, 28.511753],
                            [111.653301, 28.513865],
                            [111.65778, 28.512514],
                            [111.661167, 28.509718],
                            [111.663351, 28.509299],
                            [111.668743, 28.510557],
                            [111.673289, 28.513974],
                            [111.675139, 28.519487],
                            [111.674381, 28.535605],
                            [111.674515, 28.542732],
                            [111.672064, 28.551519],
                            [111.67418, 28.555617],
                            [111.679439, 28.557729],
                            [111.681155, 28.560243],
                            [111.68091, 28.565226],
                            [111.678191, 28.569076],
                            [111.674849, 28.572522],
                            [111.673712, 28.577473],
                            [111.670169, 28.585838],
                            [111.671016, 28.589113],
                            [111.673512, 28.591456]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430981,
                "name": "沅江市",
                "center": [112.361088, 28.839713],
                "centroid": [112.556606, 28.969918],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430900
                },
                "subFeatureIndex": 5,
                "acroutes": [100000, 430000, 430900]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.254256, 29.048167],
                            [112.252116, 29.039499],
                            [112.250155, 29.021712],
                            [112.249866, 29.014819],
                            [112.25098, 29.012315],
                            [112.250891, 29.000753],
                            [112.24766, 28.997043],
                            [112.247972, 28.991586],
                            [112.246902, 28.980841],
                            [112.247259, 28.978723],
                            [112.249732, 28.976064],
                            [112.255392, 28.973126],
                            [112.257353, 28.967745],
                            [112.259537, 28.922723],
                            [112.261431, 28.917138],
                            [112.261743, 28.908134],
                            [112.258645, 28.900831],
                            [112.256996, 28.861461],
                            [112.259292, 28.857854],
                            [112.266066, 28.853442],
                            [112.273063, 28.845346],
                            [112.27645, 28.840732],
                            [112.28641, 28.834601],
                            [112.304682, 28.82302],
                            [112.30593, 28.818003],
                            [112.304771, 28.809965],
                            [112.306398, 28.800224],
                            [112.305952, 28.7977],
                            [112.299579, 28.784844],
                            [112.294209, 28.78077],
                            [112.283424, 28.776897],
                            [112.281753, 28.77352],
                            [112.28309, 28.766997],
                            [112.283602, 28.7575],
                            [112.282488, 28.751751],
                            [112.282733, 28.746824],
                            [112.283758, 28.741726],
                            [112.282889, 28.738394],
                            [112.283224, 28.728429],
                            [112.27919, 28.727173],
                            [112.2744, 28.723438],
                            [112.278299, 28.719594],
                            [112.280973, 28.71806],
                            [112.285853, 28.713642],
                            [112.288371, 28.710371],
                            [112.299802, 28.703086],
                            [112.306108, 28.71158],
                            [112.312994, 28.711875],
                            [112.315846, 28.710464],
                            [112.319389, 28.706775],
                            [112.323155, 28.705101],
                            [112.33677, 28.70924],
                            [112.343923, 28.710759],
                            [112.350719, 28.713286],
                            [112.357159, 28.717223],
                            [112.361348, 28.721361],
                            [112.363911, 28.723004],
                            [112.379866, 28.729777],
                            [112.386929, 28.735511],
                            [112.392233, 28.741106],
                            [112.394439, 28.747087],
                            [112.392389, 28.750016],
                            [112.393102, 28.752201],
                            [112.395976, 28.753921],
                            [112.397469, 28.757267],
                            [112.396199, 28.759948],
                            [112.397313, 28.761807],
                            [112.400099, 28.76283],
                            [112.406472, 28.763062],
                            [112.406851, 28.764379],
                            [112.403865, 28.772699],
                            [112.403553, 28.775224],
                            [112.404912, 28.775766],
                            [112.414226, 28.774294],
                            [112.41719, 28.772311],
                            [112.419329, 28.769089],
                            [112.422471, 28.767741],
                            [112.428131, 28.767633],
                            [112.431407, 28.76503],
                            [112.434638, 28.765448],
                            [112.43798, 28.7685],
                            [112.450659, 28.768671],
                            [112.455183, 28.768237],
                            [112.461734, 28.765216],
                            [112.466837, 28.764023],
                            [112.470023, 28.762194],
                            [112.473901, 28.762102],
                            [112.479516, 28.766564],
                            [112.480229, 28.767989],
                            [112.490992, 28.779298],
                            [112.49576, 28.781389],
                            [112.501487, 28.782551],
                            [112.503493, 28.784069],
                            [112.508194, 28.783852],
                            [112.512205, 28.781002],
                            [112.521475, 28.777997],
                            [112.527313, 28.775518],
                            [112.530723, 28.775472],
                            [112.533865, 28.776959],
                            [112.541953, 28.783728],
                            [112.540416, 28.784224],
                            [112.542956, 28.787415],
                            [112.546187, 28.792945],
                            [112.547591, 28.797095],
                            [112.549931, 28.800116],
                            [112.552048, 28.805134],
                            [112.552159, 28.813078],
                            [112.551624, 28.814875],
                            [112.554031, 28.819381],
                            [112.558822, 28.820279],
                            [112.563991, 28.823624],
                            [112.56622, 28.824166],
                            [112.571857, 28.82175],
                            [112.583044, 28.811483],
                            [112.589595, 28.806264],
                            [112.594363, 28.80408],
                            [112.605861, 28.801355],
                            [112.610184, 28.797823],
                            [112.613259, 28.791659],
                            [112.615087, 28.791303],
                            [112.618451, 28.793254],
                            [112.620145, 28.791411],
                            [112.621571, 28.77936],
                            [112.628657, 28.782117],
                            [112.630574, 28.784503],
                            [112.634317, 28.785231],
                            [112.639286, 28.787461],
                            [112.639843, 28.788824],
                            [112.644478, 28.789305],
                            [112.647888, 28.788344],
                            [112.649982, 28.788979],
                            [112.650918, 28.790946],
                            [112.653614, 28.791705],
                            [112.655999, 28.791148],
                            [112.659497, 28.788855],
                            [112.661926, 28.788995],
                            [112.671686, 28.791582],
                            [112.673914, 28.793115],
                            [112.675496, 28.797235],
                            [112.672756, 28.799001],
                            [112.671909, 28.802423],
                            [112.673357, 28.804932],
                            [112.672978, 28.811499],
                            [112.670327, 28.817043],
                            [112.667229, 28.818638],
                            [112.664934, 28.821038],
                            [112.66353, 28.824971],
                            [112.663931, 28.829446],
                            [112.659898, 28.834617],
                            [112.659341, 28.836661],
                            [112.659854, 28.840856],
                            [112.662216, 28.84403],
                            [112.668366, 28.850455],
                            [112.669213, 28.856461],
                            [112.671641, 28.861693],
                            [112.675162, 28.866491],
                            [112.677435, 28.871243],
                            [112.68519, 28.87977],
                            [112.693078, 28.88073],
                            [112.698916, 28.880807],
                            [112.708899, 28.882448],
                            [112.716787, 28.883407],
                            [112.722893, 28.883484],
                            [112.732073, 28.885357],
                            [112.737354, 28.887075],
                            [112.746713, 28.892831],
                            [112.754802, 28.896328],
                            [112.766122, 28.899392],
                            [112.773208, 28.90275],
                            [112.773698, 28.905318],
                            [112.776127, 28.908196],
                            [112.784149, 28.911986],
                            [112.794422, 28.91392],
                            [112.797452, 28.913688],
                            [112.80329, 28.917463],
                            [112.811602, 28.921563],
                            [112.826153, 28.925662],
                            [112.838119, 28.929282],
                            [112.84799, 28.935546],
                            [112.852692, 28.942754],
                            [112.864435, 28.952776],
                            [112.872413, 28.962967],
                            [112.874619, 28.974579],
                            [112.876089, 28.984073],
                            [112.880279, 28.989004],
                            [112.891376, 28.999362],
                            [112.904567, 29.008853],
                            [112.920656, 29.01723],
                            [112.92792, 29.023041],
                            [112.934427, 29.037336],
                            [112.943763, 29.051427],
                            [112.949379, 29.06079],
                            [112.941156, 29.063169],
                            [112.939039, 29.065532],
                            [112.939173, 29.073241],
                            [112.937747, 29.078122],
                            [112.929881, 29.096996],
                            [112.927051, 29.109041],
                            [112.926895, 29.120236],
                            [112.926182, 29.129052],
                            [112.926516, 29.133745],
                            [112.925335, 29.141649],
                            [112.925313, 29.14858],
                            [112.924667, 29.149429],
                            [112.912411, 29.152284],
                            [112.901091, 29.154615],
                            [112.89474, 29.157563],
                            [112.887855, 29.159816],
                            [112.881593, 29.162764],
                            [112.873438, 29.161283],
                            [112.812872, 29.152377],
                            [112.807814, 29.153534],
                            [112.804605, 29.155186],
                            [112.798499, 29.159801],
                            [112.791146, 29.155201],
                            [112.786444, 29.15676],
                            [112.78074, 29.155001],
                            [112.771425, 29.155263],
                            [112.764206, 29.150308],
                            [112.761955, 29.149552],
                            [112.751036, 29.148348],
                            [112.74736, 29.146496],
                            [112.736107, 29.145137],
                            [112.732318, 29.140012],
                            [112.72989, 29.132803],
                            [112.725255, 29.123092],
                            [112.717121, 29.114276],
                            [112.711395, 29.11023],
                            [112.707205, 29.108809],
                            [112.70618, 29.105783],
                            [112.704175, 29.104516],
                            [112.695685, 29.101984],
                            [112.68568, 29.096764],
                            [112.670104, 29.08637],
                            [112.66128, 29.077195],
                            [112.653793, 29.083899],
                            [112.650851, 29.084316],
                            [112.64245, 29.082787],
                            [112.617337, 29.082431],
                            [112.617092, 29.089227],
                            [112.593695, 29.085567],
                            [112.591244, 29.091034],
                            [112.579434, 29.089027],
                            [112.574398, 29.086926],
                            [112.569629, 29.083914],
                            [112.563523, 29.087621],
                            [112.560003, 29.092162],
                            [112.557262, 29.096919],
                            [112.556237, 29.102169],
                            [112.561897, 29.10847],
                            [112.567557, 29.107867],
                            [112.568582, 29.110122],
                            [112.569562, 29.116561],
                            [112.570922, 29.118275],
                            [112.577005, 29.157192],
                            [112.56925, 29.165187],
                            [112.565328, 29.166021],
                            [112.548705, 29.158674],
                            [112.543469, 29.156884],
                            [112.539903, 29.161221],
                            [112.537474, 29.165357],
                            [112.52455, 29.168289],
                            [112.506701, 29.17062],
                            [112.504206, 29.171669],
                            [112.488563, 29.175759],
                            [112.482168, 29.178212],
                            [112.477199, 29.178721],
                            [112.468464, 29.181005],
                            [112.4616, 29.182301],
                            [112.447963, 29.185465],
                            [112.44208, 29.185958],
                            [112.441011, 29.184415],
                            [112.426059, 29.168783],
                            [112.423385, 29.167271],
                            [112.420198, 29.16744],
                            [112.402082, 29.1792],
                            [112.399074, 29.17869],
                            [112.390339, 29.170465],
                            [112.390495, 29.169292],
                            [112.404778, 29.160403],
                            [112.405937, 29.156961],
                            [112.40645, 29.149398],
                            [112.400233, 29.142343],
                            [112.398561, 29.139333],
                            [112.40226, 29.135752],
                            [112.40284, 29.134254],
                            [112.401235, 29.131568],
                            [112.397469, 29.127955],
                            [112.394862, 29.121239],
                            [112.394951, 29.115109],
                            [112.394194, 29.10847],
                            [112.38664, 29.094401],
                            [112.384278, 29.092131],
                            [112.366897, 29.077087],
                            [112.33218, 29.048615],
                            [112.327968, 29.049017],
                            [112.324113, 29.05288],
                            [112.318565, 29.057236],
                            [112.311256, 29.058889],
                            [112.303613, 29.059507],
                            [112.293028, 29.058828],
                            [112.289909, 29.057901],
                            [112.286232, 29.055212],
                            [112.279592, 29.047333],
                            [112.278366, 29.045355],
                            [112.274021, 29.045247],
                            [112.266868, 29.046097],
                            [112.254256, 29.048167]
                        ]
                    ]
                ]
            }
        }]
    });
}));