/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

(function(root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['exports', 'echarts'], factory);
    } else if (typeof exports === 'object' && typeof exports.nodeName !== 'string') {
        // CommonJS
        factory(exports, require('echarts'));
    } else {
        // Browser globals
        factory({}, root.echarts);
    }
}(this, function(exports, echarts) {
    var log = function(msg) {
        if (typeof console !== 'undefined') {
            console && console.error && console.error(msg);
        }
    }
    if (!echarts) {
        log('ECharts is not Loaded');
        return;
    }
    if (!echarts.registerMap) {
        log('ECharts Map is not loaded')
        return;
    }
    echarts.registerMap('株洲市', {
        "type": "FeatureCollection",
        "features": [{
            "type": "Feature",
            "properties": {
                "adcode": 430202,
                "name": "荷塘区",
                "center": [113.162548, 27.833036],
                "centroid": [113.219503, 27.900997],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430200
                },
                "subFeatureIndex": 0,
                "acroutes": [100000, 430000, 430200]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [113.256665, 27.993989],
                            [113.254418, 27.989188],
                            [113.257715, 27.986748],
                            [113.253004, 27.984603],
                            [113.241962, 27.970061],
                            [113.239566, 27.968644],
                            [113.230281, 27.969116],
                            [113.227874, 27.976122],
                            [113.224623, 27.977145],
                            [113.217642, 27.974528],
                            [113.209463, 27.9664],
                            [113.196675, 27.956284],
                            [113.194451, 27.950753],
                            [113.197485, 27.942938],
                            [113.195751, 27.933548],
                            [113.18796, 27.923586],
                            [113.182222, 27.923882],
                            [113.18115, 27.920279],
                            [113.171488, 27.914273],
                            [113.16721, 27.914293],
                            [113.16267, 27.909114],
                            [113.165226, 27.904507],
                            [113.161701, 27.89984],
                            [113.156396, 27.898008],
                            [113.15675, 27.892435],
                            [113.148457, 27.892671],
                            [113.146518, 27.882036],
                            [113.149415, 27.881819],
                            [113.161313, 27.874177],
                            [113.159522, 27.867696],
                            [113.153602, 27.867657],
                            [113.147544, 27.862279],
                            [113.136936, 27.857472],
                            [113.150225, 27.847779],
                            [113.153841, 27.842755],
                            [113.156271, 27.835327],
                            [113.161404, 27.827858],
                            [113.171716, 27.828055],
                            [113.190139, 27.834085],
                            [113.20344, 27.834361],
                            [113.211653, 27.830854],
                            [113.220539, 27.838735],
                            [113.229014, 27.840194],
                            [113.23903, 27.84841],
                            [113.253688, 27.856192],
                            [113.260316, 27.861058],
                            [113.269864, 27.85958],
                            [113.273582, 27.863894],
                            [113.276936, 27.876147],
                            [113.281031, 27.880893],
                            [113.281624, 27.894444],
                            [113.277803, 27.903699],
                            [113.271757, 27.906515],
                            [113.274871, 27.909567],
                            [113.281602, 27.910768],
                            [113.290203, 27.909882],
                            [113.286404, 27.917621],
                            [113.279354, 27.919924],
                            [113.271974, 27.920121],
                            [113.270662, 27.927445],
                            [113.266692, 27.934198],
                            [113.259095, 27.941797],
                            [113.261867, 27.948568],
                            [113.269213, 27.947111],
                            [113.271518, 27.94969],
                            [113.271826, 27.961775],
                            [113.277963, 27.968703],
                            [113.277677, 27.970612],
                            [113.269567, 27.977027],
                            [113.271438, 27.990644],
                            [113.267365, 27.993792],
                            [113.269978, 27.996684],
                            [113.266978, 28.001347],
                            [113.268826, 28.004043],
                            [113.264023, 28.011735],
                            [113.259859, 28.002665],
                            [113.260179, 27.994186],
                            [113.256665, 27.993989]
                        ]
                    ],
                    [
                        [
                            [113.257567, 27.994481],
                            [113.259848, 28.01323],
                            [113.256335, 28.012895],
                            [113.256392, 28.00056],
                            [113.257567, 27.994481]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430203,
                "name": "芦淞区",
                "center": [113.155169, 27.827246],
                "centroid": [113.254675, 27.805177],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430200
                },
                "subFeatureIndex": 1,
                "acroutes": [100000, 430000, 430200]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [113.273582, 27.863894],
                            [113.269864, 27.85958],
                            [113.260316, 27.861058],
                            [113.253688, 27.856192],
                            [113.23903, 27.84841],
                            [113.229014, 27.840194],
                            [113.220539, 27.838735],
                            [113.211653, 27.830854],
                            [113.20344, 27.834361],
                            [113.190139, 27.834085],
                            [113.171716, 27.828055],
                            [113.161404, 27.827858],
                            [113.156271, 27.835327],
                            [113.153841, 27.842755],
                            [113.150225, 27.847779],
                            [113.136936, 27.857472],
                            [113.130297, 27.853768],
                            [113.127445, 27.847799],
                            [113.132715, 27.845041],
                            [113.146153, 27.833494],
                            [113.148605, 27.826538],
                            [113.148993, 27.816921],
                            [113.146438, 27.804031],
                            [113.143164, 27.798079],
                            [113.135909, 27.795004],
                            [113.120612, 27.792362],
                            [113.116825, 27.790115],
                            [113.113813, 27.780081],
                            [113.119951, 27.756204],
                            [113.133445, 27.758708],
                            [113.134837, 27.768922],
                            [113.151081, 27.766576],
                            [113.160355, 27.758196],
                            [113.172937, 27.751925],
                            [113.180842, 27.757821],
                            [113.177899, 27.765156],
                            [113.187367, 27.769139],
                            [113.197725, 27.761173],
                            [113.213581, 27.767917],
                            [113.221395, 27.768291],
                            [113.220767, 27.773654],
                            [113.216364, 27.78018],
                            [113.226779, 27.775783],
                            [113.235015, 27.773969],
                            [113.24454, 27.762711],
                            [113.251726, 27.756046],
                            [113.258924, 27.756401],
                            [113.26813, 27.762494],
                            [113.275225, 27.759517],
                            [113.277084, 27.753108],
                            [113.284841, 27.7495],
                            [113.297343, 27.755514],
                            [113.298712, 27.762711],
                            [113.30356, 27.762297],
                            [113.312287, 27.755908],
                            [113.31888, 27.753877],
                            [113.32374, 27.74382],
                            [113.332352, 27.74526],
                            [113.337577, 27.751235],
                            [113.346565, 27.752812],
                            [113.352155, 27.762948],
                            [113.352372, 27.767246],
                            [113.348881, 27.77598],
                            [113.348276, 27.783827],
                            [113.350467, 27.788656],
                            [113.360117, 27.789504],
                            [113.36249, 27.797665],
                            [113.360391, 27.800957],
                            [113.361748, 27.815758],
                            [113.359398, 27.848075],
                            [113.343075, 27.858556],
                            [113.331325, 27.856921],
                            [113.32723, 27.857925],
                            [113.315743, 27.865096],
                            [113.300766, 27.868839],
                            [113.300081, 27.87723],
                            [113.288491, 27.877191],
                            [113.290203, 27.865825],
                            [113.274985, 27.862673],
                            [113.273582, 27.863894]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430204,
                "name": "石峰区",
                "center": [113.11295, 27.871945],
                "centroid": [113.141998, 27.928496],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430200
                },
                "subFeatureIndex": 2,
                "acroutes": [100000, 430000, 430200]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [113.256335, 28.012895],
                            [113.255422, 28.018777],
                            [113.250266, 28.019682],
                            [113.243855, 28.009925],
                            [113.238106, 28.012935],
                            [113.228193, 28.01443],
                            [113.226573, 28.02043],
                            [113.217892, 28.018974],
                            [113.214584, 28.024069],
                            [113.209166, 28.012286],
                            [113.190812, 28.010672],
                            [113.178538, 28.012738],
                            [113.175834, 28.01504],
                            [113.167176, 28.012305],
                            [113.164096, 28.006738],
                            [113.170655, 27.999891],
                            [113.166891, 27.995464],
                            [113.160275, 27.998199],
                            [113.149438, 27.993438],
                            [113.151012, 27.988657],
                            [113.149541, 27.980845],
                            [113.153066, 27.9738],
                            [113.158906, 27.968762],
                            [113.158723, 27.96638],
                            [113.152815, 27.963015],
                            [113.146529, 27.953883],
                            [113.135487, 27.95345],
                            [113.138841, 27.945694],
                            [113.129898, 27.940005],
                            [113.132909, 27.935596],
                            [113.132966, 27.930576],
                            [113.139388, 27.927524],
                            [113.13681, 27.920928],
                            [113.136628, 27.915002],
                            [113.132795, 27.912856],
                            [113.122095, 27.914313],
                            [113.108635, 27.933036],
                            [113.101813, 27.933706],
                            [113.093121, 27.940655],
                            [113.086687, 27.950103],
                            [113.069211, 27.938588],
                            [113.065207, 27.930359],
                            [113.06134, 27.910887],
                            [113.053424, 27.900371],
                            [113.048382, 27.890997],
                            [113.046363, 27.876422],
                            [113.038606, 27.866672],
                            [113.042541, 27.86222],
                            [113.04204, 27.856349],
                            [113.046386, 27.8464],
                            [113.057895, 27.852547],
                            [113.065858, 27.853847],
                            [113.075371, 27.853217],
                            [113.104243, 27.84703],
                            [113.115798, 27.848646],
                            [113.127445, 27.847799],
                            [113.130297, 27.853768],
                            [113.136936, 27.857472],
                            [113.147544, 27.862279],
                            [113.153602, 27.867657],
                            [113.159522, 27.867696],
                            [113.161313, 27.874177],
                            [113.149415, 27.881819],
                            [113.146518, 27.882036],
                            [113.148457, 27.892671],
                            [113.15675, 27.892435],
                            [113.156396, 27.898008],
                            [113.161701, 27.89984],
                            [113.165226, 27.904507],
                            [113.16267, 27.909114],
                            [113.16721, 27.914293],
                            [113.171488, 27.914273],
                            [113.18115, 27.920279],
                            [113.182222, 27.923882],
                            [113.18796, 27.923586],
                            [113.195751, 27.933548],
                            [113.197485, 27.942938],
                            [113.194451, 27.950753],
                            [113.196675, 27.956284],
                            [113.209463, 27.9664],
                            [113.217642, 27.974528],
                            [113.224623, 27.977145],
                            [113.227874, 27.976122],
                            [113.230281, 27.969116],
                            [113.239566, 27.968644],
                            [113.241962, 27.970061],
                            [113.253004, 27.984603],
                            [113.257715, 27.986748],
                            [113.254418, 27.989188],
                            [113.256665, 27.993989],
                            [113.257567, 27.994481],
                            [113.256392, 28.00056],
                            [113.256335, 28.012895]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430211,
                "name": "天元区",
                "center": [113.136252, 27.826909],
                "centroid": [113.073878, 27.706389],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430200
                },
                "subFeatureIndex": 3,
                "acroutes": [100000, 430000, 430200]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [113.119951, 27.756204],
                            [113.113813, 27.780081],
                            [113.116825, 27.790115],
                            [113.120612, 27.792362],
                            [113.135909, 27.795004],
                            [113.143164, 27.798079],
                            [113.146438, 27.804031],
                            [113.148993, 27.816921],
                            [113.148605, 27.826538],
                            [113.146153, 27.833494],
                            [113.132715, 27.845041],
                            [113.127445, 27.847799],
                            [113.115798, 27.848646],
                            [113.104243, 27.84703],
                            [113.075371, 27.853217],
                            [113.065858, 27.853847],
                            [113.057895, 27.852547],
                            [113.046386, 27.8464],
                            [113.038138, 27.843287],
                            [113.033632, 27.83911],
                            [113.019271, 27.828923],
                            [113.012449, 27.822892],
                            [113.002343, 27.810398],
                            [113.00143, 27.80344],
                            [113.003768, 27.798749],
                            [113.010556, 27.794886],
                            [113.018027, 27.796068],
                            [113.023024, 27.791811],
                            [113.033667, 27.770637],
                            [113.034876, 27.762829],
                            [113.029549, 27.752063],
                            [113.02867, 27.745437],
                            [113.032024, 27.74242],
                            [113.044697, 27.738436],
                            [113.053698, 27.733328],
                            [113.058934, 27.727115],
                            [113.055911, 27.718357],
                            [113.050412, 27.711019],
                            [113.044081, 27.712301],
                            [113.034499, 27.710802],
                            [113.026195, 27.711019],
                            [113.008445, 27.684779],
                            [113.001373, 27.681977],
                            [112.995076, 27.67659],
                            [112.994574, 27.665795],
                            [113.000415, 27.658592],
                            [113.001122, 27.653401],
                            [113.004008, 27.652394],
                            [113.0161, 27.660388],
                            [113.027279, 27.659006],
                            [113.031465, 27.651921],
                            [113.026092, 27.650065],
                            [113.024552, 27.642565],
                            [113.032902, 27.637116],
                            [113.039039, 27.63074],
                            [113.040465, 27.624107],
                            [113.038811, 27.621599],
                            [113.026971, 27.618914],
                            [113.02064, 27.612577],
                            [113.026446, 27.603809],
                            [113.0431, 27.583271],
                            [113.052317, 27.57383],
                            [113.048199, 27.564804],
                            [113.050971, 27.562137],
                            [113.028807, 27.556388],
                            [113.025225, 27.553306],
                            [113.024176, 27.548447],
                            [113.026503, 27.54414],
                            [113.034294, 27.54005],
                            [113.038503, 27.532443],
                            [113.037089, 27.519421],
                            [113.045678, 27.516852],
                            [113.053162, 27.520132],
                            [113.079421, 27.542124],
                            [113.093942, 27.555697],
                            [113.09895, 27.566443],
                            [113.104471, 27.568616],
                            [113.109832, 27.564902],
                            [113.112924, 27.566621],
                            [113.122677, 27.578176],
                            [113.122015, 27.582225],
                            [113.115137, 27.596938],
                            [113.110836, 27.629872],
                            [113.106935, 27.636801],
                            [113.106273, 27.647243],
                            [113.112547, 27.661355],
                            [113.114897, 27.671262],
                            [113.121114, 27.684444],
                            [113.124479, 27.689416],
                            [113.137232, 27.716681],
                            [113.137403, 27.724373],
                            [113.134267, 27.732795],
                            [113.119951, 27.756204]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430212,
                "name": "渌口区",
                "center": [113.146175, 27.705844],
                "centroid": [113.140154, 27.475297],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430200
                },
                "subFeatureIndex": 4,
                "acroutes": [100000, 430000, 430200]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [113.02064, 27.612577],
                            [113.012244, 27.616545],
                            [113.00775, 27.621639],
                            [113.002525, 27.621718],
                            [112.997517, 27.618322],
                            [112.999023, 27.60612],
                            [113.00597, 27.603336],
                            [113.009004, 27.598754],
                            [113.002502, 27.594054],
                            [113.000095, 27.588406],
                            [112.993753, 27.584832],
                            [112.988825, 27.578354],
                            [112.981205, 27.577129],
                            [112.972159, 27.568794],
                            [112.970779, 27.561682],
                            [112.975559, 27.549039],
                            [112.976517, 27.542144],
                            [112.975285, 27.526297],
                            [112.969421, 27.511654],
                            [112.972193, 27.504974],
                            [112.975775, 27.501278],
                            [112.981992, 27.501713],
                            [112.987559, 27.498689],
                            [112.983988, 27.487343],
                            [112.973859, 27.4808],
                            [112.971383, 27.475225],
                            [112.974201, 27.469749],
                            [112.979015, 27.467534],
                            [112.986076, 27.469788],
                            [112.987354, 27.466922],
                            [112.986407, 27.456344],
                            [112.98911, 27.44709],
                            [112.993103, 27.445369],
                            [112.995418, 27.438309],
                            [112.99924, 27.43562],
                            [112.999707, 27.429845],
                            [112.997244, 27.420786],
                            [112.997574, 27.415644],
                            [112.994266, 27.407078],
                            [112.995977, 27.399759],
                            [113.001989, 27.392756],
                            [113.010396, 27.389392],
                            [113.017366, 27.388977],
                            [113.024142, 27.385811],
                            [113.021541, 27.378372],
                            [113.025237, 27.367271],
                            [113.033381, 27.361294],
                            [113.042199, 27.350924],
                            [113.033336, 27.345026],
                            [113.032149, 27.34172],
                            [113.033541, 27.333803],
                            [113.032515, 27.32925],
                            [113.042496, 27.327132],
                            [113.048838, 27.320935],
                            [113.048599, 27.314343],
                            [113.04383, 27.309631],
                            [113.047355, 27.3028],
                            [113.047367, 27.296879],
                            [113.051017, 27.287473],
                            [113.058226, 27.283037],
                            [113.068413, 27.280938],
                            [113.080505, 27.267906],
                            [113.085854, 27.26644],
                            [113.08274, 27.25931],
                            [113.085238, 27.25513],
                            [113.082432, 27.253882],
                            [113.092596, 27.238272],
                            [113.087862, 27.235478],
                            [113.093874, 27.23209],
                            [113.097604, 27.232328],
                            [113.107825, 27.225235],
                            [113.107802, 27.220539],
                            [113.113802, 27.212811],
                            [113.11549, 27.205617],
                            [113.125597, 27.205518],
                            [113.127502, 27.207341],
                            [113.133228, 27.229633],
                            [113.144134, 27.249306],
                            [113.148309, 27.251683],
                            [113.154868, 27.246196],
                            [113.164313, 27.236033],
                            [113.170005, 27.232902],
                            [113.173713, 27.233457],
                            [113.182998, 27.240907],
                            [113.221029, 27.238747],
                            [113.226528, 27.239936],
                            [113.231205, 27.245404],
                            [113.23149, 27.251961],
                            [113.237285, 27.272065],
                            [113.235254, 27.278621],
                            [113.231627, 27.281215],
                            [113.220402, 27.280522],
                            [113.211231, 27.284325],
                            [113.207033, 27.291374],
                            [113.206474, 27.299156],
                            [113.20952, 27.304562],
                            [113.20993, 27.314105],
                            [113.205242, 27.320361],
                            [113.197508, 27.324301],
                            [113.203713, 27.33212],
                            [113.220105, 27.338771],
                            [113.228786, 27.340731],
                            [113.24, 27.339781],
                            [113.249502, 27.344412],
                            [113.259666, 27.346767],
                            [113.268552, 27.360483],
                            [113.275784, 27.369526],
                            [113.283027, 27.372158],
                            [113.292051, 27.384505],
                            [113.296978, 27.388126],
                            [113.309572, 27.389076],
                            [113.31766, 27.396099],
                            [113.317797, 27.400748],
                            [113.311648, 27.420213],
                            [113.305751, 27.433247],
                            [113.30121, 27.4409],
                            [113.294446, 27.446852],
                            [113.289142, 27.448335],
                            [113.274347, 27.4479],
                            [113.259768, 27.449759],
                            [113.25265, 27.454604],
                            [113.243946, 27.464826],
                            [113.238825, 27.481037],
                            [113.23441, 27.487066],
                            [113.229243, 27.49017],
                            [113.224839, 27.489992],
                            [113.200234, 27.479891],
                            [113.189466, 27.483824],
                            [113.183123, 27.491613],
                            [113.186454, 27.503808],
                            [113.194964, 27.513136],
                            [113.1972, 27.521258],
                            [113.201569, 27.527404],
                            [113.208847, 27.53106],
                            [113.209714, 27.53837],
                            [113.199527, 27.546412],
                            [113.196287, 27.554195],
                            [113.189991, 27.562433],
                            [113.18739, 27.568359],
                            [113.184869, 27.584851],
                            [113.172674, 27.593719],
                            [113.170952, 27.604165],
                            [113.17742, 27.616841],
                            [113.194188, 27.629062],
                            [113.206702, 27.642723],
                            [113.20985, 27.652631],
                            [113.20936, 27.662322],
                            [113.216923, 27.67884],
                            [113.225592, 27.686535],
                            [113.244677, 27.68912],
                            [113.247049, 27.684937],
                            [113.254213, 27.685075],
                            [113.256734, 27.692001],
                            [113.260715, 27.69279],
                            [113.27105, 27.690146],
                            [113.274267, 27.691172],
                            [113.280849, 27.698571],
                            [113.27875, 27.704686],
                            [113.287887, 27.713702],
                            [113.290362, 27.720014],
                            [113.295541, 27.724334],
                            [113.301872, 27.722539],
                            [113.30396, 27.726188],
                            [113.299773, 27.731296],
                            [113.300458, 27.737627],
                            [113.295803, 27.746975],
                            [113.29821, 27.750505],
                            [113.297343, 27.755514],
                            [113.284841, 27.7495],
                            [113.277084, 27.753108],
                            [113.275225, 27.759517],
                            [113.26813, 27.762494],
                            [113.258924, 27.756401],
                            [113.251726, 27.756046],
                            [113.24454, 27.762711],
                            [113.235015, 27.773969],
                            [113.226779, 27.775783],
                            [113.216364, 27.78018],
                            [113.220767, 27.773654],
                            [113.221395, 27.768291],
                            [113.213581, 27.767917],
                            [113.197725, 27.761173],
                            [113.187367, 27.769139],
                            [113.177899, 27.765156],
                            [113.180842, 27.757821],
                            [113.172937, 27.751925],
                            [113.160355, 27.758196],
                            [113.151081, 27.766576],
                            [113.134837, 27.768922],
                            [113.133445, 27.758708],
                            [113.119951, 27.756204],
                            [113.134267, 27.732795],
                            [113.137403, 27.724373],
                            [113.137232, 27.716681],
                            [113.124479, 27.689416],
                            [113.121114, 27.684444],
                            [113.114897, 27.671262],
                            [113.112547, 27.661355],
                            [113.106273, 27.647243],
                            [113.106935, 27.636801],
                            [113.110836, 27.629872],
                            [113.115137, 27.596938],
                            [113.122015, 27.582225],
                            [113.122677, 27.578176],
                            [113.112924, 27.566621],
                            [113.109832, 27.564902],
                            [113.104471, 27.568616],
                            [113.09895, 27.566443],
                            [113.093942, 27.555697],
                            [113.079421, 27.542124],
                            [113.053162, 27.520132],
                            [113.045678, 27.516852],
                            [113.037089, 27.519421],
                            [113.038503, 27.532443],
                            [113.034294, 27.54005],
                            [113.026503, 27.54414],
                            [113.024176, 27.548447],
                            [113.025225, 27.553306],
                            [113.028807, 27.556388],
                            [113.050971, 27.562137],
                            [113.048199, 27.564804],
                            [113.052317, 27.57383],
                            [113.0431, 27.583271],
                            [113.026446, 27.603809],
                            [113.02064, 27.612577]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430223,
                "name": "攸县",
                "center": [113.345774, 27.000071],
                "centroid": [113.4818, 27.168027],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430200
                },
                "subFeatureIndex": 5,
                "acroutes": [100000, 430000, 430200]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [113.624513, 27.409452],
                            [113.616208, 27.409294],
                            [113.610219, 27.411134],
                            [113.602508, 27.411134],
                            [113.597124, 27.409294],
                            [113.590314, 27.411134],
                            [113.580196, 27.419639],
                            [113.563359, 27.427926],
                            [113.55729, 27.432495],
                            [113.543396, 27.426779],
                            [113.539666, 27.419224],
                            [113.538685, 27.407791],
                            [113.533175, 27.394596],
                            [113.528612, 27.389412],
                            [113.522966, 27.386583],
                            [113.512688, 27.383852],
                            [113.4973, 27.384703],
                            [113.490102, 27.390955],
                            [113.484147, 27.393666],
                            [113.47493, 27.391311],
                            [113.471394, 27.392024],
                            [113.457671, 27.40055],
                            [113.446424, 27.401797],
                            [113.441165, 27.403755],
                            [113.432906, 27.409828],
                            [113.425206, 27.413349],
                            [113.404879, 27.417463],
                            [113.397065, 27.423021],
                            [113.391578, 27.42223],
                            [113.381049, 27.413962],
                            [113.377422, 27.407751],
                            [113.376201, 27.399541],
                            [113.37052, 27.384268],
                            [113.361942, 27.380667],
                            [113.351368, 27.381122],
                            [113.344752, 27.37948],
                            [113.333459, 27.374454],
                            [113.324173, 27.37481],
                            [113.319451, 27.383377],
                            [113.320226, 27.389551],
                            [113.31766, 27.396099],
                            [113.309572, 27.389076],
                            [113.296978, 27.388126],
                            [113.292051, 27.384505],
                            [113.283027, 27.372158],
                            [113.275784, 27.369526],
                            [113.268552, 27.360483],
                            [113.259666, 27.346767],
                            [113.249502, 27.344412],
                            [113.24, 27.339781],
                            [113.228786, 27.340731],
                            [113.220105, 27.338771],
                            [113.203713, 27.33212],
                            [113.197508, 27.324301],
                            [113.205242, 27.320361],
                            [113.20993, 27.314105],
                            [113.20952, 27.304562],
                            [113.206474, 27.299156],
                            [113.207033, 27.291374],
                            [113.211231, 27.284325],
                            [113.220402, 27.280522],
                            [113.231627, 27.281215],
                            [113.235254, 27.278621],
                            [113.237285, 27.272065],
                            [113.23149, 27.251961],
                            [113.231205, 27.245404],
                            [113.226528, 27.239936],
                            [113.231855, 27.23734],
                            [113.242646, 27.240411],
                            [113.248418, 27.23944],
                            [113.255593, 27.229336],
                            [113.255399, 27.22363],
                            [113.263316, 27.217051],
                            [113.271917, 27.205696],
                            [113.276377, 27.195272],
                            [113.279936, 27.191387],
                            [113.283244, 27.181952],
                            [113.283655, 27.174618],
                            [113.280917, 27.167184],
                            [113.269521, 27.168036],
                            [113.260053, 27.173191],
                            [113.256757, 27.170871],
                            [113.257818, 27.160166],
                            [113.251646, 27.159551],
                            [113.249502, 27.153742],
                            [113.245715, 27.150907],
                            [113.244813, 27.140001],
                            [113.236053, 27.145216],
                            [113.235015, 27.140636],
                            [113.226494, 27.138514],
                            [113.219455, 27.131732],
                            [113.209451, 27.133993],
                            [113.204934, 27.138276],
                            [113.196025, 27.141786],
                            [113.193458, 27.135718],
                            [113.184276, 27.132407],
                            [113.178857, 27.13203],
                            [113.167963, 27.12616],
                            [113.168066, 27.118703],
                            [113.165807, 27.113467],
                            [113.164564, 27.101308],
                            [113.170792, 27.099046],
                            [113.175116, 27.08883],
                            [113.181846, 27.086628],
                            [113.193869, 27.076034],
                            [113.193641, 27.069764],
                            [113.196892, 27.064803],
                            [113.199151, 27.056866],
                            [113.196367, 27.054286],
                            [113.199938, 27.050159],
                            [113.19801, 27.040712],
                            [113.200976, 27.039104],
                            [113.211151, 27.039124],
                            [113.215851, 27.040791],
                            [113.219341, 27.030212],
                            [113.21674, 27.025865],
                            [113.226653, 27.024734],
                            [113.231079, 27.021042],
                            [113.234604, 27.021379],
                            [113.235699, 27.014789],
                            [113.224908, 27.012466],
                            [113.21917, 27.004406],
                            [113.224417, 27.000813],
                            [113.226824, 26.994658],
                            [113.22152, 26.974523],
                            [113.225843, 26.967771],
                            [113.225615, 26.956272],
                            [113.235345, 26.951743],
                            [113.237342, 26.947274],
                            [113.24503, 26.938315],
                            [113.248144, 26.93758],
                            [113.260453, 26.940004],
                            [113.275636, 26.940679],
                            [113.280986, 26.939964],
                            [113.282674, 26.936905],
                            [113.278328, 26.928442],
                            [113.266441, 26.918389],
                            [113.257076, 26.917693],
                            [113.254783, 26.913759],
                            [113.25638, 26.906209],
                            [113.254738, 26.900267],
                            [113.248258, 26.896611],
                            [113.239703, 26.894445],
                            [113.242886, 26.889298],
                            [113.239098, 26.877731],
                            [113.230429, 26.879241],
                            [113.227144, 26.876399],
                            [113.23141, 26.868389],
                            [113.23287, 26.860994],
                            [113.243513, 26.850975],
                            [113.252376, 26.839205],
                            [113.254578, 26.830596],
                            [113.256859, 26.828389],
                            [113.267628, 26.826162],
                            [113.278054, 26.826897],
                            [113.280621, 26.825207],
                            [113.274974, 26.819858],
                            [113.273799, 26.813037],
                            [113.275727, 26.809338],
                            [113.285332, 26.799672],
                            [113.285446, 26.790025],
                            [113.278944, 26.783421],
                            [113.2781, 26.777692],
                            [113.283392, 26.771446],
                            [113.29926, 26.768084],
                            [113.306264, 26.773535],
                            [113.311112, 26.788176],
                            [113.315082, 26.796191],
                            [113.326295, 26.807568],
                            [113.338147, 26.810273],
                            [113.363676, 26.80007],
                            [113.372323, 26.797902],
                            [113.384437, 26.797087],
                            [113.394304, 26.804784],
                            [113.396255, 26.808324],
                            [113.39346, 26.814946],
                            [113.386605, 26.822463],
                            [113.375562, 26.822562],
                            [113.368239, 26.827414],
                            [113.363072, 26.839484],
                            [113.355132, 26.86161],
                            [113.358475, 26.864632],
                            [113.365992, 26.865288],
                            [113.368787, 26.870853],
                            [113.366391, 26.882282],
                            [113.368216, 26.891841],
                            [113.377718, 26.896531],
                            [113.370851, 26.905454],
                            [113.368068, 26.914534],
                            [113.36857, 26.923356],
                            [113.37838, 26.93617],
                            [113.389536, 26.935991],
                            [113.408643, 26.936964],
                            [113.416355, 26.938792],
                            [113.422868, 26.948565],
                            [113.41648, 26.954742],
                            [113.436431, 26.960184],
                            [113.4425, 26.958019],
                            [113.453873, 26.949637],
                            [113.462474, 26.94491],
                            [113.46974, 26.942785],
                            [113.482459, 26.944433],
                            [113.487604, 26.95075],
                            [113.489954, 26.960244],
                            [113.493387, 26.961396],
                            [113.499935, 26.956788],
                            [113.505775, 26.956152],
                            [113.508559, 26.958714],
                            [113.510087, 26.973788],
                            [113.511707, 26.980818],
                            [113.514627, 26.984968],
                            [113.53702, 27.000654],
                            [113.549944, 27.013796],
                            [113.555568, 27.016397],
                            [113.571823, 27.020109],
                            [113.581108, 27.023384],
                            [113.588694, 27.02791],
                            [113.607368, 27.035393],
                            [113.622779, 27.046785],
                            [113.638429, 27.066907],
                            [113.643175, 27.070974],
                            [113.652996, 27.074228],
                            [113.666571, 27.075875],
                            [113.675332, 27.078732],
                            [113.688427, 27.086846],
                            [113.696606, 27.094821],
                            [113.706302, 27.099463],
                            [113.715177, 27.100494],
                            [113.726732, 27.099939],
                            [113.73709, 27.103391],
                            [113.740136, 27.114677],
                            [113.742759, 27.118187],
                            [113.749193, 27.120964],
                            [113.769452, 27.122788],
                            [113.775042, 27.124712],
                            [113.779365, 27.137186],
                            [113.801061, 27.158441],
                            [113.814704, 27.174301],
                            [113.823009, 27.192992],
                            [113.833036, 27.207301],
                            [113.847181, 27.222461],
                            [113.853055, 27.234765],
                            [113.856865, 27.25927],
                            [113.859877, 27.269114],
                            [113.871729, 27.28476],
                            [113.8719, 27.293493],
                            [113.869128, 27.296523],
                            [113.858394, 27.29876],
                            [113.854652, 27.305235],
                            [113.8595, 27.317114],
                            [113.866983, 27.331447],
                            [113.872927, 27.346748],
                            [113.87409, 27.367389],
                            [113.876178, 27.382052],
                            [113.87263, 27.384861],
                            [113.858884, 27.378886],
                            [113.837416, 27.371881],
                            [113.829351, 27.366281],
                            [113.822895, 27.364777],
                            [113.810894, 27.368399],
                            [113.803936, 27.368062],
                            [113.788457, 27.364619],
                            [113.783255, 27.362185],
                            [113.774232, 27.360938],
                            [113.762904, 27.360859],
                            [113.749136, 27.354843],
                            [113.743626, 27.348727],
                            [113.735105, 27.347915],
                            [113.727941, 27.350528],
                            [113.722751, 27.349004],
                            [113.717994, 27.342393],
                            [113.712759, 27.338553],
                            [113.699754, 27.332041],
                            [113.679358, 27.331586],
                            [113.672172, 27.333288],
                            [113.657707, 27.34744],
                            [113.644601, 27.35035],
                            [113.639171, 27.349954],
                            [113.625904, 27.343225],
                            [113.616664, 27.345402],
                            [113.60439, 27.365529],
                            [113.602006, 27.377501],
                            [113.602668, 27.385752],
                            [113.611052, 27.393468],
                            [113.621193, 27.399621],
                            [113.629771, 27.401045],
                            [113.631916, 27.40514],
                            [113.624513, 27.409452]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430224,
                "name": "茶陵县",
                "center": [113.546509, 26.789534],
                "centroid": [113.645654, 26.801089],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430200
                },
                "subFeatureIndex": 6,
                "acroutes": [100000, 430000, 430200]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [113.603626, 26.507681],
                            [113.610254, 26.506804],
                            [113.619003, 26.510792],
                            [113.62741, 26.503653],
                            [113.633216, 26.505568],
                            [113.637232, 26.510373],
                            [113.638806, 26.516694],
                            [113.647624, 26.523712],
                            [113.652677, 26.522676],
                            [113.66259, 26.515577],
                            [113.67143, 26.510852],
                            [113.679176, 26.511251],
                            [113.701021, 26.520163],
                            [113.713591, 26.525985],
                            [113.726732, 26.526484],
                            [113.75411, 26.540478],
                            [113.769041, 26.541057],
                            [113.778601, 26.544984],
                            [113.78946, 26.5578],
                            [113.794514, 26.568961],
                            [113.798677, 26.584306],
                            [113.806263, 26.592037],
                            [113.822678, 26.598234],
                            [113.83672, 26.597696],
                            [113.849553, 26.600924],
                            [113.856147, 26.608973],
                            [113.858656, 26.614312],
                            [113.857561, 26.6225],
                            [113.852268, 26.630248],
                            [113.851504, 26.634491],
                            [113.856158, 26.643354],
                            [113.86606, 26.650265],
                            [113.86801, 26.652993],
                            [113.860493, 26.664086],
                            [113.861793, 26.670079],
                            [113.861257, 26.684753],
                            [113.86266, 26.689492],
                            [113.861394, 26.705955],
                            [113.856443, 26.714733],
                            [113.856945, 26.722575],
                            [113.855371, 26.727292],
                            [113.856763, 26.73563],
                            [113.852861, 26.750018],
                            [113.855451, 26.757201],
                            [113.852428, 26.763309],
                            [113.853546, 26.769417],
                            [113.848698, 26.777354],
                            [113.845937, 26.788156],
                            [113.840564, 26.7911],
                            [113.836766, 26.796132],
                            [113.834587, 26.804088],
                            [113.835773, 26.806355],
                            [113.857949, 26.826818],
                            [113.85901, 26.833797],
                            [113.873953, 26.856263],
                            [113.877444, 26.859483],
                            [113.886421, 26.861193],
                            [113.892091, 26.860537],
                            [113.891155, 26.874411],
                            [113.888623, 26.880056],
                            [113.891315, 26.884548],
                            [113.890186, 26.889894],
                            [113.891395, 26.897843],
                            [113.89695, 26.90132],
                            [113.90303, 26.91064],
                            [113.901045, 26.917872],
                            [113.902186, 26.925839],
                            [113.910627, 26.934243],
                            [113.917015, 26.937719],
                            [113.926369, 26.947174],
                            [113.92686, 26.949161],
                            [113.921259, 26.954504],
                            [113.921407, 26.960065],
                            [113.892524, 26.964732],
                            [113.888053, 26.972835],
                            [113.879794, 26.9833],
                            [113.871478, 26.990111],
                            [113.869778, 27.008496],
                            [113.866345, 27.016357],
                            [113.863299, 27.018481],
                            [113.848139, 27.016655],
                            [113.846405, 27.019216],
                            [113.845549, 27.028426],
                            [113.8385, 27.034956],
                            [113.82391, 27.036127],
                            [113.820864, 27.037775],
                            [113.816689, 27.046805],
                            [113.812526, 27.071212],
                            [113.811944, 27.081549],
                            [113.807632, 27.097698],
                            [113.803297, 27.099384],
                            [113.798883, 27.09623],
                            [113.793624, 27.089425],
                            [113.788183, 27.086529],
                            [113.779981, 27.087104],
                            [113.775167, 27.090854],
                            [113.77195, 27.096408],
                            [113.773947, 27.107635],
                            [113.775042, 27.124712],
                            [113.769452, 27.122788],
                            [113.749193, 27.120964],
                            [113.742759, 27.118187],
                            [113.740136, 27.114677],
                            [113.73709, 27.103391],
                            [113.726732, 27.099939],
                            [113.715177, 27.100494],
                            [113.706302, 27.099463],
                            [113.696606, 27.094821],
                            [113.688427, 27.086846],
                            [113.675332, 27.078732],
                            [113.666571, 27.075875],
                            [113.652996, 27.074228],
                            [113.643175, 27.070974],
                            [113.638429, 27.066907],
                            [113.622779, 27.046785],
                            [113.607368, 27.035393],
                            [113.588694, 27.02791],
                            [113.581108, 27.023384],
                            [113.571823, 27.020109],
                            [113.555568, 27.016397],
                            [113.549944, 27.013796],
                            [113.53702, 27.000654],
                            [113.514627, 26.984968],
                            [113.511707, 26.980818],
                            [113.510087, 26.973788],
                            [113.508559, 26.958714],
                            [113.505775, 26.956152],
                            [113.499935, 26.956788],
                            [113.493387, 26.961396],
                            [113.489954, 26.960244],
                            [113.487604, 26.95075],
                            [113.482459, 26.944433],
                            [113.46974, 26.942785],
                            [113.462474, 26.94491],
                            [113.453873, 26.949637],
                            [113.4425, 26.958019],
                            [113.436431, 26.960184],
                            [113.41648, 26.954742],
                            [113.422868, 26.948565],
                            [113.416355, 26.938792],
                            [113.408643, 26.936964],
                            [113.389536, 26.935991],
                            [113.37838, 26.93617],
                            [113.36857, 26.923356],
                            [113.368068, 26.914534],
                            [113.370851, 26.905454],
                            [113.377718, 26.896531],
                            [113.368216, 26.891841],
                            [113.366391, 26.882282],
                            [113.368787, 26.870853],
                            [113.365992, 26.865288],
                            [113.358475, 26.864632],
                            [113.355132, 26.86161],
                            [113.363072, 26.839484],
                            [113.368239, 26.827414],
                            [113.375562, 26.822562],
                            [113.386605, 26.822463],
                            [113.39346, 26.814946],
                            [113.396255, 26.808324],
                            [113.394304, 26.804784],
                            [113.404719, 26.797624],
                            [113.408997, 26.791836],
                            [113.412704, 26.782606],
                            [113.416742, 26.77819],
                            [113.428127, 26.769775],
                            [113.427636, 26.764562],
                            [113.413263, 26.729819],
                            [113.400772, 26.70695],
                            [113.393597, 26.703347],
                            [113.385498, 26.701715],
                            [113.38203, 26.699147],
                            [113.381426, 26.693891],
                            [113.392194, 26.674918],
                            [113.399883, 26.666595],
                            [113.400989, 26.658231],
                            [113.407685, 26.655204],
                            [113.415476, 26.645684],
                            [113.418385, 26.63463],
                            [113.418043, 26.629352],
                            [113.412282, 26.620249],
                            [113.411358, 26.610148],
                            [113.409008, 26.607419],
                            [113.400282, 26.605227],
                            [113.397898, 26.600665],
                            [113.399118, 26.596979],
                            [113.414586, 26.587375],
                            [113.42183, 26.5749],
                            [113.427226, 26.560351],
                            [113.433066, 26.558219],
                            [113.439716, 26.558597],
                            [113.458162, 26.561786],
                            [113.467299, 26.559355],
                            [113.47371, 26.56087],
                            [113.484547, 26.568523],
                            [113.491026, 26.575319],
                            [113.49576, 26.584206],
                            [113.509152, 26.592715],
                            [113.523479, 26.595485],
                            [113.541309, 26.592695],
                            [113.554963, 26.585382],
                            [113.562207, 26.575179],
                            [113.562321, 26.566909],
                            [113.558089, 26.557322],
                            [113.558716, 26.549508],
                            [113.567363, 26.533162],
                            [113.569598, 26.523752],
                            [113.572975, 26.519127],
                            [113.579123, 26.518528],
                            [113.591021, 26.520702],
                            [113.58915, 26.51141],
                            [113.597044, 26.512208],
                            [113.603626, 26.507681]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430225,
                "name": "炎陵县",
                "center": [113.776884, 26.489459],
                "centroid": [113.844178, 26.374699],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430200
                },
                "subFeatureIndex": 7,
                "acroutes": [100000, 430000, 430200]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [113.86801, 26.652993],
                            [113.86606, 26.650265],
                            [113.856158, 26.643354],
                            [113.851504, 26.634491],
                            [113.852268, 26.630248],
                            [113.857561, 26.6225],
                            [113.858656, 26.614312],
                            [113.856147, 26.608973],
                            [113.849553, 26.600924],
                            [113.83672, 26.597696],
                            [113.822678, 26.598234],
                            [113.806263, 26.592037],
                            [113.798677, 26.584306],
                            [113.794514, 26.568961],
                            [113.78946, 26.5578],
                            [113.778601, 26.544984],
                            [113.769041, 26.541057],
                            [113.75411, 26.540478],
                            [113.726732, 26.526484],
                            [113.713591, 26.525985],
                            [113.701021, 26.520163],
                            [113.679176, 26.511251],
                            [113.67143, 26.510852],
                            [113.66259, 26.515577],
                            [113.652677, 26.522676],
                            [113.647624, 26.523712],
                            [113.638806, 26.516694],
                            [113.637232, 26.510373],
                            [113.633216, 26.505568],
                            [113.62741, 26.503653],
                            [113.619003, 26.510792],
                            [113.610254, 26.506804],
                            [113.603626, 26.507681],
                            [113.596633, 26.484489],
                            [113.596827, 26.475274],
                            [113.591135, 26.471444],
                            [113.589025, 26.467116],
                            [113.593485, 26.459017],
                            [113.603261, 26.448324],
                            [113.609626, 26.431026],
                            [113.607721, 26.419073],
                            [113.595516, 26.405562],
                            [113.588945, 26.401211],
                            [113.583219, 26.394645],
                            [113.584485, 26.383486],
                            [113.582603, 26.377837],
                            [113.583538, 26.370331],
                            [113.58923, 26.366019],
                            [113.592515, 26.360209],
                            [113.594101, 26.349467],
                            [113.597158, 26.345852],
                            [113.598972, 26.326661],
                            [113.607071, 26.315097],
                            [113.617052, 26.308266],
                            [113.616756, 26.288489],
                            [113.614634, 26.284594],
                            [113.615227, 26.27892],
                            [113.644133, 26.26797],
                            [113.650304, 26.26809],
                            [113.655483, 26.270608],
                            [113.671579, 26.25674],
                            [113.67832, 26.247267],
                            [113.683111, 26.24399],
                            [113.691507, 26.241371],
                            [113.708492, 26.234056],
                            [113.72517, 26.223742],
                            [113.727656, 26.220044],
                            [113.73093, 26.203251],
                            [113.736337, 26.191695],
                            [113.738744, 26.183177],
                            [113.738824, 26.173298],
                            [113.736531, 26.159339],
                            [113.729276, 26.139217],
                            [113.722021, 26.133116],
                            [113.702469, 26.124413],
                            [113.700119, 26.121452],
                            [113.697576, 26.107707],
                            [113.693286, 26.095821],
                            [113.693982, 26.091458],
                            [113.707922, 26.071324],
                            [113.709097, 26.0658],
                            [113.719888, 26.056032],
                            [113.728649, 26.046503],
                            [113.73644, 26.041117],
                            [113.744071, 26.039876],
                            [113.750722, 26.041618],
                            [113.761536, 26.047904],
                            [113.780939, 26.06616],
                            [113.782354, 26.073786],
                            [113.780129, 26.087216],
                            [113.780768, 26.093699],
                            [113.791605, 26.104325],
                            [113.799054, 26.104245],
                            [113.805784, 26.100003],
                            [113.811077, 26.086395],
                            [113.814043, 26.083953],
                            [113.824766, 26.084114],
                            [113.84507, 26.095701],
                            [113.847146, 26.097982],
                            [113.849622, 26.107567],
                            [113.860253, 26.119331],
                            [113.888463, 26.130355],
                            [113.89646, 26.135516],
                            [113.902813, 26.148038],
                            [113.914483, 26.158759],
                            [113.916947, 26.171319],
                            [113.930225, 26.172758],
                            [113.944723, 26.181337],
                            [113.94948, 26.192675],
                            [113.961515, 26.197114],
                            [113.97007, 26.202112],
                            [113.973538, 26.20827],
                            [113.971542, 26.219325],
                            [113.9802, 26.226461],
                            [113.98093, 26.231878],
                            [113.978911, 26.237754],
                            [113.98912, 26.246688],
                            [114.002706, 26.251344],
                            [114.011261, 26.258498],
                            [114.016771, 26.259258],
                            [114.029308, 26.266372],
                            [114.025395, 26.270248],
                            [114.02122, 26.283035],
                            [114.021596, 26.288549],
                            [114.025737, 26.2968],
                            [114.033927, 26.304231],
                            [114.041787, 26.309545],
                            [114.038867, 26.312121],
                            [114.035627, 26.323406],
                            [114.038502, 26.329897],
                            [114.047593, 26.337366],
                            [114.039757, 26.345673],
                            [114.037521, 26.354478],
                            [114.039677, 26.361427],
                            [114.031863, 26.369273],
                            [114.030562, 26.376619],
                            [114.036277, 26.377318],
                            [114.042323, 26.385882],
                            [114.042985, 26.390253],
                            [114.049898, 26.391112],
                            [114.057643, 26.398796],
                            [114.062514, 26.406101],
                            [114.071674, 26.409833],
                            [114.076476, 26.404145],
                            [114.077537, 26.406181],
                            [114.085431, 26.406261],
                            [114.08867, 26.411489],
                            [114.089058, 26.418055],
                            [114.085762, 26.423463],
                            [114.088956, 26.434817],
                            [114.086412, 26.439585],
                            [114.090233, 26.446229],
                            [114.090553, 26.455725],
                            [114.096017, 26.461271],
                            [114.097272, 26.466936],
                            [114.10958, 26.48022],
                            [114.110127, 26.482993],
                            [114.09807, 26.48415],
                            [114.086765, 26.478186],
                            [114.077366, 26.48032],
                            [114.072723, 26.479901],
                            [114.072233, 26.4874],
                            [114.078085, 26.491589],
                            [114.084199, 26.505009],
                            [114.083982, 26.513623],
                            [114.087496, 26.52118],
                            [114.086286, 26.525746],
                            [114.097534, 26.541216],
                            [114.096279, 26.548273],
                            [114.105907, 26.559674],
                            [114.108633, 26.569599],
                            [114.108108, 26.57504],
                            [114.095161, 26.577192],
                            [114.084689, 26.57777],
                            [114.07886, 26.575119],
                            [114.069655, 26.575837],
                            [114.060883, 26.580639],
                            [114.042563, 26.579922],
                            [114.033095, 26.585601],
                            [114.019737, 26.587435],
                            [114.015414, 26.593811],
                            [114.017684, 26.599609],
                            [114.012858, 26.602099],
                            [113.996386, 26.615587],
                            [113.984409, 26.614571],
                            [113.976903, 26.616205],
                            [113.973458, 26.612459],
                            [113.970264, 26.616623],
                            [113.960009, 26.618117],
                            [113.953165, 26.613714],
                            [113.934776, 26.613017],
                            [113.924544, 26.615268],
                            [113.915475, 26.613954],
                            [113.906452, 26.615687],
                            [113.903635, 26.618814],
                            [113.901353, 26.627898],
                            [113.898102, 26.630906],
                            [113.884927, 26.635786],
                            [113.881471, 26.654348],
                            [113.87717, 26.656459],
                            [113.86801, 26.652993]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430281,
                "name": "醴陵市",
                "center": [113.507157, 27.657873],
                "centroid": [113.464077, 27.657636],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430200
                },
                "subFeatureIndex": 8,
                "acroutes": [100000, 430000, 430200]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [113.624513, 27.409452],
                            [113.626224, 27.415268],
                            [113.620657, 27.418294],
                            [113.606877, 27.417523],
                            [113.601824, 27.420371],
                            [113.597729, 27.428757],
                            [113.599645, 27.450313],
                            [113.591991, 27.468167],
                            [113.600238, 27.470362],
                            [113.604139, 27.475205],
                            [113.61436, 27.477439],
                            [113.613721, 27.484101],
                            [113.620452, 27.493293],
                            [113.627022, 27.498017],
                            [113.622345, 27.50365],
                            [113.62587, 27.510725],
                            [113.628619, 27.512227],
                            [113.627319, 27.51861],
                            [113.623509, 27.519618],
                            [113.621855, 27.526772],
                            [113.616995, 27.518848],
                            [113.610425, 27.51452],
                            [113.60147, 27.516595],
                            [113.598059, 27.519243],
                            [113.586937, 27.522326],
                            [113.583378, 27.524677],
                            [113.579625, 27.531613],
                            [113.578188, 27.538054],
                            [113.578815, 27.545167],
                            [113.584964, 27.553642],
                            [113.590417, 27.557534],
                            [113.593189, 27.564468],
                            [113.599383, 27.567411],
                            [113.601504, 27.579065],
                            [113.608816, 27.58499],
                            [113.604926, 27.594588],
                            [113.606409, 27.598932],
                            [113.605257, 27.614136],
                            [113.607254, 27.62537],
                            [113.611531, 27.633109],
                            [113.619414, 27.641736],
                            [113.628813, 27.646907],
                            [113.645034, 27.657743],
                            [113.652118, 27.663625],
                            [113.659247, 27.672032],
                            [113.669742, 27.694861],
                            [113.673586, 27.699912],
                            [113.67921, 27.703483],
                            [113.696309, 27.71889],
                            [113.700416, 27.73102],
                            [113.699036, 27.741138],
                            [113.731215, 27.774482],
                            [113.751703, 27.791653],
                            [113.763212, 27.799301],
                            [113.767251, 27.80819],
                            [113.767673, 27.817138],
                            [113.765642, 27.832982],
                            [113.759334, 27.855345],
                            [113.756322, 27.860191],
                            [113.750425, 27.863481],
                            [113.742702, 27.865431],
                            [113.738972, 27.868208],
                            [113.7315, 27.869115],
                            [113.727953, 27.876068],
                            [113.730394, 27.884714],
                            [113.722877, 27.885758],
                            [113.711584, 27.877565],
                            [113.69696, 27.869902],
                            [113.686203, 27.873625],
                            [113.672206, 27.876836],
                            [113.660673, 27.884911],
                            [113.655494, 27.882233],
                            [113.654947, 27.877821],
                            [113.64492, 27.879751],
                            [113.643688, 27.884458],
                            [113.636775, 27.890288],
                            [113.632452, 27.896925],
                            [113.623246, 27.900233],
                            [113.602177, 27.913565],
                            [113.594808, 27.910335],
                            [113.597272, 27.902912],
                            [113.591386, 27.891253],
                            [113.577401, 27.899072],
                            [113.571367, 27.91258],
                            [113.573853, 27.918704],
                            [113.570386, 27.924709],
                            [113.569884, 27.930733],
                            [113.573237, 27.937938],
                            [113.578211, 27.939749],
                            [113.577298, 27.954808],
                            [113.57569, 27.959748],
                            [113.569245, 27.964629],
                            [113.55421, 27.961224],
                            [113.542347, 27.961913],
                            [113.533278, 27.958528],
                            [113.525909, 27.959905],
                            [113.523764, 27.955772],
                            [113.524437, 27.946915],
                            [113.521551, 27.942308],
                            [113.509654, 27.938726],
                            [113.501885, 27.937682],
                            [113.490193, 27.939454],
                            [113.483931, 27.93593],
                            [113.481558, 27.92715],
                            [113.463614, 27.918231],
                            [113.458173, 27.908071],
                            [113.459542, 27.900194],
                            [113.454717, 27.896964],
                            [113.446184, 27.895665],
                            [113.424191, 27.897654],
                            [113.421613, 27.89273],
                            [113.415533, 27.888969],
                            [113.41486, 27.884754],
                            [113.416183, 27.872759],
                            [113.415077, 27.861018],
                            [113.413229, 27.857807],
                            [113.401377, 27.85367],
                            [113.384392, 27.854359],
                            [113.366893, 27.851582],
                            [113.359398, 27.848075],
                            [113.361748, 27.815758],
                            [113.360391, 27.800957],
                            [113.36249, 27.797665],
                            [113.360117, 27.789504],
                            [113.350467, 27.788656],
                            [113.348276, 27.783827],
                            [113.348881, 27.77598],
                            [113.352372, 27.767246],
                            [113.352155, 27.762948],
                            [113.346565, 27.752812],
                            [113.337577, 27.751235],
                            [113.332352, 27.74526],
                            [113.32374, 27.74382],
                            [113.31888, 27.753877],
                            [113.312287, 27.755908],
                            [113.30356, 27.762297],
                            [113.298712, 27.762711],
                            [113.297343, 27.755514],
                            [113.29821, 27.750505],
                            [113.295803, 27.746975],
                            [113.300458, 27.737627],
                            [113.299773, 27.731296],
                            [113.30396, 27.726188],
                            [113.301872, 27.722539],
                            [113.295541, 27.724334],
                            [113.290362, 27.720014],
                            [113.287887, 27.713702],
                            [113.27875, 27.704686],
                            [113.280849, 27.698571],
                            [113.274267, 27.691172],
                            [113.27105, 27.690146],
                            [113.260715, 27.69279],
                            [113.256734, 27.692001],
                            [113.254213, 27.685075],
                            [113.247049, 27.684937],
                            [113.244677, 27.68912],
                            [113.225592, 27.686535],
                            [113.216923, 27.67884],
                            [113.20936, 27.662322],
                            [113.20985, 27.652631],
                            [113.206702, 27.642723],
                            [113.194188, 27.629062],
                            [113.17742, 27.616841],
                            [113.170952, 27.604165],
                            [113.172674, 27.593719],
                            [113.184869, 27.584851],
                            [113.18739, 27.568359],
                            [113.189991, 27.562433],
                            [113.196287, 27.554195],
                            [113.199527, 27.546412],
                            [113.209714, 27.53837],
                            [113.208847, 27.53106],
                            [113.201569, 27.527404],
                            [113.1972, 27.521258],
                            [113.194964, 27.513136],
                            [113.186454, 27.503808],
                            [113.183123, 27.491613],
                            [113.189466, 27.483824],
                            [113.200234, 27.479891],
                            [113.224839, 27.489992],
                            [113.229243, 27.49017],
                            [113.23441, 27.487066],
                            [113.238825, 27.481037],
                            [113.243946, 27.464826],
                            [113.25265, 27.454604],
                            [113.259768, 27.449759],
                            [113.274347, 27.4479],
                            [113.289142, 27.448335],
                            [113.294446, 27.446852],
                            [113.30121, 27.4409],
                            [113.305751, 27.433247],
                            [113.311648, 27.420213],
                            [113.317797, 27.400748],
                            [113.31766, 27.396099],
                            [113.320226, 27.389551],
                            [113.319451, 27.383377],
                            [113.324173, 27.37481],
                            [113.333459, 27.374454],
                            [113.344752, 27.37948],
                            [113.351368, 27.381122],
                            [113.361942, 27.380667],
                            [113.37052, 27.384268],
                            [113.376201, 27.399541],
                            [113.377422, 27.407751],
                            [113.381049, 27.413962],
                            [113.391578, 27.42223],
                            [113.397065, 27.423021],
                            [113.404879, 27.417463],
                            [113.425206, 27.413349],
                            [113.432906, 27.409828],
                            [113.441165, 27.403755],
                            [113.446424, 27.401797],
                            [113.457671, 27.40055],
                            [113.471394, 27.392024],
                            [113.47493, 27.391311],
                            [113.484147, 27.393666],
                            [113.490102, 27.390955],
                            [113.4973, 27.384703],
                            [113.512688, 27.383852],
                            [113.522966, 27.386583],
                            [113.528612, 27.389412],
                            [113.533175, 27.394596],
                            [113.538685, 27.407791],
                            [113.539666, 27.419224],
                            [113.543396, 27.426779],
                            [113.55729, 27.432495],
                            [113.563359, 27.427926],
                            [113.580196, 27.419639],
                            [113.590314, 27.411134],
                            [113.597124, 27.409294],
                            [113.602508, 27.411134],
                            [113.610219, 27.411134],
                            [113.616208, 27.409294],
                            [113.624513, 27.409452]
                        ]
                    ]
                ]
            }
        }]
    });
}));