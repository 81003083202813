/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

(function(root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['exports', 'echarts'], factory);
    } else if (typeof exports === 'object' && typeof exports.nodeName !== 'string') {
        // CommonJS
        factory(exports, require('echarts'));
    } else {
        // Browser globals
        factory({}, root.echarts);
    }
}(this, function(exports, echarts) {
    var log = function(msg) {
        if (typeof console !== 'undefined') {
            console && console.error && console.error(msg);
        }
    }
    if (!echarts) {
        log('ECharts is not Loaded');
        return;
    }
    if (!echarts.registerMap) {
        log('ECharts Map is not loaded')
        return;
    }
    echarts.registerMap('衡阳市', {
        "type": "FeatureCollection",
        "features": [{
            "type": "Feature",
            "properties": {
                "adcode": 430405,
                "name": "珠晖区",
                "center": [112.626324, 26.891063],
                "centroid": [112.671137, 26.876829],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430400
                },
                "subFeatureIndex": 0,
                "acroutes": [100000, 430000, 430400]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.735527, 26.976297],
                            [112.73081, 26.976966],
                            [112.722178, 26.981375],
                            [112.716922, 26.986834],
                            [112.712554, 26.994218],
                            [112.710379, 26.996634],
                            [112.707994, 27.005327],
                            [112.701851, 27.020828],
                            [112.697813, 27.024267],
                            [112.692192, 27.0272],
                            [112.684534, 27.027596],
                            [112.670525, 27.024608],
                            [112.660065, 27.020351],
                            [112.655192, 27.015916],
                            [112.654252, 27.01346],
                            [112.653104, 27.010485],
                            [112.652477, 27.001724],
                            [112.655662, 26.992362],
                            [112.658133, 26.980638],
                            [112.661475, 26.967888],
                            [112.663163, 26.953513],
                            [112.662101, 26.948338],
                            [112.659282, 26.943218],
                            [112.655714, 26.939054],
                            [112.648091, 26.932062],
                            [112.640556, 26.924634],
                            [112.636588, 26.921629],
                            [112.628895, 26.917327],
                            [112.622682, 26.914364],
                            [112.617409, 26.909092],
                            [112.616382, 26.90259],
                            [112.617113, 26.896799],
                            [112.618627, 26.89087],
                            [112.620838, 26.878507],
                            [112.621917, 26.876594],
                            [112.629957, 26.871389],
                            [112.633925, 26.869995],
                            [112.639929, 26.869025],
                            [112.644854, 26.866347],
                            [112.648822, 26.863259],
                            [112.655697, 26.861852],
                            [112.660779, 26.861469],
                            [112.664486, 26.859939],
                            [112.666905, 26.856113],
                            [112.667479, 26.850524],
                            [112.666661, 26.83135],
                            [112.664764, 26.823505],
                            [112.664033, 26.81384],
                            [112.66278, 26.810505],
                            [112.656671, 26.807252],
                            [112.652077, 26.80706],
                            [112.638589, 26.810683],
                            [112.627834, 26.811558],
                            [112.620107, 26.811612],
                            [112.613946, 26.810737],
                            [112.603939, 26.806062],
                            [112.597465, 26.800813],
                            [112.592679, 26.795194],
                            [112.586239, 26.782874],
                            [112.584673, 26.776571],
                            [112.583333, 26.77452],
                            [112.595916, 26.765658],
                            [112.604513, 26.761214],
                            [112.618592, 26.754854],
                            [112.626685, 26.750696],
                            [112.636988, 26.748002],
                            [112.649431, 26.747496],
                            [112.654078, 26.748905],
                            [112.658621, 26.751025],
                            [112.669585, 26.757453],
                            [112.676041, 26.760844],
                            [112.681558, 26.770732],
                            [112.689547, 26.773057],
                            [112.690991, 26.775969],
                            [112.691409, 26.780564],
                            [112.693184, 26.782437],
                            [112.701259, 26.78308],
                            [112.704427, 26.784091],
                            [112.707263, 26.78658],
                            [112.709561, 26.79157],
                            [112.708638, 26.793526],
                            [112.708812, 26.80557],
                            [112.706219, 26.813239],
                            [112.706585, 26.823655],
                            [112.710744, 26.83448],
                            [112.713598, 26.840657],
                            [112.717932, 26.847176],
                            [112.723135, 26.852027],
                            [112.734013, 26.860472],
                            [112.735701, 26.863041],
                            [112.737337, 26.867686],
                            [112.737859, 26.873466],
                            [112.730515, 26.886458],
                            [112.726686, 26.890365],
                            [112.724806, 26.893302],
                            [112.720682, 26.889586],
                            [112.718715, 26.891649],
                            [112.718367, 26.89602],
                            [112.720159, 26.898233],
                            [112.718628, 26.900145],
                            [112.726947, 26.90651],
                            [112.71828, 26.916754],
                            [112.710553, 26.914309],
                            [112.700911, 26.919526],
                            [112.699449, 26.923705],
                            [112.701485, 26.929495],
                            [112.706132, 26.936063],
                            [112.705366, 26.938371],
                            [112.70206, 26.941416],
                            [112.700824, 26.947888],
                            [112.701085, 26.950414],
                            [112.7038, 26.954127],
                            [112.707716, 26.955847],
                            [112.713598, 26.953922],
                            [112.718489, 26.955916],
                            [112.718698, 26.959247],
                            [112.716348, 26.962742],
                            [112.716748, 26.965322],
                            [112.721151, 26.966073],
                            [112.726755, 26.969253],
                            [112.728322, 26.970987],
                            [112.735527, 26.976297]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430406,
                "name": "雁峰区",
                "center": [112.612241, 26.893694],
                "centroid": [112.60766, 26.832564],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430400
                },
                "subFeatureIndex": 1,
                "acroutes": [100000, 430000, 430400]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.600997, 26.894873],
                            [112.602216, 26.889736],
                            [112.603939, 26.88557],
                            [112.605279, 26.877551],
                            [112.604948, 26.874927],
                            [112.602111, 26.871689],
                            [112.590381, 26.865281],
                            [112.578651, 26.858572],
                            [112.573987, 26.854487],
                            [112.576006, 26.848925],
                            [112.572612, 26.846452],
                            [112.564485, 26.835792],
                            [112.562327, 26.831924],
                            [112.557663, 26.828958],
                            [112.55681, 26.82658],
                            [112.55688, 26.821933],
                            [112.554756, 26.819814],
                            [112.550179, 26.812706],
                            [112.547429, 26.810997],
                            [112.550545, 26.810956],
                            [112.552285, 26.808619],
                            [112.548891, 26.799309],
                            [112.54689, 26.794975],
                            [112.549674, 26.786156],
                            [112.552094, 26.783613],
                            [112.555087, 26.781904],
                            [112.559751, 26.782669],
                            [112.56177, 26.785582],
                            [112.563963, 26.785035],
                            [112.569845, 26.778458],
                            [112.57987, 26.776926],
                            [112.583333, 26.77452],
                            [112.584673, 26.776571],
                            [112.586239, 26.782874],
                            [112.592679, 26.795194],
                            [112.597465, 26.800813],
                            [112.603939, 26.806062],
                            [112.613946, 26.810737],
                            [112.620107, 26.811612],
                            [112.627834, 26.811558],
                            [112.638589, 26.810683],
                            [112.652077, 26.80706],
                            [112.656671, 26.807252],
                            [112.66278, 26.810505],
                            [112.664033, 26.81384],
                            [112.664764, 26.823505],
                            [112.666661, 26.83135],
                            [112.667479, 26.850524],
                            [112.666905, 26.856113],
                            [112.664486, 26.859939],
                            [112.660779, 26.861469],
                            [112.655697, 26.861852],
                            [112.648822, 26.863259],
                            [112.644854, 26.866347],
                            [112.639929, 26.869025],
                            [112.633925, 26.869995],
                            [112.629957, 26.871389],
                            [112.621917, 26.876594],
                            [112.620838, 26.878507],
                            [112.618627, 26.89087],
                            [112.617113, 26.896799],
                            [112.606166, 26.896157],
                            [112.600997, 26.894873]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430407,
                "name": "石鼓区",
                "center": [112.607635, 26.903908],
                "centroid": [112.604714, 26.96244],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430400
                },
                "subFeatureIndex": 2,
                "acroutes": [100000, 430000, 430400]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.617113, 26.896799],
                            [112.616382, 26.90259],
                            [112.617409, 26.909092],
                            [112.622682, 26.914364],
                            [112.628895, 26.917327],
                            [112.636588, 26.921629],
                            [112.640556, 26.924634],
                            [112.648091, 26.932062],
                            [112.655714, 26.939054],
                            [112.659282, 26.943218],
                            [112.662101, 26.948338],
                            [112.663163, 26.953513],
                            [112.661475, 26.967888],
                            [112.658133, 26.980638],
                            [112.655662, 26.992362],
                            [112.652477, 27.001724],
                            [112.653104, 27.010485],
                            [112.654252, 27.01346],
                            [112.64797, 27.014497],
                            [112.644837, 27.010253],
                            [112.641095, 27.00953],
                            [112.640382, 27.007551],
                            [112.63704, 27.004658],
                            [112.63483, 27.005381],
                            [112.634116, 27.007551],
                            [112.632393, 27.007906],
                            [112.62947, 27.006296],
                            [112.621708, 27.005845],
                            [112.616678, 27.009366],
                            [112.61264, 27.010089],
                            [112.612031, 27.011181],
                            [112.615773, 27.014238],
                            [112.612849, 27.016053],
                            [112.606184, 27.015875],
                            [112.600997, 27.013214],
                            [112.60051, 27.011318],
                            [112.602407, 27.006268],
                            [112.601259, 27.002993],
                            [112.598926, 27.001915],
                            [112.594541, 27.002407],
                            [112.591147, 27.004085],
                            [112.587997, 27.006705],
                            [112.584534, 27.008247],
                            [112.581036, 27.008288],
                            [112.575397, 27.005491],
                            [112.570176, 27.000673],
                            [112.569097, 26.996429],
                            [112.566904, 26.995051],
                            [112.559159, 26.995788],
                            [112.555801, 26.994955],
                            [112.550875, 26.995078],
                            [112.546403, 26.995897],
                            [112.535247, 26.994887],
                            [112.533594, 26.991175],
                            [112.534516, 26.987571],
                            [112.540224, 26.984473],
                            [112.543514, 26.980706],
                            [112.543305, 26.975969],
                            [112.544071, 26.973799],
                            [112.552215, 26.973117],
                            [112.554565, 26.969212],
                            [112.554199, 26.967056],
                            [112.548265, 26.964121],
                            [112.547064, 26.95911],
                            [112.543879, 26.957022],
                            [112.547569, 26.950291],
                            [112.551815, 26.943942],
                            [112.563006, 26.931721],
                            [112.568627, 26.92772],
                            [112.569497, 26.925221],
                            [112.579748, 26.918242],
                            [112.583698, 26.916439],
                            [112.58831, 26.916043],
                            [112.59381, 26.916603],
                            [112.597517, 26.915292],
                            [112.600301, 26.912506],
                            [112.600232, 26.907439],
                            [112.600997, 26.894873],
                            [112.606166, 26.896157],
                            [112.617113, 26.896799]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430408,
                "name": "蒸湘区",
                "center": [112.570608, 26.89087],
                "centroid": [112.543531, 26.879172],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430400
                },
                "subFeatureIndex": 3,
                "acroutes": [100000, 430000, 430400]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.600997, 26.894873],
                            [112.600232, 26.907439],
                            [112.600301, 26.912506],
                            [112.597517, 26.915292],
                            [112.59381, 26.916603],
                            [112.58831, 26.916043],
                            [112.583698, 26.916439],
                            [112.579748, 26.918242],
                            [112.569497, 26.925221],
                            [112.568627, 26.92772],
                            [112.563006, 26.931721],
                            [112.551815, 26.943942],
                            [112.547569, 26.950291],
                            [112.543879, 26.957022],
                            [112.538449, 26.953076],
                            [112.526771, 26.946959],
                            [112.527676, 26.943505],
                            [112.526023, 26.938781],
                            [112.523152, 26.938972],
                            [112.518418, 26.943054],
                            [112.513632, 26.943846],
                            [112.511839, 26.941703],
                            [112.510081, 26.928662],
                            [112.510482, 26.922763],
                            [112.510099, 26.918461],
                            [112.505939, 26.914186],
                            [112.50366, 26.914678],
                            [112.502319, 26.918829],
                            [112.500318, 26.920974],
                            [112.494766, 26.921137],
                            [112.492295, 26.919731],
                            [112.49052, 26.91562],
                            [112.490485, 26.912643],
                            [112.493217, 26.90972],
                            [112.498473, 26.907876],
                            [112.504199, 26.907589],
                            [112.505957, 26.906278],
                            [112.510812, 26.899899],
                            [112.512152, 26.894996],
                            [112.510447, 26.890857],
                            [112.512013, 26.884409],
                            [112.511978, 26.881321],
                            [112.508741, 26.873725],
                            [112.508811, 26.870186],
                            [112.514833, 26.868656],
                            [112.513754, 26.863792],
                            [112.510586, 26.860608],
                            [112.50996, 26.857725],
                            [112.510691, 26.853189],
                            [112.50855, 26.849868],
                            [112.504338, 26.848925],
                            [112.500214, 26.849021],
                            [112.498717, 26.846178],
                            [112.496768, 26.846137],
                            [112.490085, 26.848597],
                            [112.485804, 26.849595],
                            [112.48509, 26.847914],
                            [112.488379, 26.845126],
                            [112.485769, 26.841614],
                            [112.479277, 26.842133],
                            [112.47785, 26.840029],
                            [112.481644, 26.83873],
                            [112.482184, 26.837104],
                            [112.479782, 26.833769],
                            [112.485264, 26.829272],
                            [112.489336, 26.824735],
                            [112.494871, 26.824092],
                            [112.500475, 26.827468],
                            [112.505939, 26.828316],
                            [112.510012, 26.825473],
                            [112.512779, 26.822684],
                            [112.513266, 26.820388],
                            [112.508237, 26.806254],
                            [112.509977, 26.803478],
                            [112.513301, 26.803396],
                            [112.514728, 26.80598],
                            [112.518609, 26.808851],
                            [112.521046, 26.808824],
                            [112.527032, 26.807334],
                            [112.531923, 26.808222],
                            [112.539354, 26.811093],
                            [112.547429, 26.810997],
                            [112.550179, 26.812706],
                            [112.554756, 26.819814],
                            [112.55688, 26.821933],
                            [112.55681, 26.82658],
                            [112.557663, 26.828958],
                            [112.562327, 26.831924],
                            [112.564485, 26.835792],
                            [112.572612, 26.846452],
                            [112.576006, 26.848925],
                            [112.573987, 26.854487],
                            [112.578651, 26.858572],
                            [112.590381, 26.865281],
                            [112.602111, 26.871689],
                            [112.604948, 26.874927],
                            [112.605279, 26.877551],
                            [112.603939, 26.88557],
                            [112.602216, 26.889736],
                            [112.600997, 26.894873]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430412,
                "name": "南岳区",
                "center": [112.734147, 27.240536],
                "centroid": [112.696422, 27.257198],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430400
                },
                "subFeatureIndex": 4,
                "acroutes": [100000, 430000, 430400]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.574892, 27.243696],
                            [112.578025, 27.242021],
                            [112.579139, 27.238194],
                            [112.576981, 27.236179],
                            [112.571081, 27.23498],
                            [112.569253, 27.232788],
                            [112.570437, 27.231344],
                            [112.582619, 27.228729],
                            [112.589093, 27.225365],
                            [112.597534, 27.226509],
                            [112.60587, 27.224602],
                            [112.608777, 27.224385],
                            [112.61506, 27.221647],
                            [112.621273, 27.213992],
                            [112.623222, 27.212453],
                            [112.627764, 27.210914],
                            [112.63342, 27.207372],
                            [112.640225, 27.206459],
                            [112.645463, 27.204716],
                            [112.649118, 27.205247],
                            [112.649606, 27.20413],
                            [112.647883, 27.199512],
                            [112.648161, 27.197578],
                            [112.652181, 27.199226],
                            [112.655471, 27.199553],
                            [112.663163, 27.204798],
                            [112.670177, 27.206378],
                            [112.680462, 27.206459],
                            [112.682707, 27.206078],
                            [112.688154, 27.198858],
                            [112.69475, 27.199716],
                            [112.703, 27.201828],
                            [112.70977, 27.201215],
                            [112.717079, 27.199158],
                            [112.725119, 27.197373],
                            [112.727347, 27.195153],
                            [112.730132, 27.189431],
                            [112.733212, 27.189758],
                            [112.739042, 27.194649],
                            [112.741688, 27.193695],
                            [112.742906, 27.189976],
                            [112.745847, 27.189935],
                            [112.74884, 27.193286],
                            [112.751816, 27.194758],
                            [112.755993, 27.192823],
                            [112.762259, 27.190861],
                            [112.765217, 27.19082],
                            [112.76868, 27.192155],
                            [112.768698, 27.19345],
                            [112.764069, 27.196624],
                            [112.762467, 27.199825],
                            [112.764556, 27.202495],
                            [112.767497, 27.202836],
                            [112.76922, 27.204689],
                            [112.769324, 27.211091],
                            [112.763529, 27.217179],
                            [112.761528, 27.224357],
                            [112.761632, 27.230745],
                            [112.759544, 27.231902],
                            [112.751973, 27.231998],
                            [112.748318, 27.2407],
                            [112.750494, 27.244431],
                            [112.756394, 27.245493],
                            [112.766923, 27.245357],
                            [112.768628, 27.246841],
                            [112.768663, 27.249851],
                            [112.767445, 27.252874],
                            [112.766314, 27.260035],
                            [112.766853, 27.267551],
                            [112.768628, 27.273922],
                            [112.775381, 27.274971],
                            [112.777121, 27.279463],
                            [112.780567, 27.283547],
                            [112.781959, 27.291428],
                            [112.779105, 27.297866],
                            [112.772039, 27.30361],
                            [112.76198, 27.30674],
                            [112.750215, 27.307271],
                            [112.739321, 27.311163],
                            [112.732707, 27.320649],
                            [112.732011, 27.330065],
                            [112.728235, 27.331235],
                            [112.7219, 27.330555],
                            [112.713825, 27.326119],
                            [112.706202, 27.325071],
                            [112.703713, 27.327738],
                            [112.703852, 27.329902],
                            [112.699536, 27.330786],
                            [112.693602, 27.328963],
                            [112.68932, 27.325248],
                            [112.689268, 27.321111],
                            [112.68751, 27.31662],
                            [112.684082, 27.31364],
                            [112.679435, 27.313695],
                            [112.679122, 27.320458],
                            [112.675363, 27.323506],
                            [112.665582, 27.323846],
                            [112.660866, 27.31349],
                            [112.658655, 27.306373],
                            [112.656915, 27.30376],
                            [112.653104, 27.303038],
                            [112.645899, 27.30048],
                            [112.644541, 27.294096],
                            [112.63986, 27.29151],
                            [112.632707, 27.29234],
                            [112.619236, 27.292857],
                            [112.618331, 27.289482],
                            [112.619132, 27.28646],
                            [112.627016, 27.278102],
                            [112.630357, 27.275801],
                            [112.650998, 27.273718],
                            [112.658551, 27.271377],
                            [112.660222, 27.269852],
                            [112.660987, 27.265332],
                            [112.65923, 27.26009],
                            [112.652877, 27.258265],
                            [112.64616, 27.260975],
                            [112.642783, 27.261383],
                            [112.64066, 27.259899],
                            [112.64019, 27.256141],
                            [112.639981, 27.241462],
                            [112.636135, 27.238126],
                            [112.629383, 27.237445],
                            [112.622212, 27.237513],
                            [112.618871, 27.239433],
                            [112.606358, 27.247849],
                            [112.595028, 27.250613],
                            [112.588676, 27.250654],
                            [112.577329, 27.247059],
                            [112.574892, 27.243696]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430421,
                "name": "衡阳县",
                "center": [112.379643, 26.962388],
                "centroid": [112.347267, 27.102969],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430400
                },
                "subFeatureIndex": 5,
                "acroutes": [100000, 430000, 430400]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.00162, 26.944311],
                            [112.002003, 26.937989],
                            [112.003674, 26.933715],
                            [112.003447, 26.928266],
                            [112.004318, 26.925357],
                            [112.007067, 26.920878],
                            [112.010722, 26.917573],
                            [112.015699, 26.908245],
                            [112.015874, 26.905131],
                            [112.020851, 26.902454],
                            [112.02421, 26.896812],
                            [112.027934, 26.893684],
                            [112.035853, 26.890406],
                            [112.03902, 26.886157],
                            [112.038585, 26.883343],
                            [112.039682, 26.882223],
                            [112.051586, 26.879805],
                            [112.055936, 26.881116],
                            [112.059469, 26.879217],
                            [112.063959, 26.87934],
                            [112.072766, 26.876471],
                            [112.080928, 26.875269],
                            [112.089282, 26.876854],
                            [112.095999, 26.876772],
                            [112.101151, 26.877455],
                            [112.105711, 26.876936],
                            [112.114203, 26.876867],
                            [112.120051, 26.878466],
                            [112.122174, 26.879654],
                            [112.138969, 26.888357],
                            [112.14586, 26.891089],
                            [112.151377, 26.894777],
                            [112.156181, 26.896785],
                            [112.158078, 26.899763],
                            [112.162272, 26.901525],
                            [112.160532, 26.894504],
                            [112.16175, 26.891061],
                            [112.164343, 26.887414],
                            [112.166014, 26.887182],
                            [112.168955, 26.891895],
                            [112.174977, 26.890023],
                            [112.176647, 26.890938],
                            [112.177465, 26.894736],
                            [112.180232, 26.895515],
                            [112.183435, 26.894449],
                            [112.186219, 26.896539],
                            [112.191336, 26.896129],
                            [112.196592, 26.899954],
                            [112.198732, 26.900432],
                            [112.203484, 26.903628],
                            [112.207817, 26.904011],
                            [112.210897, 26.902331],
                            [112.212655, 26.900036],
                            [112.215039, 26.899858],
                            [112.223985, 26.902576],
                            [112.232199, 26.906838],
                            [112.235715, 26.904939],
                            [112.239752, 26.89867],
                            [112.241824, 26.897468],
                            [112.245861, 26.897796],
                            [112.249029, 26.89602],
                            [112.255886, 26.894121],
                            [112.2637, 26.890187],
                            [112.264239, 26.894121],
                            [112.26638, 26.899148],
                            [112.270087, 26.901047],
                            [112.274246, 26.899763],
                            [112.283087, 26.894927],
                            [112.284845, 26.894914],
                            [112.293042, 26.900582],
                            [112.296175, 26.900746],
                            [112.29795, 26.897673],
                            [112.302457, 26.896457],
                            [112.305277, 26.892441],
                            [112.309158, 26.891485],
                            [112.312273, 26.88878],
                            [112.317825, 26.888739],
                            [112.323237, 26.885925],
                            [112.326857, 26.885898],
                            [112.333697, 26.887223],
                            [112.33904, 26.891977],
                            [112.34748, 26.892974],
                            [112.350056, 26.891772],
                            [112.356443, 26.885515],
                            [112.357714, 26.881157],
                            [112.359837, 26.880228],
                            [112.364814, 26.881294],
                            [112.369826, 26.885829],
                            [112.372176, 26.887018],
                            [112.376301, 26.886799],
                            [112.378859, 26.889832],
                            [112.380164, 26.888889],
                            [112.381452, 26.880269],
                            [112.38274, 26.878275],
                            [112.386621, 26.877605],
                            [112.385333, 26.873752],
                            [112.389214, 26.873042],
                            [112.396628, 26.876335],
                            [112.397759, 26.877387],
                            [112.397864, 26.880747],
                            [112.400126, 26.883753],
                            [112.41076, 26.882961],
                            [112.4125, 26.883999],
                            [112.418574, 26.885624],
                            [112.421028, 26.887537],
                            [112.418086, 26.891485],
                            [112.418104, 26.897167],
                            [112.424613, 26.901552],
                            [112.422785, 26.905431],
                            [112.4251, 26.908217],
                            [112.426562, 26.913121],
                            [112.429538, 26.91842],
                            [112.43589, 26.921151],
                            [112.436639, 26.925207],
                            [112.436116, 26.927324],
                            [112.433558, 26.927788],
                            [112.437544, 26.930547],
                            [112.441233, 26.930506],
                            [112.446141, 26.929208],
                            [112.449448, 26.927611],
                            [112.455295, 26.923609],
                            [112.457401, 26.921028],
                            [112.458532, 26.916685],
                            [112.457175, 26.913571],
                            [112.457975, 26.911386],
                            [112.463475, 26.906524],
                            [112.465685, 26.906182],
                            [112.47028, 26.907617],
                            [112.47329, 26.906578],
                            [112.476388, 26.902932],
                            [112.479434, 26.901252],
                            [112.485299, 26.908286],
                            [112.487335, 26.908122],
                            [112.487805, 26.905909],
                            [112.490502, 26.902344],
                            [112.494418, 26.902044],
                            [112.496959, 26.904161],
                            [112.498473, 26.907876],
                            [112.493217, 26.90972],
                            [112.490485, 26.912643],
                            [112.49052, 26.91562],
                            [112.492295, 26.919731],
                            [112.494766, 26.921137],
                            [112.500318, 26.920974],
                            [112.502319, 26.918829],
                            [112.50366, 26.914678],
                            [112.505939, 26.914186],
                            [112.510099, 26.918461],
                            [112.510482, 26.922763],
                            [112.510081, 26.928662],
                            [112.511839, 26.941703],
                            [112.513632, 26.943846],
                            [112.518418, 26.943054],
                            [112.523152, 26.938972],
                            [112.526023, 26.938781],
                            [112.527676, 26.943505],
                            [112.526771, 26.946959],
                            [112.538449, 26.953076],
                            [112.543879, 26.957022],
                            [112.547064, 26.95911],
                            [112.548265, 26.964121],
                            [112.554199, 26.967056],
                            [112.554565, 26.969212],
                            [112.552215, 26.973117],
                            [112.544071, 26.973799],
                            [112.543305, 26.975969],
                            [112.543514, 26.980706],
                            [112.540224, 26.984473],
                            [112.534516, 26.987571],
                            [112.533594, 26.991175],
                            [112.535247, 26.994887],
                            [112.546403, 26.995897],
                            [112.550875, 26.995078],
                            [112.555801, 26.994955],
                            [112.559159, 26.995788],
                            [112.566904, 26.995051],
                            [112.569097, 26.996429],
                            [112.570176, 27.000673],
                            [112.575397, 27.005491],
                            [112.581036, 27.008288],
                            [112.584534, 27.008247],
                            [112.587997, 27.006705],
                            [112.591147, 27.004085],
                            [112.594541, 27.002407],
                            [112.598926, 27.001915],
                            [112.601259, 27.002993],
                            [112.602407, 27.006268],
                            [112.60051, 27.011318],
                            [112.600997, 27.013214],
                            [112.606184, 27.015875],
                            [112.612849, 27.016053],
                            [112.615773, 27.014238],
                            [112.612031, 27.011181],
                            [112.61264, 27.010089],
                            [112.616678, 27.009366],
                            [112.621708, 27.005845],
                            [112.62947, 27.006296],
                            [112.632393, 27.007906],
                            [112.634116, 27.007551],
                            [112.63483, 27.005381],
                            [112.63704, 27.004658],
                            [112.640382, 27.007551],
                            [112.641095, 27.00953],
                            [112.644837, 27.010253],
                            [112.64797, 27.014497],
                            [112.654252, 27.01346],
                            [112.655192, 27.015916],
                            [112.660065, 27.020351],
                            [112.670525, 27.024608],
                            [112.684534, 27.027596],
                            [112.692192, 27.0272],
                            [112.697813, 27.024267],
                            [112.701851, 27.020828],
                            [112.707994, 27.005327],
                            [112.710379, 26.996634],
                            [112.712554, 26.994218],
                            [112.716922, 26.986834],
                            [112.722178, 26.981375],
                            [112.73081, 26.976966],
                            [112.735527, 26.976297],
                            [112.737406, 26.983463],
                            [112.737372, 26.98622],
                            [112.73939, 26.991188],
                            [112.742558, 26.994805],
                            [112.748162, 26.994],
                            [112.751851, 26.996593],
                            [112.751173, 26.999159],
                            [112.74757, 26.999063],
                            [112.746752, 27.002884],
                            [112.745046, 27.003758],
                            [112.739843, 27.003894],
                            [112.73939, 27.006637],
                            [112.743428, 27.010717],
                            [112.743115, 27.013801],
                            [112.740365, 27.016162],
                            [112.738137, 27.016707],
                            [112.727347, 27.014824],
                            [112.720664, 27.009871],
                            [112.718245, 27.01114],
                            [112.719289, 27.025672],
                            [112.720734, 27.029847],
                            [112.717688, 27.034445],
                            [112.718854, 27.036587],
                            [112.724684, 27.042207],
                            [112.727277, 27.045809],
                            [112.727939, 27.049287],
                            [112.727956, 27.060677],
                            [112.72646, 27.063473],
                            [112.728374, 27.065287],
                            [112.728478, 27.068983],
                            [112.725259, 27.069352],
                            [112.727086, 27.075216],
                            [112.726686, 27.07808],
                            [112.72451, 27.082212],
                            [112.722404, 27.084544],
                            [112.716922, 27.08494],
                            [112.716365, 27.089167],
                            [112.714956, 27.09184],
                            [112.711649, 27.092276],
                            [112.708569, 27.089494],
                            [112.704427, 27.090367],
                            [112.700058, 27.097935],
                            [112.69489, 27.10392],
                            [112.692209, 27.104589],
                            [112.687319, 27.102666],
                            [112.675189, 27.095671],
                            [112.66948, 27.10028],
                            [112.665286, 27.102952],
                            [112.659908, 27.103048],
                            [112.656706, 27.104493],
                            [112.65145, 27.109292],
                            [112.649606, 27.112469],
                            [112.653208, 27.121807],
                            [112.654531, 27.128174],
                            [112.657193, 27.130382],
                            [112.659404, 27.130941],
                            [112.668836, 27.130818],
                            [112.672717, 27.134689],
                            [112.671934, 27.140046],
                            [112.665112, 27.144176],
                            [112.663737, 27.145893],
                            [112.663302, 27.150118],
                            [112.665408, 27.158813],
                            [112.665982, 27.16346],
                            [112.665112, 27.171854],
                            [112.654687, 27.182455],
                            [112.650806, 27.18315],
                            [112.652268, 27.187591],
                            [112.648944, 27.193273],
                            [112.648161, 27.197578],
                            [112.647883, 27.199512],
                            [112.649606, 27.20413],
                            [112.649118, 27.205247],
                            [112.645463, 27.204716],
                            [112.640225, 27.206459],
                            [112.63342, 27.207372],
                            [112.627764, 27.210914],
                            [112.623222, 27.212453],
                            [112.621273, 27.213992],
                            [112.61506, 27.221647],
                            [112.608777, 27.224385],
                            [112.60587, 27.224602],
                            [112.597534, 27.226509],
                            [112.589093, 27.225365],
                            [112.582619, 27.228729],
                            [112.570437, 27.231344],
                            [112.569253, 27.232788],
                            [112.571081, 27.23498],
                            [112.576981, 27.236179],
                            [112.579139, 27.238194],
                            [112.578025, 27.242021],
                            [112.574892, 27.243696],
                            [112.567165, 27.244104],
                            [112.562379, 27.245752],
                            [112.556392, 27.24552],
                            [112.55044, 27.246365],
                            [112.54863, 27.250395],
                            [112.54501, 27.24936],
                            [112.539598, 27.253255],
                            [112.537823, 27.253568],
                            [112.538832, 27.24917],
                            [112.536465, 27.248339],
                            [112.530583, 27.249006],
                            [112.528164, 27.25399],
                            [112.527746, 27.257544],
                            [112.531905, 27.260172],
                            [112.531749, 27.263385],
                            [112.533628, 27.268],
                            [112.533402, 27.269348],
                            [112.530078, 27.271091],
                            [112.526441, 27.274971],
                            [112.526754, 27.276672],
                            [112.530757, 27.284595],
                            [112.530705, 27.286746],
                            [112.526893, 27.289836],
                            [112.526737, 27.293048],
                            [112.528112, 27.297975],
                            [112.527781, 27.300466],
                            [112.521637, 27.30361],
                            [112.518035, 27.306359],
                            [112.516643, 27.310619],
                            [112.517983, 27.316811],
                            [112.511439, 27.321138],
                            [112.510203, 27.324214],
                            [112.505974, 27.325561],
                            [112.502372, 27.328636],
                            [112.50272, 27.332596],
                            [112.500022, 27.333875],
                            [112.499013, 27.339059],
                            [112.500597, 27.343344],
                            [112.50305, 27.343467],
                            [112.50312, 27.345875],
                            [112.501554, 27.348052],
                            [112.497621, 27.347943],
                            [112.494732, 27.349603],
                            [112.489336, 27.349847],
                            [112.486065, 27.346324],
                            [112.480739, 27.348555],
                            [112.47705, 27.349331],
                            [112.474352, 27.348677],
                            [112.471794, 27.351725],
                            [112.469949, 27.355942],
                            [112.468957, 27.356201],
                            [112.466399, 27.352936],
                            [112.462901, 27.356078],
                            [112.461352, 27.356432],
                            [112.459907, 27.355167],
                            [112.456183, 27.356296],
                            [112.455034, 27.354337],
                            [112.456392, 27.350854],
                            [112.456009, 27.347861],
                            [112.454408, 27.347412],
                            [112.451901, 27.349807],
                            [112.449639, 27.349126],
                            [112.440433, 27.344827],
                            [112.4381, 27.344596],
                            [112.435333, 27.348351],
                            [112.432357, 27.354214],
                            [112.424178, 27.354854],
                            [112.418748, 27.357438],
                            [112.415493, 27.358255],
                            [112.408532, 27.361451],
                            [112.391633, 27.359289],
                            [112.382949, 27.358785],
                            [112.368121, 27.356092],
                            [112.370523, 27.363873],
                            [112.368086, 27.367355],
                            [112.362621, 27.367396],
                            [112.360307, 27.366008],
                            [112.361072, 27.36356],
                            [112.358758, 27.362472],
                            [112.352075, 27.365832],
                            [112.345705, 27.365491],
                            [112.339266, 27.365927],
                            [112.335472, 27.368865],
                            [112.331069, 27.370184],
                            [112.325326, 27.367763],
                            [112.321532, 27.364022],
                            [112.312116, 27.367083],
                            [112.309349, 27.365532],
                            [112.308357, 27.362934],
                            [112.309123, 27.360404],
                            [112.307417, 27.359003],
                            [112.303467, 27.359819],
                            [112.299725, 27.358744],
                            [112.298785, 27.356473],
                            [112.296001, 27.35397],
                            [112.292155, 27.348419],
                            [112.28662, 27.343739],
                            [112.283401, 27.339739],
                            [112.282565, 27.337127],
                            [112.285715, 27.333779],
                            [112.287838, 27.328935],
                            [112.286394, 27.324241],
                            [112.28079, 27.319873],
                            [112.280163, 27.315192],
                            [112.281277, 27.312987],
                            [112.27985, 27.312211],
                            [112.272976, 27.311953],
                            [112.267998, 27.308836],
                            [112.26725, 27.305066],
                            [112.265144, 27.305475],
                            [112.257521, 27.310347],
                            [112.256112, 27.310673],
                            [112.254232, 27.308169],
                            [112.253762, 27.305257],
                            [112.255903, 27.303161],
                            [112.255851, 27.297798],
                            [112.254928, 27.295689],
                            [112.248489, 27.293851],
                            [112.244121, 27.289795],
                            [112.239039, 27.287835],
                            [112.237246, 27.284935],
                            [112.237995, 27.281069],
                            [112.235941, 27.277789],
                            [112.236585, 27.273214],
                            [112.233209, 27.269457],
                            [112.23307, 27.265373],
                            [112.230842, 27.263031],
                            [112.226595, 27.260866],
                            [112.223776, 27.261206],
                            [112.22073, 27.264229],
                            [112.216623, 27.266312],
                            [112.21396, 27.268899],
                            [112.21403, 27.274807],
                            [112.217546, 27.275556],
                            [112.211507, 27.278224],
                            [112.209697, 27.281777],
                            [112.207069, 27.282676],
                            [112.206216, 27.285126],
                            [112.208583, 27.291251],
                            [112.205363, 27.296533],
                            [112.197671, 27.295811],
                            [112.193912, 27.297975],
                            [112.192798, 27.300657],
                            [112.195461, 27.303161],
                            [112.195391, 27.304576],
                            [112.191997, 27.307911],
                            [112.191858, 27.31115],
                            [112.190622, 27.311789],
                            [112.185906, 27.309272],
                            [112.18239, 27.309789],
                            [112.180232, 27.314293],
                            [112.177639, 27.317559],
                            [112.174837, 27.319628],
                            [112.163891, 27.324554],
                            [112.160097, 27.322935],
                            [112.159105, 27.321519],
                            [112.159226, 27.316403],
                            [112.153796, 27.30968],
                            [112.152822, 27.30772],
                            [112.153675, 27.305665],
                            [112.159505, 27.308836],
                            [112.161089, 27.308346],
                            [112.16088, 27.305039],
                            [112.157956, 27.303025],
                            [112.155311, 27.302426],
                            [112.153692, 27.300194],
                            [112.158391, 27.290067],
                            [112.161176, 27.287753],
                            [112.162811, 27.2842],
                            [112.163055, 27.277299],
                            [112.158948, 27.271744],
                            [112.155937, 27.26894],
                            [112.150542, 27.265849],
                            [112.149724, 27.259355],
                            [112.150716, 27.254616],
                            [112.155763, 27.248898],
                            [112.160062, 27.247522],
                            [112.156859, 27.243777],
                            [112.154075, 27.239393],
                            [112.152317, 27.238453],
                            [112.146156, 27.23829],
                            [112.140587, 27.236996],
                            [112.137924, 27.236982],
                            [112.1346, 27.234694],
                            [112.132808, 27.229601],
                            [112.127952, 27.22764],
                            [112.127848, 27.226073],
                            [112.13018, 27.22211],
                            [112.129519, 27.220898],
                            [112.125986, 27.220094],
                            [112.118659, 27.223172],
                            [112.115021, 27.221906],
                            [112.11262, 27.222314],
                            [112.111837, 27.215913],
                            [112.112568, 27.21327],
                            [112.114673, 27.21214],
                            [112.114203, 27.210737],
                            [112.109417, 27.210968],
                            [112.10719, 27.214156],
                            [112.100855, 27.208489],
                            [112.099584, 27.20665],
                            [112.098018, 27.209524],
                            [112.097148, 27.207495],
                            [112.094938, 27.208871],
                            [112.092153, 27.205683],
                            [112.09278, 27.202482],
                            [112.089995, 27.19973],
                            [112.088516, 27.195262],
                            [112.092553, 27.195221],
                            [112.091266, 27.192605],
                            [112.086828, 27.191883],
                            [112.086793, 27.189417],
                            [112.089543, 27.190684],
                            [112.091701, 27.190289],
                            [112.09278, 27.187169],
                            [112.08996, 27.185493],
                            [112.084165, 27.187101],
                            [112.076734, 27.185166],
                            [112.074158, 27.185779],
                            [112.075289, 27.188082],
                            [112.070677, 27.191066],
                            [112.067545, 27.188123],
                            [112.068484, 27.185207],
                            [112.063925, 27.18161],
                            [112.058129, 27.175438],
                            [112.054248, 27.167766],
                            [112.052073, 27.16162],
                            [112.051725, 27.158418],
                            [112.04901, 27.1508],
                            [112.049079, 27.147692],
                            [112.052473, 27.142854],
                            [112.048662, 27.135453],
                            [112.046608, 27.132699],
                            [112.046434, 27.128664],
                            [112.049045, 27.123961],
                            [112.054039, 27.119354],
                            [112.056023, 27.116641],
                            [112.05665, 27.111242],
                            [112.054596, 27.107056],
                            [112.051707, 27.102871],
                            [112.051185, 27.100335],
                            [112.052125, 27.093735],
                            [112.056232, 27.085758],
                            [112.056197, 27.081258],
                            [112.054509, 27.079621],
                            [112.046939, 27.077262],
                            [112.046243, 27.074275],
                            [112.050559, 27.071097],
                            [112.060601, 27.067892],
                            [112.071808, 27.064987],
                            [112.074784, 27.060623],
                            [112.075759, 27.057008],
                            [112.075881, 27.05252],
                            [112.077534, 27.049656],
                            [112.082807, 27.049396],
                            [112.083451, 27.046395],
                            [112.07924, 27.044172],
                            [112.078039, 27.041784],
                            [112.079518, 27.038619],
                            [112.082181, 27.036505],
                            [112.084861, 27.036041],
                            [112.086845, 27.033926],
                            [112.085783, 27.029438],
                            [112.086114, 27.027337],
                            [112.089282, 27.025072],
                            [112.089386, 27.019668],
                            [112.08681, 27.013242],
                            [112.082842, 27.009298],
                            [112.08098, 27.008561],
                            [112.072296, 27.011618],
                            [112.067266, 27.010294],
                            [112.060096, 27.013037],
                            [112.055031, 27.011113],
                            [112.050176, 27.006446],
                            [112.048731, 27.004099],
                            [112.047722, 26.999199],
                            [112.037315, 26.996675],
                            [112.043214, 26.995651],
                            [112.043789, 26.992962],
                            [112.047182, 26.987681],
                            [112.047652, 26.985729],
                            [112.047618, 26.976611],
                            [112.04713, 26.975273],
                            [112.042327, 26.970987],
                            [112.036183, 26.972584],
                            [112.034304, 26.971711],
                            [112.031049, 26.967861],
                            [112.026716, 26.965704],
                            [112.021913, 26.966346],
                            [112.019859, 26.964912],
                            [112.018919, 26.959725],
                            [112.016291, 26.955424],
                            [112.012723, 26.953376],
                            [112.00705, 26.952653],
                            [112.005257, 26.94883],
                            [112.00162, 26.944311]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430422,
                "name": "衡南县",
                "center": [112.677459, 26.739973],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430400
                },
                "subFeatureIndex": 6,
                "acroutes": [100000, 430000, 430400]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.583333, 26.77452],
                            [112.57987, 26.776926],
                            [112.569845, 26.778458],
                            [112.563963, 26.785035],
                            [112.56177, 26.785582],
                            [112.559751, 26.782669],
                            [112.555087, 26.781904],
                            [112.552094, 26.783613],
                            [112.549674, 26.786156],
                            [112.54689, 26.794975],
                            [112.548891, 26.799309],
                            [112.552285, 26.808619],
                            [112.550545, 26.810956],
                            [112.547429, 26.810997],
                            [112.539354, 26.811093],
                            [112.531923, 26.808222],
                            [112.527032, 26.807334],
                            [112.521046, 26.808824],
                            [112.518609, 26.808851],
                            [112.514728, 26.80598],
                            [112.513301, 26.803396],
                            [112.509977, 26.803478],
                            [112.508237, 26.806254],
                            [112.513266, 26.820388],
                            [112.512779, 26.822684],
                            [112.510012, 26.825473],
                            [112.505939, 26.828316],
                            [112.500475, 26.827468],
                            [112.494871, 26.824092],
                            [112.489336, 26.824735],
                            [112.485264, 26.829272],
                            [112.479782, 26.833769],
                            [112.482184, 26.837104],
                            [112.481644, 26.83873],
                            [112.47785, 26.840029],
                            [112.479277, 26.842133],
                            [112.485769, 26.841614],
                            [112.488379, 26.845126],
                            [112.48509, 26.847914],
                            [112.485804, 26.849595],
                            [112.490085, 26.848597],
                            [112.496768, 26.846137],
                            [112.498717, 26.846178],
                            [112.500214, 26.849021],
                            [112.504338, 26.848925],
                            [112.50855, 26.849868],
                            [112.510691, 26.853189],
                            [112.50996, 26.857725],
                            [112.510586, 26.860608],
                            [112.513754, 26.863792],
                            [112.514833, 26.868656],
                            [112.508811, 26.870186],
                            [112.508741, 26.873725],
                            [112.511978, 26.881321],
                            [112.512013, 26.884409],
                            [112.510447, 26.890857],
                            [112.512152, 26.894996],
                            [112.510812, 26.899899],
                            [112.505957, 26.906278],
                            [112.504199, 26.907589],
                            [112.498473, 26.907876],
                            [112.496959, 26.904161],
                            [112.494418, 26.902044],
                            [112.490502, 26.902344],
                            [112.487805, 26.905909],
                            [112.487335, 26.908122],
                            [112.485299, 26.908286],
                            [112.479434, 26.901252],
                            [112.476388, 26.902932],
                            [112.47329, 26.906578],
                            [112.47028, 26.907617],
                            [112.465685, 26.906182],
                            [112.463475, 26.906524],
                            [112.457975, 26.911386],
                            [112.457175, 26.913571],
                            [112.458532, 26.916685],
                            [112.457401, 26.921028],
                            [112.455295, 26.923609],
                            [112.449448, 26.927611],
                            [112.446141, 26.929208],
                            [112.441233, 26.930506],
                            [112.437544, 26.930547],
                            [112.433558, 26.927788],
                            [112.436116, 26.927324],
                            [112.436639, 26.925207],
                            [112.43589, 26.921151],
                            [112.429538, 26.91842],
                            [112.426562, 26.913121],
                            [112.4251, 26.908217],
                            [112.422785, 26.905431],
                            [112.424613, 26.901552],
                            [112.418104, 26.897167],
                            [112.418086, 26.891485],
                            [112.421028, 26.887537],
                            [112.418574, 26.885624],
                            [112.4125, 26.883999],
                            [112.41076, 26.882961],
                            [112.400126, 26.883753],
                            [112.397864, 26.880747],
                            [112.397759, 26.877387],
                            [112.396628, 26.876335],
                            [112.389214, 26.873042],
                            [112.385333, 26.873752],
                            [112.386621, 26.877605],
                            [112.38274, 26.878275],
                            [112.381452, 26.880269],
                            [112.380164, 26.888889],
                            [112.378859, 26.889832],
                            [112.376301, 26.886799],
                            [112.372176, 26.887018],
                            [112.369826, 26.885829],
                            [112.364814, 26.881294],
                            [112.359837, 26.880228],
                            [112.357714, 26.881157],
                            [112.356443, 26.885515],
                            [112.350056, 26.891772],
                            [112.34748, 26.892974],
                            [112.33904, 26.891977],
                            [112.333697, 26.887223],
                            [112.326857, 26.885898],
                            [112.323237, 26.885925],
                            [112.317825, 26.888739],
                            [112.312273, 26.88878],
                            [112.309158, 26.891485],
                            [112.305277, 26.892441],
                            [112.302457, 26.896457],
                            [112.29795, 26.897673],
                            [112.296175, 26.900746],
                            [112.293042, 26.900582],
                            [112.284845, 26.894914],
                            [112.283087, 26.894927],
                            [112.274246, 26.899763],
                            [112.270087, 26.901047],
                            [112.26638, 26.899148],
                            [112.264239, 26.894121],
                            [112.2637, 26.890187],
                            [112.255886, 26.894121],
                            [112.249029, 26.89602],
                            [112.245861, 26.897796],
                            [112.241824, 26.897468],
                            [112.239752, 26.89867],
                            [112.235715, 26.904939],
                            [112.232199, 26.906838],
                            [112.223985, 26.902576],
                            [112.215039, 26.899858],
                            [112.212655, 26.900036],
                            [112.210897, 26.902331],
                            [112.207817, 26.904011],
                            [112.203484, 26.903628],
                            [112.198732, 26.900432],
                            [112.196592, 26.899954],
                            [112.191336, 26.896129],
                            [112.186219, 26.896539],
                            [112.183435, 26.894449],
                            [112.180232, 26.895515],
                            [112.177465, 26.894736],
                            [112.176647, 26.890938],
                            [112.174977, 26.890023],
                            [112.168955, 26.891895],
                            [112.166014, 26.887182],
                            [112.164343, 26.887414],
                            [112.16175, 26.891061],
                            [112.160532, 26.894504],
                            [112.162272, 26.901525],
                            [112.158078, 26.899763],
                            [112.156181, 26.896785],
                            [112.151377, 26.894777],
                            [112.14586, 26.891089],
                            [112.138969, 26.888357],
                            [112.122174, 26.879654],
                            [112.120973, 26.877728],
                            [112.117179, 26.876348],
                            [112.116483, 26.873452],
                            [112.1212, 26.869353],
                            [112.127709, 26.859256],
                            [112.133817, 26.85338],
                            [112.135993, 26.847805],
                            [112.140935, 26.843896],
                            [112.142032, 26.841887],
                            [112.145338, 26.840425],
                            [112.14828, 26.842024],
                            [112.153414, 26.842844],
                            [112.158669, 26.84089],
                            [112.159905, 26.839318],
                            [112.160375, 26.831391],
                            [112.161611, 26.830694],
                            [112.165909, 26.83109],
                            [112.171861, 26.829847],
                            [112.176334, 26.832102],
                            [112.17931, 26.83202],
                            [112.179136, 26.829778],
                            [112.184079, 26.825514],
                            [112.18655, 26.822493],
                            [112.197514, 26.817176],
                            [112.202318, 26.817818],
                            [112.205137, 26.817162],
                            [112.204563, 26.813745],
                            [112.205433, 26.811366],
                            [112.208948, 26.81045],
                            [112.214918, 26.810273],
                            [112.221618, 26.808017],
                            [112.224977, 26.810355],
                            [112.226126, 26.806923],
                            [112.230268, 26.804763],
                            [112.233975, 26.807306],
                            [112.240536, 26.804135],
                            [112.241388, 26.801318],
                            [112.248454, 26.799008],
                            [112.253519, 26.799459],
                            [112.25559, 26.797572],
                            [112.256094, 26.793143],
                            [112.26055, 26.787127],
                            [112.256738, 26.787742],
                            [112.253832, 26.783599],
                            [112.248071, 26.782273],
                            [112.245739, 26.785349],
                            [112.245339, 26.781111],
                            [112.244138, 26.778827],
                            [112.247236, 26.776516],
                            [112.247149, 26.775203],
                            [112.243529, 26.774123],
                            [112.240414, 26.774465],
                            [112.238412, 26.775969],
                            [112.236881, 26.774684],
                            [112.237194, 26.771867],
                            [112.235993, 26.770581],
                            [112.235123, 26.766287],
                            [112.238569, 26.765002],
                            [112.240884, 26.766917],
                            [112.242241, 26.764277],
                            [112.246192, 26.76332],
                            [112.251187, 26.763265],
                            [112.254354, 26.755538],
                            [112.25371, 26.753363],
                            [112.257382, 26.752707],
                            [112.257713, 26.751148],
                            [112.261072, 26.747127],
                            [112.257469, 26.742353],
                            [112.259749, 26.740807],
                            [112.255659, 26.735049],
                            [112.24969, 26.734611],
                            [112.249812, 26.732545],
                            [112.241267, 26.732258],
                            [112.235367, 26.730835],
                            [112.22978, 26.727648],
                            [112.222297, 26.728031],
                            [112.218712, 26.729823],
                            [112.210741, 26.730274],
                            [112.205711, 26.732627],
                            [112.202787, 26.731971],
                            [112.198297, 26.726868],
                            [112.199724, 26.72405],
                            [112.203919, 26.72115],
                            [112.204389, 26.719522],
                            [112.20317, 26.715048],
                            [112.204719, 26.711231],
                            [112.202265, 26.707974],
                            [112.202805, 26.70573],
                            [112.204214, 26.708084],
                            [112.207399, 26.709671],
                            [112.207939, 26.707605],
                            [112.210776, 26.706839],
                            [112.209488, 26.704075],
                            [112.211611, 26.701803],
                            [112.211594, 26.699368],
                            [112.215039, 26.697849],
                            [112.220313, 26.698601],
                            [112.222871, 26.697712],
                            [112.222279, 26.694223],
                            [112.219686, 26.685492],
                            [112.223428, 26.685382],
                            [112.225255, 26.686682],
                            [112.226822, 26.68504],
                            [112.227065, 26.680921],
                            [112.231625, 26.679703],
                            [112.234897, 26.674995],
                            [112.24245, 26.673996],
                            [112.243529, 26.673038],
                            [112.243459, 26.66603],
                            [112.24433, 26.662594],
                            [112.247584, 26.662074],
                            [112.254911, 26.666495],
                            [112.259349, 26.666016],
                            [112.261263, 26.66295],
                            [112.261002, 26.658392],
                            [112.261681, 26.652383],
                            [112.260811, 26.647277],
                            [112.253171, 26.634777],
                            [112.253484, 26.630971],
                            [112.256791, 26.629082],
                            [112.262116, 26.627849],
                            [112.270853, 26.621441],
                            [112.277797, 26.621332],
                            [112.280668, 26.623358],
                            [112.281904, 26.628151],
                            [112.283505, 26.629698],
                            [112.285576, 26.628808],
                            [112.288169, 26.62355],
                            [112.290588, 26.623221],
                            [112.294574, 26.625371],
                            [112.300334, 26.625207],
                            [112.302109, 26.623071],
                            [112.29976, 26.617826],
                            [112.299673, 26.615334],
                            [112.3015, 26.612322],
                            [112.304059, 26.610117],
                            [112.307104, 26.609911],
                            [112.314849, 26.611979],
                            [112.31685, 26.611651],
                            [112.318451, 26.604721],
                            [112.321149, 26.604571],
                            [112.325935, 26.609829],
                            [112.327014, 26.607707],
                            [112.32503, 26.604612],
                            [112.32851, 26.603708],
                            [112.3322, 26.604653],
                            [112.336046, 26.601065],
                            [112.33944, 26.599914],
                            [112.340484, 26.593683],
                            [112.336464, 26.582643],
                            [112.332513, 26.577904],
                            [112.328737, 26.576],
                            [112.323307, 26.574917],
                            [112.322245, 26.573739],
                            [112.321932, 26.56926],
                            [112.326962, 26.564232],
                            [112.33347, 26.566287],
                            [112.339788, 26.567616],
                            [112.346593, 26.567684],
                            [112.355242, 26.566397],
                            [112.362935, 26.563602],
                            [112.373081, 26.557355],
                            [112.376301, 26.553738],
                            [112.381, 26.546983],
                            [112.384028, 26.544572],
                            [112.387891, 26.543749],
                            [112.390015, 26.544613],
                            [112.391389, 26.546901],
                            [112.392712, 26.554094],
                            [112.396123, 26.56078],
                            [112.401762, 26.567342],
                            [112.415667, 26.584972],
                            [112.418104, 26.587697],
                            [112.425204, 26.590272],
                            [112.428006, 26.589245],
                            [112.431035, 26.586821],
                            [112.435194, 26.580383],
                            [112.438118, 26.57152],
                            [112.441686, 26.563999],
                            [112.445984, 26.557259],
                            [112.451031, 26.554094],
                            [112.464293, 26.549573],
                            [112.470541, 26.550299],
                            [112.481696, 26.550847],
                            [112.485351, 26.552354],
                            [112.490972, 26.557053],
                            [112.493148, 26.560013],
                            [112.494088, 26.563945],
                            [112.492939, 26.567945],
                            [112.492904, 26.573534],
                            [112.494349, 26.577452],
                            [112.497899, 26.582451],
                            [112.502267, 26.581602],
                            [112.507192, 26.578534],
                            [112.511143, 26.574233],
                            [112.522264, 26.568315],
                            [112.526563, 26.566616],
                            [112.531784, 26.565561],
                            [112.537631, 26.566849],
                            [112.541147, 26.572315],
                            [112.546716, 26.579451],
                            [112.552389, 26.584287],
                            [112.558011, 26.585752],
                            [112.562344, 26.587615],
                            [112.567252, 26.590505],
                            [112.578408, 26.594011],
                            [112.595132, 26.596394],
                            [112.607628, 26.597887],
                            [112.613058, 26.599079],
                            [112.620159, 26.599791],
                            [112.622386, 26.59875],
                            [112.624718, 26.592368],
                            [112.627625, 26.587149],
                            [112.630862, 26.584752],
                            [112.634708, 26.5853],
                            [112.637649, 26.587588],
                            [112.645933, 26.595614],
                            [112.651903, 26.599599],
                            [112.657663, 26.601517],
                            [112.662589, 26.600763],
                            [112.667183, 26.597737],
                            [112.668401, 26.593409],
                            [112.665547, 26.58367],
                            [112.662171, 26.575548],
                            [112.668906, 26.576095],
                            [112.675485, 26.576027],
                            [112.676598, 26.578136],
                            [112.676424, 26.583533],
                            [112.677712, 26.585588],
                            [112.681819, 26.587985],
                            [112.695638, 26.591382],
                            [112.703313, 26.591368],
                            [112.706237, 26.589916],
                            [112.707768, 26.587738],
                            [112.706828, 26.58126],
                            [112.709996, 26.578767],
                            [112.716139, 26.579547],
                            [112.719446, 26.576548],
                            [112.719881, 26.569178],
                            [112.723292, 26.568726],
                            [112.72954, 26.572219],
                            [112.736501, 26.574794],
                            [112.740574, 26.575575],
                            [112.744037, 26.574191],
                            [112.745238, 26.576534],
                            [112.744455, 26.581465],
                            [112.744925, 26.584273],
                            [112.748771, 26.587519],
                            [112.756811, 26.590464],
                            [112.765374, 26.591395],
                            [112.769394, 26.592738],
                            [112.772544, 26.596792],
                            [112.773414, 26.604064],
                            [112.771848, 26.60883],
                            [112.77244, 26.611294],
                            [112.786328, 26.620894],
                            [112.793585, 26.6303],
                            [112.795934, 26.636817],
                            [112.793985, 26.64176],
                            [112.798075, 26.646428],
                            [112.798754, 26.651589],
                            [112.797309, 26.654806],
                            [112.798162, 26.657899],
                            [112.800651, 26.658556],
                            [112.806498, 26.657092],
                            [112.8093, 26.657872],
                            [112.811737, 26.660035],
                            [112.813042, 26.663347],
                            [112.813146, 26.669821],
                            [112.814313, 26.672846],
                            [112.817776, 26.67546],
                            [112.822423, 26.676514],
                            [112.826721, 26.680264],
                            [112.829593, 26.681044],
                            [112.833509, 26.684],
                            [112.836154, 26.682372],
                            [112.835945, 26.67791],
                            [112.833039, 26.6726],
                            [112.831925, 26.666933],
                            [112.833804, 26.664908],
                            [112.840748, 26.664456],
                            [112.845761, 26.667919],
                            [112.848423, 26.668809],
                            [112.854793, 26.668822],
                            [112.859788, 26.667714],
                            [112.872284, 26.662896],
                            [112.878566, 26.659419],
                            [112.88149, 26.656421],
                            [112.882743, 26.651055],
                            [112.882134, 26.630218],
                            [112.882621, 26.61973],
                            [112.882151, 26.616772],
                            [112.879402, 26.611719],
                            [112.876826, 26.60909],
                            [112.876426, 26.606652],
                            [112.877888, 26.605214],
                            [112.884188, 26.604283],
                            [112.894108, 26.60138],
                            [112.897484, 26.601243],
                            [112.903732, 26.603543],
                            [112.911006, 26.611294],
                            [112.916523, 26.614526],
                            [112.92258, 26.613349],
                            [112.924425, 26.613883],
                            [112.925939, 26.617101],
                            [112.92841, 26.619716],
                            [112.931873, 26.618538],
                            [112.935041, 26.612349],
                            [112.940384, 26.609295],
                            [112.946144, 26.604571],
                            [112.953871, 26.602941],
                            [112.955908, 26.601722],
                            [112.966593, 26.601612],
                            [112.967829, 26.60557],
                            [112.969952, 26.607159],
                            [112.975939, 26.609898],
                            [112.979681, 26.613883],
                            [112.982222, 26.619374],
                            [112.981926, 26.624769],
                            [112.983753, 26.629451],
                            [112.983075, 26.631642],
                            [112.984171, 26.639377],
                            [112.982883, 26.646537],
                            [112.984119, 26.649399],
                            [112.983423, 26.651411],
                            [112.977453, 26.656585],
                            [112.970422, 26.65805],
                            [112.967672, 26.658009],
                            [112.965706, 26.660021],
                            [112.964592, 26.665811],
                            [112.963391, 26.668507],
                            [112.958744, 26.670191],
                            [112.958048, 26.672258],
                            [112.954376, 26.676911],
                            [112.948581, 26.679744],
                            [112.947188, 26.682673],
                            [112.947589, 26.685314],
                            [112.949207, 26.686847],
                            [112.955229, 26.688722],
                            [112.956012, 26.69061],
                            [112.955072, 26.692895],
                            [112.944526, 26.701023],
                            [112.94517, 26.702925],
                            [112.948059, 26.703473],
                            [112.956882, 26.702583],
                            [112.965166, 26.699217],
                            [112.97265, 26.697808],
                            [112.976687, 26.697972],
                            [112.980899, 26.702939],
                            [112.984362, 26.704362],
                            [112.990889, 26.703131],
                            [112.997798, 26.702761],
                            [112.997955, 26.698232],
                            [113.000165, 26.694264],
                            [113.007091, 26.687873],
                            [113.012521, 26.686203],
                            [113.015915, 26.68452],
                            [113.017568, 26.682344],
                            [113.021275, 26.683234],
                            [113.028759, 26.688475],
                            [113.036608, 26.692977],
                            [113.038557, 26.697151],
                            [113.041637, 26.698793],
                            [113.058954, 26.699409],
                            [113.066298, 26.701749],
                            [113.068978, 26.703746],
                            [113.075557, 26.705881],
                            [113.078341, 26.705703],
                            [113.082205, 26.703979],
                            [113.083545, 26.69919],
                            [113.085895, 26.698383],
                            [113.090663, 26.694729],
                            [113.094492, 26.693566],
                            [113.099434, 26.69061],
                            [113.105195, 26.691308],
                            [113.108902, 26.692772],
                            [113.110642, 26.699751],
                            [113.11783, 26.705594],
                            [113.120145, 26.705293],
                            [113.119657, 26.702474],
                            [113.122372, 26.700449],
                            [113.12507, 26.695331],
                            [113.134972, 26.690145],
                            [113.139828, 26.691185],
                            [113.144544, 26.691253],
                            [113.148495, 26.692307],
                            [113.151454, 26.694223],
                            [113.15295, 26.697999],
                            [113.152272, 26.700517],
                            [113.147973, 26.703664],
                            [113.140611, 26.706592],
                            [113.13814, 26.708508],
                            [113.134798, 26.712599],
                            [113.134694, 26.714843],
                            [113.136922, 26.715568],
                            [113.139167, 26.717948],
                            [113.137983, 26.719973],
                            [113.133545, 26.722066],
                            [113.12232, 26.723202],
                            [113.12011, 26.724884],
                            [113.12138, 26.730999],
                            [113.124774, 26.734652],
                            [113.131405, 26.738085],
                            [113.132188, 26.74249],
                            [113.129055, 26.744117],
                            [113.120005, 26.742367],
                            [113.115341, 26.744131],
                            [113.113827, 26.750792],
                            [113.111774, 26.754293],
                            [113.108502, 26.755292],
                            [113.101958, 26.753254],
                            [113.096894, 26.756304],
                            [113.095588, 26.759915],
                            [113.096841, 26.772605],
                            [113.095832, 26.774397],
                            [113.097259, 26.78163],
                            [113.092264, 26.788768],
                            [113.091551, 26.794223],
                            [113.089863, 26.799213],
                            [113.086034, 26.806349],
                            [113.083876, 26.809398],
                            [113.078115, 26.809261],
                            [113.072859, 26.811093],
                            [113.070928, 26.81343],
                            [113.069013, 26.817887],
                            [113.070492, 26.821277],
                            [113.075052, 26.825199],
                            [113.075818, 26.828534],
                            [113.070997, 26.833099],
                            [113.070806, 26.835505],
                            [113.073468, 26.840616],
                            [113.073938, 26.848706],
                            [113.071693, 26.857356],
                            [113.074687, 26.861674],
                            [113.082135, 26.864311],
                            [113.084085, 26.866661],
                            [113.088784, 26.870064],
                            [113.088992, 26.872987],
                            [113.083267, 26.876061],
                            [113.078933, 26.877687],
                            [113.073747, 26.878643],
                            [113.069988, 26.880898],
                            [113.070405, 26.891499],
                            [113.06367, 26.89796],
                            [113.063061, 26.901224],
                            [113.064314, 26.904612],
                            [113.063357, 26.906387],
                            [113.063479, 26.911605],
                            [113.064975, 26.913776],
                            [113.070371, 26.917641],
                            [113.072198, 26.920209],
                            [113.073451, 26.925467],
                            [113.076027, 26.931762],
                            [113.074774, 26.936705],
                            [113.074791, 26.945239],
                            [113.074043, 26.947246],
                            [113.066716, 26.951533],
                            [113.060938, 26.952366],
                            [113.052166, 26.952421],
                            [113.046145, 26.954114],
                            [113.040071, 26.949376],
                            [113.036869, 26.948598],
                            [113.034049, 26.949239],
                            [113.032135, 26.951943],
                            [113.029455, 26.953895],
                            [113.017899, 26.953199],
                            [113.015532, 26.95425],
                            [113.011964, 26.959124],
                            [113.011547, 26.962264],
                            [113.009737, 26.965008],
                            [113.006099, 26.965363],
                            [112.997746, 26.96139],
                            [112.989566, 26.958469],
                            [112.980812, 26.955929],
                            [112.974286, 26.951492],
                            [112.965984, 26.947847],
                            [112.958083, 26.945649],
                            [112.953454, 26.943327],
                            [112.949068, 26.942085],
                            [112.941428, 26.939081],
                            [112.935493, 26.935039],
                            [112.930603, 26.930833],
                            [112.926078, 26.925289],
                            [112.923502, 26.923705],
                            [112.917481, 26.923568],
                            [112.911964, 26.92279],
                            [112.904515, 26.919458],
                            [112.900356, 26.917027],
                            [112.897762, 26.912916],
                            [112.897188, 26.903519],
                            [112.89865, 26.894736],
                            [112.897571, 26.888603],
                            [112.894525, 26.881977],
                            [112.892333, 26.878616],
                            [112.883022, 26.87128],
                            [112.879837, 26.867591],
                            [112.875956, 26.865787],
                            [112.874651, 26.863437],
                            [112.87204, 26.863519],
                            [112.871936, 26.8655],
                            [112.869934, 26.865418],
                            [112.869029, 26.863519],
                            [112.865322, 26.861784],
                            [112.863129, 26.857247],
                            [112.859248, 26.855607],
                            [112.853001, 26.856372],
                            [112.849781, 26.853995],
                            [112.845134, 26.859051],
                            [112.840696, 26.861387],
                            [112.838225, 26.856509],
                            [112.834466, 26.856441],
                            [112.830759, 26.858381],
                            [112.827748, 26.858846],
                            [112.824807, 26.857206],
                            [112.821674, 26.857466],
                            [112.821013, 26.85998],
                            [112.822074, 26.863109],
                            [112.821848, 26.883917],
                            [112.82016, 26.88531],
                            [112.815217, 26.886062],
                            [112.811093, 26.889887],
                            [112.81024, 26.89434],
                            [112.810449, 26.89796],
                            [112.811528, 26.900036],
                            [112.807194, 26.901293],
                            [112.804636, 26.903109],
                            [112.79623, 26.904721],
                            [112.795795, 26.906674],
                            [112.791688, 26.914432],
                            [112.785962, 26.918939],
                            [112.785597, 26.921069],
                            [112.787337, 26.923063],
                            [112.790539, 26.923841],
                            [112.792593, 26.925958],
                            [112.792036, 26.928129],
                            [112.786815, 26.931721],
                            [112.78537, 26.936091],
                            [112.786867, 26.940719],
                            [112.78638, 26.943478],
                            [112.783613, 26.949513],
                            [112.778531, 26.956571],
                            [112.774754, 26.958605],
                            [112.769324, 26.969185],
                            [112.76701, 26.971124],
                            [112.76299, 26.970973],
                            [112.756202, 26.966155],
                            [112.754514, 26.965472],
                            [112.748475, 26.96554],
                            [112.746178, 26.967984],
                            [112.748545, 26.974604],
                            [112.744089, 26.974563],
                            [112.738015, 26.975382],
                            [112.735527, 26.976297],
                            [112.728322, 26.970987],
                            [112.726755, 26.969253],
                            [112.721151, 26.966073],
                            [112.716748, 26.965322],
                            [112.716348, 26.962742],
                            [112.718698, 26.959247],
                            [112.718489, 26.955916],
                            [112.713598, 26.953922],
                            [112.707716, 26.955847],
                            [112.7038, 26.954127],
                            [112.701085, 26.950414],
                            [112.700824, 26.947888],
                            [112.70206, 26.941416],
                            [112.705366, 26.938371],
                            [112.706132, 26.936063],
                            [112.701485, 26.929495],
                            [112.699449, 26.923705],
                            [112.700911, 26.919526],
                            [112.710553, 26.914309],
                            [112.71828, 26.916754],
                            [112.726947, 26.90651],
                            [112.718628, 26.900145],
                            [112.720159, 26.898233],
                            [112.718367, 26.89602],
                            [112.718715, 26.891649],
                            [112.720682, 26.889586],
                            [112.724806, 26.893302],
                            [112.726686, 26.890365],
                            [112.730515, 26.886458],
                            [112.737859, 26.873466],
                            [112.737337, 26.867686],
                            [112.735701, 26.863041],
                            [112.734013, 26.860472],
                            [112.723135, 26.852027],
                            [112.717932, 26.847176],
                            [112.713598, 26.840657],
                            [112.710744, 26.83448],
                            [112.706585, 26.823655],
                            [112.706219, 26.813239],
                            [112.708812, 26.80557],
                            [112.708638, 26.793526],
                            [112.709561, 26.79157],
                            [112.707263, 26.78658],
                            [112.704427, 26.784091],
                            [112.701259, 26.78308],
                            [112.693184, 26.782437],
                            [112.691409, 26.780564],
                            [112.690991, 26.775969],
                            [112.689547, 26.773057],
                            [112.681558, 26.770732],
                            [112.676041, 26.760844],
                            [112.669585, 26.757453],
                            [112.658621, 26.751025],
                            [112.654078, 26.748905],
                            [112.649431, 26.747496],
                            [112.636988, 26.748002],
                            [112.626685, 26.750696],
                            [112.618592, 26.754854],
                            [112.604513, 26.761214],
                            [112.595916, 26.765658],
                            [112.583333, 26.77452]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430423,
                "name": "衡山县",
                "center": [112.86971, 27.234808],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430400
                },
                "subFeatureIndex": 7,
                "acroutes": [100000, 430000, 430400]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.920196, 27.356609],
                            [112.912712, 27.358527],
                            [112.901904, 27.360513],
                            [112.897536, 27.360322],
                            [112.885858, 27.356922],
                            [112.87881, 27.356119],
                            [112.869952, 27.356119],
                            [112.863895, 27.351208],
                            [112.852983, 27.355071],
                            [112.848058, 27.355371],
                            [112.843846, 27.354282],
                            [112.836885, 27.354949],
                            [112.834936, 27.354405],
                            [112.830689, 27.348106],
                            [112.830063, 27.345331],
                            [112.832656, 27.339739],
                            [112.831124, 27.338351],
                            [112.821518, 27.338283],
                            [112.818768, 27.339929],
                            [112.816401, 27.343671],
                            [112.812503, 27.342582],
                            [112.807873, 27.338365],
                            [112.80535, 27.338773],
                            [112.801155, 27.341916],
                            [112.799833, 27.34431],
                            [112.804897, 27.34661],
                            [112.806237, 27.350596],
                            [112.803836, 27.36205],
                            [112.796439, 27.364335],
                            [112.790435, 27.362023],
                            [112.785475, 27.366226],
                            [112.781333, 27.372238],
                            [112.77773, 27.374768],
                            [112.774145, 27.376332],
                            [112.775085, 27.381324],
                            [112.774389, 27.386288],
                            [112.771813, 27.392775],
                            [112.769185, 27.395454],
                            [112.764538, 27.39767],
                            [112.761841, 27.400077],
                            [112.760327, 27.404007],
                            [112.758047, 27.403626],
                            [112.754653, 27.400281],
                            [112.751068, 27.399248],
                            [112.745743, 27.399465],
                            [112.741078, 27.401233],
                            [112.735805, 27.401519],
                            [112.732342, 27.3985],
                            [112.73095, 27.396215],
                            [112.727486, 27.394896],
                            [112.723518, 27.398935],
                            [112.718071, 27.401886],
                            [112.715304, 27.404279],
                            [112.710065, 27.406359],
                            [112.704113, 27.407638],
                            [112.69844, 27.406999],
                            [112.694924, 27.407706],
                            [112.685648, 27.405693],
                            [112.684726, 27.40708],
                            [112.684552, 27.41154],
                            [112.682185, 27.414191],
                            [112.678513, 27.416312],
                            [112.677416, 27.419276],
                            [112.679313, 27.42621],
                            [112.678478, 27.428902],
                            [112.680514, 27.431145],
                            [112.685753, 27.434829],
                            [112.687267, 27.437126],
                            [112.68993, 27.4469],
                            [112.6916, 27.450665],
                            [112.690678, 27.454144],
                            [112.684186, 27.454674],
                            [112.679261, 27.453709],
                            [112.67538, 27.453845],
                            [112.673083, 27.456781],
                            [112.668784, 27.456183],
                            [112.664451, 27.452296],
                            [112.659752, 27.452296],
                            [112.656254, 27.454416],
                            [112.651485, 27.458358],
                            [112.650128, 27.463264],
                            [112.646281, 27.464718],
                            [112.643949, 27.463726],
                            [112.641095, 27.460029],
                            [112.639581, 27.459839],
                            [112.637162, 27.463277],
                            [112.632498, 27.463549],
                            [112.630479, 27.466254],
                            [112.626111, 27.465982],
                            [112.62136, 27.466621],
                            [112.619028, 27.468306],
                            [112.615164, 27.465982],
                            [112.61177, 27.458942],
                            [112.608324, 27.456006],
                            [112.600789, 27.454239],
                            [112.594262, 27.456074],
                            [112.592592, 27.455014],
                            [112.593758, 27.44917],
                            [112.588745, 27.4455],
                            [112.587196, 27.441571],
                            [112.587266, 27.437058],
                            [112.585491, 27.434761],
                            [112.582045, 27.43351],
                            [112.576598, 27.434462],
                            [112.571986, 27.440525],
                            [112.566904, 27.441775],
                            [112.561753, 27.437765],
                            [112.553329, 27.432409],
                            [112.552233, 27.430628],
                            [112.554025, 27.427733],
                            [112.554547, 27.424918],
                            [112.550788, 27.425625],
                            [112.546246, 27.42345],
                            [112.542469, 27.418461],
                            [112.538867, 27.416693],
                            [112.536674, 27.417849],
                            [112.5361, 27.420826],
                            [112.531766, 27.424293],
                            [112.531035, 27.430275],
                            [112.521985, 27.429187],
                            [112.524144, 27.425789],
                            [112.524039, 27.422798],
                            [112.520854, 27.419793],
                            [112.516556, 27.419657],
                            [112.508359, 27.417019],
                            [112.506462, 27.415279],
                            [112.506583, 27.413579],
                            [112.509368, 27.410765],
                            [112.513232, 27.405639],
                            [112.512796, 27.403137],
                            [112.507105, 27.404197],
                            [112.504547, 27.40398],
                            [112.504895, 27.401287],
                            [112.501397, 27.400104],
                            [112.499013, 27.397711],
                            [112.496263, 27.399098],
                            [112.495497, 27.400757],
                            [112.49313, 27.400268],
                            [112.494227, 27.395059],
                            [112.497447, 27.394121],
                            [112.498995, 27.390898],
                            [112.496594, 27.387457],
                            [112.499396, 27.385553],
                            [112.499309, 27.383078],
                            [112.501362, 27.378481],
                            [112.4999, 27.374945],
                            [112.495811, 27.374387],
                            [112.489598, 27.371925],
                            [112.488849, 27.370293],
                            [112.489789, 27.363628],
                            [112.488397, 27.360989],
                            [112.485717, 27.359846],
                            [112.482671, 27.362376],
                            [112.481366, 27.362064],
                            [112.477537, 27.367573],
                            [112.473934, 27.367573],
                            [112.471863, 27.372415],
                            [112.467251, 27.369014],
                            [112.464954, 27.364553],
                            [112.465285, 27.361356],
                            [112.461926, 27.358051],
                            [112.461352, 27.356432],
                            [112.462901, 27.356078],
                            [112.466399, 27.352936],
                            [112.468957, 27.356201],
                            [112.469949, 27.355942],
                            [112.471794, 27.351725],
                            [112.474352, 27.348677],
                            [112.47705, 27.349331],
                            [112.480739, 27.348555],
                            [112.486065, 27.346324],
                            [112.489336, 27.349847],
                            [112.494732, 27.349603],
                            [112.497621, 27.347943],
                            [112.501554, 27.348052],
                            [112.50312, 27.345875],
                            [112.50305, 27.343467],
                            [112.500597, 27.343344],
                            [112.499013, 27.339059],
                            [112.500022, 27.333875],
                            [112.50272, 27.332596],
                            [112.502372, 27.328636],
                            [112.505974, 27.325561],
                            [112.510203, 27.324214],
                            [112.511439, 27.321138],
                            [112.517983, 27.316811],
                            [112.516643, 27.310619],
                            [112.518035, 27.306359],
                            [112.521637, 27.30361],
                            [112.527781, 27.300466],
                            [112.528112, 27.297975],
                            [112.526737, 27.293048],
                            [112.526893, 27.289836],
                            [112.530705, 27.286746],
                            [112.530757, 27.284595],
                            [112.526754, 27.276672],
                            [112.526441, 27.274971],
                            [112.530078, 27.271091],
                            [112.533402, 27.269348],
                            [112.533628, 27.268],
                            [112.531749, 27.263385],
                            [112.531905, 27.260172],
                            [112.527746, 27.257544],
                            [112.528164, 27.25399],
                            [112.530583, 27.249006],
                            [112.536465, 27.248339],
                            [112.538832, 27.24917],
                            [112.537823, 27.253568],
                            [112.539598, 27.253255],
                            [112.54501, 27.24936],
                            [112.54863, 27.250395],
                            [112.55044, 27.246365],
                            [112.556392, 27.24552],
                            [112.562379, 27.245752],
                            [112.567165, 27.244104],
                            [112.574892, 27.243696],
                            [112.577329, 27.247059],
                            [112.588676, 27.250654],
                            [112.595028, 27.250613],
                            [112.606358, 27.247849],
                            [112.618871, 27.239433],
                            [112.622212, 27.237513],
                            [112.629383, 27.237445],
                            [112.636135, 27.238126],
                            [112.639981, 27.241462],
                            [112.64019, 27.256141],
                            [112.64066, 27.259899],
                            [112.642783, 27.261383],
                            [112.64616, 27.260975],
                            [112.652877, 27.258265],
                            [112.65923, 27.26009],
                            [112.660987, 27.265332],
                            [112.660222, 27.269852],
                            [112.658551, 27.271377],
                            [112.650998, 27.273718],
                            [112.630357, 27.275801],
                            [112.627016, 27.278102],
                            [112.619132, 27.28646],
                            [112.618331, 27.289482],
                            [112.619236, 27.292857],
                            [112.632707, 27.29234],
                            [112.63986, 27.29151],
                            [112.644541, 27.294096],
                            [112.645899, 27.30048],
                            [112.653104, 27.303038],
                            [112.656915, 27.30376],
                            [112.658655, 27.306373],
                            [112.660866, 27.31349],
                            [112.665582, 27.323846],
                            [112.675363, 27.323506],
                            [112.679122, 27.320458],
                            [112.679435, 27.313695],
                            [112.684082, 27.31364],
                            [112.68751, 27.31662],
                            [112.689268, 27.321111],
                            [112.68932, 27.325248],
                            [112.693602, 27.328963],
                            [112.699536, 27.330786],
                            [112.703852, 27.329902],
                            [112.703713, 27.327738],
                            [112.706202, 27.325071],
                            [112.713825, 27.326119],
                            [112.7219, 27.330555],
                            [112.728235, 27.331235],
                            [112.732011, 27.330065],
                            [112.732707, 27.320649],
                            [112.739321, 27.311163],
                            [112.750215, 27.307271],
                            [112.76198, 27.30674],
                            [112.772039, 27.30361],
                            [112.779105, 27.297866],
                            [112.781959, 27.291428],
                            [112.780567, 27.283547],
                            [112.777121, 27.279463],
                            [112.775381, 27.274971],
                            [112.768628, 27.273922],
                            [112.766853, 27.267551],
                            [112.766314, 27.260035],
                            [112.767445, 27.252874],
                            [112.768663, 27.249851],
                            [112.768628, 27.246841],
                            [112.766923, 27.245357],
                            [112.756394, 27.245493],
                            [112.750494, 27.244431],
                            [112.748318, 27.2407],
                            [112.751973, 27.231998],
                            [112.759544, 27.231902],
                            [112.761632, 27.230745],
                            [112.761528, 27.224357],
                            [112.763529, 27.217179],
                            [112.769324, 27.211091],
                            [112.76922, 27.204689],
                            [112.767497, 27.202836],
                            [112.764556, 27.202495],
                            [112.762467, 27.199825],
                            [112.764069, 27.196624],
                            [112.768698, 27.19345],
                            [112.76868, 27.192155],
                            [112.765217, 27.19082],
                            [112.762259, 27.190861],
                            [112.755993, 27.192823],
                            [112.751816, 27.194758],
                            [112.74884, 27.193286],
                            [112.745847, 27.189935],
                            [112.742906, 27.189976],
                            [112.741688, 27.193695],
                            [112.739042, 27.194649],
                            [112.733212, 27.189758],
                            [112.730132, 27.189431],
                            [112.727347, 27.195153],
                            [112.725119, 27.197373],
                            [112.717079, 27.199158],
                            [112.70977, 27.201215],
                            [112.703, 27.201828],
                            [112.69475, 27.199716],
                            [112.688154, 27.198858],
                            [112.682707, 27.206078],
                            [112.680462, 27.206459],
                            [112.670177, 27.206378],
                            [112.663163, 27.204798],
                            [112.655471, 27.199553],
                            [112.652181, 27.199226],
                            [112.648161, 27.197578],
                            [112.648944, 27.193273],
                            [112.652268, 27.187591],
                            [112.650806, 27.18315],
                            [112.654687, 27.182455],
                            [112.665112, 27.171854],
                            [112.665982, 27.16346],
                            [112.665408, 27.158813],
                            [112.663302, 27.150118],
                            [112.663737, 27.145893],
                            [112.665112, 27.144176],
                            [112.671934, 27.140046],
                            [112.672717, 27.134689],
                            [112.668836, 27.130818],
                            [112.659404, 27.130941],
                            [112.657193, 27.130382],
                            [112.654531, 27.128174],
                            [112.653208, 27.121807],
                            [112.649606, 27.112469],
                            [112.65145, 27.109292],
                            [112.656706, 27.104493],
                            [112.659908, 27.103048],
                            [112.665286, 27.102952],
                            [112.66948, 27.10028],
                            [112.675189, 27.095671],
                            [112.687319, 27.102666],
                            [112.692209, 27.104589],
                            [112.69489, 27.10392],
                            [112.700058, 27.097935],
                            [112.704427, 27.090367],
                            [112.708569, 27.089494],
                            [112.711649, 27.092276],
                            [112.714956, 27.09184],
                            [112.716365, 27.089167],
                            [112.716922, 27.08494],
                            [112.722404, 27.084544],
                            [112.72451, 27.082212],
                            [112.726686, 27.07808],
                            [112.727086, 27.075216],
                            [112.725259, 27.069352],
                            [112.728478, 27.068983],
                            [112.728374, 27.065287],
                            [112.72646, 27.063473],
                            [112.727956, 27.060677],
                            [112.727939, 27.049287],
                            [112.727277, 27.045809],
                            [112.724684, 27.042207],
                            [112.718854, 27.036587],
                            [112.717688, 27.034445],
                            [112.720734, 27.029847],
                            [112.719289, 27.025672],
                            [112.718245, 27.01114],
                            [112.720664, 27.009871],
                            [112.727347, 27.014824],
                            [112.738137, 27.016707],
                            [112.740365, 27.016162],
                            [112.743115, 27.013801],
                            [112.743428, 27.010717],
                            [112.73939, 27.006637],
                            [112.739843, 27.003894],
                            [112.745046, 27.003758],
                            [112.746752, 27.002884],
                            [112.74757, 26.999063],
                            [112.751173, 26.999159],
                            [112.751851, 26.996593],
                            [112.748162, 26.994],
                            [112.742558, 26.994805],
                            [112.73939, 26.991188],
                            [112.737372, 26.98622],
                            [112.737406, 26.983463],
                            [112.735527, 26.976297],
                            [112.738015, 26.975382],
                            [112.744089, 26.974563],
                            [112.748545, 26.974604],
                            [112.753644, 26.97571],
                            [112.76191, 26.979],
                            [112.767567, 26.981948],
                            [112.773049, 26.986507],
                            [112.777121, 26.992949],
                            [112.775955, 27.000414],
                            [112.767497, 27.007701],
                            [112.763546, 27.011604],
                            [112.756742, 27.023475],
                            [112.749171, 27.043299],
                            [112.743689, 27.059504],
                            [112.743672, 27.067129],
                            [112.7467, 27.077848],
                            [112.751242, 27.085349],
                            [112.755019, 27.088376],
                            [112.761475, 27.089453],
                            [112.767775, 27.089494],
                            [112.77733, 27.087571],
                            [112.800651, 27.08033],
                            [112.810762, 27.078394],
                            [112.818333, 27.078544],
                            [112.824807, 27.080753],
                            [112.827992, 27.08378],
                            [112.828322, 27.08704],
                            [112.827243, 27.089876],
                            [112.821465, 27.094962],
                            [112.817393, 27.099626],
                            [112.814347, 27.105311],
                            [112.814017, 27.11146],
                            [112.817306, 27.120131],
                            [112.822562, 27.126838],
                            [112.833178, 27.130273],
                            [112.85133, 27.130641],
                            [112.857647, 27.132277],
                            [112.863878, 27.136925],
                            [112.868699, 27.143767],
                            [112.869812, 27.150023],
                            [112.86936, 27.156946],
                            [112.867567, 27.161457],
                            [112.863669, 27.168052],
                            [112.854428, 27.179212],
                            [112.849955, 27.185616],
                            [112.848197, 27.192046],
                            [112.848771, 27.201133],
                            [112.852774, 27.209266],
                            [112.861789, 27.21861],
                            [112.877731, 27.230609],
                            [112.885006, 27.237554],
                            [112.892785, 27.246814],
                            [112.907004, 27.255692],
                            [112.913304, 27.264052],
                            [112.916767, 27.267047],
                            [112.927035, 27.273255],
                            [112.93572, 27.277407],
                            [112.941393, 27.281451],
                            [112.945953, 27.287154],
                            [112.947084, 27.294436],
                            [112.945848, 27.305475],
                            [112.941271, 27.315069],
                            [112.93612, 27.324867],
                            [112.93163, 27.330432],
                            [112.924703, 27.336923],
                            [112.920909, 27.341453],
                            [112.917759, 27.350269],
                            [112.920196, 27.356609]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430424,
                "name": "衡东县",
                "center": [112.950412, 27.083531],
                "centroid": [113.022238, 27.079133],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430400
                },
                "subFeatureIndex": 8,
                "acroutes": [100000, 430000, 430400]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [113.097259, 26.78163],
                            [113.100896, 26.781712],
                            [113.108467, 26.78509],
                            [113.116072, 26.784461],
                            [113.126445, 26.788152],
                            [113.133667, 26.787619],
                            [113.139167, 26.790449],
                            [113.149052, 26.792131],
                            [113.151123, 26.794811],
                            [113.15128, 26.797381],
                            [113.144631, 26.802439],
                            [113.145362, 26.807252],
                            [113.144718, 26.810614],
                            [113.142908, 26.812501],
                            [113.143396, 26.821304],
                            [113.143013, 26.826662],
                            [113.144127, 26.834466],
                            [113.145206, 26.836024],
                            [113.14813, 26.837118],
                            [113.153142, 26.837555],
                            [113.156274, 26.836557],
                            [113.15798, 26.834439],
                            [113.160503, 26.827537],
                            [113.174374, 26.82535],
                            [113.183859, 26.825213],
                            [113.191134, 26.828753],
                            [113.202481, 26.828903],
                            [113.20577, 26.830229],
                            [113.20711, 26.829669],
                            [113.205648, 26.826771],
                            [113.206327, 26.8255],
                            [113.211792, 26.824789],
                            [113.217535, 26.821441],
                            [113.223696, 26.820921],
                            [113.224949, 26.819117],
                            [113.224618, 26.814415],
                            [113.225767, 26.813362],
                            [113.231997, 26.811052],
                            [113.235165, 26.806185],
                            [113.238071, 26.805201],
                            [113.2423, 26.809316],
                            [113.254326, 26.812665],
                            [113.258503, 26.815481],
                            [113.259268, 26.82047],
                            [113.261287, 26.824625],
                            [113.259791, 26.827796],
                            [113.256849, 26.828398],
                            [113.254587, 26.830598],
                            [113.252377, 26.839209],
                            [113.243501, 26.850975],
                            [113.240403, 26.852806],
                            [113.235252, 26.857602],
                            [113.232867, 26.860991],
                            [113.231405, 26.868397],
                            [113.228203, 26.873479],
                            [113.227142, 26.876403],
                            [113.230431, 26.879245],
                            [113.233424, 26.879327],
                            [113.239098, 26.877728],
                            [113.240386, 26.879327],
                            [113.242892, 26.889299],
                            [113.239707, 26.894449],
                            [113.241465, 26.895569],
                            [113.248252, 26.896607],
                            [113.254744, 26.900268],
                            [113.256379, 26.90621],
                            [113.254778, 26.913763],
                            [113.257076, 26.917696],
                            [113.261862, 26.918461],
                            [113.266439, 26.918392],
                            [113.269798, 26.921875],
                            [113.278325, 26.928457],
                            [113.278604, 26.931325],
                            [113.282676, 26.93691],
                            [113.280988, 26.939968],
                            [113.275628, 26.940679],
                            [113.272965, 26.940091],
                            [113.264298, 26.939627],
                            [113.260452, 26.940009],
                            [113.248148, 26.937593],
                            [113.245032, 26.938316],
                            [113.23734, 26.947287],
                            [113.235356, 26.951738],
                            [113.23064, 26.954946],
                            [113.22561, 26.956271],
                            [113.225158, 26.962742],
                            [113.225836, 26.967779],
                            [113.224775, 26.970427],
                            [113.22152, 26.974523],
                            [113.222721, 26.977607],
                            [113.225332, 26.987203],
                            [113.225523, 26.991939],
                            [113.226828, 26.994655],
                            [113.225976, 26.998763],
                            [113.224409, 27.000823],
                            [113.218892, 27.002925],
                            [113.219171, 27.004413],
                            [113.222582, 27.006951],
                            [113.223313, 27.010512],
                            [113.224914, 27.012464],
                            [113.230674, 27.014142],
                            [113.235687, 27.014784],
                            [113.237218, 27.016871],
                            [113.236522, 27.019546],
                            [113.234608, 27.021374],
                            [113.231075, 27.021033],
                            [113.226654, 27.024744],
                            [113.220024, 27.024444],
                            [113.216734, 27.025863],
                            [113.219345, 27.030215],
                            [113.21663, 27.035645],
                            [113.215847, 27.040789],
                            [113.211148, 27.039124],
                            [113.200967, 27.039111],
                            [113.198008, 27.040707],
                            [113.19994, 27.05016],
                            [113.199505, 27.052357],
                            [113.196372, 27.05428],
                            [113.199157, 27.056872],
                            [113.197556, 27.060459],
                            [113.196894, 27.064796],
                            [113.19364, 27.069761],
                            [113.193866, 27.076034],
                            [113.193152, 27.077439],
                            [113.189063, 27.079744],
                            [113.184503, 27.083453],
                            [113.18184, 27.086631],
                            [113.175122, 27.088826],
                            [113.172025, 27.095167],
                            [113.170806, 27.099039],
                            [113.168022, 27.099107],
                            [113.164576, 27.101303],
                            [113.164193, 27.103961],
                            [113.165516, 27.109115],
                            [113.165812, 27.113464],
                            [113.168074, 27.118699],
                            [113.166821, 27.123934],
                            [113.16797, 27.126156],
                            [113.174165, 27.128392],
                            [113.178864, 27.132031],
                            [113.184277, 27.132399],
                            [113.189237, 27.135589],
                            [113.193448, 27.135712],
                            [113.193796, 27.140455],
                            [113.196024, 27.141791],
                            [113.204935, 27.138261],
                            [113.209442, 27.133994],
                            [113.216003, 27.131336],
                            [113.219449, 27.131731],
                            [113.22648, 27.138506],
                            [113.232293, 27.140918],
                            [113.235008, 27.140632],
                            [113.236052, 27.145212],
                            [113.239098, 27.144707],
                            [113.241517, 27.140455],
                            [113.244824, 27.140005],
                            [113.245903, 27.148428],
                            [113.245711, 27.150909],
                            [113.249505, 27.153743],
                            [113.251646, 27.159549],
                            [113.253578, 27.160312],
                            [113.257807, 27.160162],
                            [113.257772, 27.164905],
                            [113.256762, 27.170873],
                            [113.260052, 27.173176],
                            [113.269519, 27.168039],
                            [113.280918, 27.16718],
                            [113.283651, 27.17462],
                            [113.283947, 27.17864],
                            [113.283251, 27.181951],
                            [113.279944, 27.191379],
                            [113.276376, 27.195262],
                            [113.27293, 27.202482],
                            [113.271921, 27.205683],
                            [113.265882, 27.212303],
                            [113.263323, 27.217057],
                            [113.260591, 27.219876],
                            [113.255405, 27.223635],
                            [113.255596, 27.229342],
                            [113.248426, 27.239433],
                            [113.24599, 27.240795],
                            [113.242648, 27.2404],
                            [113.236574, 27.23833],
                            [113.231858, 27.23735],
                            [113.227629, 27.23976],
                            [113.221033, 27.238753],
                            [113.210678, 27.238807],
                            [113.20704, 27.239529],
                            [113.203229, 27.239243],
                            [113.195015, 27.239937],
                            [113.190594, 27.239379],
                            [113.187166, 27.240632],
                            [113.183006, 27.240904],
                            [113.179491, 27.237336],
                            [113.173713, 27.233469],
                            [113.170006, 27.232897],
                            [113.164315, 27.236029],
                            [113.160451, 27.239747],
                            [113.154865, 27.246201],
                            [113.150183, 27.249075],
                            [113.148321, 27.251689],
                            [113.144144, 27.249306],
                            [113.135181, 27.232325],
                            [113.133232, 27.229642],
                            [113.131057, 27.220339],
                            [113.127506, 27.207345],
                            [113.125592, 27.205506],
                            [113.122512, 27.204716],
                            [113.117465, 27.204511],
                            [113.115481, 27.205615],
                            [113.11381, 27.212807],
                            [113.108275, 27.219195],
                            [113.107806, 27.220544],
                            [113.11019, 27.221048],
                            [113.107823, 27.225243],
                            [113.101279, 27.228961],
                            [113.097607, 27.232325],
                            [113.093883, 27.232093],
                            [113.089236, 27.2337],
                            [113.087861, 27.235471],
                            [113.092595, 27.238276],
                            [113.091324, 27.241136],
                            [113.087879, 27.245126],
                            [113.082431, 27.253895],
                            [113.085233, 27.255134],
                            [113.082971, 27.257176],
                            [113.082744, 27.259314],
                            [113.085581, 27.262146],
                            [113.08586, 27.266435],
                            [113.083876, 27.267701],
                            [113.080499, 27.267919],
                            [113.073834, 27.274331],
                            [113.069831, 27.279722],
                            [113.065237, 27.281777],
                            [113.061686, 27.281873],
                            [113.058223, 27.283043],
                            [113.055195, 27.285698],
                            [113.051018, 27.287467],
                            [113.050095, 27.288856],
                            [113.047363, 27.296873],
                            [113.047346, 27.302807],
                            [113.046336, 27.305774],
                            [113.04383, 27.309625],
                            [113.047381, 27.31213],
                            [113.048599, 27.314348],
                            [113.048842, 27.320921],
                            [113.046214, 27.324431],
                            [113.042508, 27.327139],
                            [113.032518, 27.329248],
                            [113.033545, 27.333793],
                            [113.032152, 27.341725],
                            [113.033336, 27.345031],
                            [113.040489, 27.348392],
                            [113.042212, 27.350922],
                            [113.041202, 27.353044],
                            [113.03713, 27.355616],
                            [113.034989, 27.357928],
                            [113.033388, 27.361288],
                            [113.025243, 27.36726],
                            [113.021902, 27.373802],
                            [113.021536, 27.378359],
                            [113.023868, 27.381636],
                            [113.024147, 27.385812],
                            [113.017359, 27.388981],
                            [113.010398, 27.389402],
                            [113.001992, 27.392748],
                            [112.995988, 27.399764],
                            [112.994265, 27.40708],
                            [112.997572, 27.415646],
                            [112.997241, 27.420785],
                            [112.999712, 27.429853],
                            [112.999242, 27.435617],
                            [112.994979, 27.438622],
                            [112.987947, 27.440226],
                            [112.978132, 27.439192],
                            [112.955942, 27.430288],
                            [112.939618, 27.426264],
                            [112.931369, 27.423042],
                            [112.925121, 27.418896],
                            [112.922615, 27.415075],
                            [112.921797, 27.40984],
                            [112.922388, 27.402144],
                            [112.924007, 27.388097],
                            [112.923955, 27.376958],
                            [112.924929, 27.367083],
                            [112.923415, 27.362363],
                            [112.920196, 27.356609],
                            [112.917759, 27.350269],
                            [112.920909, 27.341453],
                            [112.924703, 27.336923],
                            [112.93163, 27.330432],
                            [112.93612, 27.324867],
                            [112.941271, 27.315069],
                            [112.945848, 27.305475],
                            [112.947084, 27.294436],
                            [112.945953, 27.287154],
                            [112.941393, 27.281451],
                            [112.93572, 27.277407],
                            [112.927035, 27.273255],
                            [112.916767, 27.267047],
                            [112.913304, 27.264052],
                            [112.907004, 27.255692],
                            [112.892785, 27.246814],
                            [112.885006, 27.237554],
                            [112.877731, 27.230609],
                            [112.861789, 27.21861],
                            [112.852774, 27.209266],
                            [112.848771, 27.201133],
                            [112.848197, 27.192046],
                            [112.849955, 27.185616],
                            [112.854428, 27.179212],
                            [112.863669, 27.168052],
                            [112.867567, 27.161457],
                            [112.86936, 27.156946],
                            [112.869812, 27.150023],
                            [112.868699, 27.143767],
                            [112.863878, 27.136925],
                            [112.857647, 27.132277],
                            [112.85133, 27.130641],
                            [112.833178, 27.130273],
                            [112.822562, 27.126838],
                            [112.817306, 27.120131],
                            [112.814017, 27.11146],
                            [112.814347, 27.105311],
                            [112.817393, 27.099626],
                            [112.821465, 27.094962],
                            [112.827243, 27.089876],
                            [112.828322, 27.08704],
                            [112.827992, 27.08378],
                            [112.824807, 27.080753],
                            [112.818333, 27.078544],
                            [112.810762, 27.078394],
                            [112.800651, 27.08033],
                            [112.77733, 27.087571],
                            [112.767775, 27.089494],
                            [112.761475, 27.089453],
                            [112.755019, 27.088376],
                            [112.751242, 27.085349],
                            [112.7467, 27.077848],
                            [112.743672, 27.067129],
                            [112.743689, 27.059504],
                            [112.749171, 27.043299],
                            [112.756742, 27.023475],
                            [112.763546, 27.011604],
                            [112.767497, 27.007701],
                            [112.775955, 27.000414],
                            [112.777121, 26.992949],
                            [112.773049, 26.986507],
                            [112.767567, 26.981948],
                            [112.76191, 26.979],
                            [112.753644, 26.97571],
                            [112.748545, 26.974604],
                            [112.746178, 26.967984],
                            [112.748475, 26.96554],
                            [112.754514, 26.965472],
                            [112.756202, 26.966155],
                            [112.76299, 26.970973],
                            [112.76701, 26.971124],
                            [112.769324, 26.969185],
                            [112.774754, 26.958605],
                            [112.778531, 26.956571],
                            [112.783613, 26.949513],
                            [112.78638, 26.943478],
                            [112.786867, 26.940719],
                            [112.78537, 26.936091],
                            [112.786815, 26.931721],
                            [112.792036, 26.928129],
                            [112.792593, 26.925958],
                            [112.790539, 26.923841],
                            [112.787337, 26.923063],
                            [112.785597, 26.921069],
                            [112.785962, 26.918939],
                            [112.791688, 26.914432],
                            [112.795795, 26.906674],
                            [112.79623, 26.904721],
                            [112.804636, 26.903109],
                            [112.807194, 26.901293],
                            [112.811528, 26.900036],
                            [112.810449, 26.89796],
                            [112.81024, 26.89434],
                            [112.811093, 26.889887],
                            [112.815217, 26.886062],
                            [112.82016, 26.88531],
                            [112.821848, 26.883917],
                            [112.822074, 26.863109],
                            [112.821013, 26.85998],
                            [112.821674, 26.857466],
                            [112.824807, 26.857206],
                            [112.827748, 26.858846],
                            [112.830759, 26.858381],
                            [112.834466, 26.856441],
                            [112.838225, 26.856509],
                            [112.840696, 26.861387],
                            [112.845134, 26.859051],
                            [112.849781, 26.853995],
                            [112.853001, 26.856372],
                            [112.859248, 26.855607],
                            [112.863129, 26.857247],
                            [112.865322, 26.861784],
                            [112.869029, 26.863519],
                            [112.869934, 26.865418],
                            [112.871936, 26.8655],
                            [112.87204, 26.863519],
                            [112.874651, 26.863437],
                            [112.875956, 26.865787],
                            [112.879837, 26.867591],
                            [112.883022, 26.87128],
                            [112.892333, 26.878616],
                            [112.894525, 26.881977],
                            [112.897571, 26.888603],
                            [112.89865, 26.894736],
                            [112.897188, 26.903519],
                            [112.897762, 26.912916],
                            [112.900356, 26.917027],
                            [112.904515, 26.919458],
                            [112.911964, 26.92279],
                            [112.917481, 26.923568],
                            [112.923502, 26.923705],
                            [112.926078, 26.925289],
                            [112.930603, 26.930833],
                            [112.935493, 26.935039],
                            [112.941428, 26.939081],
                            [112.949068, 26.942085],
                            [112.953454, 26.943327],
                            [112.958083, 26.945649],
                            [112.965984, 26.947847],
                            [112.974286, 26.951492],
                            [112.980812, 26.955929],
                            [112.989566, 26.958469],
                            [112.997746, 26.96139],
                            [113.006099, 26.965363],
                            [113.009737, 26.965008],
                            [113.011547, 26.962264],
                            [113.011964, 26.959124],
                            [113.015532, 26.95425],
                            [113.017899, 26.953199],
                            [113.029455, 26.953895],
                            [113.032135, 26.951943],
                            [113.034049, 26.949239],
                            [113.036869, 26.948598],
                            [113.040071, 26.949376],
                            [113.046145, 26.954114],
                            [113.052166, 26.952421],
                            [113.060938, 26.952366],
                            [113.066716, 26.951533],
                            [113.074043, 26.947246],
                            [113.074791, 26.945239],
                            [113.074774, 26.936705],
                            [113.076027, 26.931762],
                            [113.073451, 26.925467],
                            [113.072198, 26.920209],
                            [113.070371, 26.917641],
                            [113.064975, 26.913776],
                            [113.063479, 26.911605],
                            [113.063357, 26.906387],
                            [113.064314, 26.904612],
                            [113.063061, 26.901224],
                            [113.06367, 26.89796],
                            [113.070405, 26.891499],
                            [113.069988, 26.880898],
                            [113.073747, 26.878643],
                            [113.078933, 26.877687],
                            [113.083267, 26.876061],
                            [113.088992, 26.872987],
                            [113.088784, 26.870064],
                            [113.084085, 26.866661],
                            [113.082135, 26.864311],
                            [113.074687, 26.861674],
                            [113.071693, 26.857356],
                            [113.073938, 26.848706],
                            [113.073468, 26.840616],
                            [113.070806, 26.835505],
                            [113.070997, 26.833099],
                            [113.075818, 26.828534],
                            [113.075052, 26.825199],
                            [113.070492, 26.821277],
                            [113.069013, 26.817887],
                            [113.070928, 26.81343],
                            [113.072859, 26.811093],
                            [113.078115, 26.809261],
                            [113.083876, 26.809398],
                            [113.086034, 26.806349],
                            [113.089863, 26.799213],
                            [113.091551, 26.794223],
                            [113.092264, 26.788768],
                            [113.097259, 26.78163]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430426,
                "name": "祁东县",
                "center": [112.111192, 26.787109],
                "centroid": [111.954938, 26.800476],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430400
                },
                "subFeatureIndex": 9,
                "acroutes": [100000, 430000, 430400]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.00162, 26.944311],
                            [111.99666, 26.944037],
                            [111.993875, 26.945102],
                            [111.988828, 26.94244],
                            [111.979483, 26.94229],
                            [111.980336, 26.944884],
                            [111.986357, 26.950018],
                            [111.9858, 26.955793],
                            [111.983781, 26.958237],
                            [111.975236, 26.959097],
                            [111.972504, 26.958114],
                            [111.968936, 26.952421],
                            [111.965821, 26.951287],
                            [111.961801, 26.951151],
                            [111.955396, 26.95481],
                            [111.953586, 26.955192],
                            [111.948417, 26.953281],
                            [111.945703, 26.954742],
                            [111.935626, 26.952243],
                            [111.934843, 26.951465],
                            [111.931223, 26.943041],
                            [111.92983, 26.938562],
                            [111.925219, 26.934916],
                            [111.922799, 26.936487],
                            [111.917822, 26.935162],
                            [111.912009, 26.930096],
                            [111.907432, 26.924756],
                            [111.905413, 26.923418],
                            [111.901741, 26.924511],
                            [111.898522, 26.926655],
                            [111.897443, 26.929563],
                            [111.899044, 26.933715],
                            [111.898713, 26.940324],
                            [111.897704, 26.945007],
                            [111.899113, 26.947178],
                            [111.898034, 26.953349],
                            [111.899009, 26.960462],
                            [111.898243, 26.968844],
                            [111.894345, 26.978249],
                            [111.898278, 26.984828],
                            [111.898382, 26.986657],
                            [111.890133, 26.994682],
                            [111.883624, 26.996415],
                            [111.880944, 26.999472],
                            [111.877289, 26.997057],
                            [111.876419, 27.000687],
                            [111.877394, 27.00373],
                            [111.876523, 27.00706],
                            [111.871355, 27.009011],
                            [111.870345, 27.0126],
                            [111.868709, 27.013419],
                            [111.865611, 27.012095],
                            [111.863192, 27.009776],
                            [111.859973, 27.009434],
                            [111.854891, 27.011017],
                            [111.849061, 27.010472],
                            [111.844658, 27.008411],
                            [111.841943, 27.008725],
                            [111.829778, 27.016776],
                            [111.827132, 27.015698],
                            [111.822607, 27.020269],
                            [111.818413, 27.019559],
                            [111.817439, 27.020965],
                            [111.82038, 27.031402],
                            [111.823373, 27.034267],
                            [111.823234, 27.036778],
                            [111.816064, 27.045454],
                            [111.811382, 27.04645],
                            [111.809154, 27.046013],
                            [111.807832, 27.043667],
                            [111.810425, 27.03761],
                            [111.807849, 27.033121],
                            [111.804891, 27.033667],
                            [111.803168, 27.035454],
                            [111.796763, 27.036082],
                            [111.79464, 27.038237],
                            [111.79095, 27.03937],
                            [111.78693, 27.039684],
                            [111.783867, 27.043244],
                            [111.779621, 27.044172],
                            [111.776941, 27.045781],
                            [111.775252, 27.053475],
                            [111.773634, 27.056872],
                            [111.770258, 27.059013],
                            [111.767804, 27.059354],
                            [111.762826, 27.057485],
                            [111.758545, 27.058426],
                            [111.753533, 27.057308],
                            [111.754351, 27.059709],
                            [111.758023, 27.062737],
                            [111.756196, 27.063187],
                            [111.753324, 27.061455],
                            [111.748851, 27.0614],
                            [111.745301, 27.060377],
                            [111.743978, 27.062464],
                            [111.73787, 27.061564],
                            [111.736477, 27.063582],
                            [111.732196, 27.066801],
                            [111.72654, 27.069543],
                            [111.723755, 27.07152],
                            [111.724782, 27.069065],
                            [111.721336, 27.064987],
                            [111.720083, 27.060732],
                            [111.718012, 27.059654],
                            [111.713801, 27.055057],
                            [111.712861, 27.052329],
                            [111.709867, 27.050433],
                            [111.706804, 27.050174],
                            [111.703985, 27.047773],
                            [111.699269, 27.0466],
                            [111.697302, 27.039643],
                            [111.690341, 27.036723],
                            [111.688287, 27.031375],
                            [111.687051, 27.030679],
                            [111.68451, 27.032316],
                            [111.678837, 27.032344],
                            [111.677009, 27.033053],
                            [111.674016, 27.031962],
                            [111.66977, 27.032548],
                            [111.665819, 27.031743],
                            [111.664079, 27.029465],
                            [111.665523, 27.026859],
                            [111.66065, 27.023448],
                            [111.662478, 27.019614],
                            [111.66112, 27.01739],
                            [111.659589, 27.012164],
                            [111.6567, 27.009776],
                            [111.651513, 27.008711],
                            [111.649303, 27.004454],
                            [111.64979, 27.001137],
                            [111.653967, 26.996443],
                            [111.652157, 26.993276],
                            [111.657483, 26.988267],
                            [111.657692, 26.986015],
                            [111.652766, 26.974127],
                            [111.649216, 26.967411],
                            [111.649703, 26.962933],
                            [111.647859, 26.964366],
                            [111.644517, 26.964626],
                            [111.642394, 26.962947],
                            [111.635276, 26.963738],
                            [111.63324, 26.961786],
                            [111.630699, 26.957677],
                            [111.62633, 26.955315],
                            [111.622049, 26.954209],
                            [111.619856, 26.954919],
                            [111.613678, 26.959697],
                            [111.610145, 26.960271],
                            [111.606543, 26.958387],
                            [111.602174, 26.959179],
                            [111.599459, 26.960749],
                            [111.593473, 26.962319],
                            [111.59168, 26.96539],
                            [111.587573, 26.966933],
                            [111.584614, 26.964025],
                            [111.581464, 26.962141],
                            [111.579898, 26.95369],
                            [111.576487, 26.945021],
                            [111.571596, 26.939654],
                            [111.568255, 26.937306],
                            [111.567124, 26.934821],
                            [111.570935, 26.932363],
                            [111.57459, 26.927788],
                            [111.583135, 26.925043],
                            [111.583605, 26.923227],
                            [111.581899, 26.915948],
                            [111.583048, 26.909037],
                            [111.589243, 26.899066],
                            [111.589087, 26.89673],
                            [111.585188, 26.893001],
                            [111.583988, 26.889914],
                            [111.580994, 26.888616],
                            [111.578575, 26.883111],
                            [111.576835, 26.882496],
                            [111.570952, 26.884709],
                            [111.567837, 26.883466],
                            [111.565871, 26.879368],
                            [111.565383, 26.872659],
                            [111.562982, 26.867809],
                            [111.558822, 26.86535],
                            [111.554436, 26.864489],
                            [111.551008, 26.860021],
                            [111.549964, 26.854391],
                            [111.552052, 26.851781],
                            [111.550747, 26.846944],
                            [111.547005, 26.842775],
                            [111.543611, 26.840931],
                            [111.545787, 26.836571],
                            [111.549128, 26.832307],
                            [111.545735, 26.825582],
                            [111.545944, 26.821714],
                            [111.549999, 26.818584],
                            [111.549999, 26.81235],
                            [111.553079, 26.809302],
                            [111.558074, 26.80866],
                            [111.567541, 26.80948],
                            [111.573058, 26.810314],
                            [111.575373, 26.809343],
                            [111.576487, 26.802658],
                            [111.578401, 26.798393],
                            [111.588008, 26.797586],
                            [111.590479, 26.792869],
                            [111.598467, 26.788795],
                            [111.601252, 26.78591],
                            [111.603253, 26.785431],
                            [111.607778, 26.786566],
                            [111.61279, 26.79019],
                            [111.615627, 26.791365],
                            [111.618499, 26.791297],
                            [111.625756, 26.789219],
                            [111.629654, 26.789287],
                            [111.631099, 26.794072],
                            [111.632857, 26.796328],
                            [111.639035, 26.797231],
                            [111.640427, 26.799213],
                            [111.637068, 26.800867],
                            [111.637991, 26.802316],
                            [111.6445, 26.803232],
                            [111.643038, 26.805898],
                            [111.642446, 26.817244],
                            [111.644099, 26.820771],
                            [111.644152, 26.825295],
                            [111.647632, 26.830571],
                            [111.648764, 26.838266],
                            [111.650852, 26.840425],
                            [111.656473, 26.841313],
                            [111.661155, 26.841108],
                            [111.665976, 26.843308],
                            [111.670605, 26.841901],
                            [111.67647, 26.83735],
                            [111.684615, 26.833523],
                            [111.689227, 26.833605],
                            [111.693699, 26.837118],
                            [111.697946, 26.835806],
                            [111.701461, 26.836202],
                            [111.700574, 26.843185],
                            [111.701653, 26.844415],
                            [111.706369, 26.844716],
                            [111.709606, 26.846083],
                            [111.712687, 26.849171],
                            [111.719248, 26.847272],
                            [111.722433, 26.845017],
                            [111.725043, 26.841122],
                            [111.726227, 26.83597],
                            [111.731256, 26.832621],
                            [111.739018, 26.832881],
                            [111.743735, 26.839127],
                            [111.746728, 26.841518],
                            [111.749721, 26.841054],
                            [111.750974, 26.838648],
                            [111.747946, 26.835518],
                            [111.748172, 26.833113],
                            [111.752315, 26.831569],
                            [111.75181, 26.82889],
                            [111.749895, 26.827263],
                            [111.744988, 26.825514],
                            [111.743996, 26.823655],
                            [111.743004, 26.818215],
                            [111.743265, 26.814784],
                            [111.744709, 26.812159],
                            [111.749948, 26.809425],
                            [111.750609, 26.808058],
                            [111.753689, 26.807019],
                            [111.758475, 26.807607],
                            [111.761486, 26.810109],
                            [111.764549, 26.810437],
                            [111.765593, 26.809193],
                            [111.77212, 26.808755],
                            [111.772746, 26.806308],
                            [111.771719, 26.799883],
                            [111.779708, 26.793143],
                            [111.781274, 26.789164],
                            [111.782997, 26.789014],
                            [111.792482, 26.790285],
                            [111.797303, 26.792568],
                            [111.799182, 26.794264],
                            [111.802489, 26.801523],
                            [111.805343, 26.80259],
                            [111.81361, 26.804121],
                            [111.820554, 26.80449],
                            [111.823269, 26.80274],
                            [111.824034, 26.798461],
                            [111.822433, 26.791789],
                            [111.818431, 26.790887],
                            [111.817073, 26.783982],
                            [111.821372, 26.783121],
                            [111.824591, 26.780632],
                            [111.827533, 26.780905],
                            [111.831831, 26.78617],
                            [111.834041, 26.786935],
                            [111.838827, 26.786539],
                            [111.845075, 26.787469],
                            [111.850192, 26.78982],
                            [111.856057, 26.795481],
                            [111.859172, 26.799568],
                            [111.863523, 26.80069],
                            [111.867944, 26.800006],
                            [111.871511, 26.796492],
                            [111.874748, 26.789533],
                            [111.874748, 26.785869],
                            [111.877046, 26.782287],
                            [111.880161, 26.780632],
                            [111.885921, 26.774479],
                            [111.888636, 26.767573],
                            [111.893753, 26.763402],
                            [111.896729, 26.760284],
                            [111.898939, 26.754184],
                            [111.903325, 26.751777],
                            [111.917996, 26.748932],
                            [111.916395, 26.733667],
                            [111.920363, 26.724652],
                            [111.923461, 26.720124],
                            [111.927046, 26.711053],
                            [111.932441, 26.706018],
                            [111.93754, 26.702488],
                            [111.940342, 26.699436],
                            [111.94337, 26.689898],
                            [111.946555, 26.686231],
                            [111.949009, 26.681509],
                            [111.951446, 26.673339],
                            [111.951916, 26.664237],
                            [111.954126, 26.660774],
                            [111.959329, 26.656435],
                            [111.961192, 26.651425],
                            [111.960321, 26.646647],
                            [111.960965, 26.645278],
                            [111.969598, 26.637447],
                            [111.973566, 26.630903],
                            [111.980684, 26.627083],
                            [111.984582, 26.626946],
                            [111.988515, 26.625727],
                            [111.990203, 26.624152],
                            [111.991787, 26.615731],
                            [111.994398, 26.614444],
                            [111.995651, 26.612458],
                            [111.99753, 26.605118],
                            [112.003917, 26.599366],
                            [112.006023, 26.596901],
                            [112.01013, 26.597696],
                            [112.012114, 26.599928],
                            [112.014934, 26.605337],
                            [112.017858, 26.614951],
                            [112.019737, 26.619182],
                            [112.018084, 26.622427],
                            [112.013541, 26.623057],
                            [112.012567, 26.624467],
                            [112.013977, 26.627575],
                            [112.010357, 26.626672],
                            [112.008112, 26.624426],
                            [112.005501, 26.62481],
                            [112.004787, 26.630026],
                            [111.998766, 26.634859],
                            [111.995685, 26.639391],
                            [111.996155, 26.64406],
                            [112.001794, 26.645784],
                            [112.006493, 26.648481],
                            [112.018884, 26.64855],
                            [112.022365, 26.650412],
                            [112.025707, 26.649837],
                            [112.028508, 26.651822],
                            [112.031693, 26.650932],
                            [112.03493, 26.648386],
                            [112.045825, 26.643224],
                            [112.056145, 26.647947],
                            [112.063768, 26.646031],
                            [112.070851, 26.645429],
                            [112.076873, 26.643964],
                            [112.07964, 26.642321],
                            [112.085644, 26.63679],
                            [112.08909, 26.636174],
                            [112.090883, 26.633545],
                            [112.093458, 26.634024],
                            [112.094172, 26.637515],
                            [112.093389, 26.642636],
                            [112.094189, 26.645114],
                            [112.09955, 26.645333],
                            [112.101238, 26.646318],
                            [112.102091, 26.652164],
                            [112.105084, 26.65408],
                            [112.1098, 26.654039],
                            [112.111506, 26.65048],
                            [112.11429, 26.651383],
                            [112.117823, 26.65048],
                            [112.118763, 26.648358],
                            [112.117162, 26.647742],
                            [112.115735, 26.643389],
                            [112.120678, 26.639213],
                            [112.126403, 26.636543],
                            [112.126334, 26.635736],
                            [112.133539, 26.636105],
                            [112.133173, 26.633983],
                            [112.137559, 26.630519],
                            [112.142345, 26.630601],
                            [112.146887, 26.626439],
                            [112.151082, 26.625713],
                            [112.151604, 26.621154],
                            [112.150316, 26.617032],
                            [112.145164, 26.614704],
                            [112.144538, 26.61328],
                            [112.145843, 26.606693],
                            [112.144433, 26.603776],
                            [112.141457, 26.603365],
                            [112.141597, 26.596079],
                            [112.143911, 26.593436],
                            [112.149915, 26.591916],
                            [112.158565, 26.591834],
                            [112.160479, 26.589697],
                            [112.164621, 26.588656],
                            [112.170539, 26.585355],
                            [112.171757, 26.582533],
                            [112.171774, 26.577616],
                            [112.168276, 26.575698],
                            [112.168189, 26.574643],
                            [112.174698, 26.571191],
                            [112.172488, 26.565986],
                            [112.174315, 26.56315],
                            [112.168346, 26.560136],
                            [112.169494, 26.557067],
                            [112.165996, 26.553793],
                            [112.162637, 26.55508],
                            [112.160253, 26.552984],
                            [112.158478, 26.555615],
                            [112.156407, 26.555519],
                            [112.154127, 26.549984],
                            [112.150107, 26.548531],
                            [112.149132, 26.547175],
                            [112.151256, 26.54475],
                            [112.160827, 26.541105],
                            [112.162742, 26.539337],
                            [112.160897, 26.534993],
                            [112.154336, 26.535816],
                            [112.153414, 26.532637],
                            [112.14734, 26.527457],
                            [112.146731, 26.525209],
                            [112.148767, 26.520029],
                            [112.147496, 26.515259],
                            [112.147618, 26.511243],
                            [112.145826, 26.50831],
                            [112.144747, 26.504459],
                            [112.147931, 26.503225],
                            [112.152456, 26.502704],
                            [112.161941, 26.511161],
                            [112.168067, 26.518494],
                            [112.174193, 26.524126],
                            [112.181903, 26.529704],
                            [112.190448, 26.536898],
                            [112.200212, 26.54242],
                            [112.207869, 26.54638],
                            [112.213125, 26.548545],
                            [112.216762, 26.548901],
                            [112.222192, 26.54849],
                            [112.227814, 26.546462],
                            [112.235645, 26.541091],
                            [112.242694, 26.535048],
                            [112.244364, 26.531266],
                            [112.24433, 26.523112],
                            [112.243216, 26.514053],
                            [112.24017, 26.508776],
                            [112.236672, 26.505391],
                            [112.231938, 26.503198],
                            [112.227274, 26.502883],
                            [112.217998, 26.504335],
                            [112.215144, 26.504185],
                            [112.21128, 26.502745],
                            [112.206999, 26.49999],
                            [112.201761, 26.495261],
                            [112.195356, 26.486598],
                            [112.194573, 26.479606],
                            [112.19607, 26.475986],
                            [112.20049, 26.471654],
                            [112.206077, 26.470255],
                            [112.213003, 26.472257],
                            [112.221635, 26.47186],
                            [112.233644, 26.466732],
                            [112.247358, 26.463907],
                            [112.256181, 26.463592],
                            [112.264901, 26.465265],
                            [112.277692, 26.47053],
                            [112.28763, 26.476795],
                            [112.30606, 26.48583],
                            [112.315527, 26.492794],
                            [112.32228, 26.504514],
                            [112.32221, 26.509873],
                            [112.320157, 26.518055],
                            [112.318155, 26.524154],
                            [112.309193, 26.545325],
                            [112.308514, 26.550422],
                            [112.30914, 26.553368],
                            [112.311142, 26.557314],
                            [112.314205, 26.559848],
                            [112.319582, 26.562438],
                            [112.326962, 26.564232],
                            [112.321932, 26.56926],
                            [112.322245, 26.573739],
                            [112.323307, 26.574917],
                            [112.328737, 26.576],
                            [112.332513, 26.577904],
                            [112.336464, 26.582643],
                            [112.340484, 26.593683],
                            [112.33944, 26.599914],
                            [112.336046, 26.601065],
                            [112.3322, 26.604653],
                            [112.32851, 26.603708],
                            [112.32503, 26.604612],
                            [112.327014, 26.607707],
                            [112.325935, 26.609829],
                            [112.321149, 26.604571],
                            [112.318451, 26.604721],
                            [112.31685, 26.611651],
                            [112.314849, 26.611979],
                            [112.307104, 26.609911],
                            [112.304059, 26.610117],
                            [112.3015, 26.612322],
                            [112.299673, 26.615334],
                            [112.29976, 26.617826],
                            [112.302109, 26.623071],
                            [112.300334, 26.625207],
                            [112.294574, 26.625371],
                            [112.290588, 26.623221],
                            [112.288169, 26.62355],
                            [112.285576, 26.628808],
                            [112.283505, 26.629698],
                            [112.281904, 26.628151],
                            [112.280668, 26.623358],
                            [112.277797, 26.621332],
                            [112.270853, 26.621441],
                            [112.262116, 26.627849],
                            [112.256791, 26.629082],
                            [112.253484, 26.630971],
                            [112.253171, 26.634777],
                            [112.260811, 26.647277],
                            [112.261681, 26.652383],
                            [112.261002, 26.658392],
                            [112.261263, 26.66295],
                            [112.259349, 26.666016],
                            [112.254911, 26.666495],
                            [112.247584, 26.662074],
                            [112.24433, 26.662594],
                            [112.243459, 26.66603],
                            [112.243529, 26.673038],
                            [112.24245, 26.673996],
                            [112.234897, 26.674995],
                            [112.231625, 26.679703],
                            [112.227065, 26.680921],
                            [112.226822, 26.68504],
                            [112.225255, 26.686682],
                            [112.223428, 26.685382],
                            [112.219686, 26.685492],
                            [112.222279, 26.694223],
                            [112.222871, 26.697712],
                            [112.220313, 26.698601],
                            [112.215039, 26.697849],
                            [112.211594, 26.699368],
                            [112.211611, 26.701803],
                            [112.209488, 26.704075],
                            [112.210776, 26.706839],
                            [112.207939, 26.707605],
                            [112.207399, 26.709671],
                            [112.204214, 26.708084],
                            [112.202805, 26.70573],
                            [112.202265, 26.707974],
                            [112.204719, 26.711231],
                            [112.20317, 26.715048],
                            [112.204389, 26.719522],
                            [112.203919, 26.72115],
                            [112.199724, 26.72405],
                            [112.198297, 26.726868],
                            [112.202787, 26.731971],
                            [112.205711, 26.732627],
                            [112.210741, 26.730274],
                            [112.218712, 26.729823],
                            [112.222297, 26.728031],
                            [112.22978, 26.727648],
                            [112.235367, 26.730835],
                            [112.241267, 26.732258],
                            [112.249812, 26.732545],
                            [112.24969, 26.734611],
                            [112.255659, 26.735049],
                            [112.259749, 26.740807],
                            [112.257469, 26.742353],
                            [112.261072, 26.747127],
                            [112.257713, 26.751148],
                            [112.257382, 26.752707],
                            [112.25371, 26.753363],
                            [112.254354, 26.755538],
                            [112.251187, 26.763265],
                            [112.246192, 26.76332],
                            [112.242241, 26.764277],
                            [112.240884, 26.766917],
                            [112.238569, 26.765002],
                            [112.235123, 26.766287],
                            [112.235993, 26.770581],
                            [112.237194, 26.771867],
                            [112.236881, 26.774684],
                            [112.238412, 26.775969],
                            [112.240414, 26.774465],
                            [112.243529, 26.774123],
                            [112.247149, 26.775203],
                            [112.247236, 26.776516],
                            [112.244138, 26.778827],
                            [112.245339, 26.781111],
                            [112.245739, 26.785349],
                            [112.248071, 26.782273],
                            [112.253832, 26.783599],
                            [112.256738, 26.787742],
                            [112.26055, 26.787127],
                            [112.256094, 26.793143],
                            [112.25559, 26.797572],
                            [112.253519, 26.799459],
                            [112.248454, 26.799008],
                            [112.241388, 26.801318],
                            [112.240536, 26.804135],
                            [112.233975, 26.807306],
                            [112.230268, 26.804763],
                            [112.226126, 26.806923],
                            [112.224977, 26.810355],
                            [112.221618, 26.808017],
                            [112.214918, 26.810273],
                            [112.208948, 26.81045],
                            [112.205433, 26.811366],
                            [112.204563, 26.813745],
                            [112.205137, 26.817162],
                            [112.202318, 26.817818],
                            [112.197514, 26.817176],
                            [112.18655, 26.822493],
                            [112.184079, 26.825514],
                            [112.179136, 26.829778],
                            [112.17931, 26.83202],
                            [112.176334, 26.832102],
                            [112.171861, 26.829847],
                            [112.165909, 26.83109],
                            [112.161611, 26.830694],
                            [112.160375, 26.831391],
                            [112.159905, 26.839318],
                            [112.158669, 26.84089],
                            [112.153414, 26.842844],
                            [112.14828, 26.842024],
                            [112.145338, 26.840425],
                            [112.142032, 26.841887],
                            [112.140935, 26.843896],
                            [112.135993, 26.847805],
                            [112.133817, 26.85338],
                            [112.127709, 26.859256],
                            [112.1212, 26.869353],
                            [112.116483, 26.873452],
                            [112.117179, 26.876348],
                            [112.120973, 26.877728],
                            [112.122174, 26.879654],
                            [112.120051, 26.878466],
                            [112.114203, 26.876867],
                            [112.105711, 26.876936],
                            [112.101151, 26.877455],
                            [112.095999, 26.876772],
                            [112.089282, 26.876854],
                            [112.080928, 26.875269],
                            [112.072766, 26.876471],
                            [112.063959, 26.87934],
                            [112.059469, 26.879217],
                            [112.055936, 26.881116],
                            [112.051586, 26.879805],
                            [112.039682, 26.882223],
                            [112.038585, 26.883343],
                            [112.03902, 26.886157],
                            [112.035853, 26.890406],
                            [112.027934, 26.893684],
                            [112.02421, 26.896812],
                            [112.020851, 26.902454],
                            [112.015874, 26.905131],
                            [112.015699, 26.908245],
                            [112.010722, 26.917573],
                            [112.007067, 26.920878],
                            [112.004318, 26.925357],
                            [112.003447, 26.928266],
                            [112.003674, 26.933715],
                            [112.002003, 26.937989],
                            [112.00162, 26.944311]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430481,
                "name": "耒阳市",
                "center": [112.847215, 26.414162],
                "centroid": [112.915707, 26.418715],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430400
                },
                "subFeatureIndex": 10,
                "acroutes": [100000, 430000, 430400]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.686414, 26.123826],
                            [112.685613, 26.121901],
                            [112.686118, 26.115643],
                            [112.688555, 26.114213],
                            [112.693845, 26.116317],
                            [112.699136, 26.117294],
                            [112.707716, 26.122437],
                            [112.709648, 26.125132],
                            [112.710292, 26.131747],
                            [112.713337, 26.134414],
                            [112.716348, 26.133686],
                            [112.71821, 26.131857],
                            [112.720368, 26.12549],
                            [112.724441, 26.12681],
                            [112.729174, 26.132297],
                            [112.735057, 26.134566],
                            [112.739982, 26.144452],
                            [112.741896, 26.145538],
                            [112.746213, 26.143778],
                            [112.747483, 26.140561],
                            [112.747396, 26.133961],
                            [112.752513, 26.129877],
                            [112.755558, 26.129835],
                            [112.758586, 26.131101],
                            [112.760396, 26.133081],
                            [112.760449, 26.137591],
                            [112.758447, 26.142651],
                            [112.75876, 26.149842],
                            [112.763129, 26.151904],
                            [112.765113, 26.151368],
                            [112.766488, 26.147243],
                            [112.76835, 26.145511],
                            [112.773014, 26.144658],
                            [112.780115, 26.144562],
                            [112.782133, 26.146033],
                            [112.782238, 26.154599],
                            [112.781629, 26.157238],
                            [112.782412, 26.162627],
                            [112.78363, 26.164222],
                            [112.791392, 26.166284],
                            [112.790957, 26.168923],
                            [112.793045, 26.170917],
                            [112.79087, 26.17379],
                            [112.788103, 26.175343],
                            [112.782168, 26.175755],
                            [112.777173, 26.177336],
                            [112.775346, 26.180044],
                            [112.775973, 26.183893],
                            [112.781072, 26.190105],
                            [112.783195, 26.194366],
                            [112.786014, 26.197004],
                            [112.79047, 26.196441],
                            [112.791723, 26.193239],
                            [112.789895, 26.18337],
                            [112.789843, 26.179851],
                            [112.792993, 26.179137],
                            [112.795604, 26.180099],
                            [112.799955, 26.18491],
                            [112.802635, 26.190242],
                            [112.806202, 26.192194],
                            [112.809735, 26.192153],
                            [112.814713, 26.189899],
                            [112.818542, 26.186655],
                            [112.820299, 26.179082],
                            [112.82056, 26.17203],
                            [112.820282, 26.165501],
                            [112.818768, 26.161803],
                            [112.820212, 26.159672],
                            [112.823188, 26.158517],
                            [112.828531, 26.160249],
                            [112.831177, 26.159479],
                            [112.835736, 26.156248],
                            [112.83859, 26.155767],
                            [112.846457, 26.158283],
                            [112.851956, 26.155602],
                            [112.857908, 26.154186],
                            [112.862242, 26.154502],
                            [112.865723, 26.155671],
                            [112.870039, 26.155822],
                            [112.874163, 26.153897],
                            [112.874268, 26.151148],
                            [112.875851, 26.149814],
                            [112.878775, 26.150543],
                            [112.883909, 26.154255],
                            [112.88645, 26.154461],
                            [112.891079, 26.156551],
                            [112.894786, 26.153815],
                            [112.903627, 26.148467],
                            [112.906708, 26.148426],
                            [112.91085, 26.150488],
                            [112.921014, 26.157953],
                            [112.92164, 26.162201],
                            [112.921066, 26.174408],
                            [112.922667, 26.176869],
                            [112.930133, 26.176772],
                            [112.937338, 26.17368],
                            [112.942611, 26.170793],
                            [112.946457, 26.166504],
                            [112.947362, 26.162242],
                            [112.949886, 26.160167],
                            [112.958901, 26.159424],
                            [112.96863, 26.154214],
                            [112.975104, 26.153691],
                            [112.984571, 26.156592],
                            [112.992977, 26.162325],
                            [112.997937, 26.16282],
                            [113.000983, 26.161967],
                            [113.005542, 26.157733],
                            [113.008658, 26.155644],
                            [113.012486, 26.155602],
                            [113.016263, 26.16084],
                            [113.022807, 26.163562],
                            [113.025957, 26.165803],
                            [113.029768, 26.173473],
                            [113.030865, 26.174106],
                            [113.034049, 26.171686],
                            [113.035651, 26.172827],
                            [113.037269, 26.179192],
                            [113.035825, 26.191218],
                            [113.038, 26.195163],
                            [113.037234, 26.199973],
                            [113.034937, 26.206638],
                            [113.035477, 26.210238],
                            [113.039897, 26.213605],
                            [113.035041, 26.221011],
                            [113.033614, 26.224227],
                            [113.033005, 26.229434],
                            [113.031265, 26.232827],
                            [113.025765, 26.238804],
                            [113.025208, 26.242417],
                            [113.02587, 26.245274],
                            [113.032118, 26.252006],
                            [113.040001, 26.255014],
                            [113.045275, 26.254533],
                            [113.052045, 26.248489],
                            [113.0624, 26.25239],
                            [113.068143, 26.252926],
                            [113.07131, 26.249643],
                            [113.073521, 26.248544],
                            [113.078359, 26.249313],
                            [113.084415, 26.249162],
                            [113.090211, 26.248434],
                            [113.091673, 26.250096],
                            [113.088697, 26.259052],
                            [113.088366, 26.264437],
                            [113.086591, 26.266277],
                            [113.08626, 26.271208],
                            [113.087113, 26.273611],
                            [113.092177, 26.278954],
                            [113.09625, 26.285862],
                            [113.100496, 26.289775],
                            [113.103542, 26.290558],
                            [113.113375, 26.287455],
                            [113.117064, 26.287304],
                            [113.117465, 26.289995],
                            [113.119205, 26.293016],
                            [113.124409, 26.291259],
                            [113.127019, 26.29141],
                            [113.128812, 26.293044],
                            [113.126497, 26.297424],
                            [113.126584, 26.300404],
                            [113.130639, 26.306555],
                            [113.130674, 26.309356],
                            [113.128324, 26.311155],
                            [113.127332, 26.313571],
                            [113.128307, 26.315617],
                            [113.136835, 26.323277],
                            [113.142056, 26.325872],
                            [113.149487, 26.322934],
                            [113.153699, 26.323181],
                            [113.158328, 26.326627],
                            [113.162696, 26.324293],
                            [113.165829, 26.326792],
                            [113.170911, 26.326339],
                            [113.172808, 26.3273],
                            [113.1738, 26.329688],
                            [113.167691, 26.335989],
                            [113.165516, 26.340944],
                            [113.164785, 26.344499],
                            [113.165951, 26.34649],
                            [113.168231, 26.346874],
                            [113.168892, 26.349097],
                            [113.166891, 26.351746],
                            [113.16247, 26.35397],
                            [113.158119, 26.359226],
                            [113.15657, 26.362451],
                            [113.157319, 26.368339],
                            [113.161478, 26.371399],
                            [113.167099, 26.374267],
                            [113.167883, 26.37649],
                            [113.165707, 26.381663],
                            [113.160434, 26.387728],
                            [113.159877, 26.390142],
                            [113.161565, 26.395905],
                            [113.162018, 26.402559],
                            [113.163584, 26.409555],
                            [113.166838, 26.412587],
                            [113.171868, 26.412093],
                            [113.179073, 26.404946],
                            [113.18546, 26.403807],
                            [113.191238, 26.40618],
                            [113.198687, 26.412669],
                            [113.212209, 26.425248],
                            [113.212992, 26.429774],
                            [113.21167, 26.433107],
                            [113.207389, 26.436481],
                            [113.205109, 26.437139],
                            [113.200183, 26.440046],
                            [113.1923, 26.445518],
                            [113.192004, 26.446917],
                            [113.194928, 26.451689],
                            [113.204465, 26.462056],
                            [113.210104, 26.465169],
                            [113.214837, 26.463098],
                            [113.217396, 26.462728],
                            [113.226846, 26.467047],
                            [113.228743, 26.473834],
                            [113.230065, 26.482087],
                            [113.227925, 26.48646],
                            [113.21435, 26.502458],
                            [113.209599, 26.505089],
                            [113.200479, 26.502992],
                            [113.19451, 26.503513],
                            [113.189637, 26.506145],
                            [113.189045, 26.51101],
                            [113.183963, 26.512792],
                            [113.182206, 26.514752],
                            [113.181475, 26.51763],
                            [113.179404, 26.519357],
                            [113.171659, 26.521221],
                            [113.170145, 26.522783],
                            [113.168735, 26.528005],
                            [113.164611, 26.530389],
                            [113.159999, 26.534226],
                            [113.159216, 26.53698],
                            [113.159181, 26.541612],
                            [113.160556, 26.544188],
                            [113.164785, 26.549244],
                            [113.174461, 26.556656],
                            [113.179143, 26.564438],
                            [113.17923, 26.578493],
                            [113.181927, 26.584026],
                            [113.184172, 26.593285],
                            [113.184085, 26.602681],
                            [113.186574, 26.604146],
                            [113.186678, 26.608104],
                            [113.182693, 26.611979],
                            [113.18184, 26.615142],
                            [113.183441, 26.61684],
                            [113.183859, 26.620099],
                            [113.17956, 26.630177],
                            [113.179578, 26.638364],
                            [113.184503, 26.647715],
                            [113.18633, 26.652],
                            [113.186783, 26.658118],
                            [113.188158, 26.662458],
                            [113.186644, 26.665003],
                            [113.185095, 26.67082],
                            [113.18466, 26.676815],
                            [113.18184, 26.680976],
                            [113.182989, 26.687791],
                            [113.177977, 26.689898],
                            [113.174148, 26.689939],
                            [113.165394, 26.686984],
                            [113.160886, 26.687887],
                            [113.155979, 26.695153],
                            [113.15295, 26.697999],
                            [113.151454, 26.694223],
                            [113.148495, 26.692307],
                            [113.144544, 26.691253],
                            [113.139828, 26.691185],
                            [113.134972, 26.690145],
                            [113.12507, 26.695331],
                            [113.122372, 26.700449],
                            [113.119657, 26.702474],
                            [113.120145, 26.705293],
                            [113.11783, 26.705594],
                            [113.110642, 26.699751],
                            [113.108902, 26.692772],
                            [113.105195, 26.691308],
                            [113.099434, 26.69061],
                            [113.094492, 26.693566],
                            [113.090663, 26.694729],
                            [113.085895, 26.698383],
                            [113.083545, 26.69919],
                            [113.082205, 26.703979],
                            [113.078341, 26.705703],
                            [113.075557, 26.705881],
                            [113.068978, 26.703746],
                            [113.066298, 26.701749],
                            [113.058954, 26.699409],
                            [113.041637, 26.698793],
                            [113.038557, 26.697151],
                            [113.036608, 26.692977],
                            [113.028759, 26.688475],
                            [113.021275, 26.683234],
                            [113.017568, 26.682344],
                            [113.015915, 26.68452],
                            [113.012521, 26.686203],
                            [113.007091, 26.687873],
                            [113.000165, 26.694264],
                            [112.997955, 26.698232],
                            [112.997798, 26.702761],
                            [112.990889, 26.703131],
                            [112.984362, 26.704362],
                            [112.980899, 26.702939],
                            [112.976687, 26.697972],
                            [112.97265, 26.697808],
                            [112.965166, 26.699217],
                            [112.956882, 26.702583],
                            [112.948059, 26.703473],
                            [112.94517, 26.702925],
                            [112.944526, 26.701023],
                            [112.955072, 26.692895],
                            [112.956012, 26.69061],
                            [112.955229, 26.688722],
                            [112.949207, 26.686847],
                            [112.947589, 26.685314],
                            [112.947188, 26.682673],
                            [112.948581, 26.679744],
                            [112.954376, 26.676911],
                            [112.958048, 26.672258],
                            [112.958744, 26.670191],
                            [112.963391, 26.668507],
                            [112.964592, 26.665811],
                            [112.965706, 26.660021],
                            [112.967672, 26.658009],
                            [112.970422, 26.65805],
                            [112.977453, 26.656585],
                            [112.983423, 26.651411],
                            [112.984119, 26.649399],
                            [112.982883, 26.646537],
                            [112.984171, 26.639377],
                            [112.983075, 26.631642],
                            [112.983753, 26.629451],
                            [112.981926, 26.624769],
                            [112.982222, 26.619374],
                            [112.979681, 26.613883],
                            [112.975939, 26.609898],
                            [112.969952, 26.607159],
                            [112.967829, 26.60557],
                            [112.966593, 26.601612],
                            [112.955908, 26.601722],
                            [112.953871, 26.602941],
                            [112.946144, 26.604571],
                            [112.940384, 26.609295],
                            [112.935041, 26.612349],
                            [112.931873, 26.618538],
                            [112.92841, 26.619716],
                            [112.925939, 26.617101],
                            [112.924425, 26.613883],
                            [112.92258, 26.613349],
                            [112.916523, 26.614526],
                            [112.911006, 26.611294],
                            [112.903732, 26.603543],
                            [112.897484, 26.601243],
                            [112.894108, 26.60138],
                            [112.884188, 26.604283],
                            [112.877888, 26.605214],
                            [112.876426, 26.606652],
                            [112.876826, 26.60909],
                            [112.879402, 26.611719],
                            [112.882151, 26.616772],
                            [112.882621, 26.61973],
                            [112.882134, 26.630218],
                            [112.882743, 26.651055],
                            [112.88149, 26.656421],
                            [112.878566, 26.659419],
                            [112.872284, 26.662896],
                            [112.859788, 26.667714],
                            [112.854793, 26.668822],
                            [112.848423, 26.668809],
                            [112.845761, 26.667919],
                            [112.840748, 26.664456],
                            [112.833804, 26.664908],
                            [112.831925, 26.666933],
                            [112.833039, 26.6726],
                            [112.835945, 26.67791],
                            [112.836154, 26.682372],
                            [112.833509, 26.684],
                            [112.829593, 26.681044],
                            [112.826721, 26.680264],
                            [112.822423, 26.676514],
                            [112.817776, 26.67546],
                            [112.814313, 26.672846],
                            [112.813146, 26.669821],
                            [112.813042, 26.663347],
                            [112.811737, 26.660035],
                            [112.8093, 26.657872],
                            [112.806498, 26.657092],
                            [112.800651, 26.658556],
                            [112.798162, 26.657899],
                            [112.797309, 26.654806],
                            [112.798754, 26.651589],
                            [112.798075, 26.646428],
                            [112.793985, 26.64176],
                            [112.795934, 26.636817],
                            [112.793585, 26.6303],
                            [112.786328, 26.620894],
                            [112.77244, 26.611294],
                            [112.771848, 26.60883],
                            [112.773414, 26.604064],
                            [112.772544, 26.596792],
                            [112.769394, 26.592738],
                            [112.765374, 26.591395],
                            [112.756811, 26.590464],
                            [112.748771, 26.587519],
                            [112.744925, 26.584273],
                            [112.744455, 26.581465],
                            [112.745238, 26.576534],
                            [112.744037, 26.574191],
                            [112.740574, 26.575575],
                            [112.736501, 26.574794],
                            [112.72954, 26.572219],
                            [112.723292, 26.568726],
                            [112.719881, 26.569178],
                            [112.719446, 26.576548],
                            [112.716139, 26.579547],
                            [112.709996, 26.578767],
                            [112.706828, 26.58126],
                            [112.707768, 26.587738],
                            [112.706237, 26.589916],
                            [112.703313, 26.591368],
                            [112.695638, 26.591382],
                            [112.681819, 26.587985],
                            [112.677712, 26.585588],
                            [112.676424, 26.583533],
                            [112.676598, 26.578136],
                            [112.675485, 26.576027],
                            [112.668906, 26.576095],
                            [112.662171, 26.575548],
                            [112.660204, 26.571164],
                            [112.65742, 26.569315],
                            [112.653208, 26.568178],
                            [112.649049, 26.568],
                            [112.645028, 26.566027],
                            [112.640886, 26.562273],
                            [112.640329, 26.557588],
                            [112.642383, 26.556437],
                            [112.644367, 26.557053],
                            [112.649536, 26.56041],
                            [112.657977, 26.56241],
                            [112.660065, 26.562438],
                            [112.672474, 26.560506],
                            [112.675885, 26.559615],
                            [112.678095, 26.556012],
                            [112.67773, 26.545997],
                            [112.674962, 26.532568],
                            [112.673013, 26.527511],
                            [112.674493, 26.517795],
                            [112.676111, 26.51223],
                            [112.675815, 26.51001],
                            [112.6727, 26.504349],
                            [112.670298, 26.497619],
                            [112.667409, 26.492218],
                            [112.665321, 26.48694],
                            [112.658325, 26.480291],
                            [112.657594, 26.476288],
                            [112.659317, 26.474177],
                            [112.669376, 26.469104],
                            [112.671377, 26.466965],
                            [112.673431, 26.46185],
                            [112.669045, 26.456324],
                            [112.666644, 26.446999],
                            [112.665773, 26.439265],
                            [112.666713, 26.433079],
                            [112.665913, 26.43035],
                            [112.663232, 26.428498],
                            [112.657176, 26.426304],
                            [112.654844, 26.424315],
                            [112.647186, 26.415248],
                            [112.645359, 26.411613],
                            [112.637771, 26.403835],
                            [112.638345, 26.400295],
                            [112.641896, 26.392461],
                            [112.641043, 26.387522],
                            [112.641722, 26.383776],
                            [112.640747, 26.380455],
                            [112.633403, 26.375941],
                            [112.631036, 26.372195],
                            [112.631245, 26.369533],
                            [112.634482, 26.363824],
                            [112.635456, 26.358032],
                            [112.63838, 26.354848],
                            [112.640573, 26.35128],
                            [112.642662, 26.34358],
                            [112.640503, 26.339078],
                            [112.635195, 26.33544],
                            [112.633908, 26.330526],
                            [112.63208, 26.327835],
                            [112.627799, 26.316797],
                            [112.627642, 26.314477],
                            [112.629122, 26.309425],
                            [112.635021, 26.305306],
                            [112.639007, 26.298111],
                            [112.644384, 26.295982],
                            [112.653034, 26.295666],
                            [112.655836, 26.29395],
                            [112.656497, 26.291135],
                            [112.654061, 26.288292],
                            [112.64797, 26.284708],
                            [112.645429, 26.281714],
                            [112.645168, 26.278405],
                            [112.646299, 26.275452],
                            [112.649414, 26.272472],
                            [112.651694, 26.268063],
                            [112.65474, 26.256964],
                            [112.658133, 26.247459],
                            [112.658238, 26.243351],
                            [112.656671, 26.240246],
                            [112.652268, 26.234614],
                            [112.652059, 26.231756],
                            [112.653452, 26.229296],
                            [112.655888, 26.227689],
                            [112.65742, 26.221712],
                            [112.654165, 26.210334],
                            [112.654879, 26.204041],
                            [112.65909, 26.199409],
                            [112.662449, 26.196963],
                            [112.666661, 26.195218],
                            [112.671221, 26.191617],
                            [112.675154, 26.184085],
                            [112.678321, 26.173501],
                            [112.681593, 26.166848],
                            [112.682724, 26.162999],
                            [112.685666, 26.158146],
                            [112.688502, 26.155107],
                            [112.690643, 26.149168],
                            [112.692679, 26.145841],
                            [112.692401, 26.14206],
                            [112.689216, 26.137522],
                            [112.689564, 26.130674],
                            [112.688885, 26.127869],
                            [112.686414, 26.123826]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430482,
                "name": "常宁市",
                "center": [112.396821, 26.406773],
                "centroid": [112.430033, 26.359289],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430400
                },
                "subFeatureIndex": 11,
                "acroutes": [100000, 430000, 430400]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.686414, 26.123826],
                            [112.688885, 26.127869],
                            [112.689564, 26.130674],
                            [112.689216, 26.137522],
                            [112.692401, 26.14206],
                            [112.692679, 26.145841],
                            [112.690643, 26.149168],
                            [112.688502, 26.155107],
                            [112.685666, 26.158146],
                            [112.682724, 26.162999],
                            [112.681593, 26.166848],
                            [112.678321, 26.173501],
                            [112.675154, 26.184085],
                            [112.671221, 26.191617],
                            [112.666661, 26.195218],
                            [112.662449, 26.196963],
                            [112.65909, 26.199409],
                            [112.654879, 26.204041],
                            [112.654165, 26.210334],
                            [112.65742, 26.221712],
                            [112.655888, 26.227689],
                            [112.653452, 26.229296],
                            [112.652059, 26.231756],
                            [112.652268, 26.234614],
                            [112.656671, 26.240246],
                            [112.658238, 26.243351],
                            [112.658133, 26.247459],
                            [112.65474, 26.256964],
                            [112.651694, 26.268063],
                            [112.649414, 26.272472],
                            [112.646299, 26.275452],
                            [112.645168, 26.278405],
                            [112.645429, 26.281714],
                            [112.64797, 26.284708],
                            [112.654061, 26.288292],
                            [112.656497, 26.291135],
                            [112.655836, 26.29395],
                            [112.653034, 26.295666],
                            [112.644384, 26.295982],
                            [112.639007, 26.298111],
                            [112.635021, 26.305306],
                            [112.629122, 26.309425],
                            [112.627642, 26.314477],
                            [112.627799, 26.316797],
                            [112.63208, 26.327835],
                            [112.633908, 26.330526],
                            [112.635195, 26.33544],
                            [112.640503, 26.339078],
                            [112.642662, 26.34358],
                            [112.640573, 26.35128],
                            [112.63838, 26.354848],
                            [112.635456, 26.358032],
                            [112.634482, 26.363824],
                            [112.631245, 26.369533],
                            [112.631036, 26.372195],
                            [112.633403, 26.375941],
                            [112.640747, 26.380455],
                            [112.641722, 26.383776],
                            [112.641043, 26.387522],
                            [112.641896, 26.392461],
                            [112.638345, 26.400295],
                            [112.637771, 26.403835],
                            [112.645359, 26.411613],
                            [112.647186, 26.415248],
                            [112.654844, 26.424315],
                            [112.657176, 26.426304],
                            [112.663232, 26.428498],
                            [112.665913, 26.43035],
                            [112.666713, 26.433079],
                            [112.665773, 26.439265],
                            [112.666644, 26.446999],
                            [112.669045, 26.456324],
                            [112.673431, 26.46185],
                            [112.671377, 26.466965],
                            [112.669376, 26.469104],
                            [112.659317, 26.474177],
                            [112.657594, 26.476288],
                            [112.658325, 26.480291],
                            [112.665321, 26.48694],
                            [112.667409, 26.492218],
                            [112.670298, 26.497619],
                            [112.6727, 26.504349],
                            [112.675815, 26.51001],
                            [112.676111, 26.51223],
                            [112.674493, 26.517795],
                            [112.673013, 26.527511],
                            [112.674962, 26.532568],
                            [112.67773, 26.545997],
                            [112.678095, 26.556012],
                            [112.675885, 26.559615],
                            [112.672474, 26.560506],
                            [112.660065, 26.562438],
                            [112.657977, 26.56241],
                            [112.649536, 26.56041],
                            [112.644367, 26.557053],
                            [112.642383, 26.556437],
                            [112.640329, 26.557588],
                            [112.640886, 26.562273],
                            [112.645028, 26.566027],
                            [112.649049, 26.568],
                            [112.653208, 26.568178],
                            [112.65742, 26.569315],
                            [112.660204, 26.571164],
                            [112.662171, 26.575548],
                            [112.665547, 26.58367],
                            [112.668401, 26.593409],
                            [112.667183, 26.597737],
                            [112.662589, 26.600763],
                            [112.657663, 26.601517],
                            [112.651903, 26.599599],
                            [112.645933, 26.595614],
                            [112.637649, 26.587588],
                            [112.634708, 26.5853],
                            [112.630862, 26.584752],
                            [112.627625, 26.587149],
                            [112.624718, 26.592368],
                            [112.622386, 26.59875],
                            [112.620159, 26.599791],
                            [112.613058, 26.599079],
                            [112.607628, 26.597887],
                            [112.595132, 26.596394],
                            [112.578408, 26.594011],
                            [112.567252, 26.590505],
                            [112.562344, 26.587615],
                            [112.558011, 26.585752],
                            [112.552389, 26.584287],
                            [112.546716, 26.579451],
                            [112.541147, 26.572315],
                            [112.537631, 26.566849],
                            [112.531784, 26.565561],
                            [112.526563, 26.566616],
                            [112.522264, 26.568315],
                            [112.511143, 26.574233],
                            [112.507192, 26.578534],
                            [112.502267, 26.581602],
                            [112.497899, 26.582451],
                            [112.494349, 26.577452],
                            [112.492904, 26.573534],
                            [112.492939, 26.567945],
                            [112.494088, 26.563945],
                            [112.493148, 26.560013],
                            [112.490972, 26.557053],
                            [112.485351, 26.552354],
                            [112.481696, 26.550847],
                            [112.470541, 26.550299],
                            [112.464293, 26.549573],
                            [112.451031, 26.554094],
                            [112.445984, 26.557259],
                            [112.441686, 26.563999],
                            [112.438118, 26.57152],
                            [112.435194, 26.580383],
                            [112.431035, 26.586821],
                            [112.428006, 26.589245],
                            [112.425204, 26.590272],
                            [112.418104, 26.587697],
                            [112.415667, 26.584972],
                            [112.401762, 26.567342],
                            [112.396123, 26.56078],
                            [112.392712, 26.554094],
                            [112.391389, 26.546901],
                            [112.390015, 26.544613],
                            [112.387891, 26.543749],
                            [112.384028, 26.544572],
                            [112.381, 26.546983],
                            [112.376301, 26.553738],
                            [112.373081, 26.557355],
                            [112.362935, 26.563602],
                            [112.355242, 26.566397],
                            [112.346593, 26.567684],
                            [112.339788, 26.567616],
                            [112.33347, 26.566287],
                            [112.326962, 26.564232],
                            [112.319582, 26.562438],
                            [112.314205, 26.559848],
                            [112.311142, 26.557314],
                            [112.30914, 26.553368],
                            [112.308514, 26.550422],
                            [112.309193, 26.545325],
                            [112.318155, 26.524154],
                            [112.320157, 26.518055],
                            [112.32221, 26.509873],
                            [112.32228, 26.504514],
                            [112.315527, 26.492794],
                            [112.30606, 26.48583],
                            [112.28763, 26.476795],
                            [112.277692, 26.47053],
                            [112.264901, 26.465265],
                            [112.256181, 26.463592],
                            [112.247358, 26.463907],
                            [112.233644, 26.466732],
                            [112.221635, 26.47186],
                            [112.213003, 26.472257],
                            [112.206077, 26.470255],
                            [112.20049, 26.471654],
                            [112.19607, 26.475986],
                            [112.194573, 26.479606],
                            [112.191997, 26.484048],
                            [112.191875, 26.488859],
                            [112.187263, 26.49071],
                            [112.185453, 26.492931],
                            [112.181294, 26.495577],
                            [112.178962, 26.49825],
                            [112.17569, 26.500169],
                            [112.171235, 26.496454],
                            [112.168067, 26.495686],
                            [112.16356, 26.495755],
                            [112.160027, 26.49389],
                            [112.158913, 26.49045],
                            [112.159557, 26.486323],
                            [112.16617, 26.481059],
                            [112.166484, 26.477056],
                            [112.159087, 26.474807],
                            [112.156825, 26.4726],
                            [112.152161, 26.469693],
                            [112.151743, 26.467911],
                            [112.155763, 26.467376],
                            [112.157956, 26.46499],
                            [112.157416, 26.458573],
                            [112.162585, 26.456503],
                            [112.163125, 26.454898],
                            [112.161907, 26.450716],
                            [112.155276, 26.444517],
                            [112.148401, 26.444339],
                            [112.145686, 26.443571],
                            [112.143233, 26.441473],
                            [112.142954, 26.43803],
                            [112.146487, 26.435548],
                            [112.145582, 26.431708],
                            [112.152735, 26.430048],
                            [112.15364, 26.428169],
                            [112.152509, 26.423176],
                            [112.154649, 26.42068],
                            [112.154423, 26.418842],
                            [112.150716, 26.417731],
                            [112.150107, 26.416304],
                            [112.152526, 26.411215],
                            [112.150629, 26.408705],
                            [112.153396, 26.404973],
                            [112.153257, 26.403135],
                            [112.14962, 26.401736],
                            [112.146191, 26.404301],
                            [112.143581, 26.407662],
                            [112.140605, 26.40795],
                            [112.141283, 26.404301],
                            [112.14151, 26.395713],
                            [112.140587, 26.389621],
                            [112.141736, 26.387083],
                            [112.145704, 26.386342],
                            [112.150142, 26.384613],
                            [112.151987, 26.382061],
                            [112.152039, 26.379948],
                            [112.148697, 26.373924],
                            [112.149915, 26.370534],
                            [112.149846, 26.3623],
                            [112.148193, 26.35736],
                            [112.150646, 26.354231],
                            [112.153187, 26.349564],
                            [112.155606, 26.349894],
                            [112.158756, 26.353037],
                            [112.162115, 26.353352],
                            [112.167632, 26.351184],
                            [112.167667, 26.347245],
                            [112.169216, 26.346737],
                            [112.171896, 26.348672],
                            [112.177309, 26.348164],
                            [112.181485, 26.34336],
                            [112.182008, 26.341027],
                            [112.181172, 26.334081],
                            [112.17743, 26.324431],
                            [112.1775, 26.314862],
                            [112.177953, 26.305759],
                            [112.179449, 26.303479],
                            [112.181207, 26.304358],
                            [112.185001, 26.310345],
                            [112.190413, 26.312226],
                            [112.193999, 26.311059],
                            [112.195687, 26.307928],
                            [112.198993, 26.307448],
                            [112.200995, 26.309988],
                            [112.2019, 26.313077],
                            [112.204928, 26.314711],
                            [112.208948, 26.313557],
                            [112.211785, 26.310084],
                            [112.212481, 26.305621],
                            [112.210323, 26.300912],
                            [112.209714, 26.294197],
                            [112.205015, 26.285271],
                            [112.197323, 26.277306],
                            [112.197149, 26.275575],
                            [112.199202, 26.265687],
                            [112.200838, 26.262376],
                            [112.198384, 26.25555],
                            [112.198175, 26.23934],
                            [112.196017, 26.229805],
                            [112.190709, 26.224886],
                            [112.190274, 26.223677],
                            [112.19144, 26.21344],
                            [112.194886, 26.206885],
                            [112.195269, 26.202364],
                            [112.194764, 26.197898],
                            [112.192867, 26.193926],
                            [112.189596, 26.190806],
                            [112.186515, 26.18928],
                            [112.185941, 26.185666],
                            [112.190396, 26.180044],
                            [112.192606, 26.175714],
                            [112.195443, 26.172016],
                            [112.200125, 26.168786],
                            [112.206129, 26.165528],
                            [112.20954, 26.165665],
                            [112.213699, 26.167782],
                            [112.215736, 26.167906],
                            [112.217215, 26.165459],
                            [112.213386, 26.158489],
                            [112.211994, 26.154351],
                            [112.212568, 26.151175],
                            [112.217876, 26.149594],
                            [112.221722, 26.151382],
                            [112.223132, 26.14925],
                            [112.224333, 26.138911],
                            [112.22844, 26.134703],
                            [112.231364, 26.134442],
                            [112.232373, 26.131087],
                            [112.233122, 26.122863],
                            [112.232426, 26.117858],
                            [112.235628, 26.119824],
                            [112.243007, 26.122135],
                            [112.247741, 26.127539],
                            [112.249672, 26.126645],
                            [112.249829, 26.122836],
                            [112.257713, 26.12329],
                            [112.257243, 26.128694],
                            [112.258496, 26.130743],
                            [112.266954, 26.131156],
                            [112.27181, 26.134249],
                            [112.276457, 26.138595],
                            [112.281991, 26.142266],
                            [112.286516, 26.144301],
                            [112.290693, 26.14404],
                            [112.29158, 26.142912],
                            [112.290919, 26.13634],
                            [112.292398, 26.134401],
                            [112.295757, 26.138003],
                            [112.296766, 26.134662],
                            [112.299812, 26.132338],
                            [112.30425, 26.132902],
                            [112.306826, 26.136573],
                            [112.308705, 26.142637],
                            [112.311855, 26.148975],
                            [112.309541, 26.15541],
                            [112.310411, 26.158572],
                            [112.315632, 26.161885],
                            [112.319756, 26.161665],
                            [112.323255, 26.160345],
                            [112.327049, 26.161115],
                            [112.329102, 26.164882],
                            [112.335246, 26.167013],
                            [112.340084, 26.167301],
                            [112.34353, 26.165913],
                            [112.350857, 26.161706],
                            [112.354355, 26.160716],
                            [112.357017, 26.161321],
                            [112.360272, 26.16605],
                            [112.361734, 26.176896],
                            [112.364344, 26.181281],
                            [112.368678, 26.185391],
                            [112.375048, 26.190559],
                            [112.37604, 26.197499],
                            [112.377275, 26.201526],
                            [112.381278, 26.206432],
                            [112.384828, 26.206583],
                            [112.386638, 26.205236],
                            [112.389771, 26.198915],
                            [112.39132, 26.190394],
                            [112.392695, 26.187892],
                            [112.395062, 26.186202],
                            [112.400474, 26.186243],
                            [112.402597, 26.184951],
                            [112.405765, 26.184813],
                            [112.410742, 26.187672],
                            [112.413283, 26.18803],
                            [112.415911, 26.186174],
                            [112.418904, 26.186476],
                            [112.425848, 26.189693],
                            [112.429259, 26.18972],
                            [112.4306, 26.184071],
                            [112.4369, 26.183205],
                            [112.440346, 26.180882],
                            [112.444592, 26.175934],
                            [112.450944, 26.180388],
                            [112.456705, 26.180319],
                            [112.462135, 26.177487],
                            [112.464554, 26.177047],
                            [112.471829, 26.178312],
                            [112.476005, 26.177762],
                            [112.479521, 26.175783],
                            [112.482984, 26.171851],
                            [112.485055, 26.167961],
                            [112.484829, 26.165968],
                            [112.482027, 26.16073],
                            [112.482584, 26.156785],
                            [112.48415, 26.153361],
                            [112.487091, 26.151148],
                            [112.490624, 26.151395],
                            [112.494209, 26.153251],
                            [112.498404, 26.157513],
                            [112.501571, 26.157472],
                            [112.505887, 26.159905],
                            [112.507784, 26.159603],
                            [112.50989, 26.150144],
                            [112.507923, 26.145469],
                            [112.508254, 26.143875],
                            [112.512901, 26.135556],
                            [112.518609, 26.136037],
                            [112.524439, 26.137481],
                            [112.526528, 26.1392],
                            [112.528199, 26.143366],
                            [112.527885, 26.145662],
                            [112.529104, 26.147601],
                            [112.532845, 26.147917],
                            [112.538293, 26.146734],
                            [112.548561, 26.14914],
                            [112.550841, 26.148838],
                            [112.552424, 26.146996],
                            [112.554826, 26.147271],
                            [112.558829, 26.151601],
                            [112.566539, 26.158489],
                            [112.569271, 26.159795],
                            [112.572438, 26.160029],
                            [112.573483, 26.15574],
                            [112.57839, 26.15365],
                            [112.582062, 26.156826],
                            [112.582028, 26.159245],
                            [112.576232, 26.168346],
                            [112.576319, 26.177405],
                            [112.577729, 26.17981],
                            [112.577433, 26.181982],
                            [112.574074, 26.186641],
                            [112.573848, 26.191397],
                            [112.572334, 26.193404],
                            [112.572595, 26.195699],
                            [112.577833, 26.203477],
                            [112.580566, 26.208988],
                            [112.580131, 26.213014],
                            [112.581053, 26.219088],
                            [112.588067, 26.221066],
                            [112.597813, 26.220517],
                            [112.602842, 26.217095],
                            [112.604948, 26.213165],
                            [112.605244, 26.208493],
                            [112.608968, 26.198447],
                            [112.613737, 26.194489],
                            [112.616608, 26.193266],
                            [112.623413, 26.192112],
                            [112.625328, 26.189638],
                            [112.627573, 26.181391],
                            [112.628182, 26.176855],
                            [112.63161, 26.17302],
                            [112.631593, 26.170724],
                            [112.633664, 26.16326],
                            [112.630131, 26.154406],
                            [112.63067, 26.150048],
                            [112.634273, 26.146143],
                            [112.637301, 26.138911],
                            [112.639651, 26.13788],
                            [112.642105, 26.134057],
                            [112.646856, 26.133163],
                            [112.649466, 26.130922],
                            [112.654235, 26.129258],
                            [112.658708, 26.129725],
                            [112.663128, 26.128708],
                            [112.664799, 26.131224],
                            [112.667131, 26.128529],
                            [112.670351, 26.129547],
                            [112.670664, 26.12769],
                            [112.668071, 26.124665],
                            [112.658203, 26.114983],
                            [112.65467, 26.113622],
                            [112.646194, 26.112439],
                            [112.642627, 26.109798],
                            [112.642296, 26.106002],
                            [112.647186, 26.10354],
                            [112.654914, 26.101051],
                            [112.662136, 26.104022],
                            [112.665321, 26.10984],
                            [112.668158, 26.110692],
                            [112.671969, 26.11028],
                            [112.673814, 26.111022],
                            [112.676442, 26.117734],
                            [112.676129, 26.125985],
                            [112.677051, 26.127264],
                            [112.681872, 26.127195],
                            [112.686414, 26.123826]
                        ]
                    ]
                ]
            }
        }]
    });
}));