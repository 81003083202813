/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

(function(root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['exports', 'echarts'], factory);
    } else if (typeof exports === 'object' && typeof exports.nodeName !== 'string') {
        // CommonJS
        factory(exports, require('echarts'));
    } else {
        // Browser globals
        factory({}, root.echarts);
    }
}(this, function(exports, echarts) {
    var log = function(msg) {
        if (typeof console !== 'undefined') {
            console && console.error && console.error(msg);
        }
    }
    if (!echarts) {
        log('ECharts is not Loaded');
        return;
    }
    if (!echarts.registerMap) {
        log('ECharts Map is not loaded')
        return;
    }
    echarts.registerMap('岳阳市', {
        "type": "FeatureCollection",
        "features": [{
            "type": "Feature",
            "properties": {
                "adcode": 430602,
                "name": "岳阳楼区",
                "center": [113.120751, 29.366784],
                "centroid": [113.214548, 29.33568],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430600
                },
                "subFeatureIndex": 0,
                "acroutes": [100000, 430000, 430600]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [113.153369, 29.455378],
                            [113.148728, 29.451865],
                            [113.15022, 29.444155],
                            [113.14952, 29.44077],
                            [113.148636, 29.428008],
                            [113.146169, 29.424722],
                            [113.138766, 29.419415],
                            [113.126521, 29.407946],
                            [113.122028, 29.404274],
                            [113.113005, 29.399393],
                            [113.103227, 29.395878],
                            [113.098384, 29.393515],
                            [113.095456, 29.391309],
                            [113.093504, 29.386385],
                            [113.093302, 29.376606],
                            [113.090871, 29.371211],
                            [113.088974, 29.362669],
                            [113.086415, 29.355465],
                            [113.078257, 29.347478],
                            [113.075201, 29.341796],
                            [113.07474, 29.338535],
                            [113.076379, 29.334192],
                            [113.072733, 29.324949],
                            [113.071849, 29.321104],
                            [113.073912, 29.318156],
                            [113.080854, 29.312986],
                            [113.083045, 29.309738],
                            [113.085954, 29.301334],
                            [113.087317, 29.29367],
                            [113.089766, 29.291062],
                            [113.101201, 29.290379],
                            [113.103466, 29.294809],
                            [113.106523, 29.29696],
                            [113.109856, 29.29669],
                            [113.117074, 29.286646],
                            [113.117774, 29.284366],
                            [113.11619, 29.280206],
                            [113.111016, 29.280291],
                            [113.110224, 29.278482],
                            [113.113815, 29.273694],
                            [113.119192, 29.271928],
                            [113.122028, 29.267695],
                            [113.120223, 29.265016],
                            [113.120665, 29.262594],
                            [113.125213, 29.260613],
                            [113.129393, 29.25296],
                            [113.133942, 29.243097],
                            [113.137717, 29.240175],
                            [113.148047, 29.24381],
                            [113.152374, 29.242784],
                            [113.154382, 29.239676],
                            [113.158875, 29.239292],
                            [113.162336, 29.237838],
                            [113.164141, 29.240161],
                            [113.16427, 29.242427],
                            [113.162557, 29.248171],
                            [113.162723, 29.255896],
                            [113.160698, 29.25849],
                            [113.15602, 29.261953],
                            [113.153792, 29.267596],
                            [113.154584, 29.269477],
                            [113.159593, 29.273196],
                            [113.159298, 29.275034],
                            [113.155578, 29.276786],
                            [113.155321, 29.280234],
                            [113.158948, 29.282443],
                            [113.162668, 29.281417],
                            [113.168745, 29.276487],
                            [113.174195, 29.278582],
                            [113.176957, 29.27871],
                            [113.178007, 29.281474],
                            [113.177178, 29.285734],
                            [113.183844, 29.289652],
                            [113.189626, 29.290934],
                            [113.193107, 29.289695],
                            [113.19248, 29.284295],
                            [113.194856, 29.282201],
                            [113.194156, 29.280591],
                            [113.190639, 29.279408],
                            [113.191652, 29.276102],
                            [113.194506, 29.27378],
                            [113.195298, 29.271486],
                            [113.199257, 29.269448],
                            [113.202848, 29.269448],
                            [113.211484, 29.271742],
                            [113.217505, 29.272811],
                            [113.220415, 29.272683],
                            [113.230929, 29.265031],
                            [113.234704, 29.262622],
                            [113.235975, 29.263107],
                            [113.236122, 29.266256],
                            [113.239437, 29.268465],
                            [113.242604, 29.268408],
                            [113.244519, 29.264503],
                            [113.24903, 29.265088],
                            [113.252879, 29.261539],
                            [113.258238, 29.258219],
                            [113.2603, 29.253359],
                            [113.263283, 29.250352],
                            [113.268108, 29.249753],
                            [113.267979, 29.246404],
                            [113.264314, 29.245235],
                            [113.26715, 29.244223],
                            [113.267942, 29.242741],
                            [113.271477, 29.242114],
                            [113.277223, 29.242983],
                            [113.278604, 29.241102],
                            [113.281274, 29.242655],
                            [113.285564, 29.23912],
                            [113.291199, 29.238394],
                            [113.29339, 29.240717],
                            [113.297312, 29.238907],
                            [113.299228, 29.236811],
                            [113.302837, 29.237595],
                            [113.306593, 29.242955],
                            [113.310387, 29.24314],
                            [113.312302, 29.237567],
                            [113.316021, 29.236184],
                            [113.322006, 29.241743],
                            [113.325284, 29.243126],
                            [113.328819, 29.243111],
                            [113.333073, 29.246432],
                            [113.334436, 29.25068],
                            [113.333367, 29.254884],
                            [113.331526, 29.256708],
                            [113.333828, 29.260214],
                            [113.338744, 29.262451],
                            [113.342151, 29.270631],
                            [113.344582, 29.271913],
                            [113.349296, 29.276772],
                            [113.348596, 29.280933],
                            [113.349296, 29.287016],
                            [113.349112, 29.294994],
                            [113.351395, 29.300949],
                            [113.351321, 29.304154],
                            [113.347215, 29.317486],
                            [113.346644, 29.320762],
                            [113.347325, 29.326431],
                            [113.341028, 29.328723],
                            [113.340199, 29.331842],
                            [113.334767, 29.337211],
                            [113.333331, 29.337467],
                            [113.327383, 29.335986],
                            [113.319428, 29.336869],
                            [113.312578, 29.338834],
                            [113.309319, 29.341597],
                            [113.307882, 29.344772],
                            [113.307827, 29.352461],
                            [113.30965, 29.356789],
                            [113.312744, 29.359366],
                            [113.322982, 29.362555],
                            [113.327033, 29.369075],
                            [113.333515, 29.370969],
                            [113.335098, 29.374086],
                            [113.33171, 29.38711],
                            [113.325505, 29.40019],
                            [113.324934, 29.403065],
                            [113.326462, 29.407661],
                            [113.329077, 29.411347],
                            [113.329114, 29.413125],
                            [113.327033, 29.414747],
                            [113.321785, 29.41617],
                            [113.315653, 29.416156],
                            [113.309981, 29.413951],
                            [113.302247, 29.412741],
                            [113.296189, 29.409369],
                            [113.289302, 29.410009],
                            [113.286724, 29.408814],
                            [113.2826, 29.405413],
                            [113.28457, 29.399934],
                            [113.282176, 29.398197],
                            [113.275602, 29.400304],
                            [113.270704, 29.400375],
                            [113.266395, 29.398041],
                            [113.263007, 29.391978],
                            [113.260503, 29.39158],
                            [113.257925, 29.395294],
                            [113.256083, 29.399806],
                            [113.254223, 29.401442],
                            [113.250393, 29.402353],
                            [113.23533, 29.401385],
                            [113.22314, 29.396561],
                            [113.216585, 29.396675],
                            [113.208354, 29.402325],
                            [113.20537, 29.409255],
                            [113.206144, 29.421407],
                            [113.202406, 29.42926],
                            [113.193143, 29.436089],
                            [113.163589, 29.449361],
                            [113.156076, 29.454183],
                            [113.153369, 29.455378]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430603,
                "name": "云溪区",
                "center": [113.27387, 29.473395],
                "centroid": [113.297716, 29.508639],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430600
                },
                "subFeatureIndex": 1,
                "acroutes": [100000, 430000, 430600]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [113.307772, 29.625563],
                            [113.298086, 29.613862],
                            [113.279064, 29.596421],
                            [113.277278, 29.5951],
                            [113.272232, 29.584659],
                            [113.272251, 29.580838],
                            [113.270943, 29.578537],
                            [113.267095, 29.574516],
                            [113.256617, 29.566503],
                            [113.230045, 29.549353],
                            [113.222404, 29.543711],
                            [113.219365, 29.538751],
                            [113.182095, 29.485585],
                            [113.155726, 29.457142],
                            [113.153369, 29.455378],
                            [113.156076, 29.454183],
                            [113.163589, 29.449361],
                            [113.193143, 29.436089],
                            [113.202406, 29.42926],
                            [113.206144, 29.421407],
                            [113.20537, 29.409255],
                            [113.208354, 29.402325],
                            [113.216585, 29.396675],
                            [113.22314, 29.396561],
                            [113.23533, 29.401385],
                            [113.250393, 29.402353],
                            [113.254223, 29.401442],
                            [113.256083, 29.399806],
                            [113.257925, 29.395294],
                            [113.260503, 29.39158],
                            [113.263007, 29.391978],
                            [113.266395, 29.398041],
                            [113.270704, 29.400375],
                            [113.275602, 29.400304],
                            [113.282176, 29.398197],
                            [113.28457, 29.399934],
                            [113.2826, 29.405413],
                            [113.286724, 29.408814],
                            [113.289302, 29.410009],
                            [113.296189, 29.409369],
                            [113.302247, 29.412741],
                            [113.309981, 29.413951],
                            [113.315653, 29.416156],
                            [113.322098, 29.425362],
                            [113.324363, 29.42485],
                            [113.323442, 29.420937],
                            [113.324289, 29.419785],
                            [113.330569, 29.420923],
                            [113.332962, 29.418746],
                            [113.335927, 29.418589],
                            [113.337584, 29.423541],
                            [113.34309, 29.431238],
                            [113.342501, 29.438778],
                            [113.343937, 29.440343],
                            [113.347454, 29.44067],
                            [113.349333, 29.438493],
                            [113.353126, 29.436288],
                            [113.35946, 29.433571],
                            [113.360841, 29.433841],
                            [113.363825, 29.438166],
                            [113.365279, 29.44141],
                            [113.366881, 29.441851],
                            [113.371006, 29.439731],
                            [113.374192, 29.435293],
                            [113.37839, 29.432148],
                            [113.380766, 29.433699],
                            [113.387984, 29.441822],
                            [113.389365, 29.44572],
                            [113.386879, 29.44865],
                            [113.383417, 29.451026],
                            [113.381152, 29.45424],
                            [113.381263, 29.459688],
                            [113.379624, 29.461764],
                            [113.373676, 29.462988],
                            [113.368925, 29.460171],
                            [113.36539, 29.459489],
                            [113.361873, 29.459915],
                            [113.356827, 29.463073],
                            [113.356754, 29.464652],
                            [113.362757, 29.470426],
                            [113.365021, 29.476071],
                            [113.364193, 29.481305],
                            [113.361504, 29.480707],
                            [113.359645, 29.478588],
                            [113.356606, 29.481148],
                            [113.356882, 29.483949],
                            [113.354194, 29.487334],
                            [113.342206, 29.48567],
                            [113.330108, 29.486864],
                            [113.328727, 29.488727],
                            [113.329022, 29.491414],
                            [113.350603, 29.495197],
                            [113.364745, 29.493945],
                            [113.369054, 29.496604],
                            [113.369736, 29.505419],
                            [113.371577, 29.51301],
                            [113.371393, 29.51591],
                            [113.373124, 29.519179],
                            [113.376457, 29.519734],
                            [113.385001, 29.517971],
                            [113.38977, 29.519435],
                            [113.391925, 29.522378],
                            [113.397799, 29.526685],
                            [113.401353, 29.532199],
                            [113.404815, 29.534487],
                            [113.407043, 29.534701],
                            [113.418662, 29.530849],
                            [113.42275, 29.528831],
                            [113.429987, 29.527395],
                            [113.431534, 29.52795],
                            [113.432859, 29.533294],
                            [113.433817, 29.539646],
                            [113.42774, 29.545772],
                            [113.419822, 29.548898],
                            [113.415274, 29.552138],
                            [113.40603, 29.55309],
                            [113.401647, 29.55488],
                            [113.39929, 29.559214],
                            [113.396896, 29.561829],
                            [113.392735, 29.561701],
                            [113.386566, 29.56072],
                            [113.383399, 29.561644],
                            [113.381999, 29.564883],
                            [113.384007, 29.566986],
                            [113.393177, 29.570055],
                            [113.399806, 29.569941],
                            [113.402273, 29.570538],
                            [113.405791, 29.574331],
                            [113.413745, 29.588992],
                            [113.419012, 29.594262],
                            [113.425236, 29.598225],
                            [113.433025, 29.602202],
                            [113.435124, 29.604687],
                            [113.43448, 29.607101],
                            [113.432031, 29.608948],
                            [113.420853, 29.614046],
                            [113.418662, 29.616276],
                            [113.409216, 29.622027],
                            [113.403452, 29.62366],
                            [113.397946, 29.624256],
                            [113.395037, 29.623802],
                            [113.393803, 29.62028],
                            [113.395423, 29.615182],
                            [113.395865, 29.609842],
                            [113.395129, 29.603267],
                            [113.390396, 29.599531],
                            [113.383454, 29.600412],
                            [113.376567, 29.604588],
                            [113.373234, 29.610482],
                            [113.367857, 29.616659],
                            [113.363567, 29.620025],
                            [113.358079, 29.621629],
                            [113.355943, 29.621573],
                            [113.351874, 29.613649],
                            [113.349627, 29.612796],
                            [113.347233, 29.614614],
                            [113.348025, 29.63147],
                            [113.346699, 29.635218],
                            [113.343219, 29.63509],
                            [113.34158, 29.630944],
                            [113.333607, 29.619996],
                            [113.329795, 29.617767],
                            [113.324271, 29.618108],
                            [113.316776, 29.62126],
                            [113.307772, 29.625563]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430611,
                "name": "君山区",
                "center": [113.004082, 29.438062],
                "centroid": [112.866822, 29.476796],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430600
                },
                "subFeatureIndex": 2,
                "acroutes": [100000, 430000, 430600]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [113.140276, 29.45178],
                            [113.132561, 29.457341],
                            [113.129651, 29.458322],
                            [113.127405, 29.457327],
                            [113.118676, 29.45653],
                            [113.113576, 29.456687],
                            [113.108346, 29.460001],
                            [113.105363, 29.460868],
                            [113.099986, 29.459659],
                            [113.102085, 29.450656],
                            [113.102638, 29.443046],
                            [113.100612, 29.43801],
                            [113.096911, 29.433628],
                            [113.090576, 29.430768],
                            [113.085991, 29.431039],
                            [113.080743, 29.434083],
                            [113.078165, 29.438337],
                            [113.075992, 29.450201],
                            [113.074261, 29.471336],
                            [113.072807, 29.482769],
                            [113.069897, 29.494415],
                            [113.064686, 29.512441],
                            [113.061684, 29.518611],
                            [113.057652, 29.522463],
                            [113.052109, 29.524012],
                            [113.044872, 29.52495],
                            [113.034431, 29.5236],
                            [113.018779, 29.516194],
                            [113.00136, 29.504793],
                            [112.990329, 29.497955],
                            [112.982135, 29.48813],
                            [112.97337, 29.472388],
                            [112.96919, 29.46906],
                            [112.964089, 29.468079],
                            [112.955877, 29.469018],
                            [112.95015, 29.4729],
                            [112.946909, 29.48011],
                            [112.9433, 29.493888],
                            [112.937407, 29.527111],
                            [112.927077, 29.556188],
                            [112.923983, 29.571646],
                            [112.914629, 29.600909],
                            [112.911848, 29.60686],
                            [112.911443, 29.611717],
                            [112.912014, 29.616148],
                            [112.915844, 29.621033],
                            [112.907263, 29.626159],
                            [112.900634, 29.631952],
                            [112.897982, 29.633486],
                            [112.890138, 29.640684],
                            [112.884743, 29.639634],
                            [112.880691, 29.640173],
                            [112.875001, 29.637973],
                            [112.868464, 29.638611],
                            [112.863032, 29.636425],
                            [112.856035, 29.630873],
                            [112.849884, 29.62853],
                            [112.848872, 29.627054],
                            [112.846717, 29.618037],
                            [112.844158, 29.612583],
                            [112.839075, 29.607925],
                            [112.835448, 29.606264],
                            [112.825762, 29.603806],
                            [112.822963, 29.60169],
                            [112.81847, 29.599759],
                            [112.813553, 29.601974],
                            [112.810773, 29.607187],
                            [112.808784, 29.605852],
                            [112.806943, 29.602727],
                            [112.804475, 29.600881],
                            [112.80094, 29.600611],
                            [112.795305, 29.591478],
                            [112.791419, 29.587642],
                            [112.787442, 29.585029],
                            [112.780905, 29.583012],
                            [112.777314, 29.583537],
                            [112.77306, 29.587216],
                            [112.769709, 29.584191],
                            [112.767555, 29.574644],
                            [112.764148, 29.568919],
                            [112.761459, 29.562937],
                            [112.751645, 29.560806],
                            [112.748238, 29.558163],
                            [112.74682, 29.553658],
                            [112.744095, 29.548429],
                            [112.743027, 29.544947],
                            [112.743229, 29.541323],
                            [112.745439, 29.537614],
                            [112.751, 29.536733],
                            [112.755806, 29.533549],
                            [112.758071, 29.531062],
                            [112.760023, 29.527168],
                            [112.759968, 29.524467],
                            [112.75402, 29.52161],
                            [112.733525, 29.515782],
                            [112.694708, 29.506016],
                            [112.689036, 29.490604],
                            [112.685188, 29.485272],
                            [112.683181, 29.479712],
                            [112.680419, 29.476128],
                            [112.672095, 29.474123],
                            [112.668228, 29.471236],
                            [112.666313, 29.468164],
                            [112.666479, 29.464083],
                            [112.669204, 29.458948],
                            [112.669131, 29.454653],
                            [112.667381, 29.451381],
                            [112.66519, 29.45067],
                            [112.659279, 29.451808],
                            [112.655836, 29.456886],
                            [112.652337, 29.457754],
                            [112.64777, 29.460442],
                            [112.646702, 29.464054],
                            [112.641086, 29.465804],
                            [112.636906, 29.46559],
                            [112.631492, 29.466799],
                            [112.629595, 29.466614],
                            [112.632523, 29.462888],
                            [112.632486, 29.453999],
                            [112.630093, 29.450955],
                            [112.632045, 29.446687],
                            [112.632892, 29.442519],
                            [112.634715, 29.440058],
                            [112.639613, 29.437498],
                            [112.645119, 29.43037],
                            [112.646408, 29.428094],
                            [112.65079, 29.424338],
                            [112.656591, 29.41728],
                            [112.66287, 29.415174],
                            [112.668486, 29.414918],
                            [112.675926, 29.412784],
                            [112.679627, 29.406252],
                            [112.685206, 29.400816],
                            [112.688576, 29.400033],
                            [112.695445, 29.400859],
                            [112.698391, 29.400603],
                            [112.70316, 29.397144],
                            [112.704283, 29.3938],
                            [112.703621, 29.389003],
                            [112.704357, 29.388107],
                            [112.709771, 29.387452],
                            [112.716363, 29.382058],
                            [112.71907, 29.377588],
                            [112.719549, 29.374072],
                            [112.718462, 29.370328],
                            [112.715811, 29.365659],
                            [112.715037, 29.361573],
                            [112.716805, 29.355152],
                            [112.722016, 29.349457],
                            [112.725994, 29.346025],
                            [112.728038, 29.342323],
                            [112.727909, 29.335374],
                            [112.723821, 29.327855],
                            [112.721114, 29.320121],
                            [112.722882, 29.314182],
                            [112.724963, 29.313128],
                            [112.735201, 29.314011],
                            [112.75065, 29.31723],
                            [112.755438, 29.317871],
                            [112.759636, 29.321887],
                            [112.767057, 29.332626],
                            [112.779395, 29.345427],
                            [112.780868, 29.350112],
                            [112.781476, 29.35827],
                            [112.778787, 29.364862],
                            [112.776522, 29.368364],
                            [112.776117, 29.372051],
                            [112.777093, 29.374072],
                            [112.781568, 29.376264],
                            [112.790996, 29.37615],
                            [112.793684, 29.376862],
                            [112.796649, 29.379695],
                            [112.809521, 29.416412],
                            [112.809207, 29.430313],
                            [112.807329, 29.431067],
                            [112.806077, 29.43562],
                            [112.803646, 29.439774],
                            [112.800129, 29.440613],
                            [112.798638, 29.442747],
                            [112.79687, 29.451253],
                            [112.793961, 29.452306],
                            [112.78886, 29.451154],
                            [112.781899, 29.446118],
                            [112.779855, 29.446033],
                            [112.775804, 29.44811],
                            [112.772692, 29.451609],
                            [112.773005, 29.455549],
                            [112.776799, 29.460812],
                            [112.776614, 29.464865],
                            [112.777222, 29.470355],
                            [112.782617, 29.491557],
                            [112.78433, 29.49413],
                            [112.788197, 29.49713],
                            [112.800958, 29.505006],
                            [112.809171, 29.50903],
                            [112.817494, 29.510892],
                            [112.82427, 29.509812],
                            [112.831397, 29.503571],
                            [112.837436, 29.496817],
                            [112.848503, 29.488912],
                            [112.856035, 29.481589],
                            [112.864689, 29.477351],
                            [112.870932, 29.474763],
                            [112.881391, 29.471706],
                            [112.887689, 29.470468],
                            [112.89546, 29.467852],
                            [112.903414, 29.461807],
                            [112.938715, 29.441395],
                            [112.952765, 29.43286],
                            [112.957571, 29.427866],
                            [112.965157, 29.419144],
                            [112.974401, 29.410607],
                            [112.992466, 29.395607],
                            [112.995909, 29.391864],
                            [112.997014, 29.388363],
                            [112.997529, 29.378698],
                            [113.000936, 29.372407],
                            [113.001875, 29.360277],
                            [113.001728, 29.352062],
                            [113.003091, 29.349984],
                            [113.005429, 29.349129],
                            [113.011303, 29.349072],
                            [113.018614, 29.349998],
                            [113.025022, 29.353216],
                            [113.026532, 29.35854],
                            [113.024948, 29.372734],
                            [113.024782, 29.376834],
                            [113.025556, 29.379908],
                            [113.029091, 29.384178],
                            [113.045185, 29.388705],
                            [113.050525, 29.388263],
                            [113.058462, 29.386143],
                            [113.063784, 29.387523],
                            [113.075808, 29.394269],
                            [113.089858, 29.402951],
                            [113.095309, 29.404388],
                            [113.099544, 29.404075],
                            [113.10365, 29.405996],
                            [113.105989, 29.41045],
                            [113.116522, 29.41617],
                            [113.129173, 29.426358],
                            [113.134089, 29.429773],
                            [113.136925, 29.433002],
                            [113.140147, 29.438508],
                            [113.140884, 29.442989],
                            [113.140276, 29.45178]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430621,
                "name": "岳阳县",
                "center": [113.116073, 29.144843],
                "centroid": [113.220802, 29.16623],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430600
                },
                "subFeatureIndex": 3,
                "acroutes": [100000, 430000, 430600]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [113.606818, 29.254827],
                            [113.603817, 29.256509],
                            [113.601497, 29.254143],
                            [113.5989, 29.254927],
                            [113.596267, 29.25906],
                            [113.593192, 29.265401],
                            [113.590448, 29.269747],
                            [113.585237, 29.270688],
                            [113.581149, 29.269306],
                            [113.574925, 29.26818],
                            [113.568701, 29.269249],
                            [113.565147, 29.268337],
                            [113.562219, 29.268693],
                            [113.557984, 29.271201],
                            [113.553362, 29.277798],
                            [113.549366, 29.279565],
                            [113.544173, 29.278311],
                            [113.538557, 29.275589],
                            [113.534248, 29.272825],
                            [113.530307, 29.266897],
                            [113.520898, 29.261796],
                            [113.516515, 29.26077],
                            [113.505853, 29.261497],
                            [113.503183, 29.264318],
                            [113.500458, 29.269306],
                            [113.496094, 29.27378],
                            [113.48928, 29.274977],
                            [113.479355, 29.26932],
                            [113.476225, 29.266755],
                            [113.472984, 29.262423],
                            [113.472468, 29.258361],
                            [113.474844, 29.254741],
                            [113.478398, 29.250993],
                            [113.47663, 29.248627],
                            [113.472303, 29.24552],
                            [113.467625, 29.240988],
                            [113.464311, 29.23654],
                            [113.46026, 29.234274],
                            [113.451495, 29.230753],
                            [113.445455, 29.229],
                            [113.439249, 29.224737],
                            [113.435548, 29.218108],
                            [113.429361, 29.210908],
                            [113.423615, 29.206388],
                            [113.419251, 29.201897],
                            [113.413064, 29.19732],
                            [113.410486, 29.196179],
                            [113.40463, 29.196764],
                            [113.401666, 29.199487],
                            [113.396768, 29.202553],
                            [113.391667, 29.20502],
                            [113.388647, 29.205476],
                            [113.381594, 29.202852],
                            [113.377985, 29.205733],
                            [113.372516, 29.208],
                            [113.369441, 29.206032],
                            [113.36725, 29.203009],
                            [113.365298, 29.198275],
                            [113.362333, 29.197519],
                            [113.358484, 29.201455],
                            [113.351082, 29.20415],
                            [113.349241, 29.204264],
                            [113.345263, 29.19933],
                            [113.343237, 29.19943],
                            [113.338542, 29.207743],
                            [113.338542, 29.209739],
                            [113.340715, 29.212548],
                            [113.340181, 29.217082],
                            [113.338616, 29.219662],
                            [113.338284, 29.22632],
                            [113.341856, 29.227246],
                            [113.343514, 29.228786],
                            [113.341451, 29.230796],
                            [113.331839, 29.231665],
                            [113.326794, 29.23396],
                            [113.325173, 29.230796],
                            [113.328267, 29.229413],
                            [113.326794, 29.227403],
                            [113.321306, 29.22699],
                            [113.316132, 29.228487],
                            [113.31337, 29.231494],
                            [113.313848, 29.233661],
                            [113.316021, 29.236184],
                            [113.312302, 29.237567],
                            [113.310387, 29.24314],
                            [113.306593, 29.242955],
                            [113.302837, 29.237595],
                            [113.299228, 29.236811],
                            [113.297312, 29.238907],
                            [113.29339, 29.240717],
                            [113.291199, 29.238394],
                            [113.285564, 29.23912],
                            [113.281274, 29.242655],
                            [113.278604, 29.241102],
                            [113.277223, 29.242983],
                            [113.271477, 29.242114],
                            [113.267942, 29.242741],
                            [113.26715, 29.244223],
                            [113.264314, 29.245235],
                            [113.267979, 29.246404],
                            [113.268108, 29.249753],
                            [113.263283, 29.250352],
                            [113.2603, 29.253359],
                            [113.258238, 29.258219],
                            [113.252879, 29.261539],
                            [113.24903, 29.265088],
                            [113.244519, 29.264503],
                            [113.242604, 29.268408],
                            [113.239437, 29.268465],
                            [113.236122, 29.266256],
                            [113.235975, 29.263107],
                            [113.234704, 29.262622],
                            [113.230929, 29.265031],
                            [113.220415, 29.272683],
                            [113.217505, 29.272811],
                            [113.211484, 29.271742],
                            [113.202848, 29.269448],
                            [113.199257, 29.269448],
                            [113.195298, 29.271486],
                            [113.194506, 29.27378],
                            [113.191652, 29.276102],
                            [113.190639, 29.279408],
                            [113.194156, 29.280591],
                            [113.194856, 29.282201],
                            [113.19248, 29.284295],
                            [113.193107, 29.289695],
                            [113.189626, 29.290934],
                            [113.183844, 29.289652],
                            [113.177178, 29.285734],
                            [113.178007, 29.281474],
                            [113.176957, 29.27871],
                            [113.174195, 29.278582],
                            [113.168745, 29.276487],
                            [113.162668, 29.281417],
                            [113.158948, 29.282443],
                            [113.155321, 29.280234],
                            [113.155578, 29.276786],
                            [113.159298, 29.275034],
                            [113.159593, 29.273196],
                            [113.154584, 29.269477],
                            [113.153792, 29.267596],
                            [113.15602, 29.261953],
                            [113.160698, 29.25849],
                            [113.162723, 29.255896],
                            [113.162557, 29.248171],
                            [113.16427, 29.242427],
                            [113.164141, 29.240161],
                            [113.162336, 29.237838],
                            [113.158875, 29.239292],
                            [113.154382, 29.239676],
                            [113.152374, 29.242784],
                            [113.148047, 29.24381],
                            [113.137717, 29.240175],
                            [113.133942, 29.243097],
                            [113.129393, 29.25296],
                            [113.125213, 29.260613],
                            [113.120665, 29.262594],
                            [113.120223, 29.265016],
                            [113.122028, 29.267695],
                            [113.119192, 29.271928],
                            [113.113815, 29.273694],
                            [113.110224, 29.278482],
                            [113.111016, 29.280291],
                            [113.11619, 29.280206],
                            [113.117774, 29.284366],
                            [113.117074, 29.286646],
                            [113.109856, 29.29669],
                            [113.106523, 29.29696],
                            [113.103466, 29.294809],
                            [113.101201, 29.290379],
                            [113.089766, 29.291062],
                            [113.087317, 29.29367],
                            [113.085954, 29.301334],
                            [113.083045, 29.309738],
                            [113.080854, 29.312986],
                            [113.073912, 29.318156],
                            [113.071849, 29.321104],
                            [113.072733, 29.324949],
                            [113.076379, 29.334192],
                            [113.07474, 29.338535],
                            [113.075201, 29.341796],
                            [113.078257, 29.347478],
                            [113.086415, 29.355465],
                            [113.088974, 29.362669],
                            [113.090871, 29.371211],
                            [113.093302, 29.376606],
                            [113.093504, 29.386385],
                            [113.095456, 29.391309],
                            [113.098384, 29.393515],
                            [113.103227, 29.395878],
                            [113.113005, 29.399393],
                            [113.122028, 29.404274],
                            [113.126521, 29.407946],
                            [113.138766, 29.419415],
                            [113.146169, 29.424722],
                            [113.148636, 29.428008],
                            [113.14952, 29.44077],
                            [113.15022, 29.444155],
                            [113.148728, 29.451865],
                            [113.14534, 29.449319],
                            [113.140276, 29.45178],
                            [113.140884, 29.442989],
                            [113.140147, 29.438508],
                            [113.136925, 29.433002],
                            [113.134089, 29.429773],
                            [113.129173, 29.426358],
                            [113.116522, 29.41617],
                            [113.105989, 29.41045],
                            [113.10365, 29.405996],
                            [113.099544, 29.404075],
                            [113.095309, 29.404388],
                            [113.089858, 29.402951],
                            [113.075808, 29.394269],
                            [113.063784, 29.387523],
                            [113.058462, 29.386143],
                            [113.050525, 29.388263],
                            [113.045185, 29.388705],
                            [113.029091, 29.384178],
                            [113.025556, 29.379908],
                            [113.024782, 29.376834],
                            [113.024948, 29.372734],
                            [113.026532, 29.35854],
                            [113.025022, 29.353216],
                            [113.018614, 29.349998],
                            [113.011303, 29.349072],
                            [113.005429, 29.349129],
                            [113.003091, 29.349984],
                            [113.001728, 29.352062],
                            [113.001875, 29.360277],
                            [113.000936, 29.372407],
                            [112.997529, 29.378698],
                            [112.997014, 29.388363],
                            [112.995909, 29.391864],
                            [112.992466, 29.395607],
                            [112.974401, 29.410607],
                            [112.965157, 29.419144],
                            [112.957571, 29.427866],
                            [112.952765, 29.43286],
                            [112.938715, 29.441395],
                            [112.903414, 29.461807],
                            [112.89546, 29.467852],
                            [112.887689, 29.470468],
                            [112.881391, 29.471706],
                            [112.870932, 29.474763],
                            [112.864689, 29.477351],
                            [112.856035, 29.481589],
                            [112.848503, 29.488912],
                            [112.837436, 29.496817],
                            [112.831397, 29.503571],
                            [112.82427, 29.509812],
                            [112.817494, 29.510892],
                            [112.809171, 29.50903],
                            [112.800958, 29.505006],
                            [112.788197, 29.49713],
                            [112.78433, 29.49413],
                            [112.782617, 29.491557],
                            [112.777222, 29.470355],
                            [112.776614, 29.464865],
                            [112.776799, 29.460812],
                            [112.773005, 29.455549],
                            [112.772692, 29.451609],
                            [112.775804, 29.44811],
                            [112.779855, 29.446033],
                            [112.781899, 29.446118],
                            [112.78886, 29.451154],
                            [112.793961, 29.452306],
                            [112.79687, 29.451253],
                            [112.798638, 29.442747],
                            [112.800129, 29.440613],
                            [112.803646, 29.439774],
                            [112.806077, 29.43562],
                            [112.807329, 29.431067],
                            [112.809207, 29.430313],
                            [112.812154, 29.429943],
                            [112.815873, 29.430754],
                            [112.819888, 29.434937],
                            [112.823165, 29.436544],
                            [112.825817, 29.439304],
                            [112.827382, 29.443088],
                            [112.827345, 29.445777],
                            [112.828855, 29.446389],
                            [112.83821, 29.443586],
                            [112.83797, 29.441239],
                            [112.834288, 29.434539],
                            [112.833017, 29.428051],
                            [112.836774, 29.41617],
                            [112.842206, 29.400432],
                            [112.844176, 29.392604],
                            [112.844231, 29.388178],
                            [112.843329, 29.382143],
                            [112.840033, 29.368506],
                            [112.82985, 29.347036],
                            [112.826959, 29.341583],
                            [112.823902, 29.333095],
                            [112.81858, 29.322229],
                            [112.816279, 29.315849],
                            [112.815634, 29.310536],
                            [112.811067, 29.298043],
                            [112.810478, 29.295365],
                            [112.810533, 29.289111],
                            [112.806611, 29.276444],
                            [112.806445, 29.272027],
                            [112.804346, 29.261938],
                            [112.806059, 29.258105],
                            [112.805948, 29.25172],
                            [112.797864, 29.239263],
                            [112.794973, 29.223939],
                            [112.790259, 29.207501],
                            [112.788289, 29.197947],
                            [112.784625, 29.186667],
                            [112.783023, 29.183915],
                            [112.783372, 29.182817],
                            [112.787884, 29.185755],
                            [112.79385, 29.190931],
                            [112.799043, 29.190461],
                            [112.810386, 29.185512],
                            [112.813056, 29.182888],
                            [112.813461, 29.178652],
                            [112.806482, 29.171293],
                            [112.801216, 29.164788],
                            [112.798509, 29.15981],
                            [112.804604, 29.155188],
                            [112.807826, 29.153533],
                            [112.812872, 29.152378],
                            [112.873436, 29.161279],
                            [112.881594, 29.162763],
                            [112.887855, 29.15981],
                            [112.894741, 29.15757],
                            [112.901094, 29.154617],
                            [112.912401, 29.152278],
                            [112.924664, 29.149439],
                            [112.925309, 29.148583],
                            [112.925346, 29.141635],
                            [112.926506, 29.133745],
                            [112.926193, 29.12905],
                            [112.926893, 29.120246],
                            [112.927058, 29.109043],
                            [112.929894, 29.096997],
                            [112.937757, 29.078111],
                            [112.939175, 29.073243],
                            [112.939028, 29.065533],
                            [112.941145, 29.063177],
                            [112.949376, 29.060779],
                            [112.94376, 29.05144],
                            [112.939028, 29.044271],
                            [112.947277, 29.03923],
                            [112.949524, 29.037145],
                            [112.954753, 29.035988],
                            [112.965765, 29.056152],
                            [112.966686, 29.061036],
                            [112.976279, 29.059508],
                            [112.982872, 29.056109],
                            [112.984253, 29.053967],
                            [112.985413, 29.044828],
                            [112.985431, 29.040015],
                            [112.98788, 29.027004],
                            [112.991803, 29.021319],
                            [112.999868, 29.016805],
                            [113.00762, 29.019248],
                            [113.01447, 29.018334],
                            [113.017988, 29.015591],
                            [113.027766, 29.016134],
                            [113.030859, 29.014477],
                            [113.033069, 29.016563],
                            [113.035352, 29.016534],
                            [113.037764, 29.013163],
                            [113.044154, 29.00692],
                            [113.047653, 29.005548],
                            [113.05524, 29.005891],
                            [113.057781, 29.008248],
                            [113.060616, 29.009006],
                            [113.065865, 29.007091],
                            [113.072549, 29.007434],
                            [113.075072, 29.006248],
                            [113.077926, 29.001462],
                            [113.082272, 28.997234],
                            [113.090816, 28.99199],
                            [113.101441, 28.987418],
                            [113.106265, 28.983802],
                            [113.109451, 28.982717],
                            [113.116117, 28.982159],
                            [113.124219, 28.975129],
                            [113.131456, 28.969512],
                            [113.136704, 28.966554],
                            [113.144346, 28.968498],
                            [113.147163, 28.96814],
                            [113.148489, 28.966497],
                            [113.15033, 28.961509],
                            [113.153663, 28.958836],
                            [113.158783, 28.959436],
                            [113.164288, 28.957593],
                            [113.170107, 28.957121],
                            [113.174029, 28.959351],
                            [113.178209, 28.963553],
                            [113.183016, 28.965511],
                            [113.185207, 28.964982],
                            [113.187435, 28.962495],
                            [113.187674, 28.960108],
                            [113.186054, 28.952218],
                            [113.187895, 28.951032],
                            [113.189939, 28.95169],
                            [113.195095, 28.956292],
                            [113.198999, 28.956378],
                            [113.20642, 28.952604],
                            [113.209477, 28.952147],
                            [113.211594, 28.953376],
                            [113.215222, 28.958207],
                            [113.2178, 28.959665],
                            [113.225166, 28.960923],
                            [113.242291, 28.972513],
                            [113.243451, 28.976115],
                            [113.241499, 28.982231],
                            [113.242346, 28.986789],
                            [113.25148, 28.989461],
                            [113.259563, 28.986032],
                            [113.263228, 28.986689],
                            [113.272822, 28.992247],
                            [113.276725, 28.991433],
                            [113.288013, 28.985589],
                            [113.291733, 28.984717],
                            [113.297147, 28.986174],
                            [113.303389, 28.986146],
                            [113.310626, 28.993633],
                            [113.312504, 28.994476],
                            [113.32103, 28.990332],
                            [113.326407, 28.987146],
                            [113.329942, 28.982788],
                            [113.329961, 28.975843],
                            [113.332778, 28.972842],
                            [113.33508, 28.97217],
                            [113.345042, 28.971727],
                            [113.360952, 28.974328],
                            [113.363383, 28.973528],
                            [113.368299, 28.967812],
                            [113.373105, 28.964739],
                            [113.378298, 28.963596],
                            [113.384485, 28.964296],
                            [113.387192, 28.958022],
                            [113.393803, 28.957307],
                            [113.397338, 28.959608],
                            [113.399677, 28.962924],
                            [113.401684, 28.96371],
                            [113.405846, 28.962667],
                            [113.409768, 28.964224],
                            [113.414151, 28.96764],
                            [113.41752, 28.972428],
                            [113.425475, 28.972828],
                            [113.430742, 28.974543],
                            [113.439286, 28.978201],
                            [113.441146, 28.977572],
                            [113.443024, 28.972185],
                            [113.444884, 28.970941],
                            [113.451218, 28.970827],
                            [113.461088, 28.973056],
                            [113.465195, 28.972528],
                            [113.467257, 28.969284],
                            [113.467441, 28.965382],
                            [113.468767, 28.96341],
                            [113.473371, 28.962795],
                            [113.474954, 28.961709],
                            [113.47628, 28.958365],
                            [113.481767, 28.95855],
                            [113.491914, 28.961666],
                            [113.496204, 28.962023],
                            [113.507492, 28.95978],
                            [113.51077, 28.954377],
                            [113.513458, 28.954077],
                            [113.526882, 28.963038],
                            [113.530418, 28.964353],
                            [113.538207, 28.964224],
                            [113.545112, 28.962809],
                            [113.547819, 28.963353],
                            [113.556087, 28.966825],
                            [113.558941, 28.970198],
                            [113.561206, 28.975357],
                            [113.561593, 28.980887],
                            [113.562569, 28.983145],
                            [113.566878, 28.98486],
                            [113.571481, 28.984245],
                            [113.575238, 28.982702],
                            [113.577908, 28.979516],
                            [113.577503, 28.978101],
                            [113.570082, 28.970827],
                            [113.569235, 28.969084],
                            [113.571647, 28.967426],
                            [113.574667, 28.967469],
                            [113.581664, 28.968926],
                            [113.583285, 28.972356],
                            [113.580762, 28.977744],
                            [113.58264, 28.980887],
                            [113.585053, 28.980816],
                            [113.587686, 28.976057],
                            [113.588349, 28.97057],
                            [113.591921, 28.969412],
                            [113.598679, 28.971742],
                            [113.609875, 28.969684],
                            [113.611772, 28.973114],
                            [113.611974, 28.984903],
                            [113.611643, 28.988461],
                            [113.612398, 28.997319],
                            [113.616615, 29.00342],
                            [113.621899, 29.008063],
                            [113.622433, 29.010448],
                            [113.621513, 29.015063],
                            [113.62363, 29.018177],
                            [113.62991, 29.022776],
                            [113.635452, 29.025447],
                            [113.643113, 29.02499],
                            [113.645414, 29.024419],
                            [113.648692, 29.019219],
                            [113.651233, 29.018848],
                            [113.654603, 29.021791],
                            [113.657089, 29.028375],
                            [113.660698, 29.031418],
                            [113.670292, 29.035102],
                            [113.676847, 29.038516],
                            [113.681175, 29.03943],
                            [113.68657, 29.038116],
                            [113.698631, 29.033688],
                            [113.706016, 29.033203],
                            [113.711448, 29.035202],
                            [113.715793, 29.041258],
                            [113.723564, 29.056181],
                            [113.727984, 29.060693],
                            [113.731409, 29.060536],
                            [113.737154, 29.06145],
                            [113.734189, 29.064477],
                            [113.732403, 29.069631],
                            [113.731869, 29.073486],
                            [113.728076, 29.078097],
                            [113.726234, 29.08365],
                            [113.727137, 29.089988],
                            [113.731482, 29.099652],
                            [113.732845, 29.101736],
                            [113.73209, 29.105075],
                            [113.727173, 29.105761],
                            [113.722754, 29.104719],
                            [113.713989, 29.097796],
                            [113.709514, 29.092615],
                            [113.708244, 29.086791],
                            [113.706347, 29.082879],
                            [113.701357, 29.079896],
                            [113.69633, 29.077654],
                            [113.693844, 29.077355],
                            [113.688909, 29.078497],
                            [113.685613, 29.082294],
                            [113.685428, 29.086834],
                            [113.688154, 29.094685],
                            [113.688633, 29.104547],
                            [113.689627, 29.106874],
                            [113.690584, 29.114538],
                            [113.689093, 29.117435],
                            [113.684581, 29.121944],
                            [113.684213, 29.124655],
                            [113.685465, 29.129107],
                            [113.682998, 29.13219],
                            [113.672612, 29.136185],
                            [113.670329, 29.138896],
                            [113.66799, 29.143761],
                            [113.6688, 29.150238],
                            [113.670366, 29.154346],
                            [113.669334, 29.156629],
                            [113.664823, 29.158227],
                            [113.663147, 29.159568],
                            [113.662006, 29.167285],
                            [113.664491, 29.171592],
                            [113.671931, 29.179465],
                            [113.676903, 29.181932],
                            [113.680328, 29.182432],
                            [113.685815, 29.188863],
                            [113.691671, 29.196821],
                            [113.693788, 29.201826],
                            [113.693623, 29.207387],
                            [113.692039, 29.219634],
                            [113.69296, 29.226277],
                            [113.690087, 29.23081],
                            [113.688098, 29.232107],
                            [113.681874, 29.23396],
                            [113.676534, 29.233077],
                            [113.672428, 29.231409],
                            [113.66578, 29.23225],
                            [113.662963, 29.229613],
                            [113.662374, 29.225892],
                            [113.660054, 29.224509],
                            [113.655082, 29.22478],
                            [113.651602, 29.22612],
                            [113.646464, 29.231395],
                            [113.642781, 29.237952],
                            [113.64013, 29.240988],
                            [113.637717, 29.247758],
                            [113.63525, 29.250623],
                            [113.632119, 29.251321],
                            [113.628381, 29.250594],
                            [113.620739, 29.251107],
                            [113.614792, 29.250722],
                            [113.609654, 29.251521],
                            [113.606818, 29.254827]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430623,
                "name": "华容县",
                "center": [112.559369, 29.524107],
                "centroid": [112.641661, 29.49116],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430600
                },
                "subFeatureIndex": 4,
                "acroutes": [100000, 430000, 430600]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.915844, 29.621033],
                            [112.921645, 29.626571],
                            [112.93494, 29.638427],
                            [112.945399, 29.647499],
                            [112.950665, 29.649785],
                            [112.960001, 29.654824],
                            [112.965433, 29.658926],
                            [112.97081, 29.666549],
                            [112.976206, 29.672127],
                            [112.996038, 29.685482],
                            [113.00495, 29.693755],
                            [113.010383, 29.701829],
                            [113.015225, 29.710909],
                            [113.020842, 29.728628],
                            [113.02504, 29.748655],
                            [113.030878, 29.762298],
                            [113.03097, 29.766878],
                            [113.028999, 29.770451],
                            [113.026127, 29.772777],
                            [113.019092, 29.772096],
                            [113.0118, 29.767672],
                            [113.003477, 29.755236],
                            [112.993147, 29.746528],
                            [112.979226, 29.737607],
                            [112.974917, 29.732685],
                            [112.971823, 29.723833],
                            [112.969098, 29.718173],
                            [112.966281, 29.714116],
                            [112.962929, 29.706823],
                            [112.959394, 29.696394],
                            [112.955803, 29.692322],
                            [112.948824, 29.686802],
                            [112.94481, 29.682757],
                            [112.939451, 29.681295],
                            [112.935161, 29.681934],
                            [112.931551, 29.684857],
                            [112.928458, 29.688519],
                            [112.927003, 29.692166],
                            [112.927316, 29.708412],
                            [112.931275, 29.726217],
                            [112.93203, 29.739905],
                            [112.930925, 29.750187],
                            [112.929913, 29.755335],
                            [112.926874, 29.763035],
                            [112.922731, 29.767289],
                            [112.89975, 29.781709],
                            [112.889457, 29.785735],
                            [112.880673, 29.786869],
                            [112.870011, 29.786005],
                            [112.861651, 29.783141],
                            [112.843642, 29.771089],
                            [112.815431, 29.746627],
                            [112.801989, 29.7386],
                            [112.793942, 29.735891],
                            [112.79501, 29.732529],
                            [112.802265, 29.727465],
                            [112.805083, 29.727536],
                            [112.806095, 29.726089],
                            [112.803867, 29.722514],
                            [112.797514, 29.718102],
                            [112.792487, 29.715662],
                            [112.791788, 29.713733],
                            [112.795636, 29.70644],
                            [112.796723, 29.70234],
                            [112.795194, 29.697671],
                            [112.788841, 29.695841],
                            [112.784864, 29.693556],
                            [112.784643, 29.689725],
                            [112.786355, 29.684134],
                            [112.788565, 29.679904],
                            [112.785582, 29.678173],
                            [112.779119, 29.67972],
                            [112.774626, 29.677918],
                            [112.76947, 29.676796],
                            [112.765953, 29.675079],
                            [112.763669, 29.672311],
                            [112.763835, 29.664789],
                            [112.762104, 29.660985],
                            [112.758182, 29.658174],
                            [112.754352, 29.65755],
                            [112.752547, 29.658898],
                            [112.748514, 29.655122],
                            [112.745623, 29.653972],
                            [112.735459, 29.646264],
                            [112.731813, 29.645071],
                            [112.730321, 29.645597],
                            [112.729069, 29.648663],
                            [112.730229, 29.654199],
                            [112.727577, 29.655335],
                            [112.71896, 29.652539],
                            [112.71535, 29.649841],
                            [112.714061, 29.647158],
                            [112.714687, 29.643311],
                            [112.713914, 29.637532],
                            [112.71187, 29.633954],
                            [112.705941, 29.630433],
                            [112.703952, 29.626727],
                            [112.698686, 29.619982],
                            [112.698041, 29.617241],
                            [112.69933, 29.613151],
                            [112.699017, 29.610737],
                            [112.695813, 29.608834],
                            [112.693327, 29.608706],
                            [112.6941, 29.601747],
                            [112.689, 29.6024],
                            [112.689884, 29.598026],
                            [112.686937, 29.592728],
                            [112.682739, 29.589574],
                            [112.679001, 29.589518],
                            [112.675097, 29.598466],
                            [112.673421, 29.600028],
                            [112.660255, 29.599531],
                            [112.65613, 29.596946],
                            [112.650643, 29.592486],
                            [112.647844, 29.593793],
                            [112.646794, 29.597657],
                            [112.640184, 29.607897],
                            [112.63523, 29.610198],
                            [112.630314, 29.61389],
                            [112.629043, 29.616077],
                            [112.624716, 29.616546],
                            [112.62107, 29.61487],
                            [112.612544, 29.608919],
                            [112.609229, 29.607542],
                            [112.607738, 29.608025],
                            [112.607167, 29.612271],
                            [112.600077, 29.615424],
                            [112.594019, 29.617582],
                            [112.589673, 29.620224],
                            [112.584112, 29.621061],
                            [112.576581, 29.623362],
                            [112.572235, 29.623873],
                            [112.570836, 29.622084],
                            [112.565127, 29.620777],
                            [112.559511, 29.615864],
                            [112.551519, 29.610439],
                            [112.550912, 29.609232],
                            [112.543822, 29.603806],
                            [112.540784, 29.600838],
                            [112.535223, 29.599716],
                            [112.522388, 29.605397],
                            [112.514323, 29.607457],
                            [112.513549, 29.608948],
                            [112.509958, 29.608962],
                            [112.505226, 29.611007],
                            [112.503219, 29.613464],
                            [112.502188, 29.616546],
                            [112.502077, 29.625762],
                            [112.499112, 29.62924],
                            [112.487512, 29.631299],
                            [112.482024, 29.633486],
                            [112.475156, 29.633429],
                            [112.46849, 29.631342],
                            [112.465875, 29.631441],
                            [112.462579, 29.633685],
                            [112.456649, 29.635942],
                            [112.450941, 29.634934],
                            [112.446061, 29.632662],
                            [112.439635, 29.633812],
                            [112.436541, 29.629169],
                            [112.433981, 29.623901],
                            [112.430059, 29.619258],
                            [112.427297, 29.616972],
                            [112.425732, 29.611234],
                            [112.424811, 29.598693],
                            [112.423559, 29.595711],
                            [112.41472, 29.584716],
                            [112.407833, 29.577826],
                            [112.401333, 29.575837],
                            [112.399694, 29.572101],
                            [112.397264, 29.56413],
                            [112.395569, 29.562582],
                            [112.392844, 29.563264],
                            [112.392292, 29.566901],
                            [112.390727, 29.567512],
                            [112.386454, 29.562084],
                            [112.38209, 29.558475],
                            [112.370268, 29.542205],
                            [112.365775, 29.541409],
                            [112.357047, 29.542205],
                            [112.34633, 29.544251],
                            [112.338983, 29.544819],
                            [112.332077, 29.544748],
                            [112.325909, 29.543071],
                            [112.320053, 29.540556],
                            [112.318745, 29.538666],
                            [112.316646, 29.532341],
                            [112.315726, 29.524268],
                            [112.317475, 29.518639],
                            [112.320053, 29.515981],
                            [112.331138, 29.510579],
                            [112.33088, 29.505319],
                            [112.327713, 29.495808],
                            [112.326369, 29.488073],
                            [112.324435, 29.480423],
                            [112.324196, 29.474322],
                            [112.325853, 29.466472],
                            [112.327271, 29.452804],
                            [112.328266, 29.450058],
                            [112.335245, 29.445777],
                            [112.338449, 29.444511],
                            [112.342003, 29.444411],
                            [112.343641, 29.447797],
                            [112.347103, 29.450784],
                            [112.357544, 29.45636],
                            [112.362921, 29.458436],
                            [112.366199, 29.458422],
                            [112.366788, 29.442534],
                            [112.368943, 29.438152],
                            [112.371686, 29.437341],
                            [112.382551, 29.430057],
                            [112.387504, 29.424978],
                            [112.388848, 29.420994],
                            [112.387209, 29.412642],
                            [112.38662, 29.40601],
                            [112.388517, 29.400304],
                            [112.392034, 29.396447],
                            [112.398829, 29.390839],
                            [112.406102, 29.385531],
                            [112.408883, 29.382285],
                            [112.415494, 29.378285],
                            [112.423099, 29.371723],
                            [112.439948, 29.360633],
                            [112.446798, 29.359964],
                            [112.45711, 29.35585],
                            [112.464254, 29.353486],
                            [112.476813, 29.347819],
                            [112.485744, 29.342921],
                            [112.488727, 29.340657],
                            [112.490973, 29.33761],
                            [112.491857, 29.329008],
                            [112.496995, 29.317031],
                            [112.499591, 29.313854],
                            [112.504729, 29.311504],
                            [112.51981, 29.311732],
                            [112.527249, 29.310593],
                            [112.532405, 29.308926],
                            [112.547339, 29.301775],
                            [112.55023, 29.301291],
                            [112.553932, 29.304183],
                            [112.560229, 29.30461],
                            [112.570633, 29.301918],
                            [112.574482, 29.30179],
                            [112.579417, 29.30367],
                            [112.59041, 29.317857],
                            [112.594019, 29.321275],
                            [112.601587, 29.326744],
                            [112.611789, 29.333281],
                            [112.617092, 29.335217],
                            [112.62223, 29.336257],
                            [112.632247, 29.336499],
                            [112.634438, 29.335986],
                            [112.641399, 29.332184],
                            [112.644677, 29.329877],
                            [112.652576, 29.321446],
                            [112.660826, 29.305422],
                            [112.664767, 29.300408],
                            [112.667768, 29.300721],
                            [112.673642, 29.306262],
                            [112.674673, 29.305607],
                            [112.678651, 29.297986],
                            [112.680768, 29.294966],
                            [112.683623, 29.293784],
                            [112.687563, 29.295749],
                            [112.689239, 29.295721],
                            [112.696273, 29.288697],
                            [112.699882, 29.287387],
                            [112.694708, 29.281232],
                            [112.69051, 29.278482],
                            [112.686017, 29.276829],
                            [112.68331, 29.274977],
                            [112.682131, 29.272241],
                            [112.682794, 29.269591],
                            [112.685446, 29.265886],
                            [112.686753, 29.262679],
                            [112.688429, 29.261169],
                            [112.691283, 29.261383],
                            [112.693732, 29.259444],
                            [112.694671, 29.257321],
                            [112.693695, 29.254],
                            [112.688558, 29.25011],
                            [112.679111, 29.244779],
                            [112.678559, 29.237923],
                            [112.68272, 29.236883],
                            [112.682499, 29.232307],
                            [112.681671, 29.230425],
                            [112.678411, 29.227588],
                            [112.676386, 29.224695],
                            [112.676809, 29.220275],
                            [112.679885, 29.216212],
                            [112.680842, 29.212006],
                            [112.677564, 29.205405],
                            [112.677362, 29.202196],
                            [112.67843, 29.195922],
                            [112.682407, 29.192243],
                            [112.686698, 29.19317],
                            [112.693198, 29.192557],
                            [112.706051, 29.186311],
                            [112.711244, 29.185355],
                            [112.715848, 29.187337],
                            [112.723047, 29.181162],
                            [112.724576, 29.17764],
                            [112.727596, 29.176955],
                            [112.732641, 29.174231],
                            [112.733286, 29.171835],
                            [112.736324, 29.170636],
                            [112.739952, 29.170651],
                            [112.74194, 29.168283],
                            [112.748588, 29.168354],
                            [112.757279, 29.170094],
                            [112.766431, 29.173232],
                            [112.770814, 29.174274],
                            [112.775602, 29.176584],
                            [112.783023, 29.183915],
                            [112.784625, 29.186667],
                            [112.788289, 29.197947],
                            [112.790259, 29.207501],
                            [112.794973, 29.223939],
                            [112.797864, 29.239263],
                            [112.805948, 29.25172],
                            [112.806059, 29.258105],
                            [112.804346, 29.261938],
                            [112.806445, 29.272027],
                            [112.806611, 29.276444],
                            [112.810533, 29.289111],
                            [112.810478, 29.295365],
                            [112.811067, 29.298043],
                            [112.815634, 29.310536],
                            [112.816279, 29.315849],
                            [112.81858, 29.322229],
                            [112.823902, 29.333095],
                            [112.826959, 29.341583],
                            [112.82985, 29.347036],
                            [112.840033, 29.368506],
                            [112.843329, 29.382143],
                            [112.844231, 29.388178],
                            [112.844176, 29.392604],
                            [112.842206, 29.400432],
                            [112.836774, 29.41617],
                            [112.833017, 29.428051],
                            [112.834288, 29.434539],
                            [112.83797, 29.441239],
                            [112.83821, 29.443586],
                            [112.828855, 29.446389],
                            [112.827345, 29.445777],
                            [112.827382, 29.443088],
                            [112.825817, 29.439304],
                            [112.823165, 29.436544],
                            [112.819888, 29.434937],
                            [112.815873, 29.430754],
                            [112.812154, 29.429943],
                            [112.809207, 29.430313],
                            [112.809521, 29.416412],
                            [112.796649, 29.379695],
                            [112.793684, 29.376862],
                            [112.790996, 29.37615],
                            [112.781568, 29.376264],
                            [112.777093, 29.374072],
                            [112.776117, 29.372051],
                            [112.776522, 29.368364],
                            [112.778787, 29.364862],
                            [112.781476, 29.35827],
                            [112.780868, 29.350112],
                            [112.779395, 29.345427],
                            [112.767057, 29.332626],
                            [112.759636, 29.321887],
                            [112.755438, 29.317871],
                            [112.75065, 29.31723],
                            [112.735201, 29.314011],
                            [112.724963, 29.313128],
                            [112.722882, 29.314182],
                            [112.721114, 29.320121],
                            [112.723821, 29.327855],
                            [112.727909, 29.335374],
                            [112.728038, 29.342323],
                            [112.725994, 29.346025],
                            [112.722016, 29.349457],
                            [112.716805, 29.355152],
                            [112.715037, 29.361573],
                            [112.715811, 29.365659],
                            [112.718462, 29.370328],
                            [112.719549, 29.374072],
                            [112.71907, 29.377588],
                            [112.716363, 29.382058],
                            [112.709771, 29.387452],
                            [112.704357, 29.388107],
                            [112.703621, 29.389003],
                            [112.704283, 29.3938],
                            [112.70316, 29.397144],
                            [112.698391, 29.400603],
                            [112.695445, 29.400859],
                            [112.688576, 29.400033],
                            [112.685206, 29.400816],
                            [112.679627, 29.406252],
                            [112.675926, 29.412784],
                            [112.668486, 29.414918],
                            [112.66287, 29.415174],
                            [112.656591, 29.41728],
                            [112.65079, 29.424338],
                            [112.646408, 29.428094],
                            [112.645119, 29.43037],
                            [112.639613, 29.437498],
                            [112.634715, 29.440058],
                            [112.632892, 29.442519],
                            [112.632045, 29.446687],
                            [112.630093, 29.450955],
                            [112.632486, 29.453999],
                            [112.632523, 29.462888],
                            [112.629595, 29.466614],
                            [112.631492, 29.466799],
                            [112.636906, 29.46559],
                            [112.641086, 29.465804],
                            [112.646702, 29.464054],
                            [112.64777, 29.460442],
                            [112.652337, 29.457754],
                            [112.655836, 29.456886],
                            [112.659279, 29.451808],
                            [112.66519, 29.45067],
                            [112.667381, 29.451381],
                            [112.669131, 29.454653],
                            [112.669204, 29.458948],
                            [112.666479, 29.464083],
                            [112.666313, 29.468164],
                            [112.668228, 29.471236],
                            [112.672095, 29.474123],
                            [112.680419, 29.476128],
                            [112.683181, 29.479712],
                            [112.685188, 29.485272],
                            [112.689036, 29.490604],
                            [112.694708, 29.506016],
                            [112.733525, 29.515782],
                            [112.75402, 29.52161],
                            [112.759968, 29.524467],
                            [112.760023, 29.527168],
                            [112.758071, 29.531062],
                            [112.755806, 29.533549],
                            [112.751, 29.536733],
                            [112.745439, 29.537614],
                            [112.743229, 29.541323],
                            [112.743027, 29.544947],
                            [112.744095, 29.548429],
                            [112.74682, 29.553658],
                            [112.748238, 29.558163],
                            [112.751645, 29.560806],
                            [112.761459, 29.562937],
                            [112.764148, 29.568919],
                            [112.767555, 29.574644],
                            [112.769709, 29.584191],
                            [112.77306, 29.587216],
                            [112.777314, 29.583537],
                            [112.780905, 29.583012],
                            [112.787442, 29.585029],
                            [112.791419, 29.587642],
                            [112.795305, 29.591478],
                            [112.80094, 29.600611],
                            [112.804475, 29.600881],
                            [112.806943, 29.602727],
                            [112.808784, 29.605852],
                            [112.810773, 29.607187],
                            [112.813553, 29.601974],
                            [112.81847, 29.599759],
                            [112.822963, 29.60169],
                            [112.825762, 29.603806],
                            [112.835448, 29.606264],
                            [112.839075, 29.607925],
                            [112.844158, 29.612583],
                            [112.846717, 29.618037],
                            [112.848872, 29.627054],
                            [112.849884, 29.62853],
                            [112.856035, 29.630873],
                            [112.863032, 29.636425],
                            [112.868464, 29.638611],
                            [112.875001, 29.637973],
                            [112.880691, 29.640173],
                            [112.884743, 29.639634],
                            [112.890138, 29.640684],
                            [112.897982, 29.633486],
                            [112.900634, 29.631952],
                            [112.907263, 29.626159],
                            [112.915844, 29.621033]
                        ]
                    ],
                    [
                        [
                            [112.92936, 29.774081],
                            [112.931736, 29.772394],
                            [112.937904, 29.76658],
                            [112.939377, 29.76831],
                            [112.939598, 29.780008],
                            [112.937665, 29.784006],
                            [112.933761, 29.78928],
                            [112.927574, 29.794099],
                            [112.919693, 29.799685],
                            [112.909197, 29.802817],
                            [112.901205, 29.803186],
                            [112.896049, 29.801768],
                            [112.891077, 29.799344],
                            [112.888867, 29.799132],
                            [112.886529, 29.796424],
                            [112.898737, 29.793263],
                            [112.921018, 29.780008],
                            [112.92936, 29.774081]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430624,
                "name": "湘阴县",
                "center": [112.889748, 28.677498],
                "centroid": [112.796636, 28.700643],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430600
                },
                "subFeatureIndex": 5,
                "acroutes": [100000, 430000, 430600]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [112.978636, 28.523444],
                            [112.985137, 28.525697],
                            [112.989077, 28.528869],
                            [112.994804, 28.536734],
                            [112.995706, 28.544712],
                            [112.997529, 28.548687],
                            [112.995762, 28.554412],
                            [112.99624, 28.55764],
                            [112.99764, 28.55896],
                            [113.002207, 28.566478],
                            [113.003422, 28.571227],
                            [113.001949, 28.575674],
                            [112.997529, 28.583678],
                            [112.997014, 28.588412],
                            [112.998358, 28.592557],
                            [113.002262, 28.598538],
                            [113.009775, 28.603041],
                            [113.011322, 28.608319],
                            [113.015428, 28.616564],
                            [113.015649, 28.619504],
                            [113.018301, 28.632064],
                            [113.023696, 28.636652],
                            [113.026071, 28.640408],
                            [113.033713, 28.641297],
                            [113.037599, 28.643505],
                            [113.037488, 28.64643],
                            [113.034634, 28.648938],
                            [113.030178, 28.650687],
                            [113.021413, 28.65288],
                            [113.015741, 28.653597],
                            [113.011027, 28.655059],
                            [113.008283, 28.658872],
                            [113.005153, 28.660907],
                            [113.001175, 28.661022],
                            [112.992263, 28.65956],
                            [112.986205, 28.66128],
                            [112.976998, 28.666626],
                            [112.970221, 28.667945],
                            [112.967846, 28.668991],
                            [112.965562, 28.674724],
                            [112.964071, 28.67696],
                            [112.960057, 28.678995],
                            [112.959007, 28.680958],
                            [112.960867, 28.684828],
                            [112.95921, 28.687794],
                            [112.953464, 28.690259],
                            [112.953041, 28.692351],
                            [112.956981, 28.695102],
                            [112.969061, 28.699615],
                            [112.973094, 28.70079],
                            [112.981914, 28.700174],
                            [112.984732, 28.700776],
                            [112.988304, 28.703097],
                            [112.993294, 28.708513],
                            [112.995485, 28.708771],
                            [112.998027, 28.706206],
                            [113.001047, 28.701335],
                            [113.006847, 28.699945],
                            [113.009775, 28.701851],
                            [113.017969, 28.709587],
                            [113.022389, 28.715118],
                            [113.025095, 28.719845],
                            [113.024672, 28.721593],
                            [113.020326, 28.729214],
                            [113.015391, 28.737049],
                            [113.014894, 28.740142],
                            [113.015759, 28.743379],
                            [113.015281, 28.745413],
                            [113.011985, 28.750325],
                            [113.011469, 28.755853],
                            [113.016828, 28.768584],
                            [113.019571, 28.773982],
                            [113.018558, 28.777461],
                            [113.022076, 28.77915],
                            [113.024746, 28.781785],
                            [113.025261, 28.785736],
                            [113.02272, 28.7887],
                            [113.009351, 28.796029],
                            [113.006589, 28.799722],
                            [113.004214, 28.805691],
                            [113.003035, 28.80592],
                            [112.996701, 28.810014],
                            [112.991711, 28.81614],
                            [112.989188, 28.817614],
                            [112.977716, 28.820505],
                            [112.966409, 28.81989],
                            [112.96037, 28.821307],
                            [112.956226, 28.823725],
                            [112.952065, 28.827174],
                            [112.939469, 28.831897],
                            [112.937223, 28.831653],
                            [112.9289, 28.827847],
                            [112.925935, 28.827332],
                            [112.918919, 28.828348],
                            [112.912437, 28.834744],
                            [112.90382, 28.835317],
                            [112.897485, 28.830866],
                            [112.89058, 28.830967],
                            [112.891482, 28.839123],
                            [112.893913, 28.855034],
                            [112.89452, 28.863002],
                            [112.891832, 28.872058],
                            [112.879071, 28.890695],
                            [112.873547, 28.900534],
                            [112.872736, 28.909157],
                            [112.874338, 28.918151],
                            [112.879789, 28.930018],
                            [112.885461, 28.941269],
                            [112.894281, 28.957621],
                            [112.894097, 28.965025],
                            [112.886621, 28.977058],
                            [112.876088, 28.984074],
                            [112.874615, 28.974586],
                            [112.872423, 28.962967],
                            [112.864432, 28.952776],
                            [112.852683, 28.942756],
                            [112.847988, 28.935536],
                            [112.838118, 28.929275],
                            [112.826149, 28.925658],
                            [112.811601, 28.921568],
                            [112.803278, 28.917465],
                            [112.797459, 28.913676],
                            [112.794421, 28.913919],
                            [112.784164, 28.911988],
                            [112.776136, 28.908185],
                            [112.773705, 28.905325],
                            [112.773208, 28.902751],
                            [112.766118, 28.899404],
                            [112.754812, 28.89633],
                            [112.74671, 28.89284],
                            [112.737374, 28.887076],
                            [112.73207, 28.88536],
                            [112.7229, 28.883486],
                            [112.716787, 28.883401],
                            [112.708905, 28.882457],
                            [112.698925, 28.880797],
                            [112.693088, 28.88074],
                            [112.685188, 28.879767],
                            [112.677417, 28.871242],
                            [112.675152, 28.866493],
                            [112.671635, 28.861686],
                            [112.669223, 28.856464],
                            [112.668357, 28.850455],
                            [112.662225, 28.844031],
                            [112.65985, 28.840869],
                            [112.659887, 28.834616],
                            [112.663938, 28.829435],
                            [112.663533, 28.82497],
                            [112.664932, 28.821035],
                            [112.667234, 28.818645],
                            [112.670328, 28.817042],
                            [112.672979, 28.811503],
                            [112.673348, 28.804933],
                            [112.671911, 28.802428],
                            [112.672758, 28.799007],
                            [112.675484, 28.797232],
                            [112.673918, 28.793109],
                            [112.67169, 28.791577],
                            [112.661931, 28.788986],
                            [112.6595, 28.788857],
                            [112.656001, 28.791148],
                            [112.653608, 28.791706],
                            [112.650919, 28.790947],
                            [112.64998, 28.788986],
                            [112.647881, 28.788342],
                            [112.644474, 28.789301],
                            [112.639834, 28.788828],
                            [112.639281, 28.787454],
                            [112.634328, 28.785221],
                            [112.630571, 28.784505],
                            [112.626207, 28.780754],
                            [112.619136, 28.778563],
                            [112.618529, 28.772808],
                            [112.617479, 28.77003],
                            [112.61398, 28.76602],
                            [112.608143, 28.760522],
                            [112.606099, 28.758016],
                            [112.603687, 28.750454],
                            [112.603779, 28.746903],
                            [112.604994, 28.742592],
                            [112.602121, 28.741618],
                            [112.599138, 28.744181],
                            [112.58971, 28.742176],
                            [112.586175, 28.740357],
                            [112.583468, 28.740787],
                            [112.580577, 28.739698],
                            [112.573911, 28.735444],
                            [112.569105, 28.731649],
                            [112.562807, 28.729973],
                            [112.558093, 28.731405],
                            [112.552256, 28.7296],
                            [112.551998, 28.727638],
                            [112.555662, 28.721278],
                            [112.555828, 28.719659],
                            [112.552458, 28.71486],
                            [112.548223, 28.712353],
                            [112.534541, 28.709587],
                            [112.529017, 28.705963],
                            [112.526421, 28.702538],
                            [112.525076, 28.693468],
                            [112.525684, 28.682649],
                            [112.525316, 28.678379],
                            [112.521117, 28.66548],
                            [112.518282, 28.661653],
                            [112.510603, 28.654801],
                            [112.514893, 28.652336],
                            [112.518447, 28.652078],
                            [112.521228, 28.649125],
                            [112.526771, 28.646759],
                            [112.528207, 28.642774],
                            [112.532258, 28.64167],
                            [112.533768, 28.640423],
                            [112.544117, 28.639434],
                            [112.549475, 28.641512],
                            [112.552587, 28.644107],
                            [112.555, 28.643562],
                            [112.556546, 28.640365],
                            [112.561979, 28.635089],
                            [112.563673, 28.629369],
                            [112.564814, 28.628193],
                            [112.570707, 28.625913],
                            [112.574077, 28.623806],
                            [112.576728, 28.624264],
                            [112.580522, 28.629742],
                            [112.587556, 28.629555],
                            [112.588734, 28.627404],
                            [112.585015, 28.620923],
                            [112.585512, 28.613725],
                            [112.58855, 28.610613],
                            [112.588237, 28.609079],
                            [112.585033, 28.605235],
                            [112.590152, 28.601707],
                            [112.585917, 28.594981],
                            [112.583284, 28.593317],
                            [112.583136, 28.590864],
                            [112.578791, 28.588397],
                            [112.57439, 28.585113],
                            [112.569786, 28.579934],
                            [112.563746, 28.57094],
                            [112.561979, 28.565373],
                            [112.562476, 28.56358],
                            [112.568018, 28.560711],
                            [112.582529, 28.55688],
                            [112.58588, 28.555058],
                            [112.589213, 28.55127],
                            [112.59192, 28.542947],
                            [112.594829, 28.540838],
                            [112.601569, 28.539905],
                            [112.609745, 28.540809],
                            [112.621585, 28.54431],
                            [112.629338, 28.54718],
                            [112.632818, 28.55005],
                            [112.63547, 28.556134],
                            [112.635451, 28.559032],
                            [112.642043, 28.56127],
                            [112.645321, 28.564011],
                            [112.64882, 28.564269],
                            [112.658708, 28.580522],
                            [112.662888, 28.582975],
                            [112.668357, 28.584252],
                            [112.675134, 28.581211],
                            [112.676865, 28.582645],
                            [112.681671, 28.592586],
                            [112.682389, 28.595354],
                            [112.684009, 28.596372],
                            [112.689331, 28.595927],
                            [112.694248, 28.59686],
                            [112.697599, 28.599025],
                            [112.700361, 28.5998],
                            [112.705351, 28.599556],
                            [112.711318, 28.595626],
                            [112.717505, 28.596186],
                            [112.719715, 28.594493],
                            [112.719622, 28.589688],
                            [112.713877, 28.58867],
                            [112.713214, 28.587164],
                            [112.716474, 28.579188],
                            [112.719751, 28.579131],
                            [112.724778, 28.581756],
                            [112.726215, 28.581699],
                            [112.731813, 28.575358],
                            [112.733893, 28.571098],
                            [112.736103, 28.568243],
                            [112.735385, 28.565919],
                            [112.732126, 28.564398],
                            [112.731297, 28.562303],
                            [112.726546, 28.557196],
                            [112.720249, 28.554957],
                            [112.713969, 28.555775],
                            [112.709384, 28.552174],
                            [112.701742, 28.552403],
                            [112.698004, 28.549132],
                            [112.692885, 28.547367],
                            [112.688263, 28.544296],
                            [112.685924, 28.54101],
                            [112.685703, 28.5388],
                            [112.687453, 28.53405],
                            [112.68539, 28.525425],
                            [112.695923, 28.521794],
                            [112.700453, 28.519698],
                            [112.707616, 28.51423],
                            [112.714559, 28.510655],
                            [112.71977, 28.510555],
                            [112.724521, 28.508431],
                            [112.731665, 28.50744],
                            [112.741664, 28.509019],
                            [112.746894, 28.510469],
                            [112.745531, 28.514287],
                            [112.746176, 28.51667],
                            [112.753873, 28.51799],
                            [112.754794, 28.524205],
                            [112.757721, 28.529745],
                            [112.766708, 28.538542],
                            [112.772692, 28.546405],
                            [112.776817, 28.549074],
                            [112.787958, 28.554068],
                            [112.796428, 28.55853],
                            [112.806077, 28.559879],
                            [112.808784, 28.559061],
                            [112.80302, 28.551313],
                            [112.796925, 28.544813],
                            [112.790996, 28.539302],
                            [112.788805, 28.535012],
                            [112.789412, 28.527965],
                            [112.792045, 28.523028],
                            [112.7949, 28.520459],
                            [112.798951, 28.518866],
                            [112.80291, 28.518622],
                            [112.808581, 28.519311],
                            [112.81243, 28.521435],
                            [112.815597, 28.527793],
                            [112.821269, 28.528582],
                            [112.823976, 28.526975],
                            [112.824362, 28.522856],
                            [112.822466, 28.517588],
                            [112.822539, 28.512794],
                            [112.826259, 28.510971],
                            [112.832317, 28.512033],
                            [112.836295, 28.510785],
                            [112.845355, 28.510655],
                            [112.85261, 28.512521],
                            [112.854451, 28.51232],
                            [112.860915, 28.508086],
                            [112.864763, 28.506607],
                            [112.865002, 28.503665],
                            [112.867194, 28.502114],
                            [112.872387, 28.500636],
                            [112.873528, 28.501669],
                            [112.873712, 28.505732],
                            [112.871116, 28.512995],
                            [112.870472, 28.517],
                            [112.871466, 28.519153],
                            [112.885111, 28.520645],
                            [112.891316, 28.524779],
                            [112.893324, 28.523215],
                            [112.895994, 28.519468],
                            [112.8987, 28.517516],
                            [112.904022, 28.51667],
                            [112.912345, 28.517158],
                            [112.917925, 28.519655],
                            [112.921258, 28.520315],
                            [112.926727, 28.518464],
                            [112.932417, 28.514359],
                            [112.937057, 28.50922],
                            [112.939801, 28.508517],
                            [112.945252, 28.511919],
                            [112.947535, 28.514029],
                            [112.950536, 28.515263],
                            [112.955343, 28.515938],
                            [112.961235, 28.519454],
                            [112.965636, 28.521162],
                            [112.974438, 28.520645],
                            [112.978636, 28.523444]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430626,
                "name": "平江县",
                "center": [113.593751, 28.701523],
                "centroid": [113.714385, 28.756033],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430600
                },
                "subFeatureIndex": 6,
                "acroutes": [100000, 430000, 430600]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [113.233618, 28.66161],
                            [113.238258, 28.660879],
                            [113.243598, 28.656177],
                            [113.24509, 28.650099],
                            [113.24811, 28.645498],
                            [113.245955, 28.640609],
                            [113.251001, 28.634086],
                            [113.251811, 28.629699],
                            [113.258864, 28.62762],
                            [113.262362, 28.628924],
                            [113.264646, 28.631333],
                            [113.273742, 28.631591],
                            [113.275621, 28.632509],
                            [113.278585, 28.637584],
                            [113.283612, 28.638702],
                            [113.289118, 28.637584],
                            [113.297239, 28.634717],
                            [113.304512, 28.634115],
                            [113.31, 28.633168],
                            [113.315248, 28.630444],
                            [113.316537, 28.628623],
                            [113.315082, 28.624738],
                            [113.316187, 28.619475],
                            [113.318028, 28.616894],
                            [113.323497, 28.615403],
                            [113.332207, 28.618988],
                            [113.340475, 28.618859],
                            [113.349241, 28.616593],
                            [113.35296, 28.617754],
                            [113.358595, 28.6224],
                            [113.359792, 28.625469],
                            [113.360086, 28.631462],
                            [113.365445, 28.637226],
                            [113.369625, 28.640036],
                            [113.375812, 28.643347],
                            [113.381152, 28.644896],
                            [113.384761, 28.644566],
                            [113.397412, 28.639304],
                            [113.403323, 28.634502],
                            [113.406987, 28.633125],
                            [113.41183, 28.632251],
                            [113.417078, 28.633283],
                            [113.424518, 28.63605],
                            [113.426506, 28.634745],
                            [113.426433, 28.632021],
                            [113.424389, 28.622816],
                            [113.423008, 28.61992],
                            [113.418349, 28.619576],
                            [113.417741, 28.615259],
                            [113.420724, 28.608663],
                            [113.416931, 28.604662],
                            [113.415182, 28.595956],
                            [113.414574, 28.590907],
                            [113.415679, 28.587651],
                            [113.418644, 28.588957],
                            [113.421001, 28.587121],
                            [113.424315, 28.578715],
                            [113.425494, 28.578658],
                            [113.426783, 28.585772],
                            [113.430392, 28.590291],
                            [113.441993, 28.590362],
                            [113.445178, 28.593819],
                            [113.446504, 28.593948],
                            [113.449451, 28.590979],
                            [113.454367, 28.591037],
                            [113.459044, 28.596802],
                            [113.462727, 28.596515],
                            [113.469798, 28.590104],
                            [113.473702, 28.585356],
                            [113.474752, 28.582746],
                            [113.474586, 28.579174],
                            [113.478784, 28.576219],
                            [113.491343, 28.570452],
                            [113.497217, 28.565833],
                            [113.500937, 28.559176],
                            [113.501784, 28.551758],
                            [113.504619, 28.540953],
                            [113.506369, 28.537953],
                            [113.50845, 28.53659],
                            [113.515392, 28.537021],
                            [113.517749, 28.536317],
                            [113.521616, 28.53372],
                            [113.524783, 28.530333],
                            [113.528208, 28.528281],
                            [113.530436, 28.528496],
                            [113.537765, 28.527606],
                            [113.543565, 28.52587],
                            [113.5485, 28.524966],
                            [113.552018, 28.526501],
                            [113.554117, 28.530634],
                            [113.558021, 28.53316],
                            [113.562587, 28.532558],
                            [113.566952, 28.533045],
                            [113.575919, 28.536375],
                            [113.578442, 28.535342],
                            [113.581996, 28.532041],
                            [113.585531, 28.526185],
                            [113.59472, 28.516296],
                            [113.598127, 28.51199],
                            [113.604774, 28.505402],
                            [113.615326, 28.495755],
                            [113.619432, 28.492955],
                            [113.624846, 28.490673],
                            [113.629026, 28.484542],
                            [113.629652, 28.482762],
                            [113.635821, 28.482604],
                            [113.640608, 28.477607],
                            [113.646096, 28.473802],
                            [113.649742, 28.470025],
                            [113.654216, 28.462343],
                            [113.659078, 28.459341],
                            [113.661103, 28.457],
                            [113.661416, 28.454458],
                            [113.656665, 28.449934],
                            [113.656444, 28.448656],
                            [113.659777, 28.446732],
                            [113.669979, 28.445051],
                            [113.676958, 28.442035],
                            [113.684618, 28.437596],
                            [113.691247, 28.432669],
                            [113.69609, 28.427785],
                            [113.703401, 28.422556],
                            [113.704671, 28.422183],
                            [113.717745, 28.424022],
                            [113.719642, 28.425113],
                            [113.721668, 28.431736],
                            [113.72721, 28.438889],
                            [113.73198, 28.442365],
                            [113.740358, 28.444448],
                            [113.750338, 28.448326],
                            [113.756249, 28.449504],
                            [113.761682, 28.451385],
                            [113.768403, 28.450466],
                            [113.77389, 28.45104],
                            [113.779728, 28.453295],
                            [113.784957, 28.457561],
                            [113.789671, 28.457977],
                            [113.797829, 28.456785],
                            [113.803519, 28.457144],
                            [113.806391, 28.458638],
                            [113.81755, 28.466881],
                            [113.824898, 28.484111],
                            [113.832981, 28.48905],
                            [113.833166, 28.492309],
                            [113.832263, 28.495137],
                            [113.832447, 28.49887],
                            [113.835117, 28.503091],
                            [113.840623, 28.514832],
                            [113.844417, 28.523903],
                            [113.851801, 28.529572],
                            [113.855778, 28.53184],
                            [113.858467, 28.532227],
                            [113.868705, 28.524018],
                            [113.876494, 28.518909],
                            [113.880232, 28.516971],
                            [113.885646, 28.515163],
                            [113.889531, 28.515622],
                            [113.897597, 28.524879],
                            [113.900046, 28.527219],
                            [113.903195, 28.528611],
                            [113.911131, 28.526085],
                            [113.917852, 28.525439],
                            [113.932528, 28.530807],
                            [113.935438, 28.531395],
                            [113.939839, 28.526544],
                            [113.941257, 28.526372],
                            [113.944848, 28.528898],
                            [113.948015, 28.532098],
                            [113.950998, 28.536418],
                            [113.963575, 28.54378],
                            [113.966005, 28.550122],
                            [113.970627, 28.558774],
                            [113.97431, 28.563982],
                            [113.976004, 28.564728],
                            [113.983517, 28.562877],
                            [113.990294, 28.563178],
                            [113.993351, 28.5648],
                            [113.995763, 28.5649],
                            [114.002024, 28.562174],
                            [114.00602, 28.558932],
                            [114.010918, 28.556736],
                            [114.013551, 28.554599],
                            [114.014564, 28.55203],
                            [114.015061, 28.545688],
                            [114.019572, 28.54289],
                            [114.025447, 28.542804],
                            [114.030326, 28.54642],
                            [114.037287, 28.550194],
                            [114.040049, 28.551026],
                            [114.043069, 28.550036],
                            [114.051724, 28.544382],
                            [114.053289, 28.544167],
                            [114.062275, 28.546118],
                            [114.065958, 28.547711],
                            [114.068444, 28.550954],
                            [114.073894, 28.555201],
                            [114.081555, 28.554785],
                            [114.086766, 28.55589],
                            [114.086177, 28.558329],
                            [114.088239, 28.560008],
                            [114.094702, 28.56226],
                            [114.095641, 28.563982],
                            [114.094794, 28.570093],
                            [114.100687, 28.575889],
                            [114.102768, 28.581168],
                            [114.104554, 28.583133],
                            [114.110815, 28.585658],
                            [114.114516, 28.585399],
                            [114.118696, 28.586863],
                            [114.122158, 28.590735],
                            [114.124865, 28.597233],
                            [114.12921, 28.602654],
                            [114.13212, 28.6072],
                            [114.12956, 28.615704],
                            [114.124625, 28.61784],
                            [114.122766, 28.62012],
                            [114.122287, 28.623261],
                            [114.123594, 28.627404],
                            [114.127387, 28.630401],
                            [114.128308, 28.634932],
                            [114.132322, 28.639491],
                            [114.13282, 28.641842],
                            [114.129597, 28.645885],
                            [114.129929, 28.650988],
                            [114.132617, 28.653927],
                            [114.132433, 28.658342],
                            [114.129413, 28.662269],
                            [114.130463, 28.665308],
                            [114.132636, 28.668189],
                            [114.133114, 28.671686],
                            [114.126559, 28.677132],
                            [114.123318, 28.682205],
                            [114.123852, 28.687651],
                            [114.122397, 28.68947],
                            [114.125417, 28.697624],
                            [114.128235, 28.701034],
                            [114.131273, 28.700762],
                            [114.134679, 28.702753],
                            [114.140001, 28.702954],
                            [114.14153, 28.705089],
                            [114.142211, 28.713226],
                            [114.143776, 28.716908],
                            [114.147569, 28.720418],
                            [114.149356, 28.723756],
                            [114.149485, 28.728282],
                            [114.148803, 28.731147],
                            [114.146612, 28.734571],
                            [114.147551, 28.737607],
                            [114.15197, 28.742463],
                            [114.152118, 28.746759],
                            [114.157126, 28.761453],
                            [114.156022, 28.766479],
                            [114.153775, 28.77076],
                            [114.151363, 28.772693],
                            [114.14849, 28.773251],
                            [114.139044, 28.777919],
                            [114.13746, 28.779752],
                            [114.139559, 28.784448],
                            [114.140277, 28.789158],
                            [114.142819, 28.792393],
                            [114.148877, 28.796559],
                            [114.151989, 28.801612],
                            [114.152468, 28.805806],
                            [114.154585, 28.816941],
                            [114.151934, 28.821836],
                            [114.152965, 28.823797],
                            [114.153922, 28.829364],
                            [114.152376, 28.834558],
                            [114.151123, 28.835975],
                            [114.149521, 28.835059],
                            [114.146059, 28.830451],
                            [114.142911, 28.828019],
                            [114.138012, 28.826316],
                            [114.13444, 28.826974],
                            [114.132194, 28.829593],
                            [114.128787, 28.836776],
                            [114.129063, 28.841656],
                            [114.124883, 28.843273],
                            [114.119064, 28.841799],
                            [114.113319, 28.839395],
                            [114.10599, 28.837678],
                            [114.097464, 28.833757],
                            [114.09461, 28.832984],
                            [114.092401, 28.8337],
                            [114.085882, 28.833929],
                            [114.080431, 28.833242],
                            [114.076509, 28.834515],
                            [114.071243, 28.840583],
                            [114.069199, 28.847036],
                            [114.063232, 28.845877],
                            [114.061004, 28.846649],
                            [114.05966, 28.849726],
                            [114.060452, 28.854104],
                            [114.062293, 28.856693],
                            [114.058776, 28.861815],
                            [114.057027, 28.866307],
                            [114.056401, 28.870541],
                            [114.057561, 28.874976],
                            [114.060065, 28.879067],
                            [114.066823, 28.88453],
                            [114.067118, 28.887505],
                            [114.065, 28.895843],
                            [114.062846, 28.899991],
                            [114.060157, 28.902365],
                            [114.052129, 28.907084],
                            [114.048667, 28.908142],
                            [114.044376, 28.907913],
                            [114.040601, 28.90654],
                            [114.034948, 28.900177],
                            [114.028356, 28.891153],
                            [114.026754, 28.893598],
                            [114.025888, 28.900634],
                            [114.023955, 28.907713],
                            [114.019425, 28.914191],
                            [114.014619, 28.917851],
                            [114.00543, 28.917808],
                            [114.00451, 28.920854],
                            [114.003957, 28.926472],
                            [114.005172, 28.928403],
                            [114.008395, 28.939353],
                            [114.009426, 28.9443],
                            [114.009537, 28.949631],
                            [114.008874, 28.955406],
                            [114.006038, 28.956564],
                            [114.001932, 28.953705],
                            [113.997862, 28.951818],
                            [113.990257, 28.951318],
                            [113.985119, 28.948845],
                            [113.979116, 28.943199],
                            [113.975986, 28.938567],
                            [113.973776, 28.937895],
                            [113.970167, 28.941669],
                            [113.966724, 28.944285],
                            [113.963667, 28.954605],
                            [113.962617, 28.959494],
                            [113.959487, 28.96674],
                            [113.9591, 28.970155],
                            [113.957977, 28.971327],
                            [113.955694, 28.978401],
                            [113.955565, 28.982216],
                            [113.956743, 28.98616],
                            [113.959137, 28.990175],
                            [113.96166, 28.997691],
                            [113.961365, 28.999419],
                            [113.95608, 29.003391],
                            [113.951955, 29.01102],
                            [113.947978, 29.013806],
                            [113.946229, 29.017934],
                            [113.946523, 29.027161],
                            [113.948199, 29.03156],
                            [113.946947, 29.038344],
                            [113.947407, 29.040272],
                            [113.945989, 29.044771],
                            [113.943264, 29.047299],
                            [113.937445, 29.047213],
                            [113.933376, 29.045971],
                            [113.928625, 29.042772],
                            [113.921793, 29.039158],
                            [113.91811, 29.03833],
                            [113.914851, 29.036288],
                            [113.911684, 29.030746],
                            [113.909713, 29.030104],
                            [113.902918, 29.031203],
                            [113.897818, 29.029547],
                            [113.89535, 29.029489],
                            [113.888353, 29.032817],
                            [113.881484, 29.033574],
                            [113.877894, 29.035502],
                            [113.876586, 29.038202],
                            [113.878059, 29.042843],
                            [113.875371, 29.047927],
                            [113.874874, 29.053125],
                            [113.876107, 29.056995],
                            [113.882405, 29.06432],
                            [113.881834, 29.065448],
                            [113.877415, 29.065219],
                            [113.870933, 29.065933],
                            [113.864543, 29.064477],
                            [113.860032, 29.061321],
                            [113.854894, 29.058922],
                            [113.84926, 29.059165],
                            [113.847326, 29.059993],
                            [113.844693, 29.063905],
                            [113.842336, 29.065219],
                            [113.836591, 29.066975],
                            [113.831563, 29.069331],
                            [113.831527, 29.07173],
                            [113.834362, 29.076113],
                            [113.837014, 29.086819],
                            [113.836112, 29.091773],
                            [113.833055, 29.094014],
                            [113.827162, 29.102449],
                            [113.82545, 29.103406],
                            [113.815967, 29.105318],
                            [113.806796, 29.102464],
                            [113.798528, 29.10038],
                            [113.792489, 29.097025],
                            [113.788511, 29.098852],
                            [113.784423, 29.097468],
                            [113.78063, 29.094627],
                            [113.775584, 29.095213],
                            [113.773172, 29.094128],
                            [113.766451, 29.089246],
                            [113.766359, 29.08415],
                            [113.765457, 29.079924],
                            [113.752972, 29.066104],
                            [113.749418, 29.060921],
                            [113.745035, 29.05838],
                            [113.741242, 29.058123],
                            [113.737154, 29.06145],
                            [113.731409, 29.060536],
                            [113.727984, 29.060693],
                            [113.723564, 29.056181],
                            [113.715793, 29.041258],
                            [113.711448, 29.035202],
                            [113.706016, 29.033203],
                            [113.698631, 29.033688],
                            [113.68657, 29.038116],
                            [113.681175, 29.03943],
                            [113.676847, 29.038516],
                            [113.670292, 29.035102],
                            [113.660698, 29.031418],
                            [113.657089, 29.028375],
                            [113.654603, 29.021791],
                            [113.651233, 29.018848],
                            [113.648692, 29.019219],
                            [113.645414, 29.024419],
                            [113.643113, 29.02499],
                            [113.635452, 29.025447],
                            [113.62991, 29.022776],
                            [113.62363, 29.018177],
                            [113.621513, 29.015063],
                            [113.622433, 29.010448],
                            [113.621899, 29.008063],
                            [113.616615, 29.00342],
                            [113.612398, 28.997319],
                            [113.611643, 28.988461],
                            [113.611974, 28.984903],
                            [113.611772, 28.973114],
                            [113.609875, 28.969684],
                            [113.598679, 28.971742],
                            [113.591921, 28.969412],
                            [113.588349, 28.97057],
                            [113.587686, 28.976057],
                            [113.585053, 28.980816],
                            [113.58264, 28.980887],
                            [113.580762, 28.977744],
                            [113.583285, 28.972356],
                            [113.581664, 28.968926],
                            [113.574667, 28.967469],
                            [113.571647, 28.967426],
                            [113.569235, 28.969084],
                            [113.570082, 28.970827],
                            [113.577503, 28.978101],
                            [113.577908, 28.979516],
                            [113.575238, 28.982702],
                            [113.571481, 28.984245],
                            [113.566878, 28.98486],
                            [113.562569, 28.983145],
                            [113.561593, 28.980887],
                            [113.561206, 28.975357],
                            [113.558941, 28.970198],
                            [113.556087, 28.966825],
                            [113.547819, 28.963353],
                            [113.545112, 28.962809],
                            [113.538207, 28.964224],
                            [113.530418, 28.964353],
                            [113.526882, 28.963038],
                            [113.513458, 28.954077],
                            [113.51077, 28.954377],
                            [113.507492, 28.95978],
                            [113.496204, 28.962023],
                            [113.491914, 28.961666],
                            [113.481767, 28.95855],
                            [113.47628, 28.958365],
                            [113.474954, 28.961709],
                            [113.473371, 28.962795],
                            [113.468767, 28.96341],
                            [113.467441, 28.965382],
                            [113.467257, 28.969284],
                            [113.465195, 28.972528],
                            [113.461088, 28.973056],
                            [113.451218, 28.970827],
                            [113.444884, 28.970941],
                            [113.443024, 28.972185],
                            [113.441146, 28.977572],
                            [113.439286, 28.978201],
                            [113.430742, 28.974543],
                            [113.425475, 28.972828],
                            [113.41752, 28.972428],
                            [113.414151, 28.96764],
                            [113.409768, 28.964224],
                            [113.405846, 28.962667],
                            [113.401684, 28.96371],
                            [113.399677, 28.962924],
                            [113.397338, 28.959608],
                            [113.393803, 28.957307],
                            [113.387192, 28.958022],
                            [113.391538, 28.952976],
                            [113.395073, 28.946701],
                            [113.395534, 28.939982],
                            [113.391556, 28.935794],
                            [113.388389, 28.933464],
                            [113.388334, 28.931062],
                            [113.390396, 28.927273],
                            [113.396675, 28.921854],
                            [113.402421, 28.917994],
                            [113.404465, 28.910859],
                            [113.403489, 28.907942],
                            [113.399861, 28.901063],
                            [113.400045, 28.899261],
                            [113.402826, 28.893255],
                            [113.405367, 28.891439],
                            [113.416434, 28.888492],
                            [113.431589, 28.881913],
                            [113.438015, 28.877179],
                            [113.442785, 28.872286],
                            [113.44713, 28.860914],
                            [113.446652, 28.858668],
                            [113.442858, 28.854519],
                            [113.440667, 28.853002],
                            [113.43831, 28.85376],
                            [113.435824, 28.856879],
                            [113.432031, 28.863117],
                            [113.428053, 28.861615],
                            [113.423229, 28.856736],
                            [113.418238, 28.850112],
                            [113.416305, 28.84871],
                            [113.412604, 28.851099],
                            [113.408774, 28.8518],
                            [113.401445, 28.848052],
                            [113.396344, 28.844761],
                            [113.387063, 28.835889],
                            [113.376991, 28.827689],
                            [113.373326, 28.82663],
                            [113.370196, 28.828906],
                            [113.367139, 28.833814],
                            [113.360584, 28.843101],
                            [113.356146, 28.850799],
                            [113.350769, 28.85386],
                            [113.345595, 28.850441],
                            [113.341525, 28.845591],
                            [113.335651, 28.840869],
                            [113.326646, 28.837678],
                            [113.321656, 28.839695],
                            [113.311583, 28.840411],
                            [113.302468, 28.838722],
                            [113.29711, 28.841098],
                            [113.292598, 28.841899],
                            [113.287903, 28.840468],
                            [113.285454, 28.837206],
                            [113.285454, 28.831052],
                            [113.292654, 28.821707],
                            [113.291807, 28.817671],
                            [113.286945, 28.809699],
                            [113.281458, 28.806135],
                            [113.275418, 28.796659],
                            [113.273705, 28.791677],
                            [113.270649, 28.785693],
                            [113.269912, 28.782615],
                            [113.268365, 28.78104],
                            [113.266137, 28.780997],
                            [113.261018, 28.782944],
                            [113.254776, 28.786337],
                            [113.251553, 28.785779],
                            [113.247557, 28.783732],
                            [113.234815, 28.781441],
                            [113.22662, 28.781999],
                            [113.222827, 28.783431],
                            [113.220102, 28.786896],
                            [113.21699, 28.789616],
                            [113.212018, 28.792035],
                            [113.210011, 28.794383],
                            [113.205868, 28.803344],
                            [113.204266, 28.805033],
                            [113.198484, 28.807824],
                            [113.194966, 28.808182],
                            [113.188393, 28.810372],
                            [113.185538, 28.808411],
                            [113.181727, 28.799365],
                            [113.18261, 28.796401],
                            [113.182279, 28.793266],
                            [113.183347, 28.790131],
                            [113.182831, 28.78555],
                            [113.177344, 28.781212],
                            [113.176921, 28.779566],
                            [113.178633, 28.775657],
                            [113.177823, 28.77159],
                            [113.181966, 28.768498],
                            [113.186128, 28.768669],
                            [113.186827, 28.765247],
                            [113.185152, 28.760336],
                            [113.187159, 28.756684],
                            [113.1904, 28.75445],
                            [113.188595, 28.752073],
                            [113.189774, 28.745571],
                            [113.192352, 28.744396],
                            [113.192462, 28.739842],
                            [113.196053, 28.740415],
                            [113.194617, 28.737865],
                            [113.191468, 28.736361],
                            [113.188595, 28.73268],
                            [113.190326, 28.729658],
                            [113.192701, 28.728225],
                            [113.190363, 28.725031],
                            [113.190915, 28.719186],
                            [113.189939, 28.716364],
                            [113.191173, 28.714702],
                            [113.188632, 28.709358],
                            [113.185925, 28.706464],
                            [113.188669, 28.703642],
                            [113.188245, 28.702166],
                            [113.19005, 28.700403],
                            [113.187895, 28.698082],
                            [113.188374, 28.695159],
                            [113.187159, 28.691992],
                            [113.184968, 28.689685],
                            [113.184654, 28.685171],
                            [113.18622, 28.683108],
                            [113.194672, 28.6807],
                            [113.196716, 28.681188],
                            [113.200399, 28.678336],
                            [113.203216, 28.677419],
                            [113.208519, 28.677376],
                            [113.214817, 28.678594],
                            [113.214762, 28.676071],
                            [113.216916, 28.675068],
                            [113.219107, 28.672273],
                            [113.221391, 28.67127],
                            [113.221759, 28.669593],
                            [113.226307, 28.665953],
                            [113.229843, 28.665408],
                            [113.230856, 28.662212],
                            [113.233618, 28.66161]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430681,
                "name": "汨罗市",
                "center": [113.079419, 28.803149],
                "centroid": [113.114949, 28.798883],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430600
                },
                "subFeatureIndex": 7,
                "acroutes": [100000, 430000, 430600]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [113.233618, 28.66161],
                            [113.230856, 28.662212],
                            [113.229843, 28.665408],
                            [113.226307, 28.665953],
                            [113.221759, 28.669593],
                            [113.221391, 28.67127],
                            [113.219107, 28.672273],
                            [113.216916, 28.675068],
                            [113.214762, 28.676071],
                            [113.214817, 28.678594],
                            [113.208519, 28.677376],
                            [113.203216, 28.677419],
                            [113.200399, 28.678336],
                            [113.196716, 28.681188],
                            [113.194672, 28.6807],
                            [113.18622, 28.683108],
                            [113.184654, 28.685171],
                            [113.184968, 28.689685],
                            [113.187159, 28.691992],
                            [113.188374, 28.695159],
                            [113.187895, 28.698082],
                            [113.19005, 28.700403],
                            [113.188245, 28.702166],
                            [113.188669, 28.703642],
                            [113.185925, 28.706464],
                            [113.188632, 28.709358],
                            [113.191173, 28.714702],
                            [113.189939, 28.716364],
                            [113.190915, 28.719186],
                            [113.190363, 28.725031],
                            [113.192701, 28.728225],
                            [113.190326, 28.729658],
                            [113.188595, 28.73268],
                            [113.191468, 28.736361],
                            [113.194617, 28.737865],
                            [113.196053, 28.740415],
                            [113.192462, 28.739842],
                            [113.192352, 28.744396],
                            [113.189774, 28.745571],
                            [113.188595, 28.752073],
                            [113.1904, 28.75445],
                            [113.187159, 28.756684],
                            [113.185152, 28.760336],
                            [113.186827, 28.765247],
                            [113.186128, 28.768669],
                            [113.181966, 28.768498],
                            [113.177823, 28.77159],
                            [113.178633, 28.775657],
                            [113.176921, 28.779566],
                            [113.177344, 28.781212],
                            [113.182831, 28.78555],
                            [113.183347, 28.790131],
                            [113.182279, 28.793266],
                            [113.18261, 28.796401],
                            [113.181727, 28.799365],
                            [113.185538, 28.808411],
                            [113.188393, 28.810372],
                            [113.194966, 28.808182],
                            [113.198484, 28.807824],
                            [113.204266, 28.805033],
                            [113.205868, 28.803344],
                            [113.210011, 28.794383],
                            [113.212018, 28.792035],
                            [113.21699, 28.789616],
                            [113.220102, 28.786896],
                            [113.222827, 28.783431],
                            [113.22662, 28.781999],
                            [113.234815, 28.781441],
                            [113.247557, 28.783732],
                            [113.251553, 28.785779],
                            [113.254776, 28.786337],
                            [113.261018, 28.782944],
                            [113.266137, 28.780997],
                            [113.268365, 28.78104],
                            [113.269912, 28.782615],
                            [113.270649, 28.785693],
                            [113.273705, 28.791677],
                            [113.275418, 28.796659],
                            [113.281458, 28.806135],
                            [113.286945, 28.809699],
                            [113.291807, 28.817671],
                            [113.292654, 28.821707],
                            [113.285454, 28.831052],
                            [113.285454, 28.837206],
                            [113.287903, 28.840468],
                            [113.292598, 28.841899],
                            [113.29711, 28.841098],
                            [113.302468, 28.838722],
                            [113.311583, 28.840411],
                            [113.321656, 28.839695],
                            [113.326646, 28.837678],
                            [113.335651, 28.840869],
                            [113.341525, 28.845591],
                            [113.345595, 28.850441],
                            [113.350769, 28.85386],
                            [113.356146, 28.850799],
                            [113.360584, 28.843101],
                            [113.367139, 28.833814],
                            [113.370196, 28.828906],
                            [113.373326, 28.82663],
                            [113.376991, 28.827689],
                            [113.387063, 28.835889],
                            [113.396344, 28.844761],
                            [113.401445, 28.848052],
                            [113.408774, 28.8518],
                            [113.412604, 28.851099],
                            [113.416305, 28.84871],
                            [113.418238, 28.850112],
                            [113.423229, 28.856736],
                            [113.428053, 28.861615],
                            [113.432031, 28.863117],
                            [113.435824, 28.856879],
                            [113.43831, 28.85376],
                            [113.440667, 28.853002],
                            [113.442858, 28.854519],
                            [113.446652, 28.858668],
                            [113.44713, 28.860914],
                            [113.442785, 28.872286],
                            [113.438015, 28.877179],
                            [113.431589, 28.881913],
                            [113.416434, 28.888492],
                            [113.405367, 28.891439],
                            [113.402826, 28.893255],
                            [113.400045, 28.899261],
                            [113.399861, 28.901063],
                            [113.403489, 28.907942],
                            [113.404465, 28.910859],
                            [113.402421, 28.917994],
                            [113.396675, 28.921854],
                            [113.390396, 28.927273],
                            [113.388334, 28.931062],
                            [113.388389, 28.933464],
                            [113.391556, 28.935794],
                            [113.395534, 28.939982],
                            [113.395073, 28.946701],
                            [113.391538, 28.952976],
                            [113.387192, 28.958022],
                            [113.384485, 28.964296],
                            [113.378298, 28.963596],
                            [113.373105, 28.964739],
                            [113.368299, 28.967812],
                            [113.363383, 28.973528],
                            [113.360952, 28.974328],
                            [113.345042, 28.971727],
                            [113.33508, 28.97217],
                            [113.332778, 28.972842],
                            [113.329961, 28.975843],
                            [113.329942, 28.982788],
                            [113.326407, 28.987146],
                            [113.32103, 28.990332],
                            [113.312504, 28.994476],
                            [113.310626, 28.993633],
                            [113.303389, 28.986146],
                            [113.297147, 28.986174],
                            [113.291733, 28.984717],
                            [113.288013, 28.985589],
                            [113.276725, 28.991433],
                            [113.272822, 28.992247],
                            [113.263228, 28.986689],
                            [113.259563, 28.986032],
                            [113.25148, 28.989461],
                            [113.242346, 28.986789],
                            [113.241499, 28.982231],
                            [113.243451, 28.976115],
                            [113.242291, 28.972513],
                            [113.225166, 28.960923],
                            [113.2178, 28.959665],
                            [113.215222, 28.958207],
                            [113.211594, 28.953376],
                            [113.209477, 28.952147],
                            [113.20642, 28.952604],
                            [113.198999, 28.956378],
                            [113.195095, 28.956292],
                            [113.189939, 28.95169],
                            [113.187895, 28.951032],
                            [113.186054, 28.952218],
                            [113.187674, 28.960108],
                            [113.187435, 28.962495],
                            [113.185207, 28.964982],
                            [113.183016, 28.965511],
                            [113.178209, 28.963553],
                            [113.174029, 28.959351],
                            [113.170107, 28.957121],
                            [113.164288, 28.957593],
                            [113.158783, 28.959436],
                            [113.153663, 28.958836],
                            [113.15033, 28.961509],
                            [113.148489, 28.966497],
                            [113.147163, 28.96814],
                            [113.144346, 28.968498],
                            [113.136704, 28.966554],
                            [113.131456, 28.969512],
                            [113.124219, 28.975129],
                            [113.116117, 28.982159],
                            [113.109451, 28.982717],
                            [113.106265, 28.983802],
                            [113.101441, 28.987418],
                            [113.090816, 28.99199],
                            [113.082272, 28.997234],
                            [113.077926, 29.001462],
                            [113.075072, 29.006248],
                            [113.072549, 29.007434],
                            [113.065865, 29.007091],
                            [113.060616, 29.009006],
                            [113.057781, 29.008248],
                            [113.05524, 29.005891],
                            [113.047653, 29.005548],
                            [113.044154, 29.00692],
                            [113.037764, 29.013163],
                            [113.035352, 29.016534],
                            [113.033069, 29.016563],
                            [113.030859, 29.014477],
                            [113.027766, 29.016134],
                            [113.017988, 29.015591],
                            [113.01447, 29.018334],
                            [113.00762, 29.019248],
                            [112.999868, 29.016805],
                            [112.991803, 29.021319],
                            [112.98788, 29.027004],
                            [112.985431, 29.040015],
                            [112.985413, 29.044828],
                            [112.984253, 29.053967],
                            [112.982872, 29.056109],
                            [112.976279, 29.059508],
                            [112.966686, 29.061036],
                            [112.965765, 29.056152],
                            [112.954753, 29.035988],
                            [112.949524, 29.037145],
                            [112.947277, 29.03923],
                            [112.939028, 29.044271],
                            [112.934424, 29.03733],
                            [112.927924, 29.023048],
                            [112.920669, 29.017234],
                            [112.904575, 29.008848],
                            [112.89139, 28.999362],
                            [112.880286, 28.989004],
                            [112.876088, 28.984074],
                            [112.886621, 28.977058],
                            [112.894097, 28.965025],
                            [112.894281, 28.957621],
                            [112.885461, 28.941269],
                            [112.879789, 28.930018],
                            [112.874338, 28.918151],
                            [112.872736, 28.909157],
                            [112.873547, 28.900534],
                            [112.879071, 28.890695],
                            [112.891832, 28.872058],
                            [112.89452, 28.863002],
                            [112.893913, 28.855034],
                            [112.891482, 28.839123],
                            [112.89058, 28.830967],
                            [112.897485, 28.830866],
                            [112.90382, 28.835317],
                            [112.912437, 28.834744],
                            [112.918919, 28.828348],
                            [112.925935, 28.827332],
                            [112.9289, 28.827847],
                            [112.937223, 28.831653],
                            [112.939469, 28.831897],
                            [112.952065, 28.827174],
                            [112.956226, 28.823725],
                            [112.96037, 28.821307],
                            [112.966409, 28.81989],
                            [112.977716, 28.820505],
                            [112.989188, 28.817614],
                            [112.991711, 28.81614],
                            [112.996701, 28.810014],
                            [113.003035, 28.80592],
                            [113.004214, 28.805691],
                            [113.006589, 28.799722],
                            [113.009351, 28.796029],
                            [113.02272, 28.7887],
                            [113.025261, 28.785736],
                            [113.024746, 28.781785],
                            [113.022076, 28.77915],
                            [113.018558, 28.777461],
                            [113.019571, 28.773982],
                            [113.016828, 28.768584],
                            [113.011469, 28.755853],
                            [113.011985, 28.750325],
                            [113.015281, 28.745413],
                            [113.015759, 28.743379],
                            [113.014894, 28.740142],
                            [113.015391, 28.737049],
                            [113.020326, 28.729214],
                            [113.024672, 28.721593],
                            [113.025095, 28.719845],
                            [113.022389, 28.715118],
                            [113.017969, 28.709587],
                            [113.009775, 28.701851],
                            [113.006847, 28.699945],
                            [113.001047, 28.701335],
                            [112.998027, 28.706206],
                            [112.995485, 28.708771],
                            [112.993294, 28.708513],
                            [112.988304, 28.703097],
                            [112.984732, 28.700776],
                            [112.981914, 28.700174],
                            [112.973094, 28.70079],
                            [112.969061, 28.699615],
                            [112.956981, 28.695102],
                            [112.953041, 28.692351],
                            [112.953464, 28.690259],
                            [112.95921, 28.687794],
                            [112.960867, 28.684828],
                            [112.959007, 28.680958],
                            [112.960057, 28.678995],
                            [112.964071, 28.67696],
                            [112.965562, 28.674724],
                            [112.967846, 28.668991],
                            [112.970221, 28.667945],
                            [112.976998, 28.666626],
                            [112.986205, 28.66128],
                            [112.992263, 28.65956],
                            [113.001175, 28.661022],
                            [113.005153, 28.660907],
                            [113.008283, 28.658872],
                            [113.011027, 28.655059],
                            [113.015741, 28.653597],
                            [113.021413, 28.65288],
                            [113.030178, 28.650687],
                            [113.034634, 28.648938],
                            [113.037488, 28.64643],
                            [113.037599, 28.643505],
                            [113.033713, 28.641297],
                            [113.026071, 28.640408],
                            [113.023696, 28.636652],
                            [113.018301, 28.632064],
                            [113.015649, 28.619504],
                            [113.015428, 28.616564],
                            [113.011322, 28.608319],
                            [113.009775, 28.603041],
                            [113.002262, 28.598538],
                            [112.998358, 28.592557],
                            [112.997014, 28.588412],
                            [112.997529, 28.583678],
                            [113.001949, 28.575674],
                            [113.003422, 28.571227],
                            [113.002207, 28.566478],
                            [112.99764, 28.55896],
                            [112.99624, 28.55764],
                            [112.995762, 28.554412],
                            [112.997529, 28.548687],
                            [112.995706, 28.544712],
                            [112.994804, 28.536734],
                            [112.989077, 28.528869],
                            [112.985137, 28.525697],
                            [112.978636, 28.523444],
                            [112.979134, 28.515234],
                            [112.978342, 28.512737],
                            [112.974898, 28.506995],
                            [112.97534, 28.503277],
                            [112.977734, 28.497836],
                            [112.974696, 28.493946],
                            [112.9707, 28.491807],
                            [112.969061, 28.489639],
                            [112.968343, 28.485576],
                            [112.966096, 28.482259],
                            [112.96188, 28.480062],
                            [112.960775, 28.477061],
                            [112.969245, 28.468029],
                            [112.974033, 28.465272],
                            [112.977127, 28.464626],
                            [112.982577, 28.464755],
                            [112.984916, 28.461797],
                            [112.988083, 28.460318],
                            [112.991858, 28.460935],
                            [112.998174, 28.463779],
                            [113.009959, 28.467972],
                            [113.016201, 28.470901],
                            [113.019037, 28.473027],
                            [113.021008, 28.476846],
                            [113.024304, 28.480507],
                            [113.027784, 28.483207],
                            [113.033897, 28.486107],
                            [113.036917, 28.486265],
                            [113.041429, 28.484915],
                            [113.047819, 28.489567],
                            [113.051354, 28.49485],
                            [113.055258, 28.499358],
                            [113.06093, 28.502258],
                            [113.06371, 28.505287],
                            [113.066251, 28.511689],
                            [113.067927, 28.519268],
                            [113.072162, 28.523616],
                            [113.074777, 28.524033],
                            [113.079031, 28.52188],
                            [113.084316, 28.515163],
                            [113.085199, 28.512076],
                            [113.084997, 28.50823],
                            [113.089527, 28.506019],
                            [113.09181, 28.506277],
                            [113.096561, 28.508689],
                            [113.101662, 28.505976],
                            [113.103356, 28.503177],
                            [113.111918, 28.499301],
                            [113.114588, 28.498884],
                            [113.119302, 28.500449],
                            [113.126079, 28.499832],
                            [113.129927, 28.498956],
                            [113.131714, 28.494965],
                            [113.132082, 28.490773],
                            [113.129412, 28.480321],
                            [113.128988, 28.472596],
                            [113.133555, 28.471102],
                            [113.140239, 28.47251],
                            [113.143609, 28.472481],
                            [113.147973, 28.47116],
                            [113.151619, 28.471533],
                            [113.154289, 28.470873],
                            [113.156518, 28.471964],
                            [113.161011, 28.476114],
                            [113.167529, 28.47877],
                            [113.169886, 28.480464],
                            [113.171175, 28.484327],
                            [113.170623, 28.488935],
                            [113.171396, 28.49241],
                            [113.175503, 28.493587],
                            [113.177068, 28.498382],
                            [113.179535, 28.500435],
                            [113.185446, 28.503378],
                            [113.185888, 28.505057],
                            [113.183052, 28.510153],
                            [113.184618, 28.522152],
                            [113.185925, 28.529228],
                            [113.188595, 28.533863],
                            [113.194764, 28.541527],
                            [113.193346, 28.548615],
                            [113.195629, 28.551571],
                            [113.19992, 28.553996],
                            [113.200767, 28.555589],
                            [113.194672, 28.566464],
                            [113.19156, 28.567497],
                            [113.186109, 28.567956],
                            [113.185133, 28.573565],
                            [113.189645, 28.580178],
                            [113.188945, 28.584754],
                            [113.187085, 28.587422],
                            [113.183273, 28.596874],
                            [113.183439, 28.599097],
                            [113.186514, 28.601994],
                            [113.19504, 28.603672],
                            [113.197342, 28.607602],
                            [113.199091, 28.60905],
                            [113.204026, 28.610341],
                            [113.208372, 28.615102],
                            [113.213583, 28.618357],
                            [113.215461, 28.620823],
                            [113.215222, 28.624537],
                            [113.212847, 28.634272],
                            [113.214633, 28.636839],
                            [113.217855, 28.638788],
                            [113.225976, 28.642258],
                            [113.231979, 28.644308],
                            [113.234704, 28.646444],
                            [113.23487, 28.651175],
                            [113.23347, 28.657195],
                            [113.233618, 28.66161]
                        ]
                    ]
                ]
            }
        }, {
            "type": "Feature",
            "properties": {
                "adcode": 430682,
                "name": "临湘市",
                "center": [113.450809, 29.471594],
                "centroid": [113.513978, 29.490703],
                "childrenNum": 0,
                "level": "district",
                "parent": {
                    "adcode": 430600
                },
                "subFeatureIndex": 8,
                "acroutes": [100000, 430000, 430600]
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [113.606818, 29.254827],
                            [113.606468, 29.259003],
                            [113.60691, 29.267653],
                            [113.611146, 29.271343],
                            [113.613631, 29.272455],
                            [113.61527, 29.27814],
                            [113.621899, 29.290478],
                            [113.625987, 29.300094],
                            [113.627847, 29.305949],
                            [113.630941, 29.312544],
                            [113.633022, 29.318384],
                            [113.635342, 29.32072],
                            [113.643426, 29.326872],
                            [113.646777, 29.32861],
                            [113.654511, 29.330375],
                            [113.66103, 29.333451],
                            [113.66335, 29.338962],
                            [113.665965, 29.34705],
                            [113.662889, 29.351934],
                            [113.662208, 29.355166],
                            [113.662705, 29.36358],
                            [113.660956, 29.368207],
                            [113.66149, 29.370769],
                            [113.664289, 29.376848],
                            [113.670034, 29.384577],
                            [113.67659, 29.388975],
                            [113.686441, 29.391807],
                            [113.69191, 29.392718],
                            [113.697324, 29.391736],
                            [113.706144, 29.392675],
                            [113.71073, 29.393572],
                            [113.715259, 29.393117],
                            [113.721575, 29.389658],
                            [113.72327, 29.389615],
                            [113.729199, 29.392704],
                            [113.731869, 29.394796],
                            [113.734318, 29.401812],
                            [113.737835, 29.4087],
                            [113.741739, 29.412869],
                            [113.741389, 29.417892],
                            [113.736933, 29.425149],
                            [113.73697, 29.426258],
                            [113.744704, 29.434652],
                            [113.751812, 29.438906],
                            [113.754482, 29.44141],
                            [113.754905, 29.447583],
                            [113.752033, 29.450144],
                            [113.748792, 29.457099],
                            [113.745956, 29.45865],
                            [113.743857, 29.462604],
                            [113.740524, 29.465377],
                            [113.742365, 29.467624],
                            [113.738609, 29.469928],
                            [113.729715, 29.472161],
                            [113.728499, 29.473412],
                            [113.724798, 29.473327],
                            [113.721944, 29.475033],
                            [113.718758, 29.479811],
                            [113.714247, 29.481262],
                            [113.712645, 29.483637],
                            [113.710269, 29.483722],
                            [113.709496, 29.48557],
                            [113.707286, 29.485755],
                            [113.705518, 29.488756],
                            [113.702038, 29.490177],
                            [113.695888, 29.498651],
                            [113.696053, 29.502746],
                            [113.689645, 29.506869],
                            [113.688853, 29.509584],
                            [113.686607, 29.50984],
                            [113.683734, 29.51183],
                            [113.682298, 29.511617],
                            [113.678026, 29.513664],
                            [113.673036, 29.510366],
                            [113.671673, 29.51247],
                            [113.668082, 29.510764],
                            [113.665136, 29.510323],
                            [113.662134, 29.510991],
                            [113.659225, 29.509229],
                            [113.65534, 29.510949],
                            [113.654714, 29.508802],
                            [113.649466, 29.507992],
                            [113.643389, 29.50785],
                            [113.63536, 29.510025],
                            [113.633961, 29.511603],
                            [113.634403, 29.514943],
                            [113.631585, 29.517502],
                            [113.630922, 29.522676],
                            [113.640516, 29.526116],
                            [113.643573, 29.52839],
                            [113.649355, 29.530295],
                            [113.655027, 29.531304],
                            [113.654842, 29.536008],
                            [113.657273, 29.537017],
                            [113.665118, 29.53679],
                            [113.66869, 29.535738],
                            [113.673625, 29.537699],
                            [113.676829, 29.537913],
                            [113.680825, 29.539362],
                            [113.679149, 29.542901],
                            [113.679278, 29.546326],
                            [113.681948, 29.548699],
                            [113.686386, 29.547633],
                            [113.69005, 29.548301],
                            [113.698429, 29.552124],
                            [113.699331, 29.554241],
                            [113.701762, 29.555378],
                            [113.702904, 29.552948],
                            [113.70561, 29.552735],
                            [113.709293, 29.55434],
                            [113.709827, 29.557808],
                            [113.711079, 29.559172],
                            [113.709772, 29.562155],
                            [113.712239, 29.565722],
                            [113.710766, 29.568194],
                            [113.713915, 29.570595],
                            [113.721078, 29.571447],
                            [113.723288, 29.574303],
                            [113.726676, 29.57571],
                            [113.729125, 29.574843],
                            [113.73662, 29.577073],
                            [113.738351, 29.579517],
                            [113.738296, 29.584191],
                            [113.737449, 29.585554],
                            [113.740487, 29.587088],
                            [113.741021, 29.591563],
                            [113.738204, 29.593992],
                            [113.733563, 29.593552],
                            [113.728573, 29.598239],
                            [113.722901, 29.610837],
                            [113.721226, 29.612172],
                            [113.717009, 29.611092],
                            [113.713326, 29.611263],
                            [113.708409, 29.608976],
                            [113.706089, 29.612399],
                            [113.706181, 29.616432],
                            [113.704082, 29.618676],
                            [113.704947, 29.622851],
                            [113.70318, 29.627253],
                            [113.704763, 29.628275],
                            [113.703548, 29.6311],
                            [113.704377, 29.633216],
                            [113.702793, 29.634735],
                            [113.698558, 29.632847],
                            [113.695409, 29.63448],
                            [113.692463, 29.633557],
                            [113.695298, 29.637419],
                            [113.695151, 29.638484],
                            [113.690971, 29.638271],
                            [113.68808, 29.640826],
                            [113.685907, 29.640287],
                            [113.681967, 29.636368],
                            [113.678781, 29.635019],
                            [113.676847, 29.635346],
                            [113.672336, 29.638015],
                            [113.671342, 29.640301],
                            [113.672759, 29.641437],
                            [113.679204, 29.640769],
                            [113.680641, 29.641948],
                            [113.679573, 29.645057],
                            [113.674343, 29.649089],
                            [113.671599, 29.658941],
                            [113.667125, 29.664278],
                            [113.669242, 29.669317],
                            [113.668708, 29.671318],
                            [113.663313, 29.677591],
                            [113.663037, 29.679748],
                            [113.664786, 29.683325],
                            [113.661214, 29.684233],
                            [113.656979, 29.682317],
                            [113.65267, 29.68358],
                            [113.645378, 29.676073],
                            [113.647237, 29.673617],
                            [113.645212, 29.672113],
                            [113.641419, 29.672283],
                            [113.637865, 29.669856],
                            [113.63293, 29.670537],
                            [113.630002, 29.669913],
                            [113.627774, 29.670864],
                            [113.620942, 29.669757],
                            [113.614276, 29.670906],
                            [113.612122, 29.67031],
                            [113.609893, 29.66723],
                            [113.606413, 29.666804],
                            [113.603927, 29.667755],
                            [113.599766, 29.670935],
                            [113.584058, 29.674043],
                            [113.575569, 29.674923],
                            [113.567688, 29.673433],
                            [113.558242, 29.673986],
                            [113.547175, 29.675377],
                            [113.53841, 29.683651],
                            [113.538299, 29.684673],
                            [113.54014, 29.699644],
                            [113.547874, 29.710044],
                            [113.558444, 29.727053],
                            [113.560286, 29.732926],
                            [113.561169, 29.739039],
                            [113.56058, 29.744344],
                            [113.558242, 29.752371],
                            [113.554338, 29.758554],
                            [113.554301, 29.761277],
                            [113.555885, 29.763659],
                            [113.551078, 29.76668],
                            [113.550968, 29.768197],
                            [113.55791, 29.778207],
                            [113.559531, 29.782361],
                            [113.56198, 29.784828],
                            [113.567817, 29.786543],
                            [113.566381, 29.789039],
                            [113.562017, 29.79047],
                            [113.561888, 29.791576],
                            [113.565515, 29.799259],
                            [113.568314, 29.801201],
                            [113.569419, 29.804575],
                            [113.571647, 29.806347],
                            [113.575643, 29.807977],
                            [113.574207, 29.809975],
                            [113.571758, 29.810358],
                            [113.567578, 29.809125],
                            [113.565681, 29.810415],
                            [113.565257, 29.812597],
                            [113.566694, 29.814085],
                            [113.572052, 29.814737],
                            [113.577227, 29.818564],
                            [113.577871, 29.821072],
                            [113.576048, 29.824871],
                            [113.579381, 29.830482],
                            [113.581057, 29.83231],
                            [113.580909, 29.835669],
                            [113.571776, 29.846253],
                            [113.571518, 29.848931],
                            [113.566528, 29.846211],
                            [113.56047, 29.841138],
                            [113.551299, 29.83598],
                            [113.517896, 29.812441],
                            [113.497346, 29.790513],
                            [113.493129, 29.786586],
                            [113.479079, 29.775882],
                            [113.47326, 29.771869],
                            [113.456651, 29.759207],
                            [113.438641, 29.75162],
                            [113.425954, 29.742897],
                            [113.401776, 29.72406],
                            [113.386382, 29.709732],
                            [113.377359, 29.703205],
                            [113.362259, 29.687795],
                            [113.326223, 29.647825],
                            [113.307772, 29.625563],
                            [113.316776, 29.62126],
                            [113.324271, 29.618108],
                            [113.329795, 29.617767],
                            [113.333607, 29.619996],
                            [113.34158, 29.630944],
                            [113.343219, 29.63509],
                            [113.346699, 29.635218],
                            [113.348025, 29.63147],
                            [113.347233, 29.614614],
                            [113.349627, 29.612796],
                            [113.351874, 29.613649],
                            [113.355943, 29.621573],
                            [113.358079, 29.621629],
                            [113.363567, 29.620025],
                            [113.367857, 29.616659],
                            [113.373234, 29.610482],
                            [113.376567, 29.604588],
                            [113.383454, 29.600412],
                            [113.390396, 29.599531],
                            [113.395129, 29.603267],
                            [113.395865, 29.609842],
                            [113.395423, 29.615182],
                            [113.393803, 29.62028],
                            [113.395037, 29.623802],
                            [113.397946, 29.624256],
                            [113.403452, 29.62366],
                            [113.409216, 29.622027],
                            [113.418662, 29.616276],
                            [113.420853, 29.614046],
                            [113.432031, 29.608948],
                            [113.43448, 29.607101],
                            [113.435124, 29.604687],
                            [113.433025, 29.602202],
                            [113.425236, 29.598225],
                            [113.419012, 29.594262],
                            [113.413745, 29.588992],
                            [113.405791, 29.574331],
                            [113.402273, 29.570538],
                            [113.399806, 29.569941],
                            [113.393177, 29.570055],
                            [113.384007, 29.566986],
                            [113.381999, 29.564883],
                            [113.383399, 29.561644],
                            [113.386566, 29.56072],
                            [113.392735, 29.561701],
                            [113.396896, 29.561829],
                            [113.39929, 29.559214],
                            [113.401647, 29.55488],
                            [113.40603, 29.55309],
                            [113.415274, 29.552138],
                            [113.419822, 29.548898],
                            [113.42774, 29.545772],
                            [113.433817, 29.539646],
                            [113.432859, 29.533294],
                            [113.431534, 29.52795],
                            [113.429987, 29.527395],
                            [113.42275, 29.528831],
                            [113.418662, 29.530849],
                            [113.407043, 29.534701],
                            [113.404815, 29.534487],
                            [113.401353, 29.532199],
                            [113.397799, 29.526685],
                            [113.391925, 29.522378],
                            [113.38977, 29.519435],
                            [113.385001, 29.517971],
                            [113.376457, 29.519734],
                            [113.373124, 29.519179],
                            [113.371393, 29.51591],
                            [113.371577, 29.51301],
                            [113.369736, 29.505419],
                            [113.369054, 29.496604],
                            [113.364745, 29.493945],
                            [113.350603, 29.495197],
                            [113.329022, 29.491414],
                            [113.328727, 29.488727],
                            [113.330108, 29.486864],
                            [113.342206, 29.48567],
                            [113.354194, 29.487334],
                            [113.356882, 29.483949],
                            [113.356606, 29.481148],
                            [113.359645, 29.478588],
                            [113.361504, 29.480707],
                            [113.364193, 29.481305],
                            [113.365021, 29.476071],
                            [113.362757, 29.470426],
                            [113.356754, 29.464652],
                            [113.356827, 29.463073],
                            [113.361873, 29.459915],
                            [113.36539, 29.459489],
                            [113.368925, 29.460171],
                            [113.373676, 29.462988],
                            [113.379624, 29.461764],
                            [113.381263, 29.459688],
                            [113.381152, 29.45424],
                            [113.383417, 29.451026],
                            [113.386879, 29.44865],
                            [113.389365, 29.44572],
                            [113.387984, 29.441822],
                            [113.380766, 29.433699],
                            [113.37839, 29.432148],
                            [113.374192, 29.435293],
                            [113.371006, 29.439731],
                            [113.366881, 29.441851],
                            [113.365279, 29.44141],
                            [113.363825, 29.438166],
                            [113.360841, 29.433841],
                            [113.35946, 29.433571],
                            [113.353126, 29.436288],
                            [113.349333, 29.438493],
                            [113.347454, 29.44067],
                            [113.343937, 29.440343],
                            [113.342501, 29.438778],
                            [113.34309, 29.431238],
                            [113.337584, 29.423541],
                            [113.335927, 29.418589],
                            [113.332962, 29.418746],
                            [113.330569, 29.420923],
                            [113.324289, 29.419785],
                            [113.323442, 29.420937],
                            [113.324363, 29.42485],
                            [113.322098, 29.425362],
                            [113.315653, 29.416156],
                            [113.321785, 29.41617],
                            [113.327033, 29.414747],
                            [113.329114, 29.413125],
                            [113.329077, 29.411347],
                            [113.326462, 29.407661],
                            [113.324934, 29.403065],
                            [113.325505, 29.40019],
                            [113.33171, 29.38711],
                            [113.335098, 29.374086],
                            [113.333515, 29.370969],
                            [113.327033, 29.369075],
                            [113.322982, 29.362555],
                            [113.312744, 29.359366],
                            [113.30965, 29.356789],
                            [113.307827, 29.352461],
                            [113.307882, 29.344772],
                            [113.309319, 29.341597],
                            [113.312578, 29.338834],
                            [113.319428, 29.336869],
                            [113.327383, 29.335986],
                            [113.333331, 29.337467],
                            [113.334767, 29.337211],
                            [113.340199, 29.331842],
                            [113.341028, 29.328723],
                            [113.347325, 29.326431],
                            [113.346644, 29.320762],
                            [113.347215, 29.317486],
                            [113.351321, 29.304154],
                            [113.351395, 29.300949],
                            [113.349112, 29.294994],
                            [113.349296, 29.287016],
                            [113.348596, 29.280933],
                            [113.349296, 29.276772],
                            [113.344582, 29.271913],
                            [113.342151, 29.270631],
                            [113.338744, 29.262451],
                            [113.333828, 29.260214],
                            [113.331526, 29.256708],
                            [113.333367, 29.254884],
                            [113.334436, 29.25068],
                            [113.333073, 29.246432],
                            [113.328819, 29.243111],
                            [113.325284, 29.243126],
                            [113.322006, 29.241743],
                            [113.316021, 29.236184],
                            [113.313848, 29.233661],
                            [113.31337, 29.231494],
                            [113.316132, 29.228487],
                            [113.321306, 29.22699],
                            [113.326794, 29.227403],
                            [113.328267, 29.229413],
                            [113.325173, 29.230796],
                            [113.326794, 29.23396],
                            [113.331839, 29.231665],
                            [113.341451, 29.230796],
                            [113.343514, 29.228786],
                            [113.341856, 29.227246],
                            [113.338284, 29.22632],
                            [113.338616, 29.219662],
                            [113.340181, 29.217082],
                            [113.340715, 29.212548],
                            [113.338542, 29.209739],
                            [113.338542, 29.207743],
                            [113.343237, 29.19943],
                            [113.345263, 29.19933],
                            [113.349241, 29.204264],
                            [113.351082, 29.20415],
                            [113.358484, 29.201455],
                            [113.362333, 29.197519],
                            [113.365298, 29.198275],
                            [113.36725, 29.203009],
                            [113.369441, 29.206032],
                            [113.372516, 29.208],
                            [113.377985, 29.205733],
                            [113.381594, 29.202852],
                            [113.388647, 29.205476],
                            [113.391667, 29.20502],
                            [113.396768, 29.202553],
                            [113.401666, 29.199487],
                            [113.40463, 29.196764],
                            [113.410486, 29.196179],
                            [113.413064, 29.19732],
                            [113.419251, 29.201897],
                            [113.423615, 29.206388],
                            [113.429361, 29.210908],
                            [113.435548, 29.218108],
                            [113.439249, 29.224737],
                            [113.445455, 29.229],
                            [113.451495, 29.230753],
                            [113.46026, 29.234274],
                            [113.464311, 29.23654],
                            [113.467625, 29.240988],
                            [113.472303, 29.24552],
                            [113.47663, 29.248627],
                            [113.478398, 29.250993],
                            [113.474844, 29.254741],
                            [113.472468, 29.258361],
                            [113.472984, 29.262423],
                            [113.476225, 29.266755],
                            [113.479355, 29.26932],
                            [113.48928, 29.274977],
                            [113.496094, 29.27378],
                            [113.500458, 29.269306],
                            [113.503183, 29.264318],
                            [113.505853, 29.261497],
                            [113.516515, 29.26077],
                            [113.520898, 29.261796],
                            [113.530307, 29.266897],
                            [113.534248, 29.272825],
                            [113.538557, 29.275589],
                            [113.544173, 29.278311],
                            [113.549366, 29.279565],
                            [113.553362, 29.277798],
                            [113.557984, 29.271201],
                            [113.562219, 29.268693],
                            [113.565147, 29.268337],
                            [113.568701, 29.269249],
                            [113.574925, 29.26818],
                            [113.581149, 29.269306],
                            [113.585237, 29.270688],
                            [113.590448, 29.269747],
                            [113.593192, 29.265401],
                            [113.596267, 29.25906],
                            [113.5989, 29.254927],
                            [113.601497, 29.254143],
                            [113.603817, 29.256509],
                            [113.606818, 29.254827]
                        ]
                    ]
                ]
            }
        }]
    });
}));